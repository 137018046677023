import * as _ from 'underscore'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { PlanMixin } from './PlanMixin'
import { NamedPlanElementMixin } from './NamedPlanElementMixin'
import { PatternLookupTableDefinition } from '../lookup/PatternLookupTableDefinition'
import { PlanScenario } from './PlanScenario'
import { ScenarioExecution } from './ScenarioExecution'
import { ScenarioPathStep } from './ScenarioPathStep'
import { PeriodDataset } from './PeriodDataset'
import { ScenarioType } from '../vdml/ScenarioType'
import antlr4 from 'antlr4'
import ExprLangLexer from '../../../com/vbee/antlr/ExprLangLexer.js'
import ExprLangParser from '../../../com/vbee/antlr/ExprLangParser.js'
import ExprLangToJuliaListener from '../../../com/vbee/antlr/ExprLangToJuliaListener.js'
import { ValueType } from '../vdml/ValueType'
import { OutputPort } from '../vdml/OutputPort.js'
import { ValueElement } from '../vdml/ValueElement'
import { PeriodKind } from '../vdml/PeriodKind.js'
import { getMonthFromDate } from '../../../../utils.js'
import {ValueDeliveryModel} from '../vdml/ValueDeliveryModel'
import * as bootbox from '../../../../libs/bootbox/bootbox'

var path = DataManager.getDataManager().buildAppNsPath("transformation", global.version);

export class Plan2Mixin {
    static getMixinRelations() {
        var ret = _.union(PlanMixin.getMixinRelations(), [
            {
                type: Backbone.HasMany,
                containingClass: "transformation_Plan",
                key: "valueLibrary",
                relatedModel: "vdml.ValueLibrary",
                includeInJSON: Backbone.Model.prototype.idAttribute
            },
            {
                type: Backbone.HasOne,
                containingClass: "transformation_Plan",
                key: "financialStatementTaxonomy",
                relatedModel: "vdml.ValueLibrary",
                includeInJSON: Backbone.Model.prototype.idAttribute
            },
            {
                type: Backbone.HasOne,
                containingClass: "transformation_Plan",
                key: "defaultValueLibrary",
                relatedModel: "vdml.ValueLibrary",
                includeInJSON: Backbone.Model.prototype.idAttribute
            },
            {
                type: Backbone.HasMany,
                containingClass: "transformation_Plan",
                key: "patternDefinition",
                relatedModel: "lookup.PatternLookupTableDefinition",
                reverseRelation: {
                    key: "patternDefinitionOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            }
        ]);
        return ret;
    }
    static getCumulativeMixinRelations() {
        if (!Plan2Mixin.cummulativeRelations) {
            var BeepPackageMixin = Backbone.Relational.store.getObjectByName("beeppackage.BeepPackageMixin");
            Plan2Mixin.cummulativeRelations = _.union(Plan2Mixin.getMixinRelations()
                , BeepPackageMixin.getCumulativeMixinRelations()
                , NamedPlanElementMixin.getCumulativeMixinRelations()
            );
        }
        return Plan2Mixin.cummulativeRelations.slice();
    }

    static getProperties() {
        let properties = PlanMixin.getProperties();
        properties.push({ name: "instantiationPackage", type: "EString", defaultValue: "null", containingClass: "transformation_Plan" });
        properties.push({ name: "defaultScenario", type: "EString", defaultValue: "null", containingClass: "transformation_Plan" });
        properties.push({ name: "defaultScenarioExecution", type: "EString", defaultValue: "null", containingClass: "transformation_Plan" });
        properties.push({ name: "targetScenarioExecution", type: "EString", defaultValue: "null", containingClass: "transformation_Plan" });
        properties.push({ name: "periodKind", type: "EString", defaultValue: "null", containingClass: "transformation_Plan" });
        // properties.push({ name: "defaultStartYear", type: "EString", defaultValue: "null", containingClass: "transformation_Plan" });
        // properties.push({ name: "defaultStartPeriod", type: "EString", defaultValue: "null", containingClass: "transformation_Plan" });
        return properties;
    }

   
    

    getPeriodDatasetsAsync(scenarioExecId,periodSets,callback){
        var self = this;
        var periodDataset = {input:[],result:[]};
        var plan = DataManager.getDataManager().get('currentPlan');
        const execScenarioPeriodDataset = {input:[],result:[]}
        const nonExecScenarioPeriodDataset = {}
        var scenarioExecution = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: scenarioExecId });
        var scenarioId = scenarioExecution.get("planScenario");
        var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
        scenario.getdefaultExecutionData(periodSets,scenarioExecId,nonExecScenarioPeriodDataset,execScenarioPeriodDataset)
        if(Object.keys(nonExecScenarioPeriodDataset).length>0){
            DataManager.getDataManager().getPeriodDatasetsByScenarioExecution(plan.get('id'),scenarioExecId,nonExecScenarioPeriodDataset,(data)=>{
                if(data.input && data.input.length>0){
                    data.input.map(d=>{
                        execScenarioPeriodDataset.input.push(d);
                    })
                    const sortedInputArr = window.utils.sortYears(execScenarioPeriodDataset.input);
                    periodDataset.input = sortedInputArr;
                }
                if(data.result && data.result.length>0){
                    data.result.map(d=>{
                        execScenarioPeriodDataset.result.push(d)
                    })
                    const sortedResultArr = window.utils.sortYears(execScenarioPeriodDataset.result);
                    periodDataset.result = sortedResultArr;
                }
                callback(periodDataset);

            })
         }else {
                if(execScenarioPeriodDataset.input.length>0){
                    const sortedInputArr = window.utils.sortYears(execScenarioPeriodDataset.input);
                    periodDataset.input = sortedInputArr;
                }
                if(execScenarioPeriodDataset.result.length>0){
                    const sortedResultArr = window.utils.sortYears(execScenarioPeriodDataset.result);
                    periodDataset.result = sortedResultArr;
                }
                callback(periodDataset);

         }
         
    }

    static getDialogViewProperties(type) {
        if (type === "PlanDetails") {
            return {
                templatePath: "views/transformation/views/properties/PlanDetails2Template.html",
                templateName: "PlanDetails2Template",
                viewTypeStr: "appviews/transformation/views/properties/PlanDetails2ViewModel",
                tabId: "PlanDetails2View",
                tabName: "Plan"
            }
        } else if (type === "PlanValidation") {
            return {
                templatePath: "views/transformation/views/correction/PlanValidation2Template.html",
                templateName: "PlanValidation2Template",
                viewTypeStr: "appviews/transformation/views/correction/PlanValidation2ViewModel",
                tabId: "PlanValidation2View",
                tabName: "PlanValidation"
            }
        } else if (type === "Projections") {
            return {
                templatePath: "views/transformation/views/properties/ProjectionsTemplate.html",
                templateName: "ProjectionsTemplate",
                viewTypeStr: "appviews/transformation/views/properties/ProjectionsViewModel",
                tabId: "ProjectionsView",
                tabName: "Projections"
            }
        }
        else {
            return PlanMixin.getDialogViewProperties(type);
        }
    }

    createCommonPackage(wsData,callback) {
        var self = this;
        //var commonAltId = self.getCommonRepositoryId();
        var previousWorkspace = DataManager.getDataManager().getWorkspace();
        var commonWorkspace = wsData.get('workspace');
        DataManager.getDataManager().setWorkspace(commonWorkspace,function(){
            var comPackId = self.getCommonRepositoryId() + '-CommonPackage';
            var commonPackage = new ValueDeliveryModel({ id: comPackId, name: self.get('name') + ' Library' + ' Package', description: self.get('name') + ' Library' + ' Package', planId: self.id, 'libraryPackage': true });
            if (!commonPackage.lawnchair) {
                commonPackage.lawnchair = wsData.get('vdmStore');
            }
            var commonScenario = commonPackage.createScenario();
            DataManager.getDataManager().get('planScenarios').put(self.get('id') , commonScenario);
            DataManager.getDataManager().get('initializedPackages').add({'id':comPackId,'version':commonPackage.get('version'), 'model':commonPackage});
            DataManager.getDataManager().setWorkspace(previousWorkspace,function(){
                callback();
            });
        });
    };

    getViewProperties(type) {
        if (type === "" || !type) {
            return {
                templatePath: "views/transformation/views/properties/PlanProperties2Template.html",
                templateName: "PlanProperties2Template",
                viewTypeStr: "appviews/transformation/views/properties/Plan2ViewModel",
                tabId: "PlanView",
                tabName: "Plan"
            }
        } else if (type === "PlansView") {
            return {
                templatePath: "views/transformation/views/properties/Values2Template.html",
                templateName: "Values2Template",
                viewTypeStr: "appviews/transformation/views/properties/Values2ViewModel",
                tabId: "Values2View",
                tabName: "Values"

            }
        } else {
            return PlanMixin.getDialogViewProperties(type);
        }
    };

    getPeriodKinds(year,period,periodKind){
		var periodKindValue;
        var localeManager = DataManager.getDataManager().get('localeManager')
		if( PeriodKind.symbols()[2].name===periodKind){
			const date = new Date(year,period-1,1);
			periodKindValue = localeManager.get(getMonthFromDate(date));
		} else if(PeriodKind.symbols()[3].name === periodKind){
			periodKindValue = period
		} else if(PeriodKind.symbols()[4].name === periodKind){
            periodKindValue = localeManager.get('week') +" "+period
        } else if(periodKind === PeriodKind.symbols()[1].name){
			periodKindValue = localeManager.get('Quarter') +period
		}
		return {text:periodKindValue,value:+period}
	}

    getInstantiationPackage(callback) {
        var self = this;
        var docVersion = self.get('documentVersion');
        var vdmStore = DataManager.getDataManager().getVDMStore(self.getCommonRepositoryId());
        DataManager.getDataManager().fetchDocumentFromPackage(self.getCommonRepositoryId() + "-InstantiationPackage", "appbo/instdef/PlanInstantiationPackage", self.get('version'), self.getCommonRepositoryId() + "-InstantiationPackage", "appbo/instdef/PlanInstantiationPackage", vdmStore, {
            success: function (instPackage) {
                callback(instPackage);
            },
            error: function (error) {
                console.log('failed to fetch common package');
                self.loadCommonWorkspace(true, callback);
            },
            documentVersion: docVersion
        });
    };

    getValueElementsByPlanScenario(planScenario) {
        var self = this;
        var ret = [];
        var step = planScenario.firstStep;
        while (step) {
            let altId = step.alternativeId;
            let phaseId = step.phaseId;
            let phase = self.get("phase").findWhere({ id: phaseId });
            let alt = phase.get("alternative").findWhere({ id: altId });
            let valueElementContexts = alt.get("valueElementContext").models;

            ret = valueElementContexts.map(context => {
                return context.get("valueElementContextOwner");
            })
        }
        return ret;
    }
    getValueElementsByScenario(scenario, callback) {
        var self = this;
        var dataManager = DataManager.getDataManager();
        dataManager.getPlanScenarioFromBackend(scenario, function (planScenario) {
            var ret = self.getValueElementsByPlanScenario(planScenario);
            callback(ret);
        });
    }
    getPlanDefaultScnarioFromBackend(callback) {
        var self = this;
        let scenario = self.get("defaultScenario");
        var dataManager = DataManager.getDataManager();
        var model = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenario });
        if (model) {
            if (callback) {
                callback(model);
            }
            return
        }
        // let self = this;
        // var defaultExecutionScenario = self.get("defaultExecutionScenario");
        dataManager.getPlanInstance(scenario, self.get('documentVersion'), function (planScenario) {
            let defaultExecutionScenario = self.get("defaultExecutionScenario");
            if (defaultExecutionScenario != undefined) {
                dataManager.getPlanInstance(defaultExecutionScenario, self.get('documentVersion'), function (scenarioExecution) {
                    if (callback) {
                        callback(planScenario);
                        return;
                    }
                }, self.get('documentVersion'), "appbo/transformation/ScenarioExecution");
            } else {
                if (callback) {
                    callback(planScenario);
                    return;
                }
            }
        }, self.get('documentVersion'), "appbo/transformation/PlanScenario");
    }

    getNoOfPeriodDatasets(){
        var self = this;
        const scenarioId = self.get("defaultScenario");
        const scenarioData = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
        let stepObj = scenarioData.get("firstStep");
        let periodsUntillCurPhase = 0;
        while (stepObj) {
            const noOfPeriods = stepObj.get('noPeriods');
            periodsUntillCurPhase = periodsUntillCurPhase + parseInt(noOfPeriods, 10);
            stepObj = stepObj.get("next");
        }
        return periodsUntillCurPhase
    }

    getPlanScenarioInstance() {//for failsafe. useful for 1.0 migration
        var self = this;
        var scenarioId = window.guidGenerator();
        let startTime = (new Date()).getTime();
        let period = 1;

        let planScenario = new PlanScenario({ id: scenarioId, planId: this.id, startTime: startTime, name: "Default Scenario" });
        let executionScenario = new ScenarioExecution({ id: window.guidGenerator(), name: "Default Scenario",scenarioType:ScenarioType.Plan});//,changeSetEnabled:true });
        planScenario.set("defaultExecutionScenario", executionScenario.id);
        executionScenario.set("planScenario", planScenario.id);
        let phases = this.get("phase").models;
        let previous = null;
        phases.forEach(phase => {
            var alt = phase.get("primary") ? phase.get("primary") : phase.get("phaseAlternative").models[0];
            let stepObj = new ScenarioPathStep({ id: window.guidGenerator(), stepOwner: planScenario, phaseId: phase.id, alternativeId: alt.id, startPeriod: period, noPeriods: 1, previous: previous });
            planScenario.get("step").add(stepObj);
            if (previous) {
                previous.set("next", stepObj);
            } else {
                planScenario.set("firstStep", stepObj);
            }
            let yearPeriod = utils.calculateYearPeriod(startTime, period, self.get("periodKind"));
            let inputDataset = new PeriodDataset({ id: window.guidGenerator(), year: yearPeriod.year, periodKind: self.get("periodKind"), period: yearPeriod.period, inputOwner: executionScenario });
            executionScenario.get("input").add(inputDataset);
            period++;
            previous = stepObj;
        });
        return planScenario;
    }

    createPlanScenarioInstance(startTime, scenarioStepData) {
        var self = this;
        var scenarioId = window.guidGenerator();
        // let startTime = (startDate).getTime();
        let period = 1;
        let planScenario = new PlanScenario({ id: scenarioId, planId: this.id, startTime: startTime, name: "Default Scenario" });
        let executionScenario = new ScenarioExecution({ id: window.guidGenerator(), name: "Default Scenario",scenarioType:ScenarioType.Plan});//,changeSetEnabled:true });
        planScenario.set("defaultExecutionScenario", executionScenario.id);
        executionScenario.set("planScenario", planScenario.id);
        //let phases = this.get("phase").models;
        var previous = null;
        //phases.forEach(phase => {
        _.each(scenarioStepData,function(singleStep){
            var alt = window.utils.getElementModel(singleStep.id,['transformation.Alternative']);
            //var alt = phase.get("primary") ? phase.get("primary") : phase.get("phaseAlternative").models[0];
            //var singleStep = scenarioStepData.find((obj) => obj.name === phase.get('name'));
            var startPeriod = singleStep.noOfPeriods === 0 ? 0 : period;
            let stepObj = self.createScenarioPathStep(alt, startPeriod, singleStep.noOfPeriods, planScenario, previous);
            if (previous) {
                previous.set("next", stepObj);
            } else {
                planScenario.set("firstStep", stepObj);
            }
            self.createDefaultDataSets(startTime, stepObj.get('startPeriod'), singleStep.noOfPeriods, self.get("periodKind"), executionScenario);
            period = period + singleStep.noOfPeriods;
            previous = stepObj;
        });
        return planScenario;
    }

    createScenarioPathStep(alt, startPeriod, noOfPeriods, planScenario, previousStep) {
        var stepObj = new ScenarioPathStep({ id: window.guidGenerator(), stepOwner: planScenario, phaseId: alt.getParent().id, alternativeId: alt.id, startPeriod: startPeriod, noPeriods: noOfPeriods, previous: previousStep });
        planScenario.get("step").add(stepObj);
        return stepObj;
    }
    updateScenarioPathStep(existingStep, phase, scenarioStepData, previousStep) {
        const updatedPathStep = scenarioStepData.find((obj) => obj.modalId === phase.get('id'));
        existingStep.set('noPeriods', updatedPathStep.noOfPeriods);
        existingStep.set('startPeriod', updatedPathStep.startPeriod);
        existingStep.set('previous', previousStep);
    }

    createDefaultDataSets(startTime, startPeriod, newPeriods, periodKind, executionScenario){
        let stepInitialPeriod = startPeriod;
        for (let index = 0; index < newPeriods; index++) {
            let yearPeriod = utils.calculateYearPeriod(startTime, stepInitialPeriod, periodKind);
            let inputDataset = new PeriodDataset({ id: window.guidGenerator(), year: yearPeriod.year, periodKind: periodKind, period: yearPeriod.period, inputOwner: executionScenario });
            executionScenario.get("input").add(inputDataset);
            stepInitialPeriod = stepInitialPeriod + 1;
        }
    }

    updatePlanDefaultScenarioInstance(startTime, scenarioStepData, scenarioId, callback) {
        var self = this;
        const planScenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
        const executionScenarioId = planScenario.get("defaultExecutionScenario");
        const executionScenario = Backbone.Relational.store.getObjectByName('transformation.ScenarioExecution').find({ id: executionScenarioId });
        const updatedTotalNoOfPeriods = scenarioStepData.reduce((accumulator, currentObject) => {
            return accumulator + currentObject.noOfPeriods;
        }, 0);
        const existingTotalNoOfPeriods = executionScenario.get("input").length;
        const differTotalNoOfPeriods = updatedTotalNoOfPeriods - existingTotalNoOfPeriods;
        const phases = this.get("phase").models;
        const currentlySavedSteps = planScenario ? planScenario.get('step').models : null;
        let sortedDataset = planScenario.getSortedDataSets(self);
        var previousStep = null;
        var stepObj = null;
        phases.forEach(phase => {
            const existingStep = currentlySavedSteps.find((obj) => obj.get('phaseId') === phase.get('id'));
            if (existingStep === undefined) {
                var alt = phase.get("primary") ? phase.get("primary") : phase.get("phaseAlternative").models[0];
                var singleStep = scenarioStepData.find((obj) => obj.name === phase.get('name'));
                stepObj = self.createScenarioPathStep(alt, singleStep.startPeriod, singleStep.noOfPeriods, planScenario, previousStep);
            } else {
                self.updateScenarioPathStep(existingStep, phase, scenarioStepData, previousStep);
                stepObj = existingStep;
            }
            if (previousStep) {
                previousStep.set("next", stepObj);
            } else {
                planScenario.set("firstStep", stepObj);
            }
            previousStep = stepObj;
        });
        currentlySavedSteps.forEach(step => {
            // if phase is deleted then step correspoding to that phase need to be deleted.
            const phasePresent = phases.find((phase) => phase.get('id') ===  step.get('phaseId'));
            if(!phasePresent) step.destroy();
        });
        if (differTotalNoOfPeriods > 0) {
            // if total no of periods is incresed.
            self.createDefaultDataSets(startTime, existingTotalNoOfPeriods + 1, differTotalNoOfPeriods, self.get("periodKind"), executionScenario);
        }
        if (differTotalNoOfPeriods < 0) {
            // if total no of periods is decresed.
            for (let index = sortedDataset.length; index > (sortedDataset.length + differTotalNoOfPeriods); index--) {
                let inputDataset = executionScenario.get("input").findWhere(sortedDataset[index - 1]);
                inputDataset.destroy();
            }
        }
        callback();
    }
    updatePeriodKindOrStartTime(scenarioId, pKind, startTime) {
        var self = this;
        const perKind = pKind ? pKind : self.get("periodKind");
        const planScenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId ? scenarioId : self.get("defaultScenario") });
        const sortedDataset = planScenario.getSortedDataSets(self);//{period:8,year:2024}
        planScenario.set('startTime', startTime);
        const executionScenarioId = planScenario.get("defaultExecutionScenario");
        const executionScenario = Backbone.Relational.store.getObjectByName('transformation.ScenarioExecution').find({ id: executionScenarioId });
        var stepInitialPeriod = 1;
        sortedDataset.forEach(val => {
            let inputDataset = executionScenario.get("input").findWhere({ 'period': val.period, 'year': val.year });
            let yearPeriod = utils.calculateYearPeriod(planScenario.get('startTime'), stepInitialPeriod, perKind);
            inputDataset.set('periodKind', perKind);
            inputDataset.set('year', yearPeriod.year);
            inputDataset.set('period', yearPeriod.period);
            stepInitialPeriod = stepInitialPeriod + 1;
        });
    }

    deleteScenario(callback) {
        var self = this;
        try {
            const scenarioId = self.get("defaultScenario");
            const planScenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
            const executionScenarioId = planScenario.get("defaultExecutionScenario");
            const executionScenario = Backbone.Relational.store.getObjectByName('transformation.ScenarioExecution').find({ id: executionScenarioId });
            if(executionScenario){
                const inputDataSet = executionScenario.get("input");
                const len = inputDataSet.models.length;
                for (let index = len - 1; index >= 0; index--) {
                    inputDataSet.models[index].destroy();
                }
                const resultDataSet = executionScenario.get("result");
                const reslen = resultDataSet.models.length;
                for (let index = reslen - 1; index >= 0; index--) {
                    resultDataSet.models[index].destroy();
                }
                executionScenario.destroy();
            }
            planScenario.destroy()
            callback();
        } catch (e) {
            console.log("error:" + e);
        }
    };

    getDefaultScenarioValues(scenario,typeList,subLevelList) {
        let valuesList = [];
        //var subCompLevel = ["satisfactionLevel","percentageWeight","recipientOpinion"];
        /*var vp = DataManager.getDataManager().get('currentPlan').get('planCriterionSet');
		if(vp){
			var components = vp.get('component');
			components.each(function(comp){
				if(typeList.includes(comp.get('valueType'))){
                    valuesList.push(comp);
                }
                if(sublevel){
                    _.each(subCompLevel,function(level){
                        if(comp.get(level) && typeList.includes(comp.get(level).get('valueType'))){
                            valuesList.push(comp.get(level));
                        }
                    });
                }
			});
		}*/
        var steps = scenario.get("step");
        for(var step of steps.models){
            var altModel = window.utils.getElementModel(step.get('alternativeId'),['transformation.Alternative']);
            altModel.get('alternativeValues').each(function(comp){
                if(typeList.includes(comp.get('valueType')) && (comp.get('type')=='vdml_ValuePropositionComponent' || comp.get('type')=='vdml_ValueAdd')){
                    valuesList.push(comp);
                    for(var j=0;j<subLevelList.length;j++){
                        if(comp.get(subLevelList[j])){
                            valuesList.push(comp.get(subLevelList[j]));
                        }
                    }
                }
            });
            /*var phaseDesignPart = altModel.get("phaseDesignPart");
            for (var k = 0; k < phaseDesignPart.length; k++) {
                var packId = phaseDesignPart.at(k).get("beepReference");
                if (packId.indexOf("Common") == -1 && phaseDesignPart.at(k).get("beepType") === "vdml_ValueDeliveryModel") {
                    var packageModel = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: packId });
                    if(!packageModel){
                        continue;
                    }
                    for (var l = 0; l < packageModel.get("businessModel").models.length; l++) {
                        var bm = packageModel.get("businessModel").models.at(l);
                        var components = [];
                        bm.getValues(components,true);
                        _.each(components,function(comp){
                            if(comp.get('valueType') == type){
                                valuesList.push(comp);
                            }
                            if(sublevel){
                                _.each(subCompLevel,function(level){
                                    if(comp.get(level) && comp.get(level).get('valueType') == type){
                                        valuesList.push(comp.get(level));
                                    }
                                });
                            }
                        });
                    }
                }
            }*/
        }
        return valuesList;
    }
    
    generateScenarioJuliaModel(scenarioId,  callback) {
        var self = this;
        let dataManger = DataManager.getDataManager();
        var tabCount = 0;
        var modelScript = new Object();
        modelScript.script = "";
        dataManger.getScenario(scenarioId, self, function (scenario) {
            let valueContexts = scenario.getValuesContexts();
            let inputValues = [];//self.getDefaultScenarioValues(scenario,ValueType.Atomic,true);
            let calculatedValues = [];
            let valueBounds = {};
            let valueAltRange = {};
            self.generateScriptHeader(tabCount, modelScript);
            tabCount++;
            self.generateContextStruct(tabCount, modelScript, valueContexts, inputValues, calculatedValues, valueBounds, valueAltRange);
            self.generateValueIdsMapping(tabCount, modelScript, inputValues, calculatedValues);
            self.generateSetPropertyMethod(tabCount, modelScript, inputValues, calculatedValues);
            self.generatePlanModelConstraints(tabCount, modelScript, inputValues,calculatedValues);
            self.generatePlanModelConstructor(tabCount, modelScript, inputValues,calculatedValues,valueContexts);
            self.generateGetFieldByName(tabCount, modelScript);
            self.generateUpdateInput(tabCount, modelScript);
            let assignmentsScript = self.generatePlanData(tabCount, modelScript,inputValues, calculatedValues);
            let optExpression = scenario.get("optimizationObjective");
            let optObjective = null;
            if(optExpression){
                optObjective = optExpression.get("expressionStr");
                let config = optExpression.get("expressionConfig");
                if(config != undefined){
                    config = JSON.parse(config);
                    let aggregatedFromValuesListStr = config["aggregatedFromValue"];
                    let aggregatedFromValuesList = [];
                    for(var i=0;i < aggregatedFromValuesListStr.length;i++){
                        var valueElement = Backbone.Relational.store.getObjectByName('vdml.ValueElement').find({ id: aggregatedFromValuesListStr[i] });
                        if(valueElement != null){
                            aggregatedFromValuesList.push(valueElement);
                        }
                    }
                    optObjective = self.getOptmizedExpression(optObjective,aggregatedFromValuesList,null,true);
                }else{
                    optObjective = {code: optObjective}
                }
            }
            if(optObjective){
                self.generateDefineConstraints(tabCount, modelScript, valueContexts, inputValues, calculatedValues, valueBounds, valueAltRange, assignmentsScript,optObjective.code,scenario.get("optimizationType"),scenarioId);
            }else{
                self.generateDefineConstraints(tabCount, modelScript, valueContexts, inputValues, calculatedValues, valueBounds, valueAltRange, assignmentsScript,10,"Max",scenarioId);
            }
            
            self.generateBuildModel(tabCount, modelScript);
            self.generateGetModelValues(tabCount, modelScript);
            self.generateGetStep(tabCount, modelScript);
            self.generateReadPlanScenario(tabCount, modelScript);
            self.generateParseValue(tabCount, modelScript);
            self.generateReadContext(tabCount, modelScript, inputValues, calculatedValues);
            self.generateGetPeriod(tabCount, modelScript);
            self.generateReadContexts(tabCount, modelScript);
            self.generateCalculateScenario(tabCount, modelScript);

            self.generateRealMain(tabCount, modelScript);
            self.generateJuliMain(tabCount, modelScript);
            self.generateFooter(tabCount, modelScript);
            console.log("script:" + modelScript.script);
            callback(modelScript);
        });
    }

    generateDefineConstraints(tabCount, modelScript, valueContexts, inputValues, calculatedValues, valueBounds, valueAltRange, assignmentScript,modelObjective,optimizationType,scenarioId) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "function define_constraints(pm::PlanModel,periods::Vector{Int}, contexts::Dict{Int, Context},resultContexts::Dict{Int, Context},self,doOptimize)");
        tabCount++;
        

        self.writeScriptLine(tabCount, modelScript, "function minimum(args...)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "return minOf(pm.model,args...)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end"); 

        self.writeScriptLine(tabCount, modelScript, "function maximum(args...)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "return maxOf(pm.model,args...)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end"); 

        self.writeScriptLine(tabCount, modelScript, "function firstPeriod()");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "return modelFirstPeriod(contexts,resultContexts)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");


        self.writeScriptLine(tabCount, modelScript, "function lastPeriod()");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "return modelLastPeriod(contexts,resultContexts)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");

        self.writeScriptLine(tabCount, modelScript, "function indexYearPeriod(period_num, year_offset)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "return calculateIndexYearPeriod(pm.startTime,period_num, year_offset, pm.period_kind)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");

        self.writeScriptLine(tabCount, modelScript, "function firstYearPeriod(period_num)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "return getFirstYearPeriod(pm.startTime,period_num, pm.period_kind)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
        self.writeScriptLine(tabCount, modelScript, "function lastYearPeriod(period_num)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "return getLastYearPeriod(pm.startTime,period_num, pm.period_kind)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");

        inputValues.forEach(value => {
            let escValId = self.htmlEscape(value);
            self.writeScriptLine(tabCount, modelScript, "@variable(pm.model, " + escValId + "_var[periods])");
            
        })
        calculatedValues.forEach(value => {
            let escValId = self.htmlEscape(value);
            self.writeScriptLine(tabCount, modelScript, "@variable(pm.model, " + escValId + "[periods])");
        })
        modelScript.script = modelScript.script.concat(assignmentScript.script);

        let contextModelScript = new Object();
        contextModelScript.script = "";

        self.writeScriptLine(tabCount, contextModelScript, "for (t, context) in contexts");
        tabCount++;
        let ifConstraintScripts = self.generateStepConstraints(tabCount, contextModelScript, valueContexts, inputValues, valueBounds,scenarioId);

        //print constraints before constraint calls.
        ifConstraintScripts.forEach(ifConstraint =>{
            self.writeScriptLine(tabCount, modelScript,ifConstraint.script);    
        })
        self.writeScriptLine(tabCount, modelScript,contextModelScript.script);

        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");

        //self.writeScriptLine(tabCount, modelScript,"if !doOptimize"); //Do we need to skip for results??
        //tabCount++
        self.writeScriptLine(tabCount, modelScript, "for (t, context) in resultContexts");
        tabCount++;
        self.generateInitialVariableValueForResultContext(tabCount, modelScript, inputValues,  true);
        self.generateInitialVariableValueForResultContext(tabCount, modelScript, calculatedValues,  false);
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        //tabCount--;
        //self.writeScriptLine(tabCount, modelScript,"end");

        //Define objective
        self.writeScriptLine(tabCount, modelScript, "if doOptimize == nothing");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "@objective(pm.model, Max , 10)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"else");
        tabCount++;

        if(optimizationType =="Max"){
            self.writeScriptLine(tabCount, modelScript, "@objective(pm.model, Max, " + modelObjective + ")");
        }else{
            self.writeScriptLine(tabCount, modelScript, "@objective(pm.model, Min, "+ modelObjective + ")");
        }
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");

        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");

    }
    createMeasurementCharacteristics(valueModal, oldVpc, satisfaction, weight, recipientValue) {
        var self = this;
        var repId = DataManager.getDataManager().getRepositoryId(valueModal.id);
        var mcId;
        var valueName = valueModal.get('name');
        var nameVal;

        if (valueModal.get('type') !== "vdml_ValueAdd") {
            if (!valueModal.get('satisfactionLevel')) {
                if (satisfaction && satisfaction !== "") {
                    if (oldVpc && oldVpc.get('satisfactionLevel')) {
                        mcId = repId + window.utils.getSuffix(oldVpc.get('satisfactionLevel').id);
                    } else {
                        mcId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
                    }

                    nameVal = valueName + ' Satisfaction';
                    var satisfactionLevel = new ValueElement({ id: mcId, name: nameVal, description: nameVal, satisfactionLevelOwner: valueModal });
                    valueModal.set('satisfactionLevel', satisfactionLevel);
                }
            }
            if (!valueModal.get('percentageWeight')) {
                if (weight && weight !== "") {
                    if (oldVpc && oldVpc.get('percentageWeight')) {
                        mcId = repId + window.utils.getSuffix(oldVpc.get('percentageWeight').id);
                    } else {
                        mcId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
                    }

                    nameVal = valueName + ' Weight';
                    var percentageWeight = new ValueElement({ id: mcId, name: nameVal, description: nameVal, percentageWeightOwner: valueModal });
                    valueModal.set('percentageWeight', percentageWeight);
                }
            }
            if (!valueModal.get('recipientOpinion')) {
                if (recipientValue && recipientValue !== "") {
                    if (oldVpc && oldVpc.get('recipientOpinion')) {
                        mcId = repId + window.utils.getSuffix(oldVpc.get('recipientOpinion').id);
                    } else {
                        mcId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
                    }
                    var nameVal = valueName + ' Recipient Opinion';
                    var recipientMeasurement = new ValueElement({ id: mcId, name: nameVal, description: nameVal, recipientOpinionOwner: valueModal });
                    valueModal.set('recipientOpinion', recipientMeasurement);
                }
            }
        }
    };
    generateFooter(tabCount, modelScript) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "if abspath(PROGRAM_FILE) == @__FILE__");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "real_main()");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }
    generateJuliMain(tabCount, modelScript) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "Base.@ccallable function julia_main()::Cint");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "try");
        tabCount++
        self.writeScriptLine(tabCount, modelScript, "real_main()");
        tabCount--
        self.writeScriptLine(tabCount, modelScript, "catch");
        tabCount++
        self.writeScriptLine(tabCount, modelScript, "Base.invokelatest(Base.display_error, Base.catch_stack())");
        self.writeScriptLine(tabCount, modelScript, "return 1");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        self.writeScriptLine(tabCount, modelScript, "return 0");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }
    
    htmlEscape(val) {
        let str = undefined;
        if(typeof val == 'string'){
            str = val;
        }else{
            str = val.get("id");
            str = val.get("name") + str.substr(str.lastIndexOf('@') + 1)    

        }
        return String(str).replace(/[^a-zA-Z0-9_]/g, match => {
                if (match === '@') return 'a';  // Replace '@' with 'a'
                if (match === '-') return '_';  // Replace '-' with '_'
                return '';                      // Replace ' ', '#', '.', '(', ')' with ''
        });
    }
    generatePlanData(tabCount, modelScript,inputValues, calculatedValues) {
        var self = this;
        var planValues = [];
        var vdmlValues = [];
        var planData = {};
        planData.bm = {};
        tabCount++;
        var pckgs = new Object();
        var allValues = inputValues.concat(calculatedValues);
        var assignmentsScript = {script:""};
        allValues.forEach(val => {
            var sourceContainer = val.get('componentOwner');
            if(!sourceContainer){
                sourceContainer = val.get('valueAddOwner');
            }
            if(self.id == val.getNestedParent().id){
                planValues.push(val);
            }else{
                let np = val.getNestedParent();
                pckgs[np.id] = np;
                vdmlValues.push(val);
            }
        });
        planValues.forEach(plval =>{
            //planData[self.htmlEscape(plval.get("name") + plval.get("id"))] = self.htmlEscape(plval.get("name") + plval.get("id"));
            let isAtomic = plval.get("valueType") == ValueType.Atomic ? true : false;
            self.writeScriptLine(tabCount, assignmentsScript, "self[\"" + self.htmlEscape(plval) + "\"] = " + self.htmlEscape(plval) + (isAtomic ? "_var" : ""));
        })
        let pckgIds = Object.getOwnPropertyNames(pckgs);
        pckgIds.forEach(pckgId => {
            let pckg = pckgs[pckgId];
            let pbms =  pckg.get("businessModel");
            pbms.models.forEach(bm =>{
                let bmEscId = self.htmlEscape(bm);
                planData.bm[bmEscId] = {};
                let bmvss = bm.get("bmValueStream");
                
                bmvss.models.forEach(vs =>{
                    if(!planData.bm[bmEscId].vp){
                        planData.bm[bmEscId].vp = {};
                    }
                    let vp = vs.get("persuedProposition");
                    let vpEscId = self.htmlEscape(vp);
                    planData.bm[bmEscId].vp[vpEscId] = {};
                    planData.bm[bmEscId].vp[vpEscId]["value"] = {};
                    let vpcs = vp.get("component");
                    vpcs.forEach(vpc =>{
                        let isAtomic = vpc.get("valueType") == ValueType.Atomic ? true : false;
                        //planData.bm[bm.id].vp[vp.id]["values"][self.htmlEscape(vpc.get("name") + vpc.get("id"))] = self.htmlEscape(vpc.get("name") + vpc.get("id"));
                        self.writeScriptLine(tabCount, assignmentsScript, "values = self[\"bm\"][\"" + bmEscId + "\"][\"vp\"][\"" + vpEscId + "\"][\"value\"]");
                        self.writeScriptLine(tabCount, assignmentsScript, "values[\"" + self.htmlEscape(vpc.get("name") + vpc.get("id")) + "\"] = " +  self.htmlEscape(vpc) + (isAtomic ? "_var" : ""));
                    })
                    let vsacts = vs.get("valueStreamActivity");
                    vsacts.models.forEach(act => {
                        let actEscId = self.htmlEscape(act);
                        if(!planData.bm[bmEscId].vp[vpEscId]["activity"]){
                            planData.bm[bmEscId].vp[vpEscId]["activity"] = {}
                        }
                        if(!planData.bm[bmEscId].vp[vpEscId]["activity"][actEscId]){
                            planData.bm[bmEscId].vp[vpEscId]["activity"][actEscId] = {}
                        }
                        if(!planData.bm[bmEscId].vp[vpEscId]["activity"][actEscId]["value"]){
                            planData.bm[bmEscId].vp[vpEscId]["activity"][actEscId]["value"] = {}
                        }
                        let actports = act.get("containedPort");
                        actports.forEach(actprt =>{
                            if(actprt instanceof OutputPort){
                                let vadds = actprt.get("valueAdd");
                                vadds.forEach(vadd =>{
                                    let isAtomic = vadd.get("valueType") == ValueType.Atomic ? true : false;
                                    //planData.bm[bm.id].vp[vp.id]["activity"][act.get("id")]["values"][self.htmlEscape(vadd.get("name") + vadd.get("id"))] = self.htmlEscape(vadd.get("name") + vadd.get("id"));
                                    self.writeScriptLine(tabCount, assignmentsScript, "values = self[\"bm\"][\"" + bmEscId + "\"][\"vp\"][\"" + vpEscId + "\"][\"activity\"][\""+actEscId + "\"][\"value\"]");
                                    self.writeScriptLine(tabCount, assignmentsScript, "values[\"" + self.htmlEscape(vadd) + "\"] = " +  self.htmlEscape(vadd) + (isAtomic ? "_var" : ""));
                                })
                            }
                        })

                    })
                })
                let bmacts = bm.get("bmActivity");
                bmacts.models.forEach(bmact =>{
                    if(!planData.bm[bmEscId].activity){
                        planData.bm[bmEscId].activity = {};
                    }
                    let act = bmact.get("activity");
                    let actEscId = self.htmlEscape(act);
                    if(!planData.bm[bmEscId].activity[actEscId]){
                        planData.bm[bmEscId].activity[actEscId] = {};
                    }
                    if(!planData.bm[bmEscId].activity[actEscId]["value"]){
                        planData.bm[bmEscId].activity[actEscId]["value"] = {};
                    }
                    
                    let actports = act.get("containedPort");
                    actports.forEach(actprt =>{
                        if(actprt instanceof OutputPort){
                            let vadds = actprt.get("valueAdd");
                            vadds.forEach(vadd =>{
                                let isAtomic = vadd.get("valueType") == ValueType.Atomic ? true : false;
                                //planData.bm[bm.id]["activity"][act.get("id")]["values"][self.htmlEscape(vadd.get("name") + vadd.get("id"))] = self.htmlEscape(vadd.get("name") + vadd.get("id"));
                                self.writeScriptLine(tabCount, assignmentsScript, "values = self[\"bm\"][\"" + bmEscId + "\"][\"activity\"][\""+actEscId + "\"][\"value\"]");
                                self.writeScriptLine(tabCount, assignmentsScript, "values[\"" + self.htmlEscape(vadd) + "\"] = " +  self.htmlEscape(vadd) + (isAtomic ? "_var" : ""));
                            })
                        }
                    })
                })

                let bmFormmulas = bm.get("bmValueFormula");
                bmFormmulas.models.forEach(bmValueFormula =>{
                    let vf = bmValueFormula.get("valueFormula");
                    let vfEscId = self.htmlEscape(vf);
                    if(!planData.bm[bmEscId]["vp"]){
                        planData.bm[bmEscId]["vp"] = {};
                    }
                    if(!planData.bm[bmEscId].vp[vfEscId]){
                        planData.bm[bmEscId].vp[vfEscId] = {};
                    }
                    if(!planData.bm[bmEscId].vp[vfEscId]["value"]){
                        planData.bm[bmEscId].vp[vfEscId]["value"] = {};
                    }
                    let vpcs = vf.get("component");
                    vpcs.forEach(vpc =>{
                        let isAtomic = vpc.get("valueType") == ValueType.Atomic ? true : false;
                        //planData.bm[bm.id].vp[vp.id]["values"][self.htmlEscape(vpc.get("name") + vpc.get("id"))] = self.htmlEscape(vpc.get("name") + vpc.get("id"));
                        self.writeScriptLine(tabCount, assignmentsScript, "values = self[\"bm\"][\"" + bmEscId + "\"][\"vp\"][\"" + vfEscId + "\"][\"value\"]");
                        self.writeScriptLine(tabCount, assignmentsScript, "values[\"" + self.htmlEscape(vpc) + "\"] = " +  self.htmlEscape(vpc) + (isAtomic ? "_var" : ""));
                    })
                })
                let bmValueProps = bm.get("bmValueProposition");
                bmValueProps.models.forEach(bmValueProp =>{
                    let vp = bmValueProp.get("valueProposition");
                    let vfEscId = self.htmlEscape(vp);
                    if(!planData.bm[bmEscId]["vp"]){
                        planData.bm[bmEscId]["vp"] = {};
                    }
                    if(!planData.bm[bmEscId].vp[vfEscId]){
                        planData.bm[bmEscId].vp[vfEscId] = {};
                    }
                    if(!planData.bm[bmEscId].vp[vfEscId]["value"]){
                        planData.bm[bmEscId].vp[vfEscId]["value"] = {};
                    }
                    
                    let vpcs = vp.get("component");
                    vpcs.forEach(vpc =>{
                        let isAtomic = vpc.get("valueType") == ValueType.Atomic ? true : false;
                        //planData.bm[bm.id].vp[vp.id]["values"][self.htmlEscape(vpc.get("name") + vpc.get("id"))] = self.htmlEscape(vpc.get("name") + vpc.get("id"));
                        self.writeScriptLine(tabCount, assignmentsScript, "values = self[\"bm\"][\"" + bmEscId + "\"][\"vp\"][\"" + vfEscId + "\"][\"value\"]");
                        self.writeScriptLine(tabCount, assignmentsScript, "values[\"" + self.htmlEscape(vpc) + "\"] = " +  self.htmlEscape(vpc) + (isAtomic ? "_var" : ""));
                    })
                })
            })

        });
        let jsonData = JSON.stringify(planData);
        let escapedJsonData = jsonData.replace(/"/g, '\\"');
        self.writeScriptLine(tabCount, modelScript,"planData = " +  `JSON.parse("${escapedJsonData}")`);
        tabCount--;
        return assignmentsScript;
    }
    
    generateRealMain(tabCount, modelScript) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "function real_main()");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "pipeName = (length(ARGS) > 0)  ? ARGS[1] : \"p1234\"");
        self.writeScriptLine(tabCount, modelScript, "pipeDir = (length(ARGS) > 1)  ? ARGS[2] : \"\\\\\\\\.\\\\pipe\\\\\"");
        self.writeScriptLine(tabCount, modelScript, "pipePath = pipeDir * pipeName");
        self.writeScriptLine(tabCount, modelScript, "server = listen(pipePath)");

        self.writeScriptLine(tabCount, modelScript, "while true");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "client = nothing");
        self.writeScriptLine(tabCount, modelScript, "try");
        tabCount++
        self.writeScriptLine(tabCount, modelScript, "println(\"before accepted client\")");
        self.writeScriptLine(tabCount, modelScript, "client = accept(server)");
        self.writeScriptLine(tabCount, modelScript, "println(\"accepted client\")")

        self.writeScriptLine(tabCount, modelScript, "while isopen(client)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "try");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "data = readline(client)");
        self.writeScriptLine(tabCount, modelScript, "data = chomp(data)");
        self.writeScriptLine(tabCount, modelScript, "if data == nothing || data == \"\"");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "write(client,\"\\n\")");
        self.writeScriptLine(tabCount, modelScript, "continue");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        self.writeScriptLine(tabCount, modelScript, "println(\"Received: \", data)");

        self.writeScriptLine(tabCount, modelScript, "dataJson = JSON3.read(data)");
        self.writeScriptLine(tabCount, modelScript, "planScenario = dataJson[\"planScenario\"]");
        self.writeScriptLine(tabCount, modelScript, "scenariExecution = dataJson[\"scenarioExecution\"]");
        //self.writeScriptLine(tabCount, modelScript, "planData = JSON3.write(dataJson[\"planData\"]) |> JSON.parse");
        self.writeScriptLine(tabCount, modelScript, "inputs = dataJson[\"inputs\"]");
        self.writeScriptLine(tabCount, modelScript, "results = dataJson[\"results\"]");
        self.writeScriptLine(tabCount, modelScript, "doOptimize = haskey(dataJson,\"doOptimize\") ? dataJson[\"doOptimize\"] : nothing");
        self.writeScriptLine(tabCount, modelScript, "modelConfig = haskey(dataJson,\"modelConfig\") ? dataJson[\"modelConfig\"] : nothing");

        self.writeScriptLine(tabCount, modelScript, "solver = haskey(dataJson,\"solver\") ? dataJson[\"solver\"] : \"SCIP\"");

        self.writeScriptLine(tabCount, modelScript, "response = calculateScenario(planScenario,scenariExecution,inputs,results,planData,solver,doOptimize,modelConfig)");
        self.writeScriptLine(tabCount, modelScript, "write(client, JSON3.write(response)* \"\\n\")");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "catch e");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "vars_dict = Dict{String, Any}()");
        self.writeScriptLine(tabCount, modelScript, "if isa(e, JSON3.Error)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "vars_dict[\"error\"] = \"JSON parsing error: $e\"");
        self.writeScriptLine(tabCount, modelScript, "println(\"JSON parsing error: $e\")");
        // self.writeScriptLine(tabCount, modelScript, "write(client, \"Unexpected error: $e\\n\")");
        //self.writeScriptLine(tabCount, modelScript, "throw(e)");
        self.writeScriptLine(tabCount, modelScript, "write(client, JSON3.write(vars_dict) * \"\\n\")");
        self.writeScriptLine(tabCount, modelScript, "continue");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        self.writeScriptLine(tabCount, modelScript, "if isa(e, Base.IOError)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "println(\"client closed\") ");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "else");
        tabCount++;
        
        //self.writeScriptLine(tabCount, modelScript, "vars_dict[\"error\"] = e");
        self.writeScriptLine(tabCount, modelScript, "vars_dict[\"error\"] = \"$e\"");
        self.writeScriptLine(tabCount, modelScript, "println(\"Error reading/writing to client: \", e)");
        self.writeScriptLine(tabCount, modelScript, "stacktrace = catch_backtrace()");
        self.writeScriptLine(tabCount, modelScript, "io = IOBuffer()");
        self.writeScriptLine(tabCount, modelScript, "Base.show_backtrace(io, stacktrace)");
        self.writeScriptLine(tabCount, modelScript, "stacktrace_str = String(take!(io))");
        self.writeScriptLine(tabCount, modelScript, "vars_dict[\"stacktrace\"] = stacktrace_str");
        self.writeScriptLine(tabCount, modelScript, "write(client, JSON3.write(vars_dict) * \"\\n\")");
        //self.writeScriptLine(tabCount, modelScript, "throw(e)");
        // self.writeScriptLine(tabCount, modelScript, "println(stacktrace_str)");
        self.writeScriptLine(tabCount, modelScript, "continue");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        tabCount--
        self.writeScriptLine(tabCount, modelScript, "end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "finally");
        tabCount++
        self.writeScriptLine(tabCount, modelScript, "if client != nothing");
        tabCount++
        self.writeScriptLine(tabCount, modelScript, "close(client)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");

        self.writeScriptLine(tabCount, modelScript, "close(server)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }
    generateCalculateScenario(tabCount, modelScript) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "function calculateScenario(planscenario,scenarioexecution,inputs,results,planData,solverStr,doOptimize,modelConfig)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "periods = readPlanScenario(planscenario)");
        self.writeScriptLine(tabCount, modelScript, "startTime = DateTime(1970, 1, 1) + Millisecond(planscenario[\"startTime\"])");
        self.writeScriptLine(tabCount, modelScript, "contexts = readContexts(startTime,scenarioexecution,periods,inputs)");
        self.writeScriptLine(tabCount, modelScript, "resultContexts = readContexts(startTime,scenarioexecution,periods,results)");
        self.writeScriptLine(tabCount, modelScript, "for contextkey in keys(contexts)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "setProperty(contexts[contextkey])");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        self.writeScriptLine(tabCount, modelScript, "for contextkey in keys(resultContexts)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "setProperty(resultContexts[contextkey])");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");

        self.writeScriptLine(tabCount, modelScript, "pm = PlanModel(solverStr)");
        self.writeScriptLine(tabCount, modelScript, "pm.startTime = startTime");
        self.writeScriptLine(tabCount, modelScript, "for input in inputs");
        tabCount++;

        self.writeScriptLine(tabCount, modelScript, "if input[\"periodKind\"] != nothing");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "pm.period_kind = input[\"periodKind\"]");
        self.writeScriptLine(tabCount, modelScript, "break");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        self.writeScriptLine(tabCount, modelScript, "contexts[0] = readContext(Dict())");

        self.writeScriptLine(tabCount, modelScript, "buildModel(contexts,resultContexts,pm,planData,doOptimize,modelConfig)");
        self.writeScriptLine(tabCount, modelScript, "return getModelValues(pm)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }

    generateReadContexts(tabCount, modelScript) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "function readContexts(startTime,scenariExecution,periods::Vector{Int},inputs)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "scenarioContexts = Dict{Int,Context}()");
        self.writeScriptLine(tabCount, modelScript, "for period in periods");
        tabCount++;			
        self.writeScriptLine(tabCount, modelScript, "input = getPeriod(startTime,period,inputs)");
        self.writeScriptLine(tabCount, modelScript, "if input != nothing");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "scenarioContexts[period] = input");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "else");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "println(\"period input not found\")");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        self.writeScriptLine(tabCount, modelScript, "return scenarioContexts");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }

    generateGetPeriod(tabCount, modelScript) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "function getPeriod(startTime::DateTime,period::Int,contexts:: Any)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "for context in contexts");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "contextPeriod = context[\"period\"]");
        self.writeScriptLine(tabCount, modelScript, "contextYear = context[\"year\"]");
        self.writeScriptLine(tabCount, modelScript, "contextPeriodKind = context[\"periodKind\"]");
        self.writeScriptLine(tabCount, modelScript, "scenarioPeriod = calculate_period_number(startTime, contextYear, contextPeriodKind, contextPeriod)");
        self.writeScriptLine(tabCount, modelScript, "if period == scenarioPeriod");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "return readContext(context)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        self.writeScriptLine(tabCount, modelScript, "return nothing");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }
    generateParseValue(tabCount, modelScript){
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "function parseValue(val)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "if val === nothing || val === \"\" || val === \"undefined\"");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "return nothing");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        self.writeScriptLine(tabCount, modelScript, "if isa(val,String)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "return parse(Float64,val)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        self.writeScriptLine(tabCount, modelScript, "return val");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }
    generateReadContext(tabCount, modelScript, inputValues, calculatedValues) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "function readContext(data)::Context");
        tabCount++;
        inputValues.forEach(value => {
            let escValId = self.htmlEscape(value);
            self.writeScriptLine(tabCount, modelScript, escValId + " =  get(data, \"" + value.get("id") + "\", nothing)");
        })
        calculatedValues.forEach(value => {
            let escValId = self.htmlEscape(value);
            self.writeScriptLine(tabCount, modelScript, escValId + " =  get(data, \"" + value.get("id") + "\", nothing)");
        })
        self.writeScriptLine(tabCount, modelScript, "return Context(");
        for (let i = 0; i < inputValues.length; i++) {
            let value = inputValues[i];
            let escValId = self.htmlEscape(value);
            if (i < inputValues.length - 2 || calculatedValues.length > 0) {
                self.writeScriptLine(tabCount, modelScript, "parseValue(" + escValId + "),");
            } else {
                self.writeScriptLine(tabCount, modelScript, "parseValue(" + escValId + ")");
            }
        }
        for (let i = 0; i < calculatedValues.length; i++) {
            let value = calculatedValues[i];
            let escValId = self.htmlEscape(value);
            if (i < calculatedValues.length - 1) {
                self.writeScriptLine(tabCount, modelScript, "parseValue(" + escValId + "),");
            } else {
                self.writeScriptLine(tabCount, modelScript, "parseValue(" + escValId + ")");
            }
        }
        self.writeScriptLine(tabCount, modelScript, ")");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }

    generateReadPlanScenario(tabCount, modelScript) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "function readPlanScenario(planScenario)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "scenarioPeriods = Vector{Int}()");

        self.writeScriptLine(tabCount, modelScript, "steps = planScenario[\"step\"]");
        self.writeScriptLine(tabCount, modelScript, "firstStepId = planScenario[\"firstStep\"]");
        self.writeScriptLine(tabCount, modelScript, "step = getStep(firstStepId, steps)");

        self.writeScriptLine(tabCount, modelScript, "while step !== nothing");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "startPeriod = step[\"startPeriod\"]");
        self.writeScriptLine(tabCount, modelScript, "noPeriods = step[\"noPeriods\"]");
        self.writeScriptLine(tabCount, modelScript, "for i in 1:noPeriods")
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "push!(scenarioPeriods, startPeriod + i - 1)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        self.writeScriptLine(tabCount, modelScript, "nextStepId = nothing");
        self.writeScriptLine(tabCount, modelScript, "if haskey(step,\"next\")");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "nextStepId = step[\"next\"]");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        self.writeScriptLine(tabCount, modelScript, "step = nextStepId === nothing ? nothing : getStep(nextStepId, steps)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        self.writeScriptLine(tabCount, modelScript, "return scenarioPeriods");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }

    generateGetStep(tabCount, modelScript) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "function getStep(stepId::String, steps)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "for step in steps");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "if step[\"id\"] == stepId");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "return step");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        self.writeScriptLine(tabCount, modelScript, "return nothing");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }


    generateGetModelValues(tabCount, modelScript) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "function getModelValues(pm::PlanModel)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "model = pm.model");
        self.writeScriptLine(tabCount, modelScript, "vars_dict = Dict{String, Any}()");
        self.writeScriptLine(tabCount, modelScript, "if termination_status(model) == MOI.OPTIMAL || termination_status(model) == MOI.LOCALLY_SOLVED");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "for v in all_variables(model)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "varName = name(v)");
        self.writeScriptLine(tabCount, modelScript, "indexVal = nothing");
        self.writeScriptLine(tabCount, modelScript, "pos = findfirst(==('['),varName)");
        self.writeScriptLine(tabCount, modelScript, "if pos == nothing");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "continue");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "else");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "indexVal = varName[pos:end]");
        self.writeScriptLine(tabCount, modelScript, "varName = varName[1:pos-1]");
        self.writeScriptLine(tabCount, modelScript, "if haskey(valueIds,varName)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "vars_dict[valueIds[varName][\"id\"] * indexVal] = round(value(v),digits=valueIds[varName][\"rounding\"])");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "else");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "if pos > 4");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "varName = varName[1:pos-5]");
        self.writeScriptLine(tabCount, modelScript, "if haskey(valueIds,varName)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "vars_dict[valueIds[varName][\"id\"] * \"_var\" * indexVal] = round(value(v),digits=valueIds[varName][\"rounding\"])");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        self.writeScriptLine(tabCount, modelScript, "vars_dict[\"status\"] = primal_status(model)");
        self.writeScriptLine(tabCount, modelScript, "vars_dict[\"summary\"] = solution_summary(model)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "else");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "vars_dict[\"error\"] = primal_status(model)");
        self.writeScriptLine(tabCount, modelScript, "vars_dict[\"summary\"] = solution_summary(model;verbose = true)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        self.writeScriptLine(tabCount, modelScript, "println(\"vars_dict:\",vars_dict)");
        self.writeScriptLine(tabCount, modelScript, "return vars_dict");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }
    generateBuildModel(tabCount, modelScript) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "function buildModel(contexts,resultContexts,pm::PlanModel,planData,doOptimize,modelConfig)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "periods = collect(keys(contexts))");
        self.writeScriptLine(tabCount, modelScript, "resultPeriods = collect(keys(resultContexts))");
        self.writeScriptLine(tabCount, modelScript, "periods = [periods;resultPeriods]");
        self.writeScriptLine(tabCount, modelScript, "define_constraints(pm,periods,contexts,resultContexts,planData,doOptimize)");

        //self.writeScriptLine(tabCount, modelScript, "set_optimizer_attribute(pm.model, \"display/verblevel\", 5)");
        self.writeScriptLine(tabCount, modelScript, "set_optimizer_attribute(pm.model, \"limits/time\", 120)");
        self.writeScriptLine(tabCount, modelScript, "set_optimizer_attribute(pm.model, \"limits/gap\", 0.1)");
        

        self.writeScriptLine(tabCount, modelScript,"if modelConfig != nothing");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"for (key, value) in pairs(modelConfig)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript,"println(\"setting optimizer attribute:$(string(key)) : $(string(value))\")");
        self.writeScriptLine(tabCount, modelScript,"set_optimizer_attribute(pm.model, string(key), value)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript,"end");

        self.writeScriptLine(tabCount, modelScript, "optimize!(pm.model)");
        tabCount--
        self.writeScriptLine(tabCount, modelScript, "end");
    }

    generateStepConstraints(tabCount, modelScript, valueContexts, inputValues, valueBounds,scenarioId) {
        var self = this;
        let keys = Object.getOwnPropertyNames(valueContexts);
        let firstStep = true;
        let ifConstraintScripts = [];
        for (let key in keys) {
            let altContext = valueContexts[key];
            let altId = altContext.id;
            let range = altContext["startPeriod"] + ":" + (altContext["startPeriod"] + altContext["noPeriods"] - 1)
            //self.writeScriptLine(tabCount, modelScript, firstStep == true? "if t==1" : "elseif" + " t in " + range);
            if(firstStep == false){
                tabCount--;
            }
            self.writeScriptLine(tabCount, modelScript, firstStep == true ? ("if t in " + range) : ("elseif" + " t in " + range));
            tabCount++;
            let valueExpressions = {};
            altContext.valueElementContext.forEach(value => {
                let expression = value.getValueExpressionForScenarioAlt(scenarioId,altId);
                let hasOffset = false;
                if(expression && expression.get("offset")){
                    hasOffset = true;
                }
                valueExpressions[value.id] = expression;
                let isAtomic = value.get("valueType") == ValueType.Atomic;
                if(!isAtomic){
                    if(hasOffset){
                        self.writeScriptLine(tabCount, modelScript,"if t > " + expression.get("offset"));
                        tabCount++;
                    }
                    self.generateInitialVariableValueForCalculatedValue(tabCount, modelScript,altContext.id, value, valueBounds);
                    if(hasOffset){
                        tabCount--
                        self.writeScriptLine(tabCount, modelScript,"end");
                    }
                }
            });
            self.writeScriptLine(tabCount, modelScript,"if doOptimize == nothing || !doOptimize");
            tabCount++
            altContext.valueElementContext.forEach(value => {
                let expression = valueExpressions[value.id];
                let hasOffset = false;
                if(expression && expression.get("offset")){
                    hasOffset = true;
                }
                let isAtomic = value.get("valueType") == ValueType.Atomic;
                if(hasOffset){
                    self.writeScriptLine(tabCount, modelScript,"if t > " + expression.get("offset"));
                    tabCount++;
                }
                self.generateInitialVariableValueForContextCalculation(tabCount, modelScript,altContext.id, value, valueBounds, isAtomic);
                if(hasOffset){
                    tabCount--
                    self.writeScriptLine(tabCount, modelScript,"end");
                }
            });
            tabCount--;
            self.writeScriptLine(tabCount, modelScript,"else");
            tabCount++;
            altContext.valueElementContext.forEach(value => {
                let expression = valueExpressions[value.id];
                let hasOffset = false;
                if(expression && expression.get("offset")){
                    hasOffset = true;
                }
                let isAtomic = value.get("valueType") == ValueType.Atomic;
                if(hasOffset){
                    self.writeScriptLine(tabCount, modelScript,"if t > " + expression.get("offset"));
                    tabCount++;
                }
                self.generateInitialVariableValueForContextOptimization(tabCount, modelScript,altContext.id, value, valueBounds, isAtomic);
                if(hasOffset){
                    tabCount--
                    self.writeScriptLine(tabCount, modelScript,"end");
                }
            });
            tabCount--;
            self.writeScriptLine(tabCount, modelScript,"end");

            altContext.valueElementContext.forEach(value => {
                let expression = valueExpressions[value.id];
                let hasOffset = false;
                if(expression && expression.get("offset")){
                    hasOffset = true;
                }
                let context = value.getValueContext(altContext.id);
                let satisfactionLevelOwner = value.get("satisfactionLevelOwner");
                let isSatisfaction = satisfactionLevelOwner ? true: false;
                let escValId = self.htmlEscape(value);
                if(isSatisfaction){
                    if(!context){
                        var formula = value.getValueFormula(altContext.id);
                        context = formula.getParent();
                    }
                    let jsonStr = context.getExpression();
                    self.writeScriptLine(tabCount, modelScript,escValId + "_intervals = JSON.parse(\"\"\"" + jsonStr + "\"\"\")");
                }
                if ((value.get("valueType") != ValueType.Atomic)) {
                     let result = this.getValueExpression(context, value, altContext.id);
                     let valueExpressionStr = result.code;
                    if(result.containsIfElse || result.containsTernary){
                        //self.writeScriptLine(tabCount, modelScript,escValId + " = " + escValId + "IfConstraint(pm.model,t)");
                        self.writeScriptLine(tabCount, modelScript,escValId + self.htmlEscape(altId) + "IfConstraint(pm.model,t)");
                        let ifConstraint = new Object();
                        ifConstraint.script = "";
                        let valueBound;
                        if (valueBounds[altId] && valueBounds[altId][escValId] != null) {
                            valueBound = valueBounds[altId][escValId];
                        }
                        self.getifConstraintScript(tabCount,ifConstraint,escValId + self.htmlEscape(altId),valueExpressionStr,valueBound);
                        ifConstraintScripts.push(ifConstraint);
                    }else{
                        if(valueExpressionStr){
                            if(hasOffset){
                                self.writeScriptLine(tabCount, modelScript,"if t > " + expression.get("offset"));
                                tabCount++;
                            }
                            self.writeScriptLine(tabCount, modelScript,valueExpressionStr);
                            if(hasOffset){
                                tabCount--
                                self.writeScriptLine(tabCount, modelScript,"end");
                            }
                        }
                    }
                }
            });
            firstStep = false;
            valueExpressions = null;
        }
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
        return ifConstraintScripts;
    }
    getifConstraintScript(tabCount, ifConstraint,escValId,valueExpressionStr,valueBound){
        let self = this;
        self.writeScriptLine(tabCount,ifConstraint,"function " + escValId + "IfConstraint(model,t)");
        tabCount++
        if(valueBound && valueBound.bigM){
            self.writeScriptLine(tabCount,ifConstraint,"M = " + valueBound.bigM);
        }else{
            self.writeScriptLine(tabCount,ifConstraint,"M = 999");
        }
        
        self.writeScriptLine(tabCount,ifConstraint,valueExpressionStr);
        tabCount--;
        self.writeScriptLine(tabCount,ifConstraint,"end");
    }
    getConstraintStr(valueExpressionStr){
        var self = this;
        let exprProps = self.isNonlinearExpression(valueExpressionStr);
        if( exprProps.isNonlinear){
            return "@NLconstraint"
        }else{
            return "@constraint"
        }
    }
    isNonlinearExpression(expr) {
        const self = this;
    
        // Helper function to identify variables (simplified regex check)
        function isVariable(token) {
            return token && /^[a-zA-Z_][a-zA-Z_0-9\[\]]*$/.test(token);
        }
    
        // List of known nonlinear functions, with special handling for 'round'
        const nonlinearFunctions = ['sin', 'cos', 'tan', 'log', 'exp', 'sqrt', 'round'];
    
        // Recursive function to check for nonlinearity in sub-expressions
        function evaluateOperators(expr, operators) {
            let binOperators = ['*', '/','+','-','^',];
            const terms = self.splitExprWithOperators(expr, binOperators);
    
            let variables = []; // Tracks variables in this level of expression
            let containsNonlinearOperator = false;
    
            for (let term of terms) {
                term = term.trim();
    
                // Check for parentheses and make recursive call if needed
                if (term.startsWith('(') && term.endsWith(')')) {
                    const innerExpr = term.slice(1, -1);
                    const { isNonlinear, foundVars } = self.isNonlinearExpression(innerExpr);
    
                    // Accumulate results from the recursive context
                    if (isNonlinear) return { isNonlinear: true, foundVars: [] }; // If inner is nonlinear, whole expr is nonlinear
                    variables = [...variables, ...foundVars];
                } else if (isVariable(term)) {
                    variables.push(term);
                } else if (self.isOperator(operators,term)) {
                    containsNonlinearOperator = true;
                } else if (nonlinearFunctions.some(fn => term.startsWith(fn + '('))) {
                    // Special handling for the 'round' function
                    if (term.startsWith('round(')) {
                        const roundArg = term.slice(6, -1).trim();
                        if (isVariable(roundArg)) {
                            return { isNonlinear: true, foundVars: [] };
                        }
                    } else {
                        return { isNonlinear: true, foundVars: [] }; // Nonlinear if any known nonlinear function is found
                    }
                } else if (term == expr){
                    continue;
                }
            }
    
            // Nonlinear if we have multiple variables with multiplication or division operators
            if (containsNonlinearOperator && variables.length > 1) {
                return { isNonlinear: true, foundVars: variables };
            }
    
            return { isNonlinear: false, foundVars: variables };
        }
    
        // Check for power operations (^) as nonlinear
        if (expr.includes('^')) {
            const parts = expr.split('^');
            if (isVariable(parts[0].trim()) && parts[1].trim() !== '1') {
                return { isNonlinear: true, foundVars: [] };
            }
        }
    
        // Check for nonlinearity in multiplication or division between variables
        const result = evaluateOperators(expr, ['*', '/']);
        return result;
    }
    
    
    isOperator(operators, token){
        for(let operator in operators){
            if(operators[operator] == token){
                return true;
            }
        }
        return false;
    }
    // Split by operators without breaking parenthesized groups
    splitExprWithOperators(expr, operators) {
        const operatorRegex = new RegExp(`(?<!\\([^)]*)(${operators.map(op => `\\${op}`).join('|')})(?![^()]*\\))`);
        return expr.split(operatorRegex).filter(Boolean);
    }
    
    // Helper to identify variables, returning false if token is undefined
    isVariable(token) {
        return token !== undefined && /^[a-zA-Z_][a-zA-Z_0-9\[\]]*$/.test(token);
    }
    
    // Helper to identify constants (e.g., numbers)
    isConstant(token) {
        return /^\d+(\.\d+)?$/.test(token);
    }
    
    
    
        
    getJuliaExpr(input){
        var self = this;
        const { ParseTreeWalker } = antlr4.tree;
        const chars = new antlr4.InputStream(input);
        const lexer = new ExprLangLexer(chars);
        const tokens = new antlr4.CommonTokenStream(lexer);
        const parser = new ExprLangParser(tokens);
        const tree = parser.program();
        // Create a generic parse tree walker that can trigger callbacks
        const walker = new ParseTreeWalker();

        // Walk the tree created during the parse and trigger callbacks
        const listener = new ExprLangToJuliaListener();
        walker.walk(listener, tree);

        // Get the result
        return listener.getResult();

    }
    /*setPeriodForExpression(expresionStr, escapeStr, isAtomic,skipIndex) {
        // Match escapeStr or ["escapeStr"] and handle cases with or without .period(
        let regex = new RegExp(`(${escapeStr}|\\["${escapeStr}"\\])(\\.period\\()?(?!\\.period\\()`, "g");
    
        return expresionStr.replace(regex, (match, p1, p2) => {
            // If .period( is found (p2 is not undefined), replace accordingly
            if (p2) {
                return isAtomic ? escapeStr + "_var.period(" : escapeStr + ".period(";
            } else {
                if(skipIndex){
                    return isAtomic ? escapeStr + "_var" : escapeStr ;
                }else{
                    return isAtomic ? escapeStr + "_var[t]" : escapeStr + "[t]";
                }
                
            }
        });
    }*/

    setPeriodForExpression(expresionStr, escapeStr, isAtomic, skipIndex) {
        // Match escapeStr or ["escapeStr"] and optionally .period( or .pSum(, .pAvg(, .pMin(, .pMax(
        let regex = new RegExp(
            `(${escapeStr}|\\["${escapeStr}"\\])(\\.(period|pSum|pAvg|pMin|pMax)\\()?`,
            "g"
        );
    
        return expresionStr.replace(regex, (match, p1, p2, method) => {
            // If .period( or other specified methods are found
            if (method) {
                return isAtomic ? `${escapeStr}_var.${method}(` : `${escapeStr}.${method}(`;
            } else {
                // Default case for no methods
                if (skipIndex) {
                    return isAtomic ? `${escapeStr}_var` : escapeStr;
                } else {
                    return isAtomic ? `${escapeStr}_var[t]` : `${escapeStr}[t]`;
                }
            }
        });
    }
        
        

    parseIfElse(input) {
        input = input.trim();
    
        // Step 1: Handle ternary expressions
        const ternaryRegex = /(.+)\s*\?\s*(.+)\s*:\s*(.+)/;
        const ternaryMatch = input.match(ternaryRegex);
    
        if (ternaryMatch) {
            const condition = ternaryMatch[1].trim();
            const ifExpr = ternaryMatch[2].trim();
            const elseExpr = ternaryMatch[3].trim();
            
            // Convert ternary to if-else and recursively call parseIfElse
            input = `if ${condition}\n${ifExpr}\nelse\n${elseExpr}\nend`;
        }
    
        // Step 2: Extract if-elseif-else structure
        const ifRegex = /if\s*(.+)\s*\n\s*(.+)/;   // Adjusted regex for Julia
        const elseRegex = /else\s*\n\s*(.+)\s*\n\s*end/;  // Handles else-end block
        const elseifRegex = /elseif\s*(.+)\s*\n\s*(.+)/g;  // Handles elseif without "then"
    
        let match = input.match(ifRegex);
        if (!match) {
            throw new Error("Invalid if-else statement.");
        }
    
        const booleanExprs = [match[1].trim()];
        const ifExprs = [match[2].trim()];
    
        let elseifMatch;
        while ((elseifMatch = elseifRegex.exec(input)) !== null) {
            booleanExprs.push(elseifMatch[1].trim());
            ifExprs.push(elseifMatch[2].trim());
        }
    
        const elseMatch = input.match(elseRegex);
        if (!elseMatch) {
            throw new Error("Missing else statement.");
        }
        const elseExpr = elseMatch[1].trim();
    
        // Step 3: Generate Julia code
        const M = "99999999";  // Big-M constant
        const binaryVars = booleanExprs.map((_, i) => `y${i + 1}`); // y1, y2, ...
    
        let juliaCode = `
                # Define binary variables for each condition
                ${binaryVars.map(y => `@variable(model, ${y}, Bin)`).join("\n")}
                
                # Big-M constant
                M = ${M}
                `;
    
        // Add constraints for each if/elseif condition
        booleanExprs.forEach((cond, i) => {
            juliaCode += `
                # Condition: ${cond}
                @constraint(model, ${cond} - M * (1 - ${binaryVars[i]}) ≥ 0)  # Activate when ${binaryVars[i]} = 1
                @constraint(model, ${cond} + M * ${binaryVars[i]} ≥ 0)        # Activate when ${binaryVars[i]} = 0
                `;
        });
    
        // Add constraints for the if/elseif branches
        ifExprs.forEach((expr, i) => {
            juliaCode += `
                # If branch (or elseif): z = ${expr}
                @constraint(model, z ≤ ${expr} + M * (1 - ${binaryVars[i]}))  # Activate if ${binaryVars[i]} = 1
                @constraint(model, z ≥ ${expr} - M * (1 - ${binaryVars[i]}))  # Activate if ${binaryVars[i]} = 1
                `;
        });
    
        // Add constraints for the else branch
        juliaCode += `
            # Else branch: z = ${elseExpr}
            @constraint(model, z ≤ ${elseExpr} + M * ${binaryVars[binaryVars.length - 1]})  # Activate if no condition is true
            @constraint(model, z ≥ ${elseExpr} - M * ${binaryVars[binaryVars.length - 1]})  # Activate if no condition is true
            `;
    
        return juliaCode;
    }
    testIfExpresssion(){
        // Example usage:
        const inputIfElse = `
        if x >= 10
            z = 2 * x
        elseif x >= 5
            z = x + 1
        else
            z = x + 5
        end
        `;
        
        const generatedJuliaCode = this.parseIfElse(inputIfElse);
        console.log(generatedJuliaCode);
    }    
    
    getValueExpression(context,value,altId){
        var self = this;
        //let value = context.get("contextOwner");
        let escValId = self.htmlEscape(value);
        let satisfactionLevelOwner = value.get("satisfactionLevelOwner");
        let isSatisfaction = satisfactionLevelOwner ? true: false;
        if(!context){
            var formula = value.getValueFormula(altId);
            context = formula ? formula.getParent() : null;
        }
        let aggregatedFromValues = context ? context.get("aggregatedFromValue") : [];

        let expresionStr = context ? context.getExpression() : null;
        let result = new Object();
        if(isSatisfaction){
            let escSatisfactionLevelOwnerId = self.htmlEscape(satisfactionLevelOwner);
            let isAtomic = satisfactionLevelOwner.get("valueType") == ValueType.Atomic ? true : false;
            expresionStr = "getRank(pm.model," + escValId + "_intervals," + escSatisfactionLevelOwnerId + (isAtomic ? "_var" : "")+ "[t])"; //ToDO remove [t]
            if(expresionStr.indexOf('if ') < 0 && expresionStr.indexOf('if(') < 0  && expresionStr.indexOf('==') >= 0){
                result.code = self.getConstraintStr(expresionStr) +  "(pm.model, " +  expresionStr + ")"    
            }else{
                result.code = self.getConstraintStr(expresionStr) +  "(pm.model, " + escValId + "[t] == " + expresionStr + ")"
            }
            
            return result;
        }
        return self.getOptmizedExpression(expresionStr,aggregatedFromValues.models,value);
    }
    getOptmizedExpression(expresionStr,aggregatedFromValues,value,skipIndex){
        var self = this;
        let escValId;
        if(value){
            escValId = self.htmlEscape(value);
        }
        let result = new Object();
        if(expresionStr != undefined){
            // escape aggregatefrom
            expresionStr = expresionStr.replaceAll("currentPeriod()","currentPeriod(t)");
            expresionStr = expresionStr.replaceAll("previousPeriod()","previousPeriod(t)");
            // const pattern = /indexYearPeriod\((.*?),\s*(.*?)\)/g;
            // // Replacement string
            // const replacement = 'indexYearPeriod('+ self.get("startTime") + '$1, $2, \"' + self.get("periodKind") + '\")';
            // expresionStr = expresionStr.replace(pattern,replacement);
            for(let i = 0;i<aggregatedFromValues.length;i++){
                let aggregatedFromValue = aggregatedFromValues[i];
                //let aggregatedFromValOwner = aggregatedFromValue.get("contextOwner");
                expresionStr = self.setPeriodForExpression(expresionStr,self.htmlEscape(aggregatedFromValue),aggregatedFromValue.get("valueType") == ValueType.Atomic,skipIndex)
            }
            if(value){
                if( aggregatedFromValues.length == 0){
                    let aggregatedFromValues = value.get("aggregatedFrom");
                    for (let i = 0; i < aggregatedFromValues.models.length; i++) {
                        let aggregatedFromValue = aggregatedFromValues.models[i];
                        expresionStr = self.setPeriodForExpression(expresionStr,self.htmlEscape(aggregatedFromValue),aggregatedFromValue.get("valueType") == ValueType.Atomic,skipIndex)
                    }
                }
                expresionStr = self.setPeriodForExpression(expresionStr,self.htmlEscape(value),value.get("valueType") == ValueType.Atomic,skipIndex)
            }
            result = self.getJuliaExpr(expresionStr);
            if(value && !result.containsIfElse && !result.containsTernary){
                if(expresionStr.indexOf('==') >= 0){
                    result.code =  self.getConstraintStr(result.code) +  "(pm.model, " + result.code + ")"
                }else{
                    result.code =  self.getConstraintStr(result.code) +  "(pm.model, " + escValId + "[t] == " + result.code + ")"
                }
                
            }
            
        }
        return result;
    }
    getRandomFloat(min, max) {
        // Default to 0 if min is not provided, and to 1 if max is not provided
        min = (typeof min === 'number') ? min : 0;
        max = (typeof max === 'number') ? max : 99;

        if (min > max) [min, max] = [max, min]; // Swap if min is greater than max

        return Math.random() * (max - min) + min;
    }
    generateInitialVariableValueForResultContext(tabCount, modelScript, values, isInputs) {
        var self = this;
        values.forEach(value => {
            let escValId = self.htmlEscape(value);
            //self.writeScriptLine(tabCount, modelScript, escValId + " = context." + escValId);
            self.writeScriptLine(tabCount, modelScript, "pm." + escValId + "_constraints[t] = @constraint(pm.model, " + "context." + escValId + " <= " + escValId + (isInputs ? "_var" : "") +  "[t]  <= " + "context." + escValId + ")");
        })
       

    }

    generateInitialVariableValueForCalculatedValue(tabCount, modelScript,altId, value, valueBounds, isInputs) {
        var self = this;
        let escValId = self.htmlEscape(value);
        let maxBound = undefined;
        let minBound = undefined;
        let equalValue = undefined;
        let hasConstraint = false;
        if (valueBounds[altId] && valueBounds[altId][escValId] != null) {
            maxBound = valueBounds[altId][escValId].maxBound != undefined ? valueBounds[altId][escValId].maxBound : undefined;
            minBound = valueBounds[altId][escValId].minBound != undefined ? valueBounds[altId][escValId].minBound : undefined;
            equalValue = valueBounds[altId][escValId].equalValue != undefined ? valueBounds[altId][escValId].equalValue : undefined;
            
            //let boundConstraintScript = "pm." + escValId + "_constraints[t] = @constraint(pm.model,";
            let boundConstraintScript = "@constraint(pm.model,";
            if (equalValue !== undefined && equalValue !== "") {
                boundConstraintScript = boundConstraintScript + escValId + "[t] == " + equalValue;
                hasConstraint = true;
            }else{
                if (minBound !== undefined  && minBound !== "") {
                    boundConstraintScript = boundConstraintScript + minBound + " <= ";
                    hasConstraint = true;
                }
                boundConstraintScript = boundConstraintScript + escValId + "[t]";
                if (maxBound !== undefined && maxBound !== "") {
                    boundConstraintScript = boundConstraintScript + " <= " + maxBound;
                    hasConstraint = true;
                }
            }
            boundConstraintScript = boundConstraintScript + ")";
            if(hasConstraint){
                self.writeScriptLine(tabCount, modelScript, boundConstraintScript);
            }
        }
        self.writeScriptLine(tabCount, modelScript, "set_start_value(" + escValId + "[t]," + self.getRandomFloat(maxBound, minBound) + ")");
    }
    generateInitialVariableValueForContextCalculation(tabCount, modelScript,altId, value, valueBounds, isInputs) {
        var self = this;
        let escValId = self.htmlEscape(value);
        if (isInputs) {
            //self.writeScriptLine(tabCount, modelScript, escValId + " = context." + escValId);
            self.writeScriptLine(tabCount, modelScript, "pm." + escValId + "_constraints[t] = @constraint(pm.model, context." + escValId + " <= " + escValId + "_var[t] <= context." + escValId + ")");
        }

    }
    generateInitialVariableValueForContextOptimization(tabCount, modelScript,altId, value, valueBounds, isInputs) {
        var self = this;
        let escValId = self.htmlEscape(value);
        let maxBound = undefined;
        let minBound = undefined;
        let equalValue = undefined;
        let hasConstraint = false;
        if (isInputs) {
            if (valueBounds[altId] && valueBounds[altId][escValId] != null) {
                maxBound = valueBounds[altId][escValId].maxBound != undefined ? valueBounds[altId][escValId].maxBound : undefined;
                minBound = valueBounds[altId][escValId].minBound != undefined ? valueBounds[altId][escValId].minBound : undefined;
                equalValue = valueBounds[altId][escValId].equalValue != undefined ? valueBounds[altId][escValId].equalValue : undefined;
                
                //let boundConstraintScript = "pm." + escValId + "_constraints[t] = @constraint(pm.model,";
                let boundConstraintScript = "@constraint(pm.model,";
                if (equalValue !== undefined && equalValue !== "") {
                    boundConstraintScript = boundConstraintScript + escValId + "_var[t] == " + equalValue;
                    hasConstraint = true;
                }else{
                    if (minBound !== undefined && minBound !== "") {
                        boundConstraintScript = boundConstraintScript + minBound + " <= ";
                        hasConstraint = true;
                    }
                    boundConstraintScript = boundConstraintScript + escValId + "_var[t]";
                    if (maxBound !== undefined && maxBound !== "") {
                        boundConstraintScript = boundConstraintScript + " <= " + maxBound;
                        hasConstraint = true;
                    }
                }
                boundConstraintScript = boundConstraintScript + ")";
                if(hasConstraint){
                    self.writeScriptLine(tabCount, modelScript, boundConstraintScript);
                }
                
            }
            self.writeScriptLine(tabCount, modelScript, "set_start_value(" + escValId + "_var[t],context." + escValId + ")");
        }
    }
    generateUpdateInput(tabCount, modelScript) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "function updateInput(prop::String,period::Int64,pm::PlanModel,val::Float64)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "model = pm.model");
        self.writeScriptLine(tabCount, modelScript, "constrainField = getFieldByName(pm, prop * \"_constraints\")");
        self.writeScriptLine(tabCount, modelScript, "delete(model,constrainField[period])");
        self.writeScriptLine(tabCount, modelScript, "propVar = JuMP.variable_by_name(model, prop * \"_var[$period]\")");
        self.writeScriptLine(tabCount, modelScript, "constrainField[period]  = @constraint(model, val <= propVar <= val)");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }

    generateGetFieldByName(tabCount, modelScript) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "function getFieldByName(pm::PlanModel, field_name::String)");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "return getfield(pm, Symbol(field_name))");
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }

    generatePlanModelConstructor(tabCount, modelScript, inputValues,calculatedValues,valueContexts) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "function PlanModel(solverStr)");
        tabCount++;
        let script = "PlanModel(Model(getSolver(solverStr)), missing,missing"
        var alts = Object.getOwnPropertyNames(valueContexts);
        let maxPeriods = 0;
        let noPeriods = 0;
        for (let alt in alts) {
            let altData = valueContexts[alt];
            if(maxPeriods == 0){
                maxPeriods = altData["startPeriod"] + altData["noPeriods"];
            }else{
                let noPeriods = altData["startPeriod"] + altData["noPeriods"];
                if(maxPeriods < noPeriods){
                    maxPeriods = noPeriods
                }
            }
        }
        for (let i = 0; i < inputValues.length; i++){
            script = script + ", Vector{ConstraintRef}(undef, " + maxPeriods + ")"
        }
        for (let j = 0; j < calculatedValues.length; j++){
            script = script + ", Vector{ConstraintRef}(undef, " + maxPeriods + ")"
        }   
        script = script + ")";
        self.writeScriptLine(tabCount, modelScript, script);
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }
    generatePlanModelConstraints(tabCount, modelScript, inputValues, calculatedValues) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "mutable struct PlanModel");
        tabCount++;
        self.writeScriptLine(tabCount, modelScript, "model::Model");
        self.writeScriptLine(tabCount, modelScript, "startTime::Union{DateTime, Missing}");
        self.writeScriptLine(tabCount, modelScript, "period_kind::Union{String, Missing}");
        inputValues.forEach(value => {
            let escValId = self.htmlEscape(value);
            self.writeScriptLine(tabCount, modelScript, escValId + "_constraints::Vector{ConstraintRef}");
        })
        calculatedValues.forEach(value => {
            let escValId = self.htmlEscape(value);
            self.writeScriptLine(tabCount, modelScript, escValId + "_constraints::Vector{ConstraintRef}");
        })
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }

    generateSetPropertyMethod(tabCount, modelScript, inputValues, calculatedValues) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "function setProperty(context::Context)");
        tabCount++;
        inputValues.forEach(value => {
            let escValId = self.htmlEscape(value);
            self.writeScriptLine(tabCount, modelScript, "context." + escValId + " = context." + escValId + " === nothing ? 0.0 : context." + escValId);
        })
        calculatedValues.forEach(value => {
            let escValId = self.htmlEscape(value);
            self.writeScriptLine(tabCount, modelScript, "context." + escValId + " = context." + escValId + " === nothing ? 0.0 : context." + escValId);
        })
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }
    getValueConstraint(constraints, valueId) {
        for (var i=0;i<constraints.models.length;i++) {
            if (constraints.models[i].get('valueId') === valueId) {
                return constraints.models[i];
            }
        }
        return undefined;
    }
    generateValueIdsMapping(tabCount, modelScript, inputValues, calculatedValues){
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "valueIds = Dict(");
        tabCount++;
        
        inputValues.forEach(value =>{
            let escValId = self.htmlEscape(value);
            let rounding = value.getUnitRounding(); 
            //self.writeScriptLine(tabCount, modelScript, "\"" + escValId + "\" => \"" + self.htmlEscape(value.id) + "\",");
            self.writeScriptLine(tabCount, modelScript, "\"" + escValId + "\" => Dict(\"id\" => \"" + self.htmlEscape(value.id) + "\",\"rounding\" => " + (rounding ? rounding:4) + "),");
        })
        calculatedValues.forEach(value =>{
            let escValId = self.htmlEscape(value);
            let rounding = value.getUnitRounding(); 
            //self.writeScriptLine(tabCount, modelScript, "\"" + escValId + "\" => \"" + self.htmlEscape(value.id) + "\",");
            self.writeScriptLine(tabCount, modelScript, "\"" + escValId + "\" => Dict(\"id\" => \"" + self.htmlEscape(value.id) + "\",\"rounding\" => " + (rounding ? rounding:4) + "),");
        })
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, ")");
    }
    
    generateContextStruct(tabCount, modelScript, valueContexts, inputValues, calculatedValues, valueBounds, valuePeriodRange) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "mutable struct Context");
        tabCount++;
        var alts = Object.getOwnPropertyNames(valueContexts);

        for (let alt in alts) {
            let altData = valueContexts[alt];
            if(!valueBounds[altData.id]){
                valueBounds[altData.id] = {}
            }
            altData.valueElementContext.models.forEach(value => {
                let escValId = self.htmlEscape(value);

                if (!valuePeriodRange[value.get("id")]) {
                    valuePeriodRange[value.get("id")] = { "start": altData.startPeriod, "end": altData.noPeriods };
                    let constraint = self.getValueConstraint(altData.constraints,value.get("id"));
                    if(constraint == null){
                        constraint = value.get("valueDefinition");
                    }
                    
                    // let unit = value.get("unit");
                    // if (unit == undefined && valueDefinition) {
                    //     unit = valueDefinition.get("unit")
                    // }
                    if (constraint != undefined && (constraint.get("bigM") != undefined || constraint.get("maxBound") != undefined || constraint.get("minBound") != undefined  || constraint.get("equalValue") != undefined )) {
                        let valBound = {}
                        if (constraint.get("maxBound") != undefined) {
                            valBound.maxBound = constraint.get("maxBound")
                        }
                        if (constraint.get("minBound") != undefined) {
                            valBound.minBound = constraint.get("minBound")
                        }
                        if (constraint.get("equalValue") != undefined) {
                            valBound.equalValue = constraint.get("equalValue")
                        }
                        if (constraint.get("bigM") != undefined) {
                            valBound.bigM = constraint.get("bigM")
                        }
                        valueBounds[altData.id][escValId] = valBound;
                    }
                    if (value.get("valueType") == ValueType.Atomic) {
                        if(!inputValues.includes(value)){
                            inputValues.push(value);
                        }
                        
                    } else {
                        if(!calculatedValues.includes(value)){
                            calculatedValues.push(value);
                        }
                    }
                    /*value.get('aggregatedFrom').each(function(comp){
                        if (comp.get("valueType") == ValueType.Atomic) {
                            inputValues.push(comp);
                        }
                    });*/
                    //self.writeScriptLine(tabCount, modelScript, escValId + "::Union{Float64, Nothing}");
                } else {
                    valuePeriodRange[value.get("id")].end = altData.startPeriod + altData.noPeriods - 1;
                }
            });
        };
        //inputValues = _.uniq(inputValues);
        // inputValues.forEach(value =>{
        //     let satisfaction = value.get("satisfactionLevel");
        //     if(satisfaction){
        //         calculatedValues.push(satisfaction);
        //     }
        // })
        // calculatedValues.forEach(value =>{
        //     let satisfaction = value.get("satisfactionLevel");
        //     if(satisfaction){
        //         calculatedValues.push(satisfaction);
        //     }
        // })

        inputValues.forEach(value =>{
            let escValId = self.htmlEscape(value);
            self.writeScriptLine(tabCount, modelScript, escValId + "::Union{Float64, Nothing}");
        })
        calculatedValues.forEach(value =>{
            let escValId = self.htmlEscape(value);
            self.writeScriptLine(tabCount, modelScript, escValId + "::Union{Float64, Nothing}");
        })
        tabCount--;
        self.writeScriptLine(tabCount, modelScript, "end");
    }
    generateScriptHeader(tabCount, modelScript) {
        var self = this;
        self.writeScriptLine(tabCount, modelScript, "module " + self.htmlEscape("Plan"+self.get("name")));
        self.writeScriptLine(tabCount, modelScript, "using JuMP, SCIP, JSON3, JSON, Dates, Random");
        self.writeScriptLine(tabCount, modelScript, "using Sockets");
        self.writeScriptLine(tabCount, modelScript, "include(\"vmputil.jl\")");
        self.writeScriptLine(tabCount, modelScript, "using .vmputil");

        self.writeScriptLine(tabCount, modelScript, "#Model");
    }


    getTargetScenarioExecution(){
        var self = this;
        const scenarioId = self.get("defaultScenario");
        const planScenario = window.utils.getElementModel(scenarioId,['transformation.PlanScenario']);
        const executionScenarioId = planScenario.get("defaultExecutionScenario");
        var targetScenarioExec;
        var execuList = self.getPlanPackageList("transformation_ScenarioExecution",true);
        for(var i=0; i < execuList.length; i++){
            if(execuList[i].beepReference != executionScenarioId){
                targetScenarioExec = window.utils.getElementModel(execuList[i].beepReference,["transformation.ScenarioExecution"]);
                break;
            }
        }
        return targetScenarioExec;
    }

     

    scenarioExportDialog(timeStampList) {  
        var self = this;
        var dataGuid = DataManager.getDataManager().guidGenerator();
        var htmlContent = "<div class=\"exportClass\"><fieldset class=\"fieldsetClass\"><legend class=\"legendClass\">"+DataManager.getDataManager().get('localeManager').get('Include')+":</legend>";
        htmlContent = htmlContent + "<input type=\"checkbox\" id='" + dataGuid + "RecipientMeasurements' value='Recipient Measurements' style=\"cursor: pointer; checked \">&nbsp;&nbsp;<label style=\"cursor: pointer;\" for='" + dataGuid + "RecipientMeasurements'>Recipient Opinion</label><br>";
        htmlContent = htmlContent + "<input type=\"checkbox\" id='" + dataGuid + "SatisfactionLevelMeasurements' value='Satisfaction Level Measurements' style=\"cursor: pointer;\">&nbsp;&nbsp;<label style=\"cursor: pointer;\" for='" + dataGuid + "SatisfactionLevelMeasurements'>Satisfaction</label><br>";
        htmlContent = htmlContent + "<input type=\"checkbox\" id='" + dataGuid + "PercentageWeightMeasurements' value='Percentage Weight Measurements' style=\"cursor: pointer;\">&nbsp;&nbsp;<label style=\"cursor: pointer;\" for='" + dataGuid + "PercentageWeightMeasurements'>Weight</label></fieldset></div><br>";
        htmlContent = htmlContent + "<div class=\"exportClass row\"><div class=\"col-xs-3\"><label>"+DataManager.getDataManager().get('localeManager').get('selectFormat')+"</label></div><div class=\"col-xs-2\"><select id=\"selectFormat\"><option selected=\"selected\" value=\"csv\">csv</option><option value=\"xlsx\">xlsx</option>";	
        htmlContent = htmlContent + "</select></div></div>";
        bootbox.dialog({
            title: '<img class="smallIcon" src="img/icons/icons_15.png">  '+DataManager.getDataManager().get('localeManager').get('exportOptions'),
            message: htmlContent,
            buttons: {
                main: {
                    label: "Close",
                    className: "btn btn-default",
                },
                success: {
                    label: '<i id="generateCompleteBtn" class="fa fa-check"></i> Complete',
                    className: "btn btn-complete",
                    callback: function () {
                        var selectedOptions = jQuery('.exportClass input:checked');
                        var subCompLevel = [];
                        for (var i=0;i<selectedOptions.length;i++) {
                            var val= selectedOptions[i].value;
                            if(val==='Recipient Measurements'){
                                subCompLevel.push("recipientOpinion");
                            }else if(val==='Satisfaction Level Measurements'){
                                subCompLevel.push("satisfactionLevel");
                            }else if(val==='Percentage Weight Measurements'){
                                subCompLevel.push("percentageWeight");
                            }
                        }
                        var selectFormat = document.getElementById("selectFormat");
                        var selectedFormat = selectFormat.options[selectFormat.selectedIndex].text;
                        var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: timeStampList.scenarioId });
                        var executionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: timeStampList.scenarioExecutionId });
                        var values = self.getDefaultScenarioValues(scenario,[ValueType.Aggregated.name,ValueType.Atomic.name],subCompLevel);
                        var dataSets = scenario.getPlanDatasets(self);
                        const filteredDatasets = dataSets.filter(item => {
                            const { year, period } = item;
                            if (year < timeStampList.fromYear || year > timeStampList.toYear) {
                                return false;
                            }
                            if (year === timeStampList.fromYear && period < timeStampList.fromPeriod) {
                                return false;
                            }
                            if (year === timeStampList.toYear && period > timeStampList.toPeriod) {
                                return false;
                            }
                            return true;
                        });
                        var lastCalc = new Date();
                        //var exportName = self.get('name') + ' '+ lastCalc.toLocaleDateString() + " "+ lastCalc.toLocaleTimeString();
                        var exportName = self.get('name');
                        
                        require(["xlsx"], function (XLSX) {
                            if (selectedFormat === 'csv') {
                                window.utils.startSpinner('exportCSV', "Exporting Data ... ");
                                var csvObj = self.generateCSVData(values,filteredDatasets,scenario,executionScenario);
                                var ws = XLSX.utils.json_to_sheet(csvObj);
                                var blobData = XLSX.utils.sheet_to_csv(ws);
                                window.utils.stopSpinner('exportCSV');
                                var exportMsg = DataManager.getDataManager().get('localeManager').get('exportComplete','Data');
                                window.utils.exportFileToSystem(exportName,'CSV File',".csv",blobData,exportMsg);
                            } else if (selectedFormat === 'xlsx') {
                                window.utils.startSpinner('exportCSV', "Exporting Data ... ");
                                var wb = self.generateXLSXData(values,filteredDatasets,scenario,executionScenario,XLSX);
                                var bolbData = window.utils.s2ab(XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' }));
                                window.utils.stopSpinner('exportCSV');
                                var exportMsg = DataManager.getDataManager().get('localeManager').get('exportComplete','Data');
                                window.utils.exportFileToSystem(exportName,'XLSX File',".xlsx",bolbData,exportMsg);
                            }
                        });
                    }
                }
            }
        });
        if(DataManager.getDataManager().get('readMode')){
            $(".btn-complete").hide();$(".btn-danger").hide();$(".glyphicon-trash").hide();$(".glyphicon-plus-sign").hide();$(".glyphicon-minus").hide();
            $(".read-access").hide();
        }
    }

    generateXLSXData(values,dataSets,scenario,executionScenario,XLSX) {
        var self = this;        
        var wb = this.createWorkbook(XLSX);
        var periodKind = self.get('periodKind');
        var ws = this.createWorkbookSheetHeader(wb, XLSX, periodKind, dataSets, scenario.get('name'), executionScenario.get('name'), executionScenario.get('scenarioType'));
        var row = 7;
        var col = 0;
        var range = { s: { c: 0, r: 0 }, e: { c: 0, r: 0 } };
        _.each(values, function (value) {
            col = 0;
            self.addWorksheetCell(XLSX, ws, row, col++, value.get('name'));
            var unitName = value.get('unit') ? value.get('unit').get('name') :'';
            self.addWorksheetCell(XLSX, ws, row, col++, unitName);
            _.each(dataSets, function (yearPeriod) {
                var	pDataset;
                if(value.get("valueType") == ValueType.Atomic) {
                    pDataset = executionScenario.get("input").findWhere({period: yearPeriod.period,year: yearPeriod.year,periodKind : periodKind});
                } else {
                    pDataset = executionScenario.get("result").findWhere({period: yearPeriod.period,year: yearPeriod.year,periodKind : periodKind});
                }
                var qty = pDataset ? pDataset.get(value.id):'';
                if(value.get('type')=='vdml_ValueElement'  && value.get("valueType") == ValueType.Aggregated){
                    var satisfactionData = value.getSatisfactionIntervalData(qty,yearPeriod.alternativeId);
                    qty = satisfactionData.levelName;
                }
                self.addWorksheetCell(XLSX, ws, row, col++, qty);
            });
            row++;
            range.e.r = row; // Update range end row
            range.e.c = Math.max(range.e.c, col - 1); // Update range end column
        })
        ws['!ref'] = XLSX.utils.encode_range(range);
        return wb;
    }

    createWorkbookSheetHeader(wb, XLSX, periodKind, dataSets, scName, scExeName, type) {
        //var self = this;
        var ws = {};
        //ws['!merges'] = [];
       // ws['!merges'].push({ s: { c: 0, r: 0 }, e: { c: 0, r: 1 } });
       // ws['!merges'].push({ s: { c: 1, r: 0 }, e: { c: 1, r: 1 } });
        //ws['!merges'].push({ s: { c: 2, r: 0 }, e: { c: 2, r: 1 } });
        ws['!cols'] = [];
        ws['!cols'].push({ wch: 10 });
        ws['!cols'].push({ wch: 15 });
        ws['!cols'].push({ wch: 15 });
        ws['!cols'].push({ wch: 15 });
        const boldStyle = { font: { bold: true } };
        var ws_name = "Sheet1";
        wb.SheetNames.push(ws_name);

        ws[XLSX.utils.encode_cell({ c: 1, r: 0 })] = { v: "Scenario", t: 's', s: boldStyle };
        ws[XLSX.utils.encode_cell({ c: 2, r: 0 })] = { v: scName, t: 's' };
        ws[XLSX.utils.encode_cell({ c: 1, r: 1 })] = { v: "Scenario Execution", t: 's', s: boldStyle };
        ws[XLSX.utils.encode_cell({ c: 2, r: 1 })] = { v: scExeName, t: 's' };
        ws[XLSX.utils.encode_cell({ c: 1, r: 2 })] = { v: "Scenario Type", t: 's', s: boldStyle };
        ws[XLSX.utils.encode_cell({ c: 2, r: 2 })] = { v: type, t: 's' };

        ws[XLSX.utils.encode_cell({ c: 0, r: 3 })] = { v: "Year", t: 's', s: boldStyle };
        ws[XLSX.utils.encode_cell({ c: 0, r: 4 })] = { v: "Period", t: 's', s: boldStyle };
        ws[XLSX.utils.encode_cell({ c: 1, r: 4 })] = { v: periodKind, t: 's', s: boldStyle };

        ws[XLSX.utils.encode_cell({ c: 0, r: 6 })] = { v: "Value Name", t: 's', s: boldStyle };
        ws[XLSX.utils.encode_cell({ c: 1, r: 6 })] = { v: "Unit", t: 's', s: boldStyle };

        var ycolumn = 2;
        var pcolumn = 2;
        _.each(dataSets, function (d) {
            ws[XLSX.utils.encode_cell({ c: ycolumn++, r: 3 })] = { v: ""+d.year, t: 'n' };
            ws[XLSX.utils.encode_cell({ c: pcolumn++, r: 4 })] = { v: ""+d.period, t: 'n' };
        });
        wb.Sheets[ws_name] = ws;
        return ws;
    }

    generateCSVData(values,dataSets,scenario,executionScenario) {
        var self = this;
        var csvObj = [];
        var periodKind = self.get('periodKind');
        function getNamePath(value){
            var valueName;
            var parent = value;
            while (parent && parent.id !== oldParent) {
                var oldParent = parent.id;
                valueName = parent.get('name') + (valueName ? (">" + valueName) : "");
                parent = parent.getParent();
            }
            return valueName;  
        }
        var fullScName = scenario.get('name') + "/" + executionScenario.get('name');
        _.each(dataSets, function (yearPeriod) {
            var inpDataset = executionScenario.get("input").findWhere({period: yearPeriod.period,year: yearPeriod.year,periodKind : periodKind});
            var repDataset = executionScenario.get("result").findWhere({period: yearPeriod.period,year: yearPeriod.year,periodKind : periodKind});
            _.each(values, function (value) {
                var csvRow = {};
                csvRow['Value Path'] = getNamePath(value);
                csvRow['Value Name'] = value.get('name');
                csvRow['Value Type'] = value.get('valueType');
                var unitName = value.get('unit') ? value.get('unit').get('name') :'';
                csvRow['Unit'] = unitName;
                var qty = '';
                if(value.get("valueType") == ValueType.Atomic) {
                    qty = inpDataset ? inpDataset.get(value.id):'';
                } else {
                    qty = repDataset ? repDataset.get(value.id):'';
                }
                if(value.get('type')=='vdml_ValueElement' && value.get("valueType") == ValueType.Aggregated){
                    var satisfactionData = value.getSatisfactionIntervalData(qty,yearPeriod.alternativeId);
                    qty = satisfactionData.levelName;
                }
                //csvRow[yearPeriod.year+'-'+yearPeriod.period] = qty;
                csvRow['Year'] = yearPeriod.year;
                csvRow['Period'+periodKind] = yearPeriod.period;
                csvRow['Quantity @ '+ fullScName] = qty;
                csvObj.push(csvRow);
            });
            
        });
        if (csvObj.length === 0) {
            csvObj.push({});
        }
        return csvObj;
    }

    getCalculationChangeType(operationType,change){
		if(operationType == "add" || operationType == "destroy"){
			return;
		}else{
			if(change.periodKind || change.phase){
				return 0;
			}
		}
		return;
	}
	getCalculationChangeArtifact(operationType){
        var self = this;
		let dataManager = DataManager.getDataManager();
        var plan = dataManager.get("currentPlan");
        if(!plan){
            plan = self;
        }
		return dataManager.get("artifactsDocuments")[plan.id].artifactId;
	}
}


utils.customExtendClass(Plan2Mixin, new PlanMixin());

path.Plan2Mixin = Plan2Mixin;