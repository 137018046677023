import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {ValueAddMixin} from './ValueAddMixin'

	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	export class ValueAdd2Mixin {

	static getCumulativeMixinRelations(){
		if (!ValueAddMixin.cummulativeRelations) {
            ValueAddMixin.cummulativeRelations = _.union(ValueAddMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("ValueElement","vdml").getCumulativeMixinRelations()
            );
        }
		return ValueAddMixin.cummulativeRelations.slice();
	}
		
//#startCustomMethods
	prime(year,period,value,valueUnit,valFormula,baseTuples,viewAlternative,projectionProfile,valueConstraint,callback){
		var self = this;
		self.addBaseTuple(baseTuples);
		var measurementComponent = self.primeValueMeasurement(year,period,value,valueUnit,valFormula,baseTuples,viewAlternative,projectionProfile,valueConstraint);
		if(callback){
			callback();
		}
	};
	
	update(period,year,name,value,valueUnit,valFormula,baseTuples,viewAlternative,projectionProfile,valueConstraint,callback){
		var self = this;
		self.updateValueElementChanges(period,year,name,value,valueUnit,valFormula,baseTuples,viewAlternative,projectionProfile,valueConstraint,callback);
	};
	
//#endCustomMethods

	
	}
	path.ValueAdd2Mixin = ValueAdd2Mixin;
	utils.customExtendClass (ValueAdd2Mixin,new ValueAddMixin());
	//return ValueAddMixin;
//});