import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {ProjectionProfileMixin} from './ProjectionProfileMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { MeasuredCharacteristic } from './MeasuredCharacteristic'

	
var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

var ProjectionProfile = VdmlElement.extend(utils.customExtends({
    relations:ProjectionProfileMixin.getMixinRelations(),
    initialize: function(attributes, options) {
        VdmlElement.prototype.initialize.apply(this, arguments);
    }
    }
    , new ProjectionProfileMixin()
));
ProjectionProfile.getInstance = function(name,trendModel,rateFactor,polynomialCoefficient,periodKind,owner){
    var projectionId = DataManager.getDataManager().guidGeneratorByOwner(owner);
	var projectionProfile = new ProjectionProfile({id:projectionId,name:owner.getUniqueProperty('name', name, 'projectionProfiles'),trendModel:trendModel,rateFactor:rateFactor,polynomialDegree:polynomialCoefficient,periodKind:periodKind,projectionProfileOwner:owner});
	return projectionProfile;
}

var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
ProjectionProfile.abstract = !sp2Enabled;
ProjectionProfile.supported = sp2Enabled;
path.ProjectionProfile = ProjectionProfile;
export {ProjectionProfile};
