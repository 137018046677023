import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {EObject} from '../cmof/EObject'
import {ScenarioValueConstraintsMixin} from './ScenarioValueConstraintsMixin'


	
var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);

var ScenarioValueConstraints = EObject.extend(utils.customExtends({
    relations:ScenarioValueConstraintsMixin.getMixinRelations(),
    initialize: function(attributes, options) {
        EObject.prototype.initialize.apply(this, arguments);
    }
    }
    , new ScenarioValueConstraintsMixin()
));
ScenarioValueConstraints.getInstance = function(valId,maxBound,minBound,equalValue,bigM,step){
    var constraintId = DataManager.getDataManager().guidGeneratorByOwner(step);
	var scenarioValueConstraint = new ScenarioValueConstraints({valueId:valId,id:constraintId,maxBound:maxBound,minBound:minBound,equalValue:equalValue,bigM:bigM,constraintsOwner:step});
	return scenarioValueConstraint;
}
var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
ScenarioValueConstraints.abstract = !sp2Enabled;
ScenarioValueConstraints.supported = sp2Enabled;
path.ScenarioValueConstraints = ScenarioValueConstraints;
export {ScenarioValueConstraints};
