import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {CanvasTargetDetailsViewModel} from './CanvasTargetDetailsViewModel'

//define(["require", "jquery", "underscore", 'async', "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/BusinessModelMixin", "appbo/vdml/ValueElementMixin", "appbo/vdml/EcoMapDiagram", "appbo/ecomap/EcoMap", "jquery.DataTable", "summernote"],
//	function (require, $, _, async, Backbone, ko, koMapping, kb, bootbox, DataManager, global, BusinessModelMixin, ValueElementMixin, EcoMapDiagram, EcoMap) {

var path = DataManager.getDataManager().buildAppNsPath("canvas.views.designer", global.version);
export class CanvasTargetDetails2ViewModel extends CanvasTargetDetailsViewModel {
	constructor(model, options) {
		var self = this;
		this.CanvasTargetDetails2ViewModel = this;		
	}
	
	static getInstance(model, configObj, callback) {
		return new CanvasTargetDetails2ViewModel(model, configObj, callback);
	};
}
path.CanvasTargetDetails2ViewModel = CanvasTargetDetails2ViewModel;
