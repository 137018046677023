import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
//import {MappingWizardViewModel} from '../../../strategyMap/views/designer/MappingWizardViewModel'
import {EcoMapDiagram2Mixin} from '../../../../../version1/bo/vdml/EcoMapDiagram2Mixin'
import {BusinessModel2Mixin} from '../../../../../version1/bo/vdml/BusinessModel2Mixin'
import {Suggestion} from '../../../../../version1/bo/vdml/Suggestion'
import {ValueDeliveryModel2Mixin} from '../../../../../version1/bo/vdml/ValueDeliveryModel2Mixin'
import {ShapePropertiesViewModel} from './ShapePropertiesViewModel'
//import {EcomapMappingWizardViewModel} from './EcomapMappingWizardViewModel'
/*define(["require", "jquery", "underscore", 'async', "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/canvas/BMCanvasDiagram", "appbo/vdml/ValueStream", "appbo/vdml/BusinessItem", "appbo/vdml/CapabilityOffer", "appbo/vdml/MeasuredCharacteristic", "appviews/vdml/views/chart/BMValuesChart", "appviews/transformation/views/chart/PlanValuesChart", "appbo/vdml/BusinessModelMixin", "appbo/vdml/EcoMapDiagramMixin", "appbo/vdml/ValueDeliveryModelMixin", "summernote", "colorpicker"],
    function (require, $, _, async, Backbone, ko, koMapping, kb, bootbox, DataManager, global, BMCanvasDiagram, ValueStream, BusinessItem, CapabilityOffer, MeasuredCharacteristic, BMValuesChart, PlanValuesChart, BusinessModelMixin, EcoMapDiagramMixin,ValueDeliveryModelMixin) {
*/
var path = DataManager.getDataManager().buildAppNsPath("ecoMap.views.designer", global.version);
export class ShapeProperties2ViewModel extends ShapePropertiesViewModel {
    init(model, options) {
        super.init(model, options);
        var self = this;
        var dataManager = DataManager.getDataManager();
        this.selectedYear = ko.observable();
        this.selectedPeriod = ko.observable();
        var currentPlan = dataManager.get('currentPlan');
        this.periodKind = currentPlan.get("periodKind");
        var currentAlternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({
            id: dataManager.get('viewAlternative')
        })
        var scenarioId = currentPlan.get("defaultScenario");
        var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({
            id: scenarioId
        });
        this.dataSets = currentAlternative.getPhaseAlternativeSteps(scenario);
        var defaultPeriodList = scenario.fillDefaultPeriods(this.dataSets, self.periodKind);
        self.defaultPeriod = defaultPeriodList?.period
        self.defaultYear = defaultPeriodList?.year;

        var defaultExecutionScenaroId = scenario.get("defaultExecutionScenario");
        self.defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({
            id: defaultExecutionScenaroId
        });
        //self.loadDatasets(self.defaultPeriod, self.defaultYear);

    };

    loadValueDetailsPage(selectedBM,type) {
        var self = this;
        var alt = self.currentAlternativeModel;
        var scenario = self.currentAlternativeModel.getMainScenario(self.mappedElementModel.getNestedParent());
        if(!scenario){
            var altComp = self.currentAlternativeModel.getModelLinkedSync(self.mappedElementModel);
            if(altComp){
                scenario = self.currentAlternativeModel.getMainScenario(altComp.getNestedParent());
                self.mappedElementModel = altComp;
            }
        }
        var enableAgg = true;
        /*if(window.checkContextForRevision()){
            enableAgg = false;
        }*/
        var addOptions = {'type': type, 'disableAggr':!enableAgg, 'aggregationView':!enableAgg, 'aggTo': !enableAgg, 'mainScenario': scenario, 'overAllSatisfaction': overAllSatisfaction, 'bm': selectedBM, 'currentAlternativeModel':alt,'disableDelete':true};
        if(type == "Activity"){
            var actModel = self.mappedElementModel.get('valueAddOwner').get('containedPortOwner');
            var actObj = [{originalId:actModel.get('id'),modalId: htmlEscape(actModel.get('id')),name:actModel.get('name')}];
            addOptions = { 'type': 'createWhatValues', 'aggTo': !enableAgg, 'activities': actObj, 'aggregationView': !enableAgg,'mainScenario': scenario, view: self, 'valuesColl': [], 'bm': selectedBM, 'currentAlternativeModel': alt, 'disableDelete': true};
        }
        var overAllSatisfaction = self.mappedElementModel.get('componentOwner').get('overAllSatisfaction');
        
        var planType = false;
        if (DataManager.getDataManager().getRepositoryId(self.mappedElementModel.id) == window.plansKey) {
            planType = true;
            addOptions.type='planValues'; addOptions.planValue= true;
        } else {
            var vp = self.mappedElementModel.getParent();
            var role = vp.get("provider");
            var collaboration = role.getParent();
            addOptions.collaboration = collaboration;
        }
        self.getAggregatedColl(planType, selectedBM, function(aggregatedColl) {
            addOptions.aggregatedColl = aggregatedColl;
            addOptions.period = self.defaultPeriod;
            addOptions.year = self.defaultYear;
            window.getAndCreateModalDialog(self.mappedElementModel, window.utils.htmlEscape(self.mappedElementModel.get('id')) + "_edit", BusinessModel2Mixin, self.mappedElementModel, "ValueDetails", self.saveValue, addOptions);
        });
    }
    loadAddViewDialog = function(id, addOptions){
        var self = this;
        addOptions.period = self.defaultPeriod;
        addOptions.year = self.defaultYear;
        window.getAndCreateModalDialog(self.model, id, BusinessModel2Mixin, null, "ValueDetails", self.addValue, addOptions)
    };
    static getInstance = function(model, options) {
        var view = new ShapeProperties2ViewModel(model, options);
        view.init(model, options);
        return view;

    };
}
path.ShapeProperties2ViewModel = ShapeProperties2ViewModel;
