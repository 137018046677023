import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {ExpressionMixin} from './ExpressionMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { Operand } from './Operand'
import { RuleReference } from './RuleReference'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/VdmlElement","appbo/vdml/ExpressionMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,VdmlElement,ExpressionMixin
, Operand
, RuleReference){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!Operand){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.Operand){
			Operand = importPath.Operand;
		}
        else {
            import('./Operand').then(({ default: Operand }) => {
                Operand = Operand;
            });
            /*require(["appbo/vdml/Operand"], function (loadedModule) {
                Operand = loadedModule;
			});*/
		}
	}
	if(!RuleReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.RuleReference){
			RuleReference = importPath.RuleReference;
		}
        else {
            import('./RuleReference').then(({ default: RuleReference }) => {
                RuleReference = RuleReference;
            });
           /* require(["appbo/vdml/RuleReference"], function (loadedModule) {
                RuleReference = loadedModule;
			});*/
		}
	}
	var Expression = VdmlElement.extend(utils.customExtends({
		relations:ExpressionMixin.getMixinRelations(),
		initialize: function(attributes, options) {
			VdmlElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new ExpressionMixin()
	));
	/*Expression["change:expressionStr"] = function (model,newName) {
		DataManager.getDataManager().addToModalChange();
    }
	Expression["change:expressionConfig"] = function (model,newName) {
		DataManager.getDataManager().addToModalChange();
    }*/
	
	Expression.abstract = false;
	// Expression.supported = false;
	path.Expression = Expression;
	export {Expression};
//});