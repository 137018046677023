import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as async from 'async'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import {ValueType} from '../../../../bo/vdml/ValueType'
import { BusinessModel2Mixin } from '../../../../bo/vdml/BusinessModel2Mixin'
import * as math from 'mathjs'
import * as monaco from 'monaco-editor';
import Treeselect from 'treeselectjs'
import * as antlr4 from 'antlr4'
import ExprLangLexer from '../../../../../com/vbee/antlr/ExprLangLexer.js'
import ExprLangParser from '../../../../../com/vbee/antlr/ExprLangParser.js'
import * as autosuggest from 'antlr4-autosuggest'
import ExprLangToJuliaListener from '../../../../../com/vbee/antlr/ExprLangToJuliaListener.js'
import { EcoMapDiagramMixin } from '../../../../bo/vdml/EcoMapDiagramMixin'
import { TokenStreamWrapper} from '../../../../../com/vbee/antlr/TokenStreamWrapper.js'
import { SuggestionParser } from '../../../../../com/vbee/antlr/SuggestionParser.js'
import { SuggestionCollector } from '../../../../../com/vbee/antlr/SuggestionCollector.js'


var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties", global.version);

export class ValueDetails2ViewModel{
    constructor(model, options) {
        this.init(model, options);
    }
    htmlEscape(str) {
        return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
    }
    cleanUp() {
        delete ko.bindingHandlers.weightNumeric;
        delete ko.bindingHandlers.numeric;
        this.completionItemProvider && this.completionItemProvider.dispose();
    }
    fillMeasurementUnits() {
        var self = this;
        var currentPlan = DataManager.getDataManager().get('currentPlan');
        var valueLibraries = currentPlan.get("valueLibrary").models;
        for(var i = 0;i< valueLibraries.length;i++){
            var library = valueLibraries[i];
            var units = library.get("unit").models;
            for(var j=0; j< units.length;j++){
                var unit = units[j];
                if (!self.measureUnits.findWhere({ 'name': unit.get('name') })) {
                    self.measureUnits.push({ id: '' +unit.get('id'), name: '' + unit.get('name'), roundingDigits: '' + unit.get('roundingDigits'), significantDecimals: '' + unit.get('significantDecimals') });
                }
            }
        }
    }

    getJuliaExpr = function(){
        var self = this;
        //const { ParseTreeWalker } = antlr4.tree;
        const ParseTreeWalker  = antlr4.ParseTreeWalker;
        const input = self.formula();
        const chars = new antlr4.InputStream(input);
        const lexer = new ExprLangLexer(chars);
        const tokens = new antlr4.CommonTokenStream(lexer);
        const parser = new ExprLangParser(tokens);
        const tree = parser.program();
        // Create a generic parse tree walker that can trigger callbacks
        const walker = new ParseTreeWalker();

        // Walk the tree created during the parse and trigger callbacks
        const listener = new ExprLangToJuliaListener();
        walker.walk(listener, tree);

        // Get the result
        const juliaCode = listener.getResult();
        console.log(juliaCode); // Outputs the Julia code equivalent of the input
    }

    fillConstraints(valModel){
        var self = this;
        var scenario = DataManager.getDataManager().get('defaultScenario');;
		var step = scenario.get("step").findWhere({alternativeId: self.currentAlternativeModel.get('id')});
        if(step){
            step.get('constraints').models.map(d=>{
                if(d.get('valueId')===valModel.get('id')){
                    self.maxBound(d.get('maxBound') ? parseFloat(d.get('maxBound')) : null);
                    self.minBound(d.get('minBound') ? parseFloat(d.get('minBound')) : null);
                    self.equalValue(d.get('equalValue') ? parseFloat(d.get('equalValue')) : null);
                    self.bigM(d.get('bigM') ? parseFloat(d.get('bigM')) : null);
                    self.constraintCheck(true);
                    self.checkMaxMinBoundConstraints(self.minBound(), self.maxBound(), self.equalValue());
                }
           });
        }
    }
    fillMaxOffset(valModel) {
        var self = this;
        var formula = valModel.getValueFormula(self.currentAlternativeModel.id);
        var offset = formula ? formula.get("offset") ? formula.get("offset") : 0 : 0;
        self.maxOffset(offset);
    }

    fillFormula(valModel){
        var self = this;
        if(valModel.get('valueType') == ValueType.Atomic){
            self.showFormulaField(false);
            return;
        }
        var valueDefinition = null;
        var formula = valModel.getValueFormula(self.currentAlternativeModel.id);
      
        if(valModel && !formula){
            var valueDefinition = valModel.get("valueDefinition");
            if(valueDefinition){
                formula = valueDefinition.get("formula");
            }
        }

        var exp = formula ? formula.get("expressionStr") : null;
        var agg  = self.expBuilderOptions.variables.map((d)=>{return {...d,variableId:self.htmlEscape(d.variableId)}});
        const updatedExpression = utils.replaceIdsWithNames(exp, agg);
        if(updatedExpression){
            self.formula(updatedExpression);
        }
    }

    fillQuantity(valModel) {
        var self = this;
        var valueDefinition = null;
        //var formula = null;
        var valueDefinition = valModel.get("valueDefinition");
        /*if(valueDefinition && !formula){
            formula = valueDefinition.get("formula");
        }
        var exp = formula ? formula.get("expressionStr") : null;//remove these
        if(exp){
            self.formula(exp);
        }*/
        
        self.fillUnit(valModel,valueDefinition);
        var qty = self.periodDataset ? self.periodDataset.get(valModel.id): "";
        if(valModel.get("valueType") != ValueType.Atomic){
            qty = self.calcPeriodDataset ? self.calcPeriodDataset.get(valModel.id): "";
        }
        var quantity = qty ? window.utils.getRoundedValues(qty, self.significantDecimals()) : "";
        self.measurementValue('' + quantity);
        self.fullMeasurementValue = quantity;
    };

    checkCyclic(measurement, element) {
        var self = this;
        if (!self.model) {
            return;
        }
        var cyclePath;
        if (measurement && self.aggregateColl().length >= 1) {
            cyclePath = measurement.getCyclicPath();
            if (cyclePath && cyclePath.length > 0 && self.model.get('isIterative')) {
                self.isCyclic(true);
            } else {
                self.isCyclic(false);
            }
        } else if (element && self.aggregateColl().length >= 1) {
            cyclePath = self.model.getCyclicPath(element);
            if (cyclePath && cyclePath.length > 0) {
                self.isCyclic(true);
            } else {
                self.isCyclic(false);
            }
        }
    };
    clearValues(modelChange) {
        var self = this;
        self.satisfaction("");
        self.weight("");
        self.measurementValue("");
        self.maxOffset();
        if (modelChange) {
            self.aggregateColl([]);
            self.satisfactionIntervalColl([]);
            self.measurementUnit("");
            self.satisfactionUnitValue("");
            $('#satisfactionUnit' + self.encodeId).val('');
            $('#measurementUnit' + self.encodeId).val('');
            self.checkDimensional(true);
        }
    };
    getUniqueValue(val) {
        var self = this;
        var parentValCollection = self.options.addOptions ? self.options.addOptions.valuesColl : null;
        if (!parentValCollection && self.model) {
            var modalParent = self.model.getParent();
            var parentValBackboneCollection = modalParent.get('component') ? modalParent.get('component') : modalParent.get('valueAdd');
        }
        var activityId = null;
        if (self.selectedActivity()) {
            activityId = self.selectedActivity();
        }
        var id = self.options.modalId.split('_')[0];
        var unique = window.isUniqueName(val, parentValCollection, id, parentValBackboneCollection, activityId);
        var disallowed = false;
        if (unique && self.disallowedNames.includes(val)) {
            unique = false;
            disallowed = true;
        }
        var existedOneEdit = false;
        if (self.selectedActivity() && parentValCollection) {
            for (var i = 0; i < parentValCollection.length; i++) {
                if (parentValCollection[i].name === val.trim() && parentValCollection[i].modalId !== activityId) {
                    if (activityId) {
                        existedOneEdit = true;
                        break;
                    }

                }
            }
        }
        if (unique && self.selectedActivity() && !existedOneEdit) {
            var bm = self.options.parentView;
            if (!bm && self.options.addOptions.bm && self.options.addOptions.bm.get('type') === 'vdml_BusinessModel') {
                bm = self.options.addOptions.bm;
            }
            if(bm && bm.get('bmActivity')){
                bm.get('bmActivity').each(function (bmActivity) {
                    var activity = bmActivity.get('activity');
                    if (self.htmlEscape(activity.get('id')) == activityId) {
                        activity.get('containedPort').each(function (port) {
                            if (port.get('type') === "vdml_OutputPort") {
                                port.get('valueAdd').each(function (actValue) {
                                    if (actValue.get('name') === val.trim() && actValue.id === id) {
                                        unique = false;
                                    }
                                })
                            }
                        });
                    }
                });
            }
        }
        if (unique) {
            return false;
        }
        else {
            var valueType = "";
            if (self.options.addOptions.type.toLowerCase() === "valueProposition".toLowerCase()) {
                valueType = self.valuePropositionName;
            }
            else if (self.options.addOptions.type.toLowerCase() === "planValues".toLowerCase()) {
                valueType = "Plan";
            }
            else if (self.options.addOptions.type.toLowerCase() === "valueFormula".toLowerCase()) {
                valueType = self.myPropositionName;
            }
            else {
                valueType = self.activityName;
            }
            $('#message' + self.encodeId).text(DataManager.getDataManager().get('localeManager').get('DuplicateValueMessage1', valueType));
            if (disallowed) {
                $('#message' + self.encodeId).text(DataManager.getDataManager().get('localeManager').get('DuplicateValueMessage2'));
            }
            return true;
        }
    };
    clearTuples(type) {
        var self = this;
        for (var j = 0; j < self.satisfactionIntervalColl().length; j++) {
            for (var i = 0; i < self.intervalTuples.length; i++) {
                var tuple = self.intervalTuples[i];
                var interval = self.satisfactionIntervalColl()[j];
                if (tuple.seqNo == interval.seqNo) {
                    self.satisfactionIntervalColl.destroy(interval);
                    self.intervalTuples.splice(i, 1);
                    break;
                }
            }
        }
        self.sortSatisfactionIntervals();
    };
    checkAggregationInBm(bm, component) {
        var self = this;
        var compExists = true;
        if (component.get('type') === "vdml_ValueAdd") {
            var actValue = false;
            var activities = bm.get('bmActivity') ? bm.get('bmActivity') : [];
            for (var i = 0; i < activities.length; i++) {
                if (activities.at(i).get('activityValue').findWhere({ 'id': component.get('id') })) {
                    actValue = true;
                    break;
                }
            }
            if (!actValue) {
                compExists = false;
            }
        } else {
            var compOwner = component.get('componentOwner');
            var providerRole;
            if (compOwner) {
                providerRole = component.get('componentOwner').get('provider');
            }
            if (providerRole && !bm && !bm.get('bmRole').findWhere({ 'id': providerRole.get('id') })) {
                compExists = false;
            }
        }
        return compExists;
    };
    checkAggregationFiltering(bm, type, component) {
        var self = this;
        var validAggregation = true;
        if(component.get("isNominal")){
            return false;
        }
        if (!self.checkIsPlanValue(component.get('id'))) {
            if (!self.currentAlternativeModel.get('phaseDesignPart').findWhere({ 'beepReference': component.getNestedParent().get('id') })) {
                return false;
            }
            if (self.options.addOptions.collaboration && bm) {
                var currentPackage = bm.getNestedParent();
                var packageStoredAltId = window.utils.getPrefix(self.options.addOptions.collaboration.get('id'));
                if (packageStoredAltId !== DataManager.getDataManager().getRepositoryId(currentPackage.get('id'))) {
                    return false;
                }
            }
            if (!self.planValue) {
                validAggregation = self.checkAggregationInBm(bm, component);
                if (!validAggregation) {
                    return validAggregation;
                }
            }
        }
        
        var activitiesValues = self.options.addOptions.activitiesValues;
        if (type === "contributeWhatValues") {
            var actValueExists = false;
            if (component.get('type') === 'vdml_ValueAdd') {
                for (var j = 0; j < activitiesValues.length; j++) {
                    if (activitiesValues[j].originalId === component.get('id')) {
                        actValueExists = true;
                        break;
                    }
                }
            }
            else {
                var activityValueProposition = self.options.addOptions.activityValueProposition;
                if (activityValueProposition && activityValueProposition.get('recipient')) {
                    var receivedProp = activityValueProposition.get('recipient').get('providedProposition');
                    for (var i = 0; i < receivedProp.length; i++) {
                        if (receivedProp.at(i).get('component').findWhere({ 'id': component.get('id') })) {
                            actValueExists = true;
                            break;
                        }
                    }
                }
            }
            validAggregation = actValueExists;
        }
        else if (type === "createWhatValues") {
            if (component.get('type') === 'vdml_ValuePropositionComponent') {
                if (self.checkIsPlanValue(component.get('id'))) {
                    validAggregation = true;
                }
                else if (component.get('componentOwner').get('isValueFormula')) {
                    validAggregation = false;
                }
                else {
                    var valueprops = bm ? bm.get('bmValueProposition') : [];
                    var vpInBm = false;
                    for (var i = 0; i < valueprops.length; i++) {
                        var providedProposition = valueprops.at(i).get('valueProposition');
                        if (providedProposition.get('component').findWhere({ 'id': component.get('id') })) {
                            vpInBm = true;
                            break;
                        }
                    }
                    if (!vpInBm) {
                        validAggregation = false;
                    }
                }
            }

        }
        else if (type === "valueProposition") {
            if (component.get('type') === 'vdml_ValuePropositionComponent') {
                if (component.get('componentOwner').get('isValueFormula')) {
                    validAggregation = false;
                }
            }
            else {
                var actValExists = false;
                var bmActs = bm ? bm.get('bmActivity') : [];
                for(var x=0; x < bmActs.length; x++){
                    var activityExists = bmActs.at(x).get('activityValue').findWhere({'id':component.get('id')});
                    if(activityExists){
                        actValExists = true;
                        break;
                    }
                }
                validAggregation = actValExists;
            }
        }
        else if (type === "valueFormula") {
            if (!self.checkIsPlanValue(component.get('id')) && component.get('type') === 'vdml_ValuePropositionComponent' && !component.get('componentOwner').get('isValueFormula')) {
                var compOwner = component.get('componentOwner');
                var businessRoles = bm ? bm.getBusinessRoles() : [];
                var businessValprop = false;
                for (var i = 0; i < businessRoles.length; i++) {
                    if (businessRoles[i] === compOwner.get('recipient') || businessRoles[i] === compOwner.get('provider')) {
                        businessValprop = true;
                        break;
                    }
                }
                validAggregation = businessValprop;
            } else if (component.get('type') === 'vdml_ValueAdd') {
                var actValExists = false;
                var bmActs = bm ? bm.get('bmActivity') : [];
                for(var x=0; x < bmActs.length; x++){
                    var activityExists = bmActs.at(x).get('activityValue').findWhere({'id':component.get('id')});
                    if(activityExists){
                        actValExists = true;
                        break;
                    }
                }
                validAggregation = actValExists;
            }
        }
        return validAggregation;
    };

    /*calculateValue(measurementValues, wtArray) {
        var self = this;
        var measurementValue = '';
        return measurementValue;
    }*/

    calculateMeasurentValues() {
        var self = this;
        /*if (self.postShow && !self.readOnly) {
            var measurementValueArray = [], wtValuesArray = [];
            var measurementValue = '';
            var measurement = null;
            var count = 0;
            for (var i = 0; i < self.aggregateColl().length; i++) {
                var component = self.fetchComponentById(self.aggregateColl()[i].aggregateid);
                if (!self.aggregateColl()[i]._destroy && component) {
                    count += 1;
                    var useScenario = self.scenario;
                    useScenario = self.currentAlternativeModel.getMainScenario(component.getNestedParent());
                    if (self.checkIsPlanValue(self.aggregateColl()[i].aggregateid)) {
                        useScenario = self.fetchPlanScenario();
                    }
                    var aggValueMeasurement = useScenario ? self.getComponentMeasurement(component, 'valueMeasurement', useScenario) : null;
                    var satMeasurement;
                    measurement = aggValueMeasurement;
                    var mValue = 0, satValue = 0;
                    if (measurement) {
                        mValue = measurement.get('value');
                        if (mValue !== '') {
                            measurementValueArray.push(mValue);
                        }
                    }
                } else {
                    var tempValuesForAggregation = self.options.addOptions.tempValuesForAggregation;
                    if (tempValuesForAggregation) {
                        for (var j = 0; j < tempValuesForAggregation.length; j++) {
                            if (tempValuesForAggregation[j].id === self.aggregateColl()[i].aggregateid) {
                                mValue = tempValuesForAggregation[j].value;
                                if (mValue != '') {
                                    measurementValueArray.push(mValue);
                                }
                                break;
                            }
                        }
                    }
                }
            }
            if (self.aggregateColl().length > 0) {
                if (self.model) {
                    var mValue = self.calculateValue(measurementValueArray, wtValuesArray);
                    measurementValue = measurementValue + mValue;
                        count = window.utils.getObservableLength(self.aggregateColl());
                        self.tempMeasurementRoundingValue = window.utils.getRoundedValues(measurementValue, self.roundingDigits());
                        self.measurementValue('' + window.utils.getRoundedValues(measurementValue, self.significantDecimals()));
                }
                else if (!self.model) {
                    measurementValue = measurementValue ? measurementValue : "";
                        self.tempMeasurementRoundingValue = window.utils.getRoundedValues(measurementValue, self.roundingDigits());
                        self.measurementValue('' + window.utils.getRoundedValues(measurementValue, self.significantDecimals()));
                }
                if (count == 0) {
                    self.enableMeasurement(true);
                }
                else {
                    self.enableMeasurement(false);
                }
            }
        }*/
        if (!self.readOnly) {
            if(self.options.year != null){
                self.enableMeasurement(true);
                self.linkProjectionProfile(true);
                for (var i = 0; i < self.aggregateColl().length; i++) {
                    if (!self.aggregateColl()[i]._destroy) {
                        self.enableMeasurement(false);
                        self.linkProjectionProfile(false);
                        self.usedProjection = null;
                    }
                }
            }
        }
    }
    calculateSatisfaction() {
        var self = this;
        if (self.measurementValue() !== "") {
            var intervalFound = false;
            for (var i = 0; i < self.satisfactionIntervalColl().length; i++) {
                var interval = self.satisfactionIntervalColl()[i];
                if (!interval._destroy) {
                    var frmInc = interval.fromInclusive ? parseFloat(interval.intervalFrom) <= parseFloat(self.measurementValue()) : parseFloat(interval.intervalFrom) < parseFloat(self.measurementValue());
                    var toInc = interval.toInclusive ? parseFloat(self.measurementValue()) <= parseFloat(interval.intervalTo) : parseFloat(self.measurementValue()) < parseFloat(interval.intervalTo);
                    if (frmInc && toInc) {
                        self.satisfaction(interval.intervalName);
                        self.showSatisfaction(true);
                        var smileySrc = self.fetchSmileySrc(interval.smiley);
                        $("#showSatisfactionImg" + self.encodeId).attr('d', smileySrc);
                        var color = interval.color ? interval.color : 'rgb(0,0,0)';
                        $("#showSatisfactionImg" + self.encodeId).attr('fill', color);
                        intervalFound = true;
                        break;
                    }
                }
            }
            if (!intervalFound) {
                self.satisfaction('');
                $("#showSatisfactionImg" + self.encodeId).attr('d', '');
            }
        }
        if (self.satisfactionOnly()) {
            var smiley;
            var color;
            for (var i = 0; i < self.satisfactionIntervalColl().length; i++) {
                if (self.satisfactionIntervalColl()[i].intervalName === self.gradeSatisfaction() && !self.satisfactionIntervalColl()[i]._destroy) {
                    smiley = self.satisfactionIntervalColl()[i].smiley;
                    color = self.satisfactionIntervalColl()[i].color;
                    break;
                }
            }
            var smileySrc = self.fetchSmileySrc(smiley);
            $("#showSelectSatisfactionImg" + self.encodeId).attr('d', smileySrc);
            $("#showSelectSatisfactionImg" + self.encodeId).attr('fill', color);
        }
    };
    constructBaseTuple(seqNo, originalId, component, existing) {
        var self = this;
        var obj = {};
        obj.new = { seqNo: seqNo, id: originalId, component: component, existing: existing};
        if (existing) {
            obj.old = { seqNo: seqNo, id: originalId, component: component, existing: existing };
        }
        self.baseTuples.push(obj);
    }
    editBaseTuple(seqNo, originalId, component, existing) {
        var self = this;
        /*for (var i = 0; i < self.baseTuples.length; i++) {
            if (existing && self.baseTuples[i].new && self.baseTuples[i].new.id == originalId) {

            }
            else if (self.baseTuples[i].new && self.baseTuples[i].new.seqNo == seqNo) {
            }
        }*/
    }
    deleteBaseTuple(seqNo, originalId, component, existing) {
        var self = this;
        for (var i = 0; i < self.baseTuples.length; i++) {
            if (existing && self.baseTuples[i].new && self.baseTuples[i].new.id == originalId) {
                var obj = {};
                obj.old = { seqNo: self.baseTuples[i].old.seqNo, id: self.baseTuples[i].old.id, component: self.baseTuples[i].old.component, existing: self.baseTuples[i].old.existing };
                self.baseTuples.push(obj);
                self.baseTuples.splice(i, 1);
            }
            else if (self.deleteTuple && self.baseTuples[i].new && self.baseTuples[i].new.seqNo == seqNo) {
                self.baseTuples.splice(i, 1);
            }
        }
    }

    checkforStructuralChanges(){
        var self = this;
        if(self.model){
            if (self.model.get('valueType') == ValueType.Aggregated && self.baseTuples.length == 0) {
                self.structuralChangeCount++;
            }
            if (self.model.get('valueType') == ValueType.Atomic && self.baseTuples.length > 0) {
                self.structuralChangeCount++;
            }
            var satLevel = self.model.get("satisfactionLevel")
            if(!satLevel && self.intervalTuples.length > 0 && !self.model.get("isNominal")){
                self.structuralChangeCount++;
            }
            if(!self.model.get("percentageWeight") && (self.weight() != '' && self.weight() != null)){
                self.structuralChangeCount++;
            }
            if(!self.model.get("recipientOpinion") && (self.recipientValue() != '' && self.recipientValue() != null)){
                self.structuralChangeCount++;
            }
            if(satLevel && ((satLevel.get("isNominal") && self.satisfactionType() == self.satisfactionTypeOptions()[1]) || (!satLevel.get("isNominal") && self.satisfactionTypeOptions()[0]))){
                self.structuralChangeCount++;
            }
        }
    }

    constructSatisfactionIntervalTuple(seqNo, intervalName, intervalFrom, intervalTo, minOpen, maxOpen, smiley, color, actions , levelEnum) {
        var self = this;
        if (intervalFrom == '-Infinity') {
            intervalFrom = -Number.MAX_VALUE;
        }
        if (intervalTo == 'Infinity') {
            intervalTo = Number.MAX_VALUE;
        }
        var satisfactionTuple = { seqNo: seqNo, level: intervalName, min: intervalFrom, max: intervalTo, minOpen: minOpen, maxOpen: maxOpen, smiley: smiley, color: color, actions: actions ,levelEnum:levelEnum};
        self.intervalTuples.push(satisfactionTuple);
    };

    editSatisfactionIntervalTuple(seqNo, intervalName, intervalFrom, intervalTo, minOpen, maxOpen, smiley, color, actions) {
        var self = this;
        for (var i = 0; i < self.intervalTuples.length; i++) {
            if (intervalFrom == '-Infinity') {
                intervalFrom = -Number.MAX_VALUE;
            }
            if (intervalTo == 'Infinity') {
                intervalTo = Number.MAX_VALUE;
            }
            if (self.intervalTuples[i].seqNo == seqNo) {
                var tuple = self.intervalTuples[i];
                if (tuple.smiley !== smiley || tuple.color !== color || tuple.actions !== actions || tuple.level !== intervalName || tuple.min !== intervalFrom || tuple.max !== intervalTo || tuple.minOpen !== minOpen || tuple.maxOpen !== maxOpen) {
                    tuple.level = intervalName;
                    tuple.min = intervalFrom;
                    tuple.max = intervalTo;
                    tuple.minOpen = minOpen;
                    tuple.maxOpen = maxOpen;
                    tuple.smiley = smiley;
                    tuple.color = color;
                    tuple.actions = actions;
                    break;
                }
            }
        }
    }
    deleteSatisfactionIntervalTuple(seqNo) {
        var self = this;
        for (var i = 0; i < self.intervalTuples.length; i++) {
            var tuple = self.intervalTuples[i];
            if (tuple.seqNo === seqNo) {
                self.intervalTuples.splice(i, 1);
                i--;
                break;
            }
        }
    }
    checkComponentPrimed(component) {
        var self = this;
        var compQuantity;
        if(component.get("valueType") != ValueType.Atomic){
            compQuantity = self.calcPeriodDataset?self.calcPeriodDataset.get(component.id):null;
        } else {
            compQuantity = self.periodDataset?self.periodDataset.get(component.id):null;
        }
        if(compQuantity){
            return true;
        }
    };

    fetchSmileySrc(smileyValue) {
        var self = this;
        //var smileyValue = smileyValue?smileyValue:'1';
        var smileySrc = '';
        for (var j = 0; j < self.smilies().length; j++) {
            if (self.smilies()[j].value === smileyValue) {
                smileySrc = self.smilies()[j].src;
                break;
            }
        }
        return smileySrc;
    };
    refineIntervals(tuples, satisfactionType) {
        var self = this;
        var refinedIntervals = [];
        var seqNo = 0;
        var levelCounter = 1;
        var maxLevel = tuples.length ? tuples.reduce((max, item) => item.levelEnum > max ? item.levelEnum : max, 0) : null;
        for (var i = 0; i < tuples.length; i++) {
            var item = tuples[i];
            var refinedItem = {
                seqNo: seqNo++,
            };
            if (item.min !== "" && item.max !== "") {
                refinedItem.min = parseFloat(item.min);
                refinedItem.max = parseFloat(item.max);
                refinedItem.minOpen = item.minOpen;
                refinedItem.maxOpen = item.maxOpen;
            }
            if (satisfactionType === "Grade") {
                if(!item.levelEnum && maxLevel==0){ //initial case(create)
                    refinedItem.level = levelCounter++;
                }else if(!item.levelEnum && maxLevel>0){//updating intervals
                    refinedItem.level = maxLevel + 1;
                    maxLevel = maxLevel + 1;
                }else{
                    refinedItem.level = item.levelEnum
                }
                refinedItem.levelName = item.level;
            } else if (satisfactionType === "Ranking") {
                refinedItem.level = parseFloat(item.level);
                refinedItem.levelName = item.level;
            }
            if (item.smiley !== "") {
                refinedItem.smiley = item.smiley;
                refinedItem.color = item.color || null;
            }
            if (item.actions !== "") {
                refinedItem.actions = btoa(item.actions);
            }
            refinedIntervals.push(refinedItem);
        }
        return refinedIntervals;
    }
    fillSatisfactionDetails(satisfactionLevel){
        var self = this;
        var getSatisfactionType = satisfactionLevel ? satisfactionLevel.get('isNominal'):null;
        if (self.options.addOptions.type !== "createWhatValues" && satisfactionLevel) {
            self.visibleSatisfactionType(true);
            self.isDimensional = true;
            self.checkDimensional(true);
            if (getSatisfactionType) {
                self.satisfactionType("Grade");
            } else {
                if (!getSatisfactionType) {
                    var unitName = "";
                    var unit = satisfactionLevel.get('unit');
                    if (unit) {
                        unitName = unit.get('name');
                        self.satRoundingDigits(unit.get('roundingDigits') ? unit.get('roundingDigits') : 4);
                        self.satSignificantDecimals(unit.get('significantDecimals') ? unit.get('significantDecimals') : 2);
                    }
                    self.satisfactionUnitValue(unitName);
                    $('#satisfactionUnit' + self.encodeId).val(unitName);
                    self.satisfactionType("Ranking");
                }
            }
            var contextModel = satisfactionLevel.getValueContext(self.currentAlternativeModel.id);
            if(contextModel){
                var expressionConfig = contextModel.get('formula') ? contextModel.get('formula').get('expressionConfig') : null;
                if(expressionConfig){
                    var intervals = JSON.parse(expressionConfig);
                    self.fillSatisfactionValues(intervals);
                    self.showSatisfaction(true);
                }
            }
            var calcSatisfaction = self.calcPeriodDataset ? self.calcPeriodDataset.get(satisfactionLevel.id) : "";
            var satisfactionData = satisfactionLevel.getSatisfactionIntervalData(calcSatisfaction,self.currentAlternativeModel.id);
            if(satisfactionData){
                self.satisfaction(satisfactionData.levelName);
                var smileySrc = self.fetchSmileySrc(satisfactionData.smileyValue);
                self.satSmileySrc(smileySrc);
                var color = satisfactionData.smileyColor;
                self.satSmileyColor(color);
            }
        }
    }
    fillNominalValues(valModel){
        var self = this;
        if (self.nominalValue) {
            var valId = valModel.id;
            self.satisfactionType("Grade");
            var nominalContextModel = valModel.getValueContext(self.currentAlternativeModel.id);
            if(nominalContextModel){
                var expressionConfig = nominalContextModel.get('formula') ? nominalContextModel.get('formula').get('expressionConfig') : '';
                var intervals = JSON.parse(expressionConfig);
                self.fillSatisfactionValues(intervals);
            }
            self.satisfactionOnly(true);
            if(self.periodDataset){
                var qty = self.periodDataset.get(valId);
                var resultInterval = intervals.find(item => item.level === qty);
                var levelName = resultInterval ? resultInterval.levelName : ''; 
                self.gradeSatisfaction(levelName);
            }
        }
    }
    fillWeight(weightMeasurement){
        var self = this;
        if (weightMeasurement && self.periodDataset) {
            var weight = self.periodDataset.get(weightMeasurement.id);
            if (weight && weight !== "") {
                self.weight(parseFloat(weight));
            }
            else {
                self.weight(weight);
            }
        }
    }
    fillRecipientOpinion(recipientMeasurement){
        var self = this;
        if (!recipientMeasurement) {
            return;
        }
        if(recipientMeasurement && self.periodDataset){
            var recipientVal = self.periodDataset.get(recipientMeasurement.id);
            self.recipientValue(window.utils.getRoundedValues(recipientVal, self.significantDecimals()));
        }
    }
    fillAggregatedFrom(valModel){
        var self = this;
        if(valModel.get('valueType') == ValueType.Atomic){
            return;
        }
        if (self.existingValueModal && !valModel) {
            valModel = self.existingValueModal;
        }
        var components = valModel.getFromAggregations(self.currentAlternativeModel.id);
        var componentAddedCount = 0;
        var listOffsets = []
        if(components.length>0){
            components.each(function (component) {
                if (component) {
                    var formula = component.getValueFormula(self.currentAlternativeModel.id);
                    var offset = formula ? formula.get("offset") : 0;
                    listOffsets.push(offset);
                }
                var showComp = false;
                if (self.checkIsPlanValue(component.get('id'))) {
                    showComp = true;
                } else if (self.currentAlternativeModel.get('phaseDesignPart').findWhere({ 'beepReference': component.getNestedParent().get('id') })) {
                    showComp = true;
                }
                if (self.readOnly) {//for R.H.S
                    showComp = true;
                }
                if (valModel === component /*&& !self.currentAlternativeModel.getPreviousPhasePrimary()*/) {
                    showComp = false;
                }
                if (showComp) {
                    componentAddedCount++;
                    var componentPrimed = self.checkComponentPrimed(component);
                    self.expBuilderOptions.variables.push({variableId: DataManager.getDataManager().htmlEscape(component), name:component.get('name'), id:component.get('id')})
                    //if (componentPrimed) {
                        self.aggregateColl.push({ seqNo: '' + self.aggregateColl().length, escapeAggId: self.htmlEscape(component.id), originalId: component.id, aggregateid: component.id, aggregateValue: component.get('name'), aggregateSource: self.getSourceName(component), aggregateType: self.getSourceType(component), componentPrimed: componentPrimed });
                        self.constructBaseTuple('' + self.aggregateColl().length - 1, component.id, component, true);
                    //}
                }
            });
            self.maxAggregatedOffset = Math.max(...listOffsets);
            if(self.maxAggregatedOffset >= self.maxOffset() ) {
                self.maxOffset(self.maxAggregatedOffset);
            }
        }
        self.expBuilderOptions.variables.push({variableId: DataManager.getDataManager().htmlEscape(valModel), name:valModel.get('name'), id:valModel.get('id')})
        self.aggregateColl.sort(function (left, right) {
            return left.aggregateValue.toLowerCase() == right.aggregateValue.toLowerCase() ? 0 : (left.aggregateValue.toLowerCase() < right.aggregateValue.toLowerCase() ? -1 : 1)
        });
        if (componentAddedCount > 0) {
            self.enableMeasurement(false);
            self.showFormulaField(true);
        }
    }
    fillUnit(valModel,valueDefinition){
        var self = this;
        var unit = valModel.get("unit");
        if(!unit && valueDefinition){
            unit = valueDefinition.get("unit");
        }

        var valUnit = valueDefinition ? valueDefinition.get('unit') : unit;
        if (valUnit) {
            self.measurementUnit(valUnit.get('name'));
            $('#measurementUnit' + self.encodeId).val(valUnit.get('name'));
            self.roundingDigits(valUnit.get('roundingDigits') ? valUnit.get('roundingDigits') : 4);
            self.significantDecimals(valUnit.get('significantDecimals'));
        }
    }
    fillAggregatedTo(valModel){
        var self = this;
        //var aggToColl = valModel.get('aggregatedTo');
        var aggToColl = valModel.getToAggregations(self.currentAlternativeModel.id);
        if(aggToColl.length>0){
            aggToColl.each(function (agg) {
                var showComp = false;
                if (self.checkIsPlanValue(agg.get('id'))) {
                    showComp = true;
                } else if (self.currentAlternativeModel.get('phaseDesignPart').findWhere({ 'beepReference': agg.getNestedParent().get('id') })) {
                    showComp = true;
                }
                if (showComp) {
                    var type = self.getSourceType(agg) ? self.getSourceType(agg) : "";
                    var componentPrimed = self.checkComponentPrimed(agg);
                    self.aggregateToColl.push({ seqNo: '' + self.aggregateToColl.length, originalId: agg.get('id'), aggregateToValue: agg.get('name'), aggregateToSource: self.getSourceName(agg), aggregateToType: type, usePreviousRelation: false, componentPrimed: componentPrimed });
                }
            });
        }
        if (self.aggregateToColl().length > 0) {
            self.aggregateToColl.sort(function (left, right) {
                var leftStr = left.aggregateToValue.toLowerCase().replace(/[^a-z0-9-]/g, '');
                var rightStr = right.aggregateToValue.toLowerCase().replace(/[^a-z0-9-]/g, '');
                return left.aggregateToValue.toLowerCase() == right.aggregateToValue.toLowerCase() ? 0 : (leftStr < rightStr ? -1 : 1)
            });
        }
    }
    filterAggregateOptions(componentId) {
        var self = this;
        var len = self.aggregateOptions().length;
        while (len--) {
            if (self.aggregateOptions()[len].value === componentId) {
                self.aggregateOptions().splice(len, 1);
                if ($('#selectaggregateOptions' + self.encodeId)) {
                    $("#selectaggregateOptions" + self.encodeId + " option[value= '" + componentId + "']").remove();
                }
                break;
            }
        }
    }
    sortSatisfactionIntervals() {
        var self = this;
        self.satisfactionIntervalColl.sort(function (left, right) {
            return parseFloat(left.intervalFrom) === parseFloat(right.intervalFrom) ? 0 : (parseFloat(left.intervalFrom) < parseFloat(right.intervalFrom) ? -1 : 1);
        });
    }
    fillAggregateUIDetails(aggregate) {
        var self = this;
        if (self.aggregateEdit && aggregate) {
            var aggComp = self.fetchComponentById(aggregate);
            if (aggComp) {
                $('#aggregateSource' + self.encodeId).text(self.getSourceName(aggComp));
                $('#aggregateSourceType' + self.encodeId).text(self.getSourceType(aggComp));

                self.calculateAggregateValue();
                if (self.showValue()) {
                    self.aggregateEdit = false;
                }
            } else {
                var tempValuesForAggregation = self.options.addOptions.tempValuesForAggregation;
                if (tempValuesForAggregation) {
                    for (var i = 0; i < tempValuesForAggregation.length; i++) {
                        if (tempValuesForAggregation[i].id === aggregate) {
                            $('#aggregateSource' + self.encodeId).text('' + tempValuesForAggregation[i].source);
                            $('#aggregateSourceType' + self.encodeId).text('' + tempValuesForAggregation[i].sourceType);
                            $('#aggregateMeasurementValue' + self.encodeId).text("" + tempValuesForAggregation[i].value + ' ' + tempValuesForAggregation[i].unit);
                            break
                        }
                    }
                }
            }
        }
    }
    showSatisfactionOnly() {
        var self = this;
        self.showSelectSatisfaction(true);
        self.showSatisfaction(false);
        $("#showSatisfactionImg" + self.encodeId).attr('d', '');
        self.satisfactionUnitMark(false);
    }
    getSourceType(component) {
        var self = this;
        var type = "";
        var componentOwner;
        if (component.get('type') === "vdml_ValueAdd") {
            type = self.activityName;
        }
        else {
            if(component.get('type')=='vdml_ValueElement'){
                componentOwner = component.getParent().getParent();
                if (componentOwner.get('isValueFormula')) {
                    type = self.myPropositionName;
                }
                else {
                    if (componentOwner.get('planCriterionSetOwner')) {
                        type = 'Plan';
                    }
                    else {
                        type = self.valuePropositionName;
                    }
                }
            }else{
                componentOwner= component.get('componentOwner');
                if (componentOwner.get('isValueFormula')) {
                    type = self.myPropositionName;
                }
                else {
                    if (componentOwner.get('planCriterionSetOwner')) {
                        type = 'Plan';
                    }
                    else {
                        type = self.valuePropositionName;
                    }
                }
            }
            
        }
        return type;
    }
    getSourceName(component) {
        var self = this;
        var name;
        var componentOwner;
        if (component.get('type') == "vdml_ValueAdd") {
            name = component.get('valueAddOwner').get('containedPortOwner').get('name');
        }
        else {
            componentOwner = component.getParent();
            if(component.get('type')=='vdml_ValueElement'){
                componentOwner = component.getParent().get('componentOwner');
            }
            name = componentOwner.get('name');
            var valueProvider = componentOwner.get('provider');
            if (!valueProvider) {
                name = componentOwner.getNestedParent().get('name');
            }
        }
        return name;
    }

    getSubComponents(aggregatedColl,obj){
        if(aggregatedColl.get('percentageWeight')){
            obj.children.push({value:aggregatedColl.get('percentageWeight').get('id'),name:aggregatedColl.get('percentageWeight').get('name')})
        }

        if(aggregatedColl.get('satisfactionLevel') && !aggregatedColl.get('satisfactionLevel').get('isNominal')){
            obj.children.push({value:aggregatedColl.get('satisfactionLevel').get('id'),name:aggregatedColl.get('satisfactionLevel').get('name')})
        }

        if(aggregatedColl.get('recipientOpinion')){
            obj.children.push({value:aggregatedColl.get('recipientOpinion').get('id'),name:aggregatedColl.get('recipientOpinion').get('name')})
        }
        return obj
    }
    getAggregatedColl(){
        var self = this;
        var aggregatedColl = self.options.addOptions.aggregatedColl;
        if(!aggregatedColl){
            var aggregatedColl = new Backbone.Collection;
            var bm = self.options.addOptions.bm;
            if(bm){
                var allValues = [];
                bm.getValues(allValues,true);
                aggregatedColl.add(allValues);
                bm.fillPlanValues(aggregatedColl);
            }
        }
        return aggregatedColl;
    };

    fillAggregatedValues() {
        var self = this;
        var aggname;
        self.aggregateOptions([]);
        var tempValuesForAggregation = self.options.addOptions.tempValuesForAggregation;
        var aggregatedColl = self.getAggregatedColl();
        var dupList = [];
        var bm = null;
        if (self.parentView && self.parentView.get('type') === 'vdml_BusinessModel') {
            bm = self.parentView;
        }
        if (!bm && self.options.addOptions && self.options.addOptions.bm && self.options.addOptions.bm.get('type') === 'vdml_BusinessModel') {
            bm = self.options.addOptions.bm;
        }
        var type = self.options.addOptions.type;
        for (var i = 0; i < aggregatedColl.length; i++) {
            if (self.model && self.model.get('id') === aggregatedColl.at(i).get('id')) {
                var cond = false;//self.previousPhaseValueExists(aggregatedColl.at(i));
                if (cond) {
                    const obj = { value: self.model.get('id'), name: self.model.get('name'),children:[] }
                    self.aggregateOptions.push(obj);
                }
            }
            var aggregationValid = self.checkAggregationFiltering(bm, type, aggregatedColl.at(i));
            if (aggregationValid) {
                aggname = self.checkAggregateDuplicates(aggregatedColl, aggregatedColl.at(i), tempValuesForAggregation, dupList);
                const obj = { value: aggregatedColl.at(i).id, name: aggname,children:[] }
                const updObj =  self.getSubComponents(aggregatedColl.at(i),obj)
                self.aggregateOptions.push(updObj);
            }
        }
        self.calculateMeasurentValues();
        if (tempValuesForAggregation) {
            function checkDuplicateNameForTempAggregations(aggregatedColl, name, originalId) {
                for (var i = 0; i < aggregatedColl.length; i++) {
                    if (aggregatedColl.at(i).get('name') == name && aggregatedColl.at(i).get('id') !== originalId) {
                        return true;
                    }
                }
                return false;
            }
            for (var j = 0; j < tempValuesForAggregation.length; j++) {
                aggname = tempValuesForAggregation[j].name;
                var isNameAlreadyExist = checkDuplicateNameForTempAggregations(aggregatedColl, aggname, tempValuesForAggregation[j].originalId);
                if (isNameAlreadyExist) {
                    aggname = aggname.concat(" [" + tempValuesForAggregation[j].source + "]");
                }
                const obj = { value: tempValuesForAggregation[j].id, name: aggname,children:[] }
                //const updObj =  self.getSubComponents(tempValuesForAggregation[j],obj)
                self.aggregateOptions.push(obj);
                //self.addTempValuesUnits(tempValuesForAggregation[j].unit);
            }
        }
        self.aggregateOptions.sort(function (left, right) {
            return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1)
        });
    }
    checkAggregateDuplicates(models, element, tempModels, dupList) {
        var self = this;
        var path = element.get('name');
        var duplicateWithTempValueExist = false;
        if (tempModels) {
            for (var i = 0; i < tempModels.length; i++) {
                if (tempModels[i].name == path) {
                    duplicateWithTempValueExist = true;
                }
            }
        }
        function checkPath(path, fixDupPath) {
            var pathExists = _.find(self.aggregateOptions(), function (agg) { return agg.name == path + "]" });
            if (pathExists) {
                var dupModal = models.findWhere({ 'id': pathExists.id });
                if (dupModal && fixDupPath) {
                    dupList.push(path + "]");
                    var dupAggregatePath = dupModal.getPackagePath();
                    if (dupAggregatePath.length === 4) {
                        var newpath = dupModal.get('name') + "   [" + dupAggregatePath[0].name + "  /  " + dupAggregatePath[1].name + "  /  " + dupAggregatePath[2].name + "]";
                        pathExists.name = newpath;
                    }
                }
                return false;
            } else {
                if (dupList.indexOf(path + "]") != -1) {
                    return false;
                } else {
                    return true;
                }
            }
        }
        var phaseDesignPart = self.currentAlternativeModel.get('phaseDesignPart');
        for (var i = 0; i < models.length; i++) {
            if (duplicateWithTempValueExist || (element.get('name') === models.at(i).get('name') && (element.get('id') !== models.at(i).get('id'))) && phaseDesignPart.findWhere({ 'beepReference': models.at(i).getNestedParent().get('id') })) {
                var duplicateAggregatePath = models.at(i).getPackagePath();
                var aggregatePath = element.getPackagePath();
                if (element.get('type') === "vdml_ValueAdd" && element.get('valueAddOwner') && aggregatePath[1] && element.get('valueAddOwner').get('containedPortOwner')) {//To handle Activity values dupliates
                    aggregatePath[2].name = aggregatePath[1].name;
                    aggregatePath[1].name = element.get('valueAddOwner').get('containedPortOwner').get('performingRole').get('name');
                }
                path = path.concat("   [");
                if (aggregatePath.length === 2) {//to handle plan component values
                    if (aggregatePath[0].name.indexOf("Plan Criterion Set") == -1) {
                        path = path.concat(aggregatePath[0].name);
                    }
                    else {
                        path = path.concat(element.get('componentOwner').get('planCriterionSetOwner').get('name'));
                    }
                }
                else if (duplicateAggregatePath.length === 2 && aggregatePath[2]) {//to handle plan component values
                    path = path.concat(aggregatePath[2].name);
                }
                else {//to handle BM values
                    var pathSatisfied = false;
                    var pathAdded = false;
                    if (aggregatePath[2] && duplicateAggregatePath[2] && aggregatePath[2].name !== duplicateAggregatePath[2].name) {
                        path = path.concat(aggregatePath[2].name);
                        pathSatisfied = checkPath(path, !pathAdded);
                        pathAdded = true;
                    }
                    if (!pathSatisfied && aggregatePath[1].name !== duplicateAggregatePath[1].name) {
                        if (aggregatePath[2]) {
                            if (pathAdded) {
                                path = element.get('name') + "   [" + aggregatePath[1].name + "  /  " + aggregatePath[2].name;
                            } else {
                                path = path.concat(aggregatePath[1].name + "  /  " + aggregatePath[2].name);
                            }

                            pathSatisfied = checkPath(path, !pathAdded);
                            pathAdded = true;
                        }
                    }
                    if (!pathSatisfied && aggregatePath[0].name !== duplicateAggregatePath[0].name) {
                        if (aggregatePath[2]) {
                            if (pathAdded) {
                                path = element.get('name') + "   [" + aggregatePath[0].name + "  /  " + aggregatePath[1].name + "  /  " + aggregatePath[2].name;
                            } else {
                                path = path.concat(aggregatePath[0].name + "  /  " + aggregatePath[1].name + "  /  " + aggregatePath[2].name);
                            }
                            pathSatisfied = checkPath(path, !pathAdded);
                        }
                    }
                    if (!pathAdded && !pathSatisfied) {
                        path = path.concat('' + element.getNestedParent().get('name'));
                    }
                }
                if (!checkPath(path)) {
                    path = element.get('name');
                } else {
                    path = path.concat("]");
                    break;
                }
            }
        }
        return path;
    }
    /*addTempValuesUnits(unitName) {
        var self = this;
        if (unitName && !self.measureUnits.findWhere({ 'name': unitName })) {
            self.measureUnits.push({ name: '' + unitName, roundingDigits: '4', significantDecimals: '2' });
        }
    }*/
    checkIsPlanValue(aggId) {
        var self = this;
        var planType = false;
        if (DataManager.getDataManager().getRepositoryId(aggId) == window.plansKey) {
            planType = true;
        }
        return planType;
    }
    saveAggregateDetails() {
        var self = this;
        if(!self.selectedAggregate){
            return;
        }
        var dataManager = DataManager.getDataManager()
        var aggregate;
        if (self.aIndex == null && self.selectedAggregate()) {
            aggregate = self.fetchComponentById(self.selectedAggregate());
            if (aggregate) {                
                var componentPrimed = self.checkComponentPrimed(aggregate);
                self.aggregateColl.push({ seqNo: '' + self.aggregateColl().length, escapeAggId: self.htmlEscape(self.selectedAggregate()), originalId: '', aggregateid: self.selectedAggregate(), aggregateValue: aggregate.get('name'), aggregateSource: self.getSourceName(aggregate), aggregateType: self.getSourceType(aggregate),  componentPrimed: componentPrimed });
                self.expBuilderOptions.variables.push({variableId: dataManager.htmlEscape(aggregate), name:aggregate.get('name'), id:aggregate.get('id')})
                self.constructBaseTuple('' + self.aggregateColl().length - 1, aggregate.id, aggregate, false);
            }
            else {
                var tempValuesForAggregation = self.options.addOptions.tempValuesForAggregation;
                if (tempValuesForAggregation) {
                    for (var i = 0; i < tempValuesForAggregation.length; i++) {
                        if (tempValuesForAggregation[i].id === self.selectedAggregate()) {
                            self.aggregateColl.push({ seqNo: '' + self.aggregateColl().length, escapeAggId: self.htmlEscape(self.selectedAggregate()), originalId: '', aggregateid: self.selectedAggregate(), aggregateValue: tempValuesForAggregation[i].name, aggregateSource: tempValuesForAggregation[i].source, aggregateType: tempValuesForAggregation[i].sourceType, componentPrimed: tempValuesForAggregation[i].prime });
                            var tempCompId = tempValuesForAggregation[i].name + 'a' + tempValuesForAggregation[i].id + 'a';
                            self.expBuilderOptions.variables.push({variableId: dataManager.htmlEscape(tempCompId), name:tempValuesForAggregation[i].name, id:tempValuesForAggregation[i].id})
                            self.constructBaseTuple('' + self.aggregateColl().length - 1, self.selectedAggregate(), tempValuesForAggregation[i].id,  false);
                            self.tempValuesAggregated.push(self.selectedAggregate());
                            break;
                        }
                    }
                }
            }
        }
        else {
            if (self.selectedAggregate() || (self.tempAggregateId && self.tempAggregateId !== "")) {
                if (self.showValue()) {
                    aggregate = self.fetchComponentById(self.tempAggregateId);
                }
                else {
                    aggregate = self.fetchComponentById(self.selectedAggregate());
                }
                var aggName = aggregate ? aggregate.get('name') : '';
                var tempValuesForAggregation = self.options.addOptions.tempValuesForAggregation;
                if (!aggregate && tempValuesForAggregation) {
                    for (var i = 0; i < tempValuesForAggregation.length; i++) {
                        if (tempValuesForAggregation[i].id === self.tempAggregateId) {
                            aggName = tempValuesForAggregation[i].name;
                            break;
                        }
                    }
                }
                self.aggregateColl()[self.aIndex].aggregateValue = aggName;
                $("#aggregateValue" + self.aggregateColl()[self.aIndex].seqNo).text('' + self.aggregateColl()[self.aIndex].aggregateValue);
                $("#aggregateSource" + self.aggregateColl()[self.aIndex].seqNo).text('' + self.aggregateColl()[self.aIndex].aggregateSource);
                $("#aggregateType" + self.aggregateColl()[self.aIndex].seqNo).text('' + self.aggregateColl()[self.aIndex].aggregateType);
                var seqNo = self.aggregateColl()[self.aIndex].seqNo;
                for (var i = 0; i < self.baseTuples.length; i++) {
                    if (self.baseTuples[i].new && self.baseTuples[i].new.seqNo == seqNo) {
                        self.editBaseTuple(self.baseTuples[i].new.seqNo, self.baseTuples[i].new.id, self.baseTuples[i].new.component, self.baseTuples[i].new.existing);
                    }
                }
                self.aIndex = null;
            }
        }
        //self.aggregateDiv(true);
        if (self.showValue()) {
            $("#aggregateSource" + self.encodeId).text("");
            $("#aggregateSourceType" + self.encodeId).text("");
        }
        else {
            self.tempAggregateId = self.selectedAggregate();
        }
        self.calculateMeasurentValues();
        self.tempAggregateId = "";
        self.aggregateEdit = true;
        self.showValue(false);
        self.showAggregateDiv(false);
        if (window.utils.getObservableLength(self.aggregateColl())) {
            self.fillaggregationAndUnit();
            self.showFormulaField(true);
        }
        self.recheckOffset();
    }
    recheckOffset(){
        var self = this;
        var listOffsets = []
        for (var i = 0; i < self.aggregateColl().length; i++) {
            var aggregate = self.fetchComponentById(self.aggregateColl()[i].aggregateid);
            if (aggregate) {
                var formula = aggregate.getValueFormula(self.currentAlternativeModel.id);
                var offset = formula ? formula.get("offset") : 0;
                listOffsets.push(offset);
            }
        }
        self.maxAggregatedOffset = Math.max(...listOffsets);
        if(self.maxAggregatedOffset >= self.maxOffset() ) {
            self.maxOffset(self.maxAggregatedOffset);
        }
    }
    fillaggregationAndUnit() {
        var self = this;
        if (!self.measurementUnit()) {
            var aggModel = self.fetchComponentById(self.aggregateColl()[0].aggregateid);
            if (aggModel) {
                //var compValueMeasurement = self.periodDataset[aggModel.id];//aggModel.getMeasurement('valueMeasurement', useScenario);
                //if (compValueMeasurement) {
                    var valueUnit = aggModel.get('unit');
                    var compUnitName = valueUnit ? valueUnit.get('name') : "";
                    self.measurementUnit(compUnitName);
                    $('#measurementUnit' + self.encodeId).val(compUnitName);
                    if ($('#measurementUnit' + self.encodeId).val() !== "" && valueUnit) {
                        self.roundingFactor(true);
                        self.roundingDigits(valueUnit.get('roundingDigits'));
                        self.significantDecimals(valueUnit.get('significantDecimals'));
                    }
                //}
            } else {
                var tempValuesForAggregation = self.options.addOptions.tempValuesForAggregation;
                if (tempValuesForAggregation) {
                    for (var j = 0; j < tempValuesForAggregation.length; j++) {
                        if (tempValuesForAggregation[j].id === self.aggregateColl()[0].aggregateid) {
                            $('#measurementUnit' + self.encodeId).val(tempValuesForAggregation[j].unit);
                            self.measurementUnit(tempValuesForAggregation[j].unit);
                            break;
                        }
                    }
                }
            }
        }
    }
    saveChildData(view) {
        var self = this;
        var childExists = _.filter(self.valChildren, function (child) { if (child.view.model === view.model) { return child.view } });
        if (childExists.length > 0) {
            childExists[0].view = view;
        } else {
            if (view.options.addOptions.type !== "createWhatValues") {
                self.valChildren.push({ view: view, type: view.options.addOptions.type, bm: view.options.addOptions.bm, vp: view.options.parentView, aggregatedColl: view.options.addOptions.aggregatedColl });
            } else {
                self.valChildren.push({ view: view, type: view.options.addOptions.type, bm: view.options.addOptions.bm, activities: view.options.addOptions.activities, aggregatedColl: view.options.addOptions.aggregatedColl });
            }
        }
    };
    getAlternativeModel(altId) {
        var valueAlternativeModel;
        if (!DataManager.getDataManager().get('currentPlan')) {
            var phase = this.currentAlternativeModel.get('phaseAlternativeOwner').get('phaseOwner').get('phase');
        } else {
            phase = DataManager.getDataManager().get('currentPlan').get('phase');
        }
        this.currentAlternativeModel.get('phaseAlternativeOwner').get('phaseOwner')
        for (var j = 0; j < phase.length; j++) {
            var alts = phase.at(j).get('phaseAlternative');
            var viewAlternativeModel = alts.findWhere({ 'id': altId });
            if (viewAlternativeModel) {
                valueAlternativeModel = viewAlternativeModel;
                break;
            }
        }
        return valueAlternativeModel;
    };
    openValueHandler(view, event) {
        var self = this;
        var parentValType = self.options.addOptions.type;
        var id = self.htmlEscape(view.originalId) + parentValType;
        var aggregatedColl = self.options.addOptions.aggregatedColl;
        var bm;
        if (self.parentView.get('type') === 'vdml_BusinessModel' && !self.checkIsPlanValue(self.model.get('id'))) {
            bm = self.parentView;
        }
        else if (self.options.addOptions && self.options.addOptions.bm && self.options.addOptions.bm.get('type') === 'vdml_BusinessModel' && !self.checkIsPlanValue(self.model.get('id'))) {
            bm = self.options.addOptions.bm;
        }
        var aggToCompModel = self.fetchComponentById(view.originalId);
        var valueAlternativeModel = self.currentAlternativeModel;
        var valueAltId = self.currentAlternativeModel.id;
        if (aggToCompModel.getNestedParent() !== DataManager.getDataManager().get('currentPlan')) {
            valueAltId = DataManager.getDataManager().getRepositoryId(view.originalId);
        }
        var readOnly;
        if (self.currentAlternativeModel.id != valueAltId || view.usePreviousRelation || self.disableAggr) {
            var altId = view.altId ? view.altId : valueAltId;
            valueAlternativeModel = self.getAlternativeModel(altId);
            readOnly = true;
        }
        var scenario = valueAlternativeModel.getMainScenario(aggToCompModel.getNestedParent());
        var addOptions;
        var aggTo = true;

        if (aggToCompModel.get('type') == "vdml_ValueAdd") {
            var actModel = aggToCompModel.get('valueAddOwner').get('containedPortOwner');
            var actObj = [{ originalId: actModel.get('id'), modalId: self.htmlEscape(actModel.get('id')), name: actModel.get('name') }];
            addOptions = { 'type': 'createWhatValues', 'aggTo': aggTo, 'activities': actObj, 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, view: self, 'bm': bm, 'currentAlternativeModel': valueAlternativeModel, 'readOnly': readOnly };
        } else {
            var valType = 'valueProposition';
            if (aggToCompModel.getParent().get('isValueFormula')) {
                valType = 'valueFormula';
            }
            else if (aggToCompModel.getNestedParent() == DataManager.getDataManager().get('currentPlan')) {
                valType = 'planValues';
            }
            addOptions = { 'type': valType, 'aggTo': aggTo, 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'overAllSatisfaction': false, 'bm': bm, 'view': self, 'currentAlternativeModel': valueAlternativeModel, 'readOnly': readOnly };
        }
        if ($.find("#modal" + id).length > 0) {
            $("#modal" + id).modal('show');
        }
        else {
            window.getAndCreateModalDialog(self.model, id, BusinessModel2Mixin, aggToCompModel, "ValueDetails", _.bind(self.saveChildData, self), addOptions);
        }
    }
    editAggregateDetailsHandler(view, event) {
        var self = this;
        $("#aggregateValue" + self.encodeId).text(view.aggregateValue);
        self.aggregateEdit = true;
        self.showValue(true);
        for (var i = 0; i < self.aggregateColl().length; i++) {
            if (self.aggregateColl()[i].seqNo === view.seqNo) {
                self.tempAggregateId = view.aggregateid;
                self.aIndex = i;
                self.fillAggregateUIDetails(self.tempAggregateId);
                self.showAggregateDiv(true);
                self.componentPrimed(true);
                //self.aggregateDiv(false);
                break;
            }
        }
    };
    deleteAggregateDetailsHandler(view) {
        var self = this;
        for (var i = 0; i < self.aggregateColl().length; i++) {
            if (self.aggregateColl()[i].seqNo === view.seqNo) {
                var tempView = self.aggregateColl()[i];
                if (tempView) {
                    var found = false;
                    self.aggregateColl.replace(self.aggregateColl()[i], tempView);
                    self.aggregateColl.destroy(self.aggregateColl()[i]);
                    self.aggregateOptions.sort(function (left, right) {
                        return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1)
                    });
                    for (var j = 0; j < self.baseTuples.length; j++) {
                        if (self.baseTuples[j].new && self.baseTuples[j].new.seqNo == view.seqNo) {
                            self.deleteTuple = true;
                            self.deleteBaseTuple(self.baseTuples[j].new.seqNo, self.baseTuples[j].new.id, self.baseTuples[j].new.component, self.baseTuples[j].new.existing);
                            found = true;
                            break;
                        }
                    }
                    if (found) {
                        break;
                    }
                }
            }
        }
        self.calculateMeasurentValues();
        self.validateExp = true;
        self.recheckOffset();
    }

    validateValueExpression(formula,options,callback){
        var self = this;
        DataManager.getDataManager().validateExpression(formula,options,self.model,function(response){
            var expValid = false;
            if(response && response.isValid){
                self.formulaErrorMsg('');
                expValid = true;
            } else {
                self.formulaErrorMsg(response.error);
                expValid = false;
            }
            if(expValid){
                callback();
            }
        });
    }
    saveValueCallback(){
        var self = this;
        if (self.name() !== null && self.name().trim() !== "" && $('#message' + self.encodeId).text().trim().length < 1) {
            self.name(self.name().trim());
            self.checkforStructuralChanges();
            $('#modal'+self.options.modalId).modal('hide');
            return self.options.callback(self, self.encodeId, self.options.addOptions.type, null, self.options.addOptions.disableDelete);
        }
    }

    saveValueDetailsHandler() {
        var self = this;
        if (self.parentView) {
            var formulaSplits
            if(self.formula().length > 0) {
                if(self.formula().includes("==") && !self.formula().includes("if")){
                    formulaSplits =  self.formula().split("==")
                    formulaSplits.map(d=>{
                        var splitParts = d.split(/\s*[\+\-\*\/><]\s*/).map(s => s.trim()).filter(Boolean);
                        let updOptions = self.expBuilderOptions.variables.filter(item => splitParts.includes(item.name));
                        self.validateValueExpression(d,updOptions,function(){
                            self.saveValueCallback();
                        });
                    })
                }else {
                    self.validateValueExpression(self.formula(),self.expBuilderOptions.variables,function(){
                        self.saveValueCallback();
                    });
                }
            } else {
                self.saveValueCallback();
            }
        }
    };
    saveValChildrenData(valChildren, callback) {
        var self = this;
        async.eachSeries(self.valChildren, function (child, saveChildCallback) {
            if (child.type !== "createWhatValues" && child.view.createValue) {
                child.view.createValue(child.vp, function () {
                    window.cleanDialogModel(child.view.encodeId, child.view);
                    saveChildCallback();
                });
            } else {
                if (child.view.saveWhatValuesData) {
                    child.view.saveWhatValuesData(child.bm, child.activities, [], function () {
                        window.cleanDialogModel(child.view.encodeId, child.view);
                        saveChildCallback();
                    });
                }
                else {
                    saveChildCallback();
                }
            }
        }, function (err) {
            callback();
        });
    }
    saveWhatValuesData(bm, activityColl, activityValueColl, callback, tempValuesForAggregation) {
        var self = this;
        self.saveValChildrenData(self.valChildren, function () {
            var activityModal;
            var bmActivityModal;
            var activityId;
            if (bm.get('type') === "vdml_Activity") {
                activityModal = bm;
            }
            else {
                for (var j = 0; j < activityColl.length; j++) {
                    if (activityColl[j].modalId === self.selectedActivity() && !activityColl[j]._destroy) {
                        activityId = activityColl[j].originalId;
                        break;
                    }
                }
                var activities = bm.get("bmActivity");
                for (var i = 0; i < activities.models.length; i++) {
                    if (activities.models[i].get('activity').get('id') === activityId) {
                        bmActivityModal = activities.models[i];
                        activityModal = activities.models[i].get('activity');
                        break;
                    }
                }
            }
            var activityValue;
            self.setComponentforBaseTuples(tempValuesForAggregation, self.baseTuples);
            if (!self.model && !self.existingValueModal) {
                var currentAlternativeModel = self.currentAlternativeModel;
                var activityValueName = self.name().trim();
                var measurementValue = self.measurementValue();
                if (self.enableMeasurement() && !isNaN(self.measurementValue())) {
                    measurementValue = self.fullMeasurementValue ? self.fullMeasurementValue : self.measurementValue();
                }
                var measurementUnit = self.measurementUnit();
                var roundingDigits = self.roundingDigits();
                var significantDecimals = self.significantDecimals();
                var formula = self.formula();
                var baseTuples = self.baseTuples;
                self.baseTuples = null;
                var valConstraintObj = { maxBound:self.maxBound(), minBound: self.minBound(), equalValue: self.equalValue(), bigM : self.bigM()}

                var outputPort = bmActivityModal ? bmActivityModal.get('defaultOutput') : null;
                var activity = bmActivityModal ? bmActivityModal.get('activity') : activityModal;
                var outputPortName = '';
                if (!outputPort) {
                    outputPortName = activity.getUniqueProperty('name', activity.get('name'), 'containedPort') + " port";
                } else {
                    outputPortName = outputPort.get('name');
                }
                var collaboration = activity.getParent();
                var vdml = collaboration.getNestedParent();
                var namePath = vdml.get('name') + '>' + collaboration.get('name') + '>' + activity.get('name') + '>' + outputPortName + '>' + activityValueName;
                vdml.getDocumentWithNamePath(namePath, "vdml_ValueAdd", self.currentAlternativeModel, true, vdml, function (results) {
                    if (results && results[0]) {
                        var doc = results[0].doc;
                        var parent = results[0].parent;
                        //var newValueAddIdSuffix = window.utils.getSuffix(doc);
                        var altId = DataManager.getDataManager().getRepositoryId(doc);
                        var vdmStore = DataManager.getDataManager().getVDMStore(altId);
                        var version = DataManager.getDataManager().get('currentVDMVersion');
                        DataManager.getDataManager().fetchDocumentFromPackage(parent, "appbo/vdml/ValueDeliveryModel", version, doc, "appbo/vdml/ValueAdd", vdmStore, {
                            success: function (valModel) {
                                createActivityValue(valModel, activityValueName);
                            },
                            error: function (error) {
                                console.log('Unable to load Value document');
                                createActivityValue(null, activityValueName);
                            }
                        });
                    } else {
                        createActivityValue(null, activityValueName);
                    }
                });
                function createActivityValue(oldValModel, activityValueName) {
                    var valueExpression = utils.replaceNamesWithIds( self.formula(),self.expBuilderOptions.variables);
                    var offset = parseInt(self.maxOffset());
                    //var expressionConfig = valuesConfig ? valuesConfig.values : null;
                    activityValue = activityModal.createWhatValues(self.year,self.period,currentAlternativeModel, activityValueName, measurementValue, { unitName: measurementUnit, roundingDigits: roundingDigits, significantDecimals: significantDecimals }, {'valueExpression':valueExpression,'offset':offset}, baseTuples, bmActivityModal, oldValModel,self.usedProjection,valConstraintObj);
                    if (self.usedValue) {
                        var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue });
                        if (valueDef) {
                            activityValue.set('valueDefinition', valueDef);
                        }
                    } else {
                        activityValue.set('valueDefinition', null);
                    }
                    self.setComponentforTempAgg(tempValuesForAggregation, activityValue);
                    updateUI(true);
                }
            }
            else {
                if (self.existingValueModal) {
                    self.model = self.existingValueModal;
                    self.setComponentforTempAgg(tempValuesForAggregation);
                    if (bmActivityModal) {
                        bmActivityModal.get('activityValue').add(self.existingValueModal);
                    }
                }

                activityValue = self.model;
                if (self.usedValue) {
                    var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue });
                    if (valueDef) {
                        self.model.set('valueDefinition', valueDef);
                    }
                } else {
                    self.model.set('valueDefinition', null);
                }
                self.expBuilderOptions.variables.push({variableId:DataManager.getDataManager().htmlEscape(self.model),name:self.model.get('name'), id:self.model.get('id')})
                var valueExpression = utils.replaceNamesWithIds( self.formula(),self.expBuilderOptions.variables);
                var valConstraintObj = { maxBound:self.maxBound(), minBound: self.minBound(), equalValue: self.equalValue(), bigM: self.bigM()}
                var offset = parseInt(self.maxOffset());
                self.model.update(self.period, self.year, self.name().trim(), self.measurementValue(), { unitName: self.measurementUnit(), roundingDigits: self.roundingDigits(), significantDecimals: self.significantDecimals(), unitObj: self.measureUnits.findWhere({ 'name': self.measurementUnit() }) }, {'valueExpression':valueExpression,'offset':offset}, self.baseTuples, self.currentAlternativeModel, self.usedProjection,valConstraintObj, updateUI);
            }
            function updateUI(changeInValue) {
                for (var k = 0; k < activityValueColl.length; k++) {
                    if (activityValueColl[k].modalId === self.encodeId) {
                        activityValueColl[k].originalId = activityValue.get("id");
                        break;
                    }
                }
                bmActivityModal && bmActivityModal.get('activityValue').add(activityValue);
                callback(activityValue, changeInValue);
            }
        });
    }
    saveData(bm, vp, callback, tempValuesForAggregation) {
        var self = this;
        self.saveValChildrenData(self.valChildren, createValue, tempValuesForAggregation);
        function createValue() {
            return self.createValue(vp, callback, tempValuesForAggregation);
        }
    }
    setComponentforBaseTuples(tempValuesForAggregation, baseTuples) {
        var self = this;
        var len = self.baseTuples.length;
        while (len--) {
            if (self.baseTuples[len].new && typeof self.baseTuples[len].new.component === 'string') {
                if (tempValuesForAggregation) {
                    for (var j = 0; j < tempValuesForAggregation.length; j++) {
                        if (tempValuesForAggregation[j].id === self.baseTuples[len].new.component) {
                            if (!tempValuesForAggregation[j].component) {
                                self.baseTuples.splice(len, 1);
                            } else {
                                self.baseTuples[len].new.component = tempValuesForAggregation[j].component;
                            }
                            break;
                        }
                    }
                }
            }
        }
    }
    setComponentforTempAgg(tempValuesForAggregation, actModel) {
        var self = this;
        if (tempValuesForAggregation) {
            var component = actModel ? actModel : self.model;
            for (var i = 0; i < tempValuesForAggregation.length; i++) {
                if (tempValuesForAggregation[i].id === self.encodeId) {
                    tempValuesForAggregation[i].component = component;
                    break;
                }
            }
            _.each(self.expBuilderOptions.variables,function(variableObj){
                var valId = variableObj.variableId.replaceAll('_','-');
                for (var i = 0; i < tempValuesForAggregation.length; i++) {
                    if (tempValuesForAggregation[i].component.get('name') + 'a' + tempValuesForAggregation[i].id + 'a' == valId && tempValuesForAggregation[i].component) {
                        variableObj.variableId = DataManager.getDataManager().get('currentPlan').htmlEscape(tempValuesForAggregation[i].component);
                        break;
                    }
                }
			});
        }
    }
    fetchNamePathValue(count, results, component, valueName, callback) {
        var self = this;
        var doc = results[count].doc;
        var parent = results[count].parent;
        var altId = DataManager.getDataManager().getRepositoryId(doc);
        var vdmStore = DataManager.getDataManager().getVDMStore(altId);
        var version = DataManager.getDataManager().get('currentVDMVersion');
        DataManager.getDataManager().fetchDocumentFromPackage(parent, "appbo/vdml/ValueDeliveryModel", version, doc, "appbo/vdml/ValuePropositionComponent", vdmStore, {
            success: function (valModel) {
                if (window.utils.getSuffix(valModel.getParent().id) === window.utils.getSuffix(component.id)) {
                    callback(component, valModel, valueName);
                } else {
                    count++;
                    if (results[count]) {
                        self.fetchNamePathValue(count, results, component, valueName, callback);
                    } else {
                        callback(component, null, null, valueName);
                    }
                }
            },
            error: function (error) {
                console.log('Unable to load Value document');
                callback(null, null, valueName);
            }
        });
    }
    
    createValue(vp, callback, tempValuesForAggregation) {
        var self = this;
        var measurementValue = self.measurementValue();
        if (self.enableMeasurement() && !isNaN(self.measurementValue())) {
            measurementValue = self.fullMeasurementValue ? self.fullMeasurementValue : self.measurementValue();
        }
        self.setComponentforBaseTuples(tempValuesForAggregation, self.baseTuples);
        if (!self.model) {
            var valueName = self.name().trim();
            var providerRole = vp.get('provider');
            if (providerRole) {
                var collaboration = providerRole.getParent();
                var vdml = collaboration.getNestedParent();
                var namePath = vdml.get('name') + '>' + collaboration.get('name') + '>' + providerRole.get('name') + '>' + vp.get('name') + '>' + valueName;
                vdml.getDocumentWithNamePath(namePath, "vdml_ValuePropositionComponent", self.currentAlternativeModel, true, vdml, function (results) {
                    if (results && results[0]) {
                        self.fetchNamePathValue(0, results, vp, valueName, createVPC);
                    } else {
                        createVPC(vp, null, valueName);
                    }
                });
            } else {
                createVPC(vp, null, valueName);
            }
            function createVPC(vp, oldVpc, valueName) {
                var newVPCIdSuffix = oldVpc ? window.utils.getSuffix(oldVpc.id) : null;
                self.model = vp.createValuePropositionComponent(valueName, valueName, false, newVPCIdSuffix);
                //var vdm = vp.getNestedParent();
                //var createSatisfaction = self.satisfactionIntervalColl.length>0 && self.checkDimensional();
                //vdm.createValueDetails(self.model, oldVpc, createSatisfaction, self.weight(), self.recipientValue());
                if (self.usedValue) {
                    var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue });
                    if (valueDef) {
                        self.model.set('valueDefinition', valueDef);
                    }
                } else {
                    self.model.set('valueDefinition', null);
                }
        
                self.setComponentforTempAgg(tempValuesForAggregation);
                //self.model.addBaseTuple(self.baseTuples);
                var intervalTuples = self.refineIntervals(self.intervalTuples,self.satisfactionType());
                var satisfactionValue = self.checkDimensional() ? self.satisfaction() : intervalTuples.find(item => item.levelName === self.satisfaction()).level;
                var valUnitObj = { unitName: self.measurementUnit(), roundingDigits: self.roundingDigits(), significantDecimals: self.significantDecimals(), unitObj: self.measureUnits.findWhere({ 'name': self.measurementUnit() }) };
                var valConstraintObj = { maxBound:self.maxBound(), minBound: self.minBound(), equalValue: self.equalValue(), bigM : self.bigM()}
                var satUnitObj = { unitName: self.satisfactionUnitValue(), roundingDigits: self.satRoundingDigits(), significantDecimals: self.satSignificantDecimals(), unitObj: self.measureUnits.findWhere({ 'name': self.satisfactionUnitValue() }) };
                self.expBuilderOptions.variables.push({variableId:DataManager.getDataManager().htmlEscape(self.model),name:self.model.get('name'), id:self.model.get('id')})
                var valueExpression  = utils.replaceNamesWithIds( self.formula(),self.expBuilderOptions.variables);
                var offset = parseInt(self.maxOffset());
                self.model.prime(self.year, self.period, measurementValue, valUnitObj, {'valueExpression':valueExpression,'offset':offset}, satisfactionValue, self.satisfactionType(), self.checkDimensional(), satUnitObj, self.weight(), self.checkDimensional() ? self.recipientValue() : self.recipientSatValue(), self.baseTuples, intervalTuples, self.currentAlternativeModel, self.usedProjection,valConstraintObj, function (value) {
                    updateAggregation(value);
                });
        
            }
        }
        else {
            if (self.usedValue) {
                var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue });
                if (valueDef) {
                    self.model.set('valueDefinition', valueDef)
                }
            } else {
                self.model.set('valueDefinition', null);
            }
            var intervalTuples = self.refineIntervals(self.intervalTuples,self.satisfactionType());
            //self.model.addBaseTuple(self.baseTuples, measureChange);
            var satisfactionValue = self.checkDimensional() ? self.satisfaction() : intervalTuples.find(item => item.levelName === self.satisfaction()).level;
            var valUnitObj = { unitName: self.measurementUnit(), roundingDigits: self.roundingDigits(), significantDecimals: self.significantDecimals(), unitObj: self.measureUnits.findWhere({ 'name': self.measurementUnit() }) };
            var satUnitObj = { unitName: self.satisfactionUnitValue(), roundingDigits: self.satRoundingDigits(), significantDecimals: self.satSignificantDecimals(), unitObj: self.measureUnits.findWhere({ 'name': self.satisfactionUnitValue() }) };
            var constraintObj = {maxBound:self.maxBound(),minBound:self.minBound(),equalValue:self.equalValue(),bigM:self.bigM()}
            self.expBuilderOptions.variables.push({variableId:DataManager.getDataManager().htmlEscape(self.model),name:self.model.get('name'), id:self.model.get('id')})
            var valueExpression  = utils.replaceNamesWithIds( self.formula(),self.expBuilderOptions.variables);
            var offset = parseInt(self.maxOffset());
            self.model.update(self.period, self.year, self.name(), measurementValue, valUnitObj, {'valueExpression':valueExpression,'offset':offset}, self.satisfactionType(), self.checkDimensional(), satisfactionValue, satUnitObj, self.weight(), self.checkDimensional() ? self.recipientValue() : self.recipientSatValue(), self.baseTuples, intervalTuples, self.currentAlternativeModel,self.usedProjection,constraintObj, function (value) {
                updateAggregation(value)
            });
        }
        function updateAggregation(changeInValue) {
            if (callback) {
                callback(changeInValue);
            }
        }
    }
    cleanModalHandler() {
        var self = this;
        self.valChildren = [];
        if (!self.enableDelete()) {
            window.cleanDialogModel(self.encodeId, this);
        }
        if (self.planValue) {
            window.cleanDialogModel(self.encodeId, this);
        }
        if (self.dialogOpenCount == 1 && self.model) {
            window.cleanDialogModel(self.encodeId, this);
        }
    }
    disableUIComponents(aggTo) {
        var self = this;
        self.enableMeasurement(false);
        self.enableSatisfaction(false);
        self.enableSatisfactionType(false);
        $('#satisfactionUnit' + self.encodeId).prop('disabled', true);
        $('#measurementUnit' + self.encodeId).prop('disabled', true);
        self.roundingFactor(false);
        $('#satisfactionIntervalAddIconDiv' + self.encodeId).hide();
        $("icon[id*='satisfactionIntervalDeleteIcon']").hide();
        self.aggregateAddIconDiv(false);
        $('#valueName' + self.encodeId).prop('disabled', true);
        if (!aggTo) {
            self.enableWeight(false);
            self.enableDelete(false);
            $('#formula' + self.encodeId).prop('disabled', true);
            self.enableRecipientOpinion(true);
        }
    }
    fillSatisfactionValues(intervals) {
        var self = this;
        self.satisfactionIntervalColl([]);
        
        if (intervals && intervals.length) {
            intervals.forEach(function (interval) {
                var name;
                if (interval.levelName) {
                    name = interval.levelName;
                } else {
                    name = window.utils.getRoundedValues(interval.value, self.satSignificantDecimals()); // Fallback to rounded values
                }
                var satValue = self.measurementValue() === "" ? "" : name;
                var intervalFrom = interval.min;
                var intervalTo = interval.max;
                if (intervalTo === Number.MAX_VALUE) {
                    intervalTo = 'Infinity';
                }
                if (intervalFrom === -Number.MAX_VALUE) {
                    intervalFrom = '-Infinity';
                }
                var smiley = interval.smiley || '';
                var color = interval.color || 'rgb(0,0,0)';
                var smileySrc = self.fetchSmileySrc(smiley);
                var actions = utils.encodeDecodeBase64(interval.actions) || '';
                var levelEnum = interval.level;
    
                self.satisfactionIntervalColl.push({
                    seqNo: '' + self.satisfactionIntervalColl().length,
                    intervalName: name,
                    satisfactionValue: satValue,
                    intervalFrom: intervalFrom,
                    intervalTo: intervalTo,
                    fromInclusive: interval.minOpen,
                    toInclusive: interval.maxOpen,
                    smiley: smiley,
                    color: color,
                    smileySrc: smileySrc,
                    actions: actions,
                    uniqueId:window.utils.htmlEscape(window.guidGenerator()),
                    levelEnum: levelEnum
                });
                self.constructSatisfactionIntervalTuple( '' + (self.satisfactionIntervalColl().length - 1), name, interval.min, interval.max, interval.minOpen, interval.maxOpen, smiley, color, actions,levelEnum);
            });
        }
        
        self.sortSatisfactionIntervals();
        /*if (self.postShow) {
            self.calculateSatisfaction();
        }*/
    }
    
    editSatisfactionIntervalHandler(view, event) {
        var self = this;
        self.intervalNameDuplicateMsg('');
        for (var i = 0; i < self.satisfactionIntervalColl().length; i++) {
            if (self.satisfactionIntervalColl()[i].seqNo === view.seqNo) {
                self.intervalName(view.intervalName);
                self.intervalFrom(view.intervalFrom);
                self.intervalTo(view.intervalTo);
                self.checkFromInclusive(view.fromInclusive);
                self.checkToInclusive(view.toInclusive);
                if (view.intervalFrom == "-Infinity" || view.intervalFrom == -Number.MAX_VALUE) {
                    self.negativeInfinity(true);
                    $('#intervalFromShow' + self.encodeId).text('-Infinity');
                    self.showIntervalFromUI(false);
                }
                else {
                    self.showIntervalFromUI(true);
                    self.negativeInfinity(false);
                }
                if (view.intervalTo == "Infinity" || view.intervalTo == Number.MAX_VALUE) {
                    self.positiveInfinity(true);
                    $('#intervalToShow' + self.encodeId).text('Infinity');
                    self.showIntervalToUI(false);
                }
                else {
                    self.positiveInfinity(false);
                    self.showIntervalToUI(true);
                }
                var smileyValue = view.smiley;
                var smileySrc = self.fetchSmileySrc(smileyValue);
                if (smileySrc) {
                    $('#intervalSmiley' + self.encodeId).parent().show();
                }
                $("#intervalSmileyPath" + self.encodeId).attr('d', smileySrc);
                $("#intervalSmiley" + self.encodeId).attr('value', smileyValue);
                $("#intervalSmiley" + self.encodeId).attr('fill', view.color);
                self.actions('' + view.actions);
                self.addColorsToPalette();
                //$('#cp'+self.encodeId).find( "i" ).css('background-color',view.color);
                //    self.constructInterval(view.intervalName,view.intervalFrom,view.intervalTo);   TODO
                self.satisfactionIntervalDiv(true);
                // self.calculateSatisfaction();
                self.sIndex = i;
                break;
            }
        }
    };
    fetchComponentById(aggregateId) {
        var self = this;
        var aggComp = window.utils.getElementModel(aggregateId,['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']);
        return aggComp;
    }
    calculateAggregateValue() {
        var self = this;
        var cond = false;
        var id = self.selectedAggregate();
        if (self.aIndex !== null) {
            id = self.aggregateColl()[self.aIndex].aggregateid;
        }
        if (id) {
            var compQuantity = null;
            var component = self.fetchComponentById(id);
            if (self.model === component) {
                cond = true;
            }
            if (component) {
                var compAltId = DataManager.getDataManager().getRepositoryId(component.get('id'));
                var compAlt = self.currentAlternativeModel;
                if (self.readOnly && compAltId != window.plansKey && compAltId != self.currentAlternativeModel.id) {
                    compAlt = self.getAlternativeModel(compAltId);
                }
            }
            if (component && cond) {
                var previousAlt = compAlt.getPreviousPhasePrimary();
                if (!previousAlt) {
                    return;
                }
                if (previousAlt.get('phaseDesignPart').findWhere({ 'beepReference': component.getNestedParent().get('id') })) {
                    if(component.get("valueType") != ValueType.Atomic){//fetch correct periodDataset
                        compQuantity = self.calcPeriodDataset?self.calcPeriodDataset.get(component.id):null;
                    } else {
                        compQuantity = self.periodDataset?self.periodDataset.get(component.id):null;
                    }
                } else {
                    var aggCompmodel = previousAlt.getModelLinkedSync(component);
                    if (aggCompmodel) {//fetch correct periodDataset
                        if(aggCompmodel.get("valueType") != ValueType.Atomic){
                            compQuantity = self.calcPeriodDataset?self.calcPeriodDataset.get(aggCompmodel.id):null;
                        } else {
                            compQuantity = self.periodDataset?self.periodDataset.get(aggCompmodel.id):null;
                        }
                    }
                }
            }
            else if (component) {
                if(component.get("valueType") != ValueType.Atomic){
                    compQuantity = self.calcPeriodDataset?self.calcPeriodDataset.get(component.id):null;
                } else {
                    compQuantity = self.periodDataset?self.periodDataset.get(component.id):null;
                }
            }
            if (compQuantity) {
                var unit = component.get('unit') ? component.get('unit') : null;
                var unitName = unit ? unit.get('name') : "";
                var compSignificantDecimal = unit ? unit.get('significantDecimals') : self.significantDecimals();
                var roundValue = window.utils.getRoundedValues(compQuantity, compSignificantDecimal);
                if (roundValue != '') {
                    $('#aggregateMeasurementValue' + self.encodeId).text("" + roundValue + " " + unitName);
                } else {
                    $('#aggregateMeasurementValue' + self.encodeId).text("");
                }
            }
            else {
                $('#aggregateMeasurementValue' + self.encodeId).text("");
            }
        }
    }
    addColorsToPalette() {
        var self = this;
        $("#cp" + self.encodeId + ' input').val(self.previouslyUsedColor);
        $('#colors-wrapper1' + self.encodeId).empty();
        var color = $("#intervalSmiley" + self.encodeId).attr('fill');
        self.colorsList.push(color);
        $.unique(self.colorsList);
        $.each(self.colorsList, function (it, value) {
            var wrapper_id = 'w' + it + self.encodeId;
            if (value === color) {
                $('<div class="dwrapper" type="button" id="' + wrapper_id + '" rel="' + value + '" active="active"> </div>').appendTo('#colors-wrapper1' + self.encodeId);
            } else {
                $('<div class="dwrapper" type="button" id="' + wrapper_id + '" rel="' + value + '"> </div>').appendTo('#colors-wrapper1' + self.encodeId);
            }
            $('<div class="dcolor" style="background-color:' + value + '"> </div>').appendTo('#' + wrapper_id);
        });
    };
    showCycle(){
        var self = this;
        if (!self.model) {
            return;
        }
        var msg = "Cyclic Paths:";
        var valueMeasurement = null;//self.getMeasurement('valueMeasurement', true);
        var cyclicPaths = valueMeasurement?valueMeasurement.getCyclicPath():[];
        for(var k=0;k<cyclicPaths.length;k++){
            var cyclicPath = cyclicPaths[k];
            for(var j=0;j<cyclicPath.length;j++){
                var cyclicElement = cyclicPath[j];
                var name = cyclicElement.get('name');
                name = name.replace("Measurement","");
                msg = msg.concat("<br/>"+name);
            }
            msg = msg.concat("<br/>");
        }
        bootbox.alert(msg);
    }
    setOptionsHandler(tempValues) { //used in businessModelViewModel
        var self = this;
        if (tempValues) {
            function checkTempCycle(valueId) {
                var cyclefound = false;
                for (var j = 0; j < tempValues.length; j++) {
                    if (self.encodeId != tempValues[j].id && valueId == tempValues[j].id) {
                        var tempValuesAggregated = tempValues[j].tempValuesAggregated;
                        function checkInAggregation(aggs, encodeId) {
                            for (var k = 0; k < aggs.length; k++) {
                                if (aggs[k] === encodeId) {
                                    cyclefound = true;
                                    break;
                                } else {
                                    var aggValueaggregations;
                                    for (var m = 0; m < tempValues.length; m++) {
                                        if (aggs[k] === tempValues[m].id) {
                                            aggValueaggregations = tempValues[m].tempValuesAggregated
                                            if (aggValueaggregations) {
                                                checkInAggregation(aggValueaggregations, encodeId);
                                            }
                                            if (cyclefound) {
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        checkInAggregation(tempValuesAggregated, self.encodeId);
                        if (cyclefound) {
                            break;
                        }
                    }
                }
                return cyclefound;
            }

            for (var i = 0; i < tempValues.length; i++) {
                //self.addTempValuesUnits(tempValues[i].unit);
                var valueAlreadyAdded = false;
                if (tempValues[i].id === self.encodeId) {
                    valueAlreadyAdded = true;
                } else if (tempValues[i].tempValuesAggregated.indexOf(self.encodeId) != -1) {
                    valueAlreadyAdded = true;
                    self.filterAggregateOptions(tempValues[i].id);
                } else {
                    for (var j = 0; j < self.aggregateColl().length; j++) {//value already exists in collection
                        if (self.aggregateColl()[j].aggregateid === tempValues[i].id) {
                            valueAlreadyAdded = true;
                            if (checkTempCycle(tempValues[i].id)) {
                                self.filterAggregateOptions(tempValues[i].id);
                            }
                            break;
                        }
                    }
                    for (var k = self.aggregateOptions().length; k--;) {//value already exists in select box
                        if (self.aggregateOptions()[k].value === tempValues[i].id) {
                            valueAlreadyAdded = true;
                            if (checkTempCycle(tempValues[i].id)) {
                                self.filterAggregateOptions(tempValues[i].id);
                            }
                            break;
                        }
                    }
                }
                if (!valueAlreadyAdded) {
                    if (checkTempCycle(tempValues[i].id)) {
                        break;
                    }
                    const obj = { value: tempValues[i].id, name: tempValues[i].name,children:[] }
                    const updObj =  self.getSubComponents( tempValues[i],obj)
                    self.aggregateOptions.push(updObj);
                    var tempValuesForAggregation = self.options.addOptions.tempValuesForAggregation;
                    if (tempValuesForAggregation) {
                        tempValuesForAggregation.push(tempValues[i]);
                    }
                }
            }
        }
        if (self.aggregateOptions().indexOf('' + self.selectedAggregate()) == -1) {
            self.selectedAggregate(null);
        }
    }

    textAreaHeight() {
        var self=this;
        // var vh_60 = Math.round(window.innerHeight / (100 / 60));
        // setTimeout(function(){
        //     if($('#formula'+ self.encodeId).prop('scrollHeight') > vh_60)
        //         $('#formula'+ self.encodeId).height(vh_60+"px");
        //     else if($('#formula'+ self.encodeId).prop('scrollHeight')>54)
        //         $('#formula'+ self.encodeId).height($('#formula'+ self.encodeId).prop("scrollHeight")+"px");
        // },300);
        if(self.formula()){
            if(self.formula().length >650 )
                $('#formula'+ self.encodeId).height(300+"px");
            else if(self.formula().length>300)
                $('#formula'+ self.encodeId).height(200+"px");
            else if(self.formula().length>200)
                $('#formula'+ self.encodeId).height(100+"px");
        }
    }
    intialiseTreeSelect(){
        var self = this;
        const container = $("#treeselect"+self.encodeId)[0];
        const options = self.aggregateOptions();

        const treeElement = new Treeselect({
            parentHtmlContainer: container,
            value: self.selectedAggregate,
            options:  options,
            isSingleSelect: true,
            showTags: false,
            clearable:false,
        });

        treeElement.srcElement.addEventListener('input', (e) => {
            self.selectedAggregate(e.detail);
            self.showValueDiv()
        });  
    }

    editConstraints(){
        var self = this;
        self.showConstraintDetails(!self.showConstraintDetails());
        if(!this.showConstraintDetails){
            self.minBound("")
            self.maxBound("")
            self.equalValue("")
            self.bigM("");
        }
    }

    checkMaxMinBoundConstraints(min, max, equal){
        var self =  this;
        const isMinBoundPresent = min !== null && min !== undefined && min !== '';
        const isMaxBoundPresent = max !== null && max !== undefined && max !== '';
        const isEqualValuePresent = equal !== null && equal !== undefined && equal !== '';

        if(isMinBoundPresent && isMaxBoundPresent && (max < min)) self.isConstraintPass(false);
        else if (!isMinBoundPresent && !isMaxBoundPresent && !isEqualValuePresent) self.isConstraintPass(false);
        else if ((isMinBoundPresent || isMaxBoundPresent) && isEqualValuePresent) self.isConstraintPass(false);
        else self.isConstraintPass(true);
    }

    init(model, options) {
        var self = this;
        this.ValueDetails2ViewModel = this;
        this.model = model;
        this.options = options;
        options = null;
        this.dialogOpenCount = 0;
        this.structuralChangeCount = 0;
        this.showValueDefinition = ko.observable(false);
        this.usedValueDefName = ko.observable();
        this.usedProjectionName = ko.observable();
        this.formulaErrorMsg = ko.observable();
        this.expBuilderOptions = { variables: [], expression: ""};
        this.valueLibs = [];
        this.usedValue = null;
        this.usedProjection = null;
        this.linkProjectionProfile = ko.observable(true);
        this.id = ko.observable(self.model ? self.model.get('id') : null);
        this.encodeId = self.htmlEscape(this.id());
        this.selectableValueNames = new Backbone.Collection();
        this.planValue = self.options.addOptions.planValue;
        this.currentAlternativeModel = self.options.addOptions.currentAlternativeModel;
        if (!this.currentAlternativeModel) {
            this.currentAlternativeModel = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: DataManager.getDataManager().get('viewAlternative') });
        }
        this.disableAggr = self.options.addOptions.disableAggr;
        if (self.options && self.options.parentView) {
            this.parentView = self.options.parentView;
            this.encodeId = "" + self.options.modalId;
            /*this.scenario = self.options.addOptions.mainScenario;
            if (this.planValue && this.currentAlternativeModel) {
                this.scenario = this.currentAlternativeModel.getOrCreatePhaseObjectiveSet();
            }*/
            this.readOnly = self.options.addOptions.readOnly;
            this.period = self.options.addOptions.period;
            this.year = self.options.addOptions.year;
            var plan = DataManager.getDataManager().get('currentPlan');
            this.defaultScenario = DataManager.getDataManager().get('defaultScenario');
            var defaultExecutionScenaroId = self.defaultScenario.get("defaultExecutionScenario");
            var defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
            this.periodDataset = defaultExecutionScenario.get("input").findWhere({period: self.period, periodKind : plan.get("periodKind"), year:self.year});
            this.calcPeriodDataset = defaultExecutionScenario.get("result").findWhere({period: self.period, periodKind : plan.get("periodKind"), year:self.year});
        }
        this.postShow = false;
        this.satSmileySrc = ko.observable('');
        this.satSmileyColor = ko.observable('');
        this.gradeSatisfaction = ko.observable();
        this.checkDimensional = ko.observable(true);
        this.showFormulaField = ko.observable(true);
        this.showFormulaField.subscribe(function (value) {
            if(value){
                self.initialiseMonaco();
            }
        });
        this.gradeSatisfaction.subscribe(function (value) {
            if (self.satisfactionOnly()) {
                self.satisfaction(value);
                for (var i = 0; i < self.satisfactionIntervalColl().length; i++) {
                    if (self.satisfactionIntervalColl()[i].intervalName === value) {
                        var smileySrc = self.fetchSmileySrc(self.satisfactionIntervalColl()[i].smiley);
                        $("#showSelectSatisfactionImg" + self.encodeId).attr('d', smileySrc);
                        $("#showSelectSatisfactionImg" + self.encodeId).attr('fill', self.satisfactionIntervalColl()[i].color);
                        break;
                    }
                }
            }
        });
        var planPackages = DataManager.getDataManager().get('currentPlan').getPlanPackageList('vdml_ValueDeliveryModel',true);
        var hasLib = planPackages.filter(b=>b.beepReference.indexOf('Common@') != -1 && b.beepType == 'vdml_ValueDeliveryModel' && b.beepReference.indexOf('CommonPackage') == -1);
        if (hasLib && hasLib.length > 0) {
            self.showValueDefinition(true);
        }
        this.valChildren = [];
        this.tempMeasurementRoundingValue;
        this.name = ko.observable(self.model ? self.model.get('name') : "");
        this.originalId = ko.observable();
        this.showAggregateDiv = ko.observable(false);
        this.measurementValue = ko.observable("");
        this.formula = ko.observable("");
        this.monacoFormula = ko.observable("");
        this.visibleSatisfactionType = ko.observable(false);
        this.enableExpr = ko.observable(false);
        this.recipientValue = ko.observable("");
        this.recipientSatValue = ko.observable("");
        this.enableDelete = ko.observable(false);
        this.satisfactionIntervalDiv = ko.observable(false);
        this.enableName = ko.observable(true);
        this.showSource = ko.observable(false);
        this.weightRecipientVisible = ko.observable(true);
        this.showNominal = ko.observable(true);
        this.showValues = ko.observable(false);
        this.visibleComplete = ko.observable(true);
        this.componentPrimed = ko.observable(false);
        this.visibleMove = ko.observable(false);
        this.showOverall = ko.observable(true);
        this.satisfactionOnly = ko.observable(false);
        this.showValuePath = ko.observable(false);
        this.aggregateToColl = ko.observableArray([]);
        this.showIntervalFromUI = ko.observable(true);
        this.showIntervalToUI = ko.observable(true);
        this.roundingFactor = ko.observable(false);
        this.isMapped = ko.observable(false);
        this.isProjectionLinked = ko.observable(false);
        this.deleteValDefIcon = ko.observable(false);
        this.valDefInfoVisible = ko.observable(false);
        this.intervalNameDuplicateMsg = ko.observable('');
        this.aggregateAddIconDiv = ko.observable(true);
        this.aggregatedFromLabel =ko.observable(true);
        this.showSelectSatisfaction = ko.observable(false);
        this.satisfactionUnitMark = ko.observable(false);
        this.showContraintsDiv = ko.observable(true);
        this.completionItemProvider = null;
        
        self.disallowedNames = [];
        if (self.model) {
            var parentType = "component";
            if (self.model.get('type') === "vdml_ValueAdd") {
                parentType = "valueAdd";
            }
            self.disallowedNames = self.currentAlternativeModel.getDisallowedNames(self.model, parentType);

            if (self.parentView && (self.parentView.get('type') === "vdml_BusinessModel" || self.parentView.get('type') === "transformation_Plan")) {
                self.visibleMove(true);
            }
        }
        this.hideDelete = false;
        if (self.parentView && (self.parentView.get('type') == "vdml_ValueAdd" || self.parentView.get('type') == "vdml_ValuePropositionComponent")) {
            this.hideDelete = true;
        }

        this.roundingDigits = ko.observable(4);
        this.significantDecimals = ko.observable(2);
        this.satRoundingDigits = ko.observable(4);
        this.satSignificantDecimals = ko.observable(2);
        this.activityOptions = ko.observableArray([]);
        this.selectedActivity = ko.observable();
        this.valueOptions = ko.observableArray([]);
        this.selectedvalue = ko.observable();
        this.enableValueOptions = ko.observable(true);
        this.enableActivityOptions = ko.observable(true);
        this.existingValueModal = null;
        this.contributeWhatValuesAggregations = [];
        this.tempValuesAggregated = [];
        this.aggregateOptions = ko.observableArray([]);
        this.aggregateColl = ko.observableArray([]);
        this.selectedAggregate = ko.observable();
        this.enableMeasurement = ko.observable(true);
        this.checkPrime = ko.observable(true);
        this.satisfaction = ko.observable();
        this.enableSatisfaction = ko.observable(true);
        this.enableSatisfactionType = ko.observable(true);
        this.enableWeight = ko.observable(true);
        this.enableRecipientOpinion = ko.observable(true);
        this.enableConstraintCheck = ko.observable(true);
        this.weight = ko.observable();
        this.myPropositionName = DataManager.getDataManager().get('localeManager').get('MyProposition');
        this.valuePropositionName = DataManager.getDataManager().get('localeManager').get('ValueProposition');
        this.activityName = DataManager.getDataManager().get('localeManager').get('Activity');
        this.satisfactionIntervalArray = [];
        this.satisfactionIntervalColl = ko.observableArray(this.satisfactionIntervalArray);
        this.satisfactionTypeOptions = ko.observableArray([ "Grade" , "Ranking" ]);
        this.satisfactionType = ko.observable();
        this.nominalValue = self.model ? self.model.get('isNominal'):false;
        this.sIndex = null;
        this.aIndex = null;
        this.maxOffset = ko.observable(0);
        this.maxAggregatedOffset = 0;
        this.validationMessage = ko.observable("");
        this.isOffsetValid = ko.computed(function() {
            var offset = parseInt(self.maxOffset());
            if (offset < self.maxAggregatedOffset) {
                self.validationMessage("Offset must be greater than or equal to " + self.maxAggregatedOffset);
            } else {
                self.validationMessage("");
            }
        });
        this.measurementUnit = ko.observable("");
        this.satisfactionUnitValue = ko.observable("");
        this.intervalTuples = [];
        this.baseTuples = [];
        this.deleteTuple = false;
        this.showValue = ko.observable(false);
        this.aggregateEdit = true;
        this.tempAggregateId;
        this.changeInSigValue = false;
        this.measureUnits = new Backbone.Collection();
        this.intervalFrom = ko.observable();
        this.intervalTo = ko.observable();
        this.intervalName = ko.observable();
        this.checkFromInclusive = ko.observable(false);
        this.checkToInclusive = ko.observable(false);
        this.negativeInfinity = ko.observable(false);
        this.positiveInfinity = ko.observable(false);
        this.actions = ko.observable("");
        this.showSatisfaction = ko.observable(false);
        this.svgSmiliesList = window.utils.getSmiliesList();
        this.colorsList = ["rgb(250,174,11)", "rgb(30,166,224)", "rgb(245,35,45)", "rgb(209,223,18)", "rgb(0,0,0)"];
        this.previouslyUsedColor = "rgb(0,0,0)";

        this.defaultSmiley = ko.observable(self.svgSmiliesList[0].src);
        this.smilies = ko.observableArray(self.svgSmiliesList);
        this.constraintCheck = ko.observable(false);
        this.showConstraintDetails = ko.observable(false);
        this.minBound = ko.observable();
        this.maxBound = ko.observable();
        this.isConstraintPass = ko.observable(false)
        this.equalValue = ko.observable()
        this.bigM = ko.observable()
        this.maxMinBoundWarnMsg = ko.observable('');
        this.recipientUnit = ko.observable("");
        if(this.options.year===undefined || this.options.year===null){
            this.enableMeasurement(false);
            this.enableWeight(false);
            this.enableRecipientOpinion(false);
            this.enableConstraintCheck(false)
            this.enableSatisfaction(false);
        }
        this.fillValues = function(valModel) {
            var self = this;
            var satisfactionLevel = valModel.get('satisfactionLevel');
            var weightMeasurement = valModel.get('percentageWeight');
            var recipientMeasurement = valModel.get('recipientOpinion');
            self.fillQuantity(valModel);
            self.showNominal(false);
            self.fillSatisfactionDetails(satisfactionLevel);
            self.fillMaxOffset(valModel);
            self.fillWeight(weightMeasurement);
            self.fillRecipientOpinion(recipientMeasurement);
            self.fillNominalValues(valModel);
            self.fillAggregatedFrom(valModel);
            self.fillFormula(valModel);
            self.fillConstraints(valModel);
            self.fillAggregatedTo(valModel);
            self.checkCyclic(null, valModel);
        };
        this.constraintCheck.subscribe(newValue => {
            if (!newValue) {
                if (self.showConstraintDetails) {
                    self.showConstraintDetails(false);
                }
            }
        });

        this.isCyclic = ko.observable(false);
        this.showValueMappedInfo = function (){
            var id = window.utils.htmlEscape(window.guidGenerator());
            var currentAltId = DataManager.getDataManager().get('viewAlternative');
            var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
            var currentAlt = docTypeObj.find({
                id: currentAltId
            });
            var valModel = window.utils.getElementModel(self.usedValue, ['vdml.ValueCategory', 'vdml.ValueDefinition']);
            if (!valModel) {
                bootbox.alert("No such Element");
                return;
            }
            var scenario = currentAlt.getMainScenario(valModel.getNestedParent());
            var options = {};
            options.mappedModal = valModel;
            options.context = scenario;
            options.width = '800px';
            window.getAndCreateModalDialog(self.model, id, EcoMapDiagramMixin, self.model, "TargetDetails", function(refresh) {}, options);
        };
        this.showProjectionMappedInfo = function(){
            var id = window.utils.htmlEscape(window.guidGenerator());
            var valModel = window.utils.getElementModel(self.usedProjection, ['vdml.ProjectionProfile']);
            if (!valModel) {
                bootbox.alert("No such Element");
                return;
            }
            var options = {};
            options.mappedModal = valModel;
            options.width = '800px';
            window.getAndCreateModalDialog(self.model, id, EcoMapDiagramMixin, self.model, "TargetDetails", function(refresh) {}, options);
        }
        this.deleteProjection = function(){
            self.isProjectionLinked(false);
            self.usedProjectionName("");
            self.usedProjection = null;
        }
        this.deleteValueDef = function () {
            self.isMapped(false);
            self.usedValueDefName("");
            self.deleteValDefIcon(false);
            self.valDefInfoVisible(false);
            self.usedValue = null;
        };
        this.mapValueDef = function () {
            var id = self.htmlEscape(window.guidGenerator());
            var currentPlan = DataManager.getDataManager().get('currentPlan');
            currentPlan.loadValueLibraries(function (valLibraries) {
                var defaultValLib = currentPlan.get('defaultValueLibrary').collection;
                valLibraries.push(defaultValLib);
                if (valLibraries) {
                    var options = {
                        valueLibs: valLibraries, callback: function (valId, valName) {
                            if (!valId) {
                                return;
                            }
                            self.usedValue = valId[0];
                            var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue });
                            if (valueDef) {
                                self.usedValueDefName(valueDef.get('name'));
                                var linkedLibraryProjection = valueDef.get('libraryProjectionProfile')
                                self.isMapped(true);
                                self.deleteValDefIcon(true);
                                self.valDefInfoVisible(true);
                                if ($('#valueName' + self.encodeId).val() === "") {
                                    self.name(valueDef.get('name'));
                                    $('#valueName' + self.encodeId).val(valueDef.get('name'))
                                }
                                if(linkedLibraryProjection){
                                    self.usedProjectionName(linkedLibraryProjection.get('name'));
                                    self.usedProjection = linkedLibraryProjection.id;
                                    self.isProjectionLinked(true);
                                }
                                if ($('#measurementUnit' + self.encodeId).val() === "" && self.enableMeasurement() && valueDef.get('unit')) {
                                    self.measurementUnit(valueDef.get('unit').get('name'));
                                    self.roundingDigits(valueDef.get('unit').get('roundingDigits'));
                                    self.significantDecimals(valueDef.get('unit').get('significantDecimals'));
                                    $('#measurementUnit' + self.encodeId).val(valueDef.get('unit').get('name'));
                                }
                                if (self.formula() === "" && valueDef.get('formula')){
                                    self.formula(valueDef.get('formula').get("expressionStr"));
                                }
                            }
                        }
                    };
                    if (self.usedValue) {
                        options.showValueElementId = self.usedValue;
                    }
                    options.showPhases = true;
                    options.hideCheck = true;
                    var ValueDeliveryModelMixin = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModelMixin");
                    window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "ValueExplorerJson", null, options);
                }
            })
        };
        this.mapProjectionProfile = function(){
            var id = self.htmlEscape(window.guidGenerator());
            var currentPlan = DataManager.getDataManager().get('currentPlan');
            currentPlan.loadValueLibraries(function (valLibraries) {
                var defaultValLib = currentPlan.get('defaultValueLibrary').collection;
                valLibraries.push(defaultValLib);
                if (valLibraries) {
                    var options = {
                        valueLibs: valLibraries, callback: function (valId, valName) {
                            if (!valId) {
                                return;
                            }
                            self.usedProjection = valId[0];
                            var profile = Backbone.Relational.store.getObjectByName('vdml.ProjectionProfile').find({ id: self.usedProjection });
                            if (profile) {
                                self.usedProjectionName(profile.get('name'));
                                self.isProjectionLinked(true);
                            }
                        }
                    };
                    if (self.usedProjection) {
                        options.showValueElementId = self.usedProjection;
                    }
                    options.showPhases = false;
                    options.hideCheck = true;
                    var ValueDeliveryModel2Mixin = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel2Mixin");
                    window.getAndCreateModalDialog(self, id, ValueDeliveryModel2Mixin, self.model, "ProjectionProfileJson", null, options);
                }
            })
        }

        this.selectedSmiley = function (view, event) {
            for (var i = 0; i < self.smilies().length; i++) {
                if (self.smilies()[i].src === this.src) {
                    $("#intervalSmileyPath" + self.encodeId).attr('d', self.smilies()[i].src);
                    $("#intervalSmiley" + self.encodeId).attr('value', self.smilies()[i].value);
                    break;
                }
            }
        };

        this.selectedActivity.subscribe(function (value) {
            if (!self.model) {
                fillTypeAheadNames(value);
            }
            var name = self.name();
            self.name("");
            self.name(name);
            self.getUniqueValue(self.name());
        });
        this.measurementValue.subscribe(function (value) {
            if (value && self.checkDimensional()) {
                // self.calculateSatisfaction();
            }
            if (!value) {
                self.satisfaction('');
                self.measurementValue("");
            }
        });
        this.satisfactionOnly.subscribe(function (checked) {
            if (checked) {
                function setSatisfactionOnly() {
                    self.checkDimensional(false);
                    if (self.matchField) {
                        self.matchField.hide();
                    }
                    for (var i = 0; i < self.aggregateColl().length; i++) {
                        var view = { 'seqNo': self.aggregateColl()[i].seqNo };
                        self.deleteAggregateDetails(view);
                    }
                    self.aggregateToColl.removeAll();
                    self.showContraintsDiv(false);
                    self.weightRecipientVisible(false);
                    fillDefaultSatisfactionInterval();
                    if (self.satisfactionIntervalColl()[0] && self.postShow) {
                        self.satisfaction(self.satisfactionIntervalColl()[0].intervalName);
                    }
                }
                if (window.utils.getObservableLength(self.aggregateColl()) > 0 || self.aggregateToColl().length > 0) {
                    bootbox.confirm(DataManager.getDataManager().get('localeManager').get('SatisfactionOnlyMessage'), function (result, event) {
                        if (result) {
                            setSatisfactionOnly();
                        } else {
                            self.satisfactionOnly(false);
                        }
                    });
                } else {
                    setSatisfactionOnly();
                }

            }
            else {
                self.checkDimensional(true);
                self.clearTuples();
                self.aggregateAddIconDiv(true);
                self.aggregatedFromLabel(true);
                self.showContraintsDiv(true);
                $('#measurementUnit' + self.encodeId).prop('disabled', false);
                if ($('#measurementUnit' + self.encodeId).val() !== "") {
                    self.roundingFactor(true);
                }
                else {
                    self.roundingFactor(false);
                }
                self.enableRecipientOpinion(true);
                $('#satisfactionUnit' + self.encodeId).prop('disabled', false);
                self.weightRecipientVisible(true);
            }
        });

        this.setOptions = _.bind(self.setOptionsHandler, self);

        this.satisfactionType.subscribe(function (type) {
            if (type === "Grade") {
                if (self.isDimensional) {//Ranking
                    if (self.model) {
                        $("#satisfactionUnit" + self.encodeId).hide();
                        self.satisfactionUnitMark(false);
                        self.clearTuples(type);
                    } else {
                        $("#satisfactionUnit" + self.encodeId).hide();
                        self.satisfactionUnitMark(false);
                        self.clearTuples(type);
                    }
                }
            } else {
                if (self.satisfactionUnitMark(false)) {
                    self.showSelectSatisfaction(false);
                    $("#satisfactionUnit" + self.encodeId).show();
                    self.satisfactionUnitMark(true);
                    self.clearTuples(type);
                }
            }

        });

        this.checkDimensional.subscribe(function (cond) {
            if (!cond) {
                self.showSelectSatisfaction(true);
                self.showSatisfaction(false);
                $("#showSatisfactionImg" + self.encodeId).attr('d', '');
                $("#showSelectSatisfactionImg" + self.encodeId).attr('d', '');
                self.enableMeasurement(cond);
                self.measurementValue("");
                self.measurementUnit("");
                $('#measurementUnit' + self.encodeId).val("");
            }
            else {
                self.showSelectSatisfaction(false);
                // self.calculateSatisfaction();
                self.enableMeasurement(cond);
                $('#formula' + self.encodeId).prop('disabled', !cond);
            }
        });

        this.fillValueDefinition = function () {
            var valueDef;
            if (self.model) {
                valueDef = self.model.get('valueDefinition');
            }
            if (self.usedValue) {
                valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: self.usedValue });
            }
            if (valueDef) {
                self.usedValue = valueDef.id;
                self.usedValueDefName(valueDef.get('name'));
                self.isMapped(true);
                self.deleteValDefIcon(true);
                self.valDefInfoVisible(true);
                /*
                if (value.get('unit') != valueDef.get('unit')) {
                    value.set('unit', valueDef.get('unit'))
                }*/
                if (self.measurementUnit() === "" && self.enableMeasurement()) {
                    self.measurementUnit(valueDef.get('unit'));
                }
            } else {
                self.usedValue = null;
                self.usedValueDefName("");
                self.isMapped(false);
                self.deleteValDefIcon(false);
                self.valDefInfoVisible(false);
            }
        };
        this.fillProjectionProfile = function () {
            var profile;
            if (self.model) {
                profile = self.model.get('projectionProfile');
            }
            if (self.usedProjection) {
                profile = Backbone.Relational.store.getObjectByName('vdml.ProjectionProfile').find({ id: self.usedProjection });
            }
            if (profile) {
                self.usedProjection = profile.id;
                self.usedProjectionName(profile.get('name'));
                self.isProjectionLinked(true);
            } else {
                self.usedProjection = null;
                self.usedProjectionName("");
                self.isProjectionLinked(false);
            }
        };

        this.selectedvalue.subscribe(function (value) {
            var comp = self.options.addOptions.vpValue.findWhere({ 'id': value });
            self.model = comp;
            if (self.model) {
                self.name(self.model.get('name'));
                self.id(self.model.get('id'));
                /*if (!self.planValue) {
                    self.scenario = self.currentAlternativeModel.getMainScenario(comp.getNestedParent());
                }*/
                self.clearValues(true);
                self.fillValues(comp);
                self.fillAggregatedValues();
                self.disableUIComponents(true);
                self.aggregateAddIconDiv(false);
                self.visibleComplete(true);
                $('#primeManditorySymbol' + self.encodeId).hide();
                self.visibleSatisfactionType(false);
                var aggFrom = self.model.getFromAggregations(self.currentAlternativeModel.id);
                for (var i = 0; i < aggFrom.length; i++) {
                    self.filterAggregateOptions(aggFrom.at(i).get('id'));
                }
                for (var i = 0; i < self.aggregateOptions().length; i++) {
                    if (self.aggregateOptions()[i].value === value) {
                        self.aggregateOptions().splice(i, 1);
                        break;
                    }
                }
            }
            else {
                self.clearValues(true);
            }
        });


        self.fillMeasurementUnits();
        self.fillAggregatedValues();

        if (self.options.addOptions.activities) {
            for (var i = 0; i < self.options.addOptions.activities.length; i++) {
                self.activityOptions.push(self.options.addOptions.activities[i]);
            }
        }

        this.isDimensional = true;
        this.satisfactionMeasure;
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'name'
            , 'description'
            , 'Role'
            , 'type'
            , 'Close'
            , 'Complete'
            , 'Delete'
            , 'addAnother'
            , 'source'
            , 'sourceType'
            , 'custom'
            , 'Cancel'
            , 'selectAValue'
            , 'SelectRecipient'
            , 'recipientOpinion'
            , 'Weight'
            , 'AggregatedFrom'
            , 'FromInclusive'
            , 'PositiveInfinity'
            , 'IntervalFrom'
            , 'IntervalTo'
            , 'NegativeInfinity'
            , 'value'
            , 'AggregatePreviousPhase'
            , 'satisfaction'
            , 'SatisfactionType'
            , 'Level'
            , 'ToInclusive'
            , 'SatisfactionIntervals'
            , 'AggregatedTo'
            , 'ValueFormula'
            , 'AggregationView'
            , 'PickSmiley'
            , 'CorrectiveActions'
            , 'Definitions'
            , 'ValueDefinition'
            , 'Move'
            , 'Quantity'
            , 'week'
            , 'Quarter'
            , 'Period'
            , 'JuliaExpr'
            , 'Nominal'
            , 'Categories'
            , 'Category'
            , 'Offset'
            , 'Constraints'
            , 'EditConstraints'
            , 'MinBound'
            , 'MaxBound'
            , 'EqualValue'
            , 'bigM'
            , 'ProjectionProfile'
        ]);

        if (self.model) {
            if (self.options.addOptions.type === "contributeWhatValues") {
                self.selectedvalue(self.model.get('id'));
                self.enableValueOptions(false);
            }
            else {
                self.fillValues(self.model);
                if (self.options.addOptions.type === "createWhatValues") {
                    self.selectedActivity(self.htmlEscape(self.model.get('valueAddOwner').get('containedPortOwner').get('id')));
                    self.enableActivityOptions(false);
                }
            }
        }
        else {
            self.calculateMeasurentValues();
        }

        if (self.options.addOptions.vpValue) {
            var valueProp = self.options.addOptions.vpValue;
            for (var i = 0; i < valueProp.length; i++) {
                    if (valueProp.at(i).get('isNominal')) {
                        continue;//not allowing grade only
                    }
                    self.valueOptions.push({ id: valueProp.at(i).get('id'), name: valueProp.at(i).get('name') });
            }
            self.valueOptions.sort(function (left, right) {
                return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1)
            });
        }

        // this.selectedAggregate.subscribe(function (aggregate) {
        //     self.fillAggregateUIDetails(aggregate);
        // });

        this.openValue = _.bind(this.openValueHandler, self);
        this.editAggregateDetails = _.bind(this.editAggregateDetailsHandler, self);
        this.editSatisfactionInterval = _.bind(self.editSatisfactionIntervalHandler, self);
        this.deleteAggregateDetails = _.bind(self.deleteAggregateDetailsHandler, self);
        
        utils.defineKoNumeric(ko);

        var defaultValLib = DataManager.getDataManager().get('currentPlan').get("defaultValueLibrary");
        var perUnit = defaultValLib ? defaultValLib.get('unit').findWhere({name:'%'}):null;
        var perRoundDec = perUnit ? perUnit.get('roundingDigits') : 4;
        utils.defineKoWeightNumeric(ko,perRoundDec);

        this.cleanModal = _.bind(self.cleanModalHandler, self);

        this.deleteModal = function () {
            return self.options.callback(this, self.encodeId, self.options.addOptions.type, true);
        }


        $('#modal' + self.encodeId).on('shown.bs.modal', function () {
            window.utils.focusElement("#newModal .focus-ele");
            $("#valueName" + self.encodeId).focus();
            if (self.name() != null && self.name() !== '') {
                if (!self.hideDelete && (self.showOverall())) {
                    self.enableDelete(true);
                }
                else {
                    self.enableDelete(false);
                }
            }
            if(self.satisfactionOnly()){
                self.aggregateAddIconDiv(false);
                self.aggregatedFromLabel(false);
            }
            if (self.options.addOptions.type === "contributeWhatValues") {
                if (self.dialogOpenCount === 0) {
                    self.enableDelete(false);
                }
                else {
                    self.enableValueOptions(false);
                }
            }
            if (self.options.addOptions.disableDelete) {//for R.H.S(disabling revision process)
                self.enableDelete(false);
            }
            //for component
            if (self.readOnly) {
                self.disableUIComponents();
                self.visibleComplete(false);
            }
            if (self.options.addOptions.aggTo) {
                self.disableUIComponents(true);
                $("icon[id*='deleteAggregate" + self.encodeId + "']").hide();
            }
            if (self.options.addOptions.aggregationView) {//preventing addition of aggregated from in aggregation value edit mode
                self.aggregateAddIconDiv(false);
                if (!self.model) {
                    self.aggregatedFromLabel(false);
                }
            }
            //for modal window close functionality
            $('#valueName' + self.encodeId).val(self.name());
            $('#measurementUnit' + self.encodeId).val(self.measurementUnit());
            $('#satisfactionUnit' + self.encodeId).val(self.satisfactionUnitValue());
            self.dialogOpenCount++;
        });


        this.saveValueDetails = _.bind(self.saveValueDetailsHandler, self);

        function fillTypeAheadNames(modalId) {
            self.selectableValueNames.reset();
            if (self.options.addOptions.activities) {
                for (var i = 0; i < self.options.addOptions.activities.length; i++) {
                    if (self.options.addOptions.activities[i].modalId === modalId) {
                        var activityPort;
                        if (self.options.addOptions.activities[i].existingActivity) {
                            activityPort = self.options.addOptions.activities[i].existingActivity.get('containedPort');
                        }
                        else {
                            var actId = self.options.addOptions.activities[i].originalId;
                            if (actId) {
                                var bmActivities = self.parentView.get('bmActivity');

                                if (bmActivities) {
                                    for (var j = 0; j < bmActivities.length; j++) {
                                        if (bmActivities.at(j).get('activity') && bmActivities.at(j).get('activity').get('id') == actId) {
                                            activityPort = bmActivities.at(j).get('activity').get('containedPort');
                                            break;
                                        }
                                    }
                                } else if (!bmActivities && self.options.bm && self.parentView.getNestedParent().get('type') === "ecomap_EcoMap") {
                                    activityPort = self.options.bm.get('containedPort');
                                }
                            }
                        }
                        if (activityPort) {
                            for (var j = 0; j < activityPort.length; j++) {
                                if (activityPort.at(j).get('type') == 'vdml_OutputPort' && activityPort.at(j).get('valueAdd')) {
                                    self.selectableValueNames.add(activityPort.at(j).get('valueAdd').models);
                                }
                            }
                        }
                    }
                }
                var valColl = self.options.addOptions.valuesColl;
                for (var i = 0; i < valColl.length; i++) {
                    if (valColl[i].originalId != null || valColl[i].existingValue != null) {
                        var checkId = valColl[i].originalId;
                        if (valColl[i].existingValue) {
                            checkId = valColl[i].existingValue.get('id');
                        }
                        var valExists = self.selectableValueNames.findWhere({ 'id': checkId });
                        if (valExists) {
                            self.selectableValueNames.remove(valExists);
                        }
                    }
                }
            }
            self.selectableValueNames.models.sort(function (left, right) {
                return left.get('name').toLowerCase() == right.get('name').toLowerCase() ? 0 : (left.get('name').toLowerCase() < right.get('name').toLowerCase() ? -1 : 1)
            });
        }
        this.hideValueDiv = function () {
            self.showAggregateDiv(false);
            //self.aggregateDiv(true);
            //$('#editDetailsModalContent'+self.encodeId).css("height",$("#editDetailsModalContent"+self.encodeId).actual('outerHeight')-$("#aggregatedDetailsDiv"+self.encodeId).actual('outerHeight'));
        };
        this.showValueDiv = function () {
            if ($('#aggregateValue' + self.encodeId) && $('#aggregateValue' + self.encodeId).val() === "") {
                self.fillAggregateUIDetails(self.selectedAggregate());
            }
            self.showAggregateDiv(true);
            //self.aggregateDiv(false);
            //$('#editDetailsModalContent'+self.encodeId).css("height",$("#editDetailsModalContent"+self.encodeId).actual('outerHeight')+$("#aggregatedDetailsDiv"+self.encodeId).actual('outerHeight'));
        };
        this.moveValue = function (view) {
            var id = "moveValue" + view.encodeId;
            var addOptions = {};
            window.getAndCreateModalDialog(self, id, BusinessModel2Mixin, self.model, "moveValue", null, addOptions);
        }
        this.editRoundingFactor = function (view) {
            var id = "valueUnit" + view.encodeId;
            var addOptions = { roundingDigits: self.roundingDigits(), significantDecimals: self.significantDecimals() };
            var valUnit;
            if (self.model) {
                valUnit = self.model.get('unit');
            }
            window.getAndCreateModalDialog(self.model, id, BusinessModel2Mixin, valUnit, "ValueUnitDetails", self.saveUnitDetails, addOptions);
        }
        this.editSatisfactionRoundingFactor = function (view) {
            var id = "SatisfactionUnit" + view.encodeId;
            var addOptions = { roundingDigits: self.satRoundingDigits(), significantDecimals: self.satSignificantDecimals() };
            var satUnit;
            if (self.model) {
                var satMeasurement = self.model.get('satisfactionLevel');
                if (satMeasurement) {
                    satUnit = satMeasurement.get('unit');
                }
            }
            window.getAndCreateModalDialog(self.model, id, BusinessModel2Mixin, satUnit, "ValueUnitDetails", self.saveSatisfactionUnitDetails, addOptions);
        }
        this.saveUnitDetails = function (roundingVal, sigDigits) {
            if (self.significantDecimals() !== sigDigits) {
                self.changeInSigValue = true;
            } else {
                self.changeInSigValue = false;
            }
            self.roundingDigits(roundingVal);
            self.significantDecimals(sigDigits);
            if (self.enableMeasurement() && !isNaN(self.measurementValue())) {
                self.measurementValue(window.utils.getRoundedValues(self.fullMeasurementValue, sigDigits));
            } else {
                self.calculateMeasurentValues();
            }
            if (!isNaN(self.recipientValue())) {
                self.recipientValue(window.utils.getRoundedValues(self.recipientValue(), sigDigits));
            }
        }
        this.saveSatisfactionUnitDetails = function (roundingVal, SigDigits) {
            self.satRoundingDigits(roundingVal);
            self.satSignificantDecimals(SigDigits);
        }
        this.showValuePathDivRow = function () {
            var partcipantNetwork = "", valuePathRole = "", valuePathSource = "", receiverValuePathRole = "", ProviderRole = "", receiverRole = "", PN = "Participant Network";
            if (self.model && self.readOnly != undefined && !self.planValue) {
                fillValuePath(self.model);
            }
            if (valuePathSource === "") {
                var componentOwner = self.model.get('componentOwner');
                if (componentOwner && componentOwner.get('planCriterionSetOwner')) {
                    valuePathSource = componentOwner.get('planCriterionSetOwner').get('name');
                    PN = "";
                    partcipantNetwork = "";
                }
            }
            var msg = "<div>\
				<div class=\"row\">\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+DataManager.getDataManager().get('localeManager').get('source')+"</span></span></div>\
					<div class=\"col-xs-6\"><span> "+ valuePathSource + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div><br>\
				<div class=\"row\">\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+DataManager.getDataManager().get('localeManager').get('sourceType')+"</span></span></div>\
					<div class=\"col-xs-6\"><span> "+ self.getSourceType(self.model) + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

            var pnDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+ PN + "</span></span></div>\
					<div class=\"col-xs-6\"><span>"+ partcipantNetwork + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

            var providerDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+ ProviderRole + "</span><span></div>\
					<div class=\"col-xs-6\"><span>"+ valuePathRole + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

            var recieverDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+ receiverRole + "</span><span></div>\
					<div class=\"col-xs-6\"><span>"+ receiverValuePathRole + "</div><br>\
					<div class=\"col-xs-1\"></div><br>\
				</div>";

            if (PN !== "") {
                msg = msg.concat(pnDiv);
            }
            if (ProviderRole !== "") {
                msg = msg.concat(providerDiv);
            }
            if (receiverRole !== "") {
                msg = msg.concat(recieverDiv);
            }
            msg = msg.concat("</div>");
            const box = bootbox.dialog({
                message: msg,
                title: DataManager.getDataManager().get('localeManager').get('SourceDetails'),
                buttons: {
                    main: {
                        label: "Close",
                        className: "btn btn-default",
                    }
                }
            });
            if(document.fullscreenElement){
                box.appendTo(`#${document.fullscreenElement.id}`)
              }else{
                box.show();
              }
            function fillValuePath(value) {
                var packPath = value.getPackagePath()
                for (var k = 0; k < packPath.length - 1; k++) {
                    if (k === 0) {
                        partcipantNetwork = packPath[k].name;
                    }
                    if (value.get('type') === "vdml_ValueAdd" && k === 1 && value.get('valueAddOwner')) {
                        var performingRole = value.get('valueAddOwner').get('containedPortOwner').get('performingRole');
                        valuePathRole = performingRole.get('roleAssignment').models[0].get('participant').get('name') + "  <b><i>(" + performingRole.get('name') + ")</b></i>";
                        if (packPath[k]) {
                            valuePathSource = packPath[k].name;
                        }
                        ProviderRole = "Performer";
                        k++;
                    }
                    else if (k === 1) {
                        valuePathRole = value.get('componentOwner').get('provider').get('roleAssignment').models[0].get('participant').get('name') + "  <b><i>(" + packPath[k].name + ")</i></b>";
                        if (value.get('componentOwner').get('recipient')) {
                            var participantName = '';
                            if (value.get('componentOwner').get('recipient').get('roleAssignment').models[0]) {
                                participantName = value.get('componentOwner').get('recipient').get('roleAssignment').models[0].get('participant').get('name');
                            }
                            receiverValuePathRole = participantName + "  (" + value.get('componentOwner').get('recipient').get('name') + ")";
                            receiverRole = "To (Role)";
                        }
                        k++;
                        if (packPath[k]) {
                            valuePathSource = packPath[k].name;
                        }
                        ProviderRole = "From (Role)";
                    }
                }
            }
        };

        this.showSatisfactionIntervalDiv = function () {
            self.visibleSatisfactionType(true);
            self.satisfactionIntervalDiv(true);
            self.checkFromInclusive(true);
            self.checkToInclusive(true);
            self.addColorsToPalette();
        };

        this.addSatisfactionInterval = function (view) {

            self.intervalNameDuplicateMsg('');
            var intervalFrom = self.intervalFrom();
            var intervalTo = self.intervalTo();
            var intervalName = self.intervalName();
            var intervalFromFloat = parseFloat(intervalFrom);
            var intervalToFloat = parseFloat(intervalTo);
            var fromInc = self.checkFromInclusive() ? true : false;
            var toInc = self.checkToInclusive() ? true : false;
            var levelEnum;

            if ((!intervalFrom || !intervalTo) && self.checkDimensional()) {
                self.intervalNameDuplicateMsg(DataManager.getDataManager().get('localeManager').get('RequiredFields'));
                return;
            }
            if ((intervalFromFloat > intervalToFloat || (intervalFromFloat === intervalToFloat && (!fromInc || !toInc))) && self.checkDimensional()) {
                self.intervalNameDuplicateMsg(DataManager.getDataManager().get('localeManager').get('InappropriateIntervals'));
                return;
            }
            for (var i = 0; i < self.satisfactionIntervalColl().length; i++) {
                var existingInterval = self.satisfactionIntervalColl()[i];
                if (self.sIndex !== null && self.sIndex == existingInterval.seqNo) {
                    continue;
                }
        
                var existingFrom = parseFloat(existingInterval.intervalFrom);
                var existingTo = parseFloat(existingInterval.intervalTo);
                var existingFromInc = existingInterval.fromInclusive;
                var existingToInc = existingInterval.toInclusive;
                if (existingInterval.intervalFrom == "-Infinity") {
                    existingFrom = -Number.MAX_VALUE;
                }
                if (existingInterval.intervalTo == "Infinity") {
                    existingTo = Number.MAX_VALUE;
                }
                var isOverlapping = 
                    (intervalFromFloat < existingTo || (intervalFromFloat === existingTo && fromInc && existingToInc)) &&
                    (intervalToFloat > existingFrom || (intervalToFloat === existingFrom && toInc && existingFromInc));
        
                if (isOverlapping && !existingInterval._destroy && self.checkDimensional() && intervalName !=existingInterval.intervalName) {
                    self.intervalNameDuplicateMsg(DataManager.getDataManager().get('localeManager').get('InappropriateIntervals'));
                    return;
                }
            }
            if (self.sIndex == null && intervalName !== "") {
                var createInterval = true;
                if (intervalFrom === "" || intervalTo === "") {
                    createInterval = self.checkDimensional() ? false : true;
                }
                if (createInterval) {
                    var satValue = intervalName;
                    if (self.measurementValue() === "") {
                        satValue = "";
                    }
                    var uniqueId = window.utils.htmlEscape(window.guidGenerator());
                    var smileySrc = self.fetchSmileySrc($("#intervalSmiley" + self.encodeId).attr('value'));
        
                    self.satisfactionIntervalColl.push({
                        seqNo: '' + self.satisfactionIntervalColl().length,
                        satisfactionValue: satValue,
                        intervalName: intervalName,
                        intervalFrom: intervalFrom,
                        intervalTo: intervalTo,
                        fromInclusive: fromInc,
                        toInclusive: toInc,
                        smiley: $("#intervalSmiley" + self.encodeId).attr('value'),
                        color: $("#intervalSmiley" + self.encodeId).attr('fill'),
                        smileySrc: smileySrc,
                        actions: self.actions(),
                        uniqueId: uniqueId
                    });
        
                    self.constructSatisfactionIntervalTuple('' + (self.satisfactionIntervalColl().length - 1), intervalName, intervalFrom, intervalTo, fromInc, toInc, $("#intervalSmiley" + self.encodeId).attr('value'), $("#intervalSmiley" + self.encodeId).attr('fill'), self.actions(),levelEnum);
                }
        
                self.satisfactionIntervalDiv(false);
                self.sortSatisfactionIntervals();
        
            } else if (intervalName !== "") {
                var newIntervalName = intervalName;
                if (self.satisfactionOnly()) {
                    var oldIntervalName = self.satisfactionIntervalColl()[self.sIndex].intervalName;
                    if ($("#selectSatisfaction" + self.encodeId + " option:selected").text() === oldIntervalName) {
                        self.satisfaction(newIntervalName);
                    }
                    $("#selectSatisfaction" + self.encodeId + " option[value='" + oldIntervalName + "']").attr("val", newIntervalName);
                    $("#selectSatisfaction" + self.encodeId + " option[value='" + oldIntervalName + "']").text(newIntervalName);
                }
                self.satisfactionIntervalColl()[self.sIndex].intervalName = newIntervalName;
                self.satisfactionIntervalColl()[self.sIndex].intervalFrom = intervalFrom;
                self.satisfactionIntervalColl()[self.sIndex].intervalTo = intervalTo;
                var fromInc = self.checkFromInclusive();
                var toInc = self.checkToInclusive();
                self.satisfactionIntervalColl()[self.sIndex].fromInclusive = fromInc;
                self.satisfactionIntervalColl()[self.sIndex].toInclusive = toInc;
                $("#intervalName" + self.satisfactionIntervalColl()[self.sIndex].seqNo + "" + self.encodeId).text(newIntervalName);
                $("#intervalFrom" + self.satisfactionIntervalColl()[self.sIndex].seqNo + "" + self.encodeId).text(intervalFrom);
                $("#intervalTo" + self.satisfactionIntervalColl()[self.sIndex].seqNo + "" + self.encodeId).text(intervalTo);
                self.satisfactionIntervalColl()[self.sIndex].smiley = $("#intervalSmiley" + self.encodeId).attr('value');
                self.satisfactionIntervalColl()[self.sIndex].color = $("#intervalSmiley" + self.encodeId).attr('fill');
                var smileySrc = self.fetchSmileySrc($("#intervalSmiley" + self.encodeId).attr('value'));
                var uniqueId = self.satisfactionIntervalColl()[self.sIndex].uniqueId;
                self.satisfactionIntervalColl()[self.sIndex].smileySrc = smileySrc;
                self.satisfactionIntervalColl()[self.sIndex].actions = self.actions();
                var seqNo = self.satisfactionIntervalColl()[self.sIndex].seqNo;
                for (var i = 0; i < self.intervalTuples.length; i++) {
                    if (self.intervalTuples[i].seqNo == seqNo) {
                        self.editSatisfactionIntervalTuple(seqNo, newIntervalName, intervalFrom, intervalTo, fromInc, toInc, $("#intervalSmiley" + self.encodeId).attr('value'), $("#intervalSmiley" + self.encodeId).attr('fill'), self.actions());
                        break;
                    }
                }
                $('#editSmiley' + uniqueId).find('path').attr('d', smileySrc);
                $('#editSmiley' + uniqueId).attr('fill', $("#intervalSmiley" + self.encodeId).attr('fill'));
            }
            self.sIndex = null;
            self.satisfactionIntervalDiv(false);
            self.intervalName("");
            self.intervalFrom("");
            self.intervalTo("");
            self.intervalNameDuplicateMsg('');
            self.actions('');
            self.negativeInfinity(false);
            self.positiveInfinity(false);
            self.showIntervalToUI(true);
            self.showIntervalFromUI(true);
            self.removeSmiley();
            // self.calculateSatisfaction();
        };
        

        this.deleteSatisfactionInterval = function (view) {
            for (var i = 0; i < self.satisfactionIntervalColl().length; i++) {
                if (self.satisfactionIntervalColl()[i].seqNo == view.seqNo) {
                    var tempView = self.satisfactionIntervalColl()[i];
                    if (tempView) {
                        self.satisfactionIntervalColl.replace(self.satisfactionIntervalColl()[i], tempView);
                        self.satisfactionIntervalColl.destroy(self.satisfactionIntervalColl()[i]);
                        var seqNo = view.seqNo;
                        for (var j = 0; j < self.intervalTuples.length; j++) {
                            if (self.intervalTuples[j] && self.intervalTuples[j].seqNo == seqNo) {
                                self.deleteTuple = true;
                                self.deleteSatisfactionIntervalTuple(self.intervalTuples[j].seqNo);
                                break;
                            }
                        }
                    }
                    else {
                        self.satisfactionIntervalColl.splice(i, 1);
                    }
                }
            }
            // self.calculateSatisfaction();
            self.sortSatisfactionIntervals();
        };


        function fillDefaultSatisfactionInterval() {
            self.visibleSatisfactionType(true);
            if (window.utils.getObservableLength(self.satisfactionIntervalColl()) === 0) {
                var intervalName = "Category1";
                var satValue = "Category1";
                self.satisfactionIntervalColl([]);
                if (self.satisfactionType() == "Ranking") {
                    self.satisfaction('');
                    intervalName = "0";
                    satValue = "0";
                }
                self.satisfactionIntervalColl.push({ seqNo: '' + self.satisfactionIntervalColl().length, intervalName: intervalName, intervalFrom: '', intervalTo: '', fromInclusive: '', toInclusive: '', satisfactionValue: satValue, smiley: '', color: 'rgb(0,0,0)', smileySrc: '', actions: '', uniqueId: window.utils.htmlEscape(window.guidGenerator()) });
                self.constructSatisfactionIntervalTuple('' + self.satisfactionIntervalColl().length - 1, intervalName, '','', '', '', '', 'rgb(0,0,0)', '','');
                if (self.measurementValue() === "") {
                    self.satisfaction("");
                    self.satisfaction('')
                }
            }
        }
        this.onClickNegativeInfinity = function (view, event) {
            if (event.currentTarget.checked) {
                self.intervalFrom('-Infinity');
                $('#intervalFromShow' + self.encodeId).text('-Infinity');
                self.showIntervalFromUI(false);
                self.checkFromInclusive(false);
            }
            else {
                self.showIntervalFromUI(true);
                self.intervalFrom("");
            }
        }
        this.onClickPositiveInfinity = function (view, event) {
            if (event.currentTarget.checked) {
                self.intervalTo('Infinity');
                $('#intervalToShow' + self.encodeId).text('Infinity');
                self.showIntervalToUI(false);
                self.checkToInclusive(false);
            }
            else {
                self.showIntervalToUI(true);
                self.intervalTo("");
            }
        }
        this.checkComplete = ko.computed(function () {
            var complete = false;
            if (self.name().trim() !== "" && $('#message' + self.encodeId).text() == "") {
                if (self.measurementUnit() !== "") {
                    complete = true;
                    if (self.satisfactionType() == "Ranking") {
                        if (self.satisfactionUnitValue() !== "" && $('#message' + self.encodeId).text().trim().length < 1) {
                            complete = true;
                        }
                        else {
                            complete = false;
                        }
                    }
                    else {
                        if ($('#message' + self.encodeId).text().trim().length < 1) {
                            complete = true;
                        }
                        else {
                            complete = false;
                        }
                    }
                }
                else {
                    complete = false;
                    if (!self.checkDimensional()) {
                        complete = true;
                        if (self.satisfactionOnly() && window.utils.getObservableLength(self.satisfactionIntervalColl()) < 1) {
                            complete = false;
                        }
                    }
                }
                if (self.options.addOptions.type === "contributeWhatValues") {
                    var activitFound = false;
                    var removeAgg = [];
                    self.contributeWhatValuesAggregations = [];
                    for (var i = 0; i < self.aggregateColl().length; i++) {
                        if (self.aggregateColl()[i].aggregateType === 'Activity') {
                            if (!self.aggregateColl()[i]._destroy) {
                                activitFound = true;
                                self.contributeWhatValuesAggregations.push(self.aggregateColl()[i].aggregateid);
                            } else {
                                removeAgg.push(self.aggregateColl()[i].aggregateid);
                            }
                        }
                    }
                    self.contributeWhatValuesAggregations = _.uniq(self.contributeWhatValuesAggregations);
                    for (var i = 0; i < removeAgg.length; i++) {
                        for (var j = self.contributeWhatValuesAggregations.length; j--;) {
                            if (self.contributeWhatValuesAggregations[j] === removeAgg[i]) {
                                self.contributeWhatValuesAggregations.splice(j, 1);
                            }
                        }
                    }
                    // complete = activitFound;
                }
            } else {
                complete = false;
            }
            if(self.constraintCheck() && !self.isConstraintPass()){
                complete = false;
            }
            if(complete && parseInt(self.maxOffset()) < self.maxAggregatedOffset) {
                complete = false;
            }
            //for component
            if (self.readOnly) {
                self.enableDelete(false);
                complete = false;
            }
            return complete;
        }, this);
        function setSatisfactionIntervalAddIconCSS() {
            var satisfactionIntervalAddIcon = document.getElementById("satisfactionIntervalAddIcon" + self.encodeId);
            if (satisfactionIntervalAddIcon) {
                satisfactionIntervalAddIcon.style.cursor = "pointer";
            }
        }

        this.removeSmiley = function (view, event) {
            //var satId = event.currentTarget.id.replace('removeSmiley', "");
            $("#intervalSmiley" + self.encodeId).attr('src', '');
            $("#intervalSmiley" + self.encodeId).attr('value', '');
            $("#intervalSmileyPath" + self.encodeId).attr('d', '');
            $("#intervalSmiley" + self.encodeId).attr('fill', 'rgb(0,0,0)');
            $('#intervalSmiley' + self.encodeId).parent().hide();
            $('#colors-wrapper1' + self.encodeId).hide();
            $('.colorPickerComp').removeClass('show');
            $('.colorPickerComp').hide();
            $('#intervalSmiley' + self.encodeId).attr('value', '');
        };


        this.afterRenderView = function () {
            self.deleteValDefIcon(false);
            self.valDefInfoVisible(false);
            var valueNameExtended = Backbone.Typeahead.extend({
                template: '<input type="text" value ="' + self.name() + '" id="valueName' + self.encodeId + '" placeholder="Name" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            $('#editDetailsModalContent' + self.encodeId).resize(function () {
                $('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
            })
            var valueNameTypeahead = new valueNameExtended({ collection: self.selectableValueNames });
            valueNameTypeahead.setElement('#valueNameDiv' + self.encodeId).render();
            valueNameTypeahead.on('selected', function (fetchmodel) {
                self.existingValueModal = fetchmodel;
                $('#message' + self.encodeId).text("");
                self.name(fetchmodel.get('name'));
                self.aggregateColl([]);
                self.baseTuples = [];
                self.fillValues(fetchmodel);
                self.filterAggregateOptions(fetchmodel.get('id'));
            });
            if (self.disableAggr) {
                $("icon[id*='deleteAggregate" + self.encodeId + "']").hide();
            }

            $("#valueName" + self.encodeId).change(function (view) {
                var name = view.target.value;
                self.existingValueModal = null;
                self.name(name);
                if (self.existingValueModal == null) {
                    self.getUniqueValue(name);
                }
                if (name.trim().length > 1 && $('#message' + self.encodeId).text().trim().length < 1 && ((self.previousPhaseValName && self.previousPhaseValName != self.name().trim()) || (self.nextPhaseValName && self.nextPhaseValName != self.name().trim()))) {
                    bootbox.confirm({
                        message: "Warning: Name will change in other Phases too. Do you want to continue ?",
                        buttons: {
                            confirm: {
                                label: 'Ok',
                                className: 'btn-primary'
                            },
                            cancel: {
                                label: 'Cancel',
                                className: 'btn-default'
                            }
                        },
                        callback: function (result, name) {
                            if (!result) {
                                name = self.model.get('name');
                                self.name(name);
                            }

                        }
                    });


                }
            });
            $("#measurementValue" + self.encodeId).change(function (view) {
                if (self.enableMeasurement() && !isNaN(self.measurementValue())) {
                    self.fullMeasurementValue = self.measurementValue();
                    var roundedValue = window.utils.getRoundedValues(self.fullMeasurementValue, self.significantDecimals());
                    if (!isNaN(roundedValue)) {
                        self.measurementValue(roundedValue);
                    }
                }
            });

            $("#valueName" + self.encodeId).keyup(function (view) {
                var name = view.target.value;
                if (self.existingValueModal == null) {
                    self.getUniqueValue(name);
                }
            });
            function validate(s) {
                var rgx = /\-?[0-9]*\.?[0-9]*$/;
                if (s.match(rgx) == s) {
                    return true;
                }
                return false;
            }

            $("#intervalName" + self.encodeId).keyup(function (view) {
                var name = view.target.value;
                var found = false;
                if (self.satisfactionType() !== "Grade" && !validate(name)) {
                     $("#intervalName" + self.encodeId).val("");
                    return;
                }
                for (var i = 0; i < self.satisfactionIntervalColl().length; i++) {
                    if (self.satisfactionIntervalColl()[i].intervalName === name && i !== self.sIndex && !self.satisfactionIntervalColl()[i]._destroy) {
                        found = true;
                        break
                    }
                }
                if (found) {
                    self.intervalNameDuplicateMsg('Name Already Exists');
                } else {
                    self.intervalNameDuplicateMsg('');
                }
            });
            var diglogType = self.options.addOptions.type.toLowerCase();
            var lcManager = DataManager.getDataManager().get('localeManager');
            var lab = "";
            if (diglogType === "valueProposition".toLowerCase()) {
                lab = self.model ? lcManager.get('editVpValue') : lcManager.get('enterVpValue');
            }
            if (diglogType === "planValues".toLowerCase()) {
                lab = self.model ? lcManager.get('editPlanValue') : lcManager.get('enterPlanValue');
            }
            if (diglogType === "valueFormula".toLowerCase()) {
                lab = self.model ? lcManager.get('editMyPropositionValue') : lcManager.get('enterMyPropositionValue');
            }
            if (diglogType === "createWhatValues".toLowerCase()) {
                self.showSource(true);
                self.showNominal(false);
                lab = self.model ? lcManager.get('editActivityValue') : lcManager.get('enterActivityValue');
            }
            if (diglogType === "contributeWhatValues".toLowerCase()) {
                self.showValues(true);
                lab = self.model ? lcManager.get('editAggActivityValue') : lcManager.get('enterAggActivityValue');
            }
            $("#titleLabel" + self.encodeId).text(lab);
            var Extended = Backbone.Typeahead.extend({
                template: '<input type="text" value ="' + self.measurementUnit() + '" id="measurementUnit' + self.encodeId + '" placeholder="'+DataManager.getDataManager().get('localeManager').get('Unit')+'" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            self.measureUnits.models.sort(function (left, right) {
                return left.get('name').toLowerCase() == right.get('name').toLowerCase() ? 0 : (left.get('name').toLowerCase() < right.get('name').toLowerCase() ? -1 : 1)
            });
            self.measurementUnittypeahead = new Extended({ collection: self.measureUnits });
            self.measurementUnittypeahead.setElement('#measurementUnitDiv' + self.encodeId).render();
            //var cond = valueMeasurement ? false : true;
            if ($('#measurementUnit' + self.encodeId).val() !== "") {
                self.roundingFactor(true);
            } else {
                self.roundingFactor(false);
            }
            self.measurementUnittypeahead.on('selected', function (fetchmodel) {
                self.measurementUnit(fetchmodel.get('name'));
                self.roundingFactor(true);
                self.roundingDigits(fetchmodel.get('roundingDigits'));
                self.significantDecimals(fetchmodel.get('significantDecimals'));
                self.recipientUnit(fetchmodel.get('name'));
                if (window.utils.getObservableLength(self.aggregateColl()) > 1) {
                    self.calculateMeasurentValues();
                }
                self.matchField[0].innerHTML = "Match";
                self.matchField.show();
            });
            $('#measurementUnit' + self.encodeId).change(function (view) {
                var newValue = view.target.value.trim();
                if (self.model) {
                    setTimeout(function () {
                        var unitName;
                        var unitNodeVal = $('#measurementUnit' + self.encodeId).val();
                        if (self.model && self.model.getPreviousValueUnit) {
                            unitName = self.model.getPreviousValueUnit(self.currentAlternativeModel, self.model.get('valueMeasurement'));
                        }
                        if (unitName && unitNodeVal !== "" && unitNodeVal !== unitName) {
                            bootbox.alert(DataManager.getDataManager().get('localeManager').get('PreviousUnit', unitName));
                        }
                    }, 300);
                }
                if (newValue !== "") {
                    self.roundingFactor(true);
                }
                else {
                    self.roundingFactor(false);
                }
                self.measurementUnit(newValue);
                self.recipientUnit(newValue);
            });
            $('#measurementUnit' + self.encodeId).keyup(function (view) {
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                if (!view.target.value.trim() == "") {
                    var unitMatch = self.measureUnits.findWhere({ name: view.target.value });
                    if (unitMatch) {
                        self.matchField[0].innerHTML = 'Match';
                        self.matchField.show();
                        $('#measurementUnit' + self.encodeId).next().find('li').filter(function () { return $(this).text() === view.target.value; }).click();
                    }
                    else if (!view.target.value == "") {
                        self.matchField[0].innerHTML = 'New';
                        self.matchField.show();
                    }
                    self.measurementUnit(view.target.value);
                }
                else {
                    self.matchField.hide();
                }
            });
            $('#measurementUnit' + self.encodeId).dblclick(function (view) {
                $('#measurementUnit' + self.encodeId).trigger('keyup');
                $('#measurementUnit' + self.encodeId).focus();
            });
            self.recipientUnit(self.measurementUnit());
            if (self.model && self.options.addOptions.type === "contributeWhatValues") {
                $('#measurementUnit' + self.encodeId).prop('disabled', true);
                self.roundingFactor(false);
                self.enableRecipientOpinion(true);
                $('#satisfactionIntervalAddIconDiv' + self.encodeId).hide();
                $("icon[id*='satisfactionIntervalDeleteIcon']").hide();
            }
            var Extended = Backbone.Typeahead.extend({
                template: '<input type="text" value ="' + self.satisfactionUnitValue() + '" id="satisfactionUnit' + self.encodeId + '" placeholder="'+DataManager.getDataManager().get('localeManager').get('Unit')+'" class="form-control"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
            });
            var satisfactionUnittypeahead = new Extended({ collection: self.measureUnits });
            satisfactionUnittypeahead.setElement('#satisfactionUnitDiv' + self.encodeId).render();
            //$('#satisfactionUnit' + self.encodeId).prop('disabled', !cond);
            $('#satisfactionUnit' + self.encodeId).hide();
            // $('#formula' + self.encodeId).prop('disabled', cond);
            satisfactionUnittypeahead.on('selected', function (fetchmodel) {
                self.satisfactionUnitValue(fetchmodel.get('name'));
                self.satRoundingDigits(fetchmodel.get('roundingDigits'));
                self.satSignificantDecimals(fetchmodel.get('significantDecimals'));
                self.matchField[0].innerText = "Match";
                self.matchField.show();
            });
            $('#satisfactionUnit' + self.encodeId).change(function (view) {
                self.satisfactionUnitValue(view.target.value.trim());
            });
            if (!self.isDimensional &&  self.satisfactionType() == "Grade" && !self.nominalValue) {
                self.satisfactionOnly(true);
                self.showNominal(false);
                self.weightRecipientVisible(false);
            }
            else {
                if (self.satisfactionType() == "Ranking") {
                    $('#satisfactionUnit' + self.encodeId).show();
                    self.satisfactionUnitMark(true);
                }
                else {
                    self.satisfactionUnitMark(false);
                }
                self.showSelectSatisfaction(false);

            }
            if (self.satisfactionOnly()) {
                self.showSatisfactionOnly()
            }
            if (self.satisfactionType() === "Ranking") {
                $('#satisfactionUnit' + self.encodeId).show();
                self.satisfactionUnitMark(true);
            }
            self.fillAggregateUIDetails(self.selectedAggregate());
            $("#valueName" + self.encodeId).keyup(function (view) {
                var newValue = view.currentTarget.value;
                self.name(newValue);
            });
            $("#weight" + self.encodeId).keyup(function (view) {
                var weightValue = view.target.value;
                if (weightValue > 100) {
                    $("#weight" + self.encodeId).val(100);
                    self.weight(100);
                }
            });

            $("#satisfactionUnit" + self.encodeId).keyup(function (view) {
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                var newValue = view.target.value.trim();
                self.satisfactionUnitValue(newValue);
                if (newValue != "") {
                    var unitMatch = self.measureUnits.findWhere({ name: view.target.value });
                    if (unitMatch) {
                        self.matchField[0].innerHTML = 'Match';
                        self.matchField.show();
                        $('#satisfactionUnit' + self.encodeId).next().find('li').filter(function () { return $(this).text() === view.target.value; }).click();
                    }
                    else {
                        self.matchField[0].innerHTML = 'New';
                        self.matchField.show();
                    }
                    self.satisfactionUnitValue(view.target.value);
                }
                else {
                    self.matchField.hide();
                }
            });
            // self.calculateSatisfaction();
            attachMouseDown();
            function attachMouseDown() {
                var eleID = ('modal' + self.encodeId);
                $('#' + eleID).mouseup(function (event) {
                    var satisfactionDivId = "#satisfactionDetailsDiv" + self.encodeId;
                    var aggregatedDivId = "#aggregatedDetailsDiv" + self.encodeId;
                    if (!($(satisfactionDivId).has($(event.target)).length > 0) && $(satisfactionDivId + ':hidden').length == 0) {
                        self && self.addSatisfactionInterval ? self.addSatisfactionInterval() : null;
                    }
                    if (!($(aggregatedDivId).has($(event.target)).length > 0) && $(aggregatedDivId + ':hidden').length == 0) {
                        self && self.saveAggregateDetails ? self.saveAggregateDetails() : null;
                    }       
                });
            }
            setSatisfactionIntervalAddIconCSS();
            if (self.model && self.readOnly != undefined && !self.planValue) {
                self.showValuePath(true);
            }

            self.postShow = true;
            //self.addColorsToPalette();
            $('#addAnotherColor' + self.encodeId).on('click', function () {
                $('#intervalSmiley' + self.encodeId).parent().show();
                $('.colorPickerComp').removeClass('hide');
                $('.colorPickerComp').css('display', 'block');
                if ($('#intervalSmiley' + self.encodeId).attr('value') == '' || $('#intervalSmiley' + self.encodeId).attr('value') == '0') {
                    $('#intervalSmileyPath' + self.encodeId).attr('d', self.defaultSmiley());
                    $('#intervalSmiley' + self.encodeId).attr('value', '1');
                }
                $('#colors-wrapper1' + self.encodeId).show();
                //$('#cp'+self.encodeId).colorpicker();
            });
            $('#cp' + self.encodeId).colorpicker();
            $("#cp" + self.encodeId).on('changeColor', function (ev) {
                var alpha = ev.color.toHex();
                var $svg = $('#intervalSmiley' + self.encodeId);
                $svg.attr('fill', alpha);
            });
            $('#colors-wrapper1' + self.encodeId).on('click', '.dwrapper', function () {
                //event.stopPropagation();
                var colorBoxes = $('.dwrapper');
                for (var i = 0; i < colorBoxes.length; ++i) {
                    if (colorBoxes[i] !== this) {
                        colorBoxes[i].removeAttribute("active");
                    }
                }
                if (!this.active) {
                    this.setAttribute("active", "active");
                }
                var color = $(this).children()[0].style.backgroundColor;
                color = color.replace(/ /g, '');
                var $svg = $('#intervalSmiley' + self.encodeId);
                $svg.attr('fill', color);
            });

            self.textAreaHeight();
            if(self.model){
                self.fillValueDefinition();
                self.fillProjectionProfile();
            }
            if(self.showFormulaField()){
                self.initialiseMonaco();
            }
            antlr4.error = {};
            antlr4.error.ErrorListener = antlr4.ErrorListener;
            const autosuggester = autosuggest.autosuggester(ExprLangLexer, ExprLangParser);

            self.intialiseTreeSelect();

            
            
            $('#editDetailsModalContent' + self.encodeId).css('overflow', 'visible');

            // Suggest completions for the string "ABC"
            // let suggestions = autosuggester.autosuggest("self");
            
        };

        self.maxBound.subscribe((val)=>{
            const psrsMaxBound = parseFloat(val);
            const parsMinBound = parseFloat(self.minBound());
            if(self.minBound() && psrsMaxBound < parsMinBound){
                self.maxMinBoundWarnMsg(DataManager.getDataManager().get('localeManager').get('MaxBoundWarn'));
            } else if(val && (self.equalValue() || self.equalValue() === 0)) {
                self.equalValue(null);
                self.maxMinBoundWarnMsg(DataManager.getDataManager().get('localeManager').get('EqualValueOrMaxMinBoundWarn'));
            } else {
                self.maxMinBoundWarnMsg('');
            }
            self.checkMaxMinBoundConstraints(self.minBound(), val, self.equalValue());
        })
        self.minBound.subscribe((val)=>{
            const psrsMinBound = parseFloat(val);
            const parsMaxBound = parseFloat(self.maxBound());
            if(self.maxBound() && psrsMinBound > parsMaxBound){
                self.maxMinBoundWarnMsg(DataManager.getDataManager().get('localeManager').get('MinBoundWarn'));
            } else if(val && (self.equalValue() || self.equalValue() === 0)) {
                self.equalValue(null);
                self.maxMinBoundWarnMsg(DataManager.getDataManager().get('localeManager').get('EqualValueOrMaxMinBoundWarn'));
            } else {
                self.maxMinBoundWarnMsg('');
            }
            self.checkMaxMinBoundConstraints(val, self.maxBound(), self.equalValue());
        })
        self.equalValue.subscribe((val)=>{
            if(val && (self.minBound() || self.maxBound() || self.minBound() === 0 || self.maxBound() === 0)) {
                self.minBound(null);
                self.maxBound(null);
                self.maxMinBoundWarnMsg(DataManager.getDataManager().get('localeManager').get('EqualValueOrMaxMinBoundWarn'));
            } else self.maxMinBoundWarnMsg('');
            self.checkMaxMinBoundConstraints(self.minBound(), self.maxBound(), val);
        })
        self.constraintCheck.subscribe((val)=>{
            if(val) self.checkMaxMinBoundConstraints(self.minBound(), self.maxBound(), self.equalValue());
            else self.isConstraintPass(true)
        })

    }


    addAggregateIfNeeded(valueName,flattenArray){
        var self = this;
        const existsInAggregateColl = self.aggregateColl().some(item => item.aggregateValue === valueName);
        const matchedOption = flattenArray.find(item => item.name === valueName);
        if (!existsInAggregateColl && matchedOption) {
            if (matchedOption) {
                self.selectedAggregate(matchedOption.value);
                self.saveAggregateDetails();
            }
        }
    }
    flattenArray(array) {
        let flattened = [];
        array.forEach(item => {
            flattened.push({ value: item.value,name: item.name });
            if (item.children && item.children.length > 0) {
                item.children.forEach(child => {
                    flattened.push({ value: child.value,name: child.name });
                });
            }
        });
        return flattened;
      }
    initialiseMonaco() {
        var self = this;
        let lastCursorPosition = null;
        var flattenedAggrOptions = self.flattenArray(self.aggregateOptions());
        var autoSuggester = autosuggest.autosuggester(ExprLangLexer, ExprLangParser,"LOWER");
        utils.setMonacoEnv();
        //var pack = self.parentView.get('type') === "vdml_BusinessModel" ? self.parentView.getNestedParent() : DataManager.getDataManager().get('currentPlan')
        var value = self.formula() ? self.formula() : ''; 
        const monacoBuilder = document.getElementById('formula' + self.encodeId);
        if(monacoBuilder){
            var editor = monaco.editor.create(monacoBuilder, {
                value,
                automaticLayout: true,
                lineNumbers: 'off',
                minimap: { enabled: false },
                language: 'exprLang',
                overviewRulerLanes: 0,
                scrollbar: {
                    vertical:"hidden",
                    horizontal: "hidden",
                    handleMouseWheel:true,
                },
                wordWrap: 'on'
            });
            editor.onDidChangeModelContent((event) => {
                const newValue = editor.getValue();
                self.formula(newValue);

                // DataManager
                const change = event.changes[0];
                
                if (change?.text) {
                    const { range, text } = change;

                    // Replace only the relevant part of monacoFormula
                    const startOffset = editor.getModel().getOffsetAt(range.getStartPosition());
                    const endOffset = editor.getModel().getOffsetAt(range.getEndPosition());
                    
                    const oldFormula = self.monacoFormula();
                    const updatedFormula = oldFormula.substring(0, startOffset) + 
                                           text + 
                                           oldFormula.substring(endOffset);
            
                    self.monacoFormula(updatedFormula);
            
                    // Additional logic
                    self.addAggregateIfNeeded(text, flattenedAggrOptions);
                }
            });
            editor.onDidChangeCursorPosition((event) => {
                lastCursorPosition = event.position; // Update cursor position
            });
            editor.updateOptions({
                quickSuggestions: {
                    other: true,
                    comments: false,
                    strings: true
                }
            });
        }
        monaco.editor.registerCommand("onSuggestionSelected", (accessor, id, range, length) => {
            if (!lastCursorPosition) {
                console.error("Cursor position is not available.");
                return;
            }
            const model = editor.getModel();
            const word = model.getWordUntilPosition(range ? range.startColumn : lastCursorPosition); // Get the word at the last position
            const formulaText = self.monacoFormula();

            if(formulaText && range){
                const prefix = formulaText.slice(0, range.startColumn-1);
                const postfix = formulaText.slice(prefix.length + length, self.monacoFormula().length)
                const parsedFormula = prefix + id + postfix
                self.monacoFormula(parsedFormula);
            }
        });
        monaco.languages.register({ id: 'exprLang' });
        this.completionItemProvider =  monaco.languages.registerCompletionItemProvider('exprLang', {
            triggerCharacters: ['.', '(', '[', '?'],
            provideCompletionItems: function(model, position) {
                const word = model.getWordUntilPosition(position);
                const range = new monaco.Range(
                    position.lineNumber,
                    word.startColumn,
                    position.lineNumber,
                    word.endColumn 
                );
                const inputText = model.getValue();
                var suggestions = [];
                var antlrSuggestions = [];
                const escapedText = self.htmlEscape(inputText);
                suggestions = self.getSuggestionsJulia(escapedText, range, self.currentAlternativeModel)              
                return { suggestions: [...antlrSuggestions, ...suggestions] };
            }
        });

        monaco.languages.setMonarchTokensProvider('exprLang', {
            tokenizer: {
                root: [
                    [/\b(self|if|bm|name|vp|activity|value)\b/, 'keyword'], // Keywords
                    [/\b[A-Za-z_][A-Za-z0-9_]*\b/, 'variable'], // Variables
                    [/./, 'text'], // Other text
                ]
        
            }
        });

        monaco.editor.defineTheme('customTheme', {
            base: 'vs', // or 'vs-dark'
            inherit: true,
            rules: [
                { token: 'keyword', foreground: 'b97bae', fontStyle: 'bold' }, // Purple for keywords
                { token: 'variable', foreground: '4e9fce', fontStyle: 'plain' }, // Blue italic for variables
                { token: 'text', foreground: '000000' }, // Black for general text
            ],
            colors: {}
        });
        monaco.editor.setTheme('customTheme');
    }

       /**
    * Creates a suggestion object for the Monaco Editor's IntelliSense system.
    *
    * @param {string} label - The main text displayed for the suggestion in the dropdown.
    * @param {string} detail - Additional descriptive text for the suggestion (optional).
    * @param {string} kind - The type of suggestion (e.g., 'Class', 'Keyword', 'Variable').
    * @param {object} range - The range within the editor where the suggestion will apply.
    * @param {string} [insertText=label] - The text to insert when the suggestion is selected (defaults to `label`).
    * @param {string} [sortText='1'] - A string used to determine the sorting order of the suggestion (defaults to '1').
    * @param {object|null} [command=null] - An optional command executed when the suggestion is selected.
    *
    * @returns {object} - The suggestion object formatted for the Monaco Editor.
    */

       createSuggestion(label, detail, kind, range, insertText = label, sortText = '1', command = null) {
        const suggestion = {
            label,
            detail,
            kind: monaco.languages.CompletionItemKind[kind],
            insertText,
            range,
            sortText
        };
        if (command) {
            suggestion.command = command;
        }
        return suggestion;
    }

    /**
    * Processes value propositions and custom propositions (e.g., formulas) associated with a business model (BM).
    * Generates suggestion objects for each proposition to be used in the Monaco Editor's IntelliSense system.
    *
    * @param {object} bm - The business model object containing Value propositions and My propositions.
    * @param {object} range - The range within the editor where the suggestions will apply.
    * 
    * @returns {Array} - An array of suggestion objects for the Value propositions and My Propositions.
    */
    
    processValuePropositions(bm, range) {
        var self = this;
        const suggestions = [];
        const vpList = bm.get("bmValueProposition")?.models;
        const mpList = bm.get("bmValueFormula")?.models;
    
        // Process Value Propositions
        vpList?.forEach(vp => {
            const vpName = vp.get("name");
            if (vpName) {
                suggestions.push(self.createSuggestion(
                    vpName,
                    'Value Proposition',
                    'EnumMember',
                    range
                ));
            }
        });
    
        // Process My Propositions
        mpList?.forEach(mp => {
            const mpName = mp.get("name");
            if (mpName) {
                suggestions.push(self.createSuggestion(
                    mpName,
                    'My Proposition',
                    'Enum',
                    range,
                    mpName,
                    '2'
                ));
            }
        });
    
        return suggestions;
    }

    /**
    * Processes activity suggestions associated with a specific Business Model (BM) and Value Proposition (VP).
    * Generates suggestion objects for each activity to be used in the Monaco Editor's IntelliSense system.
    *
    * @param {Array} bmList - The list of business models.
    * @param {string} bmName - The name of the business model to match.
    * @param {string} vpName - The name of the value proposition to match.
    * @param {object} range - The range within the editor where the suggestions will apply.
    * 
    * @returns {Array} - An array of suggestion objects for the activities associated with the specified VP and BM.
    */

    
    processActivitySuggestions(bmList, bmName, vpName, range) {
        var self = this;
        const suggestions = [];
        
        bmList?.forEach(bm => {
            // Check if the current BM's name matches the specified BM name.
            if (self.htmlEscape(bm.get("name")) !== bmName) return;
    
            const bmVpList = bm.get("bmValueProposition")?.models;
            bmVpList?.forEach(bmVp => {
                // Check if the current VP's name matches the specified VP name.
                if (self.htmlEscape(bmVp.get("name")) !== vpName) return;
    
                const vp = bmVp.get("valueProposition");
                const vpStream = bm.get("bmValueStream").findWhere({"persuedProposition": vp});
                const acts = vpStream?.get("valueStreamActivity")?.models;
                // Iterate through the activities and generate suggestions.
                acts?.forEach(act => {
                    const actName = act.get("name");
                    if (actName) {
                        suggestions.push(self.createSuggestion(
                            actName,
                            'Activity',
                            'Value',
                            range
                        ));
                    }
                });
            });
        });
    
        return suggestions;
    }

    /**
    * Processes built-in functions and generates suggestion objects for them.
    * These suggestions are used in the Monaco Editor's IntelliSense system.
    *
    * @param {object} range - The range within the editor where the suggestions will apply.
    * 
    * @returns {Array} - An array of suggestion objects for the built-in functions.
    */
    
    processFunctionSuggestions(range) {
        var self = this;
        const suggestions = [];
        // Retrieve the list of built-in functions from the utility module.
        utils.getbuiltinFunctions().forEach(func => 
            suggestions.push(self.createSuggestion(
                func.name,
                func.detail,
                'Function',
                range,
                `${func.name}()`,
                '2'
            ))
        );
        return suggestions;
    }

    /**
    * Processes a default suggestion and creates a formatted suggestion object.
    * Handles cases where the suggestion is a keyword or a generic type.
    *
    * @param {object} suggestion - The suggestion object containing the type and other details.
    * @param {object} range - The range within the editor where the suggestion will apply.
    * 
    * @returns {Array} - An array containing the formatted suggestion object.
    */
    
    processDefaultSuggestion(suggestion, range) {
        try{
            var self = this;
             // Check if the suggestion type is wrapped in single quotes (indicating a keyword).
             const isKeyword = /^'.*'$/.test(suggestion.type);

             // Remove the single quotes from the suggestion type, if present.
             const cleanSuggestion = suggestion.type.replace(/^'(.*)'$/, '$1');
            
            return [self.createSuggestion(
                cleanSuggestion,
                isKeyword ? 'Keyword' : undefined,
                isKeyword ? 'Keyword' : 'Variable',
                range,
                cleanSuggestion,
                '5',
                {
                    id: "onSuggestionSelected",
                    arguments: [cleanSuggestion, range, cleanSuggestion.length]
                }
            )];
        }catch(e){
            console.error(e);
        }
    }

    /**
    * Processes suggestions for Business Models (BMs) and creates a list of formatted suggestion objects.
    * Each business model is evaluated and used to generate corresponding suggestions.
    *
    * @param {Array} bmList - List of business models to process.
    * @param {object} range - The range within the editor where the suggestion will apply.
    * 
    * @returns {Array} - An array containing the formatted suggestion objects for each business model.
    */
    
    processBMSuggestions(bmList, range) {
        var self = this;
        const suggestions = [];

        // Iterate through each business model in the provided list.
        bmList?.forEach(bm => {
            const bmName = bm.get("name");
            const id = DataManager.getDataManager().htmlEscape(bm);
        
            if (bmName) {
                suggestions.push(self.createSuggestion(
                    bmName,
                    'Business Model',
                    'Class',
                    range,
                    bmName,
                    '1',
                    {
                        id: "onSuggestionSelected",
                        arguments: [id, range, bmName.length],
                    }
                ));
            }
        });
        return suggestions;
    }
    
    /**
    * Processes suggestions for Values based on provided BM and VP and creates a list of formatted suggestion objects.
    * Iterates through business models and their associated value propositions to generate suggestions.
    *
    * @param {Array} bmList - List of business models to process.
    * @param {string} bmName - The specific business model name to filter suggestions.
    * @param {string} vpName - The specific value proposition name to filter suggestions.
    * @param {object} range - The range within the editor where the suggestion will apply.
    * 
    * @returns {Array} - An array containing the formatted suggestion objects for each value proposition.
    */
    processVPValueSuggestions(bmList, bmName, vpName, range) {
        var self = this;
        const suggestions = [];
        
        bmList?.forEach(bm => {
            const currentBmName = self.htmlEscape(bm.get("name"));
            if (currentBmName !== bmName) return;
    
            const bmVpList = bm.get("bmValueProposition")?.models;
            bmVpList?.forEach(bmVp => {
                const currentVpName = self.htmlEscape(bmVp.get("name"));
                if (!currentVpName || currentVpName !== vpName) return;
    
                const vp = bmVp.get("valueProposition");
                const vpValList = vp?.get("component");
                
                vpValList?.forEach(vp => {
                    const valueName = vp.get("name");
                    const valueUnit = vp.get("unit")?.get("name");
                    suggestions.push(self.createSuggestion(
                        valueName,
                        `Value Unit : ${valueUnit}`,
                        'Value',
                        range
                    ));
                });
            });
        });
    
        return suggestions;
    }
    
    /**
    * Processes suggestions for Activity Values within a specified Business Model and Activity Associated with VP.,
    * filtering by activity name and generating relevant suggestions based on matching values and units.
    *
    * @param {Array} bmList - List of business models to process.
    * @param {string} bmName - The specific business model name to filter suggestions.
    * @param {string} vpName - The specific value proposition name to filter suggestions.
    * @param {string} actName - The specific activity name to filter suggestions.
    * @param {object} range - The range within the editor where the suggestion will apply.
    * 
    * @returns {Array} - An array containing the formatted suggestion objects for activity values.
    */
    processActivityValueSuggestions(bmList, bmName, vpName, actName, range) {
        var self = this;
        const suggestions = [];
        
        bmList?.forEach(bm => {
            const bmVpList = bm.get("bmValueProposition")?.models;
            const currentBmName = self.htmlEscape(bm.get("name"));
            
            if (currentBmName !== bmName) return;
    
            bmVpList?.forEach(bmVp => {
                const currentVpName = self.htmlEscape(bmVp.get("name"));
                if (!currentVpName || currentVpName !== vpName) return;
    
                const vp = bmVp.get("valueProposition");
                const vpStream = bm.get("bmValueStream").findWhere({"persuedProposition": vp});
                const vpActivity = vpStream?.get("valueStreamActivity");
    
                if (!vpActivity) return;
    
                const acts = vpActivity.models;
                acts?.forEach(act => {
                    const currentActName = self.htmlEscape(act.get("name"));
                    if (!currentActName || currentActName !== actName) return;
    
                    const bmAct = bm.get("bmActivity").findWhere({"activity": act});
                    const bmActVal = bmAct.get("activityValue").models;
                    const actValueList = vpStream.get("activityValue").models;
                    const commonValues = bmActVal.filter(value => actValueList.includes(value));
    
                    commonValues?.forEach(actVal => {
                        const name = actVal.get("name");
                        const valueUnit = actVal.get("unit")?.get("name");
                        suggestions.push(self.createSuggestion(
                            name,
                            `Value Unit : ${valueUnit}`,
                            'Value',
                            range
                        ));
                    });
                });
            });
        });
    
        return suggestions;
    }

    /**
    * Processes suggestions for values, including both parent values and their child values.
    * Generates suggestions based on options and children, as well as a direct suggestion
    * using the object's `name` property if available.
    *
    * @param {object} range - The range within the editor where the suggestion will apply.
    * 
    * @returns {Array} - An array containing formatted suggestion objects.
    */

    processValueSuggestions(range){
        var self = this;
        const suggestions = [];
        if(self.aggregateOptions && self.aggregateOptions().length > 0){
            self.aggregateOptions().forEach(function(option) {
                suggestions.push(self.createSuggestion(
                    option.name,
                    'Value',
                    'Value',
                    range,
                    option.name,
                    '1'
                ));
                if (option.children && option.children.length > 0) {
                    option.children.forEach(child => {
                        suggestions.push(self.createSuggestion(
                            child.name,
                            'Value',
                            'Variable',
                            range,
                            child.name,
                            '1'
                        ));
                    });
                }
            });
        }
        if(self.name && self.name()!==""){
            suggestions.push(self.createSuggestion(
                self.name(),
                'Value',
                'Variable',
                range,
                self.name(),
                '1',
                {
                    id: "onSuggestionSelected",
                    arguments: [self.name()], // Pass the unique ID
                }
            ));
        }
        return suggestions;
    }


    /**
    * Parses the given input text and generates suggestions based on the grammar rules.
    *
    * @param {string} input - The input text to parse and analyze.
    * 
    * @returns {Array} - An array of suggestions collected during parsing.
    */
    
    parseInput(input) {
        var self = this;
        const escapedText = self.htmlEscape(input);
        // Create an ANTLR input stream from the escaped input text.
        const chars = new antlr4.InputStream(escapedText);

        // Initialize the lexer with the input stream.
        const lexer = new ExprLangLexer(chars);

        // Create a token stream from the lexer output.
        const tokens = new antlr4.CommonTokenStream(lexer);

        // Initialize the parser with the token stream.
        const parser = new ExprLangParser(tokens);

        // Create another token stream for suggestions (can be reused).
        const tokenStream = new antlr4.CommonTokenStream(lexer);

        // Fill the token stream with all tokens from the input.
        tokenStream.fill();

        // Wrap the token stream in a custom wrapper to add additional functionality.
        const wrapper = new TokenStreamWrapper(tokenStream);

        // Create an instance of the custom Parser2 class for handling suggestions.
        const parse = new SuggestionParser();
            // Initialize a SuggestionCollector with the symbolic and literal names from the parser.
        const collector = new SuggestionCollector(
            parser.symbolicNames, // Symbolic names (e.g., tokens).
            parser.literalNames   // Literal names (e.g., keywords).
        );
        const preStack =[];
        const result = parse.suggest(
            parser.atn.states[0],
            wrapper,
            collector,
            preStack
        );
        return result;
    }
    
    getSuggestionsJulia(input, range, currentAlternativeModel) {
        var self = this;
        let suggestions = []; // Array to store all generated suggestions.
        try {
            // Parse the input text to get Julia-specific suggestions.
            const juliaSuggestions = self.parseInput(input);
            
            // Iterate through each suggestion obtained from the parsed input.
            juliaSuggestions?.forEach(suggestion => {
                // If the suggestion doesn't have a type or is not an object, skip this iteration.
                if (!suggestion?.type || typeof suggestion !== 'object') return;
        
                // Handle suggestions based on their type.
                switch (suggestion.type) {
        
                    case 'BM_IDENTIFIER':
                        // Handle Business Model identifiers.
                        const bmList = currentAlternativeModel.getBmList();
                        if (bmList?.length > 0) {
                            // Add Business Model suggestions.
                            suggestions.push(...self.processBMSuggestions(bmList, range));
                        }
                        break;
                            
                    case 'VP_IDENTIFIER':
                        // Handle Value Proposition identifiers.
                        if (suggestion.bmName) {
                            const bmList = currentAlternativeModel.getBmList();
                            bmList?.forEach(bm => {
                                // Match the Business Model name with the suggestion's bmName.
                                if (self.htmlEscape(bm.get("name")) === suggestion.bmName) {
                                    // Add Value Proposition suggestions for the matching Business Model.
                                    suggestions.push(...self.processValuePropositions(bm, range));
                                }
                            });
                        }
                        break;
    
                    case 'ACTIVITY_IDENTIFIER':
                        // Handle Activity identifiers.
                        if (suggestion.bmName && suggestion.vpName) {
                            const bmList = currentAlternativeModel.getBmList();
                            // Add Activity suggestions based on the given Business Model and Value Proposition names.
                            suggestions.push(...self.processActivitySuggestions(
                                bmList,
                                suggestion.bmName,
                                suggestion.vpName,
                                range
                            ));
                        }
                        break;
                        
                    case 'VP_VALUE_IDENTIFIER':
                        // Handle Value Proposition values.
                        if (suggestion.bmName && suggestion.vpName) {
                            const bmList = currentAlternativeModel.getBmList();
                            if (bmList?.length > 0) {
                                // Add Value Proposition value suggestions.
                                suggestions.push(...self.processVPValueSuggestions(
                                    bmList,
                                    suggestion.bmName,
                                    suggestion.vpName,
                                    range
                                ));
                            }
                        }
                        break;
        
                    case 'ACTIVITY_VALUE_IDENTIFIER':
                        // Handle Activity values.
                        if (suggestion.bmName && suggestion.vpName && suggestion.actName) {
                            const bmList = currentAlternativeModel.getBmList();
                            if (bmList?.length > 0) {
                                // Add Activity value suggestions.
                                suggestions.push(...self.processActivityValueSuggestions(
                                    bmList,
                                    suggestion.bmName,
                                    suggestion.vpName,
                                    suggestion.actName,
                                    range
                                ));
                            }
                        }
                        break;
                            
                    case 'FUNC_IDENTIFIER':
                        // Handle function suggestions.
                        suggestions.push(...self.processFunctionSuggestions(range));
                        break;
    
                    case 'IDENTIFIER':
                        // Handle general identifiers.
                        suggestions.push(...self.processValueSuggestions(range));
                        break;
        
                    default:
                        // Handle any other suggestions that don't fit into specific cases.
                        suggestions.push(...self.processDefaultSuggestion(suggestion, range));
                }
            });
        } catch (error) {
            // Log any errors encountered during the suggestion generation process.
            console.error('Error in getSuggestions:', error);
        }
    
        // Return the array of generated suggestions.
        return suggestions;
    }

    static getInstance(model, options) {
        var view = new ValueDetails2ViewModel(model, options);
        return view;
    };

}

path.ValueDetails2ViewModel = ValueDetails2ViewModel;