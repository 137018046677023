import * as $ from "jquery";
import * as _ from "underscore";
import * as Backbone from "backbone";
import * as ko from "knockout";
import * as kb from "knockback";
import * as bootbox from "../../../../../../libs/bootbox/bootbox";
import * as FileSaver from "../../../../../../libs/FileSaver/FileSaver";
import { DataManager } from "../../../../../com/vbee/data/DataManager";
import { ScenarioType } from '../../../../../version1/bo/vdml/ScenarioType'
import { PeriodDataset } from '../../../../../version1/bo/transformation/PeriodDataset'
import { ScenarioExecution } from '../../../../../version1/bo/transformation/ScenarioExecution'

var path = DataManager.getDataManager().buildAppNsPath("strategyMap.views.designer",global.version);
export class StrategyMapTargetViewModel {
  constructor(model, options) {
    var self = this;
    this.init(model, options);
  }

  init(model, options) {
    var self = this;
    this.StrategyMapTargetViewModel = this;
    this.model = model;
    this.parentView = model;
    this.encodeId = window.utils.htmlEscape(self.model.id);
    this.scenarioOptions = ko.observableArray([]);
    this.showExportButton = ko.observable(true);
    this.toPeriods = ko.observableArray([]);
    this.toYears = ko.observableArray([]);
    this.fromYears = ko.observableArray([]);
    this.fromPeriods = ko.observableArray([]);
    this.selectedFromYear = ko.observable("");
    this.selectedFromPeriod = ko.observable("");
    this.selectedToYear = ko.observable("");
    this.selectedToPeriod = ko.observable("");
    this.selectedPeriodOptions = ko.observableArray([]);
    this.targetsDataTable = null;
    this.targetValues = [];
    this.monthHash = {Jan: 1,Feb: 2,Mar: 3,Apr: 4,May: 5,Jun: 6,Jul: 7,Aug: 8,Sep: 9,Oct: 10,Nov: 11,Dec: 12};
    var dataManager = DataManager.getDataManager();
    this.curPlan = dataManager.get("currentPlan");
    var scenarioId = self.curPlan.get("defaultScenario");
    this.scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
    var defaultExecutionScenaroId = this.scenario.get("defaultExecutionScenario");
    this.defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
    this.periodSets = ko.observableArray([]);
    self.getColumnPeriodDataSets()

    const filteredYears = this.periodSets().filter((item, index, self) =>
      index === self.findIndex((t) => t.year === item.year)
    );
    self.fromYears(filteredYears.map(d => d.year));
    self.periodSets().map(d => {
      self.selectedPeriodOptions().push({ text: `${d.year}-${d.period}`, value: `${d.year}-${d.period}` })
    })
    self.selectedFromYear.subscribe(function (value) {
      if (value !== undefined) {
        self.getPeriodsByFromYear(value)
        var toYears = filteredYears.filter(d => d.year >= value).map(d => d.year)
        self.toYears(toYears);
        self.selectedToYear(self.toYears()[self.toYears().length - 1]);
      }
    });

    self.selectedFromPeriod.subscribe(function (value) {
      if (value !== undefined) {
        self.selectedFromPeriod(value)
        self.getToPeriodsByFromPeriods(value)
      }

    });
    self.selectedToYear.subscribe(function (value) {
      if (value !== undefined) {
        self.getPeriodsByToYear(value);
        self.selectedToYear(value);
      }
    });
    self.selectedToPeriod.subscribe(function (value) {
      if (value !== undefined) {
        self.selectedToPeriod(value);
      }
    });


  }

  getPeriodsByFromYear(year) {
    var self = this;
    let selectedYear = year
    const periods = self.periodSets().filter(d => d.year === +selectedYear)
    self.fromPeriods(periods.map(d => d.period));
    self.selectedFromPeriod(self.fromPeriods()[0])
  }

  getToPeriodsByFromPeriods(period) {
    var self = this;
    if (self.selectedFromYear() && self.selectedToYear() && self.selectedFromYear() === self.selectedToYear()) {
      var periodObj = self.periodSets().find((d) => d.year === self.selectedFromYear() && d.period === period)
      const periods = self.periodSets().filter(d => d.year === +self.selectedToYear())
      self.toPeriods(periods.filter(d => d.periodNumber >= periodObj.periodNumber).map(d => d.period));
      self.selectedToPeriod(self.toPeriods()[self.toPeriods().length - 1])
    }

  }
  getPeriodsByToYear(year) {
    var self = this;
    let selectedYear = year
    const periods = self.periodSets().filter(d => d.year === +selectedYear)
    self.toPeriods(periods.map(d => d.period));
    self.selectedToPeriod(self.toPeriods()[self.toPeriods().length - 1])
  }

  generateMetricsCSV() {
    var self = this;
    var fromPeriodDataset = self.periodSets().find(d => d.year === self.selectedFromYear() && d.period === self.selectedFromPeriod());
    var toPeriodDataset = self.periodSets().find(d => d.year === self.selectedToYear() && d.period === self.selectedToPeriod());
    var params = { fromYear: self.selectedFromYear(), toYear: self.selectedToYear(), fromPeriod: fromPeriodDataset.periodNumber, toPeriod: toPeriodDataset.periodNumber, scenarioId: self.scenario.get('id'), scenarioExecutionId: self.scenario.get("defaultExecutionScenario") }
    DataManager.getDataManager().get('currentPlan').scenarioExportDialog([params]);
  };

  showDropdown() {
    var self = this;
    const dropdownMenu = $("#targetDropdownMenu" + self.encodeId);
    dropdownMenu.toggle();
  }

  addObjective(view, event) {
    event.stopPropagation();
    var self = this;
    var id = window.utils.htmlEscape(window.guidGenerator());
    var options = {};
    var mixinClass= self.model.getMixinClass();
    window.getAndCreateModalDialog(self, id, mixinClass, self.model, "TargetValue", null, options);
  }

  getOrCreateTargetScenarioExecution(skipCreation){debugger
    var self = this;
    var plan = DataManager.getDataManager().get("currentPlan");
    var targetScExecution = plan.getTargetScenarioExecution();
    if(!targetScExecution && !skipCreation){
      var scenarioId = plan.get("defaultScenario");
      var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId}); 
      //var startTime = scenario.get('startTime');
      //var periodKind = plan.get("periodKind");
      targetScExecution = new ScenarioExecution({ id: window.planGuidGenerator(), name: "Target Scenario",scenarioType:ScenarioType.Target,planScenario:scenario.get('id')});//,changeSetEnabled:true });
      /*let stepObj = scenario.get("firstStep");
      while (stepObj) {
        const noOfPeriods = stepObj.get('noPeriods');
        plan.createDefaultDataSets(startTime, stepObj.get('startPeriod'), noOfPeriods, periodKind, targetScExecution);
        stepObj = stepObj.get("next");
      }*/
    }
    return targetScExecution;
  }

  loadTargetDatasets(execScenario,periodList,callback){
    var self = this;
    self.inputDatasetList = [];
    var missingDatasetList = [];
    _.each(periodList,function(periodObj){
      var inputDataset = execScenario.get("input").findWhere({period: periodObj.periodNumber, periodKind : self.curPlan.get("periodKind"), year:periodObj.year});
      if(inputDataset){
        self.inputDatasetList.push(inputDataset);
      } else {
        missingDatasetList.push(periodObj);
      }
    });
    
    callback(self.inputDatasetList);
    /*
    let periodObject = {};
    missingDatasetList.forEach(item => {
      if (!periodObject[item.year]) {
        periodObject[item.year] = [];
      }
      periodObject[item.year].push(item.periodNumber);
    });
    DataManager.getDataManager().getPeriodDatasetsByScenarioExecution(self.curPlan.get('id'), scenarioId, periodObject, (data) => {
      if (data) {
        callback(data.input);  
      } else {
        callback(new Error("Failed to retrieve period data"));
      }
    });*/
  }

  createValueRows(inputDatasetList) {
    var self = this;
    self.targetValues = [];
    _.each(inputDatasetList,function(inputDataset){
      var attributes = Object.getOwnPropertyNames(inputDataset.attributes);
      for(var i=0;i<attributes.length;i++){
        var key = attributes[i];
        if(key == "year" || key == "s" || key == "id" || key == "period" || key == "inputOwner" || key == "documentVersion" || key == "documentId" || key == "version" ||
           key == "tag" || key == "assignedTicket" || key == "owner" || key == "user" || key == "dependentPackage" || key == "next" || key == "resultOwner" || key == "previous" ||
           key == "planId" || key == "periodKind" || key == "previousDocumentVersion" || key == "type" || key == "ownedTag" ){
          continue
        }
        var qty = inputDataset.get(key);
        var valObj = self.targetValues.filter(obj => obj.id == key);
        var periodText = null;
        if(inputDataset.get('periodKind') == "Yearly"){
            periodText = '1';
        }else{
            var period = self.curPlan.getPeriodKinds(inputDataset.get('year'), inputDataset.get('period'), self.curPlan.get("periodKind"));
            periodText = period ? period.text : inputDataset.get('period');
        }
        var yearPeriod = inputDataset.get('year') + '-' + periodText;//convert to label
        if(key.length > 10){
          var valModal = window.utils.getElementModel(key,['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']);
          if(!valModal){
            valModal = window.utils.getElementModel(key,['vdml.ValueCategory','vdml.ValueDefinition']);
          }
        }
        if(valModal){
            qty = valModal.getSignificantQty(qty);
            if(valObj){
              valObj[yearPeriod] = qty;
            } else {
              var obj = {'id':valModal.id,'name':valModal.get('name')};
              obj[yearPeriod] = qty;
              self.targetValues.push(obj);
            }
        } else {
          if(valObj.length){
            valObj[0][yearPeriod] = qty;
          } else {
            var obj = {'id':key,'name':key};
            obj[yearPeriod] = qty;
            self.targetValues.push(obj);
          }
        }
      }
    })
  }

  saveQuantity(view){
    var self = this;
    var year = view.year;
    var period = view.period;
    var key = view.id;
    var qty = view.qty;
    var periodKind = self.curPlan.get("periodKind")
    var targetEx = self.getOrCreateTargetScenarioExecution(false);
    var	inputDataset = targetEx.get("input").findWhere({period: period,year: year,periodKind : periodKind});				
		//if(qty!=="-" && qty!==""){
      if(!inputDataset){
        inputDataset = new PeriodDataset({ id: window.planGuidGenerator(), year: year, periodKind: periodKind, period: period, inputOwner: targetEx });
        targetEx.get("input").add(inputDataset);
      }
      inputDataset.set(key,qty);
    //}
    //refresh table
    self.instanceCreation();
  }

  createTable() {
    var self = this;
    const tarBut = document.getElementById('tarTabAddAnotherBut');
    const sortedDataSet = self.periodSets().sort((a, b) => {
      if (a.year === b.year) {
        return a.periodNumber - b.periodNumber;
      }
      return a.year - b.year;
    });
    const noOfColumns = (sortedDataSet.length + 2)
    const colWidth = 100 / noOfColumns;
    const valueColWidth = colWidth * 2;
    const tarTableColumn = [{ data: "value", title: "Value", width: `${valueColWidth}%` }];
    sortedDataSet.forEach((ele) => {
      const colHeader = ele.year + '-' + ele.period;
      tarTableColumn.push({ data: colHeader, title: colHeader, width: `${colWidth}%` })
    })
    var rowData = [];
    self.targetValues.forEach((targetValue) => {
        var temp = {};
        temp['value'] = targetValue.name;
        temp['width'] = `${colWidth}%`;
        sortedDataSet.forEach((ele) => {
            var yearPeriodColumn = ele.year + '-' + ele.period;
            var quantity = '-';
            if(targetValue[yearPeriodColumn]){
                quantity = targetValue[yearPeriodColumn];
            }
            temp[yearPeriodColumn] = quantity;
        })
        rowData.push(temp);
    })
    self.targetsDataTable = $("#targetDataTable").DataTable({
      data: rowData,
      columns: tarTableColumn,
      rowId: function (data) {
        return data.modalId;
      },
      scrollX: false,
      scrollY: "95%",
      scrollCollapse: true,
      paging: false,
      width: "100%",
      ordering: false,
      bInfo: false,
      pageResize: true,
      layout: {
        topEnd: ['search', tarBut]
      },
      "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        $('td', nRow).each(function() {
            if(this.innerText == '-' || this._DT_CellIndex.column) {
                this.className = "text-right";
                $(this).on("keydown", function(event) {
                    $(this).prop('oldValue', $(this).val());
                    window.utils.checkNumericValue(event, this);
                });
                $(this).on("keyup", function(event) {
                    let currentValue = $(event.target).val();
                    var decimalLimit = 4;
                    var rgx = new RegExp(`^\\-?[0-9]*\\.?[0-9]{0,${decimalLimit}}$`);
                    if (!rgx.test(currentValue)) {
                        $(event.target).val($(this).prop('oldValue'));
                    }
                });

                $(this).on('dblclick', function() {
                    var cell = $(this);
                    if (!cell.is('[contenteditable]')) {
                        cell.attr('contenteditable', 'true').focus();
                    }
                });

                $(this).blur(function(event) {debugger
                    var qty = event.currentTarget.innerText;
                    var phaseAltName = $('#targetDataTable tr th').eq(event.currentTarget.cellIndex).find('span.dt-column-title').text();
                    var currentPlan = DataManager.getDataManager().get('currentPlan');
                    if (currentPlan.get("periodKind") === "Yearly") {
                        phaseAltName = phaseAltName + "-1"
                    }
                    var valuename=$('#targetDataTable tr').eq(event.currentTarget._DT_CellIndex.row+1).text();
                    var id = valuename.substring(0,valuename.indexOf(')')+1).trim();
                    var yearPeriodArr = phaseAltName.split('-');
                    var year = parseInt(yearPeriodArr[0]);
                    var period = self.monthHash[yearPeriodArr[1]];
                    if(!period){
                        var temp = yearPeriodArr[1];
                        period = parseInt(temp.substring(temp.length-1, temp.length));
                    }
                    var obj = {year:year, period:period,id:id,qty:qty};
                    self.saveQuantity(obj);
                    $(this).removeAttr('contenteditable');
                });
            } else {
                var a = document.createElement('a');
                a.style.color = 'inherit';
                a.style.cursor = 'pointer';
                a.setAttribute('id', aData["suffix"]);
                var val = this.innerText;
                a.appendChild(document.createTextNode(val));
                this.innerText = '';
                this.append(a);
                $(a).on('contextmenu', function(event) {
                    event.preventDefault();
                    var qty = event.currentTarget.innerText;
                    var cellIndex = event.currentTarget.parentElement.cellIndex;
                    var phaseAltName = $('#firstScenarioTable tr th').eq(cellIndex).find('span.dt-column-title').text();
                    var currentPlan = DataManager.getDataManager().get('currentPlan');    
                });
            }
        });
      }
    });
  }

  getColumnPeriodDataSets() {
    var self = this
    // var plan = DataManager.getDataManager().get('currentPlan');

    var steps = self.scenario.get("step")
    for (var step of steps.models) {
      var periodsLen = step.get('noPeriods');
      var period = step.get("startPeriod");
      for (var l = 0; l < periodsLen; l++) {
        if (l != 0) {
          period++;
        }
        let yearPeriod = utils.calculateYearPeriod(self.scenario.get("startTime"), period, self.curPlan.get("periodKind"));
        //var phaseAltName = phases.at(j).get('name') + "/" + alternatives.at(i).get('name');
        var periodKind = self.curPlan.getPeriodKinds(yearPeriod.year, yearPeriod.period, self.curPlan.get("periodKind"));
        var periodKindText = periodKind?.text || yearPeriod.period;
        self.periodSets().push({ year: yearPeriod.year, period: periodKindText, periodNumber: periodKind.value, altId: step.get('alternativeId') })
      }
    }
  }

  instanceCreation(view) {
    var self = this;
    if (self.targetsDataTable) self.targetsDataTable.destroy();
    var tgSc = self.getOrCreateTargetScenarioExecution(true);
    if(tgSc){
      //show spinner
      self.loadTargetDatasets(tgSc,self.periodSets(),function(inputDatasetList){
        self.createValueRows(inputDatasetList);
        self.createTable();
        //stop spinner
      });
    } else {
      self.createTable();//empty
    }
    const checkVisibility = setInterval(function () {
      if ($(".dt-scroll-headInner").is(":visible")) {
        self.targetsDataTable.columns.adjust();
        clearInterval(checkVisibility); // Stop checking once it's visible
      }
    }, 1);
  }

  static getInstance(model, options) {
    var view = new StrategyMapTargetViewModel(model, options);
    return view;
  }
}
path.StrategyMapTargetViewModel = StrategyMapTargetViewModel;
