import * as _ from 'underscore'
import { ValueDeliveryModelMixin } from "./ValueDeliveryModelMixin";
import {DataManager} from '../../../com/vbee/data/DataManager'
import { VdmlElement } from './VdmlElement';
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin';
import {BeepPackageMixin} from '../beeppackage/BeepPackageMixin'
import {Scenario} from './Scenario'
import { ValueElement } from './ValueElement';
import { ValuePropositionComponent } from './ValuePropositionComponent';

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

export class ValueDeliveryModel2Mixin {
    static getMixinRelations(){
        var ret = ValueDeliveryModelMixin.getMixinRelations().filter(item =>{
            return item.key !== "metricsModel" && item.key !== "defaultMetricsModel";
        });
        return ret;
    }
    
    static getCumulativeMixinRelations(){
        if (!ValueDeliveryModel2Mixin.cummulativeRelations) {
            ValueDeliveryModel2Mixin.cummulativeRelations = _.union(ValueDeliveryModel2Mixin.getMixinRelations()
                ,DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
                ,BeepPackageMixin.getCumulativeMixinRelations()
            );
        }
        return ValueDeliveryModel2Mixin.cummulativeRelations.slice();
    }
    static getDialogViewProperties(type){
        ValueDeliveryModelMixin.getDialogViewProperties(type);
        if (type === "ProjectionProfileJson") {
            return {
                templatePath: "views/valueLibrary/properties/ProjectionProfileJsonPropertiesTemplate.html",
                templateName: "ProjectionProfileJsonPropertiesTemplate",
                viewTypeStr: "appviews/valueLibrary/properties/ProjectionProfileJsonViewModel",
                tabId: "ProjectionProfileJsonView",
                tabName: "ProjectionProfileJson"
            }
        }
    }
    createScenario(snapshotId){
        var scenarioId = DataManager.getDataManager().guidGeneratorByOwner(this);
        var scenarioName = this.getUniqueProperty('name','Base Scenario','scenario');
        var scenario = new Scenario({id:scenarioId,name:scenarioName,scenarioOwner:this});
        return scenario;
    };
    createValueDetails(valueModal, oldVpc,satisfaction, weight, recipientValue){
        var self = this;
       self.createMeasurementCharacteristics(valueModal, oldVpc, satisfaction, weight, recipientValue)
    };
    createMeasurementCharacteristics(valueModal, oldVpc, satisfaction, weight, recipientValue){
        var repId = DataManager.getDataManager().getRepositoryId(valueModal.id);
        var valId;
        var valueName = valueModal.get('name');
        var nameVal;
        if (valueModal.get('type') != "vdml_ValueAdd") {
            if (!valueModal.get('satisfactionLevel')) {
                if (satisfaction && satisfaction !== "") {
                    if (oldVpc && oldVpc.get('satisfactionLevel')) {
                        valId = repId + window.utils.getSuffix(oldVpc.get('satisfactionLevel').id);
                    } else {
                        valId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
                    }

                    nameVal = valueName + ' Satisfaction Level';
                    var satisfactionLevel = new ValueElement({ id: valId, name: nameVal, description: nameVal ,satisfactionLevelOwner: valueModal});
                    valueModal.set('satisfactionLevel', satisfactionLevel);
                }
            }
            if (!valueModal.get('percentageWeight')) {
                if (weight && weight !== "") {
                    if (oldVpc && oldVpc.get('percentageWeight')) {
                        valId = repId + window.utils.getSuffix(oldVpc.get('percentageWeight').id);
                    } else {
                        valId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
                    }

                    nameVal = valueName + ' Percentage Weight';
                    var percentageWeight = new ValueElement({ id: valId, name: nameVal, description: nameVal , percentageWeightOwner: valueModal});
                    valueModal.set('percentageWeight', percentageWeight);
                }
            }
            if (!valueModal.get('recipientOpinion')) {
                if (recipientValue && recipientValue !== "") {
                    if (oldVpc && oldVpc.get('recipientOpinion')) {
                        valId = repId + window.utils.getSuffix(oldVpc.get('recipientOpinion').id);
                    } else {
                        valId = DataManager.getDataManager().guidGeneratorByOwner(valueModal);
                    }
                    var nameVal = valueName + ' Recipient Opinion';
                    var recipientMeasurement = new ValueElement({ id: valId, name: nameVal, description: nameVal, recipientOpinionOwner: valueModal});
                    valueModal.set('recipientOpinion', recipientMeasurement);
                }
            }
        }
    }
}
utils.customExtendClass (ValueDeliveryModel2Mixin,new ValueDeliveryModelMixin());

path.ValueDeliveryModel2Mixin = ValueDeliveryModel2Mixin;