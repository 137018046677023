import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import { BMDetailsViewModel } from './BMDetailsViewModel'
import { PeriodKind } from '../../../../bo/vdml/PeriodKind'

/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","async","appcommon/com/vbee/data/DataManager","app/global"],
function(require,$,_, Backbone,ko, koMapping,kb,bootbox,async,DataManager,global)
{*/
	var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties",global.version);
	export class BMDetails2ViewModel extends BMDetailsViewModel {


        getPeriodsByYear(year,plan){
            var self = this;
            let selectedYear = year
            const periods = self.dataSets.filter(d=>d.year===+selectedYear)
            const periodsByPeriodKind = periods.map(d=>{ return plan.getPeriodKinds(d.year,d.period,self.periodKind)});
            self.periodMonths(periodsByPeriodKind);
        }

        loadDatasets(period, year, callback) {
            var self = this;
            self.scenario.getDatasetAsync(year, period, self.periodKind, null, function(periodDataset, calcPeriodDataset) {
                self.periodDataset = periodDataset;
                self.calcPeriodDataset = calcPeriodDataset;
                callback(periodDataset, calcPeriodDataset);
            });
        }
		
		init(model, options){
           super.init(model,options);
           var self = this;
           var dataManager = DataManager.getDataManager();
            this.periodYears =  ko.observableArray();
            this.periodMonths = ko.observableArray();
            this.selectedYear = ko.observable();
            this.selectedPeriod = ko.observable();
            this.showPeriods = ko.observable(true)
            this.periodKind = dataManager.get('currentPlan').get("periodKind");
            var scenarioId = dataManager.get("currentPlan").get("defaultScenario");
            var currentAlternative =  Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: dataManager.get('viewAlternative')});
            this.scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
            var currentPlan = dataManager.get('currentPlan');
            this.dataSets = currentAlternative.getPhaseAlternativeSteps(this.scenario);
            const filteredYears = this.dataSets.filter((item, index, self) =>
                index === self.findIndex((t) => t.year === item.year)
            );
            this.periodYears(filteredYears.map(d=>{return {text:d.year,value:d.year}}));
            self.selectedYear.subscribe(function(value){
                if(value!==undefined){
                    self.getPeriodsByYear(value,currentPlan);
                    self.defaultPeriod?self.selectedPeriod(self.defaultPeriod):self.selectedPeriod();
                    self.defaultPeriod=undefined
                }
            });

            var defaultPeriod = this.scenario.fillDefaultPeriods(this.dataSets,self.periodKind);
            self.defaultPeriod = defaultPeriod.period
            dataManager.set('currentPeriod',defaultPeriod.period);
            self.selectedYear(defaultPeriod.year);
            dataManager.set("currentYear",defaultPeriod.year);
        }

        saveBMDetails(){
			var self = this;
            if(self.name().trim() != self.model.get('name')){
                self.model.set('name',self.name().trim());
                if(!self.parentView.BusinessModelViewModel){
                    window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'),DataManager.getDataManager().get('viewAlternative'),self.BMDetailsViewModel);
                }
            }
            self.model.set('description',self.description());
            var dataManager = DataManager.getDataManager();
            var triggerDiagChange = false;
            if(self.selectedPeriod() != dataManager.get("currentPeriod") || self.selectedYear() != dataManager.get("currentYear")){
                triggerDiagChange = true;
            }
            dataManager.set("currentYear", self.selectedYear());
            dataManager.set('currentPeriod', self.selectedPeriod());
            self.defaultPeriod = self.selectedPeriod();
            self.defaultYear = self.selectedYear();
            if(triggerDiagChange){
                self.refreshWidgetData();
            }
		};
        refreshWidgetData(){
            var self = this;
            window.utils.startSpinner('loadingValues', "loading Values...");
            window.setTimeout(function() {
                self.loadDatasets(self.selectedPeriod(), self.selectedYear(), function() {
                    if(self.parentView.showPost){
                        window.vdmModelView.refreshWidgetData(true);
                    }
                    window.utils.stopSpinner('loadingValues');
                });
            }, 100);
        }

    static getInstance(model, options) {
        var view = new BMDetails2ViewModel(model, options);
        view.init(model, options);
        return view;
    };	
}
path.BMDetails2ViewModel = BMDetails2ViewModel;
