import { IDENTIFIER_MAPPINGS } from "./antlr4Constants";
import { DEFAULT_IDENTIFIER } from './antlr4Constants';
import ExprLangLexer from "./ExprLangLexer";

/**
 * Collects and manages suggestions for code completion based on different identifier types
 * and contexts. Handles various identifier patterns including business models (bm),
 * value propositions (vp), and activities.
 */
class SuggestionCollector {
    /**
     * Creates a new SuggestionCollector instance.
     * 
     * @param {string[]} symbolicNames - Array of symbolic names for tokens
     * @param {string[]} literalNames - Array of literal names for tokens
     */
    constructor(symbolicNames, literalNames) {
        this.collected = new Map();
        this.symbolicNames = symbolicNames;
        this.literalNames = literalNames;
    }

    /**
     * Collects suggestions based on token type and context.
     * Handles different identifier types (VP, Activity, VP Value, Activity Value)
     * and maintains context information from the inputRuleStack.
     * 
     * @param {number} tokenType - The type of token being processed
     * @param {string[]} inputRuleStack - Stack of previously processed tokens providing context
     * @param {Object} state - Current parser state
     */
    collect(tokenType, inputRuleStack, state) {
        const symbolicName = this.symbolicNames[tokenType];
        const literalName = this.literalNames[tokenType];
    
        if (tokenType === ExprLangLexer.IDENTIFIER) {
            const identifierType = this.getIdentifierType(state);

            // Handle VP (Value Proposition) identifiers
            if (identifierType === 'VP_IDENTIFIER') {
                const bmName = this.getBmContext(inputRuleStack);
                const uniqueKey = `VP_IDENTIFIER:${bmName}`;
                this.collected.set(uniqueKey, { type: identifierType, bmName });
            } 
            // Handle Activity identifiers
            else if(identifierType === 'ACTIVITY_IDENTIFIER'){
                const bmName = this.getBmContext(inputRuleStack);
                const vpName = this.getVpContext(inputRuleStack);
                const uniqueKey = `ACTIVITY_IDENTIFIER:${bmName}:${vpName}`;
                this.collected.set(uniqueKey, {type: identifierType, bmName, vpName})
            } 
            // Handle VP Value identifiers
            else if(identifierType === 'VP_VALUE_IDENTIFIER'){
                const bmName = this.getBmContext(inputRuleStack);
                const vpName = this.getVpContext(inputRuleStack);
                const uniqueKey = `VP_VALUE_IDENTIIFIER:${bmName}:${vpName}`;
                this.collected.set(uniqueKey, {type: identifierType, bmName, vpName}) 
            } 
            // Handle Activity Value identifiers
            else if(identifierType === 'ACTIVITY_VALUE_IDENTIFIER'){
                const bmName = this.getBmContext(inputRuleStack);
                const vpName = this.getVpContext(inputRuleStack);
                const actName = this.getActivityContext(inputRuleStack);
                const uniqueKey = `ACTIVITY_VALUE_IDENTIFIER:${bmName}:${vpName}:${actName}`;
                this.collected.set(uniqueKey, {type: identifierType, bmName, vpName, actName}) 
            } 
            // Handle default identifier cases
            else {
                const uniqueKey = identifierType || symbolicName || literalName;
                this.collected.set(uniqueKey, { type: identifierType || symbolicName || literalName });
            }
        } else {
            const uniqueKey = symbolicName || literalName;
            this.collected.set(uniqueKey, { type: symbolicName || literalName });
        }
    }

    /**
     * Determines the type of identifier based on the current parser state.
     * 
     * @param {Object} state - Current parser state
     * @returns {string} The identified type or DEFAULT_IDENTIFIER if no match is found
     */
    getIdentifierType(state) {
        const identifierMappings = IDENTIFIER_MAPPINGS;
        for (const [identifierType, states] of Object.entries(identifierMappings)) {
            if (states.includes(state.stateNumber)) {
                return identifierType;
            }
        }
        return DEFAULT_IDENTIFIER;
    }

    /**
     * Extracts the business model context from the inputRuleStack.
     * Looks for the pattern: 'bm' followed by '.' and a valid identifier.
     * 
     * @param {string[]} inputRuleStack - Stack of previously processed tokens
     * @returns {string|null} The business model identifier or null if not found
     */
    getBmContext(inputRuleStack) {
        const matchIndex = inputRuleStack.lastIndexOf('bm');
        
        if (matchIndex !== -1 && inputRuleStack[matchIndex + 1] === '.' && matchIndex + 2 < inputRuleStack.length) {
            const bmCandidate = inputRuleStack[matchIndex + 2];
            if (/^[a-zA-Z_][a-zA-Z0-9_ ]*$/.test(bmCandidate)) {
                return bmCandidate;
            }
        }
        return null;
    }

    /**
     * Extracts the value proposition context from the inputRuleStack.
     * Looks for the pattern: 'vp' followed by '.' and a valid identifier.
     * 
     * @param {string[]} inputRuleStack - Stack of previously processed tokens
     * @returns {string|null} The value proposition identifier or null if not found
     */
    getVpContext(inputRuleStack) {
        const matchIndex = inputRuleStack.lastIndexOf('vp');
    
        if (matchIndex !== -1 && inputRuleStack[matchIndex + 1] === '.' && matchIndex + 2 < inputRuleStack.length) {
            const vpCandidate = inputRuleStack[matchIndex + 2];
            if (/^[a-zA-Z_][a-zA-Z0-9_ ]*$/.test(vpCandidate)) {
                return vpCandidate;
            }
        }
        return null;
    }

    /**
     * Extracts the activity context from the inputRuleStack.
     * Looks for the pattern: 'activity' followed by '.' and a valid identifier.
     * 
     * @param {string[]} inputRuleStack - Stack of previously processed tokens
     * @returns {string|null} The activity identifier or null if not found
     */
    getActivityContext(inputRuleStack) {
        const matchIndex = inputRuleStack.lastIndexOf('activity');
        
        if (matchIndex !== -1 && inputRuleStack[matchIndex + 1] === '.' && matchIndex + 2 < inputRuleStack.length) {
            const activityCandidate = inputRuleStack[matchIndex + 2];
            if (/^[a-zA-Z_][a-zA-Z0-9_ ]*$/.test(activityCandidate)) {
                return activityCandidate;
            }
        }
        return null;
    }

    /**
     * Gets the label for a given token type.
     * 
     * @param {number} tokenType - The type of token
     * @returns {string} The symbolic or literal name of the token
     */
    getLabel(tokenType){
        const symbolicName = this.symbolicNames[tokenType];
        const literalName = this.literalNames[tokenType];
        return symbolicName || literalName;
    }

    /**
     * Returns all collected suggestions as an array.
     * 
     * @returns {Array} Array of collected suggestion objects
     */
    getCollected() {
        return Array.from(this.collected.values());
    }
}

export { SuggestionCollector };