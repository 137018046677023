import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {Spinner} from '../../../../../../libs/spin/spin.min'
import * as async from 'async'
import moment from 'moment';
import 'moment/locale/es'
import {DateFormat} from '../../../../../com/vbee/utils/DateFormat'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {Status} from '../../../../../version1/bo/transformation/Status'
import {Alternative} from '../../../../../version1/bo/transformation/Alternative'
import {Phase} from '../../../../../version1/bo/transformation/Phase'
import {AlternativeViewModel} from './AlternativeViewModel'
import {Plan} from '../../../../../version1/bo/transformation/Plan'
import {AlternativeMixin} from '../../../../../version1/bo/transformation/AlternativeMixin'
import {PhaseViewModel} from './PhaseViewModel'
//import {PhaseMixin} from '../../../../../version1/bo/transformation/PhaseMixin'
import { Phase2Mixin } from '../../../../bo/transformation/Phase2Mixin'

/*define(["require","jquery","jqueryui","underscore","backbone","appcommon/com/vbee/utils/DateFormat","knockout","knockoutMapping","knockback","bootbox","spin","async","Lawnchair","appcommon/com/vbee/data/DataManager","app/global","appbo/transformation/Status","appbo/transformation/Alternative","appbo/transformation/Phase","appviews/transformation/views/properties/AlternativeViewModel","appbo/transformation/Plan","appbo/transformation/AlternativeMixin","appviews/transformation/views/properties/PhaseViewModel","appbo/transformation/PhaseMixin","moment"],
function(require,$,jqueryui,_, Backbone,DateFormat,ko, koMapping,kb,bootbox,Spinner,async,Lawnchair,DataManager,global,Status,Alternative,Phase,AlternativeViewModel,Plan,AlternativeMixin,PhaseViewModel,PhaseMixin,moment)
{*/
	var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties",global.version);
	export class PhaseDetailsViewModel {
        //this.init(model, options, detailedView);
    //}
    
    htmlEscape(str){
        return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
    };
    cleanUp(){
			this.alternativeArray.length = 0;
			this.children.length = 0;
			//this.primarySubscribe.dispose();
			//this.phaseAlternative.length = 0;
	};
	setPrimarySelected(){
		var self = this;
		if(self.model){
	    	var primary = self.model.get('primary');
	    	if(primary){
	    		for(var i=0;i<self.phaseAlternative().length;i++){
		    		if(self.phaseAlternative()[i].originalId === primary.id && self.phaseAlternative()[i].del === false){
		    			var primaryAlt = document.getElementById("selectPhaseAlternative"+self.encodeId).children[i];
		    			if(primaryAlt) {
		    				primaryAlt.setAttribute("selected", "selected");
							break;
		    			}
		    		}
		    	}
	    	}
	    }
	    else{
	    	var firstAlt = document.getElementById("selectPhaseAlternative"+self.encodeId).children[0];
			firstAlt.setAttribute("selected", "selected");
	    }
	};

	/*PhaseDetailsViewModel.prototype.checkUsePrevious = function(callback){debugger
		var self = this;
		var usePrevious = false;
		var plan = self.model.getNestedParent();
		var currentPrimary = self.model.get('primary');
		var planLib = plan.get('defaultMeasureLibrary')?plan.get('defaultMeasureLibrary').get('measureElements'):[];
		function checkLib(lib){
			var isPreviousExist = false;
			for(var i = 0; i < lib.length; i++){
				var meas = lib.at(i);
				if(meas.get('type') == "smm_CollectiveMeasure"){
					if(meas.get('measureRelationships').findWhere({'usePreviousAlternative':true})){
						isPreviousExist = true;
						break;
					}
				}
			}
			return isPreviousExist
		}
		usePrevious = checkLib(planLib);
		if(!usePrevious){
			var packageList = [];
			var packagesColl = currentPrimary.fetchNonCommonPackagesFromAlternative();
			_.each(packagesColl.models,function(designPart){
				if(designPart.get('beepType') === "vdml_ValueDeliveryModel" && designPart.get('beepReference').indexOf('Common') == -1) {
					packageList = packageList.concat(designPart);
				}
			});

			async.eachSeries(packageList,function(pack,scCallback){
				var altId = DataManager.getDataManager().getRepositoryId(pack.get('beepReference'));
			    var vdmStore = DataManager.getDataManager().getVDMStore(altId);
			    DataManager.getDataManager().fetchDocumentFromPackage(pack.get('beepReference'), "appbo/vdml/ValueDeliveryModel", pack.get('version'), pack.get('beepReference'), "appbo/vdml/ValueDeliveryModel", vdmStore, {
			        success: function (package) {
						var libEle = package.get('defaultMeasureLibrary')?package.get('defaultMeasureLibrary').get('measureElements'):[];
						usePrevious = checkLib(libEle);
						if(usePrevious){
							scCallback(usePrevious);
						}else{
							scCallback();
						}
					 },
			        error: function (error) {
			            scCallback();
			        }
				});
				
			},function(err){
				callback(usePrevious); 
			})
		}else {
			callback(usePrevious); 
		}
	}*/
	createNextPhase(){
		var self = this;
		var planModel = self.options.planModel;
		if(self.model && self.model.getParent().get('goal') == self.model){
			bootbox.alert("Cannot create Phase after Goal Phase");
			return false;
		}
		var id = self.htmlEscape(window.guidGenerator()) + "phase";
		var options = {"createNextPhase" : self};
		const mixinClass = DataManager.getDataManager().getModelTypeByTypeStr(self.model.get('type'), self.model.getNestedParent().get('version'), true);
		window.getAndCreateModalDialog(planModel,id,mixinClass,null,"PhaseDetails",null,options);

	}
	
	createPreviousPhase(){
		var self = this;
		var planModel = self.options.planModel;
		var id = self.htmlEscape(window.guidGenerator()) + "phase";
    	var options = {"createPreviousPhase" : self};
		const mixinClass = DataManager.getDataManager().getModelTypeByTypeStr(self.model.get('type'), self.model.getNestedParent().get('version'), true);
		window.getAndCreateModalDialog(planModel,id,mixinClass,null,"PhaseDetails",null,options);
    }

	waitForPlanLoading(phaseModel, callback) {
        var self = this;
        const plan = phaseModel.get('phaseOwner');
        var dataManager = DataManager.getDataManager();
        dataManager.set('currentPlan', plan);
        function waitForPlanLoad() {
            setTimeout(function () {
                if (window.loadedPackages[plan.id]) {
                    callback();
                } else {
                    waitForPlanLoad();
                }
            }, 100);
        }
        waitForPlanLoad();
    }
	
	deletePhase(){
		var self = this;
		var planDetails = self.options.addOptions ? self.options.addOptions.planDetails : null;
		function cleanModalWindow(){
			if(!self.options){
				return;
			}
			var planModel = self.options.planModel;
			if(planModel) {
				var planViews = window.vdmModelView.plan();
				var foundView = false;
				for(var i=0;i<planViews.length;i++){
					if(planViews[i].model.id === planModel.id){
						var phases = planViews[i].phase();
						for(var j=0;j<phases.length;j++){
							if(phases[j].model.id === self.model.id){
								$("#customPlanphaseblock"+window.utils.htmlEscape(self.model.id)).remove();
								window.cleanViewModel(phases[j]);
								foundView = true;
								break;
							}
						}
						if(foundView){
							break;
						}
					}
				}	
			}
			self.cleanModal && self.cleanModal();
			DataManager.getDataManager().set('currentPlan',null);
			window.utils.stopSpinner('phaseDelete');
		}
		function doDelete(){
			self.waitForPlanLoading(self.model ,function(){
				self.deletePhaseObject(self.model ,function(){
					setTimeout(function(){
						var dataManager = DataManager.getDataManager();
						dataManager.set('currentPlan',null);
						var serverChangeSet = dataManager.get(dataManager.CURRENT_CHANGESET);
						dataManager.saveChangeSetToBackend(serverChangeSet ,function(){
							dataManager.applyWorkspaceById(dataManager.get("currentWorkspaceId"),function(){
							//dataManager.acquireSaveLock(function(){
							//  dataManager.pullServerChanges(function(){
							//      dataManager.clearSaveInterval();
							//      dataManager.releaseSaveLock();
									//window.vdmModelView.addPlanToHomePage(self.model.getNestedParent(),true);
									window.vdmModelView.loadHomePagePlans();
									cleanModalWindow();
							//  },true);
							});
						});
					},100);
				})
			})
		}
		
		/*function removePhaseBlock(){
			var encodeId = self.encodeId + "phase";
			var phaseSlick  =  $("[encodeid='"+encodeId+"']");
			var planSlick = phaseSlick.closest('.slickWrapper');
			var index = phaseSlick.attr('data-slick-index');
			planSlick.slick('slickRemove', index);
		}*/
		if(self.model){
		    var plan = self.model.get('phaseOwner');var components;
			var planCriterionSet = plan.get('planCriterionSet');
			if(planCriterionSet){
			   components = plan.get('planCriterionSet').get('component').models;
			}
			if(self.checkPackageLoss() || (components && components.length > 0)){
				bootbox.confirm(DataManager.getDataManager().get('localeManager').get('deletePhase'), function(result) {
					if(result){
						if(self.parentView || planDetails){ //when clicked on Plan edit
							return self.options.callback(this,self.encodeId,true);
						}
						else{
							window.utils.startSpinner('phaseDelete','Deleting Phase...');
							self.enableButton(false);
							setTimeout(doDelete,10);
						}
					}
				});
			}
			else if(self.parentView || planDetails){ //when clicked on Plan edit
				return self.options.callback(this,self.encodeId,true);
			}else{
				window.utils.startSpinner('phaseDelete','Deleting Phase...');
				self.enableButton(false);
				setTimeout(doDelete,10);
			}
		}else{
			if(self.parentView || planDetails){ //when clicked on Plan edit
				return self.options.callback(this,self.encodeId,true);
			}
		}
	};

	deletePhaseObject = function(phaseModel,callback){
		phaseModel.deletePhase(function(){
			callback();
		});
	}

	afterRenderView(node,view){
		var self = view;
		var createText = DataManager.getDataManager().get('localeManager').get('CreateNewPhase');
		if(self.options.addOptions && (self.options.addOptions.createNextPhase || self.options.addOptions.createPreviousPhase)) {
			$("#titleLabel"+self.encodeId).text(createText);
		}
		else if(self.model || (self.options.addOptions && !self.options.addOptions.phaseArray)) {
        	$("#titleLabel"+self.encodeId).text(''+DataManager.getDataManager().get('localeManager').get('editPhase'));
        }
        else {
        	$("#titleLabel"+self.encodeId).text(createText);
        }
		var msecsInADay = 86400000;
        if(self.model && self.model.get('previousPhase')){
			var preEndDate = + self.model.get('previousPhase').get('endDate');
	        var endDate = new Date(preEndDate + msecsInADay);
	        $("#implementationDatepicker"+self.encodeId).datepicker();
	        $("#implementationDatepicker"+self.encodeId ).datepicker( "option", "minDate", endDate );
	        self.implementationDate(self.model.get('endDate')  ? DateFormat.getDateFromTimestamp(self.model.get('endDate')):'');
        }else if(!self.model){
        	if(self.options.parentView && self.options.parentView.get('phase') && self.options.parentView.get('phase').length>0){
				var preEndDate = + self.options.parentView.get('phase').at(self.options.parentView.get('phase').length-1).get('endDate');
        		endDate = new Date(preEndDate + msecsInADay);
        		$("#implementationDatepicker"+self.encodeId).datepicker();
	        	$("#implementationDatepicker"+self.encodeId ).datepicker( "option", "minDate", endDate );
				$("#startDatepicker"+self.encodeId).datepicker();
				$("#startDatepicker"+self.encodeId ).datepicker( "option", "minDate", endDate );
        	}else{
        		$("#implementationDatepicker"+self.encodeId).datepicker();
        	}
    	}
        else{
        	$("#implementationDatepicker"+self.encodeId).datepicker();
        	self.implementationDate(self.model.get('endDate')  ? DateFormat.getDateFromTimestamp(self.model.get('endDate')):'');
        }
		$("#startDatepicker"+self.encodeId).datepicker();
		$("#name"+self.encodeId).keyup(function (view) {
			if(view.currentTarget.value.trim()!==""){
				self.getUniqueValue(view.currentTarget.value);
			}
			else{
				self.enableComplete(false);
			}
	    });
	    self.setPrimarySelected();
	    $("#master").text(self.master ? self.master.get('name'):'');
	    window.utils.applyFontSizeInDialog();
	};
    createPhaseAlternative(){
    	var self = this;
    	var id = self.htmlEscape(window.guidGenerator()) + "alternative";
    	var planModel;
		var prevPhase;
     	if(self.options && self.options.parentView && self.options.parentView instanceof Backbone.RelationalModel){
			planModel =  self.options.parentView;
		}
		if(self.options.createNextPhase){
			prevPhase = self.options.createNextPhase.model;
		}else if(planModel && !prevPhase){
			var phases = planModel.get('phase');
			prevPhase = phases.findWhere({ nextPhase: null });
		}
    	var addOptions = {'createNewAlternative':true,'alternatives':self.phaseAlternative(),'parentModal':self.model,'planModel':planModel,'prevPhase':prevPhase};
    	window.getAndCreateModalDialog(true,id,AlternativeMixin,null,"AlternativeDetails",_.bind(self.updateView,self),addOptions);
    	if(!self.model){
    		while(self.alternativeArray.length>0){
    			var master= self.alternativeArray[0];
    			$("#master").text(master.name);
    			break;
    		}
    	}
    };	
	deleteAlternative(view,modalId){
		var self = this;
        var index = $("#selectPhaseAlternative"+ self.encodeId +" option:selected").attr("value");
        if(self.primaryId()===parseInt(index) && (self.phaseMasterId===parseInt(index) || self.phaseMasterId===view.id())){
        	bootbox.alert(DataManager.getDataManager().get('localeManager').get('deleteBaseAlternative'));
        }
        else if(self.primaryId()===parseInt(index)){
        	bootbox.alert(DataManager.getDataManager().get('localeManager').get('deletePrimaryAlternative'));
        }
        else if(self.phaseMasterId===view.id() || self.phaseMasterId===parseInt(index)){
        	bootbox.alert(DataManager.getDataManager().get('localeManager').get('deleteMasterAlternative'));
        }
        else{
        	for(var j=0;j<self.children.length;j++){
				if(self.children[j].modalId === modalId){
					self.children.splice(j,1);
					break;
				}
			}
    	    self.alternativeArray[index].del = true;
    	    self.checkAlternativesLength();
    		$("#selectPhaseAlternative"+ self.encodeId +" option[value="+index+"]").remove();		    	
    		$("#selectPhaseAlternativePrimary"+ self.encodeId +" option[value="+index+"]").remove();
    		window.cleanDialogModel(modalId,view);
        }
        self.setPrimarySelected();
	};
	showAlternativeCount(phase){
		var self = this;
		var totalCount =  phase.get('phaseAlternative').length;
		if($('#alternativesCount:checked').length>0){
			var phaseEncodeId = self.htmlEscape(phase.get('id'));
			var altEle = $("#"+phaseEncodeId+"noofAlternatives");
			altEle.html("  ("+totalCount+")");
		}
		return totalCount;
	};
	savePhaseDetails(){
		var self = this;
		var dataManager = DataManager.getDataManager();
		var planDetails = self.options.addOptions ? self.options.addOptions.planDetails : null;
		if(!self.options.createNextPhase && !self.options.createPreviousPhase && (self.parentView || planDetails)){
			if(self.name() !== null && self.name() !== "") {
				self.count++;
				planDetails && $("#modal"+self.encodeId).modal('hide');
				return self.options.callback(this,self.encodeId,false);
			}else {
				for(var i=0;i<self.children.length;i++){
	   				window.cleanDialogModel(self.children[i].modalId,self.children[i].view);
	   			}
				window.cleanDialogModel(self.encodeId,self);
			}
		}else{
			window.utils.startSpinner('phaseCreate','Saving Phase...');
			var planId;
			if(self.model){
				planId = self.model.getNestedParent().id;
			} else {
				var parentModel = self.parentView ? self.parentView : parentview;
				if(parentModel){
					planId = parentModel.id;
				}
			}
			var planWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
			var planWorkspaceName = dataManager.getWorkspaceName(planWorkspaceId);
            window.setTimeout(function () {
			    dataManager.saveAndSwitchWorkspace(planWorkspaceName, function(workspaceSwitch){
					var selectedPrimaryAlt = self.model?.get('primary');
				    self.saveData(null,function(phase){
					    function cleanModalWindow(){
						    var plan = dataManager.get('currentPlan');
						    //var refreshSearch = false;
						    if(self.options.createPreviousPhase){
						       plan.get('phase').sort();
						       window.cleanDialogModel(self.options.createPreviousPhase.encodeId,self.options.createPreviousPhase);
							    //refreshSearch = true;
						    }else if(self.options.createNextPhase){
							    plan.get('phase').sort();
							    window.cleanDialogModel(self.options.createNextPhase.encodeId,self.options.createNextPhase);
							    //refreshSearch = true;
						    }
							if(!window.vdmModelView.addPlanToHomePage && window.vdmModelView.showAccordion){
								window.vdmModelView.showAccordion(window.vdmModelView);
							} else {
								window.vdmModelView.addPlanToHomePage(plan,true);
								dataManager.set('currentPlan',null);
							}
						    window.cleanDialogModel(self.encodeId,self);
						
						    //if(refreshSearch){
							    //window.vdmModelView.initializeSearch(null);
						    //}else {
                                window.utils.stopSpinner('phaseCreate');
						    //}
					    }
					    if(self.children && self.children.length > 0) {
						    self.saveDirectMeasurementValues(self.children);
						    for(var i=0;i<self.children.length;i++){
							    window.cleanDialogModel(self.children[i].modalId,self.children[i].view);
						    }
						    dataManager.invokeValueUpdates(function(){
							    var serverChangeSet = dataManager.get(dataManager.CURRENT_CHANGESET);
							    dataManager.saveChangeSetToBackend(serverChangeSet,function(){
									dataManager.applyWorkspaceById(dataManager.get("currentWorkspaceId"),function(){
								    //dataManager.acquireSaveLock(function(){
									    //dataManager.pullServerChanges(function(){
										    //dataManager.clearSaveInterval();
										    //dataManager.releaseSaveLock();
										    cleanModalWindow();
									    //},true);
								    });
							    });
						    });
					    }else {
						    var serverChangeSet = dataManager.get(dataManager.CURRENT_CHANGESET);
						    dataManager.saveChangeSetToBackend(serverChangeSet,function(){
								dataManager.applyWorkspaceById(dataManager.get("currentWorkspaceId"),function(){
							    //dataManager.acquireSaveLock(function(){
								  //  dataManager.pullServerChanges(function(){
								//	    dataManager.clearSaveInterval();
								//	    dataManager.releaseSaveLock();
										if(self.model && self.options.customMode && selectedPrimaryAlt.get('id')!==self.model.get('primary').get('id')){
											var altId = self.model.get('primary').get('id')
											DataManager.getDataManager().get('router').navigate("/" + window.utils.getPlanNavigateUrl(self.model.getParent().id,self.model.getParent().get('documentVersion')) + '/'+ altId, { trigger: true });
										}
									    cleanModalWindow();
								 //   },true);
							    });
						    });
					    }
						
				    });
			    },planWorkspaceId);
            }, 500);
		}	
	};
	saveDirectMeasurementValues(children){
		var self = this;
		for(var i=0;i<children.length;i++){
			var alt = children[i].view.model;
			if(!alt) {
				var altFound = _.filter(self.alternativeArray,function(obj){return  obj.modalId === children[i].modalId;});
				if(altFound[0] && !altFound[0].del) {
					alt = altFound[0].originalId;
				}
			}
			
			var inputValuesView = children[i].view.inputValuesView;
			if(inputValuesView) {
				if(!(alt instanceof Backbone.RelationalModel)) {
					alt = DataManager.getDataManager().getAlternativeSync(alt);
				}
				if(alt instanceof Backbone.RelationalModel) {
					alt.saveDirectValues(children[i].view.inputValuesView.directValueChanges);
				}
				var inputValuesEncodeId = children[i].view.inputValuesView.encodeId;
				window.cleanDialogModel(inputValuesEncodeId,inputValuesView);
			}
		}
	};
	updateView(view, modalId,del) {
		var self = this;
		var count = 0;
		if(del === true){
			self.deleteAlternative(view,modalId);
		}
		else{
			for(var i=0;i<self.phaseAlternative().length;i++) {
				if(self.phaseAlternative()[i].modalId === modalId){
					count++;
					self.phaseAlternative()[i].name = view.name();
					self.phaseAlternative()[i].description = view.description();
					self.phaseAlternative()[i].purpose = view.purpose();
					self.phaseAlternative()[i].dependency = _.uniq(view.selectedPackage());
					$("#selectPhaseAlternative"+self.encodeId+" option[value="+self.phaseAlternative()[i].id+"]").text(view.name());
					if(self.phaseAlternative()[i].originalId && self.master && self.phaseAlternative()[i].originalId === self.master.id){
						$("#master").text(view.name());
					}
					break;
				}
			}
			var childcount = 0;
			for(var j=0;j<self.children.length;j++){
				if(self.children[j].modalId === view.encodeId){
					childcount = 1;
					self.children[j].view = view;
					self.children[j].oldData = window.getViewData(view);
				}
			}
			if(childcount === 0) {
				var oldData = window.getViewData(view);
				self.children.push({modalId:modalId,view:view,oldData:oldData});
			}
			if(self.phaseAlternative().length < 1 || count === 0) {
				self.phaseAlternative.push({id:self.phaseAlternative().length,originalId:'',modalId:modalId,name:view.name(),description:view.description(),purpose:view.purpose(),clonedFromAltId:view.clonedFromAltId,del:false});
				self.checkAlternativesLength();
			}
		}
	};
	editAlternative(){
		var self = this;
    	if($("#selectPhaseAlternative"+ self.encodeId +" option:selected").length > 0  ){
	         var index = $("#selectPhaseAlternative"+ self.encodeId +" option:selected").attr("value");
	         var id = self.phaseAlternative()[index].modalId;  
	         var  altModal = null;
	         if(self.phaseAlternative()[index].originalId != ''){
	         	altModal = self.phaseAlternatives.get(self.phaseAlternative()[index].originalId);
	         }
	         
	         if($.find("#modal"+id).length > 0){
	         	for(var i=0;i<self.children.length;i++){
					if(id === self.children[i].modalId){
	     				window.updateView(self.children[i].view,self.children[i].oldData);
	    				break;
					}
	    		}
	        	$("#modal"+id).modal('show');
	         }
	         else {
	         	var planModel;
	         	if(self.options && self.options.parentView && self.options.parentView instanceof Backbone.RelationalModel){
					planModel =  self.options.parentView;
				}
	         	var addOptions = {'createNewAlternative':false,'alternatives':self.phaseAlternative(),'planModel':planModel};
	        	window.getAndCreateModalDialog(true,id,AlternativeMixin,altModal,"AlternativeDetails",_.bind(self.updateView,self),addOptions);
	  		}
      	}
 	};
	getUniqueValue(val){
		var self = this;
		var parentView;
		if(self.options && self.options.parentView){
			parentView =  self.options.parentView;
		}
		else if(self.options && self.options.addOptions){
			parentView = self.options.addOptions.planModel;
		}
    	if(self.options && parentView) {
    		var unique = window.isUniqueName(val,null, self.options.modalId,parentView.get('phase'));
    		if(unique){
    			$('#message'+self.encodeId).text("");
    			 self.enableComplete(true);
    		}
    		else{
    			$('#message'+self.encodeId).text(DataManager.getDataManager().get('localeManager').get('PhaseDuplicate'));
    			self.enableComplete(false);
    		}
    	}
    	//If parentView not exist...(case: new plan & new Phase)
    	if(self.options && self.options.parentView === null && self.options.addOptions.phaseArray && !parentView && val!==""){
    		var phaseArray = self.options.addOptions.phaseArray;
    		var exist = false;
    		for(var i=0;i<phaseArray.length;i++){
    			if(phaseArray[i].name === val.trim()){
    				exist = true;
    				break;
    			}
    		}
    		if(exist){
    			$('#message'+self.encodeId).text(DataManager.getDataManager().get('localeManager').get('PhaseDuplicate'));
    			self.enableComplete(false);
    		}
    		else{
    			$('#message'+self.encodeId).text("");
        		self.enableComplete(true);
    		}
    	}
    }; 
    checkPrimaryChange(id,callback){
    	var self = this;
    	var packageLoss = false;
		var packageArray = [];
		var nextPhase = self.model.get('primary').get('phaseAlternativeOwner').get('nextPhase');
		if(nextPhase){
			var primaryDesignPart = self.model.get('primary').get('phaseDesignPart');
			//var newPrimary = self.model.get('phaseAlternative').get(id);
			for(var i=0;i<primaryDesignPart.length;i++){
				var beepRef = primaryDesignPart.at(i).get('beepReference');
				if(beepRef.indexOf("Common") == -1 && primaryDesignPart.at(i).get('beepType') !== 'report_BeepReport') {
					packageArray.push(primaryDesignPart.at(i));
				}
			}
			async.eachSeries(packageArray, function(pack, handleCallback){
				DataManager.getDataManager().getReferencesToModel2WithId(pack.get('beepReference'),"appbo/vdml/ValueDeliveryModel",pack.get('version'),pack.get('beepReference'),"appbo/vdml/ValueDeliveryModel",function(results){
				//DataManager.getDataManager().getReferencesToModel(pack.get('beepReference'), function(results){
					var packageDep = false;
					for(var i=0;i<results.length;i++){
						var resultId = results[i].id;
						var altId = resultId.substr(0,resultId.lastIndexOf('@') + 1);
						if(nextPhase.get('primary').get('id') === altId){
							packageDep = true;
							break;
						}
					}
					if(!packageDep){
						packageLoss = true;
					}
					handleCallback();
				});
			},function(err){
				packageArray.length = 0;
				callback(packageLoss);
			});
		}else {
			callback(packageLoss);
		}
    };

	getStartDate(){
		var self = this;
		return self.planDate();
	}
	getEndDate(){
		var self = this;
		return self.implementationDate();
	}
	saveData(parentview,callback){
		var self = this;
		self.model && DataManager.getDataManager().set('currentPlan',self.model.getNestedParent(),{silent:true});
		self.parentView = self.parentView ? self.parentView : parentview;
		var parentModel = self.parentView;
		if(parentModel && !(parentModel instanceof Backbone.RelationalModel)){
			parentModel = Backbone.Relational.store.getObjectByName('transformation.Plan').find({id:parentModel.id}); 
		}
		var phaseName = self.name().trim();
		var phaseDesc = self.description().trim();
		if(!phaseDesc){
			phaseDesc = phaseName;
		}	
		function statusFromSymbols(status){
			for(var i=0;i< self.statusOptions.length;i++){
				if(self.statusOptions[i].name===status){
					return self.statusOptions[i];       			
				}
			}
		}
		var phaseStatus = statusFromSymbols(self.phaseStatus());
		var purpose = self.purpose();
		// var planDate = Date.parse($("#startDatepicker"+self.encodeId).datepicker('getDate'));
		// var implementationDate = Date.parse($("#implementationDatepicker"+ self.encodeId).datepicker('getDate'));
		var planDate = Date.parse(self.getStartDate());
		var implementationDate = Date.parse(self.getEndDate());
		if(!self.model){
			Plan.createNewPhase(this.name().trim(),this.name().trim(),this.purpose(),this.phaseAlternative(),parentModel,function(phase){
				var phasePrimary = self.phaseAlternative()[self.primaryId()].originalId;
				phase.savePhaseDetails(phaseName,phaseDesc,phaseStatus,purpose,phasePrimary,planDate,implementationDate,self.phaseAlternative(),function(){
					self.checkIntermediatePhase(phase,function(){
						callback(phase);
					});
				});
			}); 	
		}
		else{
			self.model.savePhaseAlternatives(this.phaseAlternative(),true,function(phase){
				var phasePrimary = self.phaseAlternative()[self.primaryId()].originalId;
				phase.savePhaseDetails(phaseName,phaseDesc,phaseStatus,purpose,phasePrimary,planDate,implementationDate,self.phaseAlternative(),function(){
					callback(phase);
				});
			});
		}
 	};

	checkIntermediatePhase(phase,intermediateCallback){
		var self = this;
		var createIntermediatePhase = self.options.createNextPhase?self.options.createNextPhase.model:null;
		if(!createIntermediatePhase) {
			createIntermediatePhase = self.options.createPreviousPhase?self.options.createPreviousPhase.model:null;
		}
		if(createIntermediatePhase){
			var createPreviousPhase = self.enableIsolation()?false:true;
			phase.setPrevNextIntermediateLinks(createIntermediatePhase,createPreviousPhase,self.isolation(),function(){
				intermediateCallback(phase);
			});
		}else{
			intermediateCallback(phase);
		}
	}
 	fetchPrimaryFromAltArray(){
		var self = this;
		var altIndex = null;
		for(var i=0;i<self.alternativeArray.length;i++){
		    if(self.model.get('primary') && self.alternativeArray[i].originalId === self.model.get('primary').get('id')) {
				altIndex = self.alternativeArray[i].id;
				break;
			}
		}
		return altIndex;
	};
	checkAlternativesLength(){
		var self = this;
		var alternativecount=0;
    	for(var i=0;i<self.alternativeArray.length;i++){
    		if(self.alternativeArray[i].del===false){
    			alternativecount=alternativecount+1;
    		}
    	}
    	if(alternativecount>1){
    		self.editable(true);
    	}
    	else
    	{
    		self.editable(false);
    	}
    }

	filterPrimaryAltCandidate(altList) {
        let self = this;
        if(self.model.get('previousPhase') === null && self.model.get('nextPhase') === null){
            // if there is only one phase
            const newAltList = altList.filter(obj => obj.originalId && obj.originalId.trim() !== '');
            return newAltList;
        } else if (self.model.get('nextPhase')){
            return self.candidateIfNextPhase(altList);
        } else if (self.model.get('nextPhase') === null || self.model.get('nextPhase') === undefined){
            return self.candidateIfLastPhase(altList);
        }
    }

    candidateIfNextPhase(altList){
        // get candidates for primary alternative if there is next phase
        let self = this;
        const primAlt = self.model.get('primary');
        const nextPhase = self.model.get('nextPhase');
        const nextPhasePrimAlt = nextPhase.get('primary');
        let primAltCand = [];
        let clonFrom = nextPhasePrimAlt.get('clonedFrom');
        while(clonFrom){
            let clonedFromAlt = window.utils.getElementModel(clonFrom, ['transformation.Alternative']);
            const clonedFromPhaseId = clonedFromAlt?.get('phaseAlternativeOwner')?.id;
            if(clonedFromPhaseId === nextPhase.id) {
                clonFrom = clonedFromAlt.get('clonedFrom');
            } else if(clonedFromPhaseId === self.model.id) {
                const alt = altList.find((obj) => obj.originalId === clonFrom);
                primAltCand.push(alt);
                clonFrom = alt.clonedFrom ? alt.clonedFrom : null;
            } else {
                break;
            }
        }
        return primAltCand;
    }

    candidateIfLastPhase(altList){
        // get candidates for primary alternative if there is no any next phase
        let self = this;
        const primAlt = self.model.get('primary');
        const previousPhase = self.model.get('previousPhase');
		if (!previousPhase){
			const newAltList = altList.filter(obj => obj.originalId && obj.originalId.trim() !== '');
			return newAltList;
		} 
        const previousPhasePrimAlt = previousPhase.get('primary');
        let primAltCand = [];
        altList.forEach((alt)=>{
            let clonFrom = alt.clonedFrom;
            while(clonFrom){
                let clonedFromAlt = window.utils.getElementModel(clonFrom, ['transformation.Alternative']);
                const clonedFromPhaseId = clonedFromAlt?.get('phaseAlternativeOwner')?.id;
                if(clonFrom === primAlt.id){
                    primAltCand.push(alt);
                    break;
                } else if(clonedFromPhaseId === self.model.id) {
                    clonFrom = clonedFromAlt.get('clonedFrom');
                } else if(clonedFromPhaseId === previousPhase.id) {
                    if(clonFrom === previousPhasePrimAlt.id) {
                        primAltCand.push(alt);
                        break;
                    }
                    clonFrom = clonedFromAlt.get('clonedFrom');
                } else {
                    break;
                }
            }
        })
        return primAltCand;
    }

 	init(model, options, detailedView){
		var self = this;
		this.PhaseDetailsViewModel = this;
		this.model = model;
		model = null;
		this.options = options;
		options = null;
		this.id = ko.observable(self.model ? self.model.get('id') : null);
		//this.encodeId = htmlEscape(this.id());
		this.encodeId = ""+ self.options.modalId;
		this.showNextPhaseButton = ko.observable(self.model ? true : false);
		this.showPreviousPhaseButton = ko.observable(false);
		var firstPhase = self.model ? self.model.getNestedParent().get('phase').findWhere({ previousPhase: null }):null;
		if(self.model && firstPhase == self.model){
			this.showPreviousPhaseButton(true);
		}
		if(self.options && self.options.parentView){
			this.parentView = self.options.parentView;
			this.showNextPhaseButton(false);
			this.showPreviousPhaseButton(false);
		}
		this.showIsolation = ko.observable(false);
		this.isolation = ko.observable(false);
		this.enableIsolation = ko.observable(true);
		this.enableButton = ko.observable(true);
		this.editable = ko.observable(false);
		this.name = ko.observable(self.model ? self.model.get('name') : null);
		this.description = ko.observable(self.model ? self.model.get('description') : "");
		this.hideAltPlusIcon = ko.observable(false);
		var lang = navigator.language;
		var landIndex = lang.indexOf("-");
		var loc = landIndex != -1?lang.substr(0,lang.indexOf("-")):lang;
		this.currentDate = moment().format(moment().locale(loc).localeData()._longDateFormat['L']);	
		this.implementationDate = ko.observable();
		this.planDate = ko.observable(self.model && self.model.get('startDate') ? DateFormat.getDateFromTimestamp(self.model.get('startDate')) : self.currentDate);
		this.purpose = ko.observable(self.model ? self.model.get('change') : "");
		this.nextPhase = ko.observable(self.model ? self.model.get('nextPhase') : false);
		this.statusOptions = Status.symbols();
		this.enableComplete = ko.observable(false);
		this.phaseStat = self.model?self.model.get('status'):'';
		this.phaseStatus = ko.observable(self.phaseStat ? self.phaseStat.name : "");
		this.phaseAlternatives = self.model ? self.model.get('phaseAlternative') : null;
		this.primary = self.model ? self.model.get('primary') : null;
		this.master = self.model ? self.model.get('master') :null;
		this.showDelete = ko.observable(self.model ? true : false);
	    this.alternativeArray = [];
	   	this.children = [];
	   	this.phaseMasterId = self.model && self.model.get('master') ? self.model.get('master').id : '';
	   	this.count = 1;
        this.datePickerEnable = ko.observable(true);
		if(self.model !== null) {
			function addPhaseAlternativesToArray(){
				for(var i=0;i<self.phaseAlternatives.length;i++){
					var PhaseAlternateModel = self.model.get('phaseAlternative').models[i];
					var obj = {id:self.alternativeArray.length,originalId:PhaseAlternateModel.get("id"),modalId:self.htmlEscape(PhaseAlternateModel.get("id"))+"alternative", name:PhaseAlternateModel.get("name"),description:PhaseAlternateModel.get("description"),purpose:PhaseAlternateModel.get("purpose"),del:false, clonedFrom: PhaseAlternateModel.get("clonedFrom")};
					self.alternativeArray.push(obj);			
					if(self.primary && PhaseAlternateModel.get("id") === self.primary.get('id')){
						self.primaryId = ko.observable(obj.id);
					}
					if(self.master && PhaseAlternateModel.get("id") === self.master.get('id')){
						self.masterId = ko.observable(obj.id);		
					}				
				}
			}
			addPhaseAlternativesToArray();
		}
		this.phaseAlternative = ko.observableArray(this.alternativeArray);
		this.primaryAltCandidate = ko.observableArray(self.model ? self.filterPrimaryAltCandidate(this.phaseAlternative()) : this.phaseAlternative());
		self.phaseAlternative.subscribe((val)=>{
            if(self.model) self.primaryAltCandidate(self.filterPrimaryAltCandidate(val));
		})
        self.enableSlectPrimAlt = ko.observable((self.primaryAltCandidate() && self.primaryAltCandidate().length > 1) ? true : false)
        self.primaryAltCandidate.subscribe((val)=>{
            if(val.length >1) self.enableSlectPrimAlt(true);
            else self.enableSlectPrimAlt(false);
		})


		self.checkAlternativesLength();
		if(self.options.addOptions?.customMode){
			self.hideAltPlusIcon(true);
			self.showDelete(false);
			self.showNextPhaseButton(false);
			self.showPreviousPhaseButton(false);
		}
		if(self.name() && self.name!== null){
			self.enableComplete(true);
		}
		if(self.options.addOptions && self.options.addOptions.planDetails){	
			this.cleanModal = function(){
					if(this.count === 1) {
						for(var i=0;i<self.children.length;i++){
			   				window.cleanDialogModel(self.children[i].modalId,self.children[i].view);
			   			}
					   	window.cleanDialogModel(self.encodeId,self);
				   	}
				}
		}
		else {//when clicked on phase edit
			this.cleanModal = function(){
				for(var i=0;i<self.children.length;i++){
		   			window.cleanDialogModel(self.children[i].modalId,self.children[i].view);
		   		}
			   	window.cleanDialogModel(self.encodeId,self);
			}	
		}
		this.checkPackageLoss = function(){
			var alternativesColl = self.model.get('phaseAlternative');
			for(var i=0;i<alternativesColl.models.length;i++){
				var alt = alternativesColl.models[i];
				var packagesColl = alt.fetchNonCommonPackagesFromAlternative();
				if(packagesColl.length > 0){
					return true;
				}
			}
			return false;
		};
		this.primaryId = ko.observable(null);
		if(self.model){
			var altIndex = self.fetchPrimaryFromAltArray();	
			self.primaryId(altIndex);
		}else {
			if(self.options.createNextPhase){
				//self.name(self.options.createNextPhase.get('name')+' Copy');
				//self.description(self.options.createNextPhase.get('description')+' Copy');
				// self.showIsolation(true);
			}
			if(self.options.createPreviousPhase){
				self.hideAltPlusIcon(true);
				// self.showIsolation(true);
				self.enableIsolation(false);
				self.isolation(true);
			}
		}
		this.primaryId.subscribe(function(val){
			var id = val ? self.alternativeArray[val].originalId:null;
			if(self.model && id && self.model.get('primary') &&  self.model.get('primary').get('id') !== id){
				window.utils.startSpinner('primaryChange','Checking Depedencies...');
				setTimeout(function(){
					self.checkPrimaryChange(id,function(packageLoss){
						window.utils.stopSpinner('primaryChange');
						if(packageLoss){
							bootbox.confirm(DataManager.getDataManager().get('localeManager').get('alertPrimary'), function(result) {
								if(!result){
									var altIndex = self.fetchPrimaryFromAltArray();
									self.primaryId(altIndex);
									$('#selectPhaseAlternativePrimary'+self.encodeId).val(altIndex);
								}
							});
						}
					});
				},10);
			}
		});
		$('#modal'+self.encodeId).on('shown.bs.modal', function () {
			if(self.name() !== null && !self.options.addOptions?.customMode){
				self.showDelete(true);
            	$("#titleLabel"+self.encodeId).text(''+DataManager.getDataManager().get('localeManager').get('editPhase'));
            }
			else{
				self.showDelete(false);
			}
			window.utils.focusElement("#newModal .focus-ele");
		});
		this.masterId = ko.observable();
		this.name.subscribe(function(val){
			self.description(val);
			self.getUniqueValue(val);
		});
		if(!self.model){
			var clonedFromAltId = {};
			if(self.options.createNextPhase){
				clonedFromAltId.id = self.options.createNextPhase.model.get('primary').id;
			}
			if(self.options.createPreviousPhase ){
				clonedFromAltId.id = self.options.createPreviousPhase.model.get('primary').id;
			}
			clonedFromAltId.creatingPrevPhase = self.options.createPreviousPhase ? true : false;
	    	self.alternativeArray.push({id:0,originalId:"",modalId:self.htmlEscape(window.guidGenerator()) + "BaseAlternative",name:'Base Alternative',description:'Base Alternative',clonedFromAltId:clonedFromAltId,del:false});
	    	self.phaseMasterId = 0;
	    }
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['Phase'
		,'name'
		,'description'
		,'endDate'
		,'startDate'
		,'name'
		,'status'
		,'Initiated'
		,'InProgress'
		,'Closed'
		,'Close'
		,'Complete'
		,'primary'
		,'master'
		,'editPhase'
		,'Delete'
		,'phaseStatus'
		,'alternatives'
		,'Purpose'
		,'CreateNewPhase']);
		
		for(var i=0;i<this.statusOptions.length;i++){
			this.statusOptions[i].description = this.labels[this.statusOptions[i].name]();
		}

	};
	//path.PhaseDetailsViewModel = PhaseDetailsViewModel;
	static getInstance(model,options){
		var view = new PhaseDetailsViewModel(model,options);
		view.init(model, options);
		return view;
	};
}
path.PhaseDetailsViewModel = PhaseDetailsViewModel;