import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {CanvasDesignerViewModel} from './CanvasDesignerViewModel'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import { CanvasDetails2ViewModel } from './CanvasDetails2ViewModel'
import { BusinessModel2Mixin } from '../../../../bo/vdml/BusinessModel2Mixin'
//define(["require", "jquery", "underscore", "muuri", "backbone", "knockout", "bootbox", "appcommon/com/vbee/data/DataManager", "appbo/vdml/Annotation", "app/global", "appbo/vdml/BusinessModel", "appbo/vdml/Participant", "appbo/vdml/ValueElement", "appbo/canvas/BMCanvasItem", "knockback", "summernote", "appviews/canvas/views/designer/MappingWizardViewModel", "appviews/canvas/views/designer/CanvasDetailsViewModel", "appviews/help/views/properties/BMGuidanceViewModel", "appcommon/com/vbee/utils/DateFormat", "appbo/canvas/BMCanvasDiagramMixin", "appbo/vdml/CapabilityLibraryMixin", "appbo/vdml/ValueDeliveryModelMixin", "appbo/ecomap/EcoMapMixin", "domtoimage", "colorpicker"],
//    function (require, $, _, Muuri, Backbone, ko, bootbox, DataManager, Annotation, global, BusinessModel, Participant, ValueElement, BMCanvasItem, kb, summernote, MappingWizardViewModel, CanvasDetailsViewModel, BMGuidanceViewModel, DateFormat, BMCanvasDiagramMixin, CapabilityLibraryMixin, ValueDeliveryModelMixin, EcoMapMixin) {
var path = DataManager.getDataManager().buildAppNsPath("canvas.views.properties", global.version);
export class CanvasDesigner2ViewModel extends CanvasDesignerViewModel {

    init(model, options){
        super.init(model,options);
        var self = this;
        this.model = model;
        var dataManager = DataManager.getDataManager();
        this.selectedYear = ko.observable(null);
        this.selectedPeriod = ko.observable(null);
        var currentPlan = dataManager.get('currentPlan');
        this.periodKind = currentPlan.get("periodKind");
        this.currentAlternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: dataManager.get('viewAlternative')})
        var scenarioId = currentPlan.get("defaultScenario");
        var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
        this.dataSets = self.currentAlternative.getPhaseAlternativeSteps(scenario);
        var defaultPeriodList = scenario.fillDefaultPeriods(this.dataSets,self.periodKind);
        self.defaultPeriod = defaultPeriodList?.period
        self.defaultYear = defaultPeriodList?.year;
        var defaultExecutionScenaroId = scenario.get("defaultExecutionScenario");
        self.defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });

    }

    getValueAndUnitString(item, value, itemName) {
        var self = this;
        let smileySrc = '';
        let smileyColor = '';
        let newName = itemName;
        const valType = ['vdml_ValuePropositionComponent','vdml_ValueAdd','vdml_ValueElement'];
        if (item.get('canvasItem') && valType.some((str)=> str === item.get('canvasItem').get('type'))) {
            newName = value.getValueSlipName(self.currentAlternativeModel, newName, null, null);
            let smilData = self.getSmileysData(item.get('canvasItem'));
            if (smilData) {
                smileySrc = smilData.smileyValue;
                smileyColor = smilData.smileyColor;
            }
        }
        return {name: newName, smileySrc: smileySrc, smileyColor: smileyColor};
    };

    getSmileysData(valComp){
        var self = this;
        var satisfactionLevel = valComp.get('satisfactionLevel');
        if(satisfactionLevel){
            var calcSatisfaction = self.calcPeriodDataset ? self.calcPeriodDataset.get(satisfactionLevel.id) : "";
            var satisfactionData = satisfactionLevel.getSatisfactionIntervalData(calcSatisfaction,self.currentAlternativeModel.id);
        }
        return satisfactionData;
    }

    refreshTempValueCollections() {
        var self = this;
        for (var i = 1; i <= self.noOfDimensions; i++) {
            if(self.configObj.dimensions[i-1]?.mapping.some(str => str === 'value')){
                self.colls['dimension' + i + 'Collection'].removeAll();
                self.loadDimensionsData('dimension' + i);
            }
        }
        self.syncNameChanges();
        window.utils.applyFontSizeByElement($('.post-it-text'));
    };


    createVPC(vp, oldVpc, valueName) {
        var self = this;
        var dataManager = DataManager.getDataManager();
        var newVPCIdSuffix = oldVpc ? window.utils.getSuffix(oldVpc.id) : null;
        var vpc = vp.createValuePropositionComponent(valueName, valueName, false, newVPCIdSuffix);
        vpc.primeValueMeasurement(dataManager.get("currentYear"), dataManager.get("currentPeriod"), null, null, null, [], self.currentAlternative);
        //var vdm = vp.getNestedParent();
        //vdm.createValueDetails(vpc, oldVpc);
        return vpc;
    }
    createValueAdd(bm, activity, activityValueName, bmActivityModal, oldValModel) {
        var self = this;
        var dataManager = DataManager.getDataManager();
        var activityValue = activity.createWhatValues(dataManager.get("currentYear"), dataManager.get("currentPeriod"), self.currentAlternative, activityValueName, null, null, null, [], bmActivityModal, oldValModel);
        bmActivityModal.get('activityValue').add(activityValue);
        return activityValue;
    }

    // loadValueDetailsPage(slipId) {
    //     var self = this;
    //     var canvasItemObj = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: slipId });
    //     var valueDef = canvasItemObj.get('canvasItem')
    //     var addOptions = { 'type': 'planValues', planValue: true,'period':"12", 'year':"2024", 'currentAlternativeModel': self.currentAlternativeModel};
    //     addOptions.bm = self.model.get("businessModel");
    //     var planType = false;
    //     if (DataManager.getDataManager().getRepositoryId(valueDef.id) == window.plansKey) {
    //         planType = true;
    //     }
    //     self.getAggregatedColl(planType, self.model.get("businessModel"), function(aggregatedColl) {
    //         addOptions.aggregatedColl = aggregatedColl;
    //         window.getAndCreateModalDialog(null, window.utils.htmlEscape(valueDef.get('id')) + "_edit", BusinessModel2Mixin, valueDef, "ValueDetails", null, addOptions);
    //     });
    // }

    // getAggregatedColl(isPlan, bm, callback) {
    //     var self = this;
    //     if (isPlan) {
    //         var plan = DataManager.getDataManager().get('currentPlan');
    //         plan.getValuesThatCanBeRefferedTo(function(values) {
    //             callback(new Backbone.Collection(values));
    //         });
    //     } else {
    //         var aggregatedColl = new Backbone.Collection;
    //         var allValues = [];
    //         bm.getValues(allValues, true);
    //         aggregatedColl.add(allValues);
    //         bm.fillPlanValues(aggregatedColl);
    //         callback(aggregatedColl);
    //     }
    // };

    loadCanvasDetailComponent(){
        var self = this;
        self.detailsViewInstance = CanvasDetails2ViewModel.getInstance(this.model);
        if(!ko.components.isRegistered('CanvasDetails')){
            ko.components.register("CanvasDetails", {
                viewModel: {instance: self.detailsViewInstance},
                template: window.utils.getHtmlPage("CanvasDetails2Template"),
                synchronous: true
            });
        }
    }

    static getInstance(model,options){
        var view = new CanvasDesigner2ViewModel(model, options);
        view.init(model, options);
        return view;
    };
}
path.CanvasDesigner2ViewModel = CanvasDesigner2ViewModel;
