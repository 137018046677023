import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {VdmlElement} from './VdmlElement'
import {VdmlElementMixin} from './VdmlElementMixin'
import { VdmlElement2Mixin } from './VdmlElement2Mixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import { PolynomialCoefficient } from './PolynomialCoefficient'
import { SeasonalFactor } from './SeasonalFactor'

var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);

export class ProjectionProfileMixin {

    defaults(){
        var ret = {
            type: "vdml_ProjectionProfile"
        }
        return jQuery.extend(VdmlElement.prototype.defaults.apply(this),ret);
    }
    static getMixinRelations(){
        return _.union([
            {
                type :Backbone.HasMany,
                containingClass:"vdml_PolynomialCoefficient",
                key:"coefficients",
                relatedModel:"vdml.PolynomialCoefficient",
                reverseRelation: {
                    key: "coefficientsOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },
            {
                type :Backbone.HasMany,
                containingClass:"vdml_SeasonalFactor",
                key:"seasonalFactors",
                relatedModel:"vdml.SeasonalFactor",
                reverseRelation: {
                    key: "seasonalFactorsOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueElement",
				key:"inputValues",
				relatedModel:"vdml.ValueElement",
				reverseRelation :{
                    key:"projectionProfile",
                    type:Backbone.HasOne,
                    includeInJSON:"id"
                }
			},
            {
                type :Backbone.HasMany,
				containingClass:"vdml_ValueDefinition",
				key:"inputValueDefinitions",
				relatedModel:"vdml.ValueDefinition",
				reverseRelation :{
                    key:"libraryProjectionProfile",
                    type:Backbone.HasOne,
                    includeInJSON:"id"
                }
            }

        ])
    }
    
    static getCumulativeMixinRelations(){
        if (!ProjectionProfileMixin.cummulativeRelations) {
            ProjectionProfileMixin.cummulativeRelations = _.union(ProjectionProfileMixin.getMixinRelations()
                , DataManager.getDataManager().getMixinClass("VdmlElement","vdml").getCumulativeMixinRelations()
            );
        }
        return ProjectionProfileMixin.cummulativeRelations.slice();
    }
    static getSuperTypes(){
        return [
            "vdml_VdmlElement"
        ];
    }
    static getProperties(){
        return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            {name : "trendModel",type : "TrendModel",defaultValue : "null",containingClass : "vdml_ProjectionProfile" },
            {name : "rateFactor",type : "double",defaultValue : "null",containingClass : "vdml_ProjectionProfile" },
            {name : "polynomialDegree",type : "int",defaultValue : "null",containingClass : "vdml_ProjectionProfile" },
            {name : "periodKind",type :"EString",defaultValue:"null",containingClass : "vdml_ProjectionProfile"}
        ]
    }
    getParent(){
        var container;
        if(!container){
            container = this.get("projectionProfileOwner") ? this.get("projectionProfileOwner") : this.previousAttributes().projectionProfileOwner;
            if(container){
                return container;
            }
        }
        return this;
    }
    getPackagePath(path){
        if(!path){
            path = [];
        }
        var container;
        if(!container){
            container = this.get("projectionProfileOwner") ? this.get("projectionProfileOwner") : this.previousAttributes().projectionProfileOwner;
            if(container){
                path.unshift({id:this.get('id'),name:this.get('name')})
                return container.getPackagePath(path);
            }
        }
        return path;
    }
    getViewProperties(type){
        return {
            templatePath : "views/vdml/views/properties/ProjectionProfilePropertiesTemplate.html",
            templateName : "ProjectionProfilePropertiesTemplate",
            viewTypeStr : "appviews/vdml/views/properties/ProjectionProfileViewModel",
            tabId : "ProjectionProfileView",
            tabName: "ProjectionProfile"
        }
    }
    updateProjectionProfile(projectionProfileObj){
		var self = this;
        if(self.get('periodKind') !== projectionProfileObj.periodKind){
            self.set('periodKind',projectionProfileObj.periodKind)
        }
        if(self.get('trendModel')!==projectionProfileObj.trendModel){
            self.set('trendModel',projectionProfileObj.trendModel);
        }
        if(projectionProfileObj.rateFactor && projectionProfileObj.rateFactor !== self.get('rateFactor')){
            self.set('rateFactor',projectionProfileObj.rateFactor);
            if(self.get('polynomialDegree')!==null){
                self.set('polynomialDegree',null);
            }
        }

        if(projectionProfileObj.polynomialDegree){
            self.set('polynomialDegree',parseInt(projectionProfileObj.polynomialDegree));
            if(self.get('rateFactor')!==null){
                self.set('rateFactor',null);
            }
        }
        if(projectionProfileObj.polynomialData){
            if(self.get('coefficients').length>0){
                self.get('coefficients').reset();
            }
            for(var j = 0; j<projectionProfileObj.polynomialData.coefficients.length;j++){
                var coefficient = projectionProfileObj.polynomialData.coefficients[j];
                let polynomialCoefficient = PolynomialCoefficient.getInstance(j+1,coefficient,self);
                self.get('coefficients').add(polynomialCoefficient);
            }
        }else{
            self.get('coefficients').reset();
        }
        if(projectionProfileObj.seasonalFactorData.length){
            if(self.get('seasonalFactors').length > 0){
                self.get('seasonalFactors').reset();
            }
			for(var i = 0; i<projectionProfileObj.seasonalFactorData.length;i++){
				var quantity = projectionProfileObj.seasonalFactorData[i].factor;
				let seasonalFactors = SeasonalFactor.getInstance(i+1,quantity,self);
				self.get('seasonalFactors').add(seasonalFactors);
			}
        }else{
            self.get('seasonalFactors').reset();
        }
	}

}
path.ProjectionProfileMixin = ProjectionProfileMixin;
