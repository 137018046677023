import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../libs/bootbox/bootbox'
import { DataManager } from '../../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("valueLibrary.properties", global.version);
export class ProjectionProfileJsonViewModel {
    constructor(model, options) {
        var self = this;
        this.init(model, options);
    }

    init(model, options) {
        var self = this;
        this.ProjectionProfileJsonViewModel = this;
        this.model = model;
        this.jsTreeModelData = null;
        this.enableComplete = ko.observable(true);
        this.showComplete = ko.observable(true);
        this.showProjectionProfile = ko.observable(false);
        this.title = ko.observable("");
        this.phasesColl = ko.observableArray([]);
        if (options && options.parentView) {
            this.encodeId = "" + options.modalId;
            this.callback = options.callback;
            this.valLibList = options.valueLibs.slice();
        }
        if (options && options.targetDetailsMode) {
            self.showComplete(false);
        }
        this.options = options;
        function htmlEscape(str) {
            return String(str)
                .replace(/@/g, '')
                .replace(/ /g, '');
        }
        if (self.valLibList) {
            for (var i = 0; i < self.valLibList.length; i++) {
                var valueLib = self.valLibList[i].models;
                if (valueLib.length > 0) {
                    self.showProjectionProfile(true);
                }
            }
        
        }
        this.options = options;
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
            'Complete'
            , 'Close', 'ProjectionProfile', 'ProjectionProfile(s)'
            , 'Phase(s)/Value Definition(s)']);
        this.title(self.labels['ProjectionProfile(s)']());
    }
    afterRenderView(event, view) {
        var self = view;
        self.jsTreeModelData = self.getProjectionDataInJSTreeFormat(self.valLibList);
        var arr = [];
        for (var i = 0; i < self.jsTreeModelData.length; i++) {
            arr.push(self.jsTreeModelData[i]);
        }
        var valueCheck = self.options.hideCheck ? false : true;
        var tree = $('#jstree_profiles').on("select_node.jstree", function (e, data) {
            if (data.selected.length) {
                var htmlContent = "<span id='showValMapped' title="+DataManager.getDataManager().get('localeManager').get('ProjectionProfile')+"class='glyphicon glyphicon-info-sign glyphicon-button' style='font-size: small;color:orange;margin-left:7px'></span>"
                var selectedNode = data.instance.get_node(data.selected[0]);
                var parentsLength = selectedNode.parents.length;
                if (parentsLength > 1 && selectedNode && selectedNode.original.type === "vdml_ProjectionProfile") {
                    //if (parentsLength > 2) {
                        if ((self.options.type && self.options.type === selectedNode.original.type || self.options.type === 'vdml_Value') || (!self.options.type)) {
                            var exists = _.filter(self.options.parentCaps, function (parentCap) { if (parentCap.id === selectedNode.original.id) { return parentCap } });
                            var mapExists = _.filter(self.options.mappedCapabilities, function (parentCap) { if (parentCap === selectedNode.original.id) { return parentCap } });
                            if (exists.length > 0 || mapExists.length > 0) {
                                self.enableComplete(false);
                                bootbox.alert(selectedNode.text + " is not allowed for mapping");
                            } else {
                                var exists = _.filter(self.options.childCaps, function (childCap) { if (childCap.id === selectedNode.original.id) { return childCap } });
                                if (exists.length > 0) {
                                    self.enableComplete(false);
                                    bootbox.alert(selectedNode.text + " is not allowed for mapping");
                                } else {
                                    self.enableComplete(true);
                                    self.selectedValId = selectedNode.id;
                                    
                                    self.selectedValName = selectedNode.text;
                                    //self.valParentId = selectedNode.parents[parentsLength - 3];
                                    self.valDescription = selectedNode.original.description;
                                    if (!$('#showValMapped').length) {
                                        if ($('[id^=\'' + self.selectedValId + "_" + '\']').length == 1) {
                                            $('[id^=\'' + self.selectedValId + "_" + '\']').append(htmlContent);
                                        }
                                    } else {
                                        $('#showValMapped').remove();
                                        if ($('[id^=\'' + self.selectedValId + "_" + '\']').length == 1) {
                                            $('[id^=\'' + self.selectedValId + "_" + '\']').append(htmlContent);
                                        }
                                    }
                                    $('#showValMapped').click(function () { 
                                        var currentAltId = DataManager.getDataManager().get('viewAlternative');
                                        var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
                                        var currentAlt = docTypeObj.find({ id: currentAltId });
                                        var valModel = window.utils.getElementModel(self.selectedValId,['vdml.ValueCategory','vdml.ValueDefinition']);
                                        if(!valModel){
                                            bootbox.alert("No such Element");
                                            return;
                                        }
                                        var scenario = currentAlt.getMainScenario(valModel.getNestedParent());
                                        var id = window.utils.htmlEscape(window.guidGenerator());
                                        var options = {};
                                        options.context = scenario;
                                        options.mappedModal = valModel;
                                        options.width = '800px';
                                        window.getAndCreateModalDialog(self.model, id, EcoMapDiagramMixin, self.model, "TargetDetails", function (refresh) {
                                        }, options);
                                    })
                                }
                            }
                        } else {
                            self.enableComplete(false);
                            bootbox.alert(selectedNode.text + " is not allowed for mapping");
                        }
                    /*} else {
                        self.enableComplete(false);
                    }*/
                }
                else {
                    self.enableComplete(false);
                }
            }
        }).jstree({
            'core': {
                'check_callback': true,
                'two_state': valueCheck,
                'data': arr
            },
            "plugins": valueCheck ? ['search', 'checkbox'] : ['search'],
            "search": {
                'case_sensitive': false
            }
        });
        $('#treeSearch').change(function () {
            window.utils.startSpinner('jsonSearch', 'Searching...');
            setTimeout(function () {
                var v = $('#treeSearch').val();
                $('#jstree_profiles').jstree(true).search(v);
                window.utils.applyFontSize($('#content'));
                window.utils.stopSpinner('jsonSearch');
                $('.jstree-anchor').css('text-overflow', 'ellipsis');
                $('.jstree-anchor').css('overflow', 'hidden');
                $('.jstree-anchor').css('max-width', '97%');
            }, 10);
        });
        $('#jstree_profiles').on('dblclick.jstree', function (e) {
            var text = e.target.text;
            if (text && text.length > 0) {
                var textSub = text.substr(text.indexOf(":") + 2);
                navigator.clipboard.writeText(textSub);
            }
        });
        $('#jstree_profiles').on('loaded.jstree', function (e) {
            if(self.model && self.model.id){
                $("#jstree_profiles").jstree('open_node', self.jsTreeModelData);
            }
        });
        $('#jstree_profiles').bind('ready.jstree', function(e, data) {
            var strategyMapObjectiveViewModel = window.vdmModelView.strategyMapObjectiveViewModel;
            var selectedValueLibList = strategyMapObjectiveViewModel?strategyMapObjectiveViewModel.selectedValueLibList:null;
            if ((self.options.showValueElementId) || selectedValueLibList && selectedValueLibList.length > 0) {
                //for (var i = 0; i < options.parentView.selectedValueLibList.length; i++) {
                if (self.options.showValueElementId) {
                    $('#jstree_profiles').jstree('select_node', self.options.showValueElementId);
                } else {
                    $("#jstree_profiles").jstree("disable_node", selectedValueLibList);
                    $('#jstree_profiles').jstree('select_node', selectedValueLibList);
                }
                self.enableComplete(false)
                // }
            }
        });
        $('#jstree_profiles').on('hover_node.jstree', function (e, data) {
            var toolTip;
            if (data.node.original.type && data.node.original.type == "vdml_ValueLibrary") {
                toolTip = DataManager.getDataManager().get('localeManager').get('ValueLibrary');
            } else if (data.node.original.type && data.node.original.type == "vdml_ProjectionProfile") {
                toolTip = DataManager.getDataManager().get('localeManager').get('ProjectionProfile');
            }
            data.instance.element.prop('title', toolTip);
            if (!data.node.original.type) {
                data.instance.element.removeAttr('title', toolTip)
            }
        });
    }
    getProjectionDataInJSTreeFormat(valueLibs) {
        var self = this;
        var ret = [];
        for (var i = 0; i < valueLibs.length; i++) {
            var jsonData = {};
            var capLibModels = valueLibs[i].models ? valueLibs[i].models : valueLibs[i];
            if ((!capLibModels.length && capLibModels instanceof Backbone.Model) || (capLibModels.length && capLibModels.length > 0)) {
                ret.push(jsonData);
            }
            for (var j = 0; j < capLibModels.length; j++) {
                var capabilityLib = capLibModels[j];
                // if (capabilityLib.get('type') == "vdml_ValueLibrary" && capabilityLib !== self.model) {
                //     continue;
                // }
                jsonData.id = capabilityLib.get('id');
                jsonData.text = capabilityLib.get('name');
                jsonData.description = capabilityLib.get('description');
                jsonData.type = capabilityLib.get('type');
                jsonData.definition = capabilityLib.get('definition');
                jsonData.children = [];
                var caplist = capabilityLib.get('projectionProfiles').models;
                var newcaplist = [];
                for (var k = 0; k < caplist.length; k++) {
                    newcaplist.push(caplist[k]);
                }
                jsonData.children = self.getProjectionDataInJSTreeFormat(newcaplist);
                ret[j] ? ret[j].icon = "img/object.png" : '';
            }
            if (!capLibModels.length && capLibModels instanceof Backbone.Model) {
                //for children
                jsonData.id = capLibModels.get('id');
                jsonData.rateFactor = capLibModels.get('rateFactor');
                jsonData.trendModel = capLibModels.get('trendModel');
                jsonData.periodKind = capLibModels.get('periodKind');
                jsonData.polynomialDegree = capLibModels.get('polynomialDegree');
                jsonData.seasonalFactors = capLibModels.get('seasonalFactors');
                jsonData.coefficients = capLibModels.get('coefficients');
                jsonData.type = capLibModels.get('type');
                jsonData.text = capLibModels.get('name');
                ret[i].icon = "img/icons/trendIcon.png";
            }
        }
        ret.sort(self.sortByName);
        return ret;
    };
    sortByName(left, right) {
        var self = this;
        if (left.text && right.text) {
            return left.text.toLowerCase() === right.text.toLowerCase() ? 0 : (left.text.toLowerCase() < right.text.toLowerCase() ? -1 : 1);
        }
    }
    projectionComplete() {
        var self = this;
        self.selectedValuesList = [];
        var dataSelected = $('#jstree_profiles').jstree(true).get_selected();
        for (var i = 0; i < dataSelected.length; i++) {
            var nodeInfo = $('#jstree_profiles').jstree(true).get_node(dataSelected[i]);
            if (nodeInfo && !nodeInfo.state.disabled) {
                self.selectedValuesList.push(dataSelected[i])
            }
        }
        self.callback(self.selectedValuesList, self.phasesColl());
        self.cleanModal();

    };
    cleanModal() {
        var self = this;
        window.cleanDialogModel(self.encodeId, self);
        if ($('#wizardModal').length > 0) {
            $('#wizardModal').removeClass('push-back');
        }
        if ($('.slipDetailsBox').length == 0 || self.options.infoIcon) {
            $('.slipDetailsBox').removeClass('push-back');
        }
        if (event.currentTarget.textContent == 'Close' && $('.slipDetailsBox').length > 0) {
            $('.slipDetailsBox').removeClass('push-back');
        }
    };
    static getInstance(model, options) {
        var view = new ProjectionProfileJsonViewModel(model, options);
        return view;
    };
    
}
path.ProjectionProfileJsonViewModel = ProjectionProfileJsonViewModel;