import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'

import {ValueElement} from './ValueElement'
import {ValuePropositionComponentMixin} from './ValuePropositionComponentMixin'
import {ValuePropositionComponent2Mixin} from './ValuePropositionComponent2Mixin'
import {MeasuredCharacteristic} from './MeasuredCharacteristic'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/ValueElement","appbo/vdml/ValuePropositionComponentMixin"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,ValueElement,ValuePropositionComponentMixin

, MeasuredCharacteristic){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
		else{
			import('./MeasuredCharacteristic').then(loadedModule => {
				MeasuredCharacteristic = loadedModule;
			});
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? ValuePropositionComponent2Mixin : ValuePropositionComponentMixin;
	}
	var vpcMixinClass = getMixinClass();
	var ValuePropositionComponent = ValueElement.extend(utils.customExtends({
		relations:vpcMixinClass.getMixinRelations(),
		initialize: function(attributes, options) {
			ValueElement.prototype.initialize.apply(this, arguments);
		}
		}
		, new vpcMixinClass()
	));
	ValuePropositionComponent["change:name"] = function (model,newName) {
		var previousName = model._previousAttributes ? model._previousAttributes.name:"";
		if(previousName != newName) {
			//DataManager.getDataManager().addToModalChange();
			this.changeValFormula(model,previousName);
		}
    }
	/*ValuePropositionComponent["destroy"] = function (model,newName) {
		DataManager.getDataManager().addToModalChange();
    }*/
	
	ValuePropositionComponent.abstract = false;
	path.ValuePropositionComponent = ValuePropositionComponent;
	export {ValuePropositionComponent};
//});