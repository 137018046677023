import * as $ from 'jquery';
import * as _ from 'underscore'
import * as ko from 'knockout';
import * as kb from 'knockback';
import { DataManager } from '../../../../../com/vbee/data/DataManager';

var path = DataManager.getDataManager().buildAppNsPath("strategyMap.views.designer",global.version);

export class TargetValueViewModel {
    afterRenderView(node,view) {
		var self = view;
		var objExtended = Backbone.Typeahead.extend({
            template: '<input type="text" id="selectedValue' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        self.valueTypeahead = new objExtended({
            collection: self.valuesColl
        });
        var unitExtended = Backbone.Typeahead.extend({
            template: '<input type="text" id="unit' + self.encodeId + '" placeholder="' + self.labels['Unit']() + '" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        self.unitTypeahead = new unitExtended({
            collection: self.unitsColl
        });
        $('#showTargetElement').hide();
        self.valueTypeahead.setElement('#valueName' + self.encodeId).render();
        self.unitTypeahead.setElement('#unitName' + self.encodeId).render();
        $('#selectedValue' + self.encodeId).attr('autocomplete','new-password');
        $('#unit' + self.encodeId).attr('autocomplete','new-password');
        self.unitTypeahead.on('selected', function(selectedValue) {
            if (self.matchField) {
                self.matchField.hide();
            }
            self.showComplete(true);
            $('#unit' + self.encodeId).val(selectedValue.get('name'));
        });
        $('#unit' + self.encodeId).keyup(function (view) {
                self.matchField = $(view.currentTarget).closest('.row').find('.match');
                if (view.target.value && !view.target.value.trim() == "") {
                    var unitMatch = self.unitsColl.findWhere({ name: view.target.value });
                    if (unitMatch) {
                        self.matchField[0].innerHTML = 'Match';
                        self.matchField.show();
                        $('#unit' + self.encodeId).next().find('li').filter(function () { return $(this).text() === view.target.value; }).click();
                    }
                    else if (!view.target.value == "") {
                        self.matchField[0].innerHTML = 'New';
                        self.matchField.show();
                    }
                }
                else {
                    self.matchField.hide();
                }
            });
            $('#unit' + self.encodeId).dblclick(function (view) {
                $('#unit' + self.encodeId).trigger('keyup');
                $('#unit' + self.encodeId).focus();
            });
        self.valueTypeahead.on('selected', function(selectedValue) {
            if (self.matchField) {
                self.matchField.hide();
            }
            self.selectedValue = selectedValue;
            self.showComplete(true);
            //var model = self.getValueTarget(selectedValue.id);
            var model = window.utils.getElementModel(selectedValue.id,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
            $('#selectedValue' + self.encodeId).attr('valueId', selectedValue.id)
            if (model) {
                self.showTargetElemetId = model.id;
                $('#showTargetElement').show();
                $('#showTargetInfo').css('visibility', 'visible');
                 $('#showSelectForm').text(model.get('name')); 
                if(model.get('valueDefinition')){
                    self.showValueElementId = model.get('valueDefinition').id;
                    $('#valueElementName').text(model.get('valueDefinition').get('name'));
                    $('#showValueDefForObj').hide();
                }
				var unit = model.get('unit');
				var unitName = unit ? unit.get('name') :'';
                $('#unit' + self.encodeId).val(unitName);
                $('#unit' + self.encodeId).prop('disabled', true);
            }
        });
        $("#selectedValue" + self.encodeId).keyup(function(view) {
            var currentValue = view.currentTarget.value.trim().toLowerCase();
            var objExist = false;
            self.matchField = $(view.currentTarget).closest('.row').find('.match');
            if (view.currentTarget.value.trim() !== "" && !objExist) {
                self.matchField.show();
                self.showComplete(true);
            } else if (!objExist) {
                self.matchField.hide();
                self.showComplete(false);
            }
            var matchFound = _.filter(self.valuesColl.models, function(obj) {
                return obj.get('name').toLowerCase() === currentValue;
            });
            if (matchFound && matchFound.length && !objExist) {
                self.selectedValue = matchFound[0];
                self.matchField[0].innerHTML = "Match";
            } else if (!objExist) {
                self.selectedValue = null;
                self.matchField[0].innerHTML = "New";
                $('#showTargetElement').hide();
                $('#unit' + self.encodeId).prop('disabled', false);
                self.showTargetElemetId = null;
            }
        });
        $('#selectedValue' + self.encodeId).change(function(view) {
            if (self.matchField) {
                self.matchField.hide();
                $('#showTargetElement').hide();
            }
        });
        $('#selectedValue' + self.encodeId).dblclick(function(view) {
            $('#selectedValue' + self.encodeId).trigger('keyup');
            $('#selectedValue' + self.encodeId).focus();
        });
	};

	saveTargetValue(){
		var self = this;
		//TODO
        var qty = "-";
        var id = self.selectedValue;
        if(!id){
            id = $('#selectedValue' + self.encodeId).val().trim();
            var unit = $('#unit' + self.encodeId).val().trim();
            id = id + ' ('+unit+')';
        }
        var pd = self.parentView.periodSets();
        if(pd[0]){
            var obj = {year:pd[0].year,period:pd[0].periodNumber,id:id,qty:qty};
            self.parentView.saveQuantity(obj);
        }
	}

    fillMeasurementUnits() {
        var self = this;
        var currentPlan = DataManager.getDataManager().get('currentPlan');
        var valueLibraries = currentPlan.get("valueLibrary").models;
        for(var i = 0;i< valueLibraries.length;i++){
          var library = valueLibraries[i];
          var units = library.get("unit").models;
          for(var j=0; j< units.length;j++){
              var unit = units[j];
              if (!self.unitsColl.findWhere({ 'name': unit.get('name') })) {
                  self.unitsColl.push({ id: unit.get('id'), name: unit.get('name')});
              }
          }
        }
    }

    init(model, options) {
		var self = this;
		this.TargetValueViewModel = this;
		this.model = model;
		this.parentView = options.parentView
		this.encodeId = options.modalId;
		this.options = options;
		this.valuesColl = new Backbone.Collection();
		this.unitsColl = new Backbone.Collection();
        this.showComplete = ko.observable(false);
        self.fillMeasurementUnits();
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
			'name',
			'Unit',
			'Close',
			'Complete'
		]);
		//this.iframeSrc = ko.observable('');
		
	}

    cleanModal(){
		var self=this;
		window.cleanDialogModel(self.encodeId,self);
	};  

    static getInstance(model, options) {
        const view = new TargetValueViewModel(model, options);
		view.init(model, options);
        return view;
    }
}
path.TargetValueViewModel = TargetValueViewModel;