import * as _ from 'underscore'
import * as ko from 'knockout'
import { ScenarioType } from "../vdml/ScenarioType";
import { ValueElement } from '../vdml/ValueElement';
import { PeriodDataset } from "./PeriodDataset";
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ScenarioMeasurement } from "./ScenarioMeasurement";
import { BeepPackage } from '../beeppackage/BeepPackage';
import { BeepPackageMixin } from '../beeppackage/BeepPackageMixin';
import { ScenarioPathStep } from './ScenarioPathStep';
import { ValueLibrary } from '../vdml/ValueLibrary'
import {Unit} from '../vdml/Unit'
import { PeriodKind } from '../vdml/PeriodKind';
import { ValueType } from '../vdml/ValueType'
import { Expression } from '../vdml/Expression';
import { InstantiationContext } from '../instdef/InstantiationContext';
import * as bootbox from '../../../../libs/bootbox/bootbox'
import * as async from 'async'

export class PlanScenarioMixin{
    constructor(){
        var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
        path.PlanScenarioMixin = PlanScenarioMixin;
    }
    getMixinClass(){
        return PlanScenarioMixin;
    }
    /*constructor(id,planId,startPeriod){
        var path = DataManager.getDataManager().buildAppNsPath("dashboard",global.version);
        path.PlanScenarioMixin = PlanScenarioMixin;
        this.id = id;
        this.planId = planId;
        this.startPeriod = startPeriod;
        this.scenarioPathSteps = {};
        this.firstStep = null;
    }*/
    defaults(){
		var ret = {
			type: "transformation_PlanScenario",
			version: DataManager.getDataManager().get('currentVDMVersion')
		}
		return jQuery.extend(BeepPackage.prototype.defaults.apply(this),ret);
	}
    static getMixinRelations(){
		return _.union([
            {
                type: Backbone.HasMany,
                containingClass: "transformation_PlanScenario",
                key: "step",
                relatedModel: "transformation.ScenarioPathStep",
                reverseRelation: {
                    key: "stepOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },
            {
                type: Backbone.HasOne,
                containingClass: "transformation_PlanScenario",
                key: "firstStep",
                relatedModel: "transformation.ScenarioPathStep",
                includeInJSON: "id"
            },
            {
                type: Backbone.HasOne,
                containingClass: "transformation_PlanScenario",
                key: "optimizationObjective",
                relatedModel: "vdml.Expression",
                reverseRelation: {
                    key: "optimizationObjectiveOwner",
                    type: Backbone.HasOne,
                    includeInJSON: "id"
                }
            },
            {
                type: Backbone.HasMany,
                containingClass: "transformation_PlanScenario",
                key: "executionContext",
                relatedModel: "instdef.InstantiationContext"
            }
        ]);
	};
	static getCumulativeMixinRelations(){
		if (!PlanScenarioMixin.cummulativeRelations) {
            PlanScenarioMixin.cummulativeRelations = _.union(PlanScenarioMixin.getMixinRelations()
                , BeepPackageMixin.getCumulativeMixinRelations()
            );
        }
		return PlanScenarioMixin.cummulativeRelations.slice();
	};
	static getSuperTypes(){
		return [
			"beeppackage_BeepPackage"
		];
	}
	static getProperties(){
		return [
			{name : "version",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "label",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "parentLabel",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "syncedChangeLog",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "synchedChangeSet",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
            {name : "planId",type : "EString",defaultValue : "null",containingClass : "transformation_PlanScenario" },
            {name : "startTime",type : "long",defaultValue : "null",containingClass : "transformation_PlanScenario" },
            {name : "defaultExecutionScenario",type : "EString",defaultValue : "null",containingClass : "transformation_PlanScenario" }
		]
	}

    static getDialogViewProperties(type) { 
        if (type === "SummaryDetails") {
            return {
                templatePath: "views/scenario/views/properties/CalculationSummaryTemplate.html",
                templateName: "CalculationSummaryTemplate",
                viewTypeStr: "appviews/scenario/views/properties/CalculationSummaryViewModel",
                tabId: "CalculationSummaryView",
                tabName: "CalculationSummary"
            }
        } else if (type === "Optimize") {
            return {
                templatePath: "views/scenario/views/properties/OptimizationTemplate.html",
                templateName: "OptimizationTemplate",
                viewTypeStr: "appviews/scenario/views/properties/OptimizationViewModel",
                tabId: "OptimizationView",
                tabName: "Optimization"
            }
        }

        
    }

    createOptimizeExpression(valFormula,config){
		var self = this;
        var expId = DataManager.getDataManager().guidGeneratorByOwner(self);
        if(config){
			config = JSON.stringify(config);
		}
        var exp = new Expression({id:expId,optimizationObjectiveOwner:self,expressionStr: valFormula,expressionConfig:config});
        self.set("optimizationObjective",exp);
        return exp;
    }

	getParent(){
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return BeepPackage.prototype.getPackagePath.apply(this, arguments);
	}
    showCalcInfo(ele){
        if($(ele).find('#dynamicTooltip').length > 0){
            return;
        }
        var dataManager = DataManager.getDataManager();
        var calcResults = dataManager.get('calcInfo');
        var modelChange = calcResults ? calcResults.modelChange :null;
        var scenarioExecutionChange = calcResults ? calcResults.scenarioExecutionChange :null;
        var scChanges = calcResults ? calcResults.scenarioChanges :0;
        if (modelChange) {
            var lastChange = modelChange.lastChange ? new Date(modelChange.lastChange).toLocaleDateString() + " "+ new Date(modelChange.lastChange).toLocaleTimeString() :'';
            const infoLines = [
                "Last change: "+lastChange,
                "Model changes: "+(modelChange.noOfChanges),
                "Scenario changes: "+(scChanges.noOfChanges ? scChanges.noOfChanges : 0),
                "Scenario Execution changes: "+(scenarioExecutionChange.noOfChanges),
            ];
            if(scenarioExecutionChange.lastCalculated){
                var lastCalculated = scenarioExecutionChange.lastCalculated ? new Date(scenarioExecutionChange.lastCalculated).toLocaleDateString() + " "+ new Date(modelChange.lastCalculated).toLocaleTimeString() :'';
                infoLines.push("Last calculated:"+lastCalculated);
            }
            const tooltip = $('<div></div>').text(infoLines.join('\n'))
            .css({
                width :'max-content',
                position: 'absolute',
                color: 'black',
                backgroundColor: '#fff',
                border: '1px solid #ccc',
                borderRadius: '5px',
                padding: '5px',
                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                fontSize: '14px',
                zIndex: 1000,
                whiteSpace: 'pre-line',
                top: '30px', // Adjust position relative to the parent
                left: '0px'  // Adjust horizontal alignment
            }).attr('id', 'dynamicTooltip');
            $(ele).append(tooltip);
        }
        setTimeout(function(){$('#dynamicTooltip').remove();},4000);
    }
    getDatasetAsync(year,period,periodKind,scId,callback){
        var self = this;
        if(!year) {
            callback();
            return;
        }
        if(!period) {
            period = 1;//year periodkind
        }
        var scenarioExecId = scId ? scId : self.get("defaultExecutionScenario");
        var execScenario = window.utils.getElementModel(scenarioExecId,['transformation.ScenarioExecution']);
        var inputDataset = execScenario ? execScenario.get("input").findWhere({period: period, periodKind : periodKind, year:year}) : null;
        var calcDataset = execScenario ? execScenario.get("result").findWhere({period: period, periodKind : periodKind, year:year}) : null;
        if(!inputDataset){
            debugger
            DataManager.getDataManager().getPeriodDatasetByScenarioExecution(DataManager.getDataManager().get("currentPlan").id,scenarioExecId,year,period,function(input,result){
                callback(input,result);
            });
        } else {
            callback(inputDataset,calcDataset);
        }
    }

    extrapolateBaseline(baseLineVals,currentAltModel,callback){
        var self = this;
        if(baseLineVals.length == 0){
            callback();
            return;
        }
        var currentPlan = currentAltModel.getNestedParent();
        var url = "/vdmbee/intrapolation/baselinevalues";
        var defaultExecutionScenario = window.utils.getElementModel(self.get("defaultExecutionScenario"),['transformation.ScenarioExecution']);
        // var valId = baseLineVals[0].id;
        // var params =  baseLineVals[0].param;
        let params =  baseLineVals; 
        DataManager.getDataManager().get('vmpServerService').postAllPromise(url, JSON.stringify(params)).then(function(res) {
            //for(let a = 0 ; a < res.length ; a++){
                var periodDatasets = currentAltModel.getPhaseAlternativeSteps(self);
                for (var key in res) {
                //var forecast = res[a] ? res[a].forecast : null;
                var forecast;
                if (res.hasOwnProperty(key)) {
                    forecast = res[key];
                }
                if(forecast){
                    
                    for(let i = 0; i < periodDatasets.length; i++){
                        var periodDataset = defaultExecutionScenario.get("input").findWhere({period: periodDatasets[i].period,year: periodDatasets[i].year,periodKind : currentPlan.get("periodKind")});
                        if(periodDataset){
                            const arrayString  = forecast.split('[')[1].split(']')[0];
                            const forecastArray = arrayString.split(',').map(Number);
                            periodDataset.set(key,forecastArray[i+1]);//to skip 1st
                        }					
                    }
                }
            }
            callback(forecast);		
        }).catch(error => {
            bootbox.alert("failed "+ error);
            console.error('An error occurred:', error);
            callback();	
        });
    }
    transformedData(dataSets, currentView) {
        if(!currentView) {
            let transformedDataSets = {inputs: {},results: {}};
            dataSets.forEach(item => {
                const { year, period } = item;
                if (!transformedDataSets.inputs[year]) {
                    transformedDataSets.inputs[year] = [];
                }
                transformedDataSets.inputs[year].push(period);
            });
            return transformedDataSets;
        } else {
            const result = {};
            const { year, period } = dataSets;
            if (!result[year]) {
                result[year] = [];
            }
            result[year].push(period);
            return result;
        }
    }      
    handleCalculationResponse(response, scenario, plan, scenarioExecId, transformedDataSets,currentView,callback) {
        if (!response) {
            bootbox.alert("Calculation Failed");
            callback();
            return;
        }
        var dataManager = DataManager.getDataManager();
        dataManager.saveCalcSummary(plan.id,response.summary);
        var dataSetInputs = !currentView ? transformedDataSets.inputs : transformedDataSets;
        dataManager.getPeriodDatasetsByScenarioExecution(plan.id, scenarioExecId, dataSetInputs, function(){
            callback();
        }, true);
    }  
    updateViewAfterCalculation(plan) {
        var dataManager = DataManager.getDataManager();
        if(plan){
            var currentView = utils.getCurrentView();
            if (currentView && currentView.refreshWidgetData && plan == dataManager.get('currentPlan')) {
                currentView.refreshWidgetData();
            }
        }
        $("#calcSpinner").hide();
        $("#calcIcon").show();
        $("#totalCount").show();
        $("#calcDisplayContainer").removeAttr("disabled");
        window.calcProgress = false;
        dataManager.fetchCalcData(true,true);
    }    
    calcDisplay(triggerEvent) {
        var self = this;
        var dataManager = DataManager.getDataManager();
        var scenario = dataManager.get('defaultScenario');
        var scenarioExecId = scenario.get("defaultExecutionScenario");
        var plan = dataManager.get('currentPlan');
        var packArtDoc = dataManager.get("artifactsDocuments")[plan.id];
        var planArtId = packArtDoc ? packArtDoc.artifactId : plan.id;
        $("#totalCount").hide();
        $("#calcIcon").hide();
        $("#calcSpinner").show();
        $("#calcDisplayContainer").attr("disabled", true);
        window.calcProgress = true;
        var planCalc = window.vdmModelView.Values2ViewModel ? true : false;
        if(triggerEvent == "modalChange" || triggerEvent == "scenarioChange"){
            planCalc = true;
        } else {
            var transformedDataSets;
            var aggValPresent = false;
            if(!planCalc) {
                var currentAlternativeId = dataManager.get('viewAlternative');
                var currentAlternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: currentAlternativeId});
                var dataSets = currentAlternative.getPhaseAlternativeSteps(scenario);
                transformedDataSets = self.transformedData(dataSets, planCalc);
                if(currentAlternative.get("valueElementContext").length > 0){
                    aggValPresent = true;
                }
            } else {
                var dataSets = scenario.getPlanDatasets(plan);
                transformedDataSets = dataSets[0] ? self.transformedData(dataSets[0], planCalc) : [];
                var ph = plan.get('phase');
                for(var i=0; i < ph.length; i++){
                    var alts = ph.at(i).get('phaseAlternative');
                    for(var j=0; j < alts.length; j++){
                        if(alts.at(j).get("valueElementContext").length > 0){
                            aggValPresent = true;
                            break;
                        }
                    }
                    if(aggValPresent){
                        break;
                    }
                }
            }
            if(transformedDataSets.length === 0 || !aggValPresent) {
                var msg = aggValPresent ? "Currently there are no period datasets." : "There are no aggregated Values in your plan.";
                bootbox.alert(msg);
                self.updateViewAfterCalculation();
                return;
            }
        }
        
        try {
            var serverChangeSet = dataManager.get(dataManager.CURRENT_CHANGESET);
            dataManager.saveChangeSetToBackend(serverChangeSet,function(){
                dataManager.applyWorkspaceById(dataManager.get("currentWorkspaceId"), function () {
                    dataManager.fetchCalcData(true,true,function(calcInfo){
                        function triggerDatasetCalc(){
                            if(!planCalc) {
                                dataManager.calculatePlanModelByPeriodDataSets(scenario, scenarioExecId, plan, false, transformedDataSets, function (response) {
                                    self.handleCalculationResponse(response, scenario, plan, scenarioExecId, transformedDataSets, planCalc, function(){
                                        self.updateViewAfterCalculation(plan);
                                    })
                                })
                            } else {
                                if(triggerEvent == "modalChange"){
									const modelChangeCalUri = "/vdmbee/calculation/calculateallmodelscenarios?planArtifactId="+planArtId;
									dataManager.get('vmpServerService').postAllPromise(modelChangeCalUri, JSON.stringify({})).then(function(response) {
										self.updateViewAfterCalculation();
									});
                                } else if(triggerEvent == "scenarioChange"){
									const scChangeCalUri = "/vdmbee/calculation/calculateallscenarioexecutions?planArtifactId="+planArtId+"&scenarioId="+scenario.id;
									dataManager.get('vmpServerService').postAllPromise(scChangeCalUri, JSON.stringify({})).then(function(response) {
                                        self.updateViewAfterCalculation();
                                    });
                                } else {
                                    dataManager.calculatePlanModel(scenario, scenarioExecId, false, false, false, function (response) {
                                        self.handleCalculationResponse(response, scenario, plan, scenarioExecId, transformedDataSets, planCalc, function(){
                                            self.updateViewAfterCalculation(plan);
                                        })
                                    });
                                }
                            }
                        }
						if(dataManager.triggerBuild(calcInfo,plan.id)){
                            dataManager.buidlJuliaModel(function(){
                                setTimeout(function () {
                                    triggerDatasetCalc();
                                }, 5000);
                            },plan,scenario.id);
                        } else {
                            triggerDatasetCalc();
                        }   
                    });
                });
            });
        } catch (e) {
            $("#calcSpinner").hide();
            $("#calcIcon").show();
            $("#totalCount").show();
            $("#calcDisplayContainer").removeAttr("disabled");
        }
    }    
    extrapolateTimeSeries(timeSeriesVals,currentAltModel,callback){
        var self = this;
        if(timeSeriesVals.length == 0){
            callback();
            return;
        }
        var currentPlan = currentAltModel.getNestedParent();
        var url = "/vdmbee/intrapolation/timeSeriesValues";
        var defaultExecutionScenario = window.utils.getElementModel(self.get("defaultExecutionScenario"),['transformation.ScenarioExecution']);
        debugger
        // var valId = timeSeriesVals[0].id;
        // var params =  timeSeriesVals[0].param;
        let params =  timeSeriesVals; 
        DataManager.getDataManager().get('vmpServerService').postAllPromise(url, JSON.stringify(params)).then(function(res) {
            // var forecast = res ? res.forecast : null;
            // if(forecast){
            //     var periodDatasets = currentAltModel.getPhaseAlternativeSteps(self);
            //     for(let i = 0; i < periodDatasets.length; i++){
            //         var periodDataset = defaultExecutionScenario.get("input").findWhere({period: periodDatasets[i].period,year: periodDatasets[i].year,periodKind : currentPlan.get("periodKind")});
            //         if(periodDataset){
            //             periodDataset.set(valId,forecast[i+1]);
            //         }					
            //     }
            // }
            var periodDatasets = currentAltModel.getPhaseAlternativeSteps(self);
                for (var key in res) {
                //var forecast = res[a] ? res[a].forecast : null;
                var forecast;
                if (res.hasOwnProperty(key)) {
                    forecast = res[key];
                }
                if(forecast){
                    
                    for(let i = 0; i < periodDatasets.length; i++){
                        var periodDataset = defaultExecutionScenario.get("input").findWhere({period: periodDatasets[i].period,year: periodDatasets[i].year,periodKind : currentPlan.get("periodKind")});
                        if(periodDataset){
                            const arrayString  = forecast.split('[')[1].split(']')[0];
                            const forecastArray = arrayString.split(',').map(Number);
                            periodDataset.set(key,forecastArray[i]);//to skip 1st
                        }					
                    }
                }
            }
            callback(forecast);			
        }).catch(error => {
            bootbox.alert("failed "+ error);
            console.error('An error occurred:', error);
            callback();	
        });
    }

    createMeasurement(value,viewAlternative, year, period, valFormula,unitToMatch,baseTuples){
		var self = this;
        var defaultExecutionScenaroId = self.get("defaultExecutionScenario");
        var defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
        let plan = Backbone.Relational.store.getObjectByName("transformation.Plan").find({ id: self.get("planId") });
        var defaultValLib = plan.get("defaultValueLibrary");
        //TODO load if not loaded
        // var step = this.get("step").findWhere({alternativeId: viewAlternative.id});
        // var periodDataset = null;
        // if(step){
        //     if(period == undefined){
        //         period = step.get("startPeriod");
        //     }
        //     let yearPeriod = utils.calculateYearPeriod(self.get("startTime") , period, plan.get("periodKind") );
        //     if(!periodDataset){
        //         var id = window.guidGenerator();
        //         periodDataset = new PeriodDataset({id:id,year:yearPeriod.year,periodKind:plan.get("periodKind"),period: yearPeriod.period});
        //         defaultExecutionScenaro.get("input").add(periodDataset);
        //     }
        // }
        if(unitToMatch && unitToMatch.unitName!= ""){
            var unit = defaultValLib.get('unit').findWhere({name:unitToMatch.unitName});
            if(!unit){
                unit = Unit.getInstance(unitToMatch.unitName,unitToMatch.unitName,unitToMatch.roundingDigits,unitToMatch.significantDecimals,defaultValLib);
                value.set("unit",unit);
            }else if(unit){
                value.set("unit",unit);
            }
        }
        
        var expFormula = valFormula ? valFormula.valueExpression : '';
        if((expFormula && expFormula.length > 0) || (baseTuples && baseTuples.length > 0)){
            var contexts = value.get("context").models;
            var context = null;
            for(var i=0;i<contexts.length;i++){
                if(contexts[i].get("alternative").id == viewAlternative.id){
                    context = contexts[i];
                    break;
                }
            }
            if(!context){
                context = value.createValueElementContext(viewAlternative);
                var expressionConfig = valFormula.expressionConfig;
                var offset = valFormula.offset;
                var exp = value.createExpression(context, expFormula, expressionConfig, offset);
                
                //context.addBaseTuple(baseTuples,viewAlternative);
                //baseTuples
            }
        }
        // if(!periodDataset.get("data")){
        //     periodDataset.set("data" , {});
        // }
        //periodDataset.data.put(value.id,)
        var measurement;
        if(value.get("valueType") == ValueType.Atomic && year){
            var periodDataset = defaultExecutionScenaro.get("input").findWhere({period: period, periodKind : plan.get("periodKind"), year:year});
            measurement = new ScenarioMeasurement(value,periodDataset);
        }
        
		return measurement;
	};

    getTSLessMeasurementReferringToMeasuredCharteristic(measuredCharacteristic){
        if(!measuredCharacteristic){
			return null;
		}
        var objType = Backbone.Relational.store.getObjectByName("transformation.ScenarioProxy");
        var scenarioProxy = objType._collection.findWhere({scenario:this.id});
        var alt = scenarioProxy.get("designScenarioOwner");
        var currentPlan = DataManager.getDataManager().get("currentPlan");
        //currentPlan.getPlanScenarioFromBackend(timeStamp ? timeStamp : currentPlan.get("defaultScenario"));
        //assume the timestamp scenario is already cached
        var planScenarios = DataManager.getDataManager().get("planScenarios");
        var planScenario = planScenarios.get(timeStamp ? timeStamp : currentPlan.get("defaultScenario"));
        var step = planScenario.get("step").findWhere({alternativeId: alt.id});
        var startPeriod = step.startPeriod;
        var dataset = planScenario.getDefaultExecutionScenarioInput(alt,startPeriod);
        if(dataset){
            //return dataset.get("data")[measuredCharacteristic.id];
            return dataset.get(measuredCharacteristic.id);
        }else{
            return null;
        }	 
	}
    getObservedMeasureWithMeasurements(measuredCharacteristic,withTimeStamp,timeStamp){
		var observationToCheck;
		if(!measuredCharacteristic){
			return null;
		}
        var objType = Backbone.Relational.store.getObjectByName("transformation.ScenarioProxy");
        var scenarioProxy = objType._collection.findWhere({scenario:this.id});
        var alt = scenarioProxy.get("designScenarioOwner");
        var currentPlan = DataManager.getDataManager().get("currentPlan");
        //currentPlan.getPlanScenarioFromBackend(timeStamp ? timeStamp : currentPlan.get("defaultScenario"));
        //assume the timestamp scenario is already cached
        var planScenarios = DataManager.getDataManager().get("planScenarios");
        var planScenario = planScenarios.get(timeStamp ? timeStamp : currentPlan.get("defaultScenario"));
        var step = planScenario.get("step").findWhere({alternativeId: alt.id});
        var startPeriod = step.startPeriod;
        var dataset = planScenario.getDefaultExecutionScenarioInput(alt,startPeriod);
        if(dataset){
            //return dataset.get("data")[measuredCharacteristic.id];
            return dataset.get(measuredCharacteristic.id);
        }else{
            return null;
        }
	}

    getDefaultExecutionScenarioInputByPeriod(period){
        var self = this;
        var defaultExecutionScenaroId = self.get("defaultExecutionScenario");
        var defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
        //TODO load if not loaded
        var periodDataset = null;
        if(period == undefined){
            return null;
        }
        let plan = Backbone.Relational.store.getObjectByName("transformation.Plan").find({ id: self.get("planId") });
        let yearPeriod = utils.calculateYearPeriod(self.get("startTime") , period,plan.get("periodKind") );
        var periodDataset = defaultExecutionScenaro.get("input").findWhere({period: yearPeriod.period,periodKind : plan.get("periodKind"), year: yearPeriod.year});
        
        if(!periodDataset){
            var id = window.guidGenerator();
            periodDataset = new PeriodDataset({id:id,year:yearPeriod.year,periodKind:plan.get("periodKind"),period: yearPeriod.period, inputOwner: defaultExecutionScenaro });
            defaultExecutionScenaro.get("input").add(periodDataset);
        }
        return periodDataset;
    }

    getDefaultExecutionScenarioInput(alternative,period){
        var self = this;
        var defaultExecutionScenaroId = self.get("defaultExecutionScenario");
        var defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
        //TODO load if not loaded
        var step = self.get("step").findWhere({alternativeId: alternative.id});
        var periodDataset = null;
        if(step){
            if(period == undefined){
                period = step.get("startPeriod");
            }
            let plan = Backbone.Relational.store.getObjectByName("transformation.Plan").find({ id: self.get("planId") });
            let yearPeriod = utils.calculateYearPeriod(self.get("startTime") , period,plan.get("periodKind") );
            var periodDataset = defaultExecutionScenaro.get("input").findWhere({period: yearPeriod.period,periodKind : plan.get("periodKind"),year:yearPeriod.year});
            
            if(!periodDataset){
                var id = window.guidGenerator();
                periodDataset = new PeriodDataset({id:id,year:yearPeriod.year,periodKind:plan.get("periodKind"),period: yearPeriod.period, inputOwner: defaultExecutionScenaro});
                defaultExecutionScenaro.get("input").add(periodDataset);
            }
        }
        return periodDataset;
    }

    getPlanDatasets(plan){
		var scenario = this;
		var datasetsArr = []
		var periodKind = plan.get("periodKind");
		var phases = plan.get("phase");
		for (var j = 0; j < phases.length; j++) {
			var alternatives = phases.at(j).get('phaseAlternative');
			for (var i = 0; i < alternatives.length; i++) {
                var step = scenario.get("step").findWhere({alternativeId: alternatives.at(i).id});
                if(!step){
                    continue;
                }
                var periodsLen = step.get('noPeriods');
                var period = step.get("startPeriod");			
                for(var l = 0; l < periodsLen; l++){
                    if(l != 0){
                        period++;
                    }
                    let yearPeriod = utils.calculateYearPeriod(scenario.get("startTime") , period, periodKind );
                    var alternativeId = alternatives.at(i).id;
                    datasetsArr.push({...yearPeriod,alternativeId});
                }
				
			}
		}
		return datasetsArr;
	}
    
    datasetsByPhase(phaseID,periodKind){
		var dataManager = DataManager.getDataManager();
		var phaseDataSets=[]
		var scenarioId = dataManager.get("currentPlan").get("defaultScenario");
		var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
		var step = scenario.get("step").findWhere({phaseId: phaseID});
		var periodsLen = step.get('noPeriods');
		var period = step.get("startPeriod");			
		for(var l = 0; l < periodsLen; l++){
			if(l != 0){
				period++;
			}
			let yearPeriod = utils.calculateYearPeriod(scenario.get("startTime") , period, periodKind );
			phaseDataSets.push(yearPeriod);
		}
		return phaseDataSets
	}

    getCurrentWeek() {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 4 - (currentDate.getDay() || 7));
        const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
        const dayOfYear = ((currentDate - startOfYear) / 86400000) + 1;
        const weekNumber = Math.ceil(dayOfYear / 7);
        return weekNumber;
    }

    getCurrentQuarter() {
        const currentDate = new Date();
    
        const month = currentDate.getMonth();
    
        const quarter = Math.floor(month / 3) + 1;
    
        return quarter;
    }

    getCurrentFourWeeklyPeriod() {
        const currentDate = new Date();
    
        currentDate.setDate(currentDate.getDate() + 4 - (currentDate.getDay() || 7));
    
        const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    
        const dayOfYear = Math.floor((currentDate - startOfYear) / 86400000) + 1;
    
        const weekNumber = Math.ceil(dayOfYear / 7);
    
        const fourWeeklyPeriod = Math.ceil(weekNumber / 4);
    
        return fourWeeklyPeriod;
    }

    getdefaultExecutionData(periodsets,scenarioExeId,nonExecScenarioPeriodDataset,execScenarioPeriodDataset){
        var self = this;
        var execScenario = window.utils.getElementModel(scenarioExeId,['transformation.ScenarioExecution']);
        var currentPlan = DataManager.getDataManager().get("currentPlan");
        periodsets.map((d,i)=>{
             var inputDataset = execScenario ? execScenario.get("input").findWhere({period: d.periodNumber, year:d.year, periodKind : currentPlan.get("periodKind")}) : null;
             var calcDataset = execScenario ? execScenario.get("result").findWhere({period: d.periodNumber, year:d.year, periodKind : currentPlan.get("periodKind")}) : null;
            if(inputDataset){
                execScenarioPeriodDataset.input.push(inputDataset);
            }
            if(calcDataset){
                execScenarioPeriodDataset.result.push(calcDataset)
            }

            if(!inputDataset){
                if (!nonExecScenarioPeriodDataset[d.year]) {
                    nonExecScenarioPeriodDataset[d.year] = [];
                  }
                  nonExecScenarioPeriodDataset[d.year].push(d.periodNumber);
            }

        });
    }

    fillDefaultPeriods(phaseDataSets,periodKind){
        var self =  this;
	
		var datasets = {}
		
        if(phaseDataSets.length>0){
            var currentDate = new Date()
            var currentYear = currentDate.getFullYear();
		var currentPeriodKind 
        if(PeriodKind.symbols()[2].name ===periodKind){
            currentPeriodKind = currentDate.getMonth()+1;
        }else if(PeriodKind.symbols()[4].name ===periodKind){
            currentPeriodKind = self.getCurrentWeek()
        }else if (PeriodKind.symbols()[1].name ===periodKind){
            currentPeriodKind = self.getCurrentQuarter()
        }else if(PeriodKind.symbols()[3].name ===periodKind){
            currentPeriodKind = self.getCurrentFourWeeklyPeriod()
        }
            const highestYear = isNaN(Math.max(...phaseDataSets.map(obj => obj.year))) ? phaseDataSets[0].year : Math.max(...phaseDataSets.map(obj => obj.year));
            const LowestYear = isNaN(Math.min(...phaseDataSets.map(obj => obj.year))) ? phaseDatasets[0].year : Math.min(...phaseDataSets.map(obj => obj.year));
            const pastYearDatasets = phaseDataSets.filter(d=>d.year<currentYear);
            const futureYearDatasets =  phaseDataSets.filter(d=>d.year>currentYear);
            const highestPeriod = Math.max(...pastYearDatasets.map(obj => obj.period));
            const lowestPeriod = Math.min(...futureYearDatasets.map(obj => obj.period));
            if(highestYear < currentYear){
                datasets.period = highestPeriod
                datasets.year = highestYear
            } else if(highestYear > currentYear && LowestYear > currentYear ){
                datasets.period = lowestPeriod
                datasets.year = highestYear
            }
            else {
                const currentYearDataSets = phaseDataSets.filter(d=>d.year===currentYear);
                datasets.year = currentYear
                var nearestMinMonth = currentYearDataSets.every(d=>d.period<currentPeriodKind);
                var nearestMaxMonth = currentYearDataSets.every(d=>d.period>currentPeriodKind);
                if(nearestMinMonth){
                    const currentYearHighestPeriod = Math.max(...currentYearDataSets.map(obj => obj.period));
                    datasets.period = currentYearHighestPeriod
                }else if(nearestMaxMonth){
                    const currentYearLowestPeriod =  Math.min(...currentYearDataSets.map(obj => obj.period));
                    datasets.period = currentYearLowestPeriod;
                }else {
                    datasets.period = currentPeriodKind
                }
            }
        }else {
            datasets.period = null;
            datasets.year = null
        }
	
		return datasets
	}


    getSortedDataSets(plan){
        //this function will return year and period of datasets in sorted order
		var self = this;
		var datasetsArr = []
		var periodKind = plan.get("periodKind");
        var totalStepLength = self.get("step").length;
        for(var i=0; i < totalStepLength; i++){
            var step = self.get("step").at(i);//.findWhere({startPeriod: i+1});
            if(!step){
                return datasetsArr;
            }
            var periodsLen = step.get('noPeriods');
            var period = step.get("startPeriod");			
            for(var l = 0; l < periodsLen; l++){
                if(l != 0){
                    period++;
                }
                let yearPeriod = utils.calculateYearPeriod(self.get("startTime") , period, periodKind );
                datasetsArr.push({...yearPeriod});
            }
        }
        
		return datasetsArr;
	}


    getValuesContexts(){
        let self = this;
        let step = self.get("firstStep");
        let alternativeContexts = {};
        let seq = 0;
        while(step!= null){
            let alternativeId = step.get("alternativeId");
            let altData = {};
            let alternative = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: alternativeId });
            //altData.valueElementContext = alternative.get("valueElementContext");
            altData.valueElementContext = alternative ? alternative.get("alternativeValues"): [];
            altData.id = alternativeId;
            altData.startPeriod = step.get("startPeriod");
            altData.noPeriods = step.get("noPeriods");
            altData.constraints = step.get("constraints");
            alternativeContexts[seq] = altData;
            step = step.get("next");
            seq++;
        }
        return alternativeContexts;
    }
    getCalculationChangeType(operationType,change){
        if(operationType == "update" ){
            if(change.startTime || change.optimizationType || change.calculationTimeFence || change.step || change.optimizationObjective || change.firstStep){
                return 1;
            }
        }
    }
    getCalculationChangeArtifact(operationType){
        return DataManager.getDataManager().get("artifactsDocuments")[this.id].artifactId;
    }
}
