import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import * as d3 from '../../../../../../libs/d3/d3.min'
import { BusinessModel2Mixin } from '../../../../bo/vdml/BusinessModel2Mixin'

//var DimensionalMeasurement = Backbone.Relational.store.getObjectByName('smm.DimensionalMeasurement');
//define(["require", "jquery", "underscore", "d3v5.4", "backbone", "Lawnchair", "knockout", "knockback", "bootbox", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/smm/DimensionalMeasurement", "appbo/vdml/BusinessModelMixin", "appbo/smm/Accumulator", "appbo/smm/BaseNMeasurementRelationship"],
//    function (require, $, _, d3, Backbone, Lawnchair, ko, kb, bootbox, backboneLawnchair, DataManager, global, DimensionalMeasurement, BusinessModelMixin, Accumulator, BaseNMeasurementRelationship) {
var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties", global.version);
export class ValueAggregation2ViewModel {
    init(model,options){
            var self = this;
            this.ValueAggregation2ViewModel = this;
            this.model = model;
            model = null;
            //this.options = options;
            //options = null;
            /*self.showAllLevels = ko.observable(false);
            self.showAllLevels.subscribe(function (showAll) {
                self.nodes = {};
                self.links = [];
                self.selectedConnector(null);
                self.selectedNode(null);
                d3.select("svg").remove();
                var links = self.buildLinks(self.model);
                self.buildGraph();
            });*/
            this.ovs = false;
            if (self.model.get('measuredCharacteristic') && self.model.get('measuredCharacteristic').getParent && self.model.get('measuredCharacteristic').getParent().checkOVS) {
                self.ovs = self.model.get('measuredCharacteristic').getParent().checkOVS();
            }
            self.altId  = DataManager.getDataManager().get('viewAlternative');
            self.selectedNode = ko.observable();
            self.selectedTreeNode = ko.observable();
            self.selectedConnector = ko.observable();
            self.measurementValue = ko.observable();
            self.treeMeasurementValue = ko.observable();
            self.valueName = ko.observable();
            self.treeValueName = ko.observable();
            self.measurementUnit = ko.observable();
            self.treeMeasurementUnit = ko.observable();
            this.defaultScenario = DataManager.getDataManager().get('defaultScenario');
            var defaultExecutionScenaroId = self.defaultScenario.get("defaultExecutionScenario");
            var defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
            this.inputPeriodDataset = defaultExecutionScenario.get("input").findWhere({period: options.addOptions.period, year:options.addOptions.year});
            this.calcPeriodDataset = defaultExecutionScenario.get("result").findWhere({period:  options.addOptions.period, year: options.addOptions.year});
            //self.enableAccu = ko.observable(false);
            //self.enableMultiplier = ko.observable(false);
            //self.accumulatorOptions = Accumulator.symbols();
            //self.accArray = self.accumulatorOptions.slice(0); //clone array
            //self.accumOptions = ko.observableArray(self.accArray);
            self.formula = ko.observable();
            self.treeAccumulator = ko.observable();
            self.readOnly = true;
            //self.aggregateOperationsOptions = ko.observableArray([]);
            self.aggregateOperation = ko.observable();
            self.multiplier = ko.observable();
            self.offset = ko.observable();
            self.selectedModels = [];
            self.selectedModels.push(self.model);
            self.modelsDepth = {};
            self.modelsDepth[self.model.id] = 0;
            self.changeInValue = false;
            self.showEditIcon = ko.observable(true);

            /*function fillAggregateOperations(){
                self.aggregateOperationsOptions.push({name:'Select Operation',description:'Select Operation'});
                self.aggregateOperationsOptions.push({name:'invertOperation',description:'Reciprocal'});
                self.aggregateOperationsOptions.push({name:'squareOperation',description:'Square'});
                self.aggregateOperationsOptions.push({name:'squareRootOperation',description:'Square root'});
            }
            fillAggregateOperations();*/

            function htmlEscape(str) {
                return String(str)
                    .replace(/@/g, '')
                    .replace(/ /g, '')
                    .replace(/#/g, '');
            }
            if (options && options.parentView) {
                //this.parentView = self.options.parentView;
                this.currentAlternativeModel = options.currentAlternativeModel;
            }
            this.encodeId = "" + options.modalId;


            this.cleanUp = function () {

            };
        this.cleanModal = function () {
            var self = this;
                if (options.callback) {
                    options.callback(self.changeInValue);
                }
                window.cleanDialogModel(self.encodeId, this);
            };
            this.buildSelectedLinks = function (refresh, centreNode, index) {
                var processedModels = {};
                self.links = [];
                //d3.select("svg").remove();
                _.each(self.selectedModels, function (model) {
                    self.buildLinks(model, processedModels);
                });
                self.buildGraph(refresh, centreNode, index);
            };
            this.buildLinks = function (model, processedModels) {
                if (!self.links) {
                    self.links = [];
                }
                if (!processedModels) {
                    processedModels = {};
                }
                if (processedModels[model.id]) {
                    return self.links;
                } else {
                    processedModels[model.id] = true;
                }
                var relationships = model.get('aggregatedFrom');
                if (relationships) {
                    relationships.each(function (relation) {
                        for (var i = 0; i < self.links.length; i++) {
                            if (self.links[i].value === relation.id) {
                                return;
                            }
                        }
                        var link = {};
                        link.source = model.id;
                        link.target = relation.id;
                        link.value = model.get('context').models[0].id;
                        self.links.push(link);
                        if (!self.modelsDepth[link.source]) {
                            self.modelsDepth[link.source] = self.modelsDepth[link.target] + 1;
                        }
                        /*if (self.showAllLevels()) {
                            self.buildLinks(relation.get('to'), self.links, processedModels);
                        }*/
                    });
                }
                var toRelationShips = model.get('aggregatedTo');
                if (toRelationShips) {
                    toRelationShips.each(function (relation) {
                        for (var i = 0; i < self.links.length; i++) {
                            if (self.links[i].value === relation.id) {
                                return;
                            }
                        }
                        var link = {};

                        link.source = relation.id;
                        link.target = model.id;
                        link.value =  relation.id;
                        self.links.push(link);
                        if (!self.modelsDepth[link.target]) {
                            self.modelsDepth[link.target] = self.modelsDepth[link.source] + 1;
                        }
                        /*if (self.showAllLevels()) {
                            self.buildLinks(relation.get('from'), self.links, processedModels);
                        }*/
                    });
                }
                console.log(self.links);
                return self.links;
            };

            this.htmlEscape = function(str) {
                return String(str)
                    .replace(/@/g, '')
                    .replace(/ /g, '')
                    .replace(/#/g, '');
            };

            $('#modal' + self.encodeId).on('shown.bs.modal', function () {
                self.buildLinks(self.model);
                self.buildGraph();
                $('#svg' + self.encodeId).css('margin-top', "0px");
				$('#svg' + self.encodeId).css('margin-left', "");
                self.setResizeHeight();
            });
            function click() {
                var actualHeight = $('#aggregationViewRoot' + self.encodeId).height();
                var mestId = this.__data__.id;
                var mest = window.utils.getElementModel(mestId,['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']);
                self.measurementUnit('');
                if(mest){
                    var value = self.getQty(mest);
                    console.log(value);
                    self.mest = mest;
                    var  updatedExpression = ""
                    if( mest.getValueContext(self.altId)){
                        var formula = mest.getValueContext(self.altId).get('formula')
                        var exp = formula ? formula.get("expressionStr") : null;
                        var agg  = mest.get('aggregatedFrom').map((d)=>{return {name:d.get('name'),id:d.get('id'),variableId:DataManager.getDataManager().htmlEscape(d)}});
                        console.log(agg);
                        updatedExpression =  utils.replaceIdsWithNames(exp, agg)
                    }
                    console.log(updatedExpression);
                    self.valueName(mest.get('name'));
                    if (mest.get('name').length >= 19) {
                        var html = "<span id='tooltipText' data-toggle='tooltip' title='" + mest.get('name').substr(15, mest.get('name').length) + "'> ...</span>";
                        self.valueName(mest.get('name').substr(0, 15));
                        $('#valueName').append(html);
                    }
                    var unit = mest.get('unit');
                    if (unit) {
                        self.measurementValue(window.utils.getRoundedValues(value, unit.get('significantDecimals')));
                        self.measurementUnit(unit.get('name'));
                    } else {
                        self.measurementValue(window.utils.getRoundedValues(value,2));
                        if(mest.get('name').includes('Recipient Opinion')){
                            self.measurementUnit(mest.getParent().get('unit').get('name'));
                       }
                    }
                    if (mest.get('aggregatedFrom').length > 1) {
                        self.formula(updatedExpression);
                        self.treeAccumulator(updatedExpression);
                        $('.accumulator').show();
                    } else {
                        self.formula('-');
                    }
                    $('#aggregationViewRoot' + self.encodeId).height(actualHeight)
                    var modelId = self.encodeId.replace(/aggregation/, '');
                    if(self.htmlEscape(mestId)!==modelId){
                        self.showEditIcon(false)
                    }else {
                        self.showEditIcon(true);
                    }
                }
               

                /*d3.select(this).select("text").transition()
                    .duration(750)
                    .attr("x", 22)
                    .style("fill", "steelblue")
                    .style("stroke", "lightsteelblue")
                    .style("stroke-width", ".5px")
                    .style("font", "20px sans-serif");*/
                var previousSelectedNode = self.selectedNode();
                if (previousSelectedNode) {
                    d3.select(previousSelectedNode).select("circle").transition()
                        .duration(750)
                        .style("stroke", "none");
                }
                $('#svg' + self.encodeId).css('margin-top',"0px")
                $('#svg' + self.encodeId).css('margin-left', "")
                var previouslyselectedConnector = self.selectedConnector();
                if (previouslyselectedConnector) {
                    $(previouslyselectedConnector).css('stroke', 'black');
                    d3.select(previouslyselectedConnector)
                        .attr('marker-end', 'url(#end)');
                }
                self.selectedConnector(null);
                self.selectedNode(this);
                self.selectedTreeNode(false);
                d3.select(this).select("circle").transition()
                    .duration(750)
                    .style("stroke", "red");
            }
            // action to take on mouse double click


            self.color = d3.scaleOrdinal(d3.schemeCategory10);
            self.nodes = {};
            self.links = [];

            self.div = d3.select("#editDetailsModalContent" + self.encodeId).append("div")
                .attr("class", "tooltip")
                .style("opacity", 0);


            this.custResize = function (view) {
                self.width = $("#editDetailsModalContent" + self.encodeId).width() - 20;
                self.svg.attr("width", self.width);
                self.setResizeHeight();
                self.simulation.alpha(1).restart();
            }
            this.buildGraph = function (refresh, centreNode, index) {
                var view = this;
                if (!refresh) {
                    self.width = $("#editDetailsModalContent" + self.encodeId).width() - 20;
                    //self.height = 400;
                    self.height = window.innerHeight-200;
                    self.svg = d3.select("#aggregationViewRoot" + self.encodeId).append("svg");
                    self.svg.attr("width", self.width)
                        .attr("height", self.height)
                        .attr('id', 'svg' + self.encodeId);

                    self.div = d3.select("#editDetailsModalContent" + self.encodeId).append("div")
                        .attr("class", "tooltip")
                        .style("opacity", 0);
                    self.rootG = self.svg.append("svg:g");

                    self.svg
                        .call(d3.zoom().on("zoom", function () {
                            self.svg.attr("transform", d3.event.transform)
                        }))
                        .call(d3.zoom().on("zoom", function () {
                            //var dialog = jQuery('#ValueAggregationView' + view.encodeId).offset();
                            //d3.event.transform.x = Math.min(0, Math.max(d3.event.transform.x, self.width - self.width * d3.event.transform.k));
                            //d3.event.transform.y = Math.min(0, Math.max(d3.event.transform.y, self.height - self.height * d3.event.transform.k));
                            self.rootG.attr("transform", d3.event.transform);
                        }));
                    self.svg.on("dblclick.zoom", null);
                    self.svg.append("svg:defs").selectAll("marker")
                        .data(["end"])      // Different link/path types can be defined here
                        .enter().append("svg:marker")    // This section adds in the arrows
                        .attr("id", String)
                        .attr("viewBox", "0 -5 10 10")
                        .attr("refX", 15)
                        .attr("refY", -1.5)
                        .attr("markerWidth", 3)
                        .attr("markerHeight", 3)
                        .attr("orient", "auto")
                        .append("svg:path")
                        .attr("d", "M0,-5L10,0L0,5");
                    // build the arrow.
                    self.svg.append("svg:defs").selectAll("marker")
                        .data(["endRed"])      // Different link/path types can be defined here
                        .enter().append("svg:marker")    // This section adds in the arrows
                        .attr("id", String)
                        .attr("viewBox", "0 -5 10 10")
                        .attr("refX", 15)
                        .attr("refY", -1.5)
                        .attr("markerWidth", 3)
                        .attr("markerHeight", 3)
                        .attr("orient", "auto")
                        .append("svg:path")
                        .attr("d", "M0,-5L10,0L0,5")
                        .attr('stroke', 'red')
                        .attr('fill', 'red');
                }

                // Compute the distinct nodes from the links.
                self.links.forEach(function (link) {
                    link.source = self.nodes[link.source] ||
                        (self.nodes[link.source] = { id: link.source });
                    link.target = self.nodes[link.target] ||
                        (self.nodes[link.target] = { id: link.target });
                    link.value = link.value;
                });
                if (Object.getOwnPropertyNames(self.nodes).length === 0) {
                    self.nodes[self.model.id] = { id: self.model.id }
                }
                // add the links and the arrows
                if (!refresh) {
                    var link_force = d3.forceLink(self.links)
                        .id(function (d) { return d.value; })
                        .distance(100)
                        .strength(1);
                    self.simulation = d3.forceSimulation().nodes(d3.values(self.nodes))
                        .force("charge_force", d3.forceManyBody())
                        .force("center_force", d3.forceCenter(self.width / 2, self.height / 2))
                        .force("links", link_force);
                }
                self.simulation.on("tick", tickActions);
                if (!refresh) {
                    self.path = self.rootG.append("svg:g").selectAll("path")
                        .data(self.links, function (d) {
                            return d.value;
                        });
                } else {
                    self.path = self.rootG.select("g").selectAll("path")
                        .data(self.links, function (d) {
                            return d.value;
                        });
                }
                self.path.exit().remove();
                self.path = self.path.enter().append("svg:path")
                    .attr("class", "link")
                    .attr("marker-end", "url(#end)")
                    .merge(self.path);

                // define the nodes
                self.mestModels = [];
                self.valueList = [];
                var newNodes = self.rootG.selectAll(".node").data(d3.values(self.nodes), function (d) { return d.id });
                var exitNodes = newNodes.exit().remove();
                self.node = newNodes
                    .enter().append("g")
                    .attr("class", "node")
                    .on("click", click)
                    .on("dblclick", dblclick)
                    .on("mouseover", function (d) {
                        var view = self;
                        var val;
                        var dialog = jQuery('#ValueAggregation2View' + view.encodeId).offset();
                        var mest = window.utils.getElementModel(d.id,['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']);
                        if (mest) {
                            var unit = mest.get('unit');
                            var unitStr = unit?unit.get('name'):"";
                            var value =  self.getQty(mest);
                            var namepath = view.getNamePath(value, view.valueList);
                            var significantDecimals = unit? unit.get('significantDecimals'):2
                            var val = window.utils.getRoundedValues(value, significantDecimals) + "(" + unitStr + ") " + namepath;
                        }
                        self.div.transition()
                            .duration(200)
                            .style("opacity", .9);
                        self.div.html(val)
                            .style("left", (d3.event.pageX - dialog.left) + "px")
                            .style("top", (d3.event.pageY - 28 - dialog.top) + "px");
                    })
                    .on("mouseout", function (d) {
                        self.div.transition()
                            .delay(100)
                            .duration(500)
                            .style('pointer-events', 'none')
                            .style("opacity", 0);
                        self.div.text('');
                    });
                self.node.append("circle")
                    .attr("r", 6)
                    .style("fill", function (d) {
                        if (d.id === self.model.id) {
                            var mest = window.utils.getElementModel(d.id,['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']);
                            var  updatedExpression = ""
                            if(mest.getValueContext(self.altId)){
                                var formula = mest.getValueContext(self.altId).get('formula')
                                var exp = formula ? formula.get("expressionStr") : null;
                                var agg  = mest.get('aggregatedFrom').map((d)=>{return {name:d.get('name'),id:d.get('id'),variableId:DataManager.getDataManager().htmlEscape(d)}});
                                console.log(agg);
                                updatedExpression =  utils.replaceIdsWithNames(exp, agg)
                            }
                            if(mest){
                                self.measurementUnit('');
                                var value = mest;
                                self.valueName(value.get('name'));
                                self.treeValueName(value.get('name'));
                                if (value.get('name').length >= 19) {
                                    var html = "<span id='tooltipText' data-toggle='tooltip' title=" + value.getParent().get('name').substr(15, value.getParent().get('name').length) + "> ...</span>";
                                    self.valueName(value.get('name').substr(0, 15));
                                    self.treeValueName(value.get('name').substr(0, 15));
                                    $('#valueName').append(html);
                                    $('#treeValueName').append(html);
                                }
                                var unit = mest.get('unit');
                                if (unit) {
                                    self.measurementValue(self.getQty(mest), unit.get('significantDecimals'));
                                    self.treeMeasurementValue(self.getQty(mest), unit.get('significantDecimals'));
                                    self.measurementUnit(unit.get('name'));
                                    self.treeMeasurementUnit(unit.get('name'));
                                } else {
                                    self.measurementValue(self.getQty(mest));
                                    self.treeMeasurementValue(self.getQty(mest));
                                }
                                if (updatedExpression) {
                                    self.formula(updatedExpression);
                                    self.treeAccumulator(updatedExpression);
                                    $('.accumulator').show();
                                } else {
                                    self.formula('-');
                                }
                            }
                            
                            // if (mest.get('observedMeasure').getParent().get('whenObserved')) {
                            //     self.showEditIcon(false);
                            // }

                            /*d3.select(this).select("text").transition()
                                .duration(750)
                                .attr("x", 22)
                                .style("fill", "steelblue")
                                .style("stroke", "lightsteelblue")
                                .style("stroke-width", ".5px")
                                .style("font", "20px sans-serif");*/
                            var previousSelectedNode = self.selectedNode();
                            if (previousSelectedNode) {
                                d3.select(previousSelectedNode).select("circle").transition()
                                    .duration(750)
                                    .style("stroke", "none");
                            }
                            $('#svg' + self.encodeId).css('margin-top',  "0px")
                            $('#svg' + self.encodeId).css('margin-left', "")
                            var previouslyselectedConnector = self.selectedConnector();
                            if (previouslyselectedConnector) {
                                $(previouslyselectedConnector).css('stroke', 'black');
                                d3.select(previouslyselectedConnector)
                                    .attr('marker-end', 'url(#end)');
                            }
                            self.selectedConnector(null);
                            self.selectedTreeNode(false);
                            self.selectedNode(self.node._groups[0][d.index]);
                            d3.select(self.node._groups[0][d.index]).select("circle").transition()
                                .duration(750)
                                .style("stroke", "red");
                            $('#spliter').css('display', 'block');
                            return "#77aaff";
                        }
                        return "#ff77aa";
                    })
                    .merge(self.node);


                // add the text 

                var textName = self.model.get('name');
                self.node.append("text")
                    .attr("x", -20)
                    .attr("dy", "1.75em")
                    .text(function (d) {
                        var mest = window.utils.getElementModel(d.id,['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']) ;
                        if (mest) {
                            var mesCharOwner = mest;
                            if (mesCharOwner.get('type') === 'vdml_OutputPort') {
                                mesCharOwner = mesCharOwner.get('containedPortOwner');
                            }
                            var usePrevious = false;
                           
                            var count = 1;
                           
                            var countTxt = count > 1 ? "("+count+")" : "";
                            if(usePrevious){
                                return mesCharOwner.get('name') + "[PreviousPhase]"+countTxt;
                            }else {
                                return mesCharOwner.get('name')+countTxt;// + "[" + mest.get('value')+ "(" + unit + ")"+ "]";
                            }
                        }
                        return textName;
                    });
                var drag_handler = d3.drag()
                    .on("start", drag_start)
                    .on("drag", drag_drag)
                    .on("end", drag_end);

                //same as using .call on the node variable as in https://bl.ocks.org/mbostock/4062045 
                drag_handler(self.node)
                //drag handler
                //d is the node 
                function drag_start(d) {
                    var nodes = d3.values(self.nodes);
                    for (var i = 0; i < nodes.length; i++) {//stop moving nodes
                        var d = nodes[i];
                        d.fx = d.x;
                        d.fy = d.y;
                    }
                    if (!d3.event.active) self.simulation.alphaTarget(0.3).restart();
                }
                function drag_drag(d) {
                    d.fx = d3.event.x;
                    d.fy = d3.event.y;
                }
                function drag_end(d) {
                    if (!d3.event.active) self.simulation.alphaTarget(0);
                    d.fx = d.x;
                    d.fy = d.y;
                    self.simulation.stop();
                }
                // add the curvy lines

                function tickActions() {
                    if (!self.node) {
                        return;
                    }
                    //update circle positions each tick of the simulation 
                    /*self.node
                        .attr("cx", function (d) {
                            return d.x;
                        })
                        .attr("cy", function (d) {
                            return d.y;
                        });*/
                    var r = 6;
                    self.node.attr("cx", function (d) {
                        return d.x = Math.max(r, Math.min(self.width - r, d.x));
                    })
                        .attr("cy", function (d) { return d.y = Math.max(r, Math.min(self.height - r, d.y)); });

                    self.path.attr("d", function (d) {
                        var dx = d.target.x - d.source.x,
                            dy = d.target.y - d.source.y,
                            dr = Math.sqrt(dx * dx + dy * dy);
                        return "M" +
                            d.source.x + "," +
                            d.source.y + "A" +
                            dr + "," + dr + " 0 0,1 " +
                            d.target.x + "," +
                            d.target.y;
                    });
                    self.node
                        .attr("transform", function (d) {
                            return "translate(" + d.x + "," + d.y + ")";
                        });
                }

               

                // function removeChildNodes(mest, parent) {
                //     var childs = mest.get('baseMeasurementTo');
                //     var nodes = d3.values(self.nodes);
                //     if (childs) {
                //         childs.each(function (child) {
                //             if (child === parent) {
                //                 return;
                //             }
                //             for (var i = 0; i < nodes.length; i++) {
                //                 if (nodes[i].id === child.get('to').id) {
                //                     var childMest = DimensionalMeasurement.find(nodes[i].id);
                //                     if (self.modelsDepth[childMest.id] > self.modelsDepth[mest.id]) {
                //                         var index = _.indexOf(self.selectedModels, childMest);
                //                         if (index >= 0) {
                //                             self.selectedModels.splice(index, 1);
                //                         }
                //                         removeChildNodes(childMest, mest);
                //                         removeParentNodes(childMest, mest);
                //                         if (!isChildOfSelectedModels(childMest)) {
                //                             delete self.nodes[child.get('to').id];
                //                             delete self.modelsDepth[childMest.id]
                //                         }
                //                     }
                //                     break;
                //                 }
                //             }
                //         });
                //     }
                // }
                // function isChildOfSelectedModels(mest) {
                //     var found = false;
                //     var parentsRels = mest.get('baseNMeasurementRelationship');
                //     parentsRels.each(function (rel) {
                //         var index = _.indexOf(self.selectedModels, rel.get('from'));
                //         if (index >= 0) {
                //             found = true;
                //         }
                //     });
                //     return found;
                // }
                // function isParentOfSelectedModels(mest) {
                //     var found = false;
                //     var childRels = mest.get('baseMeasurementTo');
                //     childRels.each(function (rel) {
                //         var index = _.indexOf(self.selectedModels, rel.get('to'));
                //         if (index >= 0) {
                //             found = true;
                //         }
                //     });
                //     return found;
                // }
                // function removeParentNodes(mest, child) {
                //     var parents = mest.get('baseNMeasurementRelationship');
                //     parents.each(function (parent) {
                //         if (parent === child) {
                //             return;
                //         }
                //         var nodes = d3.values(self.nodes);
                //         for (var i = 0; i < nodes.length; i++) {
                //             if (nodes[i].id === parent.get('from').id) {
                //                 var parentMest = DimensionalMeasurement.find(parent.get('from').id);
                //                 if (self.modelsDepth[parentMest.id] > self.modelsDepth[mest.id]) {
                //                     var index = _.indexOf(self.selectedModels, parentMest);
                //                     if (index >= 0) {
                //                         self.selectedModels.splice(index, 1);
                //                     }
                //                     removeParentNodes(parentMest, mest);
                //                     removeChildNodes(parentMest, mest);
                //                     if (!isParentOfSelectedModels(parentMest)) {
                //                         delete self.nodes[parent.get('from').id];
                //                         delete self.modelsDepth[parentMest.id]
                //                     }
                //                 }
                //                 break;
                //             }
                //         }
                //     });
                // }
                function dblclick() {
                    var nodes = d3.values(self.nodes);
                    for (var i = 0; i < nodes.length; i++) {//stop moving nodes
                        var d = nodes[i];
                        d.fx = d.x;
                        d.fy = d.y;
                    }
                    var mestId = this.__data__.id;
                    var mest =  window.utils.getElementModel(mestId,['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']);

                    if (!mest || mest === self.model || !mest.models ) {
                        return;
                    }

                    self.div.transition()
                        .duration(500)
                        .style("opacity", 0);

                    var index = _.indexOf(self.selectedModels, mest);
                    if (index >= 0) {
                        self.selectedModels.splice(index, 1);
                        removeChildNodes(mest, mest);
                        removeParentNodes(mest, mest);
                    } else {
                        self.selectedModels.push(mest);
                    }
                    self.buildSelectedLinks(true, this, index);
                }
                // action to take on mouse click

                // Update and restart the simulation.
                var nodes = d3.values(self.nodes);
                var found = _.filter(nodes, function (node) { return (self.mest && node.id === self.mest.id && node.id != self.model.id) });
                if (found && found.length > 0) {
                    var aggre = self.modalEle.get('aggregatedTo');
                    for (var i = 0; i < aggre.length; i++) {
                        var measure = aggre.at(i).get('valueMeasurement').get('measurement');
                        for (var n = 0; n < nodes.length; n++) {
                            for (var m = 0; m < measure.models.length; m++) {
                                if (measure.at(m).id === nodes[n].id && measure.at(m).id != self.model.id && measure.at(m).id != found[0].id && !nodes[n].x) {
                                    nodes[n].x = found[0].x + 90;
                                    nodes[n].fx = found[0].x + 90;
                                    if (i == 0) {
                                        nodes[n].y = found[0].y + 40;
                                        nodes[n].fy = found[0].y + 40;
                                    } else {
                                        nodes[n].y = found[0].y + (50 * (i + 1));
                                        nodes[n].fy = found[0].y + (50 * (i + 1));
                                    }
                                }
                            }
                        }
                    }
                    var aggreFrom = self.modalEle.get('aggregatedFrom');
                    for (var i = 0; i < aggreFrom.length; i++) {
                        var measure = aggreFrom.at(i).get('valueMeasurement').get('measurement');
                        for (var n = 0; n < nodes.length; n++) {
                            for (var m = 0; m < measure.models.length; m++) {
                                if (measure.at(m).id === nodes[n].id && measure.at(m).id != self.model.id && measure.at(m).id != found[0].id && !nodes[n].x) {
                                    nodes[n].x = found[0].x + 90;
                                    nodes[n].fx = found[0].x + 90;
                                    if (i == 0) {
                                        nodes[n].y = found[0].y - 40;
                                        nodes[n].fy = found[0].y - 40;
                                    } else {
                                        nodes[n].y = found[0].y - (50 * (i + 1));
                                        nodes[n].fy = found[0].y - (50 * (i + 1));
                                    }
                                }
                            }
                        }
                    }
                }

                self.node = self.rootG.selectAll(".node");
                self.simulation.nodes(d3.values(self.nodes));
                d3.forceLink(self.links)
                    .id(function (d) { return d.id; })
                    .distance(100)
                    .strength(0.5);
                if (centreNode) {
                    //self.simulation.force("center_force", d3.forceCenter(centreNode.__data__.x, centreNode.__data__.y));
                }
                self.simulation.alpha(1).restart();

            }

            function getSourceType(component) {
                var type = "";
                if (component.get('type') === "vdml_ValueAdd") {
                    type = 'Activity';
                }
                else {
                    var componentOwner = component.get('componentOwner');
                    if (componentOwner.get('isValueFormula')) {
                        type = 'My Proposition';
                    }
                    else {
                        if (componentOwner.get('planCriterionSetOwner')) {
                            type = 'Plan';
                        }
                        else {
                            type = 'Value Proposition';
                        }
                    }
                }
                return type;
            }

            this.getQty = function(valModel){
                var qty = ""
                if(self.inputPeriodDataset && self.inputPeriodDataset.get(valModel.id)){
                    qty = self.inputPeriodDataset.get(valModel.id)
                }
                if(self.calcPeriodDataset && self.calcPeriodDataset.get(valModel.id)){
                    qty = self.calcPeriodDataset.get(valModel.id)
                }
                return qty;
            }

            this.showValuePathDivRow = function () {
                var partcipantNetwork = "", valuePathRole = "", valuePathSource = "", receiverValuePathRole = "", ProviderRole = "", receiverRole = "", PN = "Participant Network";

                var componentOwner = self.modalEle.get('componentOwner');
                if (componentOwner && componentOwner.get('planCriterionSetOwner')) {
                    valuePathSource = componentOwner.get('planCriterionSetOwner').get('name');
                    PN = "";
                    partcipantNetwork = "";
                }
                if (valuePathSource === "" && self.modalEle && self.readOnly != undefined) {
                    fillValuePath();
                }
                var msg = "<div>\
				<div class=\"row\">\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+DataManager.getDataManager().get('localeManager').get('source')+"</span></span></div>\
					<div class=\"col-xs-6\"><span> "+ valuePathSource + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div><br>\
				<div class=\"row\">\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+DataManager.getDataManager().get('localeManager').get('sourceType')+"</span></span></div>\
					<div class=\"col-xs-6\"><span> "+ getSourceType(self.modalEle) + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

                var pnDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+ PN + "</span></span></div>\
					<div class=\"col-xs-6\"><span>"+ partcipantNetwork + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

                var providerDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+ ProviderRole + "</span><span></div>\
					<div class=\"col-xs-6\"><span>"+ valuePathRole + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

                var recieverDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">"+ receiverRole + "</span><span></div>\
					<div class=\"col-xs-6\"><span>"+ receiverValuePathRole + "</div><br>\
					<div class=\"col-xs-1\"></div><br>\
				</div>";

                if (PN !== "") {
                    msg = msg.concat(pnDiv);
                }
                if (ProviderRole !== "") {
                    msg = msg.concat(providerDiv);
                }
                if (receiverRole !== "") {
                    msg = msg.concat(recieverDiv);
                }
                msg = msg.concat("</div>");
                const box = bootbox.dialog({
                    message: msg,
                    title: DataManager.getDataManager().get('localeManager').get('SourceDetails'),
                    buttons: {
                        main: {
                            label: "Close",
                            className: "btn btn-default",
                        }
                    }
                });
                if(document.fullscreenElement){
                    box.appendTo(`#${document.fullscreenElement.id}`)
                  }else{
                    box.show();
                  }
                function fillValuePath() {
                    for (var k = 0; k < self.modalEle.getPackagePath().length - 1; k++) {
                        if (k === 0) {
                            partcipantNetwork = self.modalEle.getPackagePath()[k].name;
                        }
                        if (self.modalEle.get('type') === "vdml_ValueAdd" && k === 1 && self.modalEle.get('valueAddOwner')) {
                            var performingRole = self.modalEle.get('valueAddOwner').get('containedPortOwner').get('performingRole');
                            valuePathRole = performingRole.get('roleAssignment').models[0].get('participant').get('name') + "  <b><i>(" + performingRole.get('name') + ")</b></i>";
                            if (self.modalEle.getPackagePath()[k]) {
                                valuePathSource = self.modalEle.getPackagePath()[k].name;
                            }
                            ProviderRole = "Performer";
                            k++;
                        }
                        else if (k === 1) {
                            valuePathRole = self.modalEle.get('componentOwner').get('provider').get('roleAssignment').models[0].get('participant').get('name') + "  <b><i>(" + self.modalEle.getPackagePath()[k].name + ")</i></b>";
                            if (self.modalEle.get('componentOwner').get('recipient')) {
                                var participantName = '';
                                if (self.modalEle.get('componentOwner').get('recipient').get('roleAssignment').models[0]) {
                                    participantName = self.modalEle.get('componentOwner').get('recipient').get('roleAssignment').models[0].get('participant').get('name');
                                }
                                receiverValuePathRole = participantName + "  (" + self.modalEle.get('componentOwner').get('recipient').get('name') + ")";
                                receiverRole = "To (Role)";
                            }
                            k++;
                            if (self.modalEle.getPackagePath()[k]) {
                                valuePathSource = self.modalEle.getPackagePath()[k].name;
                            }
                            ProviderRole = "From (Role)";
                        }
                    }
                }
            };

            this.getAggregatedColl = function (callback) {
                if (self.aggregatedColl) {
                    callback(self.aggregatedColl);
                } else {
                    var plan = DataManager.getDataManager().get('currentPlan');
                    if (!plan) {
                        plan = self.currentAlternativeModel.getNestedParent();
                    }
                    plan.getValuesThatCanBeRefferedTo(function (values) {
                        self.aggregatedColl = new Backbone.Collection(values);
                        callback(self.aggregatedColl);
                    });
                }
            };

            this.editValueDetails = function (data, event) {
                //TODO show value details dialog
                //if(self.mest != self.model) {
                var mest = window.utils.getElementModel(self.model.id,['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement'])
                var type = 'Activity';
                if (mest.get('type') === 'vdml_ValuePropositionComponent') {
                    if (self.model.get('componentOwner').get('isValueFormula')) {
                        type = 'valueFormula';
                    }
                    else if (DataManager.getDataManager().getRepositoryId(self.model.id) === window.plansKey) {
                        type = 'planValues';
                    }
                    else {
                        type = 'valueProposition';
                    }
                }
                self.getAggregatedColl(function (aggregatedColl) {
                    const currentPlan = DataManager.getDataManager().get('currentPlan');
                    const scenarioId = currentPlan.get("defaultScenario");
                    var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
                    var addOptions = {};
                    var alt = window.utils.getElementModel(self.altId,['transformation.Alternative']);
                    if (!alt) {
                        alt = self.currentAlternativeModel;
                    }
                    var readOnly = false;
                    if (alt != self.currentAlternativeModel) {
                        readOnly = true;
                    }
                    //var prefixValId = DataManager.getDataManager().getRepositoryId(self.modalEle.getNestedParent().id);
                    //if (prefixValId !== "@BEEPPlans@" && prefixValId !== self.currentAlternativeModel.id) {
                    //    readOnly = true;
                    //}
                    if (type == "Activity") {
                        var actModel = mest.get('valueAddOwner').get('containedPortOwner');
                        var actObj = [{ originalId: actModel.get('id'), modalId: htmlEscape(actModel.get('id')), name: actModel.get('name') }];
                        addOptions = { 'type': 'createWhatValues', 'aggTo': false, 'activities': actObj, 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, view: self, 'bm': null, 'currentAlternativeModel': alt, 'readOnly': readOnly, 'aggregationView': true, 'disableAggr': true, 'disableDelete': true };
                    } else {
                        var overAllSatisfaction = mest.get('componentOwner').get('overAllSatisfaction');
                        addOptions = { 'type': type, 'aggTo': false, 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'overAllSatisfaction': overAllSatisfaction, 'readOnly': readOnly, 'currentAlternativeModel': alt, 'disableDelete': true, 'aggregationView': true, 'disableAggr': true };
                    }
                    window.getAndCreateModalDialog(mest, htmlEscape(mest.get('id')) + '_edit', BusinessModel2Mixin, mest, "ValueDetails", self.saveValue, addOptions);
                });
                //}
            }
            this.saveValue = function (view, modalId, type) {
                if (self.valueName() !== view.name().trim()) {
                    if (view.name().trim().length >= 19) {
                        var html = "<span id='tooltipText' data-toggle='tooltip' title='" + view.name().substr(15, view.name().length) + "'> ...</span>";
                        self.valueName(view.name().substr(0, 15));
                        $('#valueName').append(html)
                    } else {
                        self.valueName(view.name().trim());
                    }
                }
                if (self.measurementValue() !== view.measurementValue()) {
                    self.measurementValue(view.measurementValue());
                }
                if (self.measurementUnit() !== view.measurementUnit()) {
                    self.measurementUnit(view.measurementUnit());
                }
                window.utils.startSpinner('values', 'Saving Value ...');
                window.setTimeout(function () {
                    var parent = view.model.getParent();
                    self.currentPlan = DataManager.getDataManager().get('currentPlan');
                    if (parent.get('type') === 'vdml_ValueProposition') {
                        var previousWorkspace = DataManager.getDataManager().getWorkspace();
                        DataManager.getDataManager().getWorkspaceDataWithId(view.currentAlternativeModel.id, function (wsData) {
                            var altWorkspace = wsData.get('workspace');
                            DataManager.getDataManager().setWorkspace(altWorkspace, function () {
                                view.saveData(parent.getNestedParent(), parent, function (changeInValue) {
                                    DataManager.getDataManager().setWorkspace(previousWorkspace, function () {
                                        if (changeInValue) {
                                            self.changeInValue = true;
                                            var measurement = self.model;
                                                self.nodes = {};
                                                self.links = [];
                                                self.selectedConnector(null);
                                                self.selectedNode(null);
                                                self.selectedModels = [];
                                                self.selectedModels.push(measurement);
                                                $("#aggregationViewRoot" + self.encodeId).find('svg').remove();
                                                self.buildSelectedLinks(false, this);
                                                window.cleanDialogModel(modalId, view);
                                                window.utils.stopSpinner('values');
                                        } else {
                                            if (view.changeInSigValue) {
                                                self.changeInValue = true;
                                            }
                                            if (!self.currentPlan) {
                                                DataManager.getDataManager().set('currentPlan', null);
                                            }
                                            window.cleanDialogModel(modalId, view);
                                            window.utils.stopSpinner('values');
                                        }
                                    });
                                }, null);
                            });
                        });

                    } else {
                        var activity = parent.getParent();
                        view.saveWhatValuesData(activity, [{ modalId: window.utils.htmlEscape(activity.id), originalId: activity.id }], [], function (actValue, changeInValue) {
                            if (changeInValue) {
                                self.changeInValue = true;
                                var measurement = self.model;
                                    self.nodes = {};
                                    self.links = [];
                                    self.selectedConnector(null);
                                    self.selectedNode(null);
                                    self.selectedModels = [];
                                    self.selectedModels.push(measurement);
                                    $("#aggregationViewRoot" + self.encodeId).empty();
                                    self.buildSelectedLinks(false, this);
                                    window.cleanDialogModel(modalId, view);
                                    window.utils.stopSpinner('values');
                            } else {
                                if (view.changeInSigValue) {
                                    self.changeInValue = true;
                                }
                                if (!self.currentPlan) {
                                    DataManager.getDataManager().set('currentPlan', null);
                                }
                                window.cleanDialogModel(modalId, view);
                                window.utils.stopSpinner('values');
                            }
                        }, null);

                    }
                }, 100);
                /*if(options.parentView){
                    options.parentView.saveValue(view,modalId,type);
                } */
            }
            this.afterRenderView = function () {
                $('#editDetailsModalContent' + self.encodeId).resize(function () {
                    $('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
                })
                $('#mainTab' + self.encodeId + ' a').click(function (e) {
                    e.preventDefault();
                    $(this).tab('show');
                    if ($(this)[0].className === "Tree") {
                        var data = [];
                        self.addedValues = [];
                        self.selectedTreeNode(true);
                        self.selectedNode(null);
                        self.selectedConnector(null);
                        var modelEle = window.utils.getElementModel(self.model.id,['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']) ;
                        data.push({ "id": modelEle.id, originalId : self.model.id,"parent": "#", originalText: modelEle.get('name'), "text": modelEle.get('name'), "icon": "img/object.png" });
                        self.getTreeStructure(modelEle, data, modelEle.id);
                        var selectedNodes = [];
                        var tree = $('#jstree_div').on("select_node.jstree", function (e, data) {
                            var selectedNode = data.instance.get_node(data.selected[0]);
                            var found = _.filter(selectedNodes, function (val) { return val.id === selectedNode.original.originalId })
                            if (selectedNode.original.originalId != self.model.id  && selectedNode.original.hasChild && selectedNode.original.hasChild.length > 0) {
                                selectedNodes.push({ id: selectedNode.original.originalId });
                                var newNode = self.getTreeStructure(selectedNode.original.hasChild[0].model, [], selectedNode.original.hasChild[0].parent);
                                var parent = $('#jstree_div').jstree('get_selected')[0];
                                $('#jstree_div').jstree().select_node(selectedNode.original.id)
                                for (var i = 0; i < newNode.length; i++) {
                                    $('#jstree_div').jstree().create_node(parent, newNode[i], 'first', false, true);
                                    $('#jstree_div').jstree().close_node(selectedNode.original.parent);
                                    $('#jstree_div').jstree().open_node(selectedNode.original.parent);
                                    $('#jstree_div').jstree().open_node(selectedNode.original.id);
                                }
                                selectedNode.original.hasChild = [];
                            }
                            var mestId = selectedNode.original.originalId;
                            var mest =  window.utils.getElementModel(mestId,['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']) ;
                            self.treeMeasurementUnit('');
                            self.treeValueName('')
                        
                            if (mest.get('name').trim().length >= 19) {
                                var html = "<span id='tooltipText' data-toggle='tooltip' title='" + mest.get('name').substr(15,  mest.get('name').length) + "'> ...</span>";
                                self.treeValueName( mest.get('name').substr(0, 15));
                                $('#treeValueName').append(html)
                            } else {
                                self.treeValueName( mest.get('name').trim());
                            }
                            var unit = mest.get('unit');
                            if (unit) {
                                self.treeMeasurementValue(window.utils.getRoundedValues(self.getQty(mest), unit.get('significantDecimals')));
                                self.treeMeasurementUnit(unit.get('name'));
                            } else {
                                self.treeMeasurementValue(self.getQty(mest));
                            }
                            if (mest.get('aggregatedFrom').length > 1) {
                                self.treeAccumulator(mest.get('aggregatedFrom'));
                                $('.accumulator').show();
                            } else {
                                self.formula('-');
                            }
                        
                            if($('#jstree_div').height() -15 > $('#splitPanels1'+self.encodeId).height()){
                                $('#jstree_div').css('height',$('#splitPanels1'+self.encodeId).height()-25);
                            }
                        }).jstree({
                            'core': {
                                'check_callback': true,
                                'data': data
                            },
                            "plugins": ['search'],
                            "search": {
                                'case_sensitive': false
                            }
                        });
                        if(self.model.get('measuredCharacteristic') && self.model.get('measuredCharacteristic').getParent) {
                            $("#jstree_div").jstree('open_node', self.model.get('measuredCharacteristic').getParent().id);
                        }
                    }
                });

            };
            this.getTreeStructure = function (model, data,parentId) {
                self.getAggregatedTo(model, data,parentId);
                self.getAggregatedFrom(model, data,parentId);
                return data;
            }
            this.getAggregatedTo = function (model, data,parentId) {
                var components =  model.getToAggregations(self.altId)
              
                if(components.models.length>0){
                    var hasChild = []
                    components.models.map((d)=>{
                        var sigDec = d.get('unit') ? d.get('unit').get('significantDecimals') : 2;
                        if (d.get('aggregatedTo').length > 0 || d.get('aggregatedFrom').length > 0) {
                            hasChild.push({ model: d, parent: 'to' + d.id + parentId });
                        }
                        var value = this.getQty(d) ? parseFloat(this.getQty(d)).toFixed(sigDec) : ""
                        data.push({ id: 'to' + d.id + parentId, originalId:d.id , originalText: d.get('name'), text: d.get('name') + " (" + value + " " + (d.get('name') ? d.get('name') : "") + ")", icon: "img/icons/forward.png", parent: parentId, hasChild: hasChild })
                    })
                }
                
                return data;
            };
            this.getAggregatedFrom = function (model, data,parentId) {
                var components =  model.getFromAggregations(self.altId)
                var hasChild = []
                if(components.models.length>0){
                    components.models.map((d)=>{

                        var sigDec = d.get('unit') ? d.get('unit').get('significantDecimals') : 2;
                        if (d.get('aggregatedTo').length > 0 || d.get('aggregatedFrom').length > 0) {
                            hasChild.push({ model: d, parent: 'to' + d.id + parentId });
                        }
                        var value = this.getQty(d) ? parseFloat(this.getQty(d)).toFixed(sigDec) : ""
                        data.push({ id: 'from' + d.id + parentId, originalId: d.id, originalText: d.get('name'), text: d.get('name') + " (" + value + " " + (d.get('name') ? d.get('name') : "") + ")", icon: "img/icons/back.png", parent: parentId, hasChild: hasChild });
                    })
                }
               
                return data;
            };
            
            this.getNamePath = function (val, values) {
                var displayPath = ' ';
                var valFound = _.filter(values, function (obj) { return obj.get('name').toLowerCase() === val.get('name').toLowerCase(); });
                if (valFound && valFound.length > 1) {
                    var valuePackagePath = val.getPackagePath();
                    var packageName = valuePackagePath[0].name;
                    if (val.getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
                        packageName = val.getNestedParent().get('name');
                        displayPath = displayPath.concat(" [" + packageName + "  /  " + valuePackagePath[1].name + "]");
                    } else {
                        if (valuePackagePath.length == 4) {//activity
                            displayPath = displayPath.concat(" [" + packageName + "  /  " + valuePackagePath[1].name + " /  " + valuePackagePath[3].name + "]");
                        } else {
                            var valueName = valuePackagePath[2] ? valuePackagePath[2].name : "";
                            displayPath = displayPath.concat(" [" + packageName + "  /  " + valuePackagePath[1].name + "  /  " + valueName + "]");
                        }
                    }
                }
                return displayPath;
            };
            this.setResizeHeight = function (view, event) {
                    var spliterLength = $('#splitPanels1'  + self.encodeId).find('.propertiesArea').parent().css('width');
                    setTimeout(function () {
                        var propertiesNode = 0;
                        if ($('.propertiesArea').width() > 10) {
                            propertiesNode = $('.propertiesArea valueAggegationView').outerHeight();
                        }
                        //var baseHeight = _.max([bmNode.outerHeight(),propertiesNode.outerHeight()])+20;
                        //var topAreaHeight = $('.navbar').outerHeight();
                        var lhsHeight = $("#aggregationViewRoot" + self.encodeId).outerHeight();
                        
						//var windowHeight = window.innerHeight -200;
						var windowHeight = $("#editDetailsModalContent" + self.encodeId).height()-118;
                        var baseHeight = _.max([lhsHeight, propertiesNode, windowHeight]);
                        if (self.spliter) {
                            self.spliter.css('height', windowHeight + 'px');
                            if ($('.propertiesArea valueAggegationView').width() < 5 && Math.abs($(window).width() - $('.modeler').width()) < 30) {
                                var windowWidth = $(window).width() - 15;
                                $('.splitter_bar').css('left', windowWidth);
                            }
                            $("#splitPanels1" + self.encodeId).enhsplitter('refresh');
                            $('#jstree_div').css('height',$('#splitPanels1'+self.encodeId).height()-25);
							/*if($('.splitter_panel').find('.propertiesArea').parent().css('width') != spliterLength){
							   $('.splitter_panel').find('.propertiesArea').parent().css('width',spliterLength);
							}*/
                        }else {
                            windowHeight = window.innerHeight -200;
                            self.spliter = $("#splitPanels1" + self.encodeId).enhsplitter({ minSize: 0, onDrag: window.utils.splitterBoundary,collapse:'right', position: '64%', handle: 'block', splitterSize: '9px', height: windowHeight, onDragEnd: self.setResizeHeight });
                            self.spliter.enhsplitter('collapse');
                        }
                        
                        if ($('#mainTab' + self.encodeId).find('.active a span').text() === "showMap") {
                            self.modeler.get("canvas").zoom("fit-viewport", true);
                        }
					}, 500);
            };

            this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
                , 'name'
                , 'description'
                , 'type'
                , 'Close'
                , 'Complete'
                , 'addAnother'
                , 'Cancel'
                , 'showAllLevels'
                , 'value'
                , 'nodeDetails'
                , 'connectorDetails'
                , 'AggregationView'
                , 'Quantity'
                , 'formula'
            ]);
            /*for (var i = 0; i < this.accumulatorOptions.length; i++) {
                this.accumulatorOptions[i].description = this.labels[this.accumulatorOptions[i].name]();
            }*/
        }
		static getInstance(model,options){
			var view = new ValueAggregation2ViewModel(model,options);
			view.init(model,options);
			return view;
		}
    }
	path.ValueAggregation2ViewModel = ValueAggregation2ViewModel;
