import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { Phase2Mixin } from '../../../../../version1/bo/transformation/Phase2Mixin'

/*define(["require", "jquery", "enhsplitter", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "app/global", "typeahead", "appviews/dashboard/views/chart/PresentationViewModel", "appbo/vdml/BusinessModel","appbo/dashboard/Presentation", "appviews/vdml/views/chart/BMValuesChart", "appbo/dashboard/DashboardMixin", "appbo/beeppackage/CodeContainer"],
function (require, $, enhsplitter, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, global, typeahead, PresentationViewModel, BusinessModel, Presentation, BMValuesChart, DashboardMixin, CodeContainer)
{*/
var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart", global.version);
export class SelectScenarioViewModel {
	
	cleanUp() {
	};
	
	afterRenderView() {
		var self = this;
	};            
	cleanModal(){
		var self = this;
		self.parentView = null;
		window.cleanDialogModel(self.encodeId,self);
	};
	saveSelection(){
		var self = this;
		self.parentView.refreshTable(self.scenarioOptions());
		self.cleanModal();
	};
    getPeriodsRange(){ debugger
        var self = this;
        var range = self.getDatesForPhaseDialog();
        var sDate = new Date(range.sDate);
        var startMonth = sDate.toLocaleString('en-US', {month: 'short'});
        var eDate = new Date(range.eDate);
        var endMonth = eDate.toLocaleString('en-US', {month: 'short'});
        return sDate.getFullYear() + '/' + startMonth + '-' + eDate.getFullYear() + '/' + endMonth;
    }
    getDatesForPhaseDialog() {
        var self = this;
        const dataManager = DataManager.getDataManager();
        const currentPlan = dataManager.get('currentPlan');
        const phaseId = dataManager.get('viewPhase');
        const periodKind = currentPlan.get("periodKind");
        const scenarioId = currentPlan.get("defaultScenario");
        const scenarioData = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({
            id: scenarioId
        });
        const startTime = scenarioData.get("startTime");
        let stepObj = scenarioData.get("firstStep");
        let periodsUntillCurPhase = 0;
        let planStartDate = new Date(startTime);
        while (stepObj) {
            const noOfPeriods = stepObj.get('noPeriods');
            if (stepObj.get('phaseId') === phaseId) {
                const startDate = noOfPeriods === 0 ? null : Phase2Mixin.calcPhaseStartDate(periodKind, planStartDate, periodsUntillCurPhase);
                const endDate = noOfPeriods === 0 ? null : Phase2Mixin.calcPhaseEndDate(periodKind, startDate, noOfPeriods);
                return {
                    sDate: startDate,
                    eDate: endDate
                };
            }
            periodsUntillCurPhase = periodsUntillCurPhase + parseInt(noOfPeriods, 10);
            stepObj = stepObj.get("next");
        }
        //if (!self.model && self.isLastPhase()) return self.getDatesForLastPhase(periodKind, planStartDate, periodsUntillCurPhase);
    }

    getDatesForLastPhase(periodKind, planStartDate, totalPeriods) {
        var self = this;
        const startDate = Phase2Mixin.calcPhaseStartDate(periodKind, planStartDate, totalPeriods);
        const endDate = Phase2Mixin.calcPhaseEndDate(periodKind, startDate, 1);
        return {
            sDate: startDate,
            eDate: endDate
        };
    }
	
	
	init(model, options){
		var self = this;
		this.afterRenderView = _.bind(this.afterRenderView,self);
		this.saveSelection = _.bind(this.saveSelection,self);
		this.SelectScenarioViewModel = this;
		this.model = model;
		this.encodeId = options.modalId;
		this.scenarioOptions = ko.observableArray([]);
		var dataManager = DataManager.getDataManager();
		var currentPlan = dataManager.get('currentPlan');
		var scenarioId = currentPlan.get("defaultScenario");
		this.scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
		var defaultExecutionScenarioId = this.scenario.get("defaultExecutionScenario");
		this.defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenarioId });
        var displayName = self.scenario.get('name') + ' / ' + self.defaultExecutionScenario.get('name') + "(Applied)";
        var periods = self.getPeriodsRange(currentPlan);
		self.scenarioOptions.push({id:defaultExecutionScenarioId,escapeId:window.utils.getSuffix(this.scenario.id),name:this.defaultExecutionScenario.get('name'),displayName: displayName,scenarioType: "Plan",options:null,periods:periods,when:null});
        this.targetScenario = currentPlan.getTargetScenarioExecution();
        if(self.targetScenario){
            this.targetExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: this.targetScenario.id });
            displayName = self.targetScenario.get('name') + ' / ' + self.targetExecutionScenario.get('name');
            self.scenarioOptions.push({id:this.targetScenario.id,escapeId:window.utils.getSuffix(this.targetScenario.id),name:this.targetExecutionScenario.get('name'),displayName: displayName,scenarioType: self.targetScenario.get('scenarioType'),options:null,options:null,periods:periods,when:null});
        }
        /*dataManager.getAllScenariosByPlan(currentplan.get('id'),(data)=>{
			data.map(d=>{
				const options = d.scenarioExecution.map(d=>{return {text:d.name,value:d.id}})
				if(d && d.id && d.id !== this.scenario.id){
					self.scenarioOptions.push({id:d.id,name:d.name,suffix:window.utils.getSuffix(d.id),options:options})				
				}
			})
		})*/;
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
			'Close'
			, 'Complete'
			, 'Cancel'
			, 'Delete'
			, 'scenario'
		]);
		this.parentView = options.parentView;
	}
	static getInstance(model,options){
		var view = new SelectScenarioViewModel(model, options);
		view.init(model, options);
		return view;
	};
}

path.SelectScenarioViewModel = SelectScenarioViewModel;
