import * as $ from "jquery";
import * as _ from "underscore";
import * as Backbone from "backbone";
import * as ko from "knockout";
import * as kb from "knockback";
import * as bootbox from "../../../../../../libs/bootbox/bootbox";
import * as async from "async";
import "moment/locale/es";
import { DataManager } from "../../../../../com/vbee/data/DataManager";
import { PhaseDetailsViewModel } from "./PhaseDetailsViewModel";
import { Phase2Mixin } from "../../../../bo/transformation/Phase2Mixin";
// import { calcPhaseStartDate, calcPhaseEndDate } from "../../../../../../utils";
// import { UTCDate } from "@date-fns/utc";


var path = DataManager.getDataManager().buildAppNsPath(
    "transformation.views.properties",
    global.version
);
export class PhaseDetails2ViewModel extends PhaseDetailsViewModel {
    init(model, options, detailedView) {
        super.init(model, options);
        var self = this;
        this.PhaseDetails2ViewModel = this;
        var dataManager = DataManager.getDataManager();
        self.defaultScenario = dataManager.get('defaultScenario');
        self.datePickerEnable = ko.observable(false);
        self.options = options;
        this.nextPhase = ko.observable((self.model && self.isValueContext()) ? self.model.get('nextPhase') : false);
        self.isLastPhase = ko.observable(false);
        if(!self.model && self.options.createNextPhase ) {
            const parentPhase = self.options.createNextPhase.model;
            // self.parentPhaseId = parentPhase.id;
            const nextPhase = parentPhase.get("nextPhase");
            if (nextPhase == null) self.isLastPhase(true);
        }
    }

    isValueContext() {
        // function to check weather value context is created or not other than primary alternative of first phase,
        var self = this;
        const valueContexts = self.defaultScenario?.getValuesContexts();
        let keys = Object.getOwnPropertyNames(valueContexts);
        for (let index = 1; index < keys.length; index++) {
            let key = keys[index];
            let altContext = valueContexts[key];
            if(altContext.valueElementContext.length > 0) return true;
        }
        return false;
    }

    getStartDate() {
        return null;
    }
    getEndDate() {
        return null;
    }

    getDatesForPhaseDialog(phaseId) {
        // when opened directly from home page
        var self = this;
        const dataManager = DataManager.getDataManager();
        const currentPlan = dataManager.get('currentPlan');
        const periodKind = currentPlan.get("periodKind");
        const scenarioId = currentPlan.get("defaultScenario");
        const scenarioData = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
        const startTime = scenarioData.get("startTime");
        let stepObj = scenarioData.get("firstStep");
        let periodsUntillCurPhase = 0;
        let planStartDate = new Date(startTime);
        while (stepObj) {
            const noOfPeriods = stepObj.get('noPeriods');
            if (stepObj.get('phaseId') === phaseId) {
                const startDate = noOfPeriods === 0 ? null : Phase2Mixin.calcPhaseStartDate(periodKind, planStartDate, periodsUntillCurPhase);
                const endDate = noOfPeriods === 0 ? null : Phase2Mixin.calcPhaseEndDate(periodKind, startDate, noOfPeriods);
                return { sDate: startDate, eDate: endDate };
            }
            periodsUntillCurPhase = periodsUntillCurPhase + parseInt(noOfPeriods, 10);
            stepObj = stepObj.get("next");
        }
        if(!self.model && self.isLastPhase()) return self.getDatesForLastPhase(periodKind, planStartDate, periodsUntillCurPhase);
    }

    getDatesForLastPhase(periodKind, planStartDate, totalPeriods){
        var self = this;
        const startDate = Phase2Mixin.calcPhaseStartDate(periodKind, planStartDate, totalPeriods);
        const endDate = Phase2Mixin.calcPhaseEndDate(periodKind, startDate, 1);
        return { sDate: startDate, eDate: endDate };
    }

    getDatesForPlanDialog() {
        //when opened via create plan dialog
        var self = this;
        if (self.options?.planDetails === "plan") {
            self.planDate(self.options.startDate);
            self.implementationDate(self.options.endDate);
        }
    }

    deletePhaseObject = function (phaseModel, callback) {
        var self = this;
        phaseModel.deletePhaseAndStep(function () {
            phaseModel.deletePhase(function () {
                callback();
            });
        });
    }


    checkIntermediatePhase(phase, intermediateCallback) {
        var self = this;
        var createIntermediatePhase = self.options.createNextPhase ? self.options.createNextPhase.model : null;
        if (!createIntermediatePhase) {
            createIntermediatePhase = self.options.createPreviousPhase ? self.options.createPreviousPhase.model : null;
        }
        if (createIntermediatePhase) {
            var createPreviousPhase = self.enableIsolation() ? false : true;
            phase.setPrevNextIntermediateLinks(createIntermediatePhase, createPreviousPhase, self.isolation(), function () {
                phase.createIntermediatePhaseStep(createIntermediatePhase, createPreviousPhase);
                if(createPreviousPhase){
                    intermediateCallback(phase);
                } else {
                    phase.updateProjection(function(){
                        intermediateCallback(phase);
                    });
                }
            });
        } else {
            //phase.updateProjection(function(){
                intermediateCallback(phase);
            //});
        }
    }

    afterRenderView(node, view) {
        super.afterRenderView(node, view)
        var self = view;
        self.getDatesForPlanDialog();
        if (self.model && self.options?.planDetails !== "plan") {
            let rangeDates = self.getDatesForPhaseDialog(self.id());
            self.planDate(rangeDates.sDate);
            self.implementationDate(rangeDates.eDate);
        }
        if(!self.model && self.isLastPhase()) {
            let rangeDates = self.getDatesForPhaseDialog();
            self.planDate(rangeDates.sDate);
            self.implementationDate(rangeDates.eDate);
        } else if (!self.model && (self.options.createNextPhase || self.options.createPreviousPhase)) {
            self.planDate(null);
            self.implementationDate(null);
        }
    }

    static getInstance(model, options) {
        var view = new PhaseDetails2ViewModel(model, options);
        view.init(model, options);
        return view;
    }
}
path.PhaseDetails2ViewModel = PhaseDetails2ViewModel;
