import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import {asyncsimple} from '../../../../libs/asyncsimple/asyncsimple'
import {NamedPlanElement} from './NamedPlanElement'
import {NamedPlanElementMixin} from './NamedPlanElementMixin'
import {PackageReference as PlanPackageReference} from './PackageReference'
import {Scenario} from '../vdml/Scenario'
import {ValueDeliveryModel} from '../vdml/ValueDeliveryModel'
import {Analytics} from '../dashboard/Analytics'
import {EcoMap} from '../ecomap/EcoMap'
import {Alternative} from './Alternative'
import {ScenarioProxy} from './ScenarioProxy'
import { DataManager } from '../../../com/vbee/data/DataManager'

//define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","async","asyncsimple","appbo/tickets/Ticket","appbo/cmof/Tag","appcommon/com/vbee/data/DataManager","app/global","appbo/transformation/NamedPlanElement","appbo/transformation/NamedPlanElementMixin","appbo/transformation/PackageReference","appbo/vdml/Scenario","appbo/vdml/ValueDeliveryModel","appbo/dashboard/Analytics","appbo/ecomap/EcoMap"],
/*function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,async,asyncsimple,Ticket,Tag,DataManager,global,NamedPlanElement,NamedPlanElementMixin,PlanPackageReference,Scenario,ValueDeliveryModel,Analytics,EcoMap
, Alternative
, ScenarioProxy){*/
	
	if(!Alternative){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.Alternative){
			Alternative = importPath.Alternative;
		}
        else {
            import('./Alternative').then(loadedModule => {
                Alternative = loadedModule;
            });
			/*require(["appbo/transformation/Alternative"],function(loadedModule){
				Alternative = loadedModule;
			});*/
		}
	}
	if(!ScenarioProxy){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.ScenarioProxy){
			ScenarioProxy = importPath.ScenarioProxy;
		}
        else {
            import('./ScenarioProxy').then(loadedModule => {
                ScenarioProxy = loadedModule;
            });
			/*require(["appbo/transformation/ScenarioProxy"],function(loadedModule){
				ScenarioProxy = loadedModule;
			});*/
		}
	}
	var path = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
export class PhaseMixin{
	getMixinClass(){
        return PhaseMixin;
    }
	defaults(){
		var ret = {
			type: "transformation_Phase"
		}
		return jQuery.extend(NamedPlanElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasOne,
			containingClass:"transformation_Phase",
			key:"previousPhase",
			relatedModel:"transformation.Phase",
			includeInJSON: Backbone.Model.prototype.idAttribute,
			reverseRelation: {
				key:"nextPhase",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasMany,
			containingClass:"transformation_Phase",
			key:"phaseAlternative",
			relatedModel:"transformation.Alternative",
			reverseRelation: {
				key:"phaseAlternativeOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"transformation_Phase",
			key:"primary",
			relatedModel:"transformation.Alternative",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasOne,
			containingClass:"transformation_Phase",
			key:"master",
			relatedModel:"transformation.Alternative",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}		
		])
	}
	static getCumulativeMixinRelations(){
		if (!PhaseMixin.cummulativeRelations) {
            PhaseMixin.cummulativeRelations = _.union(PhaseMixin.getMixinRelations()
                , NamedPlanElementMixin.getCumulativeMixinRelations()
            );
        }
		return PhaseMixin.cummulativeRelations.slice();
	}
	static getSuperTypes(){
		return [
			"transformation_NamedPlanElement"
		];
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			//{name : "implementationDate",type : "EDate",defaultValue : "null",containingClass : "transformation_Phase" },
			//{name : "planDate",type : "EDate",defaultValue : "null",containingClass : "transformation_Phase" },
			{name : "status",type : "Status",defaultValue : "null",containingClass : "transformation_Phase" },
			{name : "change",type : "EString",defaultValue : "null",containingClass : "transformation_Phase" }
		]
	}
	getParent(){
		var container;
		if(!container){
			container = this.get("phaseOwner") ? this.get("phaseOwner") : this.previousAttributes().phaseOwner;
			if(container){
				return container;
			}
		}
		return this;
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		var container;
		if(!container){
			container = this.get("phaseOwner") ? this.get("phaseOwner") : this.previousAttributes().phaseOwner;
			if(container){
				path.unshift({id:this.get('id'),name:this.get('name')})
				return container.getPackagePath(path);
			}
		}
		return path;
	}

//#startCustomMethods	

	savePhaseDetails(phaseName,phaseDesc,phaseStatus,purpose,phasePrimary,planDate,implementationDate,phaseAlternative,callback){
		var self = this;
		//self.addDependency(phaseAlternative,function(){
			self.set({name:phaseName, description: phaseDesc, status: phaseStatus, change: purpose, startDate: planDate, endDate: implementationDate});
			//$("#" + window.utils.htmlEscape(self.id) + "phaseStatus").text(phaseStatus);
			/*self.set('name',phaseName);
			self.set('description', phaseDesc);
			self.set('status', phaseStatus);
			self.set('change', purpose);
			self.set('startDate', planDate);
			self.set('endDate', implementationDate);*/
			self.get('phaseAlternative').models.sort(function(left, right) { 
	            return left.get('name').toLowerCase() == right.get('name').toLowerCase() ? 0 : (left.get('name').toLowerCase() < right.get('name').toLowerCase() ? -1 : 1); 
	        });
			if(phasePrimary != null && self.get('primary').id !== phasePrimary){
				var oldPrimary = self.get('primary');
				var newPrimary = self.get('phaseAlternative').get(phasePrimary);
				self.set('primary',newPrimary);	
				self.handlePrimaryChange(oldPrimary,newPrimary,callback);
			}else {
				callback();
			}
		//});
	};
	
	createIsolation(packageAlt,callback){
		var self = this;
		var packRef = self.get('master').fetchNonCommonPackagesFromAlternative();
		var pacList = [];
		var previousWorkspace = DataManager.getDataManager().getWorkspace();
        for(var i=0;i<packRef.length;i++){
            if(packRef.at(i).get('beepReference').indexOf('Common') == -1){
				if(packRef.at(i).get('beepType') === 'vdml_ValueDeliveryModel'){
					pacList.unshift({'beepType':packRef.at(i).get('beepType'),'beepReference':packRef.at(i).get('beepReference')});
				}else {
					pacList.push({'beepType':packRef.at(i).get('beepType'),'beepReference':packRef.at(i).get('beepReference')});
				}
            }
        }

    	DataManager.getDataManager().getWorkspaceDataWithId(self.get('master').id,function(wsData){
			function checkAndAddRDFForModel(){
				var packRDFModel = wsData.get('rdfModel');
				var vdmStore = wsData.get('vdmStore');
				if(packRDFModel){
					async.eachSeries(pacList, function(pack,pacCallback){
						var count = self.get('master').get('phaseDesignPart').findWhere({'beepReference':pack.beepReference});
						if(count){
							packageAlt.getBeepPackageWithId(pack.beepReference, pack.beepType, function (beepPackage) {
								if(beepPackage){
			                		beepPackage.createRevision(self.get('master').get('id'), packageAlt.get('id'), function(args){
										pacCallback();
									});
			            		}else {
									pacCallback();
								}
			        		});
						}else {
							pacCallback();
						}		
			        },function(arr){
			        	DataManager.getDataManager().setWorkspace(previousWorkspace, function () {      		   
							callback();
						});
			        });
				}else{
					setTimeout(checkAndAddRDFForModel,10);
				}
			}
			checkAndAddRDFForModel();
		});	
	};

	getUsePreviousIds(phase){
		var usePreviousId = [];

		function getComponentMeasurement(component, mc, useScenario){
            var measurement = null;
            var mCharacteristic = component.get(mc);
            var observedMeasure = useScenario.getObservedMeasureWithMeasurements(mCharacteristic, null);
            if(observedMeasure){
                measurement = observedMeasure.get('measurements').at(0);
            }
            return measurement;
        };

		if(phase.get('type') === "vdml_ValueDeliveryModel"){
			var dependentPackages = phase.get('dependentPackage');
			var vdmAltId = DataManager.getDataManager().getRepositoryId(phase.get('id'));
			for(var a = 0; a < dependentPackages.length; a++){
				if(DataManager.getDataManager().getRepositoryId(dependentPackages.at(a).get('id')) != vdmAltId && dependentPackages.at(a).get('id').indexOf('Common') == -1){
					usePreviousId.push(dependentPackages.at(a).get('id'));
				}
			}
			var scenario = phase.get('scenario');
			for(var n = 0; n < scenario.length; n++){
				var contextObs = scenario.at(n).get('contextObservation');
				for(var j = 0; j < contextObs.length; j++){
					var observedMeasures = contextObs.at(j).get('observedMeasures');
					for(var k = 0; k < observedMeasures.length; k++){
						var msmts = observedMeasures.at(k).get('measurements');
						for(var i = 0; i < msmts.length; i++){
							var msmt = msmts.at(i);
							if(msmt.get('type') == "smm_CollectiveMeasurement" ||  msmt.get('type') == "smm_DirectMeasurement"){
								var msmtRel = msmt.get('baseNMeasurementRelationship');
								if(msmtRel){
									for(var m=0;m<msmtRel.length;m++){
										var msmtRelId = DataManager.getDataManager().getRepositoryId(msmtRel.at(m).get('id'));
										if(msmtRelId != window.plansKey && msmtRelId != vdmAltId && msmtRel.at(m).get('definition')){
											usePreviousId.push(msmtRel.at(m).get('id'));
											usePreviousId.push(msmtRel.at(m).get('definition').id);
										}
									}
								}
							}
						}
					}
				}
			}
		}else {
			var alts = phase.get('phaseAlternative');
			var planCriterionSet = phase.getParent().getOrCreatePlanCriterionSet();
			var planComponents = planCriterionSet.get('component');
			_.each(alts.models,function(alt){
				var scenario = alt.getOrCreatePhaseObjectiveSet();
				planComponents.each(function(comp){
					var valueMsmt = getComponentMeasurement(comp,'valueMeasurement',scenario);
					if(valueMsmt && valueMsmt.get('type') === "smm_CollectiveMeasurement"){
						var msmtRelationships = valueMsmt.get('measurementRelationships');
						_.each(msmtRelationships.models,function(msmt){
							if(msmt.get('definition').get('usePreviousAlternative')){
								usePreviousId.push(msmt.id);
								usePreviousId.push(msmt.get('definition').id);
							}
						})
					}
				});
			});
		}
		return usePreviousId;
	};

	createOwnedPackages(ownedPackages,phasePrimary,currentAlt,callback,previousPhaseCreation){
		var self = this;
		var ownedPackageList = [];
        var copiedPackages = [];
		var previousWorkspace = DataManager.getDataManager().getWorkspace();
		var usePreviousId = [];
		if(previousPhaseCreation){
			var nextPhase = phasePrimary.getParent().get('nextPhase');
			if(nextPhase){
				usePreviousId = self.getUsePreviousIds(nextPhase);
			}
		}
		DataManager.getDataManager().getWorkspaceDataWithId(currentAlt.id,function(wsData){
			function checkAndAddRDFForModel(){
				var packRDFModel = wsData.get('rdfModel');
				var vdmStore = wsData.get('vdmStore');
				if(packRDFModel){
					DataManager.getDataManager().setWorkspace(wsData.get('workspace'),function(){
						async.eachSeries(ownedPackages, function(ownedPackage,childCallback){
            				var copyAlt = ownedPackage.copyAlt;
            				var alt = ownedPackage.alt;
            				var pacRef = ownedPackage.pacRef;
            				copyAlt.getBeepPackageWithId(pacRef.get('beepReference'), pacRef.get('beepType'), function (beepPackage) {
								if(beepPackage && DataManager.getDataManager().getRepositoryId(beepPackage.get('id')) === copyAlt.id){
                    				var copyPckStr = JSON.stringify(beepPackage);
                    				var re = new RegExp(copyAlt.get('id'), 'g');
                    				copyPckStr = copyPckStr.replace(re,alt.get('id'));
									var jsonCopyStr = JSON.parse(copyPckStr);
        							DataManager.getDataManager().set('suppressLogging',true);
									DataManager.getDataManager().set('suppressChangeLogging',true);
									var copyPck = null;
									if(beepPackage.get('type') === "dashboard_Analytics"){
										copyPck = new Analytics(jsonCopyStr, { silent: true });
										copyPck.save();
									}else if(beepPackage.get('type') === 'vdml_ValueDeliveryModel'){
										usePreviousId = usePreviousId.concat(self.getUsePreviousIds(beepPackage));
										for(var i = 0; i < usePreviousId.length; i++){
											copyPckStr = copyPckStr.replace(usePreviousId[i],'');
										}
										//TODO remove dependency package
										Backbone.skipPersistance = true;
										copyPck = new ValueDeliveryModel(jsonCopyStr, { silent: true });
										Backbone.skipPersistance = false;
										ownedPackageList.push(copyPck);
									}else if(beepPackage.get('type') === 'ecomap_EcoMap'){
										copyPck = new EcoMap(jsonCopyStr, { silent: true });
										copyPck.save();
									}
									DataManager.getDataManager().set('suppressLogging',false);
									DataManager.getDataManager().set('suppressChangeLogging',false);
									
									DataManager.getDataManager().get('initializedPackages').add({'id':copyPck.get('id'),'version':parseInt(copyPck.get('version')),'model':copyPck});
									copiedPackages.push(beepPackage);
	
									var serverChangeSet = DataManager.getDataManager().get(DataManager.getDataManager().CURRENT_CHANGESET);
									DataManager.getDataManager().addChangeObjectForFile(serverChangeSet,jsonCopyStr,self.getNestedParent(),false);

									function scenarioChanges(){
										//var scenarioPath = beepPackage.getNamePath(alt);
                						var scenario = phasePrimary.getMainScenario(beepPackage);
                						var scenarioId = scenario.get('id').replace(re,alt.get('id'));
										var scenarioProxyId = DataManager.getDataManager().guidGeneratorByOwner(alt.get('phaseAlternativeOwner'));
                    					new ScenarioProxy({id:scenarioProxyId,name:"Scenario Proxy",scenario:scenarioId,designScenarioOwner:alt});
										
										var mainScenario = alt.getMainScenario(copyPck);
										mainScenario.set('name',"Base scenario");
										if(copyPck.get('scenario').length>1){
                            				for(var i=0;i<copyPck.get('scenario').length;i++){
                            					if(copyPck.get('scenario').at(i).id !== mainScenario.id){
                            						copyPck.get('scenario').at(i).destroy();
                            					}
                            				}
										}
											
										var currentPlanVp = DataManager.getDataManager().get('currentPlan').get('planCriterionSet');
	                    				if(currentPlanVp){
											var components = currentPlanVp.get('component');
											components.each(function(valueComponent){
												var newAggToComps = [];
												var newAggFromComps = [];
												var aggTo = valueComponent.get('aggregatedTo');
												var aggFrom = valueComponent.get('aggregatedFrom');
												aggFrom.each(function(vpComponent){
													if(DataManager.getDataManager().getRepositoryId(vpComponent.get('id')) === copyAlt.get('id')){
														var suffixId = vpComponent.get('id').substr(vpComponent.get('id').lastIndexOf('@')+ 1);
	                    								var newComp = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({id:alt.id + suffixId});
														if(newComp) {
															newAggFromComps.push(newComp);
														}
													}
												});
												aggTo.each(function(vpComponent){
													if(DataManager.getDataManager().getRepositoryId(vpComponent.get('id')) === copyAlt.get('id')){
														var suffixId = vpComponent.get('id').substr(vpComponent.get('id').lastIndexOf('@')+ 1);
	                    								var newComp = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({id:alt.id + suffixId});
														if(newComp) {
															newAggToComps.push(newComp);
														}
													}
												});
												for(var i=0;i<newAggToComps.length;i++){
													aggTo.push(newAggToComps[i]);										
												}
												for(var i=0;i<newAggFromComps.length;i++){
													aggFrom.push(newAggFromComps[i]);										
												}
											});
	                    				}
									}
	                    			function updateValueAggregations(bm){
	                    				var bmValuePropositions = bm.get('bmValueProposition');
	                    				bmValuePropositions.each(function(bmValueProposition){
		                    				var components = bmValueProposition.get('valueProposition').get('component');
											components.each(function(valueComponent){
												var aggFrom = valueComponent.get('aggregatedFrom');
												aggFrom.each(function(vpComponent){
													if(DataManager.getDataManager().getRepositoryId(vpComponent.get('id')) !== copyAlt.get('id')){
														vpComponent.get('aggregatedTo').push(valueComponent);
													}
												});
											});
										});
										var bmValueFormulas = bm.get('bmValueFormula');
	                    				bmValueFormulas.each(function(bmValueFormula){
		                    				var components = bmValueFormula.get('valueFormula').get('component');
											components.each(function(valueComponent){
												var aggFrom = valueComponent.get('aggregatedFrom');
												aggFrom.each(function(vpComponent){
													if(DataManager.getDataManager().getRepositoryId(vpComponent.get('id')) !== copyAlt.get('id')){
														vpComponent.get('aggregatedTo').push(valueComponent);
													}
												});
											});
										});
										var bmActivities = bm.get('bmActivity');
										bmActivities.each(function(bmActivity){
		                    				var components = bmActivity.get('activityValue');
											components.each(function(valueComponent){
												var aggFrom = valueComponent.get('aggregatedFrom');
												aggFrom.each(function(vpComponent){
													if(DataManager.getDataManager().getRepositoryId(vpComponent.get('id')) !== copyAlt.get('id')){
														vpComponent.get('aggregatedTo').push(valueComponent);
													}
												});
											});
										});
	                    			}
	                    			function updateAssignments() {
	                    				var bms = copyPck.get('businessModel');
	                    				bms.each(function (bm) {
	                    					var participantNetworks = bm.get('participantNetwork');
	                    					participantNetworks.each(function (pn) {
	                    						var pnColl = pn.get('collaboration');
	                    						var pnCollRep = DataManager.getDataManager().getRepositoryId(pnColl.id);
	                    						if (alt.id !== pnCollRep) {
	                    							var pnCollRoleAssignments = pnColl.get('ownedAssignment');
	                    							var destroyAssignments = [];
	                    							pnCollRoleAssignments.each(function (assignment) {
														var assignmentParticipant = assignment.get('participant');
														var assignmentRole = assignment.get('assignedRole');
	                    								if (!assignmentParticipant) {
	                    									destroyAssignments.push(assignment);
	                    									return;
	                    								}
														var assPartParent = assignmentParticipant.getNestedParent();
														if (assPartParent === beepPackage) {
															var suffixId = assignmentParticipant.id.substr(assignmentParticipant.id.lastIndexOf('@') + 1);
															var newAssignmentParticipant = Backbone.Relational.store.getObjectByName('vdml.Participant').find({ id: alt.id + suffixId });
															if (newAssignmentParticipant) {
																var assignmentId = DataManager.getDataManager().guidGeneratorByOwner(pnColl);
																var newAssignment = new assignment.constructor({ id: assignmentId, name: assignment.get('name'), description: assignment.get('description') });
																pnColl.get('ownedAssignment').add(newAssignment);
																newAssignment.set('participant', newAssignmentParticipant);
																var role = assignment.get('assignedRole');
																newAssignment.set('assignedRole', role);
																role.get('roleAssignment').add(newAssignment);
															}
														}
	                    							});
	                    							_.each(destroyAssignments, function (assignment) {
	                    								assignment.destroy();
	                    							});
	                    							destroyAssignments.length = 0;
	                    						}
	                    					});
	                    					updateValueAggregations(bm);
	                    				});
	                    			}

									copyPck.lawnchair = vdmStore;
									packRDFModel.addRDFForModel(copyPck,function(){
										packRDFModel.save();
										if(beepPackage.get('type') === 'vdml_ValueDeliveryModel'){
											scenarioChanges();
											updateAssignments();
                    					}
										childCallback();
									}, true);
											
                				} else {
                    				childCallback();
								}
							});
            			},function(arr){
							DataManager.getDataManager().setWorkspace(previousWorkspace,function(){
								callback(ownedPackageList,copiedPackages);
							});
        				});
					});
				}else{
					setTimeout(checkAndAddRDFForModel,10);
				}
			}
			checkAndAddRDFForModel();
		})
    }

	setPrevNextIntermediateLinks(prevPhaseModel,createPreviousPhase,isolation,callback){
		var self = this;
		if(createPreviousPhase){
			self.createPreviousPhaseLinks(prevPhaseModel,function(){
				callback();
			});
		} else {
			var oldNext = prevPhaseModel.get('nextPhase');
			const currentNextPrimAlt = self.get('primary');
			const oldNextPrimAlt = oldNext?.get('primary');
			if(oldNext) {
				prevPhaseModel.set('nextPhase',self);
				self.set('previousPhase',prevPhaseModel);
				self.set('nextPhase',oldNext);
				oldNext.set('previousPhase',self);
				oldNextPrimAlt.set('clonedFrom', currentNextPrimAlt?.id);
				self.createReferencesForIntermediatePhase(prevPhaseModel,function(copiedPackages){
					self.fixUsePreviousRelationsInNextPhase(self.get('primary'),self.get('nextPhase'),isolation);
					if(isolation){
						self.createIsolation(prevPhaseModel.get('primary'),callback);
					}else{
						callback();
					}
				});
			} else {
				prevPhaseModel.set('nextPhase',self);
				self.set('previousPhase',prevPhaseModel);
				self.createReferencesForNewPhase(prevPhaseModel,function(measurementsCopied){
					if(isolation){
						self.createIsolation(prevPhaseModel.get('primary'),callback);
					}else{
						callback();
					}
				});
			}
		}
	};

	createPreviousPhaseLinks(prevPhaseModel,prevCallback){
		var self = this;
		var oldpreviousPhase = prevPhaseModel.get('previousPhase');
		self.set('previousPhase',oldpreviousPhase);
		self.set('nextPhase',prevPhaseModel);
		prevPhaseModel.set('previousPhase',self);
		var ownedPackages = [];
		var packRef = prevPhaseModel.get('primary').get('phaseDesignPart');
		for(var i=0;i<packRef.length;i++){
			var pacRef = packRef.at(i);
			var pprId = DataManager.getDataManager().guidGeneratorByOwner(self);
			var beepRef = pacRef.get('beepReference');
			if(beepRef.indexOf('Common') == -1){
				var suffixId = pacRef.get('beepReference').substr(pacRef.get('beepReference').lastIndexOf('@')+ 1);
				beepRef = self.get('primary').id + suffixId;
				var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({id:DataManager.getDataManager().getRepositoryId(pacRef.get('beepReference'))}); 
				ownedPackages.push({'copyAlt':alt,'alt':self.get('primary'),'pacRef':pacRef});
			}
			if(beepRef.indexOf('Common@-CommonPackage') == -1) {
				var newPacRef = new PlanPackageReference({id:pprId,beepReference:beepRef,name:pacRef.get('name'),description:pacRef.get('description'),version:pacRef.get('version'),label:pacRef.get('label'),isMain:pacRef.get('isMain'),phaseDesignPartOwner:self.get('primary')});
				newPacRef.set('beepType', pacRef.get('beepType'));
				if(pacRef.get('beepType') === "vdml_ValueDeliveryModel" && pacRef.get('beepReference').indexOf('Common') == -1){
					newPacRef.set('isMain', true);
				}else {
					newPacRef.set('isMain', false);
				} 
				   self.get('primary').get('phaseDesignPart').add(newPacRef); 
			}
		}
		
		self.createOwnedPackages(ownedPackages,prevPhaseModel.get('primary'),self.get('master'),function(ownedPackageList,copiedPackages){
			var measurementsCopied = [];
			var altScenario = self.get('master').getOrCreatePhaseObjectiveSet();
			var nextPhaseScenario = self.get('nextPhase').get('primary').getOrCreatePhaseObjectiveSet();
			if(nextPhaseScenario.copyData){
				nextPhaseScenario.copyData(altScenario,false,false,false,measurementsCopied);
			}
			self.get('phaseOwner').copyMeasurements(measurementsCopied,function(){
				self.movePackagePlanRelationsToCopyAlternative(altScenario,ownedPackageList);
				self.correctRelationsGoneWrongWithClone(copiedPackages,self.get('nextPhase').get('primary'),self.get('master'));
				prevCallback();
			},self.get('nextPhase').get('primary'),ownedPackageList);
		},true);
	}
	
	/*setPrevNextLinks(goalPhase,prevPhaseModel,callback){
		var self = this;
		if(goalPhase){
			var prevPhase = goalPhase.get('previousPhase');
			self.set('nextPhase',goalPhase);
			if(prevPhase){
				self.set('previousPhase',prevPhase);
				self.createReferencesForNewPhase(prevPhase,function(){
					callback();
				});
			}else {
				callback();
			}
		}else{
			if(prevPhaseModel) {
				prevPhaseModel.set('nextPhase',self);
				self.set('previousPhase',prevPhaseModel);
				self.createReferencesForNewPhase(prevPhaseModel,function(measurementsCopied){
					callback();
				});
			}else {
				callback();
			}
		}
	};*/
	
 	savePhaseAlternatives(alternativeArray,existingPhase,callback){
		var self = this;
		asyncsimple.each(alternativeArray,function(alt,altHandleCallback){
 			if(alt.originalId === "" && !alt.del){
                self.createNewAlternative(alt.name,alt.description,existingPhase,alt.clonedFromAltId,alt.purpose,function(alternative){
	 				alt.originalId = alternative.get("id");
					altHandleCallback();
                });
 			}
 			else if(alt.originalId !== ""){
 				var alternativeModel = self.get("phaseAlternative").get(alt.originalId);
 				if(alt.del){
 					self.deleteAlternative(alternativeModel,altHandleCallback);
				}
				else {
					alternativeModel.set("name",alt.name);
					alternativeModel.set("description",alt.description);
					alternativeModel.set("purpose",alt.purpose);
					altHandleCallback();
				}
			}else{
				altHandleCallback();
			}
	 	}, function(err){
				if(alternativeArray.length < 1){
					self.createNewAlternative('Base Alternative','Base Alternative',null,null,null,function(alternative){
						self.set('primary',alternative);
						self.set('master',alternative);
						if(callback){
							callback(self);
						}
					});
				}else{
					if(self.get('primary') == null || self.get('master') == null){
						self.set({'primary':self.get('phaseAlternative').at(0), 'master':self.get('phaseAlternative').at(0)});    
					}
					if(callback){
						callback(self);
					}
				}
		});
	};
	
	/*addDependency(alternativeArray,phaseCallback){
		var self = this;
		var altList = [];
		for(var i=0;i<alternativeArray.length;i++){
			if(!alternativeArray[i].del && alternativeArray[i].originalId !== "" && alternativeArray[i].dependency && alternativeArray[i].dependency.length > 0){
	    		altList.push(alternativeArray[i]);
	    	}
	    }
	    async.eachSeries(altList, function(alternative,depCallback){
	    	var alternativeModel = self.get("phaseAlternative").get(alternative.originalId);
			var designParts = alternativeModel.get('phaseDesignPart');
			var masterAlt = alternativeModel.get('phaseAlternativeOwner').get('master');
			var dependencyList = [];
			for(var i=0;i<alternative.dependency.length;i++){
		    	dependencyList.push(alternative.dependency[i]);
	    	}
	    	var measurementsCopied = [];
	    	function addDependencyPackage(dependency,callback){
	    		var packageRef = designParts.findWhere({'beepReference':dependency.originalId});
				if(!packageRef && dependency.check) {
					if(dependency.originalId.indexOf("Common") > -1) {//for report now not using
						var pprId = DataManager.getDataManager().guidGeneratorByOwner(alternativeModel.get('phaseAlternativeOwner'));
                    	var newPacRef = new PlanPackageReference({id:pprId,beepReference:dependency.originalId,name:dependency.name,description:dependency.name,version:DataManager.getDataManager().get('currentVDMVersion'),isMain:dependency.isMain,phaseDesignPartOwner:alternativeModel}); 
						newPacRef.set('beepType','report_BeepReport');
						alternativeModel.get('phaseDesignPart').add(newPacRef);
						callback();
					}else {
						var currentPhase = alternativeModel.get('phaseAlternativeOwner');
						var prevPhase;
						if(currentPhase.get('master') === alternativeModel){
							prevPhase = alternativeModel.get('phaseAlternativeOwner').get('previousPhase');
						}
						else {
							prevPhase = currentPhase.get('master');
						}
						if(prevPhase) {
							var prevPhasePrimary;
							if(alternativeModel === masterAlt){
	                    		prevPhasePrimary = prevPhase.get("primary");	
		                    }else{
		                    	prevPhasePrimary = alternativeModel.get('phaseAlternativeOwner').get('master');	
		                    }
		                    var pacRef = prevPhasePrimary.get('phaseDesignPart').findWhere({'beepReference':dependency.originalId});
							prevPhasePrimary.getBeepPackageWithId(pacRef.get('beepReference'), pacRef.get('beepType'), function (beepPackage) {
							    if(beepPackage){
									if(currentPhase.get('master') === alternativeModel || currentPhase.get('primary') === alternativeModel) {
				            			beepPackage.referPackageToNextPhases(alternativeModel);
				            		}
									var newPacRef = new PlanPackageReference({ beepReference: pacRef.get('beepReference'), name: pacRef.get('name'), description: pacRef.get('description'), version: pacRef.get('version'), label: pacRef.get('label'), isMain: pacRef.get('isMain'), phaseDesignPartOwner: alternativeModel });
									newPacRef.set('beepType', beepPackage.get('type'));
									if(beepPackage.get('type') === "vdml_ValueDeliveryModel") {
										var snapshotId = window.utils.getScenarioSnapshotId(alt,null);
							  			//var scenarioPath = beepPackage.getNamePath(alternativeModel);
										var scenario = beepPackage.createScenario(snapshotId);	
				                        var earilerScenario = prevPhasePrimary.getMainScenario(beepPackage);
				                        earilerScenario.copy(scenario,measurementsCopied);
										var scenarioProxyId = DataManager.getDataManager().guidGeneratorByOwner(alternativeModel.get('phaseAlternativeOwner'));
									    new ScenarioProxy({id:scenarioProxyId,name:"Scenario Proxy",scenario:scenario.get('id'),designScenarioOwner:alternativeModel});
									}
								    alternativeModel.get('phaseDesignPart').add(newPacRef);
								    callback();
								}else {
								    callback();
								}
							});
						}else{
							callback();		
						}
					}
				}else {
					if(packageRef && !dependency.check){
						if(dependency.originalId.indexOf("Common") > -1) {
							var phaseDesignPartRef = alternativeModel.get('phaseDesignPart').findWhere({'beepReference':dependency.originalId});
							phaseDesignPartRef && phaseDesignPartRef.destroy();
							callback();
						}else {
						    alternativeModel.getBeepPackageWithId(packageRef.get('beepReference'), packageRef.get('beepType'), function (beepPackage) {
		                    	alternativeModel.getReferencesToModel2(beepPackage,function(result){
		                    		var len = result.length;
						        	while(len--){
						        		if(result[len].predicate === "transformation_ScenarioProxy-scenario" || result[len].predicate === "transformation_PackageReference-beepReference") {
					                        result.splice(len,1);
					                    }
					                    else if(beepPackage.get('type') === 'ecomap_EcoMap'){
			                    			result.splice(len,1);
				                    	}
						        	}
					                if(result.length==0) {
		                    			var packageArray = [];
				            			packageArray.push(packageRef);
				            			var phase = alternativeModel.get('phaseAlternativeOwner');
				            			if(phase.get('master') === alternativeModel || phase.get('primary') === alternativeModel) {
				            				var altsWithRefs = alternativeModel.getAlternativesWhereICanBeReffered();
				            				for(var i=0;i<altsWithRefs.length;i++){
												var nextpackageRef = altsWithRefs[i].get('phaseDesignPart').findWhere({'beepReference':dependency.originalId});
												if(nextpackageRef && altsWithRefs[i] !== alternativeModel) {
													packageArray.push(nextpackageRef);
												}
											}
											altsWithRefs.length = 0;
				            			}
										function deletePackage(packageModel,callChildback){
											var beepPackage = this;
											var altModel = packageModel.get('phaseDesignPartOwner');
											var scenarios = beepPackage.get("scenario");
											if(scenarios) {
												for(var i=0;i<scenarios.length;i++){
													var scenarioProxy = altModel.get('designScenario').findWhere({'scenario':scenarios.models[i].get("id")});
													if(scenarioProxy){
														scenarios.models[i].destroy();
														scenarioProxy.destroy();
														break;
													}
												}
											}
											packageModel.destroy();
											callChildback();
										}
										async.eachSeries(packageArray, deletePackage.bind(beepPackage), function(err){
											callback();
										});
		                        	}else {
		                        		callback();
		                        	}
		                        });	
	                    	});
	                    }	
					}else{
						callback();
					}
				}
				
			}
			async.eachSeries(dependencyList, addDependencyPackage.bind(measurementsCopied), function(err){
				self.copyPlanScenario(null,measurementsCopied,function(){
					depCallback();
				});
			});
				
	    }, function(err){
	    	altList.length = 0;
	    	phaseCallback();
	    
		});
	};*/
	
	createMasterReferences(alternative,callback){
			var masterAlt = this.get('master');
			var clonedFrom = alternative.get('clonedFrom');
			var derivedAlt = clonedFrom ? window.utils.getElementModel(clonedFrom,['transformation.Alternative']) : masterAlt;
	        var packRef = derivedAlt.get('phaseDesignPart');
	        var altList = [];
	        var measurementsCopied = [];
	        for(var i=0;i<packRef.length;i++){
                var pacRef = packRef.at(i);
				if(pacRef.get('beepType') === "vdml_ValueDeliveryModel" && pacRef.get('beepReference').indexOf('Common') !== -1){
					continue;
				}
                var pprId = DataManager.getDataManager().guidGeneratorByOwner(alternative.get('phaseAlternativeOwner'));
                var newPacRef = new PlanPackageReference({ id: pprId, beepReference: pacRef.get('beepReference'), name: pacRef.get('name'), description: pacRef.get('description'), version: pacRef.get('version'), label: pacRef.get('label'), isMain: pacRef.get('isMain'), phaseDesignPartOwner: alternative });
                newPacRef.set('beepType', pacRef.get('beepType'));
				if(pacRef.get('beepType') === "vdml_ValueDeliveryModel" && pacRef.get('beepReference').indexOf('Common') == -1){
					newPacRef.set('isMain', true);
					altList.push({'phasePrimary':derivedAlt,'alt':alternative,'pacRef':pacRef});
				}else {
					newPacRef.set('isMain', false);
				} 
               	alternative.get('phaseDesignPart').add(newPacRef);
				
            }
            function createScenarioProxy(altList,childCallback){
            	var  phasePrimary = altList.phasePrimary;
            	var alt = altList.alt;
            	var pacRef = altList.pacRef;
            	phasePrimary.getBeepPackageWithId(pacRef.get('beepReference'), pacRef.get('beepType'), function (beepPackage) {
                    if(beepPackage && beepPackage.get('type') === 'vdml_ValueDeliveryModel'){
                    	//var scenarioPath = beepPackage.getNamePath(alt);
						var snapshotId = window.utils.getScenarioSnapshotId(alt,null);
                    	var scenario = beepPackage.createScenario(snapshotId);
                    	var earilerScenario = phasePrimary.getMainScenario(beepPackage);
						if(earilerScenario){
							earilerScenario.copy(scenario,measurementsCopied);
						}
                        var scenarioProxyId = DataManager.getDataManager().guidGeneratorByOwner(alt.get('phaseAlternativeOwner'));
                        new ScenarioProxy({id:scenarioProxyId,name:"Scenario Proxy",scenario:scenario.get('id'),designScenarioOwner:alt});
                    	childCallback();
                    }else{
                    	childCallback();
                    }
              	});  
            }
            async.eachSeries(altList, createScenarioProxy.bind(measurementsCopied), function(err){
            	altList.length = 0;
            	if(callback){
            		callback(measurementsCopied);
            	}
			});
	};
	
	/*createCloneAlternative(alternative,copyAlt,workspace,callback){
			var self = this;
	        var packRef = copyAlt.get('phaseDesignPart');
	        var refPackageList = [];
	        var ownedPackages = [];
	        var ownedPackageList = [];
            var measurementsCopied = [];
            var copiedPackages = [];
	        for(var i=0;i<packRef.length;i++){
                var pacRef = packRef.at(i);
                var pprId = DataManager.getDataManager().guidGeneratorByOwner(alternative.get('phaseAlternativeOwner'));
                var beepRef = pacRef.get('beepReference');
                if(DataManager.getDataManager().getRepositoryId(pacRef.get('beepReference')) === copyAlt.id) {
	                var suffixId = pacRef.get('beepReference').substr(pacRef.get('beepReference').lastIndexOf('@')+ 1);
	                beepRef = alternative.id + suffixId;
	                ownedPackages.push({'copyAlt':copyAlt,'alt':alternative,'pacRef':pacRef});
                }else {
                    if(pacRef.get('beepReference').indexOf('Common') == -1){
                    	refPackageList.push({'copyAlt':copyAlt,'alt':alternative,'pacRef':pacRef});
                    }
                }
                var newPacRef = new PlanPackageReference({id:pprId,beepReference:beepRef,name:pacRef.get('name'),description:pacRef.get('description'),version:pacRef.get('version'),label:pacRef.get('label'),isMain:pacRef.get('isMain'),phaseDesignPartOwner:alternative});
                newPacRef.set('beepType', pacRef.get('beepType'));
                var phasePrimary = this.get('primary');	
                if(pacRef.get('beepType') === "vdml_ValueDeliveryModel" && pacRef.get('beepReference').indexOf('Common') == -1){
					newPacRef.set('isMain', true);
				}else {
					newPacRef.set('isMain', false);
				}    
               	alternative.get('phaseDesignPart').add(newPacRef);

				
            }
			self.createOwnedPackages(ownedPackages,copyAlt,alternative,function(ownedPackageList,copiedPackages){
	            async.eachSeries(refPackageList, function(refPac,childCallback){
	            	var  copyAlt = refPac.copyAlt;
	            	var alt = refPac.alt;
	            	var pacRef = refPac.pacRef;
	            	phasePrimary.getBeepPackageWithId(pacRef.get('beepReference'), pacRef.get('beepType'), function (beepPackage) {
	                    if(beepPackage && beepPackage.get('type') === 'vdml_ValueDeliveryModel'){
	                    	//var scenarioPath = beepPackage.getNamePath(alt);
							var snapshotId = window.utils.getScenarioSnapshotId(alt,null);
	                    	var scenario = beepPackage.createScenario(snapshotId);
	                    	var earilerScenario = copyAlt.getMainScenario(beepPackage);
	                    	earilerScenario.copy(scenario,measurementsCopied);
	                    	scenario.set('localChange',true);
	                        var scenarioProxyId = DataManager.getDataManager().guidGeneratorByOwner(alt.get('phaseAlternativeOwner'));
	                        new ScenarioProxy({id:scenarioProxyId,name:"Scenario Proxy",scenario:scenario.get('id'),designScenarioOwner:alt});
	                        checkWrongDependencyMeasurementRelations(earilerScenario,scenario,alt.id,childCallback());
	                    } else {
	                    	childCallback();
	                    }
	                 });   
	              	  
	            }.bind(measurementsCopied), function(err){
	            	if(callback){
                        callback(measurementsCopied, ownedPackageList, copiedPackages);
	            	}
				});
			});

			function checkWrongDependencyMeasurementRelations(earilerScenario,newScenario,newAltID,callback){
				var Observations = earilerScenario.get('contextObservation').models;
				async.each(Observations, function (obs, obsUpdated) {
					var observedMeasuresModels = obs.get('observedMeasures').models;
					async.each(observedMeasuresModels, function (obMeasure, obMeasureUpdated) {
            			var mest = obMeasure.get('measurements').at('0');
            			var baseNmeasurementRelations = mest.get('baseNMeasurementRelationship') ? mest.get('baseNMeasurementRelationship').models:'';
            			if(baseNmeasurementRelations){
            				for(var i=0;i < baseNmeasurementRelations.length;i++){
	            				if(baseNmeasurementRelations[i].get('id').indexOf(newAltID) !== -1){
	            					var clonedObsMeasure = newScenario.getObservedMeasureWithMeasurements(mest.get('measuredCharacteristic'),obs.get('whenObserved')?true:false, obs.get('whenObserved'));
	            					var clonedBaseNmeasurementRelations = clonedObsMeasure.get('measurements').at('0').get('baseNMeasurementRelationship');
	            					clonedBaseNmeasurementRelations.add(baseNmeasurementRelations[i]);
	            				}
	            			}
	            			obMeasureUpdated();
            			}else{
            				obMeasureUpdated();
            			}
        			},function(){
        				obsUpdated();
        			});
				}, function () {
        			callback && callback();
				});
			}
	};*/

	createReferencesForIntermediatePhase(prevPhaseModel,callback){
		var self = this;
		var alternatives = self.get('phaseAlternative');
		var vdmlPackages = [];
		var packList = [];
		var listOfAlts = _.filter(alternatives.models, function (e) { return self.get('master') !== e; });
		listOfAlts.unshift(self.get('master'));
		async.eachSeries(listOfAlts, function(alt,altCallback){
			var measurementsCopied = [];
			packList = [];
			var clonedFrom = alt.get('clonedFrom');
			var derivedAlt = clonedFrom ? window.utils.getElementModel(clonedFrom,['transformation.Alternative']) : prevPhaseModel.get('primary');
			var packRef = derivedAlt.get('phaseDesignPart');
        	for(var i=0;i<packRef.length;i++){
        	    var pacRef = packRef.at(i);
				if(pacRef.get('beepType') === "vdml_ValueDeliveryModel" && pacRef.get('beepReference').indexOf('Common') !== -1){
					continue;
				}
        	    var pprId = DataManager.getDataManager().guidGeneratorByOwner(alt.get('phaseAlternativeOwner'));
        	    var newPacRef = new PlanPackageReference({ id: pprId, beepReference: pacRef.get('beepReference'), name: pacRef.get('name'), description: pacRef.get('description'), version: pacRef.get('version'), label: pacRef.get('label'), isMain: pacRef.get('isMain'), phaseDesignPartOwner: alt });
        	    newPacRef.set('beepType', pacRef.get('beepType'));
				if(pacRef.get('beepType') === "vdml_ValueDeliveryModel" && pacRef.get('beepReference').indexOf('Common') == -1){
					newPacRef.set('isMain', true);
					packList.push({'phasePrimary':derivedAlt,'alt':alt,'pacRef':pacRef});
				}else {
					newPacRef.set('isMain', false);
				}
				alt.get('phaseDesignPart').add(newPacRef); 
        	}

	    	function createScenarioProxy(packList,childCallback){
	    	    var  phasePrimary = packList.phasePrimary;
	    	    var alt = packList.alt;
	    	    var pacRef = packList.pacRef;
	    	    phasePrimary.getBeepPackageWithId(pacRef.get('beepReference'), pacRef.get('beepType'), function (beepPackage) {
        	        if(beepPackage && beepPackage.createScenario && beepPackage.get('type') === 'vdml_ValueDeliveryModel'){
        	            //var scenarioPath = beepPackage.getNamePath(alt);
						var snapshotId = window.utils.getScenarioSnapshotId(alt,null);
        	            var scenario = beepPackage.createScenario(snapshotId);
        	            var earilerScenario = phasePrimary.getMainScenario(beepPackage);
        	            var scenarioProxyId = DataManager.getDataManager().guidGeneratorByOwner(alt.get('phaseAlternativeOwner'));
        	            new ScenarioProxy({id:scenarioProxyId,name:"Scenario Proxy",scenario:scenario.get('id'),designScenarioOwner:alt});
						if(earilerScenario){
							earilerScenario.copy(scenario,measurementsCopied);
						}
						vdmlPackages.push(beepPackage);
        	            childCallback();
        	        }else{
        	            childCallback();
        	        }
        	    });  
        	}
	    	async.eachSeries(packList, createScenarioProxy.bind(measurementsCopied), function(){
				self.copypValues(measurementsCopied, vdmlPackages, function(){
					altCallback();
				});
			});
		}, function(err){
			callback && callback(vdmlPackages);
		});
	};	
	
	copypValues(measurementsCopied, vdmlPackages, callback){
		var self = this;
		var nextPhaseScenario = self.get('previousPhase').get('primary').getOrCreatePhaseObjectiveSet();
		var altScenario = self.get('master').getOrCreatePhaseObjectiveSet();
		nextPhaseScenario.copyData(altScenario,false,false,false,measurementsCopied);
		self.get('phaseOwner').copyMeasurements(measurementsCopied,function(){
			callback(vdmlPackages);
		},self.get('nextPhase').get('master'),vdmlPackages);
	}
	
	createReferencesForNewPhase(prevPhaseModel,callback){
		var self = this;
		var alternatives = self.get('phaseAlternative');
		var vdmlPackages = [];
		var packList = [];
		var listOfAlts = _.filter(alternatives.models, function (e) { return self.get('master') !== e; });
		listOfAlts.unshift(self.get('master'));
		async.eachSeries(listOfAlts, function(alt,altCallback){
			var measurementsCopied = [];
			packList = [];
			var clonedFrom = alt.get('clonedFrom');
			var derivedAlt = clonedFrom ? window.utils.getElementModel(clonedFrom,['transformation.Alternative']) : prevPhaseModel.get('primary');
			var packRef = derivedAlt.get('phaseDesignPart');
            for(var i=0;i<packRef.length;i++){
                var pacRef = packRef.at(i);
				if(pacRef.get('beepType') === "vdml_ValueDeliveryModel" && pacRef.get('beepReference').indexOf('Common') !== -1){
					continue;
				}
                var pprId = DataManager.getDataManager().guidGeneratorByOwner(alt.get('phaseAlternativeOwner'));
				var PlanPackageReference = Backbone.Relational.store.getObjectByName("transformation.PackageReference");
                var newPacRef = new PlanPackageReference({ id: pprId, 'beepType': pacRef.get('beepType'), beepReference: pacRef.get('beepReference'), name: pacRef.get('name'), description: pacRef.get('description'), version: pacRef.get('version'), label: pacRef.get('label'), isMain: pacRef.get('isMain'), phaseDesignPartOwner: alt });
                //newPacRef.set('beepType', pacRef.get('beepType'));
				if(pacRef.get('beepType') === "vdml_ValueDeliveryModel" && pacRef.get('beepReference').indexOf('Common') == -1){
					newPacRef.set('isMain', true);
					packList.push({'phasePrimary':derivedAlt,'alt':alt,'pacRef':pacRef});
				}else {
					newPacRef.set('isMain', false);//check
				}
				alt.get('phaseDesignPart').add(newPacRef); 
            }
   
	        function createScenarioProxy(packList,childCallback){
	        	var  phasePrimary = packList.phasePrimary;
	        	var alt = packList.alt;
	        	var pacRef = packList.pacRef;
	        	phasePrimary.getBeepPackageWithId(pacRef.get('beepReference'), pacRef.get('beepType'), function (beepPackage) {
                    if(beepPackage && beepPackage.createScenario && beepPackage.get('type') === 'vdml_ValueDeliveryModel'){
                    	//var scenarioPath = beepPackage.getNamePath(alt);
						var snapshotId = window.utils.getScenarioSnapshotId(alt,null);
                    	var scenario = beepPackage.createScenario(snapshotId);
                    	var earilerScenario = phasePrimary.getMainScenario(beepPackage);
                        var scenarioProxyId = DataManager.getDataManager().guidGeneratorByOwner(alt.get('phaseAlternativeOwner'));
                        new ScenarioProxy({id:scenarioProxyId,name:"Scenario Proxy",scenario:scenario.get('id'),designScenarioOwner:alt});
						if(earilerScenario){
							earilerScenario.copy(scenario,measurementsCopied);
						}
						vdmlPackages.push(beepPackage);
                    	childCallback();
                    }else{
                    	childCallback();
                    }
            	});  
        	}
			if(packList.length > 0){
				async.eachSeries(packList, createScenarioProxy.bind(measurementsCopied), function(){
					self.copyPlanScenario(alt,measurementsCopied,function(){
						altCallback();
					});
				}); 
			} else {
				self.copyPlanScenario(alt,measurementsCopied,function(){
					altCallback();
				});
			}
	        
		}, function(err){
			callback && callback(vdmlPackages);
		});	
	};	

		fixUsePreviousRelationsInNextPhase(currentPrimaryAlt,nextPhase,isolation){
            var self = this;
			var currentPlanScenario = currentPrimaryAlt.getOrCreatePhaseObjectiveSet()
			function fixUsePreviousRelation(observations,bmPack){
				observations.each(function(obs){
	    			var obsMess = obs.get('observedMeasures');
	    			obsMess.each(function(obsMes){
	    				var mests = obsMes.get('measurements');
	    				mests.each(function(mest){
	    					var rels = mest.get('measurementRelationships');
	    					rels.each(function(rel){
	    						var targetMest = rel.get('to');
	    						if(targetMest && (rel.get('definition') && rel.get('definition').get('usePreviousAlternative'))) {
		    						var targetRepId = DataManager.getDataManager().getRepositoryId(targetMest.id);
		    						if(targetRepId === window.plansKey){
		    							self.updateMeasurementRelTarget(rel,currentPlanScenario,mest);
										if(isolation){
											mest.get('baseMeasurements').remove(targetMest);
											mest.get('baseMeasurements').add(rel.get('to'));
										}
		    						}else {
										var bmPackage = targetMest.getNestedParent();
										var scenario = currentPrimaryAlt.getMainScenario(bmPackage);
										if(!scenario){
											var pacSuffix = window.utils.getSuffix(targetMest.getNestedParent().id);
											var pac = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: currentPrimaryAlt.id + pacSuffix });
											scenario = currentPrimaryAlt.getMainScenario(pac);
										}
										self.updateMeasurementRelTarget(rel,scenario,mest);
										if(isolation){
											mest.get('baseMeasurements').remove(targetMest);
											mest.get('baseMeasurements').add(rel.get('to'));
											var pac = obs.getNestedParent();
											if(pac.get('type') ==  "vdml_ValueDeliveryModel") {
												obs.getNestedParent().get('dependentPackage').remove(bmPackage);
											}
										}
									}
	    						}
	    					});
	    				});
	    			});
	    		});
			}
			var altList = nextPhase.get('phaseAlternative');
			altList.each(function(alt){
				var nextAltPlanScenario = alt.getOrCreatePhaseObjectiveSet()
				var observations = nextAltPlanScenario.get('objectiveObservation');
				fixUsePreviousRelation(observations);
				var packList = alt.fetchNonCommonPackagesFromAlternative();
				var designScenarios = alt.get('designScenario');
				_.each(packList.models,function(pack){
					if(pack.get('beepType') == "vdml_ValueDeliveryModel"){
						var newPck = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: pack.get('beepReference') });
						if(newPck){
							var scenarios = newPck.get('scenario');
							_.each(scenarios.models,function(sc){
								var observation = sc.get('contextObservation');
								if(observation && designScenarios.findWhere({'scenario':sc.id})){
									fixUsePreviousRelation(observation);
								}
							});
						}
					}
				});
			});
    	}
	
		movePackagePlanRelationsToCopyAlternative(cloneAltPlanScenario,ownedPackageList){
            var self = this;
    		_.each(ownedPackageList,function(copyPack){
	    		var smmModels = copyPack.get('metricsModel');
	    		smmModels.each(function(smmModel){
	    			var observations = smmModel.get('observations');
	    			observations.each(function(obs){
	    				var obsMess = obs.get('observedMeasures');
	    				obsMess.each(function(obsMes){
	    					var mests = obsMes.get('measurements');
	    					mests.each(function(mest){
	    						var rels = mest.get('measurementRelationships');
	    						rels.each(function(rel){
	    							var targetMest = rel.get('to');
	    							if(targetMest && (rel.get('definition') && !rel.get('definition').get('usePreviousAlternative'))) {
		    							var targetRepId = DataManager.getDataManager().getRepositoryId(targetMest.id);
		    							if(targetRepId === window.plansKey && rel.get('to')){
		    								self.updateMeasurementRelTarget(rel,cloneAltPlanScenario,mest);
		    							}
	    							}
	    						});
	    					});
	    				});
	    			});
	    		});
    		});
    	}
        correctRelationsGoneWrongWithClone(copiedPackages,copyAlt,alternative){
            _.each(copiedPackages, function (oldPck) {
                var suffPck = oldPck.id.substr(oldPck.id.lastIndexOf('@') + 1);
                var newPck = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: alternative.id + suffPck });
	        	var obss = copyAlt.get('phaseObjectiveSet').get('objectiveObservation');
	        	obss.each(function(obs){
	        		var obsMess = obs.get('observedMeasures');
	        		obsMess.each(function(obsMes){
	        			var mests = obsMes.get('measurements');
	        			mests.each(function(mest){
	        				if(mest.get('type') === 'smm_CollectiveMeasurement'){
	        					var rels = mest.get('baseMeasurementTo');
	        					rels.each(function(rel){
	        						var targetMest = rel.get('to');
	        						if(targetMest && targetMest.getNestedParent() === newPck && (rel.get('definition') && !rel.get('definition').get('usePreviousAlternative'))){
                                        var suffTargetMest = targetMest.id.substr(targetMest.id.lastIndexOf('@') + 1);
                                        var oldTarget = Backbone.Relational.store.getObjectByName('smm.DimensionalMeasurement').find({ id: copyAlt.id + suffTargetMest });
	        							if(oldTarget){
	        								rel.set('to',oldTarget,{silent:true});
                                            if (rel.get('definition')) {
                                                var defTarget = rel.get('definition').get('to');
                                                var defTargetSuff = defTarget.id.substr(defTarget.id.lastIndexOf('@') + 1);
                                                if (defTarget && defTarget.getNestedParent() === newPck) {
                                                    var relMeasure = rel.get('definition').get('from');
                                                    var relMeasureRelations = relMeasure.get('measureRelationships');
                                                    for (var i = 0; i < relMeasureRelations.length; i++) {
                                                        var mesRel = relMeasureRelations.at(i);
                                                        var mesRelTarget = mesRel.get('to');
                                                        if (mesRelTarget && mesRelTarget.getNestedParent() === oldPck) {
                                                            var mesRelTargetSuff = mesRelTarget.id.substr(mesRelTarget.id.lastIndexOf('@') + 1);
                                                            if (defTargetSuff === mesRelTargetSuff) {
                                                                rel.set('definition', mesRel);
                                                                break;
                                                            }
                                                        }
                                                    }
                                                }
	        								}
	        							}
	        						}
	        					});
	        				}
	        			});        			
	        		});
	        	});
	        });
        }    	
    	updateMeasurementRelTarget(rel,cloneAltPlanScenario,mest){
    		var mestChar = rel.get('to').get('measuredCharacteristic');
    		var observations = cloneAltPlanScenario.get('objectiveObservation')?cloneAltPlanScenario.get('objectiveObservation'):cloneAltPlanScenario.get('contextObservation');
    		var done = false;
    		observations.each(function(obs){
    			if(done){
    				return;
    			}
    			var obsMeasures = obs.get('observedMeasures');
				for(var m=0;m<obsMeasures.length;m++){
					var obsmsure = obsMeasures.at(m);
					if(mestChar === obsmsure.get('measurements').at(0).get('measuredCharacteristic')){
						var newToMest = obsmsure.get('measurements').at(0);
						rel.set('to',newToMest);
						var toMeasure = newToMest.get('observedMeasure').get('measure');
						if(rel.get('definition').get('to') != toMeasure){
							var bmrel = rel.get('from').get('observedMeasure').get('measure').createBaseMeasureRelationship(toMeasure);
							bmrel.set('usePreviousAlternative',rel.get('definition').get('usePreviousAlternative'));
							rel.set('definition',bmrel);
						}
    					done = true;
						break;
    				}
				}
    		});
    	}
	
	createNewAlternative(name, description, existingPhase, clonedFromAltId,purpose, callback){
		var self = this;
		var workspaceStore = DataManager.getDataManager().get("workspaceStore");
		var alternative = new Alternative({
				'name':name,
				'description':description,
				'purpose':purpose,
				phaseAlternativeOwner:this,
		});
		if(clonedFromAltId && clonedFromAltId.id && !clonedFromAltId.creatingPrevPhase){
			alternative.set('clonedFrom',clonedFromAltId.id);
		}else if(clonedFromAltId && clonedFromAltId.id && clonedFromAltId.creatingPrevPhase){
			var firstPhasePrimary = window.utils.getElementModel(clonedFromAltId.id, ['transformation.Alternative']);
			firstPhasePrimary.set('clonedFrom',alternative.id);
		}else {
			if(self.get('phaseAlternative').at(0) == alternative) {
				if(self.get('previousPhase')) {
					alternative.set('clonedFrom',self.get('previousPhase').get('primary').id);
				}else if(self.getParent().get('phase').length > 1){
					var lastPhaseList = _.filter(self.getParent().get('phase').models,function(obj){return  obj.get('nextPhase') === null;});
					var previousLastPhase = lastPhaseList[lastPhaseList.length - 2];
					if(previousLastPhase && previousLastPhase.get('primary') && !clonedFromAltId.creatingPrevPhase){
						alternative.set('clonedFrom',previousLastPhase.get('primary').get('id'))
					}
				}
			}else {
				alternative.set('clonedFrom',self.get('phaseAlternative').at(0).id);
			}
		}
		var plan = alternative.getNestedParent();
		
		DataManager.getDataManager().set('currentPlan',plan,{silent:true});
		
	    //this.get('phaseOwner').getCommonScenario(function(commonScenario){
	    	
			//var commonPack = commonScenario.get('scenarioOwner');
			var designParts = alternative.get('phaseDesignPart');
			//if(commonPack) {
			var comPackId = self.get('phaseOwner').getCommonRepositoryId() + '-CommonPackage';
			var commonPacRef = new PlanPackageReference({beepReference:comPackId,name:'commonScenario',description:'commonScenario',version:DataManager.getDataManager().get('currentVDMVersion'),beepType:"vdml_ValueDeliveryModel",label:'Base',phaseDesignPartOwner:alternative});
			designParts.add(commonPacRef);	
		//}
	    //});
    	
		function initializeWorkspaceData(phaseWorkspace){
			DataManager.getDataManager().getWorkspaceData(phaseWorkspace,function(){
				var plan = alternative.getNestedParent();
				if(plan.get('planCriterionSet')){ //new plan
					var altObjective = alternative.getOrCreatePhaseObjectiveSet();
					//altObjective.createContextObservation();
				}
				if(clonedFromAltId && clonedFromAltId.id){
					var cloneAlt = window.utils.getElementModel(clonedFromAltId.id,['transformation.Alternative'])
				}
				if(cloneAlt && !clonedFromAltId.creatingPrevPhase && existingPhase){
					self.createMasterReferences(alternative,function(measurementsCopied){
						if(callback){
							self.copyPlanScenario(alternative,measurementsCopied,function(){
								callback(alternative);
							});
						}	        		
					});
				}else if(callback){
					callback(alternative);	        		
				}	
			})
		}
		DataManager.getDataManager().loadAltScenarios(DataManager.getDataManager(),plan,function(){
			window.getDefaultRepository(workspaceStore,initializeWorkspaceData,alternative.get('id'),name,true);
		});
		
	};
	
	handlePrimaryChange(currentAltenative,newPrimary,callback){
		var self = this;
		self.updatePrimaryReferences(currentAltenative,newPrimary,callback);
	};

	updatePrimaryReferences(currentAltenative,newPrimary,callback){
		var self = this;
		var oldPackagesColl = currentAltenative.fetchNonCommonPackagesFromAlternative();
		var packageArray = [];
		oldPackagesColl.each(function(pack){
			var packExists = newPrimary.get('phaseDesignPart').findWhere({'beepReference':pack.get('beepReference')});
			if(!packExists){
				packageArray.push(pack);
			}
		});	
		async.eachSeries(packageArray, function (pack, packageHandlerCallback) {
			currentAltenative.getBeepPackageWithId(pack.get('beepReference'), pack.get('beepType'), function (beepPackage) {
				if (beepPackage) {
					currentAltenative.checkPackageDependency(beepPackage, function (needCopy) {
						var nextPhaseModel = self.get('nextPhase');
						if (needCopy && nextPhaseModel) {
							var copyAlternative = nextPhaseModel.get('master');
							beepPackage.copyToAlternative(currentAltenative, copyAlternative, function (newPack) {
								packageHandlerCallback();
							}, true);
						} else {
							var beepScenarios = beepPackage.get('scenario');
							function removeScenarios(nextPhase) {
								if (nextPhase) {
									var phaseSearch = true;
									for (var i = 0; i < nextPhase.get('phaseAlternative').length; i++) {
										var designPart = nextPhase.get('phaseAlternative').at(i).get('phaseDesignPart');
										var pacExists = designPart.findWhere({ 'beepReference': beepPackage.get('id') });
										if (pacExists) {
											for (var j = 0; j < beepScenarios.length; j++) {
												var scenarioProxy = nextPhase.get('phaseAlternative').at(i).get('designScenario').findWhere({ 'scenario': beepScenarios.at(j).get("id") });
												if (scenarioProxy) {
													beepScenarios.at(j).destroy();
													scenarioProxy.destroy();
													break;
												}
											}
											pacExists.destroy();
										}
										else {
											if (nextPhase.get('phaseAlternative').at(i) === nextPhase.get('primary')) {
												phaseSearch = false;
											}
											break;
										}
									}
									nextPhaseModel = nextPhase.get('nextPhase');
									if (phaseSearch && nextPhaseModel) {
										removeScenarios(nextPhaseModel);
									}
								}
							}
							removeScenarios(nextPhaseModel);
							packageHandlerCallback();
						}
					});
				}
				else {
					packageHandlerCallback();
				}
			});
		}, function (err) {
			packageArray.length = 0;
			var packagesColl = newPrimary.fetchNonCommonPackagesFromAlternative();
			async.eachSeries(packagesColl.models, function (newPack, handleCallback) {
				currentAltenative.getBeepPackageWithId(newPack.get('beepReference'), newPack.get('beepType'), function (beepPackage) {
					if (beepPackage) {
						beepPackage.referPackageToNextPhases(newPrimary);
						handleCallback();
					}
					else {
						handleCallback();
					}
				});

			}, function (err) {
				callback(newPrimary.id);
			});
		});
	};
	getAlternativesWithReferences(alt){
		var ret = new Backbone.Collection();
		var phase = this;
		var nextPhase = phase.get('nextPhase');
		var altId = alt.get('id');
		var altRepId = altId.substr(0,altId.lastIndexOf('@')+1);
		while(nextPhase){
			var nextAlternatives = nextPhase.get('phaseAlternative');
			for(var i=0;i<nextAlternatives.length;i++){
				var nextAlt = nextAlternatives.at(i);
				var packRefs = nextAlt.get('phaseDesignPart');
				for(var j=0;j<packRefs.length;j++){
					var packRef = packRefs.at(j);
					var packRefId = packRef.get('beepReference');
					var packRefRepId = packRefId.substr(0,packRefId.lastIndexOf('@')+1);
					if(packRefRepId === altRepId){
						ret.add(nextAlt);
					}
				}
			}
			nextPhase = nextPhase.get('nextPhase');
		}
		return ret;		
	};
	
	createTicketForUsePrevious(callback){
		var self = this;
		var nextPhase = self.get('nextPhase');
		if(nextPhase) {
			var plan = self.getNestedParent();
			var email = DataManager.getDataManager().get('email');
			var user = plan.get('user').findWhere({'email':email});
			var previousWorkspace = DataManager.getDataManager().getWorkspace();
			async.eachSeries(nextPhase.get('phaseAlternative').models,function(alt,altCallback){
				alt.getUsePreviousRel(function(usePreviousList){
					if(usePreviousList.length > 0){
						DataManager.getDataManager().getWorkspaceDataWithId(alt.id,function(wsData){
							DataManager.getDataManager().setWorkspace(wsData.get('workspace'),function(){
								_.each(usePreviousList,function(rel){
									var text = 'Use Previous Aggregation lost in '+ alt.getParent().get('name') + '/'+alt.get('name') + ' :\n'+ rel.get('from').get('measuredCharacteristic').getParent().get('name') + ' -> ' + rel.get('to').get('measuredCharacteristic').getParent().get('name');
									var rescaled = rel.get('definition')?rel.get('definition').get('rescaledMeasure'):null;
									if(rescaled){
										text = text.concat('\n');
										text = text.concat('Multiplier: '+ rescaled.get('multiplier') + ' offset: '+ rescaled.get('offset'));
										if(rescaled.get('operation')){
											text = text.concat('\n');
											text = text.concat('operation: '+rescaled.get('operation').get('name'));
										}
									}
									var ticketOwner = plan;
									var referredElement = rel.get('from').get('measuredCharacteristic').getParent();
									if(referredElement){
										ticketOwner = referredElement.getNestedParent();
									}
									var Ticket = Backbone.Relational.store.getObjectByName('tickets.Ticket');
									var assignedTicket = new Ticket({
										'name':'Use Previous Value Ticket',
										'description':text,
										'ticketType':DataManager.getDataManager().buildAppNsPath("tickets.TicketType","version1")['Validation'],
										'referredElement':referredElement,
										'ticketOwner':ticketOwner,
										'reporter':user
									});
									ticketOwner.get('assignedTicket').add(assignedTicket);
								});
								altCallback();
							});
						});
					}else {
						altCallback();
					}
				});
			},function(err){
				DataManager.getDataManager().setWorkspace(previousWorkspace, function () {      		   
					callback();
				});
			});
		}else {
			callback();
		}
	}
	deletePhase(callback){
		var self = this;
		self.createTicketForUsePrevious(function(){
			function setNextPrevLinks(){
				var prevPhase = self.get('previousPhase');
				var nextPhase = self.get('nextPhase');
				if(prevPhase){
					prevPhase.set('nextPhase',nextPhase);
				}
				if(nextPhase){
					nextPhase.set('previousPhase',prevPhase);
				}
				if(self.getParent().get('asIs') == self) {
					self.getParent().set('asIs',null);
				}
				if(self.getParent().get('goal') == self) {
					self.getParent().set('goal',null);
				}
				self.destroy();
				callback && callback();
			}
			function deletePrimaryAndMasterAlt(){
				if(self.get('primary')){
					self.get('primary').copyLinkedPackages(self, function(){
						self.deleteAlternative(self.get('primary'),function(){
							if(self.get('master')){
								self.deleteAlternative(self.get('master'),setNextPrevLinks);
							}else{
								setNextPrevLinks();
							}
						});
					});
				} else {
					setNextPrevLinks();
				}
			}
		
			function deleteOtherAlts(){
				var alternativesColl = self.get('phaseAlternative').models.concat();
				async.eachSeries(alternativesColl, function(alternative, handlerCallBack){
					if(alternative != self.get('master') && alternative != self.get('primary')) {
						self.deleteAlternative(alternative,handlerCallBack);
					}else {
						handlerCallBack();
					}
				},function(err){
					deletePrimaryAndMasterAlt();
				});
			}
			deleteOtherAlts();	
		})
	};
	
	
	handlePhaseNameChange(model,newName){
		var alternatives = this.get('phaseAlternative').models;
		_.each(alternatives, function(alt){
			alt.handleAlternativeNameChange(alt,alt.get('name'));
			//callback();
		});
	};
	deleteAlternative(alternative,callback){
		var self = this;
		// var refAlts = alternative.getAlternativesWhereICanBeReffered();
		var refAlts = alternative.getClonedAltsWhereICanBeReffered();
		function removeAlternativeReferencedPackage(alternative){
			var currentAltId = alternative.get("id");
			_.each(refAlts,function(refAlt){
				var packagesColl = refAlt.fetchNonCommonPackagesFromAlternative().models.concat();
				_.each(packagesColl,function(pack){
					var packId = pack.get('beepReference');
					//var altDesignParts = pack.get('phaseDesignPartOwner').get('phaseDesignPart');
					var altId = packId.substr(0,packId.lastIndexOf('@') + 1);
					if(currentAltId === altId){
						//altDesignParts.remove(pack);
						pack.destroy();
					}
				});
				
			});	
		}
		var altRDFModel = DataManager.getDataManager().getRDFModel(alternative.get('id'));
		if(altRDFModel){
			altRDFModel.suppressUpdates = true;				
		}
		var preAltId = alternative.get('clonedFrom');
		var derviedAlt = preAltId ? window.utils.getElementModel(preAltId,['transformation.Alternative']):null;
		if(derviedAlt){
			_.each(refAlts,function(refAlt){
				if(refAlt.get('clonedFrom') == alternative.id){
					refAlt.set('clonedFrom',derviedAlt.id);
				}
			});
		}
		alternative.destroyScenariosAndPackages(function(){
			removeAlternativeReferencedPackage(alternative);
			setTimeout(function(){
				self.nukeAlternative(alternative,callback);	
			},10);
		});
	};
	
	nukeAlternative(alternative,callback){
	
		var planScenario = DataManager.getDataManager().altScenarios?DataManager.getDataManager().altScenarios[alternative.getNestedParent().id]:null;
		if(planScenario){
			delete planScenario[alternative.id];
		}
		
		DataManager.getDataManager().getWorkspaceDataWithId(alternative.get("id"), function(wsData){
			DataManager.getDataManager().unRegisterLastChangeset(wsData);
			DataManager.getDataManager().nuke(wsData,function(){
				alternative.destroy();
				DataManager.getDataManager().get('changedParentObjects').reset();
				DataManager.getDataManager().get('changedObjects').reset();
				if(callback){
					callback();
				}
			}); 
		});
	};	
	
	copyPlanScenario(currentAltModel,measurementsCopied,callback){
		var self = this;
		var plan = self.get('phaseOwner');
		var previousPhase = self.get('previousPhase');
		var previousPhasePrimary = previousPhase?previousPhase.get('primary'):null;
		var previousPhaseScenario = previousPhasePrimary?previousPhasePrimary.get('phaseObjectiveSet'):null;
		if(currentAltModel && self.get('master') !== currentAltModel){
			previousPhasePrimary = self.get('master');
		    if(previousPhaseScenario) {
		    	var altScenario = currentAltModel.getOrCreatePhaseObjectiveSet();
			    previousPhaseScenario.copyData(altScenario,false,false,false,measurementsCopied);
			    plan.copyMeasurements(measurementsCopied,callback);
			    return;
		    }else {
		    	plan.copyMeasurements(measurementsCopied,callback);
		    	return;
		    }
		    
		}
		if(previousPhaseScenario) {
			if(currentAltModel && self.get('master')) {
				var altScenario = currentAltModel.getOrCreatePhaseObjectiveSet();
				previousPhaseScenario.copyData(altScenario,false,false,false,measurementsCopied);
				plan.copyMeasurements(measurementsCopied,callback);
				return;
			}else {//not sure might be useful in future
				var phaseAlts = self.get('phaseAlternative').models.concat();
				async.eachSeries(phaseAlts, function(alt, handleCallback){
					//for(var i=0;i<phaseAlts.length;i++){
						var presScenario = alt.getOrCreatePhaseObjectiveSet();
						previousPhaseScenario.copyData(presScenario,false,false,false,measurementsCopied);
						plan.copyMeasurements(measurementsCopied,handleCallback);
					//}
				}, function(err){
					callback();
				});	
			}
		} else {
			callback();
		}
		
	};
	getBackboneModel(){
		var phase = this;
		var phaseModel = new Backbone.Model({id:phase.id,name:phase.get('name'),description:phase.get('description'),phaseOwner:phase.get('phaseOwner').id,status:phase.get('status')});
		var nextPhase = phase.get('nextPhase');
		var previousPhase = phase.get('previousPhase');
		if(nextPhase){
			phaseModel.set('nextPhase',nextPhase.id);
		}
		if(previousPhase){
			phaseModel.set('previousPhase',previousPhase.id);
		}
		var alts = new Backbone.Collection();
		phase.get('phaseAlternative').each(function(alt){
			var altModel = new Backbone.Model({id:alt.id,name:alt.get('name'),description:alt.get('description'),purpose:alt.get('purpose'),phaseAlternativeOwner:phaseModel.id});
			altModel.phaseAlternativeOwner = phase;
			alts.add(altModel);
		});
		phaseModel.set('phaseAlternative',alts);		
		return phaseModel;
	}
//#endCustomMethods	        
	
	static getDialogViewProperties(type){
		if(type=="PhaseDetails")
		{
			return {
				templatePath : "views/transformation/views/properties/PhaseDetailsTemplate.html",
				templateName : "PhaseDetailsTemplate",
				viewTypeStr : "appviews/transformation/views/properties/PhaseDetailsViewModel",
				tabId : "PhaseDetailsView",
				tabName: "Phase"
			}
		} else {
			return {
				templatePath : "views/transformation/views/properties/PhasePropertiesTemplate.html",
				templateName : "PhasePropertiesTemplate",
				viewTypeStr : "appviews/transformation/views/properties/PhaseViewModel",
				tabId : "PhaseView",
				tabName: "Phase"
			}
		}
		
	}
	
		//path.PhaseMixin = PhaseMixin;
		//return PhaseMixin;
	}
	path.PhaseMixin = PhaseMixin;
