import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as Highcharts from 'highcharts';
import * as async from 'async'
//import {select, selectAll} from 'd3-selection';
//import {scaleOrdinal, scaleLinear} from 'd3-scale';
import {DateFormat} from '../../../../../com/vbee/utils/DateFormat'
//import {RadarChart} from '../../../../../../libs/d3/RadarChart'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import { ValueElementMixin } from '../../../../../version1/bo/vdml/ValueElementMixin'
import { DashboardMixin } from '../../../../../version1/bo/dashboard/DashboardMixin'
//import { Dashboard } from '../../../../../version1/bo/dashboard/Dashboard'
import { Preference } from '../../../../../version1/bo/preference/Preference'
import { UserPreferences } from '../../../../../version1/bo/preference/UserPreferences'
//import { PackageReference } from '../../../../../version1/bo/transformation/PackageReference'
import { CodeContainer } from '../../../../../version1/bo/beeppackage/CodeContainer'
//import { Analytics } from '../../../../../version1/bo/dashboard/Analytics'
//const d3 = {select, selectAll, scaleOrdinal, scaleLinear}
import more from 'highcharts/highcharts-more';
more(Highcharts);

/*define(["require", "jquery", "jstree", "underscore",  "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async","appcommon/com/vbee/data/DataManager", "appbo/beeppackage/CodeContainer", "app/global", "appbo/preference/UserPreferences", "appbo/preference/Preference", "d3","RadarChart", "appbo/vdml/ValueElementMixin", "moment","appcommon/com/vbee/utils/DateFormat","summernote", "appbo/dashboard/DashboardMixin", "highcharts-common","d3-legend", "bootstrap-multiselect", "jquery.DataTable", "dataTable.PageResize", "dataTable.ColReorderWithResize",  "tick-positioner"],
function (require, $, jstree, _,  Backbone, ko, koMapping, kb, bootbox, async, DataManager, CodeContainer, global, UserPreferences, Preference, d3,RadarChart,ValueElementMixin,moment, DateFormat, summernote, DashboardMixin, Highcharts
) {*/
    
    var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart",global.version);

    export class DashboardValuesChart {
        constructor(model,options){
			this.init(model,options);
		}
        refreshChart(){
			var self = this;
        	self.getValueMeasurements(function(){
				self.plotAccordingToChoices(true);
				//window.vdmModelView.setResizeHeight();
				window.utils.stopSpinner('dashboardSpinner');
		    });
        };
    	resizeChartWithGridResize(resize){
			var self = this;
    		//var selectedChartType = self.selectedChartType();
    		//if(resize){   
    			//$('#view'+self.encodeId).outerHeight($('#view'+ self.encodeId).parent().height());
    			var totalHeight = $('#view'+ self.encodeId).parent().height();
				var detailsLegendHeight = $('#view'+ self.encodeId + ' legend').outerHeight(true);
				var legendHeight = $('#legend'+ self.encodeId).is(':visible')? $('#legend'+ self.encodeId).outerHeight(true) : 0;
				var altLegendHeight = $('#altLegend'+ self.encodeId).is(':visible')? $('#altLegend'+ self.encodeId).outerHeight(true) : 0;
				var placeHolderHeight = totalHeight - detailsLegendHeight - legendHeight - altLegendHeight;
				/*var minHeightOfPlaceHolder = 150; //change it if the min-height is changed from 150
				if(placeHolderHeight < minHeightOfPlaceHolder && selectedChartType === 'Table'){
					$('#view'+self.encodeId).css('min-height', minHeightOfPlaceHolder + detailsLegendHeight + legendHeight + altLegendHeight + 50);//50 is the minheight of chart-container
					$('#view'+self.encodeId).parent().css('min-height', minHeightOfPlaceHolder + detailsLegendHeight + legendHeight + altLegendHeight + 50);
				}else{*/
					$('#js-canvas'+self.encodeId).outerHeight(placeHolderHeight);
				//}
				/*if(selectedChartType!== 'Table' && selectedChartType !== 'Polar' && selectedChartType !== 'Spiderweb'){
					$.each(Highcharts.charts, function(i, highChart){
			        	if(highChart && highChart.series.length!== 0){
					    	var highChartCont = $(highChart.container).parent();
						    highChart.setSize(highChartCont.width(), highChartCont.height());
						    highChart.hasUserSize = undefined;
					    }
			        });
				}*/
    		//}
    		//$("#placeholder"+self.encodeId).css('min-width','10px');
    	};
    	initializePresentation(refresh){
			var self = this;
    		if(!self.model){
    			return;
    		}
			if(refresh && self.plot){
				//self.plot.reflow(false);
				//self.resizeChartWithGridResize(true);
				self.plot.setSize(null,null);
				//self.plot.redraw();
				return;
			}
	        var plan = DataManager.getDataManager().get('currentPlan');
	        //$('.chart-container' + self.encodeId).css('height', '450px');
	        self.preference = JSON.parse(self.model.get('chartConfig'));
			if(!self.preference){
				return;
			}
	        var selectedChartType = self.selectedChartType();
	        self.chartInitialized = true;
			self.savedPreference = false;
			//var preference;
			var xAxisData;
			//var instance;
			//if (!refresh) {
		        if(self.preference['annotations']){
		        	var annotationsText = self.preference['annotations'];
		        	if(annotationsText && annotationsText.trim() !== ''){
		        		$('#annotations'+self.encodeId).val(annotationsText);
		        		//self.annotationNonEmpty(true);
		        	}			        	
		        }
		        $("#chartConfig" + self.encodeId).hide();
			    
			/*}else{
				instance = $.jstree.reference("#xaxis" + self.encodeId);	
			}*/
			xAxisData = self.getXAxisData(refresh);
			var yaxisNode = $("#yaxis" + self.encodeId);
			//var yaxis1Node = $("#yaxis1");
			//var yaxis2Node = $("#yaxis2");
			//var xaxisNode = $("#xaxis" + self.encodeId);
			var reDraw = false;
			self.showBars = selectedChartType === "Bars" || selectedChartType === "Stacked bars" || selectedChartType === "Columns" || selectedChartType === "Stacked columns"? true : false;
			self.maxUnitsPermitted = selectedChartType === "Stacked bars" || selectedChartType === "Stacked columns"? 1: 2;
			self.phaseIdsChronologicalList = [];
			var phase = plan.get('phase').findWhere({previousPhase:null});
			while(phase){
				self.phaseIdsChronologicalList.push(phase.id);
				phase = phase.get('nextPhase');
			}
			this.alts = self.getAlternatives();
    		if(selectedChartType === "Table"){
            	$('#groupByRow' + self.encodeId).hide();
  				$('#alignScales' + self.encodeId).hide();
            	$('#units' + self.encodeId).hide();
				$('#showValues' +self.encodeId).hide();
            	$("#groupBy" + self.encodeId + " option[value='Time']").removeAttr('disabled');
                //$('#printChart'+self.encodeId).hide();
            }else if(selectedChartType === "Radar" || selectedChartType === "Spiderweb" || selectedChartType === "Polar"){
            	$("#groupBy" + self.encodeId + " option[value='Time']").attr('disabled','disabled');
  				$('#groupByRow' + self.encodeId).show();
  				$('#alignScales' + self.encodeId).hide();
  				$('#units' + self.encodeId).hide();
				if(selectedChartType === "Radar"){
				        $('#showValues' +self.encodeId).show();
                }
  				else{
				        $('#showValues' +self.encodeId).show();
                }
            }else{
            	$('#groupByRow' + self.encodeId).show();
  				$('#alignScales' + self.encodeId).show();
            	$("#groupBy" + self.encodeId + " option[value='Time']").removeAttr('disabled');
  				$('#units' + self.encodeId).show();
				$('#showValues' +self.encodeId).show();
  			}
			/*$("#chartTooltip").css({
				position: "absolute",
				display: "none",
				border: "1px solid #fdd",
				padding: "2px",
				"background-color": "#fee",
				opacity: 0.80,
				"z-index": 9999
			});	
			$("#labelTooltip").css({
				position: "absolute",
				display: "none",
				border: "1px solid #fdd",
				padding: "2px",
				"background-color": "#fee",
				opacity: 0.80,
				"z-index": 9999
			});
			xaxisNode.jstree({
				'core' : {'data' : xAxisData,'themes':{"icons":false,"dots":false}},
				"checkbox" : {
				"three_state" : true
				},		
			"plugins" : [ "wholerow", "checkbox" ]
			}).on("changed.jstree", function (e, data) {
				if(reDraw === true){
					self.xAxisSelected = data.selected;
					showValues(self.values);
					reDraw = false;				
				}else{
					self.xAxisSelected = data.selected;
				}
			}).on("select_node.jstree", function (e, data) {
				var currentNode = data.node.id;
				var parentNode = data.node.parent;
				if(parentNode === '#'){
					self.showBars = true;
					var phases = plan.get('phase');
					var deSelectedNodes = 0;
					phases.each(function(phase){
						if(phase.get('id') !== currentNode){
							data.instance.deselect_node(phase.get('id'));
							deSelectedNodes++;
							var phaseAlts = phase.get('phaseAlternative');
							phaseAlts.each(function(alt){
								data.instance.deselect_node(alt.get('id'));
							});
						}
					});
					if(deSelectedNodes >= 0){
						reDraw = true;
					}
				} else {
					var parentPhase = plan.get('phase').get(parentNode);
					if (parentPhase) {
						var children = data.instance.get_node(parentNode).children;
						_.each(children, function (childNode) {
							if (childNode !== currentNode) {
								data.instance.deselect_node(childNode);
							}
						});
						reDraw = true;
					}
					var phases = plan.get('phase');
					phases.each(function(phase){
						var selectedNodes = 0;
						if (phase.get('id') !== parentPhase) {

							var children = data.instance.get_node(phase.get('id')).children;
							_.each(children, function (childNode) {
								if (data.instance.is_selected(childNode)) {
									if (selectedNodes > 0) {
										data.instance.deselect_node(childNode);
									}
									selectedNodes++;
									//self.showBars = false;
								}
							});
						}
					});
					
					
				}
			}).on("deselect_node.jstree", function (e, data) {
				reDraw = true;
			}).on("open_node.jstree", function (e, data) {
				if(self.parentView){
					window.vdmModelView.setResizeHeight();
				}
			}).on("close_node.jstree", function (e, data) {
				if(self.parentView){
					window.vdmModelView.setResizeHeight();
				}
			});	*/		
			/*if(self.parentView && self.parentView.setResizeHeight){
				self.parentView.setResizeHeight();
			}*/			
							
			if(refresh){
				this.values = [];
			}
			//self.getPlanValues(self.model);
			showValues(self.preference,!refresh);
			
			function showValues(preference,initializeMultiselect){
				self.getValueMeasurements(function(){
					if(!self.preference){
						return;
					}
					if(initializeMultiselect){
						$('#yaxis' + self.encodeId).multiselect({
			            	onDropdownShow:function(){
								$('.multiselect-container').css({ 'left': "auto", 'right': "0"  });
			            	},
							optionClass: function () {
								return 'chartLiWidth';
							},
							numberDisplayed : 0,
			            	//includeSelectAllOption: true,
							onDropdownShown : function(){
								if(self.parentView){
									window.vdmModelView.setResizeHeight();
								}
							},
							onDropdownHidden : function(){
								if(self.parentView){
									window.vdmModelView.setResizeHeight();
								}
							},
			            	onChange: function(option, checked) {
			            		var checkedAtr = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:checked");
			            		var uncheckedAtr =	$('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:not(:checked)");
			            		if(checkedAtr.length >= self.maxUnitsPermitted){
			            			uncheckedAtr.attr("disabled", true);
			            			uncheckedAtr.parent().attr("disabled", true);
			            			uncheckedAtr.parent().css('cursor',"no-drop");
			            		}else{
			            			uncheckedAtr.attr("disabled", false);
			            			uncheckedAtr.parent().attr("disabled", false);
			            			uncheckedAtr.parent().css('cursor',"pointer");
			            		}
			            		var checkedArr = [];
			            		for(var i=0;i<checkedAtr.length;i++){
			            			checkedArr[i]=checkedAtr[i].value;
			            		}
			            		self.preference.yAxisSelected = checkedArr;
			            		self.model.set('chartConfig', JSON.stringify(self.preference));
								self.getValueMeasurements(function(){
									self.plotAccordingToChoices(true);
								});
							}
			        	});
					}
					var i = 0;						
					var selectedUnits = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:checked");
					var oldSelectedUnits = [];
					_.each(selectedUnits,function(valueOption){
						oldSelectedUnits.push({value:valueOption.value});
					});
					yaxisNode.empty();
					//yaxis1Node.empty();
					//yaxis2Node.empty();
					var count =0;
					var count1Key;
					//yaxis2Node.append("<option value=''>Empty</option>");
					//sorting object key value pair
					var values = [];
					$.each(self.datasets, function(key, val) {
						values.push({'key':key, 'value':val});	
					});
					
					values.sort(function(a,b){
						if(a.value.valueName.toLowerCase() < b.value.valueName.toLowerCase()){
							return -1;
						}
						else if(a.value.valueName.toLowerCase() > b.value.valueName.toLowerCase()){
							return 1;
						}
						return 0;					
					});
					self.datasets = {};
					
	                for(var i=0; i < values.length; i++){
	                	 self.datasets[values[i].key] = values[i].value;
	                }
	                $.each(self.datasets, function(key, val) {
	                	if (val.unit.indexOf('Grade only') != -1) {
				            return;
				        }
						var showValue = false;
						
						for(var i=0;i<val.data.length;i++){
							if(selectedChartType !== "Table"){
								if(!self.groupByScenario && self.showBars && isNaN(val.data[i][0]) && val.data[i][0].indexOf('Ph') > -1){
									var replacedString = val.data[i][0].replace('Ph','Alt');
									val.data[i][0] = replacedString;
								}
							}
							if(val.data[i][1] != null){
								showValue = true;
								break;
							}
						}
						if(showValue) {
						    var multiselectNode = $('#yaxis' + self.encodeId);
						    var unitString = window.utils.restoreEscapeCharacters(val.unit);
							var unitOptionGroupNode = multiselectNode.children('option[label="' + unitString + '"]');
							if(unitOptionGroupNode.length === 0){
								multiselectNode.append('<option' + ' label="' +  unitString +'">'+ unitString + '</option>');
								unitOptionGroupNode = multiselectNode.children('option[label="' + unitString + '"]');
							}
							if(count === 1){
								count1Key = key;
							}
							count++;
						}
					});
					$('#yaxis' + self.encodeId).multiselect('rebuild');
					if(oldSelectedUnits.length>0){
						for(i=0;i<oldSelectedUnits.length;i++){
							var label = window.utils.restoreEscapeCharacters(oldSelectedUnits[i].value);
						    if ($('#yaxis' + self.encodeId)[0].children) {
						        $('#yaxis' + self.encodeId).multiselect('select', label);
							}
						}
						// if they still remain unchecked because the oldunits and units available are completely different sets, check
						// if they are unchecked and check them manually
						var checked = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:checked");
				        if(checked.length === 0 && !self.preference.yAxisSelected){
				        	var defaultUnitsArray = [];
							var units = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input");
							if(units.length>0){
								defaultUnitsArray.push(units[0].value);
							}
							if(units.length>1 && self.maxUnitsPermitted !== 1){
								defaultUnitsArray.push(units[1].value);
							}
							self.preference.yAxisSelected = defaultUnitsArray;
				        }
					}else if(!self.preference.yAxisSelected){
						var defaultUnitsArray = [];
						var units = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input");
						if(units.length>0){
							defaultUnitsArray.push(units[0].value);
						}
						if(units.length>1 && self.maxUnitsPermitted !== 1){
							defaultUnitsArray.push(units[1].value);
						}
						self.preference.yAxisSelected = defaultUnitsArray;
					}
					if(self.preference.yAxisSelected){
						for(var i=0;i<self.preference.yAxisSelected.length;i++){
							label = window.utils.restoreEscapeCharacters(self.preference.yAxisSelected[i]);
						    if ($('#yaxis' + self.encodeId)[0] && $('#yaxis' + self.encodeId)[0].children) {
						        $('#yaxis' + self.encodeId).multiselect('select', label);
							}
						}
					}
					var checkedAtr = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:checked");
			        var uncheckedAtr =	$('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:not(:checked)");
	        		if(checkedAtr.length >= self.maxUnitsPermitted){
	        			uncheckedAtr.attr("disabled", true);
	        			uncheckedAtr.parent().attr("disabled", true);
	        			uncheckedAtr.parent().css('cursor',"no-drop");
	        		}else{
	        			uncheckedAtr.attr("disabled", false);
	        			uncheckedAtr.parent().attr("disabled", false);
	        			uncheckedAtr.parent().css('cursor',"pointer");
			        }
					self.plotAccordingToChoices(true);
				});
			}
			$(".multiselect").parent().css('margin-left','4%');
			$('.multiselect').css({'font-size':'14px','text-align':'left', 'width':'143px','height':'30px','border-radius':'4px','cursor':'pointer','line-height':'8px','padding-right': '5px'});
			$('.multiselect').find('.caret').css({ 'float': 'right', 'border-right': '3px solid transparent', 'border-left': '3px solid transparent', 'border-top': '7px dashed'});
			$('#dropdown'+self.encodeId).unbind('click');
			$('#dropdown'+self.encodeId).on('click', function(){
		    	event.stopPropagation();
	       		$("#dropdownMenu"+self.encodeId).toggle();
				$("#presWrapper"+self.encodeId).css('overflow','visible')
				if(self.groupByTime){
				   self.timeBars(true);
				   var actualMinValue = self.selectedCalculatedPhaseDates[0];
			       var actualMaxValue = self.selectedCalculatedPhaseDates[self.selectedCalculatedPhaseDates.length-1];
			       $("#minimumDatepicker"+self.encodeId).datepicker();
	               $("#maximumDatepicker"+self.encodeId).datepicker();
			       $("#minimumDatepicker"+self.encodeId).datepicker('option', {minDate: new Date(actualMinValue), maxDate: new Date(actualMaxValue)});
	    	       $("#maximumDatepicker"+self.encodeId).datepicker('option', {minDate: new Date(actualMinValue), maxDate: new Date(actualMaxValue)});
				}
	       	});
			$('#splitPanels').unbind('click');
			$('#splitPanels').on('click', function (e) {
			    if (!e.target.closest('.dropdown-menu-right') && !(e.target.id ==='dropdown'+self.encodeId) && !e.target.closest('.ui-datepicker-header') && !e.target.closest('.ui-datepicker')){
			        $(".dropdown-menu-right").hide();
			    }
			});
		};
  
//#startCustomMethods    
    showConfig() {
        var self = this;
        if ($('#chartConfig' + self.encodeId).is(":visible")) {
            $("#chartConfig" + self.encodeId).hide();
        } else {
            $("#chartConfig" + self.encodeId).show();
        }
        if (self.parentView) {
            window.vdmModelView.setResizeHeight();
        }
    }
	dispose(){
		var self = this;
		if(!this.model){
			return;
		}
		function cleanViewData(){
			if(self.values){
				self.values.length = 0;
			} 
			self.datasets = null;
			if(self.xAxisSelected){
				self.xAxisSelected.length = 0;	
			}
			window.cleanViewModel(self);
		}		
		if(self.plot){
			//self.plot.shutdown();
        }
        if (self.dataTable) {
            self.dataTable.destroy(true);
        }
        $.each(Highcharts.charts, function(i, chart){
        	if(chart != undefined){
        		chart.destroy();
        	}
        });
        Highcharts.charts.length = 0;
        //d3.select("#placeholder" + self.encodeId + ' svg').remove();
		$("#xaxis"+self.encodeId).jstree('destroy');
		$(window,".resize").unbind('resize');
        /*var codeContainerKeys = Object.getOwnPropertyNames(self.codeContainerList);
        _.each(codeContainerKeys, function (key) {
            var codeContainer = self.codeContainerList[key];
            codeContainer.save(null,{
                success: function () {
                    Backbone.Relational.store.unregister(codeContainer);
                }
            });
        });*/
		self.model = null;
        cleanViewData();
	};
	toggleFullScreen(data, event){
		var self = this;
		var parentGrid = $('#view'+data.encodeId).parent();
		// if(!parentGrid.hasClass('viewFullScreen')){
		// 	self.normalViewHeight = parentGrid.height();
		// }
		// $('#toggleFullScreen'+data.encodeId).toggleClass('glyphicon glyphicon-resize-full glyphicon glyphicon-resize-small');	
		if (document.fullscreenElement) {
			document.exitFullscreen();
		}else{
			//parentGrid.resizable( "disable" );
			// parentGrid.height(window.innerHeight - 32);
			// $('#view'+data.encodeId).outerHeight(window.innerHeight - 32);
			parentGrid.get(0).requestFullscreen();
		}
		// parentGrid.toggleClass('viewFullScreen');
		// self.plotAccordingToChoices(true);
		// if(self.parentView){ //grids gets adjusted when returned from full screen view
		// 	window.vdmModelView.setResizeHeight();
		// }
	};
	viewAnnotation(){        
        var self = this;
        var config = JSON.parse(self.model.get('chartConfig'));
        var annotation = config['annotations'];
    	var dataGuid = DataManager.getDataManager().guidGenerator();
    	var htmlContent = "<div id='summernote"+self.encodeId+"'+></div>";
    	const box = bootbox.dialog({
            title: '  &nbsp;Annotation',
            message: htmlContent,
			backdrop:false,
            buttons: {
                main: {
                    label: "Close",
                    className: "btn btn-default pull-left",
                },
                edit: {
                    label: "Edit",
                    className: "annotationEdit btn btn-default",
                    callback: function(){
                    	initializeSummernote(false);
                    	$('.annotationComplete').show();
                    	$('.annotationEdit').hide();
                    	return false;
                    }
                },
                success: {
                    label: "Complete",
                    className: "annotationComplete btn btn-default",
                    callback: function(){
                    	var summernote = $('#summernote'+self.encodeId);
                    	var contentData = summernote.summernote('code');	
						var contentJQuery = $(contentData);
						contentData = "";
						var canvasImgs = contentJQuery.find('img').each(function () {
						    if (this.getAttribute('diagramId')) {
						        this.src = "";
						    }
						});
						contentJQuery.each(function () {
						    contentData = contentData + this.outerHTML;
						});
						if(contentJQuery.length == 0){
							contentData = summernote.summernote('code');
						}
                    	config['annotations'] = contentData;
                    	self.model.set('chartConfig', JSON.stringify(config));
                    }
                }
            }
        });
		if(document.fullscreenElement){
			box.appendTo(`#${document.fullscreenElement.id}`)
		  }else{
			box.show();
		  } 
        function initializeSummernote(airMode){
        	var summernote = $('#summernote'+self.encodeId);
        	summernote.summernote('destroy');
	    	summernote.summernote(
			{
				airMode: airMode,
				dialogsInBody: true,
			  	minHeight: null,             // set minimum height of editor
			  	maxHeight: null,             // set maximum height of editor
			  	focus: true ,
			  	disableResizeEditor: true, //disable resize 
			  	toolbar: [
		  		    ['style', ['bold', 'italic', 'underline', 'clear']],
		  		    ['font', ['fontname','fontsize','color','strikethrough','superscript', 'subscript']],
		  		    ['para', ['style','ul', 'ol', 'paragraph']],
		  		    ['height', ['height']],
		  		    ['insert', ['picture','link','table','hr']]
		  		]
			});
			summernote.find('.note-statusbar').hide();
			if(airMode){
				summernote.summernote('disable');
			}
            summernote.summernote('code', annotation);
            summernote.summernote("editor.clearHistory", 'editor');
			var modalBody = summernote.parents('.modal-body');
			modalBody.css('max-height', '400px');
			modalBody.css('overflow-y', 'auto');
			$("#summernotecss").attr("href", "js/libs/summernote/dist/summernote.css");
	    }
	    initializeSummernote(true);
	    $('.annotationComplete').hide();
	    if(self.model.getNestedParent().id.startsWith(DataManager.getDataManager().get('viewAlternative'))){
	    	$('.annotationEdit').prop('disabled',false);
    	}
    	else{
    		$('.annotationEdit').prop('disabled',true);
    	}
	};

	findUID(id,treeData){
        var self = this
        if (window.utils.htmlEscape(treeData.id) === id) {
          return treeData.uId;
        }
        if (treeData.subRows) {
            for (let child of treeData.subRows) {
                const result = self.findUID(id, child);
                if (result) {
                return result;
                }
            }
        }
        return null;        
    }

	editPresentation(data,event){
		var self = this;
		let treeData;
		if(window.vdmModelView.model.get("treeStructure")){
			treeData = JSON.parse(window.vdmModelView.model.get("treeStructure"))
		}else {
			treeData = {}
		}
		var uId = self.findUID(window.utils.htmlEscape(self.model.id),treeData)
		var newId = window.utils.htmlEscape(self.model.id) + "editchart";
		var presentationView = _.filter(window.vdmModelView.charts(), function(chartView){return chartView.id() === self.model.id;})[0];
		var addOptions = {'chartModel':self.model,uId:uId,treeData:treeData,viewInstance:presentationView.viewInstance, width:'1000px', 'hideDelete':true};
		window.getAndCreateModalDialog(window.vdmModelView,newId,DashboardMixin,window.vdmModelView.model,"CreateChart",null,addOptions);
	};

	printChart(){
		var self = this;
		Highcharts.setOptions({
		    chart: {
		        events: {
		            afterPrint: function () {
		            	self.parentView && window.vdmModelView.setResizeHeight(true);
		                
		            }
		        }
		    }
		});
		this.plot.print();
	};
    generateMetricsCSV() {
        
        var self = this;
        var config = JSON.parse(self.model.get('chartConfig'));
        var filterMC = [];
        for (var i = 0; i < config.selectedValueObjs.length; i++) {
            filterMC.push(config.selectedValueObjs[i].suffixId);
        }
        var filterObs = [];
        for (var i = 0; i < config.selectedObservationObjs.length; i++) {
            filterObs.push(config.selectedObservationObjs[i].timeStamp);
        }
        var filterAlts = [];
        for (var i = 0; i < config.selectedAlternativeObjs.length; i++) {
            filterAlts.push(config.selectedAlternativeObjs[i].id);
        }
    	var dataGuid = DataManager.getDataManager().guidGenerator();
    	var htmlContent = "<div><fieldset class=\"fieldsetClass\"><legend class=\"legendClass\">"+DataManager.getDataManager().get('localeManager').get('Include')+":</legend><input type=\"checkbox\" id='" + dataGuid +"ValueMeasurements' value='Value Measurements' checked style=\"cursor: pointer;\" >&nbsp;&nbsp;<label style=\"cursor: pointer;\" for='" + dataGuid + "ValueMeasurements'>Value Measurements</label><br>";
        htmlContent = htmlContent + "<input type=\"checkbox\" id='" + dataGuid + "RecipientMeasurements' value='Recipient Measurements' style=\"cursor: pointer;\">&nbsp;&nbsp;<label style=\"cursor: pointer;\" for='" + dataGuid + "RecipientMeasurements'>Recipient Measurements</label><br>";
        htmlContent = htmlContent + "<input type=\"checkbox\" id='" + dataGuid + "SatisfactionLevelMeasurements' value='Satisfaction Level Measurements' style=\"cursor: pointer;\">&nbsp;&nbsp;<label style=\"cursor: pointer;\" for='" + dataGuid + "SatisfactionLevelMeasurements'>Satisfaction Level Measurements</label><br>";
        htmlContent = htmlContent + "<input type=\"checkbox\" id='" + dataGuid + "PercentageWeightMeasurements' value='Percentage Weight Measurements' style=\"cursor: pointer;\">&nbsp;&nbsp;<label style=\"cursor: pointer;\" for='" + dataGuid + "PercentageWeightMeasurements'>Percentage Weight Measurements</label></fieldset></div><br>";
        htmlContent = htmlContent + "<div class=\"exportClass row\"><div class=\"col-xs-3\"><label>"+DataManager.getDataManager().get('localeManager').get('selectFormat')+"</label></div><div class=\"col-xs-2\"><select id=\"selectFormat\"><option selected=\"selected\" value=\"csv\">csv</option><option value=\"xlsx\">xlsx</option></select></div></div>";
        const dialog = bootbox.dialog({
            title: '<img class="smallIcon" src="img/icons/icons_15.png">  '+DataManager.getDataManager().get('localeManager').get('exportOptions'),
            message: htmlContent,
            buttons: {
                main: {
                    label: "Close",
                    className: "btn btn-default",
                },
                success: {
                    label: '<i id="generateCompleteBtn" class="fa fa-check"></i> Complete',
                    className: "btn btn-complete",
                    callback: function () {
                        var selectedOptions = $('.fieldsetClass input:checked');
                        var excludeValue = true;
                        var excludeRecipientOpinion = true;
                        var excludeSatisfaction = true;
                        var excludePercentageWeight = true;
                        for (i=0;i<selectedOptions.length;i++) {
                            var val= selectedOptions[i].value;
                            if(val==='Value Measurements'){
                            	excludeValue = false;
                            }else if(val==='Recipient Measurements'){
                            	excludeRecipientOpinion = false;
                            }else if(val==='Satisfaction Level Measurements'){
                            	excludeSatisfaction = false;
                            }else{
                            	excludePercentageWeight=false;
                            }
                        }
                         var e = document.getElementById("selectFormat");
						var selectedFormat = e.options[e.selectedIndex].text;
                        DataManager.getDataManager().get('currentPlan').generateMetricsCSV(filterMC,filterObs,filterAlts,excludeRecipientOpinion,excludePercentageWeight,excludeSatisfaction,excludeValue,selectedFormat);
                    }
                }
            }
        });
		if(document.fullscreenElement){
			dialog.appendTo(`#${document.fullscreenElement.id}`)
		  }else{
			dialog.show();
		  } 
	};

	
	getPreferenceValueToStore() {
	    var self = this;
		var preference = {};
		preference.xAxisSelected = this.xAxisSelected;
		var selectedValues = $("#yaxis" + self.encodeId + " option:selected");
		preference.selectedValues = [];
		_.each(selectedValues,function(valueOption){
			preference.selectedValues.push(valueOption.value);
		});
		preference.selectedChartType = self.selectedChartType();
		return preference;
	};
	getPlanValues(plan){
		var self = this;
		var bms = [];
		var vp = plan.get('planCriterionSet');
		if(vp){
			var components = vp.get('component');
			components.each(function(valueComponent){
				var value = valueComponent.get('valueMeasurement');
				self.values.push(value);
			});
		}
		return self.values;
	};
	
	plotFlotChart(resize) {
		var self = this;
		var selectedOptions = self.preference? self.preference.selectedValueObjs : 0;
		var selectedChartType = self.selectedChartType();
		
		var data = [];
		var yaxis = 1;
		var xAxisObject;
		var placeHolderWidth = $("#placeholder" + self.encodeId).width();
		
		var yaxis1Val;// = yaxis1Node.val();
		var yaxis2Val;// = yaxis2Node.val();
		
		var units = [];
		$.each(self.datasets, function(key, val) {
			units.push(val['unit']);
		});
		$.unique(units);
		units = $("#yaxis" + self.encodeId + " option:selected");
		if(units.length > 0){
		    yaxis1Val =  units[0].label;
		}
		if(units.length > 1 && self.maxUnitsPermitted !== 1){
		    yaxis2Val =  units[1].label;
		}					
		if(yaxis2Val === yaxis1Val){
			yaxis2Val = null;
		}
		
		var values = Object.getOwnPropertyNames(self.datasets);
		var color = 0;
		for(var i=0;i<values.length;i++){
			if (values[i].indexOf('Grade only') != -1 && selectedChartType !== 'Table') {
	            continue;
	        }
			var valueData = self.datasets[values[i]];
			var unit = window.utils.restoreEscapeCharacters(valueData.unit);
			valueData.yAxis = undefined;
			if (selectedChartType !== 'Radar' && selectedChartType !== 'Table' && selectedChartType !== 'Polar' && selectedChartType !== 'Spiderweb') {
			    if (yaxis1Val && yaxis1Val === unit) {
			        //valueData.yAxis = 1;
			        //valueData.color = self.timeBars()? valueData.color : color++;
			        valueData.color = color++;
			        data.push(valueData);
			    }
			    if (yaxis2Val && yaxis2Val === unit) {
			        valueData.yAxis = 1;
			        //valueData.color = self.timeBars()? valueData.color : color++;
			        valueData.color = color++;
			        data.push(valueData);
			    }
			} else {
			    data.push(valueData);
			}
		}
		var chartType;
		    	if(selectedChartType === "Bars" || selectedChartType === "Stacked bars"){
		    		chartType = 'bar';
				}else if(selectedChartType === "Columns" || selectedChartType === "Stacked columns"){
		    		chartType = 'column';
				}else if(selectedChartType === "Curves"){
		    		chartType = 'spline';
				}
		//sorting
		if (data.length > 0) {
		    var placeholder = $("#placeholder" + self.encodeId);
		    data.sort(function(a,b){
		    	if(!a.yaxis){
		    		return 0;
		    	}
		        if(a.yaxis === b.yaxis){
		            if(a.label < b.label){
		                return -1;
		            }
		            if(a.label > b.label){
		                return 1;
		            }
		            return 0;
		        }
		        if(a.yaxis < b.yaxis){
		            return -1;
		        }
		        if(a.yaxis > b.yaxis){
		            return 1;
		        }
		        return 0;
		    });

		    $('#altLegend' + self.encodeId).empty();
		    var altIndexes = Object.getOwnPropertyNames(self.altIndex);
		    _.each(altIndexes,function(key){
		        var altIndexVal = self.altIndex[key];
				var altFound = $.grep(self.preference.selectedAlternativeObjs, function (e) { return e.displayName === key;});
				if (altFound && altFound.length == 0 && self.preference.selectedObjectiveObjs) {
					altFound = $.grep(self.preference.selectedObjectiveObjs, function (e){ return e.displayName === key; });
                }
				if(altFound[0] && altFound[0].label && altFound[0].label!==""){
					if(altFound[0].label && altFound[0].label!==""){
						$('#altLegend' + self.encodeId).append($("<div> "+ altFound[0].label + " : " + key + "</div>"));
					}
				}else{
					if(self.groupByScenario){
		            	var sceLabel = self.changeScenarioLabel(self.preference.selectedObservationObjs[altIndexVal])
						if(sceLabel){
						    $('#altLegend' + self.encodeId).append($("<div>" + sceLabel + " : " + key + "</div>"));
						} else if (altFound[0] && altFound[0].timeStamp) {
							//$('#altLegend' + self.encodeId).append($("<div>Objective : " + "Actual" + "</div>"));
							$('#altLegend' + self.encodeId).append($("<div>" + key + " : " + altIndexVal + "</div>"));
                        }
						else {
						    $('#altLegend' + self.encodeId).append($("<div>Sc-" + altIndexVal + " : " + key + "</div>"));
						}
		        	}else if(self.groupByPhase){
					    var altLabel;
		            	if(altFound[0]){
						   altLabel = self.changeAltLabel(altFound[0])
						}
						if (altLabel) {
							$('#altLegend' + self.encodeId).append($("<div>" + altLabel + " : " + key + "</div>"));
						} else if (altFound[0] && altFound[0].timeStamp) {
							//$('#altLegend' + self.encodeId).append($("<div>Objective : " + "Actual" + "</div>"));
							$('#altLegend' + self.encodeId).append($("<div>" + key + " : " + altIndexVal + "</div>"));
						} else {
                           $('#altLegend' + self.encodeId).append($("<div>Alt-" + altIndexVal + " : " + key + "</div>"));
						} 
					}else{
		         		$('#altLegend' + self.encodeId).hide();
		         	}
				}
		    });
		    
		    if (selectedChartType === 'Table') {
				$('#showLegend' + self.encodeId).hide();
		        self.displayTable(data, resize);
		    } else {
			    var unit1 = undefined, unit2 = undefined, chartData = [];
			    //var selectedCount;
				var selectedObjCount=[];
				//if (self.groupByPhase) {
					var selectedCount = self.preference.selectedAlternativeObjs;
					if (self.preference.selectedObjectiveObjs) {
						//
						for (var i = 0; i < self.preference.selectedObjectiveObjs.length; i++) {
							selectedObjCount.push(self.preference.selectedObjectiveObjs[i])
                        }
					}
					if (!selectedCount) {
						selectedCount = [];
					}
			    /*}else if(self.groupByScenario){
					selectedCount = self.preference.selectedObservationObjs;
					if (self.preference.selectedObjectiveObjs) {
						//var selectedObjCount = [];
						for (var i = 0; i < self.preference.selectedObjectiveObjs.length; i++) {
							selectedCount.push(self.preference.selectedObjectiveObjs[i])
						}
					}
			    }*/
				$.unique(selectedCount);
		    	_.each(data, function(val, i){
		    		var newDataset = {};
		    		newDataset.name = val.label;
		    		if(self.groupByTime){
                        // displaying Date in current system timezone
                        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                        var currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        var date = new Date(
                          new Date(self.implementationDate()).toLocaleString('en-US', {
                            currentTimeZone,
                          }),
                        );
		    			newDataset.tooltip = {
			               //headerFormat: '<b>'+val.label+' </b>{point.x:%b %e, %Y}<br>',
			               headerFormat: "<b>"+val.label+" </b>{point.x:" + monthNames[date.getMonth()] + " " +date.getDate() + ", " +  date.getFullYear() +"}<br>",
			               pointFormat: "Value: {point.y:."+val.significantDecimals+"f} "//("+val.unit + ')'
			            };
		    		}else{
		    			newDataset.tooltip = {
			               headerFormat: '<b>'+val.label+'</b><br>',
			               pointFormat: "Value: {point.y:."+val.significantDecimals+"f} "//("+val.unit + ')'
			            };
		    		}
		    		if(val.yAxis === 1){
		    			unit2 = val.unit;
		    			newDataset.yAxis = val.yAxis;
		    		}else{
		    			unit1 = val.unit;
		    		}
		    		var newDataArray = [];
		    		var altOrScenarioArray = [];
					_.each(val.data, function(valData){
		    			var newData = [];
		    			newData.push(valData[0]);
		    			altOrScenarioArray.push(valData[0]);
		    			newData.push(valData[1] === "-" || valData[1] === "####" ? null : valData[1]);
		    			newDataArray.push(newData);
		    		});
					//if (selectedCount && selectedCount.length > 0) {
						var count = selectedCount.length;
						var altName;
		    			if(self.groupByPhase){
			    			while(count > 0){
							    var altLabel = self.changeAltLabel(selectedCount[count-1]);
								if(altLabel){
									altName = altLabel;
								} else if (selectedCount[count-1] && selectedCount[count-1].timeStamp) {
								    altName = selectedCount[count-1].displayName
								} else if (selectedCount[count - 1] && !selectedCount[count - 1].timeStamp) {
									altName = "Alt-" + (count-1);
                                }
								else {    
								    altName = "Alt-"+(count-1);
								}
		    				    //removing this as this is in each loop as data will come as valId+euro and valId+euro+Timestamp for same value
								/*if(altOrScenarioArray.indexOf(altName) === -1){
		    						var newData = [];
					    			newData.push(altName);
					    			newData.push(null);
					    			newDataArray.push(newData);
		    					}*/
			    				count--;
							}
							if (selectedObjCount && selectedObjCount.length > 0) {
								var objCount = selectedObjCount.length;
								while (objCount > 0) {
									if (selectedObjCount[objCount - 1] && selectedObjCount[objCount - 1].timeStamp) {
										altName = selectedObjCount[objCount - 1].displayName
									} else {
										altName = "obj-" + (objCount - 1);
									}
									if (altOrScenarioArray.indexOf(altName) === -1) {
										var newData = [];
										newData.push(altName);
										newData.push(null);
										newDataArray.push(newData);
									}
									objCount--;
								}
							}
			    		}else if(self.groupByScenario){
			    			while(count > 0){
		    					var scName = self.changeScenarioLabel(selectedCount[count-1]);
							    if (selectedCount[count - 1] && !selectedCount[count - 1].timeStamp) {
									scName = "Sc-" + (count - 1);
							    } else if (!scName) {
									scName = "Sc-" + (count - 1);
								} 
								/*if(altOrScenarioArray.indexOf(scName) === -1){
		    						var newData = [];
					    			newData.push(scName);
					    			newData.push(null);
					    			newDataArray.push(newData);
		    					}*/
			    				count--;
							}
							if (selectedObjCount && selectedObjCount.length > 0) {
								var objCount = selectedObjCount.length;
								while (objCount > 0) {
									if (selectedObjCount[objCount - 1] && selectedObjCount[objCount - 1].timeStamp) {
										altName = selectedObjCount[objCount - 1].displayName
									} else {
										altName = "obj-" + (objCount - 1);
									}
									if (altOrScenarioArray.indexOf(altName) === -1) {
										var newData = [];
										newData.push(altName);
										newData.push(null);
										newDataArray.push(newData);
									}
									objCount--;
								}
							}
			    		}
		    		//}
		    		// newDataArray.sort();
		    		newDataset.data = newDataArray;
		    		chartData.push(newDataset);
		    	})

		    	var xAxisObj = {};
		    	if(self.selectedGroupType() === "Time"){
		    		xAxisObj = {
		    			labels: {
			    			style :{
			            		fontSize: '12px'
			            	},
		            	},
				    	type: 'datetime',
				    	minRange:1
				    }
		    	}else{
		    		xAxisObj = {
				    	tickInterval : 1,
				        labels: {
			    			style :{
			            		fontSize: '12px'
			            	},
				            enabled: true,
				            formatter: function(){ return chartData[0].data[this.value]? chartData[0].data[this.value][0] : '';},
				        },
				        minRange:1
				    }
		    	}
		    	var yaxesObj;
		    	var tickInterval1;
		    	var tickInterval2;
		    	if(units.length > 1){
		    		/*if(!self.alignScales()){
			    		var tickIntervalArray1 = [];
			    		var tickIntervalArray2 = [];
			    		_.each(chartData, function(chartInfo){
			    			var count = 0;
			    			_.each(chartInfo.data, function(data){
			    				if(count == 0){
			    					if(data[1] !== null && data[1]!== 0){
				    					tickIntervalArray1.push(Math.abs(data[1]));
			    					}
			    					count++;
			    				}
			    				else {
			    					if(data[1] !== null && data[1]!== 0){
			    						tickIntervalArray2.push(Math.abs(data[1]));
		    						}
			    				}
			    			});
						});
						tickInterval1 = Math.floor(Math.min.apply(Math,tickIntervalArray1)/5)*5;
						tickInterval2 = Math.floor(Math.min.apply(Math,tickIntervalArray2)/5)*5;
						
						tickInterval1 = tickInterval1 > 5 ? tickInterval1 : 5;
						tickInterval2 = tickInterval2 > 5 ? tickInterval2 : 5;
					}*/
				    yaxesObj = [{
				    	//tickInterval : tickInterval1,
				    	title: {
				            text: unit1
				        },
				    	labels: {
				            format: '{value}',
					        style :{
			            		fontSize: '12px'
			            	}
				        },
        				gridLineWidth: 0

				    },
				    {
				    	//tickInterval : tickInterval2,
				    	title: {
				            text: unit2
				        },
				    	labels: {
				            format: '{value}',
					        style :{
			            		fontSize: '12px'
			            	},
				        },
    					opposite: true
				    }];
				}else{
					yaxesObj = {
						//tickInterval : 5,
				    	title: {
				            text: unit1
				        },
				    	labels: {
				            format: '{value}'
				        },
        				gridLineWidth: 0
				    }
				}
				self.plot = new Highcharts.Chart({
		    		colors: self.chartColors,
					chart: {
                        type: chartType,
                        zoomEnabled: true,
                        renderTo: "placeholder"  + self.encodeId,
                        zoomType: 'xy',
                        resetZoomButton: {
                            position: {
                                align: 'right', // by default
                                verticalAlign: 'top' // by default
                            }
                        }
                    },
		    		title: {
					    text: '',
					    style: {
					        display: 'none'
					    }
					},
					credits: {
						enabled: false
					},
					subtitle: {
					    text: '',
					    style: {
					        display: 'none'
					    }
					},
					legend: {
				        itemStyle: {
				            fontWeight: 'normal'
				        }
				    },
				    yAxis: yaxesObj,
				    xAxis: xAxisObj,
					//alignScales: self.alignScales(),
				    plotOptions: {
				        series: {
				            dataLabels: {
								enabled: self.showValues(),
								allowOverlap:true,
							},
							label: {
				                connectorAllowed: false
				            },
				            stacking: selectedChartType === "Stacked bars" || selectedChartType === "Stacked columns"? 'normal' :  null
				        }
				    },

				    series: chartData,
				    
				    navigation: {
				        buttonOptions: {
				            enabled: false
				        }
				    },

				    responsive: {
				        rules: [{
				            condition: {
				                maxWidth: 500
				            },
				            chartOptions: {
				                legend: {
				                    layout: 'horizontal',
				                    align: 'center',
				                    verticalAlign: 'bottom'
				                }
				            }
				        }]
				    }

				});
			    
				self.resizeChartWithGridResize(resize);
    		}
			data.length = 0;
		}else{
		   // var placeholder = $("#placeholder" + self.encodeId); 
			self.plot = new Highcharts.Chart({
			            colors: self.chartColors,
			            chart :{
						      type : chartType,
                              zoomEnabled: true,
							  renderTo : "placeholder" + self.encodeId,
							  zoomType: 'xy',
							  resetZoomButton: {
									position: {
										align: 'right', // by default
										verticalAlign: 'top' // by default
									}
							  }
						},
					    title: {
					      text: '',
						    style: {
						        display: 'none'
						    }
						},
						credits: {
							enabled: false
						},
						navigation: {
							buttonOptions: {
								enabled: false
							}
						},
						subtitle: {
						    text: '',
						    style: {
						        display: 'none'
						    }
						},series:[{
						     dataLabels: {
								enabled: self.showValues()
							 }
						}]});
		    $('#altLegend' + self.encodeId).empty();
		    $('#altLegend' + self.encodeId).hide();
		    self.resizeChartWithGridResize(resize);
		}					
	};
	displayTable(data, resize) {
		var length;
		/*if(data.length<=7){
			length='5'
		}else if(data.length>7 && data.length<=10){
			length='10'
		}else if(data.length>10 && data.length<=25){
			length='25'
		}else if(data.length>25 && data.length<=50){
			length='50'
		}else if(data.length>50){
			length='100'
		}*/
	    var self = this;
	    if(self.plot){
			//self.plot.shutdown();
		}
	    $("#placeholder" + self.encodeId).empty();
	    $('#legend' + self.encodeId).empty().hide();
	    $('#altLegend' + self.encodeId).empty().hide();
	    if(!data || !data.length){
	    	return;
	    }
	    var selectedAlts = self.preference.selectedAlternativeObjs;
		debugger
	    var tr1Headers = [];
	    var phaseAltCombos = ["valueName"];
		var uniqueDataForTR2 = [];
		for(var i=0;i<data.length;i++){
			for(var j=0;j<data[i].data.length;j++){
				uniqueDataForTR2.push(data[i].data[j]);
			}
		}
		uniqueDataForTR2 = _.uniq(uniqueDataForTR2, "phaseAltObs");
		// to sort columns(alts) in chronological order
		var sortedArray = [];
		_.each(selectedAlts,function(key1) {
		    //var found = false;
		    uniqueDataForTR2 = uniqueDataForTR2.filter(function(item) {
		        if(item['phaseAlt'] === key1.displayName) {
		            sortedArray.push(item);
		            found = true;
		            return false;
		        } else 
		            return true;
		    });
		});
	    uniqueDataForTR2 = sortedArray.concat(uniqueDataForTR2);
		_.each(uniqueDataForTR2, function (data) {
			if (data['timeStamp']) {
				phaseAltCombos.push(data['phaseAltObs']);
			} else {
				phaseAltCombos.push(data[0]);
				tr1Headers.push(data[2]);
            }
		});
	    phaseAltCombos = _.uniq(phaseAltCombos);
	    tr1Headers = _.uniq(tr1Headers);
	    var myTableDiv = $('#placeholder' + self.encodeId);
     
	    var table = document.createElement('TABLE');
	    table.id = 'table' + self.encodeId;
	    //table.className="display";
	    //table.className="pageResize";
	    table.cellspacing="0";
	    table.width="100%";
	   
	    var tableHeader = document.createElement('THEAD');
	    table.appendChild(tableHeader);
	    var headerTR1 = document.createElement('TR');
	    tableHeader.appendChild(headerTR1);
	    //for first values header
	    var th = document.createElement('TH');
        th.appendChild(document.createTextNode("Values"));
        th.rowSpan = '2';
        headerTR1.appendChild(th);
        //for phase headers
        _.each(tr1Headers, function(name){
	       var th = document.createElement('TH');
           th.appendChild(document.createTextNode(name));
           var colSpan = phaseAltCombos.reduce(function(n, val) {
			    return n + (val.substr(0, val.indexOf('/') - 1) === name);
		   }, 0);
			th.colSpan = colSpan;
			for (var i = 0; i < uniqueDataForTR2.length; i++) {
				if (uniqueDataForTR2[i].timeStamp && uniqueDataForTR2[i].phaseName === name) {
					th.colSpan = th.colSpan + 1;
					break;
                }
            }
           headerTR1.appendChild(th);
	    });
	    var thGroupLabel = document.createElement('TH');
        thGroupLabel.appendChild(document.createTextNode("Group Label"));
        thGroupLabel.rowSpan = '2';
        headerTR1.appendChild(thGroupLabel);
	    //to create a new row for alt headers
	    var headerTR2 = document.createElement('TR');
	    tableHeader.appendChild(headerTR2);
	    
	    var tableBody = document.createElement('TBODY');
	    table.appendChild(tableBody);
	    var trTBody = {};
	    for(var k=0; k<data.length; k++){
	    	trTBody[k] = document.createElement('TR');
	    	tableBody.appendChild(trTBody[k]);
            var td = document.createElement('TD');
            td.align = "left";
   			td.appendChild(document.createTextNode(data[k].label));
   			trTBody[k].appendChild(td);
   		}
   		for(var i=1;i<headerTR1.children.length-1;i++){
			for (var k = 0; k < uniqueDataForTR2.length; k++){
				if (uniqueDataForTR2[k].timeStamp && uniqueDataForTR2[k].phaseName === headerTR1.children[i].innerText) {
					th = document.createElement('TH');
					th.appendChild(document.createTextNode(uniqueDataForTR2[k][3]));
					headerTR2.appendChild(th);
                }
	   			if(uniqueDataForTR2[k][2] === headerTR1.children[i].innerText){
	   				th = document.createElement('TH');
	    			th.appendChild(document.createTextNode(uniqueDataForTR2[k][3]));
           			headerTR2.appendChild(th);
   				}
	   		}
		}
		var cumulativeColSpan = 0;
	    for(var i=1;i<headerTR1.children.length-1;i++){
	    	for(var j=0;j<headerTR1.children[i].colSpan;j++){
	    		for(var k=0; k<data.length; k++){
	    				var found = _.find(data[k].data, function(e){ return e[2] === headerTR1.children[i].innerText && headerTR2.children[j+cumulativeColSpan].innerText === e[3];});
						if (!found) {
							found = _.find(data[k].data, function (e) { return e.phaseName === headerTR1.children[i].innerText && headerTR2.children[j + cumulativeColSpan].innerText === e[3]; });
                        }
					    if (found) {
                            var td = document.createElement('TD');
                            td.align = "right";
			    			var text = window.utils.thousandSeparator(window.utils.getRoundedValues(found[1], data[k].significantDecimals, false));
							text = window.utils.thousandSeparator(text);
			    			var a = document.createElement('a');
			    			a.appendChild(document.createTextNode(text));
			    			a.style.color = 'inherit';
							a.style.marginRight= "1%";
			    			a.setAttribute('id',data[k].valueId);
			    			a.setAttribute('altId',found[6]);
			    			a.setAttribute('whenObserved',found[7]);
							// a.setAttribute('mestId','');
							if(isNaN(found[7])) {
								a.style.cursor = 'pointer';
								$(a).click(function(event){
				    				var currentTarget = event.currentTarget;
				    				var obj1 = {valueId:currentTarget.getAttribute('id'),altId:currentTarget.getAttribute('altId'),timeStamp:currentTarget.getAttribute('whenObserved')};
				    				self.showAggregationView(obj1);
				    			});
							}
							td.appendChild(a);
				   			if(found[4]){
				           		var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
								svg.setAttribute("width", 21);
								svg.setAttribute("height", 20);
								svg.setAttribute("style",'vertical-align:bottom; margin-right: 1%');
								svg.setAttribute("viewBox", "-3 -2 26 26");
								svg.setAttribute("fill", ""+found[5]);
								svg.setAttribute("correctiveActions",found[8]);
                                svg.setAttribute("overallSatisfaction",found[9]);
								svg.setAttribute("minimumEndPoint",found[10]);
								svg.setAttribute("maximumEndPoint",found[11]);
								svg.style.cursor = 'pointer';
								var link = document.createElementNS("http://www.w3.org/2000/svg", "path");
								link.setAttribute("d", found[4]);
								svg.appendChild(link);
								$(svg).click(function(event){
									var correctiveActions = event.currentTarget.getAttribute('correctiveActions') ? event.currentTarget.getAttribute('correctiveActions') : '';
									var htmlContent = "<div class='row'>";
                                    htmlContent = htmlContent + "<div class = col-xs-1></div>";
                                    htmlContent = htmlContent + "<div class = col-xs-3>Satisfaction</div>";
                                    htmlContent = htmlContent + "<div class = col-xs-7>" + event.currentTarget.getAttribute('overallSatisfaction') + "</div>";
                                    htmlContent = htmlContent + "</div><br>";
                                    htmlContent = htmlContent + "<div class='row'>";
                                    htmlContent = htmlContent + "<div class = col-xs-1></div>";
                                    htmlContent = htmlContent + "<div class = col-xs-3>Interval From</div>";
                                    htmlContent = htmlContent + "<div class = col-xs-7>" + event.currentTarget.getAttribute('minimumEndPoint') + "</div>";
                                    htmlContent = htmlContent + "</div><br>";
                                    htmlContent = htmlContent + "<div class='row'>";
                                    htmlContent = htmlContent + "<div class = col-xs-1></div>";
                                    htmlContent = htmlContent + "<div class = col-xs-3>Interval To</div>";
                                    htmlContent = htmlContent + "<div class = col-xs-7>" + event.currentTarget.getAttribute('maximumEndPoint') + "</div>";
                                    htmlContent = htmlContent + "</div><br>";
									htmlContent = htmlContent + "<div class='row'>";
                                    htmlContent = htmlContent + "<div class='col-xs-1'></div>";
                                    htmlContent = htmlContent + "<div class='col-xs-5'>Corrective Actions</div>";
                                    htmlContent = htmlContent + "<div class='col-xs-4'></div>";
                                    htmlContent = htmlContent + "</div><br>";
                                    htmlContent = htmlContent + "<div class='row'>";
                                    htmlContent = htmlContent + "<div class='col-xs-1'></div>";
                                    htmlContent = htmlContent + "<div class='col-xs-10'><textarea class='control-label' disabled id='correctiveActionsArea' style='word-wrap: break-word;height:200px;width:100%;resize:vertical;'>"+correctiveActions+"</textarea></div>";
                                    htmlContent = htmlContent + "<div class='col-xs-1'></div>";                                       
                                    htmlContent = htmlContent + "</div><br>";
									var box = bootbox.dialog({
										message: htmlContent,
										backdrop:false,
										title: "<div><img class='smallIcon' src='img/icons/icons_15.png'>&nbsp;&nbsp;&nbsp;"+DataManager.getDataManager().get('localeManager').get('CorrectiveActions')+"</div>",
										buttons: {
											cancel : {
												label: "Close",
												className : 'btn vtn-default',
												callback : function(){
													
												}
											}
										}
									});
									// bootbox.alert(event.currentTarget.getAttribute('correctiveActions'));
									if(document.fullscreenElement){
										box.appendTo(`#${document.fullscreenElement.id}`)
									}else{
										box.show();
									} 
									
									$('.modal-dialog').css('width', '80%');
								});
				           		td.appendChild(svg);		           		
				           	}
				   			trTBody[k].appendChild(td);
			    		}else{
			    			var td = document.createElement('TD');
                            td.appendChild(document.createTextNode('-'));
                            td.align = "right";
				   			trTBody[k].appendChild(td);
			    		}
		    	}
			}
			cumulativeColSpan = cumulativeColSpan + headerTR1.children[i].colSpan;
		}
	    //for group label
		var noOfGroups = 0;
	    for(var k=0; k<data.length; k++){
		   	var tdGroupLabel = document.createElement('TD');
            tdGroupLabel.appendChild(document.createTextNode(data[k].groupLabel ? data[k].groupLabel : "Uncategorized"));
		   	trTBody[k].appendChild(tdGroupLabel);
			if (data[k].groupLabel) noOfGroups += 1;
   		}
   		self.resizeChartWithGridResize(resize);
	    myTableDiv.append(table);
		var groupLabelIndex = phaseAltCombos.length;
		//var selectedChartPageLength = self.preference.selectedChartPageLength ? Number(self.preference.selectedChartPageLength):Number(length);
	    		self.dataTable = $('#table' + self.encodeId).DataTable({
	    	"columnDefs": [
                { "visible": false, "targets": groupLabelIndex }
            ],
            //"sDom": "rtip",
			"bInfo": false,
			"bFilter": false,
            "order": [[ groupLabelIndex, 'asc' ]],
			"orderFixed": [ groupLabelIndex, 'asc' ],
			//"bLengthChange": false,
			"paging": false,
			//"pageLength": selectedChartPageLength,
			"pageResize": false,
            "drawCallback": function ( settings ) {
                var api = this.api();
                var rows = api.rows( {page:'current'} ).nodes();
                var last=null;
     
                api.column(groupLabelIndex, {page:'current'} ).data().each( function ( group, i ) {
                    if ( last !== group && noOfGroups > 0 ) {
                        $(rows).eq( i ).before(
                            '<tr class="group" style="text-align:left;"><td colspan='+groupLabelIndex+'><span>'+group+'</span></td></tr>'
                        );

                        last = group;
                    }
                } );
            }
		});	
		/*self.dataTable.on('length.dt', function (e, settings, len) {
			var self = this;
			var chartJson = JSON.parse(self.model.get('chartConfig'));
			chartJson.selectedChartPageLength = len;
			self.model.set('chartConfig', JSON.stringify(chartJson))
		}.bind(self));*/
	};
	/*plotRadarChart(resize) {
	    var self = this;
	    $('#legend' + self.encodeId).empty().hide();
	    var chartContainerNode = $('#chart-container' + self.encodeId);
	    /*var w = chartContainerNode.width();
	    var h = chartContainerNode.height();//
	    var margin = { top: 60, right: 20, bottom: 60, left: 20 },
                        legendPosition = { x: 10, y: 25 }/*,
                        width = Math.min(1000, w - 10) - margin.left - margin.right,
                        height = Math.min(width, h - margin.top - margin.bottom - 20)//; //shifted down since alt legend needs to be built before height and width are calculated
	
	    var color = d3.scaleOrdinal()
				.range(["#EDC951", "#F08080", "#FFA07A","#4169E1","#98FB98","#EDC951","#87CEFA","#ceaeff","#003366","#0066ff"]);
	    var LegendOptions = [];
	    var values = Object.getOwnPropertyNames(self.datasets);
	    var radarData = [];
	    var noOfAlts = 0;
		
		var LegendOptions = [];
	    for (var j = 0; j < values.length; j++) {
	        if (values[j].indexOf('Grade only') != -1) {
	            continue;
	        }
	        var val = self.datasets[values[j]];
	        if (val.data && val.data.length > 0) {
	        	for (var i = 0; i < val.data.length; i++) {
		        	if(val.data[i][1] !== null && LegendOptions.indexOf(val.data[i][0]) === -1){
	            		LegendOptions.push(val.data[i][0]);
	            	}
	            }
	        }
	    }
	    noOfAlts = LegendOptions.length;
	    $('#altLegend' + self.encodeId).empty();
	    var altIndexes = Object.getOwnPropertyNames(self.altIndex);
	    _.each(altIndexes, function (key) {
	        var altIndexVal = self.altIndex[key];
			var altFound = $.grep(self.preference.selectedAlternativeObjs, function(e){ return e.displayName === key;});
	        if(self.groupByScenario){
            	var sceLabel = self.changeScenarioLabel(self.preference.selectedObservationObjs[altIndexVal]);
				if(sceLabel){
					$('#altLegend' + self.encodeId).append($("<div>" + sceLabel + " : " + key + "</div>"));
				}else{
				    $('#altLegend' + self.encodeId).append($("<div>Sc-" + altIndexVal + " : " + key + "</div>"));
        	    }
			}else if(self.groupByPhase){
			    var altLabel;
				if(altFound[0]){
				   altLabel = self.changeAltLabel(altFound[0]);
				}
            	if(altLabel){
				    $('#altLegend' + self.encodeId).append($("<div>" + altLabel + " : " + key + "</div>"));
				}else{
				    $('#altLegend' + self.encodeId).append($("<div>Alt-" + altIndexVal + " : " + key + "</div>"));
				}
			}
	    });
	    //legendPosition.y = h - altIndexes.length * 30;
	    var maxVal;
	    var altIndexes = Object.getOwnPropertyNames(self.altIndex);
	    var selectedValueList = [];
	    for (var i = 0; i < noOfAlts; i++) {
	        var valDataArr = [];
	        var altName, index;
	        index = parseInt( LegendOptions[i].substr(LegendOptions[i].lastIndexOf('-') + 1));
	        _.each(altIndexes, function (key) {
	            var altIndexVal = self.altIndex[key];
	            if (altIndexVal === index) {
	                altName = key;
	            }
	        });
	        for (var j = 0; j < values.length; j++) {
	        		var valueSuffix = window.utils.getSuffix(values[j]);
	        	   	var data = self.datasets[values[j]].data;
	                var foundValue = false;
	            	for(var k = 0; k < data.length; k++){
	            		if(data[k][0] === LegendOptions[i]){
	            			foundValue = true;
	            			var val = data[k]?data[k][1]:'';
	            			if(val==='####'){
	            				continue;
	            			}
			                val = window.utils.getRoundedValues(val, self.datasets[values[j]].significantDecimals, true);
			                var valId = self.datasets[values[j]].valId;
			                var valueIndex =_.filter(selectedValueList,function(obj){return obj.id === valueSuffix;});
			                if(valueIndex && valueIndex[0]){
			                	valueIndex[0].value.push(val);
			                }else {
			                	selectedValueList.push({'id':valueSuffix,value:[val],valId:valId});
			                }
			                if(self.datasets[values[j]].displayLabel){
		                		var radarHover = self.datasets[values[j]].displayLabel +' '+ self.datasets[values[j]].data[k].radarHover+' '+ val + ' ' + self.datasets[values[j]].unit;
		                		var radarName = self.datasets[values[j]].radarName? self.datasets[values[j]].displayLabel +' '+ self.datasets[values[j]].radarName : self.datasets[values[j]].displayLabel;
		                		if(self.showValues()){
								   var radarName = self.datasets[values[j]].radarName? self.datasets[values[j]].displayLabel +"(" +data[k][1]  +")" +' '+ self.datasets[values[j]].radarName : self.datasets[values[j]].displayLabel +"(" +data[k][1]  +")";
								}
								var labelHover = self.datasets[values[j]].labelHover? self.datasets[values[j]].displayLabel +' '+ self.datasets[values[j]].labelHover : self.datasets[values[j]].displayLabel;
			                }else{
			                	radarHover = self.datasets[values[j]].displayName + ' '+self.datasets[values[j]].data[k].radarHover+' '+ val + ' ' + self.datasets[values[j]].unit;
		                		radarName = self.datasets[values[j]].radarName? self.datasets[values[j]].valueName +' '+ self.datasets[values[j]].radarName : self.datasets[values[j]].valueName;
		                		if(self.showValues()){
								   radarName = self.datasets[values[j]].radarName? self.datasets[values[j]].valueName +"(" +data[k][1]  +")"+' '+ self.datasets[values[j]].radarName : self.datasets[values[j]].valueName+"(" +data[k][1]  +")";
								}
								labelHover = self.datasets[values[j]].labelHover? self.datasets[values[j]].displayName +' '+ self.datasets[values[j]].labelHover : self.datasets[values[j]].displayName;
			                }
			                valDataArr.push({valueSuffix:valueSuffix, axis:radarName, radarHover: radarHover,labelHover:labelHover, value: val, areaName: LegendOptions[i] });
			                if (!maxVal || maxVal < val) {
			                    maxVal = val;
			                }
	            			break;
	            		}
	            	}
	            	if(!foundValue){
	            		radarHover = self.datasets[values[j]].displayName;
	                	radarName = self.datasets[values[j]].radarName? self.datasets[values[j]].valueName+ ' '+ self.datasets[values[j]].radarName : self.datasets[values[j]].valueName;
	            		valDataArr.push({valueSuffix:valueSuffix, axis: radarName, radarHover: radarHover,labelHover:radarHover, value: '', areaName: LegendOptions[i] });
	            	}
	        }
	        
	        radarData.push({ values: valDataArr, key: LegendOptions[i] });
	    }
	    for(var m=0;m<selectedValueList.length;m++){
	    	var max = Math.max.apply(Math,selectedValueList[m].value.map(function(o){return o;}));   	
		    for(var i=0;i<radarData.length;i++){
		    	var valueList = radarData[i].values;
		    	var valueListIndex =_.filter(valueList,function(obj){return obj.valueSuffix === selectedValueList[m].id;});
		    	if(valueListIndex && valueListIndex[0]){
		    		var oldValue = valueListIndex[0].value;
		    		if(max == 0 || isNaN(max)){
		    			max = 1;
		    		}
		    		if(oldValue !== '' || !isNaN(oldValue) || !isNaN(max) /*|| oldValue == Number.MAX_VALUE || oldValue == Infinity//){
		    			var valuePercentage = oldValue/max * 100;
		    			if(isNaN(valuePercentage)){
		    				valuePercentage = 0
		    			}
			    		if(valuePercentage < -100){
			    			valuePercentage = -100;
			    		}
			    		valueListIndex[0].value = window.utils.getRoundedValues(valuePercentage, 2 , true);
		    		}
		    	}
		    }
		}
		
        self.resizeChartWithGridResize(resize);
	    var w = chartContainerNode.width();
	    var h = chartContainerNode.height();
        var width = Math.min(1000, w - 10) - margin.left - margin.right;
        var height = Math.min(width, h - margin.top - margin.bottom - 20);
        legendPosition.y = h - altIndexes.length * 30;
                        
	    //Options for the Radar chart, other than default
	    var radarChartOptions = {
	        w: width,
	        h: height,
	        margin: margin,
	        legendPosition: legendPosition,
	        //maxValue: 0.5,
	        wrapWidth: 120,
	        levels: 5,
	        roundStrokes: true,
	        color: color,
	        axisName: "axis",
	        areaName: "areaName",
	        value: "value"
	    };
        //RadaraChart.draw("#placeholder" + self.encodeId, radarData, radarChartOptions);
	}*/
	plotPolarChart(resize) {
	    var self = this;
	    $('#legend' + self.encodeId).empty().hide();
	    $('#altLegend' + self.encodeId).empty();
	    var altIndexes = Object.getOwnPropertyNames(self.altIndex);
	    _.each(altIndexes, function (key) {
	        var altIndexVal = self.altIndex[key];
			var altFound = $.grep(self.preference.selectedAlternativeObjs, function (e) { return e.displayName === key; });
			if (altFound && altFound.length === 0 && self.preference.selectedObjectiveObjs) {
				altFound = $.grep(self.preference.selectedObjectiveObjs, function (e) { return e.displayName === key; });
            }
	        if(self.groupByScenario){
            	var sceLabel = self.changeScenarioLabel(self.preference.selectedObservationObjs[altIndexVal]);
				if(sceLabel){
				   $('#altLegend' + self.encodeId).append($("<div>" + sceLabel + " : " + key + "</div>"));
				} else if (altFound[0] && altFound[0].timeStamp) {
					$('#altLegend' + self.encodeId).append($("<div>" + altFound[0].displayName + " : " + key + "</div>"));
				}else{
				   $('#altLegend' + self.encodeId).append($("<div>Sc-" + altIndexVal + " : " + key + "</div>"));
				}
        	}else if(self.groupByPhase){
            	var altLabel;
				if(altFound[0]){
					altLabel = self.changeAltLabel(altFound[0])
				}
				if(altLabel){
					$('#altLegend' + self.encodeId).append($("<div>" + altLabel + " : " + key + "</div>"));
				} else if (altFound[0] && altFound[0].timeStamp) {
					$('#altLegend' + self.encodeId).append($("<div>" + altFound[0].displayName + " : " + key + "</div>"));
                }
				else {
				   $('#altLegend' + self.encodeId).append($("<div>Alt-" + altIndexVal + " : " + key + "</div>"));
				}
			}
	    });		
        self.resizeChartWithGridResize(resize);
        var values = Object.getOwnPropertyNames(self.datasets);

        var selectedValueList = [], polarData = [], categoriesArray = [];//creating new data to populate charts
	    async.each(altIndexes, function (key, keyHandled) {
	    	var newDataset = {};
	    	var newDataArray = [];
	    	var altIndexVal = self.altIndex[key];
			var altFound = $.grep(self.preference.selectedAlternativeObjs, function (e) { return e.displayName === key; });
			if (altFound && altFound.length === 0 && self.preference.selectedObjectiveObjs) {
				altFound = $.grep(self.preference.selectedObjectiveObjs, function (e) { return e.displayName === key; });
            }
	    	if(self.groupByScenario){
            	var sceLabel = self.changeScenarioLabel(self.preference.selectedObservationObjs[altIndexVal]);
				if(sceLabel){
					newDataset.name = sceLabel
				} else if (altFound[0] && altFound[0].timeStamp) {
					newDataset.name = altFound[0].displayName
				}else{
				   newDataset.name = "Sc-" + altIndexVal;
				}
        	}else if(self.groupByPhase){
            	var altLabel;
				if(altFound[0]){
					altLabel = self.changeAltLabel(altFound[0])
				}
				if(altLabel){
					newDataset.name = altLabel;
				} else if (altFound[0] && altFound[0].timeStamp) {
					newDataset.name = altFound[0].displayName
                }
				else {
				    newDataset.name = "Alt-" + altIndexVal;
				}
			}
         	newDataset.pointPlacement = 'on';
	    	async.each(values, function (value, valueHandled) {
	    		var dataset = self.datasets[value];
	    		var valueSuffix = window.utils.getSuffix(value);
	    		var val = undefined;
	    		async.each(dataset.data, function(valData, dataHandled){
	    			if(valData[0] === newDataset.name){
		    			val = valData[1] === "-"? null : valData[1];
		    			var valueIndex =_.filter(selectedValueList,function(obj){return obj.id === valueSuffix;});
		                if(valueIndex && valueIndex[0]){
		                	valueIndex[0].value.push(val);
		                }else {
		                	selectedValueList.push({'id':valueSuffix,value:[val],valId:dataset.valId});
		                }
		    			dataHandled();
		    			return;
	    			}else{
	    				dataHandled();
	    			}
	    		},function(){
	    			var valData = {};
	    			valData['valSuffix'] = valueSuffix;
	    			if(val != undefined){
	    				valData['value'] = val;
	    			}else{
	    				valData['value'] = null;
	    			}
	    			var indivisualDataWithTooltip = {};
	    			indivisualDataWithTooltip['y'] = valData;
	    			indivisualDataWithTooltip['tooltip'] = window.utils.getRoundedValues(valData.value, dataset.significantDecimals, true);
	    			newDataArray.push(indivisualDataWithTooltip);
					categoriesArray.push(dataset.label);
					categoriesArray = _.uniq(categoriesArray);
	    			valueHandled();
	    		})
	    	}, function () {
	    		newDataset.data = newDataArray;
	    		polarData.push(newDataset);
	    		keyHandled();
	    	})
	    }, function () {
	    	for(var m=0;m<selectedValueList.length;m++){
		    	var min = Math.min.apply(Math,selectedValueList[m].value.map(function(o){return o;}));
		    	if(min < 0){
		    		selectedValueList[m].value = selectedValueList[m].value.map(function(o){
		    			return o = o - min;
		    		});
		    	}
		    	var max = Math.max.apply(Math,selectedValueList[m].value.map(function(o){return o;}));	    	
			    for(var i=0;i<polarData.length;i++){
			    	var valueList = polarData[i].data;
			    	var valueListIndex =_.filter(valueList,function(obj){return obj['y'] && obj['y'].valSuffix === selectedValueList[m].id;});
			    	if(min < 0 && valueListIndex && valueListIndex[0] && valueListIndex[0]['y'].value !== selectedValueList[m].value[i]){
			    		//Replacing polardata values when selectedvaluelist is changed
			    		valueListIndex[0]['y'].value = selectedValueList[m].value[i];
			    	}
			    	if(valueListIndex && valueListIndex[0] && valueListIndex[0]['y']){
			    		var oldValue = valueListIndex[0]['y'].value;
			    		if(max == 0 || isNaN(max)){
			    			max = 1;
			    		}
			    		if(oldValue !== '' || !isNaN(oldValue) || !isNaN(max)){
			    			var valuePercentage = oldValue/max * 100;
			    			if(isNaN(valuePercentage)){
			    				valuePercentage = 0
			    			}
				    		if(valuePercentage < -100){
				    			valuePercentage = -100;
				    		}
				    		var index = valueList.map(function (val) { return val['y'] && val['y'].valSuffix ; }).indexOf(selectedValueList[m].id);
				    		valueList[index]['y'] = window.utils.getRoundedValues(valuePercentage, 2 ,true);
			    		}
			    	}
			    }
			}
	    	var polarChartType;
	    	if(self.selectedChartType() === "Polar"){
	    		polarChartType= 'area';
	    	}else if(self.selectedChartType() === "Spiderweb"){
	    		polarChartType = 'line'
	    	}
	        new Highcharts.Chart({
			    colors: self.chartColors,
				chart: {
                        polar : true,
						type: polarChartType,
                        zoomEnabled: true,
                        renderTo: "placeholder"  + self.encodeId,
                        zoomType: 'xy',
                        resetZoomButton: {
                            position: {
                                align: 'right', // by default
                                verticalAlign: 'top' // by default
                            }
                        }
                },
			    title: {
				    text: '',
				    style: {
				        display: 'none'
				    }
				},
				credits: {
					enabled: false
				},
				navigation: {
					buttonOptions: {
						enabled: false
					}
				},
				subtitle: {
				    text: '',
				    style: {
				        display: 'none'
				    }
				},
				legend: {
			        itemStyle: {
			            fontWeight: 'normal'
			        }
			    },
			    pane: {
			        size: '70%'
			    },
			    responsive: {
			        rules: [{
			            condition: {
			                maxWidth: 500
			            },
			            chartOptions: {
			                legend: {
			                    layout: 'horizontal',
			                    align: 'center',
			                    verticalAlign: 'bottom'
			                }
			            }
			        }]
			    },

			    xAxis: {
			    	categories: categoriesArray,
			        tickmarkPlacement: 'on',
			        labels: {
			            enabled: true,
				        style :{
		            		fontSize: '12px'
		            	},
			            labels: {
			                align: 'center',
			                distance: 43
			            }
			        },
			        lineWidth: 0
			    },

			    yAxis: {
			    	gridLineInterpolation: 'polygon',
			    	lineWidth: 0,
			        endOnTick: true,
            		showLastLabel: true,
            		labels: {
				    	style :{
		            		fontSize: '12px'
		            	},
		                enabled: false,
		            },
		            max: 100
			    },
			    tooltip: {
				    formatter: function() {
				        return this.key+'<br>Value: '+this.point.tooltip;
				    }
				},
				plotOptions: {
				        series: {
				            dataLabels: {
								allowOverlap: true,
								enabled: self.showValues()
							},
							label: {
				                connectorAllowed: false
				            },
				        }
			    },
				series: polarData
			});
	    })
	}

	plotAccordingToChoices(resize) {
	    var self = this;
	    var placeholder = $("#placeholder" + self.encodeId);
	    var selectedOptions = self.preference? self.preference.selectedValueObjs : 0;
	    var selectedChartType = self.selectedChartType();
		if (selectedChartType === 'Radar') {//old radar chart migration
			self.selectedChartType('Curves');
		}
	    var values = Object.getOwnPropertyNames(self.datasets);
	    //var selectedOptions = $('#yaxis'+ self.encodeId + ' option:selected');
	    placeholder.css('border', 'none');
	    if (selectedChartType != 'Table') {
	        placeholder.css('overflow', 'visible');
			var showAltLegend = self.showLegend();
			if(self.preference && self.preference.showLegend){
				showAltLegend = self.preference.showLegend ;
			}
			if (showAltLegend) {
				$('#altLegend' + self.encodeId).show();
			} else {
				$('#altLegend' + self.encodeId).hide();
			}
	    } else {
	    	if(selectedOptions && selectedOptions.length > 0) {
	    		placeholder.css('overflow', 'auto');
	    	}else {
	    		placeholder.css('overflow', '');
	    	}
	    }
		
		if(self.dataTable){//destroy previously created datatable
			self.dataTable.destroy(true);
		}
	    /*if (self.showRadar() && selectedChartType === 'Radar') {
	        if(self.plot){
				//self.plot.shutdown();
			}
	        placeholder.empty();
	        self.plotRadarChart(resize);
	    } else*/ if(selectedChartType === 'Polar' || selectedChartType === "Spiderweb"){
	        placeholder.empty();
	        self.plotPolarChart(resize);
	    } else {
	        //d3.select("#placeholder" + self.encodeId + ' svg').remove();
	        self.plotFlotChart(resize);
	        if(values.length == 0 && selectedChartType === 'Table') {
	        	self.displayTable([]);
				
        	}

	    }
	
	};
	changeAltLabel(alt){
		var self = this;
		var altName;
		if(self.preference && self.preference.alternativeLabels && self.preference.alternativeLabels.length  > 0){
			for(var i=0;i<self.preference.alternativeLabels.length;i++){
				if(self.preference.alternativeLabels[i].id === alt.id){
						altName = self.preference.alternativeLabels[i].label 
						break;
				}
			}
		}
		return altName;
	}
	changeScenarioLabel(obs){
		var self = this;
		var sceName;
		if(self.preference && self.preference.scenarioLabels && self.preference.scenarioLabels.length  > 0 && obs){
			for(var i=0;i<self.preference.scenarioLabels.length;i++){
				if(self.preference.scenarioLabels[i].id === obs.id){
						sceName = self.preference.scenarioLabels[i].label 
						break;
				}
			}
		}
		return sceName;
	}
    loadSelectedValues(alt, obsModel, valObj, val, measurandData, hidePreviousAltData,obs, callback){
		var self = this;
		if(!self.preference){
			callback();
			return;
		}
		var selectedAlts = self.preference.selectedAlternativeObjs;
		var selectedChartType = self.selectedChartType();
		var tableType = false;
		var radarType = false;
		var curveType = false;
		if(selectedChartType === "Table"){
			tableType = true;
		}else if(selectedChartType === "Radar"){
			radarType = true;
		}else{
			curveType = true;
		}
        var timeStamp;
        if (obsModel) {
            timeStamp = obsModel.get('whenObserved');
        } else if (obs && obs.timeStamp) {
            timeStamp = obs.timeStamp;
        }
        
		var isTimestamp = timeStamp? true : false;
		var altObs, dataStr, data, unit;
		if(val){
			//var measure = val.get('valueMeasurement');
			var altComp = alt.getModelLinkedSync(val);
			if(altComp){
				val = altComp;
			}
			var preAltScenario = altComp?alt.getMainScenario(altComp.getNestedParent()):null;
			var observedMeasure = preAltScenario?preAltScenario.getObservedMeasureWithMeasurements(altComp.get('valueMeasurement'), isTimestamp, timeStamp):null;
			var mest = observedMeasure?observedMeasure.get('measurements').at(0):null;
			if(!observedMeasure){
				observedMeasure = preAltScenario?preAltScenario.getObservedMeasureWithMeasurements(altComp.get('satisfactionLevel'), isTimestamp, timeStamp):null;
				mest = observedMeasure?observedMeasure.get('measurements').at(0):null;
				var compSatisfactionMeasure = mest?mest.get('observedMeasure').get('measure'):null;
			}			
        }
        /*if (!self.snapshots) {
            self.snapshots = {};
        }
        if (obs.snapshot) {
            if (self.snapshots[obs.id]) {
                handleLoadedData();
            } else {
                var promise = DataManager.getDataManager().get('vmpSnapshotService').getAllPromise("/vdmbee/snapshot/" + obs.id + "?tenantId=" + DataManager.getDataManager().get("currentLegalEntity").entityId);
                promise.then(function (data) {
                    if (data) {
                        self.snapshots[obs.id] = data;
                        handleLoadedData();
                    }
                });
                promise.catch(function (error) {
                    console.log(error);
                    self.snapshots[obs.id] = {};
                    handleLoadedData();
                });
            }
        } else {
            handleLoadedData();
        }*/
        //function handleLoadedData() {
            if (val || mest || tableType) {
                var valData = [];
                var phaseName = alt.get('phaseAlternativeOwner').get('name');
                var altName = alt.get('name');
                var altProp = phaseName + " / " + altName;
                var whenObsName;
                var whenObsNameWithLocaleString;
                if (obsModel) {
                    whenObsName = obsModel.get('name');
                } else if (obs.snapshot) {
                    whenObsName = self.snapshots[obs.id].name;
                }
                whenObsNameWithLocaleString = whenObsName;
                var whenObsNameWithLocaleDateString = whenObsName;

                var isImportedTimeStamp = timeStamp ? !isNaN(timeStamp) : false;
                //var altObsName = altName+" / " + whenObsNameWithLocaleDateString;
                var implementationDate = alt.get('phaseAlternativeOwner').get('endDate') ? alt.get('phaseAlternativeOwner').get('endDate') : alt.get('phaseAlternativeOwner').get('startDate');
                if (!implementationDate) {
                    implementationDate = new Date().getTime();
                }
                self.implementationDate(implementationDate);
                var altObsName = altName + " / " + whenObsNameWithLocaleDateString;
                if (tableType) {
                    valData.push(altProp + " / " + whenObsNameWithLocaleDateString);
                    valData['phaseAltObs'] = altProp + " / " + whenObsNameWithLocaleDateString;
                    valData['phaseAlt'] = altProp;
                    if (self.groupByScenario) {
                        if (!_.has(self.altIndex, whenObsName)) {
                            self.altIndex[whenObsName] = (_.keys(self.altIndex)).length;
                        }
                    } else {
                        //group by phase and time
                        if (!_.has(self.altIndex, altProp)) {
                            self.altIndex[altProp] = (_.keys(self.altIndex)).length;
                        }
                    }
                } else if (radarType) {
                    if (self.groupByScenario) {
                        if (!_.has(self.altIndex, whenObsName)) {
                            self.altIndex[whenObsName] = (_.keys(self.altIndex)).length;
                        }
						var sceLabel = self.changeScenarioLabel(obsModel);
						if(sceLabel){
							valData.push(sceLabel)
						}else{
                          valData.push("Sc-" + self.altIndex[whenObsName]);
						}
                        valData['radarHover'] = "[" + altProp + "] [" + whenObsNameWithLocaleString + "]";
                    } else {
                        //group by phase and time
                        if (!_.has(self.altIndex, altProp)) {
                            self.altIndex[altProp] = (_.keys(self.altIndex)).length;
                        }
                        var altLabel = self.changeAltLabel(alt);
						if(altLabel){
							valData.push(altLabel);
						}else{
							valData.push("Alt-" + self.altIndex[altProp]);
						}
                        valData['radarHover'] = "[" + altProp + "] [" + whenObsNameWithLocaleString + "]";
                    }
                } else {
                    //valData['altObs'] = "["+altProp+"] ["+whenObsNameWithLocaleString+"]";
                    if (self.groupByScenario) {
                        if (!_.has(self.altIndex, whenObsName)) {
                            self.altIndex[whenObsName] = (_.keys(self.altIndex)).length;
                        }
						var sceLabel = self.changeScenarioLabel(obsModel);
						if(sceLabel){
							valData.push(sceLabel)
						}else{
                            valData.push("Sc-" + self.altIndex[whenObsName]);
						}
                    } else {
                        //group by phase and time
                        if (self.groupByPhase) {
                            if (!_.has(self.altIndex, altProp)) {
                                self.altIndex[altProp] = (_.keys(self.altIndex)).length;
                            }
							var altLabel = self.changeAltLabel(alt);
							if(altLabel){
							    valData.push(altLabel);
							}else{
							    valData.push("Alt-" + self.altIndex[altProp]);
							}
						} else {
                            valData.push(implementationDate);
                        }
                    }
                }
                //valData['hide'] = hidePreviousAltData;
				
                function fillValueData(valData,obs,unitName) {
                    var vm = val ? val.get('valueMeasurement') : null;
                    if (tableType) {
                        valData.push(phaseName);
                        var altFound = _.find(selectedAlts, function (e) { return e.id === alt.id; });
                        if (altFound) {
                            if (timeStamp && altFound['labelInAlts'] && altFound['labelInAlts'][timeStamp] && altFound['labelInAlts'][timeStamp] != "") {
                                valData.push(altFound['labelInAlts'][timeStamp]);
                            } else if (!timeStamp && altFound['labelInAlts'] && altFound['labelInAlts']['Base'] && altFound['labelInAlts']['Base'] != "") {
                                valData.push(altFound['labelInAlts']['Base']);
                            } else {
                                valData.push(altObsName);
                            }
                        }
                        var importTimeStampValue;
                        if (!isNaN(obsModel.get('whenObserved'))) {
                            importTimeStampValue = data ? data[vm.id] : null;
                        }
                        var selectedInterval = preAltScenario && val ? val.getSatisfactionInterval(preAltScenario, timeStamp, importTimeStampValue) : null;
                        var smileySrc = '';
                        var smileyColor = '';
                        var correctiveActions = '';
                        var minimumEndPoint = '';
                        var maximumEndPoint = '';
                        var minimumOpen = '';
                        var maximumOpen = '';
                        var overallSatifaction = '';
                        if (selectedInterval) {
                            smileySrc = val.fetchSmileySrc(selectedInterval.get('smiley'));
                            smileyColor = selectedInterval.get('smileyColor');
                            correctiveActions = selectedInterval.get('correctiveActions') ? selectedInterval.get('correctiveActions'):"";
                            minimumEndPoint = selectedInterval.get('minimumEndPoint');
                            maximumEndPoint = selectedInterval.get('maximumEndPoint');
                            minimumOpen = selectedInterval.get('minimumOpen');
                            maximumOpen = selectedInterval.get('maximumOpen');
                            overallSatifaction = selectedInterval.get('symbol')?selectedInterval.get('symbol'):selectedInterval.get('value');
                        }
                        valData.push(smileySrc);
                        valData.push(smileyColor);
                        valData.push(alt.id);
                        valData.push(obsModel.get('whenObserved'));
                        valData.push(correctiveActions);
                        valData.push(overallSatifaction);
                        valData.push(minimumEndPoint);
                        valData.push(maximumEndPoint);
                        valData.push(minimumOpen);
                        valData.push(maximumOpen);
                    }
                    var valueData;
					if (mest || data || obs.snapshot) {
						if (mest) {
							var unit = mest.get('observedMeasure').get('measure').get('unit');
                            unitName = unit ? unit.get('name') : null;
							//unitName = unit?window.utils.replaceEscapeCharacters(unit.get('name')):null;
                        }
						valueData = measurandData[unitName];
						if (!valueData) {
							unitName = unitName ? unitName : 'Grade only';
							var measureData = self.addMeasurandData(val, alt.id, timeStamp, vm.id, valObj, unitName, measurandData);
							valueData = measureData ? measureData[unitName] : null;
							if (radarType && self.preference) {
								if (self.groupByScenario) {
									valueData['radarName'] = self.preference.selectedAlternativeObjs.length > 1 ? "[" + altProp + "]" : "";
									valueData['labelHover'] = "[" + altProp + "]";
								} else {
									valueData['radarName'] = self.preference.selectedObservationObjs.length > 1 ? "[" + whenObsName + "]" : "";
									valueData['labelHover'] = "[" + whenObsNameWithLocaleString + "]";
								}
							} else if (curveType) {
								var obsName;
								if (obsModel) {
									obsName = obsModel.get("name");
								} else if (obs.snapshot && self.snapshots[obs.id]) {
									obsName = self.snapshots[obs.id].name;
								}
								if (self.groupByScenario) {
									valueData.label = valueData.label.concat(" [" + altProp + "]");
								} else if (self.groupByTime) {
									if (self.preference.selectedObservationObjs && self.preference.selectedObservationObjs.length > 1) {
										valueData.label = valueData.label.concat(" [" + obsName + "] [" + altProp + "]");
									} else {
										valueData.label = valueData.label.concat(" [" + altProp + "]");
									}
								} else if (self.preference.selectedObservationObjs && self.preference.selectedObservationObjs.length > 1) {
									valueData.label = valueData.label.concat(" [" + obsName + "]");
								}
								else {
									valueData.label = valueData.label;
								}
							}
							var valid = window.utils.getSuffix(val.id);
							var valFound = $.grep(self.preference.selectedValueObjs, function (e) { return window.utils.getSuffix(e.suffixId) === valid; });
							if (valFound[0] && valFound[0].label && valFound[0].label !== "") {
								var label = unitName == "Grade only" ? valFound[0].label : valFound[0].label.concat(" (" + unitName + ")");
								valueData.displayLabel = label;
							} else {
								label = valueData.label;
							}
							if (valFound[0] && valFound[0].groupLabel && valFound[0].groupLabel !== "") {
								var groupLabel = valFound[0].groupLabel;
								valueData.groupLabel = groupLabel;
							} else {
								valueData.groupLabel = null;
							}
							valueData.significantDecimals = unit ? unit.get('significantDecimals') : '2';
							valueData.label = label;
							valueData.unit = unitName;
						}
						valueData.data.push(valData);
					} else if(!mest){
						var measurement = val.get('valueMeasurement').get('measurement').at(0);
                        var valUnit = measurement? measurement.get('observedMeasure').get('measure').get('unit'):'';
						var unit = valUnit ? valUnit.get('name') : "";
						if (measurandData[unit]) {
							measurandData[unit].data.push(valData);
						} else if(val && unit && !tableType){
							measurandData[unit] = {};
							valueData = measurandData[unit];
							valueData.valueName = val.get('name');
							valueData.displayName = val.get('name');
							valueData.valueId = val.get('id');
							valueData.valId = vm? vm.id:'';
							valueData.unit = unit;
                            valueData.label = unit == "Grade only" ? valueData.valueName : valueData.valueName + ' (' + unit + ')';
							if(!valueData.data){
								valueData.data = [];
							}
							valueData.data.push(valData);
						}
                    }
                    callback(measurandData);
                }
                function getSnapshotData(altComp) {
                    var currentValue;
                    if (self.snapshots[obs.id]) {
                        var snapshotValues = self.snapshots[obs.id].fileContent;
                        var vm = altComp ? altComp.get('valueMeasurement') : null;
                        var valueObj;
                        if (!vm) {
                            vm = altComp ? altComp.get('satisfactionLevel') : null;
                            if (vm) {
                                var mest = vm.get('measurement').at(0);
                                if (snapshotValues && mest) {
                                    valueObj = snapshotValues[mest.id];
                                }
                            }
                            currentValue = valueObj ? '' + valueObj.Value : null;
                        } else {
                            var mest = vm.get('measurement').at(0);
                            if (snapshotValues && mest) {
                                valueObj = snapshotValues[mest.id];
                                unit = vm.get('measurement').at(0) ? vm.get('measurement').at(0).get('observedMeasure').get('measure').get('unit') : null;
                                currentValue = window.utils.getRoundedValues(valueObj.Value, unit ? unit.get('significantDecimals') : '2', true);
                            }
                        }
                        if (currentValue) {
                            if (currentValue === '') {
                                valData.push('-');
                            } else {
                                valData.push(currentValue);
                            }
                        } else {
                            valData.push("-");
                        }
                        fillValueData(valData,obs);
                    } 
                }
                // for fetching data from code container instead of mest
                if (isImportedTimeStamp) {
                    if (val.id.indexOf(window.plansKey) >= 0) {
                        altObs = _.find(alt.getOrCreatePhaseObjectiveSet().get('objectiveObservation').models, function (a) { if (a.get('whenObserved')) { return a.get('whenObserved') === obsModel.get('whenObserved'); } });
                    } else {
                        var scenarioObj = Backbone.Relational.store.getObjectByName('vdml.Scenario').find({ id: alt.get('designScenario').models[0].get('scenario') });
                        var altBmobsModels = scenarioObj.get('contextObservation').models;
                        altObs = _.find(altBmobsModels, function (a) { if (a.get('whenObserved')) { return a.get('whenObserved') === obsModel.get('whenObserved'); } });
                    }
                    if (altObs) {
                        var dataRef = altObs.get('dataReference');
                    }
                }
                var vm = val ? val.get('valueMeasurement') : null;
				var unitName = "";
                if (dataRef) {
                    var codeContainer = self.codeContainerList[dataRef];
                    if (codeContainer) {
                        dataStr = codeContainer.get('code');
						data = dataStr ? JSON.parse(dataStr) : null;
						if(data){
							var value;
							if (!vm || vm.get('measurement').at(0) == null) {
								vm = val ? val.get('satisfactionLevel') : null;
								value = data[vm.id];
							} else if(vm && data[vm.id]){
								value = parseFloat(data[vm.id]);
							}
							valData.push(value ?  value : "-");
							unit = vm.get('measurement').at(0) ? vm.get('measurement').at(0).get('observedMeasure').get('measure').get('unit') : null;
							unitName = unit?window.utils.replaceEscapeCharacters(unit.get('name')):'';
						}
						fillValueData(valData,obs,unitName);
                    } else {
						var codeContainer = window.utils.getElementModel(dataRef,['beeppackage.CodeContainer']);
                        //CodeContainer.getBlobInstanceWithId(dataRef, DataManager.getDataManager().get('currentPlan'), function (codeContainer) {
                            if (codeContainer) {
                                dataStr = codeContainer.get('code');
                                data = dataStr ? JSON.parse(dataStr) : null;
								var value;
                                if (!vm || vm.get('measurement').at(0) == null) {
                                    vm = val ? val.get('satisfactionLevel') : null;
									value = data[vm.id];
                                } else if(vm && data[vm.id]){
									value = parseFloat(data[vm.id]);
								}
                                valData.push(value ?  value : "-");
                                unit = vm.get('measurement').at(0) ? vm.get('measurement').at(0).get('observedMeasure').get('measure').get('unit') : null;
                                unitName = unit?window.utils.replaceEscapeCharacters(unit.get('name')):'';
								fillValueData(valData,obs,unitName);
                            } else {
                                console.log('Unable to load selected Package');
								fillValueData(valData,obs);
                            }
                        //});
                    }
                } else {
                    if (obs && obs.snapshot) {
                        getSnapshotData(val);
                    } else {
                        if (mest && mest.get('observedMeasure').get('measure') && (mest.get('value') !== null || mest.get('symbol') !== null)) {
                            unit = mest.get('observedMeasure').get('measure').get('unit');
							var currentValue = window.utils.getRoundedValues(mest.get('value'), unit ? unit.get('significantDecimals') : '2', true);
                            if (mest.get('value') && currentValue === '') {
                                valData.push('-');
                            } else {
                                if (mest.get('value')) {
                                    valData.push(currentValue);
                                } else if (compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
                                    valData.push(mest.get('symbol'));
                                } else {
                                    valData.push("-");
                                }
                            }
                        } else {
                            valData.push("-");
                        }
                        fillValueData(valData,obs);
                    }
                }
            }
        //}
		
		    	
	}
	addMeasurandData(val, altId, whenObserved, mcId, valObj, unitName, measurandData){
		var self = this;
		var valueData = {};
		valueData.valueName = val.get('name');
		valueData.name = val.get('name');
		valueData.valueId = val.get('id');
		valueData.altId = altId;
		valueData.whenObserved = whenObserved;
		valueData.displayName = valObj.namePath? valueData.valueName + " [" + valObj.namePath +"]" : valueData.valueName ;	
		/*if(isTimestamp){
    		valueData.obsName = obsModel.get('name');
    	}
    	valueData.altName = alt.get('name');*/ //Set these both attributes after executing this function //TODO
        // To hide Grade only in the dashboard table
		var labelName = valObj.namePath? valueData.valueName + " [" + valObj.namePath +"]" : valueData.valueName ;
		valueData.label = unitName == "Grade only" ? labelName : labelName + " (" + unitName +")" ;		
		valueData.valId = mcId;
        if(measurandData[unitName]){
            valueData.data = measurandData[unitName].data;
		    measurandData[unitName] = valueData;
        }else{
            valueData.data = [];
            measurandData[unitName] = valueData;
        }
		return measurandData;
	}
	/*fetchImportedTimestampObservation(fetchCallback){
		var self = this;
		var plan = DataManager.getDataManager().get('currentPlan');
		plan.getAllDocumentsCollectionOfType(plan, "smm_Observation", function (results) {
			_.each(results.models,function(observationResultObj){
				var observation = Backbone.Relational.store.getObjectByName('smm.Observation').find({ id: observationResultObj.id });
    			if(observation && !isNaN(observation.get('whenObserved'))){
    				self.importedTimestampObservationList.push(observation);
    			}
			});
			fetchCallback();
         }, null, null, null, "vbc:cmof_EObject-name", true, null, true, true, true);
	}
	loadCodeContainers(timeStamp, callback){
		var self = this;
		async.eachSeries(self.importedTimestampObservationList.models, function (observation, obsUpdated) {
            //var observation = Backbone.Relational.store.getObjectByName('smm.Observation').find({ id: observationResultObj.id });
            if(observation && observation.get('whenObserved') === timeStamp && observation.get('dataReference')){
            	CodeContainer.getBlobInstanceWithId(observation.get('dataReference'),DataManager.getDataManager().get('currentPlan'),function (codeContainer) {
                    if (codeContainer) {
                    	self.codeContainerList[''+observation.get('dataReference')] = codeContainer;
                        obsUpdated();
                    } else {
                        console.log('Unable to load selected Package');
                        obsUpdated();
                    }
                });
            }else {
            	obsUpdated();
            }
        },function(){
        	callback();
    	});
	}*/
	getAltIdOfObservation(observation){
		var plan = DataManager.getDataManager().get('currentPlan');
		if(observation.id.indexOf('@BEEPPlans@')!== -1){
    		return observation.get('phaseObjectiveSet').get('phaseObjectiveSetOwner').id;
    	}else{
    		var context = observation.getContext();
            while (context.get('parentContext')) {
                context = context.get('parentContext');
            }
            var scenarioProxy = plan.getScenarioProxy(context.id);
            if(!scenarioProxy){
            	obsUpdated();
                return;
            }
            return scenarioProxy.get('designScenarioOwner').id;
    	}
	}
	getCodeContainerObjectiveJson() {
		var self = this;
		var plan = DataManager.getDataManager().get('currentPlan');
		plan.getCommonScenario(function (commonScenario) {
			var actualObs = commonScenario.get('contextObservation') ? commonScenario.get('contextObservation').findWhere({ 'name': "Actual" }) : "";
			if (actualObs) {
				var codeContainerId = actualObs.get('codeContainerId');
				CodeContainer.getBlobInstanceWithId(codeContainerId, DataManager.getDataManager().get('currentPlan'), function (codeContainer) {
					//callback(codeContainer)
					if (codeContainer && codeContainer.get('code')) {
                        self.codeContainerObjectiveJson = JSON.parse(codeContainer.get('code'));
                    }
				})
			}
		});
	}
	addMeasureUnit(val, measurandData, unit, valUnit) {//used only for objective
		var self = this;
		measurandData[unit] = {};
		measurandData[unit].altId = window.utils.getPrefix(val.id);
		measurandData[unit].data = [];
		var valid = window.utils.getSuffix(val.id);
		var valFound = $.grep(self.preference.selectedValueObjs, function (e) { return window.utils.getSuffix(e.suffixId) === valid; });
		if (valFound[0] && valFound[0].label && valFound[0].label !== "") {
			var label = unit == "Grade only" ? valFound[0].label : valFound[0].label.concat(" (" + unit + ")");
			measurandData[unit].label = label;
		} else {
			measurandData[unit].label = val.get('name').concat(" (" + unit + ")");
		}
		if (valFound[0] && valFound[0].groupLabel && valFound[0].groupLabel !== "") {
			measurandData[unit].groupLabel = valFound[0].groupLabel;
		} else {
			measurandData[unit].groupLabel = null;
		}
		measurandData[unit].displayName = val.get('name');
		measurandData[unit].name = val.get('name')
		measurandData[unit].significantDecimals = valUnit ? valUnit.get('significantDecimals') : "2";
		measurandData[unit].unit = unit;
		measurandData[unit].valId = val.get('valueMeasurement').get('id')
		measurandData[unit].valueId = val.get('id');
		measurandData[unit].valueName = val.get('name');
		measurandData[unit].type = val.get('type');
	}
	addObjectiveData(measurandData, val, obj, altObj, groupScenario) {
		var self = this;
		var phase = Backbone.Relational.store.getObjectByName('transformation.Phase').find({ id: obj.id });
		var phaseAdded = false;
		var objPhaseNameLabel = "Objective /" + phase.get('name');
		/*for(var k in self.altIndex){
			if(self.altIndex[k] == phaseName){
				phaseAdded = true;
				break;
			}
		}*/
		if (self.codeContainerObjectiveJson && !phaseAdded) {
			var json = self.codeContainerObjectiveJson;
			var found = phase ? _.filter(phase.get('phaseAlternative').models, function (e) { return e.id === altObj.id; }) : "";
			var isPhaseExist = false;
			for (var phaseId in json) {
				if (json[phaseId].phaseMapId === obj.id && found && found.length > 0 && self.preference.selectedObjectiveObjs) {
					var dispPrefix = "obj-";//groupScenario?"Sc-":"obj-";
					var disp = dispPrefix+Object.keys(self.altIndex).length;//phase.get('name')+" Objective"
					var matched = _.filter(self.preference.selectedObjectiveObjs, function (e) { return e.id === json[phaseId].phaseMapId });
					if (matched && matched.length > 0) {
						if (matched[0].displayName != json[phaseId].phaseMapName) {
							matched[0].displayName = disp;
							
						}
					}
					isPhaseExist = true;
					var isExist = false;
					var valData = [];
					var valsuffix = window.utils.getSuffix(val.id);
					for (var key in json[phaseId]['fileContent']) {
						if (json[phaseId]['fileContent'][key] && json[phaseId]['fileContent'][key].valueTargetId === valsuffix) {
							isExist = true;
							var measurement = val.get('valueMeasurement').get('measurement').at(0);
							var valUnit = measurement?measurement.get('observedMeasure').get('measure').get('unit'):'';
							var unit = valUnit.get('name');
							if (!measurandData[unit]) {
								self.addMeasureUnit(val, measurandData, unit, valUnit)
							}
							
							valData.push(disp)//for chart display
							valData.push(json[phaseId]['fileContent'][key].Value ? parseFloat(json[phaseId]['fileContent'][key].Value) : "-")
							valData.push("Objective")
							valData.push("Objective")//for table display //TODO use locale here
							if (self.groupByPhase) {
								if (!_.has(self.altIndex, disp)) {
									self.altIndex[disp] = objPhaseNameLabel;//"Objective /" + json[phaseId].phaseMapName;//(_.keys(self.altIndex)).length;
								}
							} else {
								valData.push(altObj.timeStamp);
								if (!_.has(self.altIndex, disp)) {
									self.altIndex[disp] = objPhaseNameLabel;//"Objective /" + json[phaseId].phaseMapName;//(_.keys(self.altIndex)).length;
								}
							}
							valData.push("");//smileySrc
							valData.push("");//smileyColor
							valData.push(altObj.id);
							valData.push(self.codeContainerId);
							valData.push("");//correctiveActions
							valData['phaseAltObs'] = objPhaseNameLabel;//"Objective /" + json[phaseId].phaseMapName;
							valData['phaseAlt'] = "Objective";
							valData['timeStamp'] = obj.timeStamp;
							valData['phaseName'] = phase.get('name');
							//valData['type'] = val.get('type');
							measurandData[unit].data.push(valData);
							break;
						}
						
					}
					if (!isExist) {
						var measurement = val.get('valueMeasurement').get('measurement').at(0);
						var valUnit = measurement?measurement.get('observedMeasure').get('measure').get('unit'):'';
						var unit = valUnit ? valUnit.get('name') : "";
						if (!measurandData[unit]) {
							self.addMeasureUnit(val, measurandData, unit, valUnit)
						}
						valData.push(disp)
						valData.push("-")
						valData.push("Objective")
						valData.push("Objective")
						if (self.groupByPhase) {
							if (!_.has(self.altIndex, disp)) {
								self.altIndex[disp] = "Objective /" + phase.get('name');//(_.keys(self.altIndex)).length;
							}
						} else {
							valData.push(altObj.timeStamp);
							if (!_.has(self.altIndex, disp)) {
								self.altIndex[disp] = "Objective /" + phase.get('name');//(_.keys(self.altIndex)).length;
							}
						}
						valData.push("");//smileySrc
						valData.push("");//smileyColor
						valData.push(altObj.id);
						valData.push(self.codeContainerId);
						valData.push("");//correctiveActions
						valData['phaseAltObs'] = "Objective /" + phase.get('name');
						valData['phaseAlt'] = "Objective";
						valData['timeStamp'] = obj.timeStamp;
						valData['phaseName'] = phase.get('name');
						measurandData[unit].data.push(valData);
					}
					break;
				}
			}
			if (!isPhaseExist) {
				var valData = [];
				valData.push("Objective")
				valData.push("-")
				valData.push("Objective")
				valData.push("Objective")
				valData.push("");//smileySrc
				valData.push("");//smileyColor
				valData.push(altObj.id);
				valData.push(self.codeContainerId);
				valData.push("");//correctiveActions
				valData['phaseAltObs'] = "Objective /" + "";
				valData['phaseAlt'] = "Objective";
				valData['timeStamp'] = obj.timeStamp;
				valData['phaseName'] = "";
				//measurandData[unit].data.push(valData);
			}
		}
    }
	getValueMeasurements(callback){
		function htmlEscape(str) {
            return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
        }
		var self = this;
		var dataSet = {};
		var hasMoreThanOneValue = false;
		self.margin = 0.05;
		self.altIndex = {};
		self.alts = self.getAlternatives();
		//self.scIndex = {};
		var selectedValues = self.preference.selectedValueObjs;
		var selectedAlts = self.preference.selectedAlternativeObjs;
		var selectedObjs = self.preference.selectedObjectiveObjs;
		if (!selectedObjs) {
			selectedObjs = [];
		}

		/*for (var i = 0; i < selectedObjs.length; i++) {
			if (selectedObjs[i].id) {
				var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: selectedAlts[i] ? selectedAlts[i].id:"" });
				if (alt) {
					var phase = alt.get('phaseAlternativeOwner');
					if (selectedObjs[i].id != phase.id) {
						selectedObjs[i].id = phase.id;
						selectedObjs[i].displayName = phase.get('name') + " objective";
					}
				}
			}
        }*/
		var selectedCalculatedPhases = [];
		self.selectedCalculatedPhaseDates = [];
		_.each(selectedAlts, function(altObj){
			var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: altObj.id });
			if(alt){
				var phase = alt.get('phaseAlternativeOwner');
				var exists = _.find(selectedCalculatedPhases, function(calculatedPhase){return calculatedPhase.get('id') === phase.get('id');})
				if(!exists){
					var phase= alt.get('phaseAlternativeOwner');
					selectedCalculatedPhases.push(alt.get('phaseAlternativeOwner'));
					self.selectedCalculatedPhaseDates.push(phase.get('endDate')? phase.get('endDate') : phase.get('startDate')? phase.get('startDate') :  new Date().getTime());
				}
			}
		});
		var selectedObs = self.preference.selectedObservationObjs;
		var selectedChartType = self.selectedChartType();
		self.showBars = selectedChartType === "Bars" || selectedChartType === "Stacked bars" || selectedChartType === "Columns" || selectedChartType === "Stacked columns"? true : false;
		var tableType = false;
		if(selectedChartType === "Table"){
			tableType = true;
		}
		self.groupByPhase = false;
		self.groupByScenario = false;
		self.groupByTime = false;
		if(self.selectedGroupType() === "Phase/Alternative"){
			self.groupByPhase = true;
		}else if(self.selectedGroupType() === "Scenario"){
			self.groupByScenario = true;
		}else if(self.selectedGroupType() === "Time"){
			self.groupByTime = true;
		}
		function sortByNumber(e1, e2){
			return e1 - e2;
		}
		self.phaseGroupsInBars = [];
		if(self.timeBars()){
			var dayDiffInTimeStamp = 86400000;
			var weekDiffInTimeStamp = 604800000;
			var monthDiffInTimeStamp = 2629743000;
			var quarterlyDiffInTimeStamp = 7889231500;
			var yearDiffInTimeStamp = 31556926000;
			self.selectedCalculatedPhaseDates.sort(sortByNumber);
			var actualMinValue = self.selectedCalculatedPhaseDates[0];
			var actualMaxValue = self.selectedCalculatedPhaseDates[self.selectedCalculatedPhaseDates.length-1];
			//$("#minimumDatepicker"+self.encodeId).datepicker();
	       // $("#maximumDatepicker"+self.encodeId).datepicker();
			$("#minimumDatepicker"+self.encodeId).datepicker('option', {minDate: new Date(actualMinValue), maxDate: new Date(actualMaxValue)});
	    	$("#maximumDatepicker"+self.encodeId).datepicker('option', {minDate: new Date(actualMinValue), maxDate: new Date(actualMaxValue)});
	    	if(!self.minimumDate()){
	    		self.minimumDate(DateFormat.getDateFromTimestamp(actualMinValue));
	    	}
	    	if(!self.maximumDate()){
	    		self.maximumDate(DateFormat.getDateFromTimestamp(actualMaxValue));
	    	}
	    	self.minValueInBars = Date.parse(self.minimumDate());
			self.maxValueInBars = Date.parse(self.maximumDate());
			var interval;
			if(self.selectedCalculatedPhaseDates.length > 1 && !self.selectedIntervalValue){
				var minDiff = self.selectedCalculatedPhaseDates[1] - self.selectedCalculatedPhaseDates[0];
				var validMinDiff;
				for (var i = 2 ; i != self.selectedCalculatedPhaseDates.length ; i++) {
				    validMinDiff = Math.min(minDiff, self.selectedCalculatedPhaseDates[i] - self.selectedCalculatedPhaseDates[i-1]);
				    if(validMinDiff !== 0){
				    	minDiff = validMinDiff;
				    }
				}
				self.coincidentPhases = minDiff === 0? true: false;	
				if(minDiff/yearDiffInTimeStamp >= 0.97 || !minDiff){// 0.97 is error correction. year has 365/366 days
					interval = "Year";
				}else if(minDiff/quarterlyDiffInTimeStamp >= 0.95){
					interval = "Quarter"
				}else if(minDiff/monthDiffInTimeStamp >= 0.95){
					interval = "Month";
				}else if(minDiff/weekDiffInTimeStamp >= 1){
					interval = "Week";
				}else if(minDiff/dayDiffInTimeStamp >= 1){
					interval = "Day";
				}
				self.selectedIntervalValue(interval? interval : "Day");
			}else if(self.selectedCalculatedPhaseDates.length === 1 && !self.selectedIntervalValue){
				self.selectedIntervalValue("Day");
			}
			if(self.selectedIntervalValue()){
				var firstGroup = self.minValueInBars;
				var dateFormat = new Date(firstGroup);
				if(self.selectedIntervalValue() === "Year"){
					dateFormat = new Date(dateFormat.getFullYear(), 0, 1);
				}else if(self.selectedIntervalValue() === "Month" || self.selectedIntervalValue() === "Quarter"){
					dateFormat = new Date(dateFormat.getFullYear(), dateFormat.getMonth(), 1);
				}
				firstGroup = Date.parse(dateFormat);
				while(firstGroup <= self.maxValueInBars){
					self.phaseGroupsInBars.push(firstGroup);
					if(self.selectedIntervalValue() === "Year"){
						firstGroup = Date.parse(new Date(dateFormat.getFullYear() + 1, 0, 1));
					}else if(self.selectedIntervalValue() === "Quarter"){
						if (dateFormat.getMonth() == 9) {
						    firstGroup = Date.parse(new Date(dateFormat.getFullYear() + 1, 0, 1));
						}else if(dateFormat.getMonth() == 10){
							firstGroup = Date.parse(new Date(dateFormat.getFullYear() + 1, 1, 1));
						}else if(dateFormat.getMonth() == 11){
							firstGroup = Date.parse(new Date(dateFormat.getFullYear() + 1, 2, 1));
						}else {
						    firstGroup = Date.parse(new Date(dateFormat.getFullYear(), dateFormat.getMonth() + 3, 1));
						}
					}else if(self.selectedIntervalValue() === "Month"){
						if (dateFormat.getMonth() == 11) {
						    firstGroup = Date.parse(new Date(dateFormat.getFullYear() + 1, 0, 1));
						} else {
						    firstGroup = Date.parse(new Date(dateFormat.getFullYear(), dateFormat.getMonth() + 1, 1));
						}
					}else if(self.selectedIntervalValue() === "Week"){
						firstGroup = firstGroup + weekDiffInTimeStamp;
					}else if(self.selectedIntervalValue() === "Day"){
						firstGroup = firstGroup + dayDiffInTimeStamp;
					}
					dateFormat = new Date(firstGroup);
				}
			}
		}
		self.requiredIntervals = [];
		var config = window.vdmModelView.model.get('config');
		var scenariosList = config? JSON.parse(config) : {};
		if(self.groupByScenario){
			async.each(selectedAlts, function(altObj, altHandleCallback){
				var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: altObj.id });
				if (!alt) {
					altHandleCallback();
					return;
				}
				async.eachSeries(selectedValues,function(valObj,valHandleCallback){
				    var measure;
					var measurandData = {};
					var val = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: valObj.id });
					if(!val){
						val = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: valObj.id });
					}
					if(val){
						measure = val.get('valueMeasurement');
					}
					if (!val) {
						valHandleCallback();
						return;
					}
					if(tableType){//limiting objectives too table
						_.each(selectedObjs, function (obj) {
							self.addObjectiveData(measurandData, val, obj, altObj, true);
						});
					}
					//	objHandleCallback();
					//}, function () {
						async.eachSeries(selectedObs, function (obs, obsHandleCallback) {
							var obsModel = Backbone.Relational.store.getObjectByName('smm.Observation').find({ id: obs.id });
							if (!obsModel) {
								obsHandleCallback();
								return;
							}
							if (obsModel.get('whenObserved')) {
								var startingAlt = _.find(self.alts, function (alt) { return _.contains(scenariosList[alt.id], obsModel.get('whenObserved')) });
								var startingPhaseIdOfScenario = startingAlt ? startingAlt.get('phaseAlternativeOwner').id : null;
							}
							var phaseId = alt.get('phaseAlternativeOwner').id;
							var hidePreviousAltData = false;
							// To hide the scenario in previous phases on user's choice
							if (startingPhaseIdOfScenario && _.indexOf(self.phaseIdsChronologicalList, phaseId) < _.indexOf(self.phaseIdsChronologicalList, startingPhaseIdOfScenario)) {
								hidePreviousAltData = true;
							}
							if (!isNaN(obs.timeStamp)) {
								if (self.importedTimestampObservationList.findWhere({ 'whenObserved': obs.timeStamp })) {
									//self.loadCodeContainers(obs.timeStamp, function () {
										self.loadSelectedValues(alt, obsModel, valObj, val, measurandData, hidePreviousAltData, obs, function (measurandDataCalculated) {
											measurandData = measurandDataCalculated;
											/*async.setImmediate(function () {
												obsHandleCallback();
											});*/
											altHandleCallback();
										});
									//});
								} else {
									//self.fetchImportedTimestampObservation(function () {
										//self.loadCodeContainers(obs.timeStamp, function () {
											self.loadSelectedValues(alt, obsModel, valObj, val, measurandData, hidePreviousAltData, obs, function (measurandDataCalculated) {
												measurandData = measurandDataCalculated;
												async.setImmediate(function () {
													obsHandleCallback();
												});
											});
										//});
									//});
								}
							} else {
								self.loadSelectedValues(alt, obsModel, valObj, val, measurandData, hidePreviousAltData, obs, function (measurandDataCalculated) {
									measurandData = measurandDataCalculated;
									async.setImmediate(function () {
										obsHandleCallback();
									});
								});
							}
						}, function () {
							var emptyData = measurandData?measurandData[""]:null;
							if (emptyData) {
								delete measurandData[""];
							}
							if(measurandData != null && Object.getOwnPropertyNames(measurandData).length > 0){
								Object.getOwnPropertyNames(measurandData).forEach(function (key) {
									var valueCount = 0;
									var valueData = measurandData[key];
									if (emptyData) {
										valueData.data = valueData.data.concat(emptyData.data);
									}
									for (var i = 0; i < valueData.data.length; i++) {
										if (valueData.data[i][1] != null) {
											valueCount++;
										}
									}
									var unitString = window.utils.replaceEscapeCharacters(valueData.unit);
									if (tableType) {
										if (dataSet[measure.id + unitString]) {
											dataSet[measure.id + unitString].data = dataSet[measure.id + unitString].data.concat(valueData.data);
										} else {
											dataSet[measure.id + unitString] = valueData;
										}
									} else {
										dataSet[measure.id + unitString + window.utils.htmlEscape(alt.get('id'))] = valueData;
									}
									if (valueCount > 1) {
										hasMoreThanOneValue = true;
									}
								});
							}
							valHandleCallback();
						});
					//});
				}, function(){			
					altHandleCallback();
				});
			},function(){
				self.datasets = dataSet;
				callback();
			});
		}else {
			async.eachSeries(selectedObs, function(obs, obsHandleCallback){
				var obsModel = Backbone.Relational.store.getObjectByName('smm.Observation').find({ id: obs.id });
				if (!obsModel && !obs.snapshot) {
	                obsHandleCallback();
	                return;
	            }
				// find starting point of scenario
				if(obsModel && obsModel.get('whenObserved')){
					var startingAlt = _.find(self.alts, function(alt){return _.contains(scenariosList[alt.id], obsModel.get('whenObserved'))});
					var startingPhaseIdOfScenario = startingAlt?startingAlt.get('phaseAlternativeOwner').id:null;
				}
				
	            async.eachSeries(selectedValues, function(valObj, valHandleCallback){
	            	var measure;
					var measurandData = {};
					var val = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({ id: valObj.id });
					if(!val){
						val = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({ id: valObj.id });
					}
					if(val){
						measure = val.get('valueMeasurement');
					}
					if (!val) {
                        valHandleCallback();
                        return;
                    }
					async.eachSeries(selectedAlts, function (altObj, altHandleCallback) {
								var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: altObj.id });
								if (!alt) {
									altHandleCallback();
									return;
								}
								if(tableType){//limiting objectives too table
									_.each(selectedObjs, function (obj) {
										self.addObjectiveData(measurandData, val, obj, altObj, false);
									});
								}
								if (altObj.timeStamp) {

								} else {
									
									var phaseofAlt = alt.get('phaseAlternativeOwner');
									if (self.groupByTime && phaseofAlt.get('primary').id !== alt.id) {
										altHandleCallback();
										return;
									}
									var phaseId = phaseofAlt.id;
									var hidePreviousAltData = false;
									// To hide the scenario in previous phases on user's choice
									if (startingPhaseIdOfScenario && _.indexOf(self.phaseIdsChronologicalList, phaseId) < _.indexOf(self.phaseIdsChronologicalList, startingPhaseIdOfScenario)) {
										hidePreviousAltData = true;
									}
									if (!isNaN(obs.timeStamp)) {
										if (self.importedTimestampObservationList.findWhere({ 'whenObserved': obs.timeStamp })) {
											//self.loadCodeContainers(obs.timeStamp, function () {
												self.loadSelectedValues(alt, obsModel, valObj, val, measurandData, hidePreviousAltData, obs, function (measurandDataCalculated) {
													measurandData = measurandDataCalculated;
													async.setImmediate(function () {
														altHandleCallback();
													});
												});
											//});
										} else {
											//self.fetchImportedTimestampObservation(function () {
												//self.loadCodeContainers(obs.timeStamp, function () {
													self.loadSelectedValues(alt, obsModel, valObj, val, measurandData, hidePreviousAltData, obs, function (measurandDataCalculated) {
														measurandData = measurandDataCalculated;
														async.setImmediate(function () {
															altHandleCallback();
														});
													});
												//});
											//});
										}

									}
									else {
										self.loadSelectedValues(alt, obsModel, valObj, val, measurandData, hidePreviousAltData, obs, function (measurandDataCalculated) {
											measurandData = measurandDataCalculated;
											async.setImmediate(function () {
												altHandleCallback();
											});
										});
									}
								}
					}, function(){
						var emptyData = measurandData?measurandData[""]:null;
						if(emptyData){
							delete measurandData[""];
						}
						if(measurandData != null && Object.getOwnPropertyNames(measurandData).length > 0){
							Object.getOwnPropertyNames(measurandData).forEach(function(key) {
								var valueCount =0;
								var valueData = measurandData[key];
								if(emptyData){
							  		valueData.data = valueData.data.concat(emptyData.data);
								}
								for(var i=0;i<valueData.data.length;i++){
									if(valueData.data[i][1] != null){
										valueCount++;	
									}
								}
								var unitString = window.utils.replaceEscapeCharacters(valueData.unit ? valueData.unit : "");
								if(tableType){
									if(dataSet[measure.id + unitString]){
										dataSet[measure.id + unitString].data = dataSet[measure.id + unitString].data.concat(valueData.data);
									}else{
										dataSet[measure.id + unitString] = valueData;
									}
								}else{
									if ((obsModel && obsModel.get('whenObserved')) || obs.snapshot) {
										var whenObserved = obsModel ? obsModel.get('whenObserved') : obs.timeStamp;
										dataSet[measure.id + unitString + whenObserved] = valueData;
									}else{
										dataSet[measure.id + unitString] = valueData;	
									}	
								}
								if(valueCount > 1){
									hasMoreThanOneValue = true;
								}
							   
							});
							valHandleCallback(); 
						}else{
						     valHandleCallback();
						}
				     });
	            }, function(){
	            	obsHandleCallback();
	            });
				
			},function(){
				self.datasets = dataSet;
				callback();
			});
		}
				
	};
	
	getAlternatives(){
		var alts = [];
		var currentPlan = DataManager.getDataManager().get('currentPlan');
		var phase = currentPlan.get('phase').findWhere({nextPhase:null});
		while(phase){
			var phaseAltsArray = [];
			var phaseAlts = phase.get('phaseAlternative');
			var masterAlt = phase.get('master');
			phaseAlts.each(function(phaseAlt){
				if(phaseAlt !== masterAlt){
					phaseAltsArray.push(phaseAlt);
				}
			});
			phaseAltsArray.unshift(masterAlt);
			alts = phaseAltsArray.concat(alts);
			phase = phase.get('previousPhase');
		}		
		return alts;
	};

	getXAxisData(refresh) {
	    var self = this;
	    var xAxisData = [];
	    var currentPlan = DataManager.getDataManager().get('currentPlan');
	    var phase = currentPlan.get('phase').findWhere({ nextPhase: null });
	    while (phase) {
	        var phaseData = {};
	        xAxisData.unshift(phaseData);
	        phaseData.id = phase.get('id');
	        phaseData.parent = '#';
	        phaseData.text = phase.get('name');
	        if (self.preference && (_.indexOf(self.preference.xAxisSelected, phaseData.id) >= 0)) {
	            phaseData.selected = true;
	            self.xAxisSelected.push(phaseData.id);
	        }

	        var phasePrimary = phase.get('primary');
	        if (phasePrimary && !self.preference && !refresh) {
	            self.xAxisSelected.push(phasePrimary.get('id'));
	        }


	        var phaseAlts = phase.get('phaseAlternative');
	        phaseAlts.each(function (phaseAlt) {
	            var altData = {};
	            altData.id = phaseAlt.get('id');
	            altData.parent = phase.get('id');
	            altData.text = phaseAlt.get('name');
	            xAxisData.push(altData);
	            if (!self.preference && (phasePrimary === phaseAlt)) {
	                altData.state = { opened: true, selected: true };
	            }
	            if (self.preference && (_.indexOf(self.preference.xAxisSelected, phaseAlt.get('id')) >= 0)) {
	                altData.state = { opened: true, selected: true };
	                self.xAxisSelected.push(phaseAlt.get('id'));
	            }
	            var phObjSet = phaseAlt.get('phaseObjectiveSet');
	            if(phObjSet) {
		            var objObservations = phObjSet.get('objectiveObservation');
		            objObservations.each(function (objObs) {
		                if (objObs.get('whenObserved') != null) {
		                    var altData = {};
		                    altData.id = phaseAlt.get('id');
		                    altData.parent = phase.get('id');
		                    altData.text = phaseAlt.get('name') + "(" + objObs.get('whenObserved') + ")";
		                    altData.timeStamp = objObs.get('whenObserved');
		                    altData.id = altData.id + altData.timeStamp;
		                    xAxisData.push(altData);
		                    if (self.preference && (_.indexOf(self.preference.xAxisSelected, phaseAlt.get('id') + altData.timeStamp) >= 0)) {
		                        altData.state = { opened: true, selected: true };
		                        self.xAxisSelected.push(phaseAlt.get('id') + altData.timeStamp);
		                    }
		                }
		            });
		            self.xAxisSelected = _.uniq(self.xAxisSelected);
	            }
	        });
	        phase = phase.get('previousPhase');
	    }
	    return xAxisData;
	};
	
	showAggregationView(view) {
		var self = this;
        var valueId = view.valueId;
		var altModel = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: view.altId });
		altModel.showAggregationView(valueId, function(){
			window.utils.startSpinner('refreshValues','Refreshing ...');
			DataManager.getDataManager().invokeValueUpdates(function(){
				if(self.parentView) {
					window.vdmModelView.setResizeHeight(true);//TODO refresh only charts and strategymap
					setTimeout(function(){
						window.utils.stopSpinner('refreshValues');	
					}, 300);
				}else {
					window.utils.stopSpinner('refreshValues');	
				}
			});
		});       
    };


init(model,options){
    var self = this;
    this.datasets = {};
    this.values = [];
    this.DashboardValuesChart = this;
    this.model = model;
    this.chartInitialized = false;
    if (options && options.parentView) {
        this.parentView = options.parentView;
    }
    this.preference = JSON.parse(this.model.get('chartConfig'));
    this.name = kb.observable(model, 'name');
    this.id = kb.observable(model, 'id');
    this.importedTimestampObservationList = new Backbone.Collection;
    this.codeContainerList = {};
    this.chartTypes = ko.observableArray(['Curves', 'Stacked bars', 'Stacked columns', 'Bars', 'Columns', /*'Radar',*/ 'Table', 'Polar', 'Spiderweb']);
    this.chartTypes.sort();
    function htmlEscape(str) {
        return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
    }
	function saveChartData(name,value){
		var json = JSON.parse(self.model.get("chartConfig"));
			if(value != null){
				json[name] = value
			}
			
		self.model.set("chartConfig", JSON.stringify(json));
	}
	this.getCodeContainerObjectiveJson();
    this.encodeId = this.model ? htmlEscape(this.model.id) : null;
    this.description = kb.observable(model, 'description');
    self.xAxisSelected = [];
    this.implementationDate = ko.observable();
    this.selectedChartType = ko.observable();
    this.selectedGroupType = ko.observable();
    this.selectedIntervalValue = ko.observable();
    this.groupTypes = ko.observableArray(['Phase/Alternative', 'Scenario', 'Time']);
    this.intervalList = ko.observableArray(['Day', 'Month', 'Quarter', 'Week', 'Year']);
    this.minimumDate = ko.observable();
    this.maximumDate = ko.observable();
    this.alignScales = ko.observable(false);
    this.showValues = ko.observable(false);
	this.showLegend = ko.observable(true);
    this.timeBars = ko.observable(false);
    //this.annotationNonEmpty = ko.observable(false);
    this.enableZoom = ko.observable(false);
    this.xaxisMin = {};
    this.xaxisMax = {};
    this.yaxes1Min = {};
    this.yaxes1Max = {};
    this.yaxes2Min = {};
    this.yaxes2Max = {};
    this.chartColors = ["#EDC951", "#F08080", "#FFA07A", "#4169E1", "#98FB98", "#EDC951", "#87CEFA", "#ceaeff", "#003366", "#0066ff"]
    if (options.savedMinimum) {
        this.minimumDate(DateFormat.getDateFromTimestamp(options.savedMinimum));
    }
    if (options.savedMaximum) {
        this.maximumDate(DateFormat.getDateFromTimestamp(options.savedMaximum));
    }
    if (options.selectedChartType) {
        this.selectedChartType(options.selectedChartType);
    } else {
        this.selectedChartType("Table");
    }
    if (options.selectedGroupType) {
        this.selectedGroupType(options.selectedGroupType)
    }
    if (options.savedInterval) {
        this.selectedIntervalValue(options.savedInterval)
    }
    if (options.showValues != null) {
        this.showValues(options.showValues);
    }
	if (options.showLegend != null) {
        this.showLegend(options.showLegend);
    }
    this.minimumDate.subscribe(function (val) {
		saveChartData("savedMinimum",val)
        self.refreshChart();
    });
    this.maximumDate.subscribe(function (val) {
		saveChartData("savedMaximum",val)
        self.refreshChart();
    });
	this.alignScales.subscribe(function (val) {
        self.refreshChart();
    });
    this.showValues.subscribe(function (val) {
        if (val) {
            self.showValues(true);
			saveChartData("showValues",true)
            self.refreshChart();
        } else {
            self.showValues(false);
			saveChartData("showValues",false)
            self.refreshChart();
        }
    });
	this.showLegend.subscribe(function (val) {
		if(self.preference){
			self.preference.showLegend = val;
		}
        if (val) {
            self.showLegend(true);
			saveChartData("showLegend",true)
            //self.refreshChart();
        } else {
            self.showLegend(false);
			saveChartData("showLegend",false)
            //self.refreshChart();
        }
		self.plotAccordingToChoices(true);
		self.initializePresentation(true);
    });

    this.selectedChartType.subscribe(function (selectedType) {
        if (!self.model || !self.chartInitialized) return;
        self.preference = JSON.parse(self.model.get('chartConfig'));
        if (self.plot) {
            //self.plot.shutdown();
        }
        if (self.dataTable) {
            self.dataTable.destroy(true);
        }
        //d3.select("#placeholder" + self.encodeId + ' svg').remove();
        self.maxUnitsPermitted = selectedType === "Stacked bars" || selectedType === "Stacked columns" ? 1 : 2;
        if (self.maxUnitsPermitted === 1) {
            var defaultUnitsArray = [];
            var units = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:checked");
            if (units.length > 0) {
                defaultUnitsArray.push(units[0].value);
                var label1 = window.utils.restoreEscapeCharacters(units[0].value);
                $('#yaxis' + self.encodeId).multiselect('select', label1);
                if (units.length > 1) {
                    var label2 = window.utils.restoreEscapeCharacters(units[1].value);
                    $('#yaxis' + self.encodeId).multiselect('deselect', label2);
                }
                self.preference.yAxisSelected = defaultUnitsArray;
                self.model.set('chartConfig', JSON.stringify(self.preference));
                var checkedAtr = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:checked");
                var uncheckedAtr = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:not(:checked)");
                if (checkedAtr.length >= self.maxUnitsPermitted) {
                    uncheckedAtr.attr("disabled", true);
                    uncheckedAtr.parent().attr("disabled", true);
                    uncheckedAtr.parent().css('cursor', "no-drop");
                } else {
                    uncheckedAtr.attr("disabled", false);
                    uncheckedAtr.parent().attr("disabled", false);
                    uncheckedAtr.parent().css('cursor', "pointer");
                }
            }
        } else {
            var checkedAtr = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:checked");
            var uncheckedAtr = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:not(:checked)");
            if (checkedAtr.length >= self.maxUnitsPermitted) {
                uncheckedAtr.attr("disabled", true);
                uncheckedAtr.parent().attr("disabled", true);
                uncheckedAtr.parent().css('cursor', "no-drop");
            } else {
                uncheckedAtr.attr("disabled", false);
                uncheckedAtr.parent().attr("disabled", false);
                uncheckedAtr.parent().css('cursor', "pointer");
            }
        }
        if (selectedType === "Table") {
            $('#groupByRow' + self.encodeId).hide();
            $('#alignScales' + self.encodeId).hide();
            $('#units' + self.encodeId).hide();
            $('#showValues' + self.encodeId).hide();
			$('#showLegend' + self.encodeId).hide();
            //            	$('#printChart'+self.encodeId).hide();
            self.resizeChartWithGridResize(true);
        } else if (selectedType === "Radar" || selectedType === "Polar" || selectedType === "Spiderweb") {
            $('#groupByRow' + self.encodeId).show();
            $('#alignScales' + self.encodeId).hide();
            $('#units' + self.encodeId).hide();
            $('#showValues' + self.encodeId).show();
			$('#showLegend' + self.encodeId).show();
            self.resizeChartWithGridResize(true);
        } else {
            $('#groupByRow' + self.encodeId).show();
            $('#alignScales' + self.encodeId).show();
            $('#units' + self.encodeId).show();
            $('#showValues' + self.encodeId).show();
			$('#showLegend' + self.encodeId).show();
            //  				$('#printChart'+self.encodeId).show();
        }
        if (self.model) {
            var gradeOnlyCount = 0;
            _.each(self.preference.selectedValueObjs, function (option) {
                if (option.gradeOnly) {
                    gradeOnlyCount++;
                }
            });
            if (selectedType === 'Radar' && self.groupByPhase && ((self.preference.selectedValueObjs.length * self.preference.selectedObservationObjs.length) - gradeOnlyCount) < 3) {
                bootbox.alert(DataManager.getDataManager().get('localeManager').get('radarMessage'));
                self.selectedChartType('Curves');
            } else if (selectedType === 'Radar' && self.groupByScenario && ((self.preference.selectedValueObjs.length * self.preference.selectedAlternativeObjs.length) - gradeOnlyCount) < 3) {
                bootbox.alert(DataManager.getDataManager().get('localeManager').get('radarMessage'));
                self.selectedChartType('Curves');
            } else {
                if (selectedType === "Radar" || selectedType === "Table" || selectedType === "Spiderweb" || selectedType === "Polar") {
                    $("#groupBy" + self.encodeId + " option[value='Time']").attr('disabled', 'disabled');
                } else {
                    $("#groupBy" + self.encodeId + " option[value='Time']").removeAttr('disabled');
                }
                self.selectedChartType(selectedType);
                self.refreshChart();
            }
        }
		saveChartData("selectedChartType",selectedType)
    });
    this.selectedGroupType.subscribe(function (val) {
        if (!self.model || !self.chartInitialized) return;
        if (val === "Phase/Alternative") {
            self.groupByPhase = true;
            self.timeBars(false)
        } else if (val === "Scenario") {
            self.groupByScenario = true;
            self.timeBars(false)
        } else if (val === "Time") {
            self.groupByTime = true;
            self.timeBars(true);
        }
        var gradeOnlyCount = 0;
        _.each(self.preference.selectedValueObjs, function (option) {
            if (option.gradeOnly) {
                gradeOnlyCount++;
            }
        });
        if (val === "Time") {
            $("#selectChartType" + self.encodeId + " option[value='Radar']").attr('disabled', 'disabled');
            $("#selectChartType" + self.encodeId + " option[value='Table']").attr('disabled', 'disabled');
            $("#selectChartType" + self.encodeId + " option[value='Spiderweb']").attr('disabled', 'disabled');
            $("#selectChartType" + self.encodeId + " option[value='Polar']").attr('disabled', 'disabled');
        } else {
            $("#selectChartType" + self.encodeId + " option[value='Radar']").removeAttr('disabled');
            $("#selectChartType" + self.encodeId + " option[value='Table']").removeAttr('disabled');
            $("#selectChartType" + self.encodeId + " option[value='Spiderweb']").removeAttr('disabled');
            $("#selectChartType" + self.encodeId + " option[value='Polar']").removeAttr('disabled');
        }
        if (self.selectedChartType() === 'Radar' && self.groupByPhase && ((self.preference.selectedValueObjs.length * self.preference.selectedObservationObjs.length) - gradeOnlyCount) < 3) {
            bootbox.alert(DataManager.getDataManager().get('localeManager').get('radarMessage'));
            self.selectedChartType('Curves');
        } else if (self.selectedChartType() === 'Radar' && self.groupByScenario && ((self.preference.selectedValueObjs.length * self.preference.selectedAlternativeObjs.length) - gradeOnlyCount) < 3) {
            bootbox.alert(DataManager.getDataManager().get('localeManager').get('radarMessage'));
            self.selectedChartType('Curves');
        }
		saveChartData("selectedGroupType",val)
        self.refreshChart();
		});
		this.selectedIntervalValue.subscribe(function (val) {
			if ($('#placeholder' + self.encodeId) && $('#placeholder' + self.encodeId).length > 0) {
				self.selectedIntervalValue(val);
				saveChartData("savedInterval",val)
				self.refreshChart();
			}
		});
	}
    //#endCustomMethods
	static getInstance = function(model,options){
			var view = new DashboardValuesChart(model, options);
			//view.init(model, options);
			return view;

	};
}
path.DashboardValuesChart = DashboardValuesChart;