import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as async from 'async'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {BMValuesChart} from '../chart/BMValuesChart'
import {BMGuidanceViewModel} from '../../../help/views/properties/BMGuidanceViewModel'
import {BMValueDetailsViewModel} from '../properties/BMValueDetailsViewModel'
import {BMDetailsViewModel} from '../properties/BMDetailsViewModel'
import {BusinessModelMixin} from '../../../../bo/vdml/BusinessModelMixin'
import {ValueElementMixin} from '../../../../bo/vdml/ValueElementMixin'
import {BMCanvasDiagramMixin} from '../../../../bo/canvas/BMCanvasDiagramMixin'
import * as Highcharts from 'highcharts';
//import theme from 'highcharts/themes/dark-unica';
//theme(Highcharts);
import {HighChartThemes} from '../../../../../com/vbee/utils/HighChartThemes'
/*define(["require","jquery","enhsplitter","underscore","backbone","async","knockout","knockoutMapping","knockback","bootbox","spin","appcommon/com/vbee/data/DataManager","app/global","appbo/vdml/BusinessModel","appviews/vdml/views/chart/BMValuesChart","appbo/vdml/BusinessModelMixin",
		"appviews/vdml/views/properties/BMDetailsViewModel","appbo/tickets/User","appviews/vdml/views/properties/BMValueDetailsViewModel","appviews/help/views/properties/BMGuidanceViewModel","appbo/canvas/BMCanvasDiagramMixin","appbo/vdml/ValueElementMixin","appcommon/com/vbee/utils/HighChartThemes", "highcharts-common"],
function(require,$,enhsplitter,_,Backbone,async,ko,koMapping,kb,bootbox,Spinner,DataManager,global,BusinessModel,BMValuesChart,BusinessModelMixin
, BMDetailsViewModel,User,BMValueDetailsViewModel,BMGuidanceViewModel,BMCanvasDiagramMixin,ValueElementMixin,HighChartThemes, Highcharts
){*/
	
    var path = DataManager.getDataManager().buildAppNsPath("vdml.views.bmcube", global.version);
    export class BMCubeViewModel {
    
    refreshWidgetData(cond) {
        var self = this;
        self.chartViewInstance.initialize(self.model, cond, self);
        self.bmValueWidgetInstance.initialize(self.model);
    };
    showBmPage() {
        var self = this;
        var bo = self.model;
        window.utils.startSpinner('bmCubeSpinner', "Navigating to Business Model Properties...");
        DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + bo.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + bo.getNestedParent().get('version') + "/" + bo.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('currentWorkspace').get('id'), { trigger: true });
        //DataManager.getDataManager().get('router').navigate("views/" + bo.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + bo.getNestedParent().get('version') + "/" + bo.get('id') + "/vdml_BusinessModel/" + DataManager.getDataManager().get('currentWorkspace').get('id'), { trigger: true });
    }
    showobjectExplorerTree() {
        var self = this;
        var addOptions = this;
        var vdm = self.model.getNestedParent();
        function closeView() {
            window.cleanDialogModel(self.encodeId, BusinessModelMixin);
        }
        window.getAndCreateModalDialog(true, self.encodeId, BusinessModelMixin, vdm, "explorerModelJson", closeView, addOptions);
    }; 
    handlePublicMenus() {
        var self = this;
        //DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-plus","New",$.proxy(handleNew,this));
        //DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-remove","Query1",$.proxy(this.execQ1,this));
        //DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-remove","Query2",$.proxy(this.execQ2,this));
        //	DataManager.getDataManager().addContextMenu("glyphicon glyphicon-eye-open","Advanced Options",$.proxy(this.showMisclaneousDetails,this),$("#actionsList"));
        this.zoomFactor = 1;
        /*DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-zoom-in","Zoom-in",$.proxy(self.zoomIn,this));
        DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-zoom-out","Zoom-out",$.proxy(self.zoomOut,this));*/
        
        DataManager.getDataManager().addContextBarMenu("glyphicon glyphicon-wrench", "Show Object Explorer", $.proxy(this.showobjectExplorerTree, this));
        var props = 'transform WebkitTransform MozTransform OTransform msTransform'.split(' '),
            prop,
            el = document.createElement('div');

        for (var i = 0, l = props.length; i < l; i++) {
            if (typeof el.style[props[i]] !== "undefined") {
                this.prop = props[i];
                break;
            }
        }
        this.handleKeyEvents();
    }
    zoomOut() {
        this.cubeLength = this.cubeLength - 10;
        this.adjustCubeContainerProperties();
        this.adjustCubeProperties();
        this.adjustFaceProperties();

        this.adjustSideOne();
        this.adjustSideTwo();
        this.adjustSideThree();
        this.adjustSideFour();
        this.adjustSideFive();
        this.adjustSideSix();
    }
    zoomIn() {
        this.cubeLength = this.cubeLength + 10;
        this.adjustCubeContainerProperties();
        this.adjustCubeProperties();
        this.adjustFaceProperties();

        this.adjustSideOne();
        this.adjustSideTwo();
        this.adjustSideThree();
        this.adjustSideFour();
        this.adjustSideFive();
        this.adjustSideSix();
    };
    adjustCubeContainerProperties() {
        var cubeContainer = $('.cubeviewport');

        cubeContainer[0].style[this.perspectiveProp] = this.cubeLength * 4 + 'px';
        cubeContainer[0].style[this.perspectiveOriginProp] = '50% ' + this.cubeLength + 'px';
    };
    adjustCubeProperties() {
        var cube = $('.cube');
        cube.css('height', this.cubeLength * 2 + 'px');
        cube.css('width', this.cubeLength * 2 + 'px');
    };
    adjustFaceProperties() {
        var face = $('.cube > div');
        face.css('height', (this.cubeLength * 2 - 40) + 'px');
        face.css('width', (this.cubeLength * 2 - 40) + 'px');
    };

    adjustSideOne(){
        var side = $('.cube > div:first-child');
        side[0].style[this.transformProp] = 'rotateX(90deg) translateZ(' + this.cubeLength + 'px';
    };
    adjustSideTwo() {
        var side = $('.cube > div:nth-child(2)');
        side[0].style[this.transformProp] = 'translateZ(' + this.cubeLength + 'px';
    };
    adjustSideThree() {
        var side = $('.cube > div:nth-child(3)');
        side[0].style[this.transformProp] = 'rotateY(90deg) translateZ(' + this.cubeLength + 'px';
    };

    adjustSideFour() {
        var side = $('.cube > div:nth-child(4)');
        side[0].style[this.transformProp] = 'rotateY(180deg) translateZ(' + this.cubeLength + 'px';
    };
    adjustSideFive() {
        var side = $('.cube > div:nth-child(5)');
        side[0].style[this.transformProp] = 'rotateY(-90deg) translateZ(' + this.cubeLength + 'px';
    };
    adjustSideSix() {
        var side = $('.cube > div:nth-child(6)');
        side[0].style[this.transformProp] = 'rotateX(-90deg) rotate(180deg) translateZ(' + this.cubeLength + 'px';
    };	

    cleanUp() {
        var self = this;
        if(!self.BMCubeViewModel){
            return;
        }
        self.customerArray.length = 0;
        self.customer.removeAll();
        self.valuePropositionArray.length = 0;
        self.valueProposition.removeAll();
        self.activityArray.length = 0;
        self.activity.removeAll();
        self.competenceArray.length = 0;
        self.competence.removeAll();
        self.networkPartnerArray.length = 0;
        self.networkPartner.removeAll();
        self.valueFormulaArray.length = 0;
        self.valueFormula.removeAll();
        self.valuesArray.length = 0;
        self.values.removeAll();
        self.spliter.enhsplitter('remove');
        $(window, ".resize").unbind('resize');
        if (ko.components.isRegistered('CubeGuidance')) {
            ko.components.unregister('CubeGuidance');
            ko.components.unregister('valuewidget');
            ko.components.unregister('BMDetailsWidget');
            ko.components.unregister('BMValuesChart');
			//window.utils.loadTemplate(null,true);
        }
        if(self.highchartsDefaultTheme){
	    	Highcharts.setOptions(HighChartThemes.getDefaultThemeFont());
        	Highcharts.setOptions(self.highchartsDefaultTheme);
    	}
    };
    checkAlreadyExists(model, models) {
        for (var i = 0; i < models.length; i++) {
            if (model && models.at(i).get('name') === model.get('name') && models.at(i).id !== model.id) {
                return models.at(i);
            }
        }
        return null;
    }
    fillBmCompetencies() {
        var self = this;
        self.competenceArray = [];
        self.competence([]);
        for (var i = 0; i < self.model.get('bmCompetence').length; i++) {
            var name = self.model.get('bmCompetence').at(i).get('name');
            var id = self.model.get('bmCompetence').at(i).get('id');
            var duplicateComptence = self.checkAlreadyExists(self.model.get('bmCompetence').at(i), self.model.get('bmCompetence'));
            if (duplicateComptence) {
                var competencePath = self.model.get('bmCompetence').at(i).get('competence').getPackagePath();
                var duplicateCompetencePath = duplicateComptence.get('competence').getPackagePath();
                var displayName = "   [";
                if (competencePath[1].name !== duplicateCompetencePath[1].name) {
                    displayName = displayName.concat(competencePath[1].name);
                }
                else if (competencePath[0].name !== duplicateCompetencePath[0].name) {
                    displayName = displayName.concat(competencePath[0].name + "  /  " + competencePath[1].name);
                }
                else {
                    displayName = displayName.concat(competencePath[0].name);
                }
                displayName = displayName.concat("]");
                name = name.concat(displayName);
            }
            self.competenceArray.push({'id':id, 'name': name });
        }
        self.sortArrayData(self.competenceArray);
        self.competence(self.competenceArray);
    }
    checkValuesDuplicates(valueComponents, value) {
        var self = this;
        var path = value.get('name');
        for (var i = 0; i < valueComponents.length; i++) {
            if ((value.get('name') === valueComponents[i].get('name') && (value.get('id') !== valueComponents[i].get('id')))) {
                var duplicateAggregatePath = valueComponents[i].getPackagePath();
                var aggregatePath = value.getPackagePath();
                if (value.get('type') === "vdml_ValueAdd" && value.get('valueAddOwner') && aggregatePath[1] && aggregatePath[2]) {//To handle Activity values dupliates
                    aggregatePath[2].name = aggregatePath[1].name;
                    aggregatePath[1].name = value.get('valueAddOwner').get('containedPortOwner').get('performingRole').get('name');
                }
                path = path.concat("   [");
                //to handle BM values
                if (aggregatePath[2] && duplicateAggregatePath[2] && aggregatePath[2].name !== duplicateAggregatePath[2].name) {
                    path = path.concat(aggregatePath[2].name);
                }
                else if (aggregatePath[1].name !== duplicateAggregatePath[1].name) {
                    if (aggregatePath[2]) {
                        path = path.concat(aggregatePath[1].name + "  /  " + aggregatePath[2].name);
                    }
                }
                else if (aggregatePath[0].name !== duplicateAggregatePath[0].name) {
                    if (aggregatePath[2]) {
                        path = path.concat(aggregatePath[0].name + "  /  " + aggregatePath[1].name + "  /  " + aggregatePath[2].name);
                    }
                }
                path = path.concat("]");
                break;
            }
        }
        return path;
    }
    fillValuesTab() {
        var self = this;
        var valueComponents = [];
        var bmRoles = self.model.get('bmRole');
        bmRoles.each(function (bmrole) {
            var propostions = bmrole.get('providedProposition');
            propostions.each(function (proposition) {
                var bmObjExists;
                if (proposition.get('isValueFormula')) {
                    bmObjExists = self.model.get('bmValueFormula').findWhere({ 'valueFormula': proposition });
                } else {
                    bmObjExists = self.model.get('bmValueProposition').findWhere({ 'valueProposition': proposition });
                }
                if (bmObjExists) {
                    var components = proposition.get('component');
                    components.each(function (valComp) {
                        valueComponents.push(valComp);
                    });
                }
            });
        });
        var bmActivities = self.model.get('bmActivity');
        bmActivities.each(function (bmActivity) {
            var actValues = bmActivity.get('activityValue');
            actValues.each(function (actValue) {
                valueComponents.push(actValue);
            });
        });
        for (var i = 0; i < valueComponents.length; i++) {
            var name = self.checkValuesDuplicates(valueComponents, valueComponents[i]);
            self.valuesArray.push({'id':valueComponents[i].id, 'name': name });
        }
        valueComponents.length = 0;
        self.sortArrayData(self.valuesArray);
        self.valuesArray = self.valuesArray.slice(0,100);//limiting to 100 values in Cube
        self.values(self.valuesArray);
    }
    setResizeHeightHandler(reDraw) {
        var self = this;
        setTimeout(function () {
            if (self.spliter) {
                var rightTab = $('#rightTab');
                if (rightTab.width() > 10 && rightTab.children().last()[0].classList.contains('dropdown') /*&& rightTab.height() < 50*/) {
                    var dropdownDiv = rightTab.children().last();
                    var appendList = dropdownDiv.find('li');
                    var removedList = [];
                    for (var i = 0; i < appendList.length; i++) {
                        dropdownDiv.before(appendList[i]);
                        removedList.push(appendList[i]);
                        if (self.selectedRightTab == appendList[i].textContent) {
                            (appendList.eq(i).addClass('active'))
                        }
                    }
                    if (appendList.length === removedList.length) {
                        dropdownDiv.remove();
                    }
                }
                if (rightTab.width() > 10 && rightTab.width() < 500 && rightTab.children().length > 2/* && rightTab.height() > 50*/) {
                    var rightChildren = rightTab.children();
                    var appendList = [];
                    for (var i = rightChildren.length; i > 0; i--) {
                        if (!rightChildren[i - 1].classList.contains('dropdown')) {
                            appendList.push(rightChildren[i - 1]);
                            if (rightTab.width() < 450 && rightChildren[i - 2]) {
                                appendList.push(rightChildren[i - 2]);
                                if (rightTab.width() < 330 && rightChildren[i - 3]) {
                                    appendList.push(rightChildren[i - 3]);
                                    if (rightTab.width() < 235 && rightChildren[i - 4]) {
                                        appendList.push(rightChildren[i - 4]);
                                    }
                                    break;
                                }
                                break;
                            } else {
                                break;
                            }
                        }
                    }
                    if (!rightChildren.last()[0].classList.contains('dropdown')) {
                        rightTab.append('<li class="dropdown"><a class="dropdown-toggle" data-toggle="dropdown" href="#"><span class="caret"></span></a><ul style="right:0" class="dropdown-menu pull-right"></ul></li>');
                    }
                    for (var i = 0; i < appendList.length; i++) {
                        rightTab.children().last().find('ul').prepend(appendList[i]);
                    }
                }

                //var splitterNode = $('#splitPanels');
                var cubeNode = $('.cubeArea');
                //var propertiesNode = $('.propertiesArea').children();
                var propAreaHeight = 0;
                var rightTabHeight = 0;
                
                if ($('#rightTab').width() > 0) {
                    rightTabHeight = $('#rightTab').outerHeight();
                    if ($('#rightTab .active a')[0]) {
                        var propDivId = $('#rightTab .active a')[0].hash;
                        if (propDivId !== null && $('#rightTab .dropdown .active a')[0]) {
                            propDivId = $('#rightTab .dropdown .active a')[0].hash;
                        }
                        var propNode = $('' + propDivId);
                        propAreaHeight = rightTabHeight + propNode.outerHeight();
                    }
                }
                //var treeNode = $("#xaxis");
                var baseHeight = 0;
                var topAreaHeight = $('.navbar').outerHeight();
                baseHeight = _.max([propAreaHeight + 15, cubeNode.height() + 5, window.innerHeight - topAreaHeight - 5]);
                //splitterNode.css('height', (baseHeight) + 'px');
                if ($("#Progress").hasClass('active') && $("#placeholder" + self.encodeId).width() > 0 && reDraw && $('#rightTab').width() > 0) {
                    self.chartViewInstance.initialize(self.model, true, self);
                }
                var beforeScrollExists = $("body").height() > $(window).height();
                self.spliter.css('height', (baseHeight) + 'px');
                var afterScrollExists = $("body").height() > $(window).height();
                if ($('#rightTab').width() < 5 && beforeScrollExists != afterScrollExists && !afterScrollExists) {
                    var windowWidth = $(window).width() - 12;
                    $('.splitter_bar').css('left', windowWidth);
                }
                if ($('#rightTab').width() > 5) {
                    $("#splitPanels").enhsplitter('refresh');
                }
                if (rightTab.children().last()[0] && rightTab.children().last()[0].classList.contains('dropdown') && propNode && propNode.parent()[0] && propNode.parent()[0].classList.contains('tab-content')) {
                    var dropdownDiv = rightTab.children().last();
                    var appendList = dropdownDiv.find('li');
                    for (var i = 0; i < appendList.length; i++) {
                        appendList.eq(i).removeClass('active');
                    }
                }
            }
			if(window['refreshCharts']){
				window.utils.stopSpinner('refreshCharts');
			}
        }, 150);
    };	
    autoAdjustCubeForDimension(dimension) {
        var self = this;
        var dimensionheight = $('#' + dimension).height();
		var contentHeight = $('#' + dimension + ' .form-group').height();
		var currentHeight = dimensionheight;
		if(dimension == "bmVP"){
		   var myValuesContentHeight = $('.MyValues').height();
           var myPropositionsHeight = $('.MyPropositions').height();
           currentHeight = myValuesContentHeight + myPropositionsHeight;
		   /*if(myValuesContentHeight> dimensionheight/2){
		        var requiredHeight = myValuesContentHeight - (dimensionheight/2); 
                while (dimensionheight <= requiredHeight + currentHeight) {
                  dimensionheight = $('#' + dimension).height();
                  contentHeight = $('#' + dimension + ' .form-group').height();

                  $.proxy(self.zoomIn, self)();
                }
		   }*/
		}
		{
			 while (currentHeight <= contentHeight) {
			      currentHeight = $('#' + dimension).height();
                  contentHeight = $('#' + dimension + ' .form-group').height();
				  $.proxy(self.zoomIn, self)();
             }
		}
    };
    
    customerDialog(obj,event) {
    	var model = window.vdmModelView.model;
    	if(obj.id){
    		var cust = model.get('bmCustomer').findWhere({'id':obj.id});
    		var id = window.utils.htmlEscape(window.guidGenerator());
            var options = {};
            //options.context = self.context;
            options.mappedModal = cust.get('customer');
    		window.getAndCreateModalDialog(model, id, BMCanvasDiagramMixin, model, "TargetDetails", null, options);
    	}
    };	
    
    partnerDialog(obj,event) {
    	var model = window.vdmModelView.model;
    	if(obj.id){
    		var part = model.get('bmNetworkPartner').findWhere({'id':obj.id});
    		var id = window.utils.htmlEscape(window.guidGenerator());
            var options = {};
            //options.context = self.context;
            options.mappedModal = part.get('networkPartner');
    		window.getAndCreateModalDialog(model, id, BMCanvasDiagramMixin, model, "TargetDetails", null, options);
    	}
    };
    
    valuePropositionDialog(obj,event) {
    	var model = window.vdmModelView.model;
    	if(obj.id){
    		var vp = model.get('bmValueProposition').findWhere({'id':obj.id});
    		var id = window.utils.htmlEscape(window.guidGenerator());
            var options = {};
            //options.context = self.context;
            options.mappedModal = vp.get('valueProposition');
    		window.getAndCreateModalDialog(model, id, BMCanvasDiagramMixin, model, "TargetDetails", null, options);
    	}
    };
    
    valueFormulaDialog(obj,event) {
    	var model = window.vdmModelView.model;
    	if(obj.id){
    		var	vp = model.get('bmValueFormula').findWhere({'id':obj.id});
    		var id = window.utils.htmlEscape(window.guidGenerator());
            var options = {};
            //options.context = self.context;
            options.mappedModal = vp.get('valueFormula');
    		window.getAndCreateModalDialog(model, id, BMCanvasDiagramMixin, model, "TargetDetails", null, options);
    	}
    };
    
    showAggregationView(obj,event) {
    	var model = window.vdmModelView.model;
        var currentAlt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
    	if(obj.id){
            currentAlt.showAggregationView(obj.id, function(){
                event.currentTarget.textContent = 'v2'
                window.utils.startSpinner('refreshValues','Refreshing ...');
                DataManager.getDataManager().invokeValueUpdates(function(){
                    window.vdmModelView.refreshWidgetData(true);
                    window.vdmModelView.fillValuesTab()
                    window.utils.stopSpinner('refreshValues');	
                });
            });
	    }    
    };
    
    activityDialog(obj,event) {
    	var model = window.vdmModelView.model;
    	if(obj.id){
    		var bmAct = model.get('bmActivity').findWhere({'id':obj.id});
    		var id = window.utils.htmlEscape(window.guidGenerator());
            var options = {};         
            //options.context = scenario;
            options.mappedModal = bmAct.get('activity');
    		window.getAndCreateModalDialog(model, id, BMCanvasDiagramMixin, model, "TargetDetails", null, options);
    	}
    };
    
    competencyDialog(obj,event) {
    	var model = window.vdmModelView.model;
    	if(obj.id){
    		var comp = model.get('bmCompetence').findWhere({'id':obj.id});
    		var id = window.utils.htmlEscape(window.guidGenerator());
            var options = {};
            var scenario = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative')).getMainScenario(model.getNestedParent());
            options.context = scenario;
            options.mappedModal = comp.get('competence');
    		window.getAndCreateModalDialog(model, id, BMCanvasDiagramMixin, model, "TargetDetails", null, options);
    	}
    };
    handleKeyEvents() {
        var view = this;

        var mouse = {
            start: {}
        },
            touch = document.ontouchmove !== undefined,
            viewport = {
                x: -10,
                y: 20,
                el: $('.cube')[0],
                move: function (coords) {
                    if (coords) {
                        if (typeof coords.x === "number") this.x = coords.x;
                        if (typeof coords.y === "number") this.y = coords.y;
                    }

                    this.el.style[view.transformProp] = "rotateX(" + this.x + "deg) rotateY(" + this.y + "deg) scale3d(" + view.zoomFactor + "," + view.zoomFactor + "," + view.zoomFactor + ")";
                },
                reset: function () {
                    this.move({ x: 0, y: 0 });
                }
            };

        viewport.duration = function () {
            var d = touch ? 50 : 500;
            viewport.el.style[view.transitionDurationProp] = d + "ms";
            return d;
        }();
        $(".cube").hover(
            function () {
                $(this).toggleClass('is-hover'); // you can use every class name you want of course
            }
        );
        //$('.cubeContainer').focus();
        $('.cubeContainer').keydown(function (evt) {
            switch (evt.keyCode) {
                case 37: // left
                    viewport.move({ y: viewport.y - 90 });
                    break;

                case 38: // up
                    evt.preventDefault();
                    viewport.move({ x: viewport.x + 90 });
                    break;

                case 39: // right
                    viewport.move({ y: viewport.y + 90 });
                    break;

                case 40: // down
                    evt.preventDefault();
                    viewport.move({ x: viewport.x - 90 });
                    break;

                case 27: //esc
                    viewport.reset();
                    break;

                default:
                    break;
            };
        });
        $('.cubeContainer').bind('mousedown touchstart', function (evt) {
            delete mouse.last;
            if ($(evt.target).is('a, iframe') || !$('.cube').hasClass('is-hover')) {
            //    return true;
            }

            evt.originalEvent.touches ? evt = evt.originalEvent.touches[0] : null;
            mouse.start.x = evt.pageX;
            mouse.start.y = evt.pageY;
            $('.cubeContainer').bind('mousemove touchmove', function (event) {
                // Only perform rotation if one touch or mouse (e.g. still scale with pinch and zoom)
                if (!touch || !(event.originalEvent && event.originalEvent.touches && event.originalEvent.touches.length > 1)) {
                    event.preventDefault();
                    // Get touch co-ords
                    event.originalEvent.touches ? event = event.originalEvent.touches[0] : null;
                    $('.cubeviewport').trigger('move-viewport', { x: event.pageX, y: event.pageY });
                }
            });

            $('.cubeContainer').bind('mouseup touchend', function () {
                $('.cubeContainer').unbind('mousemove touchmove');
            });
        });

        $('.cubeviewport').bind('move-viewport', function (evt, movedMouse) {

            // Reduce movement on touch screens
            var movementScaleFactor = touch ? 4 : 1;

            if (!mouse.last) {
                mouse.last = mouse.start;
            } else {
                if (forward(mouse.start.x, mouse.last.x) != forward(mouse.last.x, movedMouse.x)) {
                    mouse.start.x = mouse.last.x;
                }
                if (forward(mouse.start.y, mouse.last.y) != forward(mouse.last.y, movedMouse.y)) {
                    mouse.start.y = mouse.last.y;
                }
            }

            viewport.move({
                x: viewport.x + parseInt((mouse.start.y - movedMouse.y) / movementScaleFactor),
                y: viewport.y - parseInt((mouse.start.x - movedMouse.x) / movementScaleFactor)
            });

            mouse.last.x = movedMouse.x;
            mouse.last.y = movedMouse.y;

            function forward(v1, v2) {
                return v1 >= v2 ? true : false;
            }
        });


    };

	checkForRevision(model,callback){
        var self = this;
		if (!window.checkContextForRevision()){
			callback();
			return;
		}
		var nestedParent = model.getNestedParent();
		var depPacks = nestedParent.get('dependentPackage');
		var createRevision = false;
		if(depPacks){
			var currentAlt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
			var phaseDesignPart = currentAlt.get('phaseDesignPart');
			for(var i=0;i<depPacks.length;i++){
				if(depPacks.at(i).get('type') == "vdml_ValueDeliveryModel" && !depPacks.at(i).get('libraryPackage') && !phaseDesignPart.findWhere({'beepReference':depPacks.at(i).get('id')})){
					if(window.utils.getSuffix(depPacks.at(i).get('id')) != window.utils.getSuffix(nestedParent.get('id'))){
						createRevision = true;
						break;
					}
				}
			}
		}
		if(createRevision){
			
			window.utils.startSpinner('revisionSpinner', "Creating a copy...");
			function fetchBmModel(newVdmPackage){
					if(newVdmPackage) {
                        var altId = DataManager.getDataManager().getRepositoryId(newVdmPackage.id);
						var oldAltId = DataManager.getDataManager().getRepositoryId(self.model.id);
						var diagrams = newVdmPackage.get('diagrams');
						for (var i = 0; i < diagrams.length; i++) {
							newVdmPackage.fixDiagramOnRevision(diagrams.at(i), altId, oldAltId);
						}
                        DataManager.getDataManager().releaseSaveLock();
		        		DataManager.getDataManager().set('isActive',false);
						/*DataManager.getDataManager().saveData({
							success:function(){*/
								DataManager.getDataManager().getReferencesToModel2(newVdmPackage,function(result){
									var collList = [];
									var participantList = [];
									var obDel = [];
									var len = result.length;
									var viewAlt = DataManager.getDataManager().get('viewAlternative');
									while (len--) {
										 if (result[len].predicate === "vdml_ParticipantNetwork-collaboration"){
											collList.push(result[len].id);
										 }else if(result[len].predicate === "vdml_Assignment-participant"){
											participantList.push(result[len].id);
										 }
									}
									function getNewModal(oldModal){
										var newId = viewAlt + window.utils.getSuffix(oldModal.get('id'));
										var vdmlType = oldModal.get('type').replace('_', '.');
										return Backbone.Relational.store.getObjectByName(vdmlType).find({ id: newId });
									}
									var roleArray = [];
									_.each(newVdmPackage.get('businessModel').models,function(bm){
										if(participantList.includes(bm.get('business').get('id'))){
											_.each(bm.get('participantNetwork').models,function(pn){
												pn.removeCollaborationToScenarioContexts(bm);
												obDel.push(pn);
											});
										}else {
											_.each(bm.get('participantNetwork').models,function(pn){
												if(collList.includes(pn.get('collaboration').get('id'))){
													pn.removeCollaborationToScenarioContexts(bm);
													obDel.push(pn);
												}else {
													_.each(pn.get('collaboration').get('ownedAssignment').models,function(assign){
														if(participantList.includes(assign.get('participant').get('id'))){
															var newModal = getNewModal(assign.get('participant'));
															if (newModal) {
																assign.set('participant',newModal);
															}else {
																obDel.push(assign);
															}
														}
													});
												}
											});
											_.each(bm.get('bmNetworkPartner').models,function(bmNetPart){
												if(participantList.includes(bmNetPart.get('networkPartner').get('id'))){
													var newModal = getNewModal(bmNetPart.get('networkPartner'));
													if (newModal) {
														bmNetPart.set('networkPartner',newModal);
													} else {
														_.each(bmNetPart.get('networkPartnerRole').models,function(role){
															_.each(role.get('roleAssignment').models,function(assignment){
																if(assignment.get('participant') == bmNetPart.get('networkPartner')) {
																	bm.get("bmRole").remove(role);
                    												roleArray.push({'bm':bm,'role':role,'refId':[assignment.get("id"),bmNetPart.get('id')],'assign':assignment});
																	if(!bm.checkBmNetworkPartnerReused(bmNetPart,role.getParent())){
																		obDel.push(bmNetPart);
                														bm.businessCanvasDeletions([bmNetPart.get('networkPartner')]);
																	}
																}
															});
														});
													}
												}
											});
											_.each(bm.get('bmCustomer').models,function(bmCustomer){
												if(participantList.includes(bmCustomer.get('customer').get('id'))){
													var newModal = getNewModal(bmCustomer.get('customer'));
													if (newModal) {
														bmCustomer.set('customer',newModal);
													} else {													
														_.each(bmCustomer.get('customerRole').models,function(role){
															_.each(role.get('roleAssignment').models,function(assignment){
																if(assignment.get('participant') == bmCustomer.get('customer')) {
																	bm.get("bmRole").remove(role);
                    												roleArray.push({'bm':bm,'role':role,'refId':[assignment.get("id"),bmCustomer.get('id')],'assign':assignment});
																	if(!bm.checkBmCustomerReused(bmCustomer,role.getParent())){
																		obDel.push(bmCustomer);
                														bm.businessCanvasDeletions([bmCustomer.get('customer')]);
																	}
																}
															});
														});
													}
												}
											});
										}
									});
									var obDelLen = obDel.length;
									while (obDelLen--) {
										obDel[obDelLen].destroy();
									}
									async.eachSeries(roleArray, function(role,roleCallback){
										role.bm.deleteRole(role,roleCallback);
									}, function(err){  
										window.utils.stopSpinner('revisionSpinner');
		        						callback(newVdmPackage);
									});
								});
							/*}, 
							error:function(){
								window.utils.stopSpinner('revisionSpinner');
		        				callback(newVdmPackage);
							}, 
							persist:true});	*/
					}
				}
			window.setTimeout(function(){
                DataManager.getDataManager().acquireSaveLock(function () {
                    nestedParent.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
                });
	        },100);	
			
		}else {
			callback();
		}
		
	}
	openAccordian(){
		var phaseModel = null;
        var phase = DataManager.getDataManager().get('currentPlan').get('phase');
        for (var j = 0; j < phase.length; j++) {
            var alts = phase.at(j).get('phaseAlternative');
            if (alts.findWhere({ 'id': DataManager.getDataManager().get('viewAlternative') })) {
                phaseModel = phase.at(j);
                break;
            }
        }
        $('#collapse' + window.utils.htmlEscape(phaseModel.get('id'))).addClass('collapse in');
        $('#collapse' + window.utils.htmlEscape(DataManager.getDataManager().get('viewAlternative'))).addClass('collapse in');
	}

    loadBusinessDetailComponent(){
        var self = this;
        self.bmDetailsViewInstance = BMDetailsViewModel.getInstance(this);
    	if(!ko.components.isRegistered('BMDetailsWidget')){
	    	ko.components.register("BMDetailsWidget", {
                viewModel: {instance:self.bmDetailsViewInstance},
                template: window.utils.getHtmlPage("BMDetailsTemplate"),
			});
    	}
    }

    init(model, options){
		var self = this;
		this.BMCubeViewModel = this;
		this.model = model;
		this.id = kb.observable(model, 'id');
        this.encodeId = window.utils.htmlEscape(model.id);
		this.name = kb.observable(model, 'name');
		this.description = kb.observable(model, 'description');
		this.planValuesClickCount = 0;
        self.selectedRightTab = "";		
		/*window.utils.loadTemplate(function(name){
			if(name == "BMValuesChart"){
				self.chartViewInstance.initialize(self.model, false, self);
			}else if(name == "valuewidget") {
				self.bmValueWidgetInstance.initialize(self.model);
				self.openAccordian()
			}
		})*/
	 	self.chartViewInstance = BMValuesChart.getInstance(this.model);
    	if(!ko.components.isRegistered('BMValuesChart')){
	    	ko.components.register("BMValuesChart", {
			    viewModel: {instance:self.chartViewInstance},
                template: window.utils.getHtmlPage("BMValuesChartTemplate"),
			   	synchronous: true
			});
    	}
        self.bmValueWidgetInstance = BMValueDetailsViewModel.getInstance(self);      
    	if(!ko.components.isRegistered('valuewidget')){
	    	ko.components.register("valuewidget", {
			    viewModel: {instance:self.bmValueWidgetInstance},
                template: window.utils.getHtmlPage("BMValueDetailsTemplate"),
			   	synchronous: true
			});
    	}
        this.loadBusinessDetailComponent()
		self.guidanceViewInstance = BMGuidanceViewModel.getInstance(this.model);
		if(!ko.components.isRegistered('CubeGuidance')){
	    	ko.components.register("CubeGuidance", {
			    viewModel: {instance:self.guidanceViewInstance},
			   	template: '<div id="guidanceTab"></div>',
			   	synchronous: true
			});
    	}
    	self.setResizeHeight = _.bind(self.setResizeHeightHandler,self);

/*	zoomIn = function(){
		this.zoomFactor = this.zoomFactor + 0.1;
		$('.cube')[0].style[this.prop] = "scale3d(" +this.zoomFactor + "," + this.zoomFactor + "," + this.zoomFactor + ")"; 
	}
	zoomOut = function(){
		this.zoomFactor = this.zoomFactor - 0.1;
		$('.cube')[0].style[this.prop] = "scale3d(" +this.zoomFactor + "," + this.zoomFactor + "," + this.zoomFactor + ")"; 
	}*/
	this.cubeLength = 200;
    var el = document.createElement('div');
    var transformProps = 'transform WebkitTransform MozTransform OTransform msTransform'.split(' ');
    self.transformProp = support(transformProps);
    var perspectiveProps = 'perspective WebkitPerspective MozPerspective'.split(' ');
    self.perspectiveProp = support(perspectiveProps);
    var perspectiveOriginProps = 'perspective-origin WebkitPerspectiveOrigin MozPerspectiveOrigin'.split(' ');
    self.perspectiveOriginProp = support(perspectiveOriginProps);
    var transitionDuration = 'transitionDuration WebkitTransitionDuration MozTransitionDuration OTransitionDuration msTransitionDuration'.split(' ');
    self.transitionDurationProp = support(transitionDuration);

    function support(props) {
        for(var i = 0, l = props.length; i < l; i++) {
            if(typeof el.style[props[i]] !== "undefined") {
                return props[i];
            }
        }
    }
	
	self.sortArrayData = function(data){
		data.sort(function(left, right) { 
	        return left.name.toLowerCase() === right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1) 
	    });
	}
	this.customer = ko.observableArray([]);
	this.customerArray = [];
	for(var i=0;i<model.get('bmCustomer').length;i++){
		var name = model.get('bmCustomer').at(i).get('name');
        var id = model.get('bmCustomer').at(i).get('id');
        if (model.get('bmCustomer').at(i).get('customerRole').length == 0) {
            continue;
        }
		var duplicateCustomer = self.checkAlreadyExists(model.get('bmCustomer').at(i),model.get('bmCustomer'));
		if(duplicateCustomer){
			var customerPath = model.get('bmCustomer').at(i).getPackagePath();
			var duplicateCustomerPath = duplicateCustomer.getPackagePath();
			var displayName = "   [";
			if(customerPath[1].name !== duplicateCustomerPath[1].name){
				displayName = displayName.concat(customerPath[1].name);
			}
			else if(customerPath[0].name !== duplicateCustomerPath[0].name){
				displayName = displayName.concat(customerPath[0].name+"  /  "+customerPath[1].name);
			}
            displayName = displayName.concat("]");
			name = name.concat(displayName);
		}
		self.customerArray.push({'id':id,'name':name});
	}
	self.sortArrayData(self.customerArray);
	self.customer(self.customerArray);
	this.valueProposition = ko.observableArray([]);
	this.valuePropositionArray = [];
    
	function getPropositionDispName(vps,vp){
		var displayname = "";
		for(var i=0;i<vps.length;i++){
			if(vp && (vps.at(i).get('name')==vp.get('name')) && (vps.at(i).get('id')!==vp.get('id'))){
				displayname = "   [";
				for(var k=0;k<vp.getPackagePath().length-1;k++){
					displayname = displayname.concat(vp.getPackagePath()[k].name);
					if(vp.getPackagePath()[k+2]){
						displayname = displayname.concat("  /  ");
					}
				}
				displayname = displayname.concat("]");
			}
		}
		return displayname;
	}
	var delValProp;
	for(var i=0;i<model.get('bmValueProposition').length;i++){
        var bmvp = model.get('bmValueProposition').at(i);
		var name = bmvp.get('name');
        if(!name || !bmvp.get('valueProposition')){
            delValProp = bmvp;
        } else {
            var id = bmvp.get('id');
            var duplicateVP = self.checkAlreadyExists(bmvp,model.get('bmValueProposition'));
            if(duplicateVP) {
                var displayName = getPropositionDispName(model.get('bmValueProposition'),bmvp.get('valueProposition'));
                name = name.concat(displayName);
            }
            self.valuePropositionArray.push({'id':id,'name':name});
        }
	}
    if(delValProp){//temp fix for script error
        delValProp.destroy();
    }
	self.valueProposition(self.valuePropositionArray);
	this.activity = ko.observableArray([]);
	this.activityArray = [];

	for(var i=0;i<model.get('bmActivity').length;i++){
		var name = model.get('bmActivity').at(i).get('name');
		var id = model.get('bmActivity').at(i).get('id');
		var duplicateActivity = self.checkAlreadyExists(model.get('bmActivity').at(i),model.get('bmActivity'));
		var act = model.get('bmActivity').at(i).get('activity');
		if(duplicateActivity && act && duplicateActivity.get('activity')) {
			var activityPath = model.get('bmActivity').at(i).get('activity').getPackagePath();
			var duplicateActivityPath = duplicateActivity.get('activity').getPackagePath();
			var displayName = "   [";
			displayName = displayName.concat(activityPath[0].name+"  /  "+duplicateActivity.get('activity').get('performingRole').get('name'));
		    displayName = displayName.concat("]");
			name = name.concat(displayName);
		}
		self.activityArray.push({'id':id,'name':name});
	}
	self.sortArrayData(self.activityArray);
	self.activity(self.activityArray);
	this.selectedActivity = ko.observableArray();
	model.getAsync("bmActivity");
	this.competence = ko.observableArray([]);
	this.competenceArray = [];
	

	self.fillBmCompetencies();
	
	this.networkPartner = ko.observableArray([]);
	this.networkPartnerArray = [];
		
	for(var i=0;i<model.get('bmNetworkPartner').length;i++){
		var name = model.get('bmNetworkPartner').at(i).get('name');
        var id = model.get('bmNetworkPartner').at(i).get('id');
        if (model.get('bmNetworkPartner').at(i).get('networkPartnerRole').length == 0) {
            continue;
        }
		var duplicatePartner = self.checkAlreadyExists(model.get('bmNetworkPartner').at(i),model.get('bmNetworkPartner'));
		if(duplicatePartner) {
			var partnerPath = model.get('bmNetworkPartner').at(i).getPackagePath();
			var duplicatePartnerPath = duplicatePartner.getPackagePath();
			var displayName = "   [";
			if(partnerPath[1].name !== duplicatePartnerPath[1].name){
				displayName = displayName.concat(partnerPath[1].name);
			}
			else if(partnerPath[0].name !== duplicatePartnerPath[0].name){
				displayName = displayName.concat(partnerPath[0].name+"  /  "+partnerPath[1].name);
			}
            displayName = displayName.concat("]");
			name = name.concat(displayName);
		}
		self.networkPartnerArray.push({'id':id,'name':name});
	}
	self.sortArrayData(self.networkPartnerArray);
	self.networkPartner(self.networkPartnerArray);
	this.selectedNetworkPartner = ko.observableArray();

	this.removeNetworkPartner = function(){
		this.removeFromCollection(this.selectedNetworkPartner(),this.model.get('bmNetworkPartner'));
	};
	this.valueFormula = ko.observableArray([]);
	this.valueFormulaArray = [];
	
	for(var i=0;i<model.get('bmValueFormula').length;i++){
		var name = model.get('bmValueFormula').at(i).get('name');
		var id = model.get('bmValueFormula').at(i).get('id');
		var duplicateVF = self.checkAlreadyExists(model.get('bmValueFormula').at(i),model.get('bmValueFormula'));
		if(duplicateVF && model.get('bmValueFormula').at(i).get('valueFormula') && duplicateVF.get('valueFormula')) {
			var  VFPath = model.get('bmValueFormula').at(i).get('valueFormula').getPackagePath();
			var duplicateVFPath = duplicateVF.get('valueFormula').getPackagePath();
			var displayName = "   [";
			if(VFPath[1].name !== duplicateVFPath[1].name){
				displayName = displayName.concat(VFPath[1].name);
			}
			else if(VFPath[0].name !== duplicateVFPath[0].name){
				displayName = displayName.concat(VFPath[0].name+"  /  "+VFPath[1].name);
			}
		    displayName = displayName.concat("]");
			name = name.concat(displayName);
		}
		self.valueFormulaArray.push({'id':id,'name':name});
	}
	self.valueFormula(self.valueFormulaArray);
	
 
	this.values = ko.observableArray([]);
	this.valuesArray = [];
	
	self.fillValuesTab();
	self.highchartsDefaultTheme = $.extend(true, {}, Highcharts.defaultOptions);this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['BusinessModel'
	,'version'
	,'name'
	,'description'
	,'OrgUnit'
	,'bmRole'
	,'Role'
	,'Customers'
	,'Activities'
	,'Competencies'
	,'MyPropositions'
	,'NetworkPartners'
	,'Collaboration'
	,'ValuePropositions'
	,'Tickets'
	,'valueProposition'
	,'activity'
	,'competence'
	,'MyProposition'
	,'BMValues'
	,'BMDetails'
	,'assignedTicket'
	,'Open'
    ,'InProgress'
    ,'Fixed'
    ,'Closed'
    ,'Complete'
    ,'Delete'
    ,'Guidance'
    ,'Start'
    ,'Progress'
    ,'SelectValues'
    ,'Values'
    ,'PresentationType'
    , 'Theme'
    , 'year/period'
    , 'week'
    , 'Quarter',
    , 'month'
    ]);

    }
	afterRenderView(node, view) {
		var self = view;
		$(window).bind("resize", function(event){
            if (this == event.target) {
               $('.sp').removeAttr('style');
            }
            var viewportWidth = $(window).width();
            $('#monitor').html(viewportWidth);
            self.setResizeHeight(true);
        });    
        self.refreshWidgetData(false);  
        self.openAccordian();
         $('#rightTab a').click(function (e) { 
            e.preventDefault();
            if($(this).text() === "Collaboration"){
     			return false; 
            }
             $(this).tab('show');
             self.selectedRightTab = $(this).text();
            if(this.getAttribute('href') === '#Progress'){
            	self.setResizeHeight(true);	
            }else if($(this).text()==="Guidance"){
     			if($('#guidanceTab').is(':empty')){
	    			$.get('js/app/version1/views/help/views/properties/BMCubeGuidancePropertiesTemplate.html',function(viewStr){
					    $('#guidanceTab').empty();
						$('#guidanceTab').append(viewStr);
						window.utils.applyFontSize($('#guidanceTab'));
						self.setResizeHeight();
					})
				}else {
	    			self.setResizeHeight();
	    		}
            }else {
                if(self.planValuesClickCount < 1){
                	window.utils.applyFontSize($('.panel-heading'));
                }
                self.planValuesClickCount++;
            	$("#chartTooltip").hide();
            	self.setResizeHeight();
			}  
            
        });
		
		self.autoAdjustCubeForDimension("bmCust");
		self.autoAdjustCubeForDimension("bmVP");
		self.autoAdjustCubeForDimension("bmAct");
		self.autoAdjustCubeForDimension("bmComp");
		self.autoAdjustCubeForDimension("bmNP");
		self.autoAdjustCubeForDimension("bmValues");
		
		var diagonalHeight = $("section").outerHeight()/2 + parseInt($("#cubeContainer").css("height"));
		var cubeContainer = $("#cubeContainer").css('height', diagonalHeight);
		cubeContainer.css("top", diagonalHeight/30);
		
		var bmCubeAreaNode = $('.cubeArea');
        //var divId = $('#cubeContainer');  
        var bmNode =  $('#cubeContainer');
        var propertiesNode = $('.propertiesArea');
        var treeNode = $("#xaxis");
        var baseHeight = _.max([bmNode.outerHeight(),propertiesNode.outerHeight(),bmCubeAreaNode.outerHeight(),propertiesNode.outerHeight()]);
        var baseWidth = _.max([bmNode.outerWidth(),propertiesNode.outerWidth(),bmCubeAreaNode.outerWidth()]) + propertiesNode.outerWidth();
		//$('#splitPanels').css({width: baseWidth, height: baseHeight}).split({orientation:'vertical', limit:100,position:'56.7%'});
		self.spliter = $("#splitPanels").enhsplitter({minSize:2,onDrag:window.utils.splitterBoundary,collapse:'right',position: '56%',handle:'block',splitterSize:'9px',height:baseHeight, onDragEnd:self.setResizeHeight});
		self.model.getNestedParent().updateScenarioContextBased(self,function (){
			self.checkForRevision(self.model,function(newPack){
				if(newPack) {
					var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
					var altId = DataManager.getDataManager().getRepositoryId(newPack.id);
					var newId = altId + bmId;
					DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newPack.get('id') + "/" + newPack.get('type') + "/" + newPack.get('version') + "/" + newId + "/" + self.model.get('type') + "/" + DataManager.getDataManager().get('viewAlternative') + "/Cube", { trigger: true });
				}else {
					//self.refreshWidgetData(false);
					setTimeout(function(){window.utils.stopSpinner('cubeNavigate');},10);
				}
			});				
		});
		/*$('#splitPanels').bind('splitter_panel',self.setResizeHeight);*/
		/*$('.splitter_bar').bind('onDrag',self.setResizeHeight);	*/
		$('.panel').on('hidden.bs.collapse', function (e) {
			self.setResizeHeight();
		});
		$('.panel').on('shown.bs.collapse', function (e) {
			self.setResizeHeight();
		});
		self.setResizeHeight();
		window.utils.applyFontSize($('#content'));
        const dialogConfig = {
            title: "<div><img class='smallIcon' src='img/icons/icons_14_1.png'>&nbsp;&nbsp;&nbsp;"+DataManager.getDataManager().get('localeManager').get('BusinessModelArrowVideo')+"</div>",
            videos: [
                { id: '3DUCARvMP7I', title: '', width: '25vw', height: '14vw',description:DataManager.getDataManager().get('localeManager').get('ValueCubeDescription') },
            ],
        };
        DataManager.getDataManager().handleVideoClick(dialogConfig,'ValueCubeVideo');
	};

	static getInstance(model,options){
			var view = new BMCubeViewModel(model, options);
			view.init(model, options);
			return view;
	};
}
path.BMCubeViewModel = BMCubeViewModel;
