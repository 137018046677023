import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as async from 'async'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as  bootbox  from '../../../../../../libs/bootbox/bootbox'
import { Presentation } from '../../../../bo/dashboard/Presentation'
import PresentationDataGrid from './PresentationDataGrid'
import NavigationTree from './NavigationTree'


/*define(["require", "jquery", "enhsplitter", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "app/global", "typeahead", "appviews/dashboard/views/chart/PresentationViewModel", "appbo/vdml/BusinessModel","appbo/dashboard/Presentation", "appviews/dashboard/views/chart/DashboardValuesChart", "appbo/dashboard/DashboardMixin", "appbo/beeppackage/CodeContainer","appbo/vdml/ValueElementMixin"],
function (require, $, enhsplitter, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, global, typeahead, PresentationViewModel, BusinessModel, Presentation, DashboardValuesChart, DashboardMixin,  CodeContainer,ValueElementMixin)*/
//{
    var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart", global.version);
export class CreateChart2ViewModel {
  htmlEscape(str) {
    return String(str).replace(/@/g, "").replace(/ /g, "").replace(/#/g, "");
  }
  cleanUp() {}
  getUniqueFileName(propertyName, baseValue, relationName) {
    var ret = baseValue;
    var count = 0;
    var relation = this.model.get(relationName);
    if (!relation) {
      return baseValue;
    }
    var queryObj = {};
    queryObj[propertyName] = ret;
    var found = relation.findWhere(queryObj);
    while (found) {
      count++;
      ret = baseValue + " - " + count;
      queryObj[propertyName] = ret;
      found = relation.findWhere(queryObj);
    }
    return ret;
  }

  copyPresentation() {
    var self = this;
    if (DataManager.getDataManager().isDiscoverPurchased() || DataManager.getDataManager().isAdoptPurchased() ) {
      //var id = window.guidGenerator();
      var currentName = self.name();
      var newName = self.getUniqueFileName("name", currentName, "presentation");
      var chart = Presentation.getInstance(newName, self.description(), self.model.getNestedParent(), self.config );
      chart.set("presentationType", "Chart/Table");
      self.model.get("presentation").push(chart);
      var chartBackboneModel = new Backbone.Model({
        id: chart.id,
        name: chart.get("name"),
        parent: chart.getNestedParent().id,
        description: chart.get("description"),
        presentationType: chart.get("presentationType"),
        newlyCreated: true,
      });
      window.vdmModelView.chartCollection.push(chartBackboneModel);
      self.presentationsList.removeAll();
      self.model.get("presentation").forEach(function (chart) {
        self.presentationsList.push({ id: chart.id, name: chart.get("name"), escapeId: self.htmlEscape(chart.id), type: "Chart" });
      });
      bootbox.alert("Presentation copied");
    } else {
      window.utils.limitExceeded();
      return false;
    }
  }
  fillAll(selectedPhase, chart) {
    var self = this;
    self.selectedPhase(selectedPhase ? selectedPhase : "");
    var selectedAlt = _.find(self.altList(), function (e) {
      return e.id === chart.get("presentationReferenceAlt");
    });
    self.selectedAlt(selectedAlt ? selectedAlt : ""); //goes to susbscribe
  }
  afterRenderView() {
    //var self = window.getVdmModelViewArray();
    var self = this;
    jQuery("#editDetailsModalContent" + self.encodeId + " .modal-body").css("max-height", jQuery(window).height() * 0.65);
    var chartConfig;
    var chart = self.chartModel;
    if (self.config) {
      jQuery(".modal-title span").html("&nbsp&nbspEdit Presentation");
      chartConfig = JSON.parse(self.config);
      self.selectedChartPageLength = chartConfig.selectedChartPageLength;
      self.selectedChartType = chartConfig.selectedChartType;
      jQuery("#selectPresentationType" + self.encodeId).attr( "disabled", "disabled");
      jQuery("#selectPhase").attr("disabled", "disabled");
      jQuery("#selectAlternative").attr("disabled", "disabled");
      jQuery("#selectCanvas").attr("disabled", "disabled");
      jQuery("#selectEcoMap").attr("disabled", "disabled");
      jQuery("#selectStrategyMap").attr("disabled", "disabled");
      jQuery("#selectVsMap").attr("disabled", "disabled");
      jQuery("#selectCapMap").attr("disabled", "disabled");
      var selectedPhase = _.find(self.phaseList(), function (e) {
        return e.id === chart.get("presentationReferencePhase");
      });

      if (chart.get("presentationType") === "Business Canvas") {
        self.selectedPresentationType(self.canvasTitle);
        self.fillAll(selectedPhase, chart);
        self.canvasesList(self.allCanvasesList);
        var selectedCanvas = _.find(self.canvasesList(), function (e) {
          return e.id === chart.get("presentationReference");
        });
        self.selectedCanvas(selectedCanvas);
      } else if (chart.get("presentationType") === "Business Ecosystem Map") {
        self.selectedPresentationType(self.ecoMapTitle);
        self.fillAll(selectedPhase, chart);
        self.ecoMapsList(self.allEcoMapsList);
        var selectedEcoMap = _.find(self.ecoMapsList(), function (e) {
          return e.id === chart.get("presentationReference");
        });
        self.selectedEcoMap(selectedEcoMap);
      } else if (chart.get("presentationType") === "Strategy Map") {
        self.selectedPresentationType(self.strategyMapTitle);
        self.fillAll(selectedPhase, chart);
        self.strategyMapsList(self.allStrategyMapsList);
        var selectedStrategyMap = _.find(self.strategyMapsList(), function (e) {
          return e.id === chart.get("presentationReference");
        });
        self.selectedStrategyMap(selectedStrategyMap);
      } else if (chart.get("presentationType") === "Value Stream Map") {
        self.selectedPresentationType(self.vsMapTitle);
        self.fillAll(selectedPhase, chart);
        self.vsMapsList(self.allVsMapsList);
        var selectedVsMap = _.find(self.vsMapsList(), function (e) {
          return e.id === chart.get("presentationReference");
        });
        self.selectedVsMap(selectedVsMap);
      } else if (chart.get("presentationType") === "Capability Map") {
        self.selectedPresentationType(self.capMapTitle);
        self.fillAll(selectedPhase, chart);
        self.capMapsList(self.allCapMapsList);
        var selectedCapMap = _.find(self.capMapsList(), function (e) {
          return e.id === chart.get("presentationReference");
        });
        self.selectedCapMap(selectedCapMap);
      } else {
        self.selectedPresentationType(chart.get("presentationType"));
      }

      self.name(chart.get("name"));
      self.description(chart.get("description"));
      _.each(chartConfig.selectedAlternativeObjs, function (alt) {
        var alternative = DataManager.getDataManager().getAlternativeSync(alt.id);
        if (alternative) {
          alt.name = alternative.get("name");
          alt.phaseName = alternative.get("phaseAlternativeOwner").get("name");
          alt.displayName = alt.phaseName + " / " + alt.name;
          self.selectedAlternativeArray.push(alt);
        }
      });
      if (chartConfig.selectedObservationObjs) {
        async.each(chartConfig.selectedObservationObjs, function (obs, handledObservation) {
            var observation = Backbone.Relational.store.getObjectByName("smm.Observation").find({ id: obs.id });
            if (observation) {
              obs.name = observation.get("name");
              obs.escapeId = self.htmlEscape(observation.id);
              obs.id = observation.id;
              obs.timeStamp = observation.get("whenObserved");
              self.selectedObservationArray.push(obs);
              handledObservation();
            } else {
              handledObservation();
            }
          },
          function (e) {
            _.each(chartConfig.selectedValueObjs, function (val) {
              self.selectedValuesArray.push(val);
            });
            _.each(chartConfig.altLabels, function (obj) {
              self.altLabels.push(obj);
            });
            _.each(chartConfig.valueLabels, function (obj) {
              self.valueLabels.push(obj);
            });
            _.each(chartConfig.valueGroupLabels, function (label) {
              self.groupLabelTypeAhead.add({ name: label });
            });
            _.each(chartConfig.alternativeLabels, function (obj) {
              self.alternativeLabels.push(obj);
            });
            _.each(chartConfig.scenarioLabels, function (obj) {
              self.scenarioLabels.push(obj);
            });
            handlePostRender();
          }
        );
      } else {
        handlePostRender();
      }
    } else {
      self.selectedChartType = "Table";
      /*var currentAlt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get("viewAlternative"));
      var baseScenario = _.find(currentAlt.getOrCreatePhaseObjectiveSet().get("objectiveObservation").models, function (e) {
          return !e.get("whenObserved");
        }
      );
      var baseScenarioObj = { name: baseScenario.get("name"), id: baseScenario.get("id"), timeStamp: undefined, escapeId: self.htmlEscape(baseScenario.get("id")), checked: true };
      self.selectedObservationArray.push(baseScenarioObj);*/
      handlePostRender();
    }
    function handlePostRender() {
      if (self.selectedPresentationType() === "Chart/Table") {
        self.chartType(true);
        if (DataManager.getDataManager().get("viewAlternative") === DataManager.getDataManager().get("currentWorkspace").get("id") && self.enableDelete()) {
          jQuery("#copyPresentation").show();
        }
      } else {
        self.chartType(false);
      }
      jQuery("#name" + self.encodeId).keyup(function (view) {
        self.handleComplete();
      });
      jQuery("#description" + self.encodeId).keyup(function (view) {
        self.handleComplete();
      });
      self.refreshTable();
    }
  }
  cleanModal() {
    var self = this;
    self.parentView = null;
    // self.viewInstance = null;
    window.cleanDialogModel(self.encodeId, self);
  }

  createRevisionBasedOnContext(pack, callback) {
    if (window.checkContextForRevision()) {
      window.utils.startSpinner("revisionSpinner", "Creating a copy...");
      function fetchBmModel(newVdmPackage) {
        if (newVdmPackage) {
          DataManager.getDataManager().releaseSaveLock();
          DataManager.getDataManager().set("isActive", false);
          window.utils.stopSpinner("revisionSpinner");
          callback(newVdmPackage);
        }
      }
      window.setTimeout(function () {
        DataManager.getDataManager().acquireSaveLock(function () {
          pack.createRevision(
            DataManager.getDataManager().get("viewAlternative"),
            DataManager.getDataManager().get("currentWorkspace").get("id"),
            fetchBmModel
          );
        });
      }, 100);
    } else {
      callback(pack);
    }
  }

  showScenarioInfo(view, event) {
    var self = this;

  }

  showValuePathDivRow(view, event) {
    var self = this;
    var element = window.utils.getElementModel(view.id,['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']);
    var partcipantNetwork = "",
      valuePathRole = "",
      valuePathSource = "",
      receiverValuePathRole = "",
      ProviderRole = "",
      receiverRole = "",
      PN = "Participant Network";
    if (element.getNestedParent() != DataManager.getDataManager().get("currentPlan") ) {
      fillValuePath();
    }
    if (valuePathSource === "") {
      var componentOwner = element.get("componentOwner");
      if (componentOwner && componentOwner.get("planCriterionSetOwner")) {
        valuePathSource = componentOwner.get("planCriterionSetOwner").get("name");
        PN = "";
        partcipantNetwork = "";
      }
    }
    var msg =
      '<div>\
				<div class="row">\
					<div class="col-xs-1"></div>\
					<div class="col-xs-4"><span class="control-label"><span style="font-family: Roboto;">Source</span></span></div>\
					<div class="col-xs-6"><span> ' +
      valuePathSource +
      '</div><br>\
					<div class="col-xs-1"></div>\
				</div><br>\
				<div class="row">\
					<div class="col-xs-1"></div>\
					<div class="col-xs-4"><span class="control-label"><span style="font-family: Roboto;">'+DataManager.getDataManager().get('localeManager').get('sourceType')+'</span></span></div>\
					<div class="col-xs-6"><span> ' +
      window.utils.getSourceType(element) +
      '</div><br>\
					<div class="col-xs-1"></div>\
				</div>';

    var pnDiv =
      '<div class="row">\
					<br>\
					<div class="col-xs-1"></div>\
					<div class="col-xs-4"><span class="control-label"><span style="font-family: Roboto;">' +
      PN +
      '</span></span></div>\
					<div class="col-xs-6"><span>' +
      partcipantNetwork +
      '</div><br>\
					<div class="col-xs-1"></div>\
				</div>';

    var providerDiv =
      '<div class="row">\
					<br>\
					<div class="col-xs-1"></div>\
					<div class="col-xs-4"><span class="control-label"><span style="font-family: Roboto;">' +
      ProviderRole +
      '</span><span></div>\
					<div class="col-xs-6"><span>' +
      valuePathRole +
      '</div><br>\
					<div class="col-xs-1"></div>\
				</div>';

    var recieverDiv =
      '<div class="row">\
					<br>\
					<div class="col-xs-1"></div>\
					<div class="col-xs-4"><span class="control-label"><span style="font-family: Roboto;">' +
      receiverRole +
      '</span><span></div>\
					<div class="col-xs-6"><span>' +
      receiverValuePathRole +
      '</div><br>\
					<div class="col-xs-1"></div><br>\
				</div>';

    if (PN !== "") {
      msg = msg.concat(pnDiv);
    }
    if (ProviderRole !== "") {
      msg = msg.concat(providerDiv);
    }
    if (receiverRole !== "") {
      msg = msg.concat(recieverDiv);
    }
    msg = msg.concat("</div>");
    const box = bootbox.dialog({
      message: msg,
      title: DataManager.getDataManager().get('localeManager').get('SourceDetails'),
      buttons: {
        main: {
          label: "Close",
          className: "btn btn-default",
        },
      },
    });
    if(document.fullscreenElement){
      box.appendTo(`#${document.fullscreenElement.id}`)
    }else{
      box.show();
    }
    function fillValuePath() {
      for (var k = 0; k < element.getPackagePath().length - 1; k++) {
        if (k === 0) {
          partcipantNetwork = element.getPackagePath()[k].name;
        }
        if (element.get("type") === "vdml_ValueAdd" && k === 1 && element.get("valueAddOwner")) {
          var performingRole = element.get("valueAddOwner").get("containedPortOwner").get("performingRole");
          valuePathRole = performingRole.get("roleAssignment").models[0].get("participant").get("name") +
            "  <b><i>(" + performingRole.get("name") +")</b></i>";
          if (element.getPackagePath()[k]) {
            valuePathSource = element.getPackagePath()[k].name;
          }
          ProviderRole = "Performer";
          k++;
        } else if (k === 1) {
          valuePathRole = element.get("componentOwner").get("provider").get("roleAssignment").models[0].get("participant").get("name") +
            "  <b><i>(" + element.getPackagePath()[k].name +")</i></b>";
          if (element.get("componentOwner").get("recipient")) {
            var participantName = "";
            if (element.get("componentOwner").get("recipient").get("roleAssignment").models[0]) {
              participantName = element.get("componentOwner").get("recipient").get("roleAssignment").models[0].get("participant").get("name");
            }
            receiverValuePathRole = participantName + "  (" + element.get("componentOwner").get("recipient").get("name") + ")";
            receiverRole = "To (Role)";
          }
          k++;
          if (element.getPackagePath()[k]) {
            valuePathSource = element.getPackagePath()[k].name;
          }
          ProviderRole = "From (Role)";
        }
      }
    }
  }

  handleSavePresentation = (uId,id,name) => {
    const currentElement =  window.vdmModelView.hashTreeData.get(uId)
    currentElement.id = id;
    currentElement.name = name;
  };

    createPresentation() {
      var self = this;
      var vdmPackage = self.model.getNestedParent();
      self.createRevisionBasedOnContext(vdmPackage, function (newVdmPackage) {
      if(self.config){
        window.utils.startSpinner('createChart', 'Updating Presentation ...');
      }else{
        window.utils.startSpinner('createChart', 'Creating Presentation ...');
      }
      window.setTimeout(function () {
          if (self.description && !self.description()) {
              self.description(self.name());
          }
          if (newVdmPackage !== vdmPackage) {
              var bmId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
              var viewAltId = DataManager.getDataManager().getRepositoryId(newVdmPackage.id);
              var analyticChart = newVdmPackage.get('dashboard').findWhere({ 'id': viewAltId + bmId });
              self.model = analyticChart;
              if (self.chartModel) {
                  var chartSuffixId = self.chartModel.id.substr(self.chartModel.id.lastIndexOf('@') + 1, self.chartModel.id.length);
                  self.chartModel = self.model.get('presentation').findWhere({ 'id': viewAltId + chartSuffixId });
              }
        }
        var chart;
        var chartConfig = self.selectedPresentationType() === "Chart/Table" ? self.saveConfiguration() : JSON.stringify({});
        if (self.chartModel) {
            chart = self.chartModel;
            chart.set({'name': self.name(),'description': self.description(),'chartConfig': chartConfig});
            var chartHeader = jQuery('#details' + self.htmlEscape(chart.id) + ' .lead')[0];
            if (chartHeader) {
                chartHeader.innerText = chart.get('name');
            }
            if (newVdmPackage === vdmPackage) {
              var viewInstance = self.viewInstance;
              var previousBackBoneModel = window.vdmModelView.chartCollection.findWhere({ 'id': chart.id });
              previousBackBoneModel.set('name', self.name());
              previousBackBoneModel.set('description', self.description());
              self.editPresentationName()
              if(self.model){
                var charts = window.vdmModelView.charts();
                _.each(charts, function (presentationView) {
                    if (presentationView.viewInstance && presentationView.viewInstance.encodeId == window.utils.htmlEscape(chart.id)) {
                        if($("#view"+presentationView.encodeId).is(':visible')){
                            presentationView.viewInstance.initializePresentation();
                        }
                    }
                }); 
              } else{
                window.vdmModelView.reIntializePresentation();  
              }
            }
          } else {
              chart = Presentation.getInstance(self.name(), self.description(), newVdmPackage, chartConfig);
              if (self.selectedPresentationType() === self.canvasTitle) {
                  chart.set({'presentationReference': self.selectedCanvas().id,'presentationReferenceParent': self.selectedCanvas().parentId,'presentationReferenceAlt': self.selectedAlt().id,'presentationReferencePhase': self.selectedPhase().id,'presentationType': "Business Canvas"});
              } else if (self.selectedPresentationType() === self.ecoMapTitle) {
                  chart.set({'presentationReference': self.selectedEcoMap().id,'presentationReferenceParent': self.selectedEcoMap().parentId,'presentationReferenceAlt': self.selectedAlt().id,'presentationReferencePhase': self.selectedPhase().id,'presentationType': "Business Ecosystem Map"});
              } else if (self.selectedPresentationType() === self.strategyMapTitle) {
                  chart.set({'presentationReference': self.selectedStrategyMap().id,'presentationReferenceParent': self.selectedStrategyMap().parentId,'presentationReferenceAlt': self.selectedAlt().id,'presentationReferencePhase': self.selectedPhase().id,'presentationType': "Strategy Map"});
              } else if (self.selectedPresentationType() === self.vsMapTitle) {
                  chart.set({'presentationReference': self.selectedVsMap().id,'presentationReferenceParent': self.selectedVsMap().parentId,'presentationReferenceAlt': self.selectedAlt().id,'presentationReferencePhase': self.selectedPhase().id,'presentationType': "Value Stream Map"});
              } else if (self.selectedPresentationType() === self.capMapTitle) {
                  chart.set({'presentationReference': self.selectedCapMap().id,'presentationReferenceParent': self.selectedCapMap().parentId,'presentationType': "Capability Map"});
              } else {
                  chart.set('presentationType', self.selectedPresentationType());
              }
              self.model.get('presentation').push(chart);
          }

        if (newVdmPackage !== vdmPackage) {
            jQuery('#' + self.encodeId).hide();
            self.cleanModal();
            DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newVdmPackage.get('id') + "/" + newVdmPackage.get('type') + "/" + newVdmPackage.get('version') + "/" + analyticChart.id + "/" + analyticChart.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
        } else {
            //TO refresh Presentations list Tab(Parent view) , arrange them in alphabetical order
            //options.parentView.parentView.chartCollection.sort();
            self.model.get('presentation').models.sort(self.sortByName);
            if (self.presentationsList) {
                self.presentationsList.removeAll();
                self.model.get('presentation').forEach(function (chart) {
                    self.presentationsList.push({ id: chart.id, name: chart.get('name'), escapeId: self.htmlEscape(chart.id), type: 'Chart' });
                });
                self.handleSavePresentation(self.uId,chart.id,chart.get('name'),self.treeData);
                var dashboardModel = Backbone.Relational.store.getObjectByName("dashboard.Dashboard").find({ id: self.model.get("id") })
                dashboardModel.set("treeStructure", JSON.stringify(self.treeData));
                window.vdmModelView.eventBus.emit("updatedNav", {
                  component: NavigationTree,
                  treeData: self.treeData
                });
                window.vdmModelView.eventBus.emit("updateProps",{
                    component: PresentationDataGrid, dashboardId: self.model.get("id"),
                });
                window.vdmModelView.eventBus.emit(`${self.uId}addPresentation`, self.uId);
                setTimeout(()=>{
                  window.vdmModelView.renderPresentation("");
                  window.vdmModelView.setResizeHeight();
                },10)
               
            }
            jQuery('#' + self.encodeId).hide();
            self.cleanModal();
        }
        window.utils.stopSpinner("createChart");
      }, 100);
    });
  }
  refreshValueTable() {
    var self = this;
    self.valueRowsList.removeAll();
    /*_.each(self.selectedValuesArray,function(valId){
      var element = window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']);
      var currentValueObj = {'displayName':element.get('name'),'escapeId':element.get('id')};
      self.valueRowsList.push(currentValueObj);
    });*/
    self.valueRowsList(self.selectedValuesArray);
  }
  refreshTable(selectedScList) {
    var self = this;
    /*var altCount = self.selectedAlternativeArray.length;
    var obsCount = self.selectedObservationArray.length;
    self.scenarioRowsList.removeAll();
    if (altCount && obsCount) {
      var objPhase = {};
      for (var i = 0; i < altCount; i++) {
        for (var j = 0; j < obsCount; j++) {
          var alt = self.selectedAlternativeArray[i];
          var obs = self.selectedObservationArray[j];
          var obj = {};
          obj.altName = alt.name;
          obj.displayName = alt.name + " / " + obs.name;
          obj.comboEscapeId = self.htmlEscape(alt.id) + obs.escapeId;
          obj.timeStamp = obs.timeStamp;
          obj.altId = alt.id;
          obj.obsId = obs.id;
          obj.obsName = obs.name;
          obj.snapshot = obs.snapshot;
          if (alt.timeStamp) {
            obj.timeStamp = alt.timeStamp;
          }
          for (var a = 0; a < self.alternativeLabels.length; a++) {
            if (self.alternativeLabels[a].id === alt.id) {
              obj.alternativeLabels = self.alternativeLabels[a];
            }
          }
          for (var b = 0; b < self.scenarioLabels.length; b++) {
            if (self.scenarioLabels[b].id === obs.id) {
              obj.scenarioLabels = self.scenarioLabels[b];
            }
          }
          if (obs.timeStamp && alt["labelInAlts"]) {
              obj.label = alt["labelInAlts"][obs.timeStamp] ? alt["labelInAlts"][obs.timeStamp]: "";
          } else if (alt["labelInAlts"]) {
              obj.label = alt["labelInAlts"]["Base"] ? alt["labelInAlts"]["Base"] : "";
          }
          self.scenarioRowsList.push(obj);
        }
      }
    } else if (altCount) {
      for (var i = 0; i < altCount; i++) {
        var alt = self.selectedAlternativeArray[i];
        var obj = {};
        obj.altName = alt.name;
        obj.displayName = alt.name;
        obj.comboEscapeId = self.htmlEscape(alt.id);
        obj.altId = alt.id;
        obj.alternativeLabels = self.alternativeLabels;
        obj.scenarioLabels = self.scenarioLabels;
        if (alt["labelInAlts"]) {
            obj.label = alt["labelInAlts"]["Base"] ? alt["labelInAlts"]["Base"] : "";
        }
        if (obsCount === 0) {
          obj.returnVal = true;
        }
        self.scenarioRowsList.push(obj);
      }
    }*/
    self.scenarioRowsList.removeAll();
    if(!selectedScList){
        return;
    }
    for(var i=0; i<selectedScList.length; i++){
      self.scenarioRowsList.push(selectedScList[i]);
    }
  }
  editScenarioDetails(data, event) {
    var self = this;
    //TODO reuse same as editValueDetails
  }
  editValueDetails(data, event) {
    var self = this;
    var label = data.label ? data.label : "";
    var groupLabel = data.groupLabel ? data.groupLabel : "";
    var htmlContent = '<div class="row">';
    htmlContent = htmlContent + '<div class="col-xs-1"></div>';
    htmlContent = htmlContent + '<div class="col-xs-3">';
    htmlContent = htmlContent + '<span class="control-label">'+DataManager.getDataManager().get('localeManager').get('name')+'</span>';
    htmlContent = htmlContent + "</div>";
    htmlContent = htmlContent + '<div class="col-xs-7">';
    htmlContent = htmlContent + "<span>" + data.name + "</span>";
    htmlContent = htmlContent + "</div>";
    htmlContent = htmlContent + '<div class="col-xs-1"></div>';
    htmlContent = htmlContent + "</div><br>";
    htmlContent = htmlContent + '<div class="row">';
    htmlContent = htmlContent + '<div class="col-xs-1"></div>';
    htmlContent = htmlContent + '<div class="col-xs-3">';
    htmlContent = htmlContent + '<span class="control-label">'+DataManager.getDataManager().get('localeManager').get('Label')+'</span>';
    htmlContent = htmlContent + "</div>";
    htmlContent = htmlContent + '<div class="col-xs-7">';
    htmlContent = htmlContent + '<input id="label" class="form-control focus-ele" autofocus type="text" value=\'' + label + "'></input>";
    htmlContent = htmlContent + "</div>";
    htmlContent = htmlContent + '<div class="col-xs-1"></div>';
    htmlContent = htmlContent + "</div>";
    //for warning
    htmlContent = htmlContent + '<div class="row">';
    htmlContent = htmlContent + '<div class="col-xs-4"></div>';
    htmlContent = htmlContent + '<div class="col-xs-7">';
    htmlContent = htmlContent + '<span class=" warning hide" style="font-size: 12px;color: orange;">*Value Label with same name already exists.</span>';
    htmlContent = htmlContent + "</div>";
    htmlContent = htmlContent + '<div class="col-xs-1"></div>';
    htmlContent = htmlContent + "</div><br>";

    htmlContent = htmlContent + '<div class="row">';
    htmlContent = htmlContent + '<div class="col-xs-1"></div>';
    htmlContent = htmlContent + '<div class="col-xs-3">';
    htmlContent = htmlContent + '<span class="control-label">'+DataManager.getDataManager().get('localeManager').get('GroupLabel')+'</span>';
    htmlContent = htmlContent + "</div>";
    htmlContent = htmlContent + '<div id="groupLabelDiv" class="col-xs-7">';
    htmlContent = htmlContent + "</div>";
    htmlContent = htmlContent + '<div class="col-xs-1"></div>';
    htmlContent = htmlContent + "</div><br>";

    var box = bootbox.dialog({
      message: htmlContent,
      title: '<div><img class="smallIcon" src="img/icons/icons_15.png">&nbsp;&nbsp;&nbsp;'+DataManager.getDataManager().get('localeManager').get('ValueProperties')+'</div>',
      buttons: {
        danger: {
          label: "&nbsp;Delete",
          className: "btn btn-danger glyphicon glyphicon-trash pull-left",
          callback: function () {
            self.selectedValuesArray.splice( _.findLastIndex(self.selectedValuesArray, { id: data.id }), 1);
            self.refreshTable();
          },
        },
        cancel: {
          label: "Close",
          className: "btn btn-default",
          callback: function () {},
        },
        success: {
          label: "Complete",
          className: "valueDetailsComplete btn btn-complete",
          callback: function () {
            if (jQuery("#label").val()) {
              var labelValue = jQuery("#label").val().trim();
              var exists = self.valueLabels.map(function (e) {
                  return e.label;
                }).indexOf(labelValue);
              data.label = labelValue;
              if (exists < 0) {
                self.valueLabels.push({ id: data.suffixId, label: labelValue });
              } else {
                self.valueLabels[exists] = { id: data.suffixId, label: labelValue };
              }
            } else {
              data.label = jQuery("#label").val();
            }
            if (jQuery("#groupLabel").val()) {
              data.groupLabel = jQuery("#groupLabel").val().trim();
              var exists = self.groupLabelTypeAhead.findWhere({ name: data.groupLabel });
              if (!exists) {
                self.groupLabelTypeAhead.add({ name: data.groupLabel });
              }
            } else {
              data.groupLabel = jQuery("#groupLabel").val();
            }
            self.enableComplete(true);
          },
        },
      },
    });
    if(document.fullscreenElement){
      box.appendTo(`#${document.fullscreenElement.id}`)
    }else{
      box.show();
    } 
    //for group label
    var groupLabelExtended = Backbone.Typeahead.extend({
      template:
        '<input type="text" id="groupLabel" placeholder="Select group label" width="90%" class="form-control focus-ele"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
    });
    self.groupTypeAhead = new groupLabelExtended({ collection: self.groupLabelTypeAhead  });
    self.groupTypeAhead.setElement("#groupLabelDiv").render();
    jQuery("#groupLabel").val(data.groupLabel);
    //for label
    jQuery(".valueDetailsComplete").attr("disabled", "disabled");
    jQuery("#groupLabel").keyup(function (event) {
      var value = jQuery("#label").val().trim();
      var exists = _.find(self.valueLabels, function (e) {
        return e.id !== data.suffixId && e.label === value;
      });
      if (exists && value != "") {
        jQuery(".warning").removeClass("hide");
        jQuery(".valueDetailsComplete").attr("disabled", "disabled");
      } else {
        jQuery(".warning").addClass("hide");
        jQuery(".valueDetailsComplete").removeAttr("disabled");
      }
    });
    jQuery("#label").keyup(function (event) {
      var value = event.currentTarget.value.trim();
      var exists = _.find(self.valueLabels, function (e) {
        return e.id !== data.suffixId && e.label === value;
      });
      if (exists && value != "") {
        jQuery(".warning").removeClass("hide");
        jQuery(".valueDetailsComplete").attr("disabled", "disabled");
      } else {
        jQuery(".warning").addClass("hide");
        jQuery(".valueDetailsComplete").removeAttr("disabled");
      }
    });
  }
  openSelectScenariosView() {
    var self = this;
    var id = self.htmlEscape(window.guidGenerator()) + "addObservation";
    var addOptions = { viewType: "addObservation" };
    var mixinClass = self.model.getMixinClass();
    window.getAndCreateModalDialog(self,id,mixinClass, self.model,"SelectScenario",null,addOptions);
  }
  openSelectValuesView() {
    var self = this;
    var id = self.htmlEscape(window.guidGenerator()) + "addValue";
    var addOptions = { viewType: "addValue" };
    var mixinClass = self.model.getMixinClass();
    window.getAndCreateModalDialog(self,id,mixinClass,self.model,"SelectChartValues",null, addOptions);
  }
  sortByName(left, right) {
    return left.get("name").toLowerCase() === right.get("name").toLowerCase() ? 0 : left.get("name").toLowerCase() < right.get("name").toLowerCase() ? -1 : 1;
  }
  sortByChartName(left, right) {
    return left.name().toLowerCase() === right.name().toLowerCase() ? 0 : left.name().toLowerCase() < right.name().toLowerCase() ? -1 : 1;
  }
  saveConfiguration() {
    var self = this;
    var chartConfig = {};
    chartConfig.xAxisSelected = [];
    chartConfig.selectedAlternativeObjs = [];
    chartConfig.selectedObservationObjs = [];
    chartConfig.selectedObjectiveObjs = [];
    chartConfig.altLabels = [];
    chartConfig.valueLabels = [];
    chartConfig.valueGroupLabels = [];
    var selectPhaseList = [];
    _.each(self.selectedObservationArray, function (obs) {
      chartConfig.selectedObservationObjs.push({ id: obs.id, timeStamp: obs.timeStamp, snapshot: obs.snapshot });
    });
    chartConfig.selectedValues = [];
    chartConfig.selectedValueObjs = [];
    _.each(self.selectedValuesArray, function (val) {
        chartConfig.selectedValueObjs.push(val.id);
        //chartConfig.valueLabels.push({ id: val.suffixId, label: val.label });
        //chartConfig.valueGroupLabels.push(val.groupLabel);
    });
    chartConfig.valueGroupLabels = _.uniq(chartConfig.valueGroupLabels);
    chartConfig.selectedChartType = self.selectedChartType;
    chartConfig.alternativeLabels = self.alternativeLabels;
    chartConfig.scenarioLabels = self.scenarioLabels;
    if (self.selectedChartPageLength) {
      chartConfig.selectedChartPageLength = self.selectedChartPageLength;
    }
    // window.vdmModelView.savePresentationJsonStructure()
    return JSON.stringify(chartConfig);
  }

  deleteNode(nodes, targetNodeId) {
    var self = this;
    return nodes.reduce((result, node) => {
      if (node.id === targetNodeId) {
      } else {
      if (node.subRows && node.subRows.length > 0) {
        node.subRows = node.subRows.map((childNode) => ({
        ...childNode,
        }));
        node.subRows = self.deleteNode(node.subRows, targetNodeId);
      }
      result.push(node);
      }
      return result;
    }, []);
  }

  updatePresentationName(id, treeData, newValue) {
    var self = this
    if (treeData.uId === id) {
        treeData['name'] = newValue;
        return treeData; 
    }
    if (treeData.subRows) {
        for (let child of treeData.subRows) {
            const updatedChild = self.updatePresentationName(id, child, newValue);
            if (updatedChild) {
                return treeData;
            }
        }
    }
    return null;
}

  editPresentationName(){
    var self = this
    window.vdmModelView.hashTreeData.get(self.uId).name = self.name();
    const updatedData = self.updatePresentationName(self.uId,self.treeData,self.name());
    var dashboardModel = Backbone.Relational.store.getObjectByName("dashboard.Dashboard").find({ id: self.model.get("id") })
    dashboardModel.set("treeStructure", JSON.stringify(updatedData));
   
    window.vdmModelView.eventBus.emit(
      "updateProps",
      {
        component: PresentationDataGrid,
        dashboardId: self.model.get("id"),
      }
    );
    window.vdmModelView.eventBus.emit("updatedNav", {
      component: NavigationTree,
      treeData:updatedData
    });
    window.vdmModelView.eventBus.emit(`${self.uId}addPresentation`, self.uId);
  }

  deletePresentation() {
    var self = this;
    bootbox.confirm(self.localeManager.get("PresentationDelete"),function (result) {
        if (result) {
          var chartModel = self.chartModel;
          self.parentView.deleteChart({ id: chartModel.get("id") });
          self.cleanModal();          
        }
      }
    );
  }

  handleComplete() {
    var self = this;
    var inputVal = jQuery("#name" + self.encodeId).val().trim();
    var chart = this.chartModel;
    var charId = chart ? chart.get("id") : "";
    if (inputVal !== "") {
      var chartList = window.vdmModelView.model.get("presentation").models;
      var exists =  _.filter(chartList, function (present) {
          return present.get("name") == inputVal && present.get("id") != charId;
        });
      // exists = _.find(options.parentView.presentationsList(), function(e){ return e.name === inputVal && (self.chartModel ? e.id !== self.chartModel.id : true);});
      if (exists && exists.length > 0) {
        self.enableComplete(false);
        jQuery(".warning").removeClass("hide");
      } else if (self.selectedPresentationType() === "Chart/Table" && self.selectedValuesArray.length > 0 ) {
        if (chart) {
          var chartConfig = JSON.parse(chart.get("chartConfig"));
          if (!chartConfig.selectedObjectiveObjs) {
            chartConfig.selectedObjectiveObjs = [];
          }
          if ( chart.get("name") !== inputVal || checkChanges( self.selectedValuesArray, chartConfig.selectedValueObjs, "suffixId" ) ||
              checkChanges( self.selectedAlternativeArray, chartConfig.selectedAlternativeObjs, "id" ) ||
              checkChanges( self.selectedObservationArray, chartConfig.selectedObservationObjs, "id" ) ||
              checkNonUniqueProperty( self.selectedObservationArray, chartConfig.selectedObservationObjs, "snapshot" )
          ) {
              self.enableComplete(true);
          } else {
              self.enableComplete(false);
          }
        } else {
          self.enableComplete(true);
        }
        function checkChanges(a, b, attr) {
          var bigArray = a.length > b.length ? a : b;
          var smallArray = a.length > b.length ? b : a;
          for (var i = 0; i < bigArray.length; i++) {
            var value = bigArray[i];
            var index = smallArray.findIndex(function (def) {
              return def[attr] === value[attr];
            });
            if (index === -1) {
              return true;
            }
          }
          return false;
        }
        function checkNonUniqueProperty(a, b, attr) {
          if (a.length != b.length) {
            return true;
          }
          for (var i = 0; i < a.length; i++) {
            if (a[i][attr] !== b[i.attr]) {
              return true;
            }
          }
          return false;
        }
      } else if ( self.selectedPresentationType() === self.ecoMapTitle && self.selectedEcoMap() ) {
        if (!chart || chart.get("name") !== inputVal) {
          self.enableComplete(true);
        } else {
          self.enableComplete(false);
        }
      } else if ( self.selectedPresentationType() === self.strategyMapTitle && self.selectedStrategyMap() ) {
        if (!chart || chart.get("name") !== inputVal) {
          self.enableComplete(true);
        } else {
          self.enableComplete(false);
        }
      } else if ( self.selectedPresentationType() === self.vsMapTitle && self.selectedVsMap() ) {
        if (!chart || chart.get("name") !== inputVal) {
          self.enableComplete(true);
        } else {
          self.enableComplete(false);
        }
      } else if ( self.selectedPresentationType() === self.capMapTitle && self.selectedCapMap() ) {
        if (!chart || chart.get("name") !== inputVal) {
          self.enableComplete(true);
        } else {
          self.enableComplete(false);
        }
      } else if ( self.selectedPresentationType() === self.canvasTitle && self.selectedCanvas() ) {
        if (!chart || chart.get("name") !== inputVal) {
          self.enableComplete(true);
        } else {
          self.enableComplete(false);
        }
      } else if (self.selectedPresentationType() === "Notes") {
        if (!chart || chart.get("name") !== inputVal) {
          self.enableComplete(true);
        } else {
          self.enableComplete(false);
        }
      } else {
        self.enableComplete(false);
      }
      if (exists && exists.length == 0) {
        jQuery(".warning").addClass("hide");
      }
    } else {
      self.enableComplete(false);
    }
  }
  fillPhases() {
    var self = this;
    var phases = DataManager.getDataManager().get("currentPlan").get("phase");
    for (var i = 0; i < phases.length; i++) {
      self.phaseList.push({
        id: phases.at(i).get("id"),
        name: phases.at(i).get("name"),
      });
    }
  }
  fillAlternatives() {
    var self = this;
    var planPhases = DataManager.getDataManager().get("currentPlan").get("phase");
    var phaseId = self.selectedPhase().id;
    for (var j = 0; j < planPhases.length; j++) {
      if (planPhases.at(j).id === phaseId) {
        var selPhase = planPhases.at(j);
        break;
      }
    }
    if (selPhase) {
      var alts = selPhase.get("phaseAlternative");
      alts.each(function (alt) {
        self.altList.push({ id: alt.get("id"), name: alt.get("name") });
      });
    }
  }
  fillEcoMaps(altId,type,allList) {
    var self = this;
    var alt = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: altId });
    var packRefs = alt.get('phaseDesignPart');
    var mapList = [];
    for (var i = 0; i < packRefs.length; i++) {
        if (packRefs.at(i).get('beepType') === "ecomap_EcoMap") {
            var ecoMapPackage = window.utils.getElementModel(packRefs.at(i).get('beepReference'),['ecomap.EcoMap']);
            if(ecoMapPackage){
              var ecomaps = ecoMapPackage.get("diagrams");
              var presentationList = self.model.get("presentation");
              for (var j = 0; j < ecomaps.length; j++) {
                var parentId = ecoMapPackage.id;
                var ecoMapId = ecomaps.at(j).id;
                //self.allEcoMapsList.push({ id: ecoMapId, name: ecomaps.at(j).get("name"), parentId: parentId });
                allList.push({ id: ecoMapId, name: ecomaps.at(j).get("name"), parentId: parentId });
                if (!presentationList.findWhere({ presentationReference: ecoMapId }) && ecomaps.at(j).get("type") === type ) {
                  //self.ecoMapsList.push({ id: ecomaps.at(j).get("id"), name: ecomaps.at(j).get("name"), parentId: parentId });
                  mapList.push({ id: ecomaps.at(j).get("id"), name: ecomaps.at(j).get("name"), parentId: parentId });
                }
              }
            }
        }
    }
    return mapList;
  }

    checkCapMap(pack, capMapPackageList) {
        var check = false;
        for (var i = 0; i < capMapPackageList.length; i++) {
            if (pack.get('parent') === capMapPackageList[i].id) {
                check = true;
                break;
            }
        }
        return check;
    }
    fillCapMaps(altId) {
        var self = this;
        //var alt = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: altId });
        loadLibraryPackages()
        function loadLibraryPackages() {
            var capMapPackageList = []; var capMapExist;
            var planId = self.model.id.substr(self.model.id.lastIndexOf('@'));
            var commonAltId = planId + "-Common@";
            DataManager.getDataManager().getAllDocumentsCollectionOfType(commonAltId, 'vdml_CapabilityLibraryDiagram', function (result) {
                if (result && result.length > 0) {
                    _.each(result.models, function (pack) {
                        if (pack.get('id').indexOf("Common") > -1) {
                            if (pack.get('id').indexOf('CommonPackage') === -1) {
                                if (capMapPackageList.length > 0) {
                                    capMapExist = self.checkCapMap(pack, capMapPackageList);
                                }
                                if (!capMapExist) {
                                    capMapPackageList.push({ id: pack.get('parent'), name: pack.get('parentName'), version: pack.get('version') });
                                }
                            }
                        }
                    });
                }
                fetchCapMaps(capMapPackageList);
            }, false, null, true);
        }
        function fetchCapMaps(capMapPackageList) {
            async.eachSeries(capMapPackageList, function (pack, capCallback) {
                var model = window.utils.getElementModel(pack.id,['vdml.ValueDeliveryModel']);
                if(model){
                    showSelectCapMap(model); 
                }
                capCallback();
            }, function (err) {
                function sortCapMaps(left, right) {
                    return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
                }
                self.capMapsList.sort(sortCapMaps);
            });
        }

    function showSelectCapMap(capMapPackage) {
      var checkCapMapList;
      var capmaps = capMapPackage.get("diagrams");
      var presentationList = self.model.get("presentation");
      for (var i = 0; i < capmaps.length; i++) {
        var parentId = capmaps.models[i].get("diagramOwner").get("id");
        var capMapId = capmaps.models[i].id;
        self.allCapMapsList.push({ id: capMapId, name: capmaps.models[i].get("name"), parentId: parentId });
        if ( !presentationList.findWhere({ presentationReference: capMapId }) && capmaps.models[i].get("type") === "vdml_CapabilityLibraryDiagram" ) {
          if (self.capMapsList().length > 0) {
            checkCapMapList = self.checkCapMapsExist(capMapId, self.capMapsList());
          }
          if (!checkCapMapList) {
            self.capMapsList.push({ id: capmaps.models[i].id, name: capmaps.models[i].get("name"), parentId: parentId });
          }
        }
      }
    }
  }
  checkCapMapsExist(capMapId, capMapsList) {
    var check = false;
    for (var i = 0; i < capMapsList.length; i++) {
      if (capMapsList[i].id === capMapId) {
        check = true;
      }
    }
    return check;
  }

  fillCanvases(altId) {
    var self = this;
    var alt = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: altId });

        function fetchCanvas(canvasPackageList) {
            async.eachSeries(canvasPackageList, function (packId, canvasCallback) {
                var model = window.utils.getElementModel(packId,['vdml.ValueDeliveryModel']);
                if(model){
                    selectCanvas(model); 
                }
                canvasCallback();
            }, function (err) {
                function sortCanvases(left, right) {
                    return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
                }
                self.canvasesList.sort(sortCanvases);
            });
        }
        function selectCanvas(canvasPackage) {
            var canvas = canvasPackage.get('canvas');
            var presentationList = self.model.get('presentation')
            for (var i = 0; i < canvas.length; i++) {
                var parentId = canvas.at(i).getNestedParent().id;
                var canvasId = canvas.at(i).id;
                self.allCanvasesList.push({ id: canvasId, name: canvas.at(i).get('name'), parentId: parentId });
                if (!presentationList.findWhere({ 'presentationReference': canvasId })) {
                    self.canvasesList.push({ id: canvas.at(i).get('id'), name: canvas.at(i).get('name'), parentId: parentId });
                }
            }
        }

    function selectCanvasPackageList() {
      var packRefs = alt.get("phaseDesignPart");
      var canvasPackageList = [];
      for (var i = 0; i < packRefs.length; i++) {
        if (packRefs.at(i).get("beepType") === "vdml_ValueDeliveryModel" && packRefs.at(i).get("beepReference").indexOf("Common") == -1) {
          canvasPackageList.push(packRefs.at(i).get("beepReference"));
        }
      }
      fetchCanvas(canvasPackageList);
    }
    selectCanvasPackageList();
  }

  init(model, options) {
    var self = this;
    self.openSelectValuesView = _.bind(self.openSelectValuesView, self);
    self.openSelectScenariosView = _.bind(self.openSelectScenariosView, self);
    self.editValueDetails = _.bind(self.editValueDetails, self);
    self.afterRenderView = _.bind(self.afterRenderView, self);
    this.CreateChart2ViewModel = this;
    this.model = model;
    this.id = kb.observable(model, "id");
    this.encodeId = options.modalId;
    this.name = ko.observable("");
    this.description = ko.observable("");
    this.enableComplete = ko.observable(false);
    this.enableDelete = ko.observable(false);
    if (options.parentView) {
      self.parentView = options.parentView;
      this.presentationsList = options.parentView.presentationsList;
    }
    if(options.addOptions){
      this.rowId = options.addOptions.rowId;
      this.uId = options.addOptions.uId;
      this.treeData = options.addOptions.treeData;
    }
    if (options.addOptions.chartModel) {
      this.chartModel = options.addOptions.chartModel;
      this.config = this.chartModel.get("chartConfig");
    }
    this.addValueButton = ko.observable(true);
    this.localeManager = DataManager.getDataManager().get("localeManager");
    this.ecoMapTitle = self.localeManager.get("BusinessEcosystemMap");
    this.strategyMapTitle = self.localeManager.get("StrategyMap");
    this.vsMapTitle = self.localeManager.get("ValueStreamMap");
    this.capMapTitle = self.localeManager.get("CapabilityMap");
    this.canvasTitle = self.localeManager.get("BusinessCanvas");
    this.presentationTypes = ko.observableArray([self.canvasTitle, self.ecoMapTitle, self.capMapTitle, "Chart/Table", "Notes", self.strategyMapTitle, self.vsMapTitle]);
    this.selectedPresentationType = ko.observable();
    this.selectedAlternativeArray = [];
    this.selectedObservationArray = [];
    this.scenarioRowsList = ko.observableArray([]);
    this.selectedValuesArray = [];
    this.selectedChartPageLength = null;
    this.valueRowsList = ko.observableArray([]);
    this.groupLabelTypeAhead = new Backbone.Collection();
    this.altLabels = [];
    this.alternativeLabels = [];
    this.scenarioLabels = [];
    this.valueLabels = [];
    this.selectedPhase = ko.observable();
    this.selectedAlt = ko.observable();
    this.selectedCanvas = ko.observable();
    this.selectedEcoMap = ko.observable();
    this.selectedStrategyMap = ko.observable();
    this.selectedVsMap = ko.observable();
    this.selectedCapMap = ko.observable();
    this.phaseList = ko.observableArray([]);
    this.altList = ko.observableArray([]);
    this.canvasesList = ko.observableArray([]);
    this.ecoMapsList = ko.observableArray([]);
    this.strategyMapsList = ko.observableArray([]);
    this.vsMapsList = ko.observableArray([]);
    this.capMapsList = ko.observableArray([]);
    this.allCanvasesList = [];
    this.allEcoMapsList = [];
    this.allStrategyMapsList = [];
    this.allVsMapsList = [];
    this.allCapMapsList = [];
    this.chartType = ko.observable(false);
    this.canvasType = ko.observable(false);
    this.ecoMapType = ko.observable(false);
    this.strategyMapType = ko.observable(false);
    this.vsMapType = ko.observable(false);
    this.capMapType = ko.observable(false);
    this.otherType = ko.observable(false);
    self.fillPhases();

    jQuery("#modal" + self.encodeId).on("shown.bs.modal", function () {
      window.utils.focusElement("#newModal .focus-ele");
    });
    this.labels = kb.viewModel( DataManager.getDataManager().get("localeManager"), [
        "name",
        "description",
        "Plan",
        "BusinessModel",
        "Close",
        "Complete",
        "Delete",
        "type",
        "selectBusinessModel",
        "selectType",
        "selectphase",
        "selectalternative",
        "Values",
        "selectScenario",
        "selectScenarios",
        "CreatePresentation",
        "SelectBusinessCanvas",
      ]
    );
    this.selectedPresentationType.subscribe(function (type) {
      if (type === "Chart/Table") {
        self.chartType(true);
        self.canvasType(false);
        self.ecoMapType(false);
        self.strategyMapType(false);
        self.capMapType(false);
        self.vsMapType(false);
        self.otherType(false);
      } else if (type === "Notes") {
        self.chartType(false);
        self.canvasType(false);
        self.ecoMapType(false);
        self.strategyMapType(false);
        self.vsMapType(false);
        self.capMapType(false);
        self.otherType(false);
        self.enableComplete(true);
      } else if (type === self.canvasTitle) {
        self.chartType(false);
        self.canvasType(true);
        self.ecoMapType(false);
        self.strategyMapType(false);
        self.vsMapType(false);
        self.capMapType(false);
        self.otherType(true);
      } else if (type === self.ecoMapTitle) {
        self.chartType(false);
        self.canvasType(false);
        self.ecoMapType(true);
        self.strategyMapType(false);
        self.vsMapType(false);
        self.capMapType(false);
        self.otherType(true);
      } else if (type === self.strategyMapTitle) {
        self.chartType(false);
        self.canvasType(false);
        self.ecoMapType(false);
        self.strategyMapType(true);
        self.vsMapType(false);
        self.capMapType(false);
        self.otherType(true);
      } else if (type === self.vsMapTitle) {
        self.chartType(false);
        self.canvasType(false);
        self.ecoMapType(false);
        self.strategyMapType(false);
        self.capMapType(false);
        self.vsMapType(true);
        self.otherType(true);
      } else if (type === self.capMapTitle) {
        self.chartType(false);
        self.canvasType(false);
        self.ecoMapType(false);
        self.strategyMapType(false);
        self.vsMapType(false);
        self.capMapType(true);
        self.otherType(false);
        self.allCapMapsList.length = 0;
        self.fillCapMaps();
        self.handleComplete();
      }
    });
    this.name.subscribe(function (val) {
      self.description(val);
    });
    this.selectedPhase.subscribe(function (val) {
      self.ecoMapsList.removeAll();
      self.strategyMapsList.removeAll();
      self.vsMapsList.removeAll();
      //self.capMapsList.removeAll();
      self.canvasesList.removeAll();
      self.allCanvasesList.length = 0;
      self.allEcoMapsList.length = 0;
      self.allStrategyMapsList.length = 0;
      self.allVsMapsList.length = 0;
      //self.allCapMapsList.length = 0;
      self.altList.removeAll();
      if (val) {
        self.fillAlternatives();
      }
      self.handleComplete();
    });
    this.selectedAlt.subscribe(function (val) {
      self.ecoMapsList.removeAll();
      self.strategyMapsList.removeAll();
      self.vsMapsList.removeAll();
      //self.capMapsList.removeAll();
      self.canvasesList.removeAll();
      self.allCanvasesList.length = 0;
      self.allEcoMapsList.length = 0;
      self.allStrategyMapsList.length = 0;
      self.allVsMapsList.length = 0;
      //self.allCapMapsList.length = 0;
      if (val) {
        function sortEcoMaps(left, right) {
          return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
        }
        var mapList = [];
        if (self.selectedPresentationType() === self.ecoMapTitle) {
          mapList = self.fillEcoMaps(val.id,'vdml_EcoMapDiagram',self.allEcoMapsList);
          if(mapList && mapList.length > 0){
            mapList.sort(sortEcoMaps);
            self.ecoMapsList(mapList);
          }
        } else if (self.selectedPresentationType() === self.strategyMapTitle) {
          mapList = self.fillEcoMaps(val.id,'vdml_StrategyMapDiagram',self.allStrategyMapsList);
          if(mapList && mapList.length > 0){
            mapList.sort(sortEcoMaps);
            self.strategyMapsList(mapList);
          }
        } else if (self.selectedPresentationType() === self.vsMapTitle) {
          mapList = self.fillEcoMaps(val.id,'vdml_ValueStreamMapDiagram',self.allVsMapsList);
          if(mapList && mapList.length > 0){
            mapList.sort(sortEcoMaps);
            self.vsMapsList(mapList);
          }
        } else if (self.selectedPresentationType() === self.canvasTitle) {
          self.fillCanvases(val.id);
        }
       
      }
      self.handleComplete();
    });

    this.selectedCanvas.subscribe(function (val) {
      self.handleComplete();
    });

    this.selectedEcoMap.subscribe(function (val) {
      self.handleComplete();
    });

    this.selectedStrategyMap.subscribe(function (val) {
      self.handleComplete();
    });

    this.selectedVsMap.subscribe(function (val) {
      self.handleComplete();
    });

    this.selectedCapMap.subscribe(function (val) {
      self.handleComplete();
    });

    if (options.addOptions.chartModel && !options.addOptions.hideDelete) {
      self.enableDelete(true);
    }
  }
  static getInstance(model, options) {
    var view = new CreateChart2ViewModel(model, options);
    view.init(model, options);
    return view;
  }
}
path.CreateChart2ViewModel = CreateChart2ViewModel;
