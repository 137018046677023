import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {NamedPlanElement} from './NamedPlanElement'
import {PlanMixin} from './PlanMixin'
import {Plan2Mixin} from './Plan2Mixin'
import {Alternative} from './Alternative'
import {Vocabulary} from '../concept/Vocabulary'

import {BeepPackage} from '../beeppackage/BeepPackage'
import {BeepPackageMixin} from '../beeppackage/BeepPackageMixin'
import {Phase} from './Phase'
import {Role} from './Role'
import {User} from '../tickets/User'
import {ValueProposition} from '../vdml/ValueProposition'
import {SmmModel} from '../smm/SmmModel'
import { DataManager } from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/transformation/NamedPlanElement","appbo/transformation/PlanMixin","appbo/transformation/Alternative","appcommon/com/vbee/filesystem/PackageReference","appbo/concept/Vocabulary"],
function(require,jQuery,_, Backbone,Lawnchair, backboneLawnchair,DataManager,global,NamedPlanElement,PlanMixin,Alternative,PackageReference,Vocabulary
, BeepPackage
, BeepPackageMixin
, Phase
, Role
, User
, ValueProposition
, SmmModel){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
	if(!BeepPackage){
		var importPath = DataManager.getDataManager().buildAppNsPath("beeppackage",global.version);
		if(importPath.BeepPackage){
			BeepPackage = importPath.BeepPackage;
		}
        else {
            import('../beeppackage/BeepPackage').then(loadedModule => {
                BeepPackage = loadedModule;
            });
            /*
			require(["appbo/beeppackage/BeepPackage"],function(loadedModule){
				BeepPackage = loadedModule;
			});*/
		}
	}
	if(!BeepPackageMixin){
		var importPath = DataManager.getDataManager().buildAppNsPath("beeppackage",global.version);
		if(importPath.BeepPackageMixin){
			BeepPackageMixin = importPath.BeepPackageMixin;
		}
        else {
            import('../beeppackage/BeepPackage').then(loadedModule => {
                BeepPackage = loadedModule;
            });
			/*require(["appbo/beeppackage/BeepPackageMixin"],function(loadedModule){
				BeepPackageMixin = loadedModule;
			});*/
		}
	}
	if(!Phase){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.Phase){
			Phase = importPath.Phase;
		}
        else {
            import('./Phase').then(loadedModule => {
                Phase = loadedModule;
            });
			/*require(["appbo/transformation/Phase"],function(loadedModule){
				Phase = loadedModule;
			});*/
		}
	}
	if(!Role){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.Role){
			Role = importPath.Role;
		}
        else {
            import('./Role').then(loadedModule => {
                Role = loadedModule;
            });
			/*require(["appbo/transformation/Role"],function(loadedModule){
				Role = loadedModule;
			});*/
		}
	}
	if(!User){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.User){
			User = importPath.User;
		}
        else {
            import('../tickets/User').then(loadedModule => {
                User = loadedModule;
            });
			/*require(["appbo/tickets/User"],function(loadedModule){
				User = loadedModule;
			});*/
		}
	}
	if(!ValueProposition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueProposition){
			ValueProposition = importPath.ValueProposition;
		}
        else {
            import('../vdml/ValueProposition').then(loadedModule => {
                ValueProposition = loadedModule;
            });
			/*
			require(["appbo/vdml/ValueProposition"],function(loadedModule){
				ValueProposition = loadedModule;
			});*/
		}
	}
	if(!SmmModel){
		var importPath = DataManager.getDataManager().buildAppNsPath("smm",global.version);
		if(importPath.SmmModel){
			SmmModel = importPath.SmmModel;
		}
        else {
            import('../smm/SmmModel').then(loadedModule => {
                SmmModel = loadedModule;
            });
			/*
			require(["appbo/smm/SmmModel"],function(loadedModule){
				SmmModel = loadedModule;
			});*/
		}
	}
	var getMixinClass = function (){
		var toggleRouter = DataManager.getDataManager().get("toggleRouter");
		var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
		return sp2Enabled == true ? Plan2Mixin : PlanMixin;
	}
	var planMixinClass = getMixinClass();
	var Plan = NamedPlanElement.extend(utils.customExtends({
		relations:planMixinClass.getMixinRelations(),
		initialize: function(attributes, options) {
			if(this.isNew()){
				this.set('version',DataManager.getDataManager().get('currentPlanVersion'));	
			}
			if(!this.get('originalId')){
				this.set('originalId',this.get('id'));	
			}
			if(!attributes.documentId){
				this.set("documentId",attributes.id);
				attributes.documentId = attributes.id;
			}
			if(attributes.documentVersion == null){
				this.set("documentVersion","0");
				attributes.documentVersion = "0";
			}
			if(DataManager && DataManager.getDataManager() && !DataManager.getDataManager().get("artifactsDocuments")[this.get("documentId")] ){
				DataManager.getDataManager().get("artifactsDocuments")[this.get("documentId")] = {"artifactId":this.get("id") ,"name": this.get("name"),"documentId":this.get("documentId"),"documentVersion":this.get("documentVersion"),"workspaceId":DataManager.getDataManager().get("currentWorkspaceId")};
				DataManager.getDataManager().get('planPackages')[this.get("id")] = [];
			}
			NamedPlanElement.prototype.initialize.apply(this, arguments);
			//this.on("change:name", this.handlePlanNameChange);
			if(attributes.planType){
				//this.set('planType',path.PlanType[attributes.planType instanceof Object ? attributes.planType.name : attributes.planType]);
			}
			if(attributes.status){
				this.set('status',path.Status[attributes.status instanceof Object ? attributes.status.name : attributes.status]);
			}
			
			this.get('phase').comparator= function(m1,m2){
		         while(true)
		         {
		         	 if(m1.get('nextPhase')==null){
	                	return 1;
	                }
	                else if(m1.get('nextPhase')==m2)
	                {
	                	return -1;
	                }
	                else
	                {
	                	m1=m1.get('nextPhase');
	                	
	                }
	                
		         }            
			};
/*			if(this.isNew()){
				this.set('planPackage',new Backbone.Collection());
			}*/
			/*if(this.get('phase').length === 0){
				var createCommonPackage = this.isNew();
				this.on('sync',function(model,resp,options){
					if(!options || (options && !options.silent)){
						this.loadAlternativeWorkspaces(createCommonPackage);
						createCommonPackage = null;
					}
				});
			}*/
			
		}
		}
		, new BeepPackageMixin()
		, new planMixinClass()
	));
//#startCustomMethods			
	Plan.createNewPhase = function(name,description,change,alternativesArray,planModel,callback){
			var phaseId = DataManager.getDataManager().guidGeneratorByOwner(planModel);
			var phase = new Phase({
				'id':phaseId,
				'name':name,
				'description':description,
				'status' :path.Status['Initiated'],
				'change' :change,
				'phaseOwner':planModel});
 			
			var phases = planModel.get('phase');	
			phases.sort();
			phase.savePhaseAlternatives(alternativesArray,null,callback);
		}; 
	
	Plan.prototype.getSystemTags = function(){
		return ['Plan'];
	};

    Plan["change:name"] = function (model, newName) {
		var previousName = model._previousAttributes ? model._previousAttributes.name:"";
		if(previousName != newName) {
			this.handlePlanNameChange(model, newName);
		}
    }
	utils.customExtendStaticMethods(Plan,planMixinClass);
	Plan.abstract = false;
	//#endCustomMethods
	path.Plan = Plan;
	//return Plan;
	export {Plan};
//});
