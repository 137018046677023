import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import {EObject} from '../cmof/EObject'
import {BeepPackage} from '../beeppackage/BeepPackage'
import {PlanScenarioMixin} from './PlanScenarioMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'

var path = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
var PlanScenario = BeepPackage.extend(utils.customExtends({
        relations:PlanScenarioMixin.getMixinRelations(),
        initialize: function(attributes, options) {
            BeepPackage.prototype.initialize.apply(this, arguments);
            if(attributes.optimizationType){
				var sil = options ? options.silent:false;
				this.set('optimizationType',OptimizationType[attributes.optimizationType instanceof Object ? attributes.optimizationType.name : attributes.optimizationType],{silent:sil});
			}
        }
    }
    , new PlanScenarioMixin()
));
//#startCustomMethods			
/*PlanScenario["add:step"] = function (model,newName) {
    DataManager.getDataManager().addToScenarioChange();
}
PlanScenario["remove:step"] = function (model,newName) {
    DataManager.getDataManager().addToScenarioChange();
}*/
PlanScenario.prototype.getSystemTags = function(){
    return ['PlanScenario Package', 'Scenario'];
};
//#endCustomMethods
PlanScenario.getName = function(){
    return "PlanScenario";
};

PlanScenario.prototype.getCalculationChangeType = function(operationType){
    return 1;
};

PlanScenario.prototype.getCalculationChangeArtifact = function(operationType){
    return this.id;
}
PlanScenario.abstract = false;
PlanScenario.supported = true;
path.PlanScenario = PlanScenario;
export {PlanScenario};
