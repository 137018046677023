import * as $ from 'jquery'
import * as _ from 'underscore'
import * as Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { EcoMapDesignerViewModel } from './EcoMapDesignerViewModel'
import { EcoMapDetails2ViewModel } from './EcoMapDetails2ViewModel'

//define(["require", "jquery", "underscore", "backbone", "knockout", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/vdml/BusinessModel", "appbo/vdml/Participant", "appbo/vdml/ValueElement", "appbo/vdml/ValueStreamMapDiagram", "appbo/vdml/ValueStreamMapDiagramMixin", "appbo/vdml/ValueDeliveryModelMixin", "knockback", "ecomodeler", "econavigatedviewer", "appviews/valueStreamMap/views/designer/ValueStreamMapDetailsViewModel", "appviews/help/views/properties/BMGuidanceViewModel", "appbo/beeppackage/CodeContainer", "appviews/strategyMap/views/designer/StrategyMapValuesChart", "appbo/ecomap/EcoMapMixin"],
    //function (require, $, _, Backbone, ko, bootbox, DataManager, global, BusinessModel, Participant, ValueElement, ValueStreamMapDiagram, ValueStreamMapDiagramMixin, ValueDeliveryModelMixin, kb, EcoModeler, EcoNavigatedViewer, ValueStreamMapDetailsViewModel, BMGuidanceViewModel, CodeContainer, StrategyMapValuesChart, EcoMapMixin) {

var path = DataManager.getDataManager().buildAppNsPath("ecoMap.views.designer", global.version);
export class EcoMapDesigner2ViewModel extends EcoMapDesignerViewModel {
            
    loadEcoMapDetails = function(){
        var self = this;
        self.detailsViewInstance = EcoMapDetails2ViewModel.getInstance(this.model);
        if (!ko.components.isRegistered('EcoMapDetails')) {
            ko.components.register("EcoMapDetails", {
                viewModel: { instance: self.detailsViewInstance },
                template: window.utils.getHtmlPage("EcoMapDetails2Template"),
                synchronous: true
            });
        }
    }
            
    init(model, options){
        super.init(model,options);
        var self = this;
        var dataManager = DataManager.getDataManager();
        this.selectedYear = ko.observable();
        this.selectedPeriod = ko.observable();
        var currentPlan = dataManager.get('currentPlan');
        this.periodKind = currentPlan.get("periodKind");
        var currentAlternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: dataManager.get('viewAlternative')})
        var scenarioId = currentPlan.get("defaultScenario");
        var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
        this.dataSets = currentAlternative.getPhaseAlternativeSteps(scenario);
        var defaultPeriodList = scenario.fillDefaultPeriods(this.dataSets,self.periodKind);
        self.defaultPeriod = defaultPeriodList?.period
        self.defaultYear = defaultPeriodList?.year;

        var defaultExecutionScenaroId = scenario.get("defaultExecutionScenario");
        self.defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
        //self.loadDatasets(self.defaultPeriod, self.defaultYear);

    }

    static getInstance = function (model, options) {
        var view = new EcoMapDesigner2ViewModel(model, options);
        view.init(model, options);
        return view;

    };
}
path.EcoMapDesigner2ViewModel = EcoMapDesigner2ViewModel;
