
import { Plan2ViewModel } from './version1/views/transformation/views/properties/Plan2ViewModel';
import { ValueDetails2ViewModel } from './version1/views/vdml/views/properties/ValueDetails2ViewModel';
import { BusinessModel2ViewModel } from './version1/views/vdml/views/properties/BusinessModel2ViewModel';
import { ValueLibrary2ViewModel } from './version1/views/valueLibrary/properties/ValueLibrary2ViewModel';
import { PlanDetails2ViewModel } from './version1/views/transformation/views/properties/PlanDetails2ViewModel';
import { Values2ViewModel } from './version1/views/transformation/views/properties/Values2ViewModel.js';
import { BMCube2ViewModel } from './version1/views/vdml/views/bmcube/BMCube2ViewModel';
import ValueLibrary2Template from './version1/views/valueLibrary/properties/ValueLibrary2Template.html';
import PlanDetails2Template from './version1/views/transformation/views/properties/PlanDetails2Template.html';
import BMDetails2Template from './version1/views/vdml/views/properties/BMDetails2Template.html';
import ValueDetailsProperties2Template from './version1/views/vdml/views/properties/ValueDetailsProperties2Template.html';
import PlanValueDetails2Template from './version1/views/transformation/views/properties/PlanValueDetails2Template.html';
import AdvancedPlanDetails2Template from './version1/views/transformation/views/properties/AdvancedPlanDetails2Template.html';
import { AdvancedPlanDetails2ViewModel } from './version1/views/transformation/views/properties/AdvancedPlanDetails2ViewModel.js';
import Values2Template from './version1/views/transformation/views/properties/Values2Template.html';
import { PlanValues2Chart } from './version1/views/transformation/views/chart/PlanValues2Chart.js';
import PlanProperties2Template from './version1/views/transformation/views/properties/PlanProperties2Template.html';
import PlanValidation2Template from './version1/views/transformation/views/correction/PlanValidation2Template.html';
import StrategyMapDetails2Template from './version1/views/strategyMap/views/designer/StrategyMapDetails2Template.html';
import ValueStreamMapDetails2Template from './version1/views/valueStreamMap/views/designer/ValueStreamMapDetails2Template.html';
import EcoMapDetails2Template from './version1/views/ecoMap/views/designer/EcoMapDetails2Template.html';
import CanvasDetails2Template from './version1/views/canvas/views/designer/CanvasDetails2Template.html';
import StrategyMapTargetTemplate from './version1/views/strategyMap/views/designer/StrategyMapTargetTemplate.html';
import Dashboard2Template from './version1/views/dashboard/views/chart/Dashboard2Template.html';
import DashboardValuesTemplate from './version1/views/dashboard/views/chart/DashboardValuesTemplate.html';
import CalculationSummaryTemplate from './version1/views/scenario/views/properties/CalculationSummaryTemplate.html';
import OptimizationTemplate from './version1/views/scenario/views/properties/OptimizationTemplate.html';
import CanvasTargetDetails2Template from './version1/views/canvas/views/designer/CanvasTargetDetails2Template.html';
import TargetValueTemplate from './version1/views/strategyMap/views/designer/TargetValueTemplate.html';
import CreateChart2Template from './version1/views/dashboard/views/chart/CreateChart2Template.html';
import SelectScenarioTemplate from './version1/views/dashboard/views/chart/SelectScenarioTemplate.html';
import DashboardValuesChart2Template from './version1/views/dashboard/views/chart/DashboardValuesChart2Template.html';
import ValueAggregationProperties2Template from  './version1/views/vdml/views/properties/ValueAggregationProperties2Template.html'
import ProjectionProfileJsonPropertiesTemplate from './version1/views/valueLibrary/properties/ProjectionProfileJsonPropertiesTemplate.html';
import ProjectionsTemplate from  './version1/views/transformation/views/properties/ProjectionsTemplate.html'
import {PlanValidation2ViewModel} from './version1/views/transformation/views/correction/PlanValidation2ViewModel.js';
import DynamicClass from './DynamicClass.js';
import { PhaseDetails2ViewModel } from './version1/views/transformation/views/properties/PhaseDetails2ViewModel.js';
import { StrategyMapDesigner2ViewModel } from './version1/views/strategyMap/views/designer/StrategyMapDesigner2ViewModel.js';
import { StrategyMapValuesChart } from './version1/views/strategyMap/views/designer/StrategyMapValuesChart.js';
import { StrategyMapValues2Chart } from './version1/views/strategyMap/views/designer/StrategyMapValues2Chart.js';
import { StrategyMapDetails2ViewModel } from './version1/views/strategyMap/views/designer/StrategyMapDetails2ViewModel.js';
import { StrategyMapTargetViewModel } from './version1/views/strategyMap/views/designer/StrategyMapTargetViewModel.js';
import { ValueStreamMapDesigner2ViewModel } from './version1/views/valueStreamMap/views/designer/ValueStreamMapDesigner2ViewModel.js';
import { ValueStreamMapDetails2ViewModel } from './version1/views/valueStreamMap/views/designer/ValueStreamMapDetails2ViewModel.js';
import { EcoMapDesigner2ViewModel } from './version1/views/ecoMap/views/designer/EcoMapDesigner2ViewModel.js';
import { EcoMapDetails2ViewModel } from './version1/views/ecoMap/views/designer/EcoMapDetails2ViewModel.js';
import { ShapePropertiesViewModel } from './version1/views/ecoMap/views/designer/ShapePropertiesViewModel.js';
import { ShapeProperties2ViewModel } from './version1/views/ecoMap/views/designer/ShapeProperties2ViewModel.js';
import { TargetValueViewModel } from './version1/views/strategyMap/views/designer/TargetValueViewModel.js';
import { CanvasDesigner2ViewModel } from './version1/views/canvas/views/designer/CanvasDesigner2ViewModel.js';
import { CanvasDetails2ViewModel } from './version1/views/canvas/views/designer/CanvasDetails2ViewModel.js';
import { Dashboard2ViewModel } from './version1/views/dashboard/views/chart/Dashboard2ViewModel.js';
import { DashboardValuesViewModel } from './version1/views/dashboard/views/chart/DashboardValuesViewModel.js';
import { DashboardDetails2ViewModel } from './version1/views/dashboard/views/chart/DashboardDetails2ViewModel.js';
import { CalculationSummaryViewModel } from './version1/views/scenario/views/properties/CalculationSummaryViewModel.js';
import { OptimizationViewModel } from './version1/views/scenario/views/properties/OptimizationViewModel.js';
import { CanvasTargetDetails2ViewModel } from './version1/views/canvas/views/designer/CanvasTargetDetails2ViewModel.js';
import { CreateChart2ViewModel } from './version1/views/dashboard/views/chart/CreateChart2ViewModel.js';
import { SelectScenarioViewModel } from './version1/views/dashboard/views/chart/SelectScenarioViewModel.js';
import { SelectChartValues2ViewModel } from './version1/views/dashboard/views/chart/SelectChartValues2ViewModel.js';
import { ValueAggregation2ViewModel } from './version1/views/vdml/views/properties/ValueAggregation2ViewModel.js';
import { DashboardValuesChart2ViewModel } from './version1/views/dashboard/views/chart/DashboardValuesChart2ViewModel.js';
import { ProjectionProfileJsonViewModel } from './version1/views/valueLibrary/properties/ProjectionProfileJsonViewModel.js';
import { ProjectionsViewModel } from './version1/views/transformation/views/properties/ProjectionsViewModel.js';



const htmlList = {
    ValueLibrary2Template,
    AdvancedPlanDetails2Template,
    PlanDetails2Template,
    Values2Template,
    BMDetails2Template,
    PlanProperties2Template,
    ValueDetailsProperties2Template,
    PlanValueDetails2Template,
    PlanValidation2Template,
    StrategyMapDetails2Template,
    ValueStreamMapDetails2Template,
    EcoMapDetails2Template,
    CanvasDetails2Template,
    StrategyMapTargetTemplate,
    Dashboard2Template,
    DashboardValuesTemplate,
    CalculationSummaryTemplate,
    OptimizationTemplate,
    CanvasTargetDetails2Template,
    TargetValueTemplate,
    CreateChart2Template,
    SelectScenarioTemplate,
    DashboardValuesChart2Template,
    ValueAggregationProperties2Template,
    ProjectionProfileJsonPropertiesTemplate,
    ProjectionsTemplate
}

// Use ES6 Object Literal Property Value Shorthand to maintain a map
// where the keys share the same names as the classes themselves
const classes = {
    
    Plan2ViewModel,
    PlanValues2Chart,
   
    ValueDetails2ViewModel,
   
    BusinessModel2ViewModel,
   
    ValueLibrary2ViewModel,
    
    PlanDetails2ViewModel,
    
    BMCube2ViewModel,

    AdvancedPlanDetails2ViewModel,

    Values2ViewModel,

    PlanValidation2ViewModel,

    PhaseDetails2ViewModel,

    StrategyMapDesigner2ViewModel,

    StrategyMapValuesChart,

    StrategyMapValues2Chart,

    StrategyMapDetails2ViewModel,

    StrategyMapTargetViewModel,

    ValueStreamMapDesigner2ViewModel,

    ValueStreamMapDetails2ViewModel,

    EcoMapDesigner2ViewModel,

    EcoMapDetails2ViewModel,

    CanvasDesigner2ViewModel,

    ShapePropertiesViewModel,

    ShapeProperties2ViewModel,

    TargetValueViewModel,

    CanvasDetails2ViewModel,

    Dashboard2ViewModel,

    DashboardValuesViewModel,

    DashboardDetails2ViewModel,

    CalculationSummaryViewModel,

    OptimizationViewModel,

    CanvasTargetDetails2ViewModel,

    CreateChart2ViewModel,

    SelectScenarioViewModel,

    SelectChartValues2ViewModel,

    DashboardValuesChart2ViewModel,

    ValueAggregation2ViewModel,

    ProjectionProfileJsonViewModel,
    
    ProjectionsViewModel
    
};

class DynamicClass2  extends DynamicClass{
    constructor (className, opts) {
        return new classes[className](opts);
    }
    static getView(className){
        var view = super.getView(className)
        if(view){
            return view
        }
        return classes[className];
    }
	static getHtml(fileName){
        var htmlPage = super.getHtml(fileName);
        if(htmlPage) {
            return htmlPage;
        }
        return htmlList[fileName];
    }
}

export default DynamicClass2;