import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as Highcharts from 'highcharts';
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import { DashboardDetailsViewModel } from './DashboardDetailsViewModel'

/*define(["require", "jquery", "enhsplitter", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "app/global", "typeahead","appbo/dashboard/DashboardMixin", "appbo/vdml/BusinessModelMixin", "appbo/dashboard/Dashboard", "appbo/dashboard/Presentation", "appbo/dashboard/Analytics","appbo/transformation/PackageReference","appcommon/com/vbee/filesystem/PackageReference", "appcommon/com/vbee/utils/HighChartThemes", "jquery.DataTable", "dataTable.PageResize", "dataTable.ColReorderWithResize", "highcharts", "highcharts-more"],
function (require, $, enhsplitter, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, global, typeahead, DashboardMixin, BusinessModelMixin, Dashboard, Presentation, Analytics,PlanPackageReference,PackageReference, HighChartThemes)
{*/
var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart", global.version);
export class DashboardDetails2ViewModel extends DashboardDetailsViewModel {
	
	init(model, options){
		var self = this;
		super.init(model,options);
		this.isVMPOne = ko.observable(false);
	}
	
	displayTable(){
		var self = this;
	}
	refreshTable(callback){
		var self = this;
	}
	static getInstance = function(model,options){
         var view = new DashboardDetails2ViewModel(model, options);
         view.init(model, options);
         return view;
	};
}
path.DashboardDetails2ViewModel = DashboardDetails2ViewModel;