import { DataManager } from '../../../../../com/vbee/data/DataManager'
import {StrategyMapDesignerViewModel} from './StrategyMapDesignerViewModel'
import {StrategyMapDetails2ViewModel} from './StrategyMapDetails2ViewModel'
import {StrategyMapDiagram2Mixin} from '../../../../../version1/bo/vdml/StrategyMapDiagram2Mixin'
import * as ko from 'knockout'
import * as kb from "knockback";
import * as _ from 'underscore'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {ValueStream} from '../../../../../version1/bo/vdml/ValueStream'
import {StrategyMapValues2Chart} from './StrategyMapValues2Chart'

var path = DataManager.getDataManager().buildAppNsPath("strategyMap.views.properties", global.version);

export class StrategyMapDesigner2ViewModel extends StrategyMapDesignerViewModel{
    createVPC(vp, oldVpc, valueName) {
        var self = this;
        var dataManager = DataManager.getDataManager();
        var newVPCIdSuffix = oldVpc ? window.utils.getSuffix(oldVpc.id) : null;
        var vpc = vp.createValuePropositionComponent(valueName, valueName, false, newVPCIdSuffix);
        vpc.primeValueMeasurement(dataManager.get("currentYear"), dataManager.get("currentPeriod"), null, null, null, [], self.currentAlternative);
        //var vdm = vp.getNestedParent();
        //vdm.createValueDetails(vpc, oldVpc);
        return vpc;
    }
    createValueAdd(bm, activity, activityValueName, bmActivityModal, oldValModel) {
        var self = this;
        var dataManager = DataManager.getDataManager();
        var activityValue = activity.createWhatValues(dataManager.get("currentYear"), dataManager.get("currentPeriod"), self.currentAlternative, activityValueName, null, null, null, [], bmActivityModal, oldValModel);
        bmActivityModal.get('activityValue').add(activityValue);
        return activityValue;
    }
    showProperties = function(data, ev) {
        var self = this;
        if (self.editMode) {
            var id = htmlEscape(window.guidGenerator());
            var elementRegistry = self.modeler.get('elementRegistry');
            var options = {
                businessObject: data.businessObject,
                element: elementRegistry.get(data.businessObject.id),
                width: '660px'
            };
            window.getAndCreateModalDialog(self, id, StrategyMapDiagram2Mixin, self.model, "ShapeProperties", null, options);
        }
    }
    init(model, options){
        super.init(model,options);
        var self = this;
        var dataManager = DataManager.getDataManager();
        this.selectedYear = ko.observable();
        this.selectedPeriod = ko.observable();
        var currentPlan = dataManager.get('currentPlan');
        this.periodKind = currentPlan.get("periodKind");
        this.currentAlternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: dataManager.get('viewAlternative')})
        var scenarioId = currentPlan.get("defaultScenario");
        var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
        this.dataSets = self.currentAlternative.getPhaseAlternativeSteps(scenario);
        var defaultPeriodList = scenario.fillDefaultPeriods(this.dataSets,self.periodKind);
        self.defaultPeriod = defaultPeriodList?.period
        self.defaultYear = defaultPeriodList?.year;

        var defaultExecutionScenaroId = scenario.get("defaultExecutionScenario");
        self.defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
        //self.loadDatasets(self.defaultPeriod, self.defaultYear);

        
        self.objectiveLabel(DataManager.getDataManager().get('localeManager').get('Targets'));
        self.loadStrategyValuesChart();
        self.labels["ExportCSV"] = kb.observable(
            dataManager.get("localeManager"),
            "ExportCSV"
        );
        self.labels["Yearly"] = kb.observable(
            dataManager.get("localeManager"),
            "Yearly"
        );
        self.labels["Quarterly"] = kb.observable(
            dataManager.get("localeManager"),
            "Quarterly"
        );
        self.labels["Monthly"] = kb.observable(
            dataManager.get("localeManager"),
            "Monthly"
        );
        self.labels["4-Weekly"] = kb.observable(
            dataManager.get("localeManager"),
            "4-Weekly"
        );
        self.labels["Weekly"] = kb.observable(
            dataManager.get("localeManager"),
            "Weekly"
        );
        self.labels["PresentationMode"] = kb.observable(
            dataManager.get("localeManager"),
            "PresentationMode"
        );
        self.labels["Overview"] = kb.observable(
            dataManager.get("localeManager"),
            "Overview"
        );
        self.labels["Scenarios"] = kb.observable(
            dataManager.get("localeManager"),
            "Scenarios"
        );
        self.labels["SelectValues"] = kb.observable(
            dataManager.get("localeManager"),
            "SelectValues"
        );
        self.labels["ValueDefinition(s)"] = kb.observable(
            dataManager.get("localeManager"),
            "ValueDefinition(s)"
        );
        self.labels["ImportData"] = kb.observable(
            dataManager.get("localeManager"),
            "ImportData"
        );
        self.labels["addAnother"] = kb.observable(
            dataManager.get("localeManager"),
            "addAnother"
        );
        self.labels["value"] = kb.observable(
            dataManager.get("localeManager"),
            "value"
        );
        this.loadScenario = function(callback){
            var dataManager = DataManager.getDataManager();
            var periodKind = dataManager.get('currentPlan').get("periodKind");
            var scenarioId = dataManager.get("currentPlan").get("defaultScenario");
            var planScenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
            planScenario.getDatasetAsync(dataManager.get("currentYear"), dataManager.get("currentPeriod"), periodKind, null, function(periodDataset, calcPeriodDataset) {
                callback();
            });
        }
    }

    clickedTargetTab = function() {
        var self = this;
        self.strategyMapTargetViewModel.instanceCreation();
    }

    loadStrategyValuesChart = function(){
        var self = this;
        var StrategyMapValues2Chart = window.utils.getView('StrategyMapValues2Chart');
        self.chartViewInstance = StrategyMapValues2Chart.getInstance(this.model);
        if (!ko.components.isRegistered('StrategyValuesChart')) {
            ko.components.register("StrategyValuesChart", {
                viewModel: { instance: self.chartViewInstance },
                template: window.utils.getHtmlPage("StrategyMapValuesChartTemplate"),
                synchronous: true
            });
        }
    }
    loadStrategyMapDetails = function(){
        var self = this;
        self.detailsViewInstance = StrategyMapDetails2ViewModel.getInstance(this.model);
        if (!ko.components.isRegistered('StrategyMapDetails')) {
            ko.components.register("StrategyMapDetails", {
                viewModel: { instance: self.detailsViewInstance },
                template: window.utils.getHtmlPage("StrategyMapDetails2Template"),
                synchronous: true
            });
        }
    }
    loadStrategyObjectives = function(){
        var self = this;
        var targetViewModel = window.utils.getView('StrategyMapTargetViewModel')
        self.strategyMapTargetViewModel = targetViewModel.getInstance(this.model);
        if (!ko.components.isRegistered("StrategyMapTargetTemplate")) {
            ko.components.register("StrategyMapTargetTemplate", {
                viewModel : { instance: self.strategyMapTargetViewModel },
                template: window.utils.getHtmlPage("StrategyMapTargetTemplate"),
                synchronous : true
            })
        }
    }

    static getInstance(model, options) {
        var view = new StrategyMapDesigner2ViewModel();
        view.init(model, options);
        return view;
    };
}


path.StrategyMapDesigner2ViewModel = StrategyMapDesigner2ViewModel;