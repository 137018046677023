import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as Highcharts from 'highcharts';
import * as async from 'async'
//import {select, selectAll} from 'd3-selection';
//import {scaleOrdinal, scaleLinear} from 'd3-scale';
import {DateFormat} from '../../../../../com/vbee/utils/DateFormat'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import { ValueType } from '../../../../bo/vdml/ValueType'
import more from 'highcharts/highcharts-more';
more(Highcharts);

/*define(["require", "jquery", "jstree", "underscore",  "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async","appcommon/com/vbee/data/DataManager", "appbo/beeppackage/CodeContainer", "app/global", "appbo/preference/UserPreferences", "appbo/preference/Preference", "d3","RadarChart", "appbo/vdml/ValueElementMixin", "moment","appcommon/com/vbee/utils/DateFormat","summernote", "appbo/dashboard/DashboardMixin", "highcharts-common","d3-legend", "bootstrap-multiselect", "jquery.DataTable", "dataTable.PageResize", "dataTable.ColReorderWithResize",  "tick-positioner"],
function (require, $, jstree, _,  Backbone, ko, koMapping, kb, bootbox, async, DataManager, CodeContainer, global, UserPreferences, Preference, d3,RadarChart,ValueElementMixin,moment, DateFormat, summernote, DashboardMixin, Highcharts
) {*/
    
    var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart",global.version);

    export class DashboardValuesChart2ViewModel {
        constructor(model,options){
			this.init(model,options);
		}
        refreshChart(){
			var self = this;
        	self.getValueMeasurements(function(){
				self.plotAccordingToChoices(true);
				//window.vdmModelView.setResizeHeight();
				//window.utils.stopSpinner('dashboardSpinner');
		    });
        };
    	resizeChartWithGridResize(resize){
			var self = this;
			var totalHeight = $('#view'+ self.encodeId).parent().height();
			var detailsLegendHeight = $('#view'+ self.encodeId + ' legend').outerHeight(true);
			var legendHeight = $('#legend'+ self.encodeId).is(':visible')? $('#legend'+ self.encodeId).outerHeight(true) : 0;
			var altLegendHeight = $('#altLegend'+ self.encodeId).is(':visible')? $('#altLegend'+ self.encodeId).outerHeight(true) : 0;
			var placeHolderHeight = totalHeight - detailsLegendHeight - legendHeight - altLegendHeight;
			$('#js-canvas'+self.encodeId).outerHeight(placeHolderHeight);
    	};
    	initializePresentation(refresh){
			var self = this;
    		if(!self.model){
    			return;
    		}
			if(refresh && self.plot){
				self.plot.setSize(null,null);
				return;
			}
	        var plan = DataManager.getDataManager().get('currentPlan');
	        self.preference = JSON.parse(self.model.get('chartConfig'));
			if(!self.preference){
				return;
			}
	        var selectedChartType = self.selectedChartType();
	        self.chartInitialized = true;
			self.savedPreference = false;
			var xAxisData;
			if(self.preference['annotations']){
				var annotationsText = self.preference['annotations'];
				if(annotationsText && annotationsText.trim() !== ''){
					$('#annotations'+self.encodeId).val(annotationsText);
					//self.annotationNonEmpty(true);
				}			        	
			}
			$("#chartConfig" + self.encodeId).hide();
			xAxisData = self.getXAxisData(refresh);
			var yaxisNode = $("#yaxis" + self.encodeId);
			self.showBars = selectedChartType === "Bars" || selectedChartType === "Stacked bars" || selectedChartType === "Columns" || selectedChartType === "Stacked columns"? true : false;
			self.maxUnitsPermitted = selectedChartType === "Stacked bars" || selectedChartType === "Stacked columns"? 1: 2;
			self.phaseIdsChronologicalList = [];
			var phase = plan.get('phase').findWhere({previousPhase:null});
			while(phase){
				self.phaseIdsChronologicalList.push(phase.id);
				phase = phase.get('nextPhase');
			}
			this.alts = self.getAlternatives();
    		if(selectedChartType === "Table"){
            	$('#groupByRow' + self.encodeId).hide();
  				$('#alignScales' + self.encodeId).hide();
            	$('#units' + self.encodeId).hide();
				$('#showValues' +self.encodeId).hide();
            	$("#groupBy" + self.encodeId + " option[value='Time']").removeAttr('disabled');
                //$('#printChart'+self.encodeId).hide();
            }else if(selectedChartType === "Radar" || selectedChartType === "Spiderweb" || selectedChartType === "Polar"){
            	$("#groupBy" + self.encodeId + " option[value='Time']").attr('disabled','disabled');
  				$('#groupByRow' + self.encodeId).show();
  				$('#alignScales' + self.encodeId).hide();
  				$('#units' + self.encodeId).hide();
				$('#showValues' +self.encodeId).show();
            }else{
            	$('#groupByRow' + self.encodeId).show();
  				$('#alignScales' + self.encodeId).show();
            	$("#groupBy" + self.encodeId + " option[value='Time']").removeAttr('disabled');
  				$('#units' + self.encodeId).show();
				$('#showValues' +self.encodeId).show();
  			}
			if(refresh){
				this.values = [];
			}
			showValues(self.preference,!refresh);
			
			function showValues(preference,initializeMultiselect){
				self.getValueMeasurements(function(){
					if(!self.preference){
						return;
					}
					if(initializeMultiselect){
						$('#yaxis' + self.encodeId).multiselect({
			            	onDropdownShow:function(){
								$('.multiselect-container').css({ 'left': "auto", 'right': "0"  });
			            	},
							optionClass: function () {
								return 'chartLiWidth';
							},
							numberDisplayed : 0,
			            	//includeSelectAllOption: true,
							onDropdownShown : function(){
								if(self.parentView){
									window.vdmModelView.setResizeHeight();
								}
							},
							onDropdownHidden : function(){
								if(self.parentView){
									window.vdmModelView.setResizeHeight();
								}
							},
			            	onChange: function(option, checked) {
			            		var checkedAtr = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:checked");
			            		var uncheckedAtr =	$('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:not(:checked)");
			            		if(checkedAtr.length >= self.maxUnitsPermitted){
			            			uncheckedAtr.attr("disabled", true);
			            			uncheckedAtr.parent().attr("disabled", true);
			            			uncheckedAtr.parent().css('cursor',"no-drop");
			            		}else{
			            			uncheckedAtr.attr("disabled", false);
			            			uncheckedAtr.parent().attr("disabled", false);
			            			uncheckedAtr.parent().css('cursor',"pointer");
			            		}
			            		var checkedArr = [];
			            		for(var i=0;i<checkedAtr.length;i++){
			            			checkedArr[i]=checkedAtr[i].value;
			            		}
			            		self.preference.yAxisSelected = checkedArr;
			            		self.model.set('chartConfig', JSON.stringify(self.preference));
								self.getValueMeasurements(function(){
									self.plotAccordingToChoices(true);
								});
							}
			        	});
					}
					var i = 0;						
					var selectedUnits = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:checked");
					var oldSelectedUnits = [];
					_.each(selectedUnits,function(valueOption){
						oldSelectedUnits.push({value:valueOption.value});
					});
					yaxisNode.empty();
					var count =0;
	                $.each(self.datasets, function(key, val) {
						for(var i=0;i<val.data.length;i++){
							if(selectedChartType !== "Table"){
								if(!self.groupByScenario && self.showBars && isNaN(val.data[i][0]) && val.data[i][0]?.indexOf('Ph') > -1){
									var replacedString = val.data[i][0].replace('Ph','Alt');
									val.data[i][0] = replacedString;
								}
							}
						}
						if(val.unit) {
						    var multiselectNode = $('#yaxis' + self.encodeId);
						    var unitString = window.utils.restoreEscapeCharacters(val.unit);
							var unitOptionGroupNode = multiselectNode.children('option[label="' + unitString + '"]');
							if(unitOptionGroupNode.length === 0){
								multiselectNode.append('<option' + ' label="' +  unitString +'">'+ unitString + '</option>');
								unitOptionGroupNode = multiselectNode.children('option[label="' + unitString + '"]');
							}
							count++;
						}
					});
					$('#yaxis' + self.encodeId).multiselect('rebuild');
					if(oldSelectedUnits.length>0){
						for(i=0;i<oldSelectedUnits.length;i++){
							var label = window.utils.restoreEscapeCharacters(oldSelectedUnits[i].value);
						    if ($('#yaxis' + self.encodeId)[0].children) {
						        $('#yaxis' + self.encodeId).multiselect('select', label);
							}
						}
						// if they still remain unchecked because the oldunits and units available are completely different sets, check
						// if they are unchecked and check them manually
						var checked = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:checked");
				        if(checked.length === 0 && !self.preference.yAxisSelected){
				        	var defaultUnitsArray = [];
							var units = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input");
							if(units.length>0){
								defaultUnitsArray.push(units[0].value);
							}
							if(units.length>1 && self.maxUnitsPermitted !== 1){
								defaultUnitsArray.push(units[1].value);
							}
							self.preference.yAxisSelected = defaultUnitsArray;
				        }
					}else if(!self.preference.yAxisSelected){
						var defaultUnitsArray = [];
						var units = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input");
						if(units.length>0){
							defaultUnitsArray.push(units[0].value);
						}
						if(units.length>1 && self.maxUnitsPermitted !== 1){
							defaultUnitsArray.push(units[1].value);
						}
						self.preference.yAxisSelected = defaultUnitsArray;
					}
					if(self.preference.yAxisSelected){
						for(var i=0;i<self.preference.yAxisSelected.length;i++){
							label = window.utils.restoreEscapeCharacters(self.preference.yAxisSelected[i]);
						    if ($('#yaxis' + self.encodeId)[0] && $('#yaxis' + self.encodeId)[0].children) {
						        $('#yaxis' + self.encodeId).multiselect('select', label);
							}
						}
					}
					var checkedAtr = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:checked");
			        var uncheckedAtr =	$('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:not(:checked)");
	        		if(checkedAtr.length >= self.maxUnitsPermitted){
	        			uncheckedAtr.attr("disabled", true);
	        			uncheckedAtr.parent().attr("disabled", true);
	        			uncheckedAtr.parent().css('cursor',"no-drop");
	        		}else{
	        			uncheckedAtr.attr("disabled", false);
	        			uncheckedAtr.parent().attr("disabled", false);
	        			uncheckedAtr.parent().css('cursor',"pointer");
			        }
					self.plotAccordingToChoices(true);
				});
			}
			$(".multiselect").parent().css('margin-left','4%');
			$('.multiselect').css({'font-size':'14px','text-align':'left', 'width':'143px','height':'30px','border-radius':'4px','cursor':'pointer','line-height':'8px','padding-right': '5px'});
			$('.multiselect').find('.caret').css({ 'float': 'right', 'border-right': '3px solid transparent', 'border-left': '3px solid transparent', 'border-top': '7px dashed'});
			$('#dasboChartDropdown'+self.encodeId).unbind('click');
			$('#dasboChartDropdown'+self.encodeId).on('click', function(){
		    	event.stopPropagation();
	       		$("#dasboChartDropdownMenu"+self.encodeId).toggle();
				$("#presWrapper"+self.encodeId).css('overflow','visible')
	       	});
			$('#splitPanels').unbind('click');
			$('#splitPanels').on('click', function (e) {
			    if (!e.target.closest('.dropdown-menu-right') && !(e.target.id ==='dasboChartDropdown'+self.encodeId) && !e.target.closest('.ui-datepicker-header') && !e.target.closest('.ui-datepicker')){
			        $(".dropdown-menu-right").hide();
			    }
			});
		};
  
	dispose(){
		var self = this;
		if(!this.model){
			return;
		}
		function cleanViewData(){
			if(self.values){
				self.values.length = 0;
			} 
			self.datasets = null;
			if(self.xAxisSelected){
				self.xAxisSelected.length = 0;	
			}
			window.cleanViewModel(self);
		}
        if (self.dataTable) {
            self.dataTable.destroy(true);
        }
        $.each(Highcharts.charts, function(i, chart){
        	if(chart != undefined){
        		chart.destroy();
        	}
        });
        Highcharts.charts.length = 0;
		$("#xaxis"+self.encodeId).jstree('destroy');
		$(window,".resize").unbind('resize');
		self.model = null;
        cleanViewData();
	};
	toggleFullScreen(data, event){
		var self = this;
		var parentGrid = $('#view'+data.encodeId).parent();
		if (document.fullscreenElement) {
			document.exitFullscreen();
		}else{
			parentGrid.get(0).requestFullscreen();
		}
	};
	viewAnnotation(){        
        var self = this;
        var config = JSON.parse(self.model.get('chartConfig'));
        var annotation = config['annotations'];
    	var dataGuid = DataManager.getDataManager().guidGenerator();
    	var htmlContent = "<div id='summernote"+self.encodeId+"'+></div>";
    	const box = bootbox.dialog({
            title: '  &nbsp;Annotation',
            message: htmlContent,
			backdrop:false,
            buttons: {
                main: {
                    label: "Close",
                    className: "btn btn-default pull-left",
                },
                edit: {
                    label: "Edit",
                    className: "annotationEdit btn btn-default",
                    callback: function(){
                    	initializeSummernote(false);
                    	$('.annotationComplete').show();
                    	$('.annotationEdit').hide();
                    	return false;
                    }
                },
                success: {
                    label: "Complete",
                    className: "annotationComplete btn btn-default",
                    callback: function(){
                    	var summernote = $('#summernote'+self.encodeId);
                    	var contentData = summernote.summernote('code');	
						var contentJQuery = $(contentData);
						contentData = "";
						var canvasImgs = contentJQuery.find('img').each(function () {
						    if (this.getAttribute('diagramId')) {
						        this.src = "";
						    }
						});
						contentJQuery.each(function () {
						    contentData = contentData + this.outerHTML;
						});
						if(contentJQuery.length == 0){
							contentData = summernote.summernote('code');
						}
                    	config['annotations'] = contentData;
                    	self.model.set('chartConfig', JSON.stringify(config));
                    }
                }
            }
        });
		if(document.fullscreenElement){
			box.appendTo(`#${document.fullscreenElement.id}`)
		  }else{
			box.show();
		  } 
        function initializeSummernote(airMode){
        	var summernote = $('#summernote'+self.encodeId);
        	summernote.summernote('destroy');
	    	summernote.summernote(
			{
				airMode: airMode,
				dialogsInBody: true,
			  	minHeight: null,             // set minimum height of editor
			  	maxHeight: null,             // set maximum height of editor
			  	focus: true ,
			  	disableResizeEditor: true, //disable resize 
			  	toolbar: [
		  		    ['style', ['bold', 'italic', 'underline', 'clear']],
		  		    ['font', ['fontname','fontsize','color','strikethrough','superscript', 'subscript']],
		  		    ['para', ['style','ul', 'ol', 'paragraph']],
		  		    ['height', ['height']],
		  		    ['insert', ['picture','link','table','hr']]
		  		]
			});
			summernote.find('.note-statusbar').hide();
			if(airMode){
				summernote.summernote('disable');
			}
            summernote.summernote('code', annotation);
            summernote.summernote("editor.clearHistory", 'editor');
			var modalBody = summernote.parents('.modal-body');
			modalBody.css('max-height', '400px');
			modalBody.css('overflow-y', 'auto');
			$("#summernotecss").attr("href", "js/libs/summernote/dist/summernote.css");
	    }
	    initializeSummernote(true);
	    $('.annotationComplete').hide();
	    if(self.model.getNestedParent().id.startsWith(DataManager.getDataManager().get('viewAlternative'))){
	    	$('.annotationEdit').prop('disabled',false);
    	}
    	else{
    		$('.annotationEdit').prop('disabled',true);
    	}
	};

	findUID(id,treeData){
        var self = this
        if (window.utils.htmlEscape(treeData.id) === id) {
          return treeData.uId;
        }
        if (treeData.subRows) {
            for (let child of treeData.subRows) {
                const result = self.findUID(id, child);
                if (result) {
                return result;
                }
            }
        }
        return null;        
    }

	editPresentation(data,event){
		var self = this;
		let treeData;
		if(window.vdmModelView.model.get("treeStructure")){
			treeData = JSON.parse(window.vdmModelView.model.get("treeStructure"))
		}else {
			treeData = {}
		}
		var uId = self.findUID(window.utils.htmlEscape(self.model.id),treeData)
		var newId = window.utils.htmlEscape(self.model.id) + "editchart";
		var presentationView = _.filter(window.vdmModelView.charts(), function(chartView){return chartView.id() === self.model.id;})[0];
		var addOptions = {'chartModel':self.model,uId:uId,treeData:treeData,viewInstance:presentationView.viewInstance, width:'1000px', 'hideDelete':true};
		var mixinClass = window.vdmModelView.model.getMixinClass();
		window.getAndCreateModalDialog(window.vdmModelView,newId,mixinClass,window.vdmModelView.model,"CreateChart",null,addOptions);
	};

	printChart(){
		var self = this;
		Highcharts.setOptions({
		    chart: {
		        events: {
		            afterPrint: function () {
		            	self.parentView && window.vdmModelView.setResizeHeight(true);
		                
		            }
		        }
		    }
		});
		this.plot.print();
	};
	
	getPreferenceValueToStore() {
	    var self = this;
		var preference = {};
		preference.xAxisSelected = this.xAxisSelected;
		var selectedValues = $("#yaxis" + self.encodeId + " option:selected");
		preference.selectedValues = [];
		_.each(selectedValues,function(valueOption){
			preference.selectedValues.push(valueOption.value);
		});
		preference.selectedChartType = self.selectedChartType();
		return preference;
	};
	
	plotFlotChart(resize) {
		var self = this;
		var selectedOptions = self.preference? self.preference.selectedValueObjs : 0;
		var selectedChartType = self.selectedChartType();
		
		var data = [];
		var yaxis = 1;
		var xAxisObject;
		var placeHolderWidth = $("#placeholder" + self.encodeId).width();
		
		var yaxis1Val;// = yaxis1Node.val();
		var yaxis2Val;// = yaxis2Node.val();
		
		var units = [];
		$.each(self.datasets, function(key, val) {
			units.push(val['unit']);
		});
		$.unique(units);
		units = $("#yaxis" + self.encodeId + " option:selected");
		if(units.length > 0){
		    yaxis1Val =  units[0].label;
		}
		if(units.length > 1 && self.maxUnitsPermitted !== 1){
		    yaxis2Val =  units[1].label;
		}					
		if(yaxis2Val === yaxis1Val){
			yaxis2Val = null;
		}
		
		var values = Object.getOwnPropertyNames(self.datasets);
		var color = 0;
		for(var i=0;i<values.length;i++){
			if (values[i].indexOf('Grade only') != -1 && selectedChartType !== 'Table') {
	            continue;
	        }
			var valueData = self.datasets[values[i]];
			var unit = window.utils.restoreEscapeCharacters(valueData.unit);
			valueData.yAxis = undefined;
			if (selectedChartType !== 'Radar' && selectedChartType !== 'Table' && selectedChartType !== 'Polar' && selectedChartType !== 'Spiderweb') {
			    if (yaxis1Val && yaxis1Val === unit) {
			        //valueData.yAxis = 1;
			        //valueData.color = self.timeBars()? valueData.color : color++;
			        valueData.color = color++;
			        data.push(valueData);
			    }
			    if (yaxis2Val && yaxis2Val === unit) {
			        valueData.yAxis = 1;
			        //valueData.color = self.timeBars()? valueData.color : color++;
			        valueData.color = color++;
			        data.push(valueData);
			    }
			} else {
			    data.push(valueData);
			}
		}
		var chartType;
		    	if(selectedChartType === "Bars" || selectedChartType === "Stacked bars"){
		    		chartType = 'bar';
				}else if(selectedChartType === "Columns" || selectedChartType === "Stacked columns"){
		    		chartType = 'column';
				}else if(selectedChartType === "Curves"){
		    		chartType = 'spline';
				}
		//sorting
		if (data.length > 0) {
		    var placeholder = $("#placeholder" + self.encodeId);
		    data.sort(function(a,b){
		    	if(!a.yaxis){
		    		return 0;
		    	}
		        if(a.yaxis === b.yaxis){
		            if(a.label < b.label){
		                return -1;
		            }
		            if(a.label > b.label){
		                return 1;
		            }
		            return 0;
		        }
		        if(a.yaxis < b.yaxis){
		            return -1;
		        }
		        if(a.yaxis > b.yaxis){
		            return 1;
		        }
		        return 0;
		    });

		    $('#altLegend' + self.encodeId).empty();
		    var altIndexes = Object.getOwnPropertyNames(self.altIndex);
		    _.each(altIndexes,function(key){
		        var altIndexVal = self.altIndex[key];
				var altFound = $.grep(self.preference.selectedAlternativeObjs, function (e) { return e.displayName === key;});
				if (altFound && altFound.length == 0 && self.preference.selectedObjectiveObjs) {
					altFound = $.grep(self.preference.selectedObjectiveObjs, function (e){ return e.displayName === key; });
                }
				if(altFound[0] && altFound[0].label && altFound[0].label!==""){
					if(altFound[0].label && altFound[0].label!==""){
						$('#altLegend' + self.encodeId).append($("<div> "+ altFound[0].label + " : " + key + "</div>"));
					}
				}else{
		         	$('#altLegend' + self.encodeId).hide();
				}
		    });
		    
		    if (selectedChartType === 'Table') {
				$('#showLegend' + self.encodeId).hide();
		        self.displayTable(data, resize);
		    } else {
			    var unit1 = undefined, unit2 = undefined, chartData = [];
			    //var selectedCount;
				var selectedObjCount=[];
				//if (self.groupByPhase) {
					var selectedCount = self.preference.selectedAlternativeObjs;
					if (self.preference.selectedObjectiveObjs) {
						//
						for (var i = 0; i < self.preference.selectedObjectiveObjs.length; i++) {
							selectedObjCount.push(self.preference.selectedObjectiveObjs[i])
                        }
					}
					if (!selectedCount) {
						selectedCount = [];
					}
				$.unique(selectedCount);
		    	_.each(data, function(val, i){
		    		var newDataset = {};
		    		newDataset.name = val.label;
					newDataset.tooltip = {
						headerFormat: '<b>'+val.label+'</b><br>',
						pointFormat: "Value: {point.y:."+val.significantDecimals+"f} "//("+val.unit + ')'
					};
		    		if(val.yAxis === 1){
		    			unit2 = val.unit;
		    			newDataset.yAxis = val.yAxis;
		    		}else{
		    			unit1 = val.unit;
		    		}
		    		var newDataArray = [];
		    		var altOrScenarioArray = [];
					_.each(val.data, function(valData){
		    			var newData = [];
		    			newData.push(valData[0]);
		    			altOrScenarioArray.push(valData[0]);
		    			newData.push(valData[1] === "-" || valData[1] === "####" ? null : valData[1]);
		    			newDataArray.push(newData);
		    		});
					//if (selectedCount && selectedCount.length > 0) {
						var count = selectedCount.length;
						var altName;
		    			if(self.groupByPhase){
			    			while(count > 0){
							    var altLabel = self.changeAltLabel(selectedCount[count-1]);
								if(altLabel){
									altName = altLabel;
								} else if (selectedCount[count-1] && selectedCount[count-1].timeStamp) {
								    altName = selectedCount[count-1].displayName
								} else if (selectedCount[count - 1] && !selectedCount[count - 1].timeStamp) {
									altName = "Alt-" + (count-1);
                                }
								else {    
								    altName = "Alt-"+(count-1);
								}
			    				count--;
							}
							if (selectedObjCount && selectedObjCount.length > 0) {
								var objCount = selectedObjCount.length;
								while (objCount > 0) {
									if (selectedObjCount[objCount - 1] && selectedObjCount[objCount - 1].timeStamp) {
										altName = selectedObjCount[objCount - 1].displayName
									} else {
										altName = "obj-" + (objCount - 1);
									}
									if (altOrScenarioArray.indexOf(altName) === -1) {
										var newData = [];
										newData.push(altName);
										newData.push(null);
										newDataArray.push(newData);
									}
									objCount--;
								}
							}
			    		}
		    		newDataset.data = newDataArray;
		    		chartData.push(newDataset);
		    	})

				var xAxisObj = {
					tickInterval : 1,
					labels: {
						style :{
							fontSize: '12px'
						},
						enabled: true,
						formatter: function(){ return chartData[0].data[this.value]? chartData[0].data[this.value][0] : '';},
					},
					minRange:1
				}
		    	var yaxesObj;
		    	var tickInterval1;
		    	var tickInterval2;
		    	if(units.length > 1){
				    yaxesObj = [{
				    	//tickInterval : tickInterval1,
				    	title: {
				            text: unit1
				        },
				    	labels: {
				            format: '{value}',
					        style :{
			            		fontSize: '12px'
			            	}
				        },
        				gridLineWidth: 0

				    },
				    {
				    	//tickInterval : tickInterval2,
				    	title: {
				            text: unit2
				        },
				    	labels: {
				            format: '{value}',
					        style :{
			            		fontSize: '12px'
			            	},
				        },
    					opposite: true
				    }];
				}else{
					yaxesObj = {
						//tickInterval : 5,
				    	title: {
				            text: unit1
				        },
				    	labels: {
				            format: '{value}'
				        },
        				gridLineWidth: 0
				    }
				}
				self.plot = new Highcharts.Chart({
		    		colors: self.chartColors,
					chart: {
                        type: chartType,
                        zoomEnabled: true,
                        renderTo: "placeholder"  + self.encodeId,
                        zoomType: 'xy',
                        resetZoomButton: {
                            position: {
                                align: 'right', // by default
                                verticalAlign: 'top' // by default
                            }
                        }
                    },
		    		title: {
					    text: '',
					    style: {
					        display: 'none'
					    }
					},
					credits: {
						enabled: false
					},
					subtitle: {
					    text: '',
					    style: {
					        display: 'none'
					    }
					},
					legend: {
				        itemStyle: {
				            fontWeight: 'normal'
				        }
				    },
				    yAxis: yaxesObj,
				    xAxis: xAxisObj,
					//alignScales: self.alignScales(),
				    plotOptions: {
				        series: {
				            dataLabels: {
								enabled: self.showValues(),
								allowOverlap:true,
							},
							label: {
				                connectorAllowed: false
				            },
				            stacking: selectedChartType === "Stacked bars" || selectedChartType === "Stacked columns"? 'normal' :  null
				        }
				    },

				    series: chartData,
				    
				    navigation: {
				        buttonOptions: {
				            enabled: false
				        }
				    },

				    responsive: {
				        rules: [{
				            condition: {
				                maxWidth: 500
				            },
				            chartOptions: {
				                legend: {
				                    layout: 'horizontal',
				                    align: 'center',
				                    verticalAlign: 'bottom'
				                }
				            }
				        }]
				    }

				});
			    
				self.resizeChartWithGridResize(resize);
    		}
			data.length = 0;
		}else{
		   // var placeholder = $("#placeholder" + self.encodeId); 
			self.plot = new Highcharts.Chart({
			            colors: self.chartColors,
			            chart :{
						      type : chartType,
                              zoomEnabled: true,
							  renderTo : "placeholder" + self.encodeId,
							  zoomType: 'xy',
							  resetZoomButton: {
									position: {
										align: 'right', // by default
										verticalAlign: 'top' // by default
									}
							  }
						},
					    title: {
					      text: '',
						    style: {
						        display: 'none'
						    }
						},
						credits: {
							enabled: false
						},
						navigation: {
							buttonOptions: {
								enabled: false
							}
						},
						subtitle: {
						    text: '',
						    style: {
						        display: 'none'
						    }
						},series:[{
						     dataLabels: {
								enabled: self.showValues()
							 }
						}]});
		    $('#altLegend' + self.encodeId).empty();
		    $('#altLegend' + self.encodeId).hide();
		    self.resizeChartWithGridResize(resize);
		}					
	};
	getColumnPeriodDataSetsTwo(scenario) {
		var self = this
		var colPeriodSets = [];
		var plan = DataManager.getDataManager().get('currentPlan');
	
		var steps = scenario.get("step")
		for (var step of steps.models) {
		  var periodsLen = step.get('noPeriods');
		  var period = step.get("startPeriod");
		  for (var l = 0; l < periodsLen; l++) {
			if (l != 0) {
			  period++;
			}
			let yearPeriod = utils.calculateYearPeriod(scenario.get("startTime"), period, plan.get("periodKind"));
			//var phaseAltName = phases.at(j).get('name') + "/" + alternatives.at(i).get('name');
			var periodKind = plan.getPeriodKinds(yearPeriod.year, yearPeriod.period, plan.get("periodKind"));
			var periodKindText = periodKind?.text || yearPeriod.period;
			colPeriodSets.push({ year: yearPeriod.year, period: periodKindText, periodNumber: periodKind.value, altId: step.get('alternativeId') })
		  }
		}
		return colPeriodSets;
	}
	displayTable(data, resize) {
		var length;
		
	    var self = this;
	    $("#placeholder" + self.encodeId).empty();
	    $('#legend' + self.encodeId).empty().hide();
	    $('#altLegend' + self.encodeId).empty().hide();
	    if(!data || !data.length){
	    	return;
	    }
		function getEmptyRow(tag){//Datable does not support rowSpan and colSpan
			var th = document.createElement(tag);
			th.style.display = "none";
			return th;
		}
	    //var phaseAltCombos = ["valueName"];
		//var uniqueDataForTR2 = [];
		//var selectedAlts = self.preference.selectedAlternativeObjs;
	    
	    var myTableDiv = $('#placeholder' + self.encodeId);
     
	    var table = document.createElement('TABLE');
	    table.id = 'table' + self.encodeId;
	    //table.className="display";
	    //table.className="pageResize";
	    table.cellspacing="0";
	    table.width="100%";
	    var tableHeader = document.createElement('THEAD');
	    table.appendChild(tableHeader);
	    var headerTR1 = document.createElement('TR');
	    tableHeader.appendChild(headerTR1);
	    //for first values header
	    var thValues = document.createElement('TH');
        thValues.appendChild(document.createTextNode("Values"));
        thValues.rowSpan = '2';
        headerTR1.appendChild(thValues);
		//headerTR1.appendChild(getEmptyRow('TH'));
	    
	    //to create a new row for year-period headers
	    var headerTR2 = document.createElement('TR');
	    tableHeader.appendChild(headerTR2);

		_.each(self.sortedDataSetList, function (pDataSet) {
			var thSc = document.createElement('TH');
        	thSc.appendChild(document.createTextNode(pDataSet.name));
			var count = 1;
			_.each(pDataSet.colPeriodSets, function (colPeriodSets) {
				var colName = colPeriodSets.year+"-"+colPeriodSets.period;
				var thPd = document.createElement('TH');
				thPd.appendChild(document.createTextNode(colName));
				headerTR2.appendChild(thPd);
				count = count+1;
				//headerTR1.appendChild(getEmptyRow('TH'));
			});
			thSc.colSpan = count;
			headerTR1.appendChild(thSc);
		});

		/*var headerTR3 = document.createElement('TR');
	    tableHeader.appendChild(headerTR3);
		var thGroupLabel = document.createElement('TH');
        thGroupLabel.appendChild(document.createTextNode("Group Label"));
        thGroupLabel.rowSpan = '2';
        headerTR3.appendChild(thGroupLabel);
		headerTR3.appendChild(getEmptyRow('TH'));*/

		var tableBody = document.createElement('TBODY');
	    table.appendChild(tableBody);

		var trTBody = {};
	    for(var k=0; k<data.length; k++){
	    	trTBody[k] = document.createElement('TR');
	    	tableBody.appendChild(trTBody[k]);
            var td = document.createElement('TD');
            td.align = "left";
   			td.appendChild(document.createTextNode(data[k].label));
   			trTBody[k].appendChild(td);
			_.each(self.sortedDataSetList, function (pDataSet) {
				var scId = pDataSet.id;
				_.each(pDataSet.colPeriodSets, function (colPeriodSets) {
					var found = _.find(data[k].data, function(e){ return e.scId === scId && colPeriodSets.year === e.year && colPeriodSets.periodNumber === e.periodNumber});
					if (found) {
						var td = document.createElement('TD');
						td.align = "right";
						var text;
						if(found.qty){
							text = window.utils.thousandSeparator(found.qty)
						}else{
							text = "-"
						}
						var a = document.createElement('a');
						a.appendChild(document.createTextNode(text));
						a.style.color = 'inherit';
						a.style.marginRight= "1%";
						a.setAttribute('id',found.valId);
						a.setAttribute('altId',found.altId);
						// a.setAttribute('mestId','');
						//if(isNaN(found[7])) {
							a.style.cursor = 'pointer';
							$(a).click(function(event){
								var currentTarget = event.currentTarget;
								var obj1 = {valueId:currentTarget.getAttribute('id'),altId:currentTarget.getAttribute('altId')};
								self.showAggregationView(obj1);
							});
						//}
						td.appendChild(a);
						if(found.svg){
							var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
							svg.setAttribute("width", 21);
							svg.setAttribute("height", 20);
							svg.setAttribute("style",'vertical-align:bottom; margin-right: 1%');
							svg.setAttribute("viewBox", "-3 -2 26 26");
							svg.setAttribute("fill", ""+found.fill);
							/*svg.setAttribute("correctiveActions",found[8]);
							svg.setAttribute("overallSatisfaction",found[9]);
							svg.setAttribute("minimumEndPoint",found[10]);
							svg.setAttribute("maximumEndPoint",found[11]);*/
							svg.style.cursor = 'pointer';
							var link = document.createElementNS("http://www.w3.org/2000/svg", "path");
							link.setAttribute("d", found[4]);
							svg.appendChild(link);
							td.appendChild(svg);		           		
						}
						trTBody[k].appendChild(td);
					}else{
						var td = document.createElement('TD');
						td.appendChild(document.createTextNode('-'));
						td.align = "right";
						trTBody[k].appendChild(td);
					}
				})
			})
   		}
		//for group label
		var noOfGroups = 0;
		/*for(var k=0; k<data.length; k++){
			var tdGroupLabel = document.createElement('TD');
			tdGroupLabel.appendChild(document.createTextNode(data[k].groupLabel ? data[k].groupLabel : "Uncategorized"));
			trTBody[k].appendChild(tdGroupLabel);
			if (data[k].groupLabel) noOfGroups += 1;
		}*/
   		self.resizeChartWithGridResize(resize);
	    myTableDiv.append(table);
		var groupLabelIndex = noOfGroups;
		//var selectedChartPageLength = self.preference.selectedChartPageLength ? Number(self.preference.selectedChartPageLength):Number(length);
	    		self.dataTable = $('#table' + self.encodeId).DataTable({
	    	/*"columnDefs": [
                { "visible": false, "targets": groupLabelIndex }
            ],*/
            //"sDom": "rtip",
			"bInfo": false,
			"bFilter": false,
            "order": [[ groupLabelIndex, 'asc' ]],
			"orderFixed": [ groupLabelIndex, 'asc' ],
			//"bLengthChange": false,
			"paging": false,
			//"pageLength": selectedChartPageLength,
			"pageResize": false,
            "drawCallback": function ( settings ) {
                var api = this.api();
                var rows = api.rows( {page:'current'} ).nodes();
                var last=null;
     
                api.column(groupLabelIndex, {page:'current'} ).data().each( function ( group, i ) {
                    if ( last !== group && noOfGroups > 0 ) {
                        $(rows).eq( i ).before(
                            '<tr class="group" style="text-align:left;"><td colspan='+groupLabelIndex+'><span>'+group+'</span></td></tr>'
                        );

                        last = group;
                    }
                });
            }
		});	
	};
	
	plotPolarChart(resize) {
	    var self = this;
	    $('#legend' + self.encodeId).empty().hide();
	    $('#altLegend' + self.encodeId).empty();
	    var altIndexes = Object.getOwnPropertyNames(self.altIndex);
	    _.each(altIndexes, function (key) {
	        var altIndexVal = self.altIndex[key];
			var altFound = $.grep(self.preference.selectedAlternativeObjs, function (e) { return e.displayName === key; });
			if (altFound && altFound.length === 0 && self.preference.selectedObjectiveObjs) {
				altFound = $.grep(self.preference.selectedObjectiveObjs, function (e) { return e.displayName === key; });
            }
	        if(self.groupByScenario){
            	var sceLabel = self.changeScenarioLabel(self.preference.selectedObservationObjs[altIndexVal]);
				if(sceLabel){
				   $('#altLegend' + self.encodeId).append($("<div>" + sceLabel + " : " + key + "</div>"));
				} else if (altFound[0] && altFound[0].timeStamp) {
					$('#altLegend' + self.encodeId).append($("<div>" + altFound[0].displayName + " : " + key + "</div>"));
				}else{
				   $('#altLegend' + self.encodeId).append($("<div>Sc-" + altIndexVal + " : " + key + "</div>"));
				}
        	}else if(self.groupByPhase){
            	var altLabel;
				if(altFound[0]){
					altLabel = self.changeAltLabel(altFound[0])
				}
				if(altLabel){
					$('#altLegend' + self.encodeId).append($("<div>" + altLabel + " : " + key + "</div>"));
				} else if (altFound[0] && altFound[0].timeStamp) {
					$('#altLegend' + self.encodeId).append($("<div>" + altFound[0].displayName + " : " + key + "</div>"));
                }
				else {
				   $('#altLegend' + self.encodeId).append($("<div>Alt-" + altIndexVal + " : " + key + "</div>"));
				}
			}
	    });		
        self.resizeChartWithGridResize(resize);
        var values = Object.getOwnPropertyNames(self.datasets);

        var selectedValueList = [], polarData = [], categoriesArray = [];//creating new data to populate charts
	    async.each(altIndexes, function (key, keyHandled) {
	    	var newDataset = {};
	    	var newDataArray = [];
	    	var altIndexVal = self.altIndex[key];
			var altFound = $.grep(self.preference.selectedAlternativeObjs, function (e) { return e.displayName === key; });
			if (altFound && altFound.length === 0 && self.preference.selectedObjectiveObjs) {
				altFound = $.grep(self.preference.selectedObjectiveObjs, function (e) { return e.displayName === key; });
            }
	    	if(self.groupByScenario){
            	var sceLabel = self.changeScenarioLabel(self.preference.selectedObservationObjs[altIndexVal]);
				if(sceLabel){
					newDataset.name = sceLabel
				} else if (altFound[0] && altFound[0].timeStamp) {
					newDataset.name = altFound[0].displayName
				}else{
				   newDataset.name = "Sc-" + altIndexVal;
				}
        	}else if(self.groupByPhase){
            	var altLabel;
				if(altFound[0]){
					altLabel = self.changeAltLabel(altFound[0])
				}
				if(altLabel){
					newDataset.name = altLabel;
				} else if (altFound[0] && altFound[0].timeStamp) {
					newDataset.name = altFound[0].displayName
                }
				else {
				    newDataset.name = "Alt-" + altIndexVal;
				}
			}
         	newDataset.pointPlacement = 'on';
	    	async.each(values, function (value, valueHandled) {
	    		var dataset = self.datasets[value];
	    		var valueSuffix = window.utils.getSuffix(value);
	    		var val = undefined;
	    		async.each(dataset.data, function(valData, dataHandled){
	    			if(valData[0] === newDataset.name){
		    			val = valData[1] === "-"? null : valData[1];
		    			var valueIndex =_.filter(selectedValueList,function(obj){return obj.id === valueSuffix;});
		                if(valueIndex && valueIndex[0]){
		                	valueIndex[0].value.push(val);
		                }else {
		                	selectedValueList.push({'id':valueSuffix,value:[val],valId:dataset.valId});
		                }
		    			dataHandled();
		    			return;
	    			}else{
	    				dataHandled();
	    			}
	    		},function(){
	    			var valData = {};
	    			valData['valSuffix'] = valueSuffix;
	    			if(val != undefined){
	    				valData['value'] = val;
	    			}else{
	    				valData['value'] = null;
	    			}
	    			var indivisualDataWithTooltip = {};
	    			indivisualDataWithTooltip['y'] = valData;
	    			indivisualDataWithTooltip['tooltip'] = window.utils.getRoundedValues(valData.value, dataset.significantDecimals, true);
	    			newDataArray.push(indivisualDataWithTooltip);
					categoriesArray.push(dataset.label);
					categoriesArray = _.uniq(categoriesArray);
	    			valueHandled();
	    		})
	    	}, function () {
	    		newDataset.data = newDataArray;
	    		polarData.push(newDataset);
	    		keyHandled();
	    	})
	    }, function () {
	    	for(var m=0;m<selectedValueList.length;m++){
		    	var min = Math.min.apply(Math,selectedValueList[m].value.map(function(o){return o;}));
		    	if(min < 0){
		    		selectedValueList[m].value = selectedValueList[m].value.map(function(o){
		    			return o = o - min;
		    		});
		    	}
		    	var max = Math.max.apply(Math,selectedValueList[m].value.map(function(o){return o;}));	    	
			    for(var i=0;i<polarData.length;i++){
			    	var valueList = polarData[i].data;
			    	var valueListIndex =_.filter(valueList,function(obj){return obj['y'] && obj['y'].valSuffix === selectedValueList[m].id;});
			    	if(min < 0 && valueListIndex && valueListIndex[0] && valueListIndex[0]['y'].value !== selectedValueList[m].value[i]){
			    		//Replacing polardata values when selectedvaluelist is changed
			    		valueListIndex[0]['y'].value = selectedValueList[m].value[i];
			    	}
			    	if(valueListIndex && valueListIndex[0] && valueListIndex[0]['y']){
			    		var oldValue = valueListIndex[0]['y'].value;
			    		if(max == 0 || isNaN(max)){
			    			max = 1;
			    		}
			    		if(oldValue !== '' || !isNaN(oldValue) || !isNaN(max)){
			    			var valuePercentage = oldValue/max * 100;
			    			if(isNaN(valuePercentage)){
			    				valuePercentage = 0
			    			}
				    		if(valuePercentage < -100){
				    			valuePercentage = -100;
				    		}
				    		var index = valueList.map(function (val) { return val['y'] && val['y'].valSuffix ; }).indexOf(selectedValueList[m].id);
				    		valueList[index]['y'] = window.utils.getRoundedValues(valuePercentage, 2 ,true);
			    		}
			    	}
			    }
			}
	    	var polarChartType;
	    	if(self.selectedChartType() === "Polar"){
	    		polarChartType= 'area';
	    	}else if(self.selectedChartType() === "Spiderweb"){
	    		polarChartType = 'line'
	    	}
	        new Highcharts.Chart({
			    colors: self.chartColors,
				chart: {
                        polar : true,
						type: polarChartType,
                        zoomEnabled: true,
                        renderTo: "placeholder"  + self.encodeId,
                        zoomType: 'xy',
                        resetZoomButton: {
                            position: {
                                align: 'right', // by default
                                verticalAlign: 'top' // by default
                            }
                        }
                },
			    title: {
				    text: '',
				    style: {
				        display: 'none'
				    }
				},
				credits: {
					enabled: false
				},
				navigation: {
					buttonOptions: {
						enabled: false
					}
				},
				subtitle: {
				    text: '',
				    style: {
				        display: 'none'
				    }
				},
				legend: {
			        itemStyle: {
			            fontWeight: 'normal'
			        }
			    },
			    pane: {
			        size: '70%'
			    },
			    responsive: {
			        rules: [{
			            condition: {
			                maxWidth: 500
			            },
			            chartOptions: {
			                legend: {
			                    layout: 'horizontal',
			                    align: 'center',
			                    verticalAlign: 'bottom'
			                }
			            }
			        }]
			    },

			    xAxis: {
			    	categories: categoriesArray,
			        tickmarkPlacement: 'on',
			        labels: {
			            enabled: true,
				        style :{
		            		fontSize: '12px'
		            	},
			            labels: {
			                align: 'center',
			                distance: 43
			            }
			        },
			        lineWidth: 0
			    },

			    yAxis: {
			    	gridLineInterpolation: 'polygon',
			    	lineWidth: 0,
			        endOnTick: true,
            		showLastLabel: true,
            		labels: {
				    	style :{
		            		fontSize: '12px'
		            	},
		                enabled: false,
		            },
		            max: 100
			    },
			    tooltip: {
				    formatter: function() {
				        return this.key+'<br>Value: '+this.point.tooltip;
				    }
				},
				plotOptions: {
				        series: {
				            dataLabels: {
								allowOverlap: true,
								enabled: self.showValues()
							},
							label: {
				                connectorAllowed: false
				            },
				        }
			    },
				series: polarData
			});
	    })
	}

	plotAccordingToChoices(resize) {
	    var self = this;
	    var placeholder = $("#placeholder" + self.encodeId);
	    var selectedOptions = self.preference? self.preference.selectedValueObjs : 0;
	    var selectedChartType = self.selectedChartType();
		if (selectedChartType === 'Radar') {//old radar chart migration
			self.selectedChartType('Curves');
		}
	    var values = Object.getOwnPropertyNames(self.datasets);
	    //var selectedOptions = $('#yaxis'+ self.encodeId + ' option:selected');
	    placeholder.css('border', 'none');
	    if (selectedChartType != 'Table') {
	        placeholder.css('overflow', 'visible');
			var showAltLegend = self.showLegend();
			if(self.preference && self.preference.showLegend){
				showAltLegend = self.preference.showLegend ;
			}
			if (showAltLegend) {
				$('#altLegend' + self.encodeId).show();
			} else {
				$('#altLegend' + self.encodeId).hide();
			}
	    } else {
	    	if(selectedOptions && selectedOptions.length > 0) {
	    		placeholder.css('overflow', 'auto');
	    	}else {
	    		placeholder.css('overflow', '');
	    	}
	    }
		
		if(self.dataTable){//destroy previously created datatable
			self.dataTable.destroy(true);
		}
		if(selectedChartType === 'Polar' || selectedChartType === "Spiderweb"){
	        placeholder.empty();
	        self.plotPolarChart(resize);
	    } else {
	        //d3.select("#placeholder" + self.encodeId + ' svg').remove();
	        self.plotFlotChart(resize);
	        if(values.length == 0 && selectedChartType === 'Table') {
	        	self.displayTable([]);
				
        	}
	    }
	};
	changeAltLabel(alt){
		var self = this;
		var altName;
		if(self.preference && self.preference.alternativeLabels && self.preference.alternativeLabels.length  > 0){
			for(var i=0;i<self.preference.alternativeLabels.length;i++){
				if(self.preference.alternativeLabels[i].id === alt.id){
						altName = self.preference.alternativeLabels[i].label 
						break;
				}
			}
		}
		return altName;
	}
	changeScenarioLabel(obs){
		var self = this;
		var sceName;
		if(self.preference && self.preference.scenarioLabels && self.preference.scenarioLabels.length  > 0 && obs){
			for(var i=0;i<self.preference.scenarioLabels.length;i++){
				if(self.preference.scenarioLabels[i].id === obs.id){
						sceName = self.preference.scenarioLabels[i].label 
						break;
				}
			}
		}
		return sceName;
	}
    loadSelectedValues(alt, obsModel, valObj, val, measurandData, hidePreviousAltData,obs, callback){
		var self = this;
		if(!self.preference){
			callback();
			return;
		}
		var selectedAlts = self.preference.selectedAlternativeObjs;
		var selectedChartType = self.selectedChartType();
		var tableType = false;
		var radarType = false;
		var curveType = false;
		if(selectedChartType === "Table"){
			tableType = true;
		}else if(selectedChartType === "Radar"){
			radarType = true;
		}else{
			curveType = true;
		}
        var timeStamp;
        if (obsModel) {
            timeStamp = obsModel.get('whenObserved');
        } else if (obs && obs.timeStamp) {
            timeStamp = obs.timeStamp;
        }
        
		var isTimestamp = timeStamp? true : false;
		var altObs, dataStr, data, unit;
		if(val){
			//var measure = val.get('valueMeasurement');
			var altComp = alt.getModelLinkedSync(val);
			if(altComp){
				val = altComp;
			}
			var preAltScenario = altComp?alt.getMainScenario(altComp.getNestedParent()):null;
			var observedMeasure = preAltScenario?preAltScenario.getObservedMeasureWithMeasurements(altComp.get('valueMeasurement'), isTimestamp, timeStamp):null;
			var mest = observedMeasure?observedMeasure.get('measurements').at(0):null;
			if(!observedMeasure){
				observedMeasure = preAltScenario?preAltScenario.getObservedMeasureWithMeasurements(altComp.get('satisfactionLevel'), isTimestamp, timeStamp):null;
				mest = observedMeasure?observedMeasure.get('measurements').at(0):null;
				var compSatisfactionMeasure = mest?mest.get('observedMeasure').get('measure'):null;
			}			
        }
            if (val || mest || tableType) {
                var valData = [];
                var phaseName = alt.get('phaseAlternativeOwner').get('name');
                var altName = alt.get('name');
                var altProp = phaseName + " / " + altName;
                var whenObsName;
                var whenObsNameWithLocaleString;
                if (obsModel) {
                    whenObsName = obsModel.get('name');
                }
                whenObsNameWithLocaleString = whenObsName;
                var whenObsNameWithLocaleDateString = whenObsName;

                var isImportedTimeStamp = timeStamp ? !isNaN(timeStamp) : false;
                //var altObsName = altName+" / " + whenObsNameWithLocaleDateString;
                var implementationDate = alt.get('phaseAlternativeOwner').get('endDate') ? alt.get('phaseAlternativeOwner').get('endDate') : alt.get('phaseAlternativeOwner').get('startDate');
                if (!implementationDate) {
                    implementationDate = new Date().getTime();
                }
                self.implementationDate(implementationDate);
                var altObsName = altName + " / " + whenObsNameWithLocaleDateString;
                    valData.push(altProp + " / " + whenObsNameWithLocaleDateString);
                    if (self.groupByScenario) {
                        if (!_.has(self.altIndex, whenObsName)) {
                            self.altIndex[whenObsName] = (_.keys(self.altIndex)).length;
                        }
                    } else {
                        //group by phase and time
                        if (!_.has(self.altIndex, altProp)) {
                            self.altIndex[altProp] = (_.keys(self.altIndex)).length;
                        }
                    }
                //valData['hide'] = hidePreviousAltData;
				
                function fillValueData(valData,obs,unitName) {
                    var vm = val ? val.get('valueMeasurement') : null;
                    if (tableType) {
                        valData.push(phaseName);
                        var altFound = _.find(selectedAlts, function (e) { return e.id === alt.id; });
                        if (altFound) {
                            if (timeStamp && altFound['labelInAlts'] && altFound['labelInAlts'][timeStamp] && altFound['labelInAlts'][timeStamp] != "") {
                                valData.push(altFound['labelInAlts'][timeStamp]);
                            } else if (!timeStamp && altFound['labelInAlts'] && altFound['labelInAlts']['Base'] && altFound['labelInAlts']['Base'] != "") {
                                valData.push(altFound['labelInAlts']['Base']);
                            } else {
                                valData.push(altObsName);
                            }
                        }
                        var selectedInterval = preAltScenario && val ? val.getSatisfactionInterval(preAltScenario, timeStamp, null) : null;
                        var smileySrc = '';
                        var smileyColor = '';
                        if (selectedInterval) {
                            smileySrc = val.fetchSmileySrc(selectedInterval.get('smiley'));
                            smileyColor = selectedInterval.get('smileyColor');
                        }
                        valData.push(smileySrc);
                        valData.push(smileyColor);
                        valData.push(alt.id);
                    }
                    var valueData;
					if (mest || data) {
						if (mest) {
							var unit = mest.get('observedMeasure').get('measure').get('unit');
                            unitName = unit ? unit.get('name') : null;
							//unitName = unit?window.utils.replaceEscapeCharacters(unit.get('name')):null;
                        }
						valueData = measurandData[unitName];
						if (!valueData) {
							unitName = unitName ? unitName : 'Grade only';
							var measureData = self.addMeasurandData(val, alt.id, timeStamp, vm.id, valObj, unitName, measurandData);
							valueData = measureData ? measureData[unitName] : null;
							if (radarType && self.preference) {
								if (self.groupByScenario) {
									valueData['radarName'] = self.preference.selectedAlternativeObjs.length > 1 ? "[" + altProp + "]" : "";
									valueData['labelHover'] = "[" + altProp + "]";
								} else {
									valueData['radarName'] = self.preference.selectedObservationObjs.length > 1 ? "[" + whenObsName + "]" : "";
									valueData['labelHover'] = "[" + whenObsNameWithLocaleString + "]";
								}
							} else if (curveType) {
								var obsName;
								if (obsModel) {
									obsName = obsModel.get("name");
								}
								if (self.preference.selectedObservationObjs && self.preference.selectedObservationObjs.length > 1) {
									valueData.label = valueData.label.concat(" [" + obsName + "]");
								}
								else {
									valueData.label = valueData.label;
								}
							}
							var valid = window.utils.getSuffix(val.id);
							var valFound = $.grep(self.preference.selectedValueObjs, function (e) { return window.utils.getSuffix(e.suffixId) === valid; });
							if (valFound[0] && valFound[0].label && valFound[0].label !== "") {
								var label = unitName == "Grade only" ? valFound[0].label : valFound[0].label.concat(" (" + unitName + ")");
								valueData.displayLabel = label;
							} else {
								label = valueData.label;
							}
							if (valFound[0] && valFound[0].groupLabel && valFound[0].groupLabel !== "") {
								var groupLabel = valFound[0].groupLabel;
								valueData.groupLabel = groupLabel;
							} else {
								valueData.groupLabel = null;
							}
							valueData.significantDecimals = unit ? unit.get('significantDecimals') : '2';
							valueData.label = label;
							valueData.unit = unitName;
						}
						valueData.data.push(valData);
					} else if(!mest){
						var measurement = val.get('valueMeasurement').get('measurement').at(0);
                        var valUnit = measurement? measurement.get('observedMeasure').get('measure').get('unit'):'';
						var unit = valUnit ? valUnit.get('name') : "";
						if (measurandData[unit]) {
							measurandData[unit].data.push(valData);
						} else if(val && unit && !tableType){
							measurandData[unit] = {};
							valueData = measurandData[unit];
							valueData.valueName = val.get('name');
							valueData.displayName = val.get('name');
							valueData.valueId = val.get('id');
							valueData.valId = vm? vm.id:'';
							valueData.unit = unit;
                            valueData.label = unit == "Grade only" ? valueData.valueName : valueData.valueName + ' (' + unit + ')';
							if(!valueData.data){
								valueData.data = [];
							}
							valueData.data.push(valData);
						}
                    }
                    callback(measurandData);
                }
                // for fetching data from code container instead of mest
                if (isImportedTimeStamp) {
                    if (val.id.indexOf(window.plansKey) >= 0) {
                        altObs = _.find(alt.getOrCreatePhaseObjectiveSet().get('objectiveObservation').models, function (a) { if (a.get('whenObserved')) { return a.get('whenObserved') === obsModel.get('whenObserved'); } });
                    } else {
                        var scenarioObj = Backbone.Relational.store.getObjectByName('vdml.Scenario').find({ id: alt.get('designScenario').models[0].get('scenario') });
                        var altBmobsModels = scenarioObj.get('contextObservation').models;
                        altObs = _.find(altBmobsModels, function (a) { if (a.get('whenObserved')) { return a.get('whenObserved') === obsModel.get('whenObserved'); } });
                    }
                    if (altObs) {
                        var dataRef = altObs.get('dataReference');
                    }
                }
                var vm = val ? val.get('valueMeasurement') : null;
				var unitName = "";
                if (dataRef) {
                    var codeContainer = self.codeContainerList[dataRef];
                    if (codeContainer) {
                        dataStr = codeContainer.get('code');
						data = dataStr ? JSON.parse(dataStr) : null;
						if(data){
							var value;
							if (!vm || vm.get('measurement').at(0) == null) {
								vm = val ? val.get('satisfactionLevel') : null;
								value = data[vm.id];
							} else if(vm && data[vm.id]){
								value = parseFloat(data[vm.id]);
							}
							valData.push(value ?  value : "-");
							unit = vm.get('measurement').at(0) ? vm.get('measurement').at(0).get('observedMeasure').get('measure').get('unit') : null;
							unitName = unit?window.utils.replaceEscapeCharacters(unit.get('name')):'';
						}
						fillValueData(valData,obs,unitName);
                    } else {
						var codeContainer = window.utils.getElementModel(dataRef,['beeppackage.CodeContainer']);
                        //CodeContainer.getBlobInstanceWithId(dataRef, DataManager.getDataManager().get('currentPlan'), function (codeContainer) {
                            if (codeContainer) {
                                dataStr = codeContainer.get('code');
                                data = dataStr ? JSON.parse(dataStr) : null;
								var value;
                                if (!vm || vm.get('measurement').at(0) == null) {
                                    vm = val ? val.get('satisfactionLevel') : null;
									value = data[vm.id];
                                } else if(vm && data[vm.id]){
									value = parseFloat(data[vm.id]);
								}
                                valData.push(value ?  value : "-");
                                unit = vm.get('measurement').at(0) ? vm.get('measurement').at(0).get('observedMeasure').get('measure').get('unit') : null;
                                unitName = unit?window.utils.replaceEscapeCharacters(unit.get('name')):'';
								fillValueData(valData,obs,unitName);
                            } else {
                                console.log('Unable to load selected Package');
								fillValueData(valData,obs);
                            }
                        //});
                    }
                } else {
                        if (mest && mest.get('observedMeasure').get('measure') && (mest.get('value') !== null || mest.get('symbol') !== null)) {
                            unit = mest.get('observedMeasure').get('measure').get('unit');
							var currentValue = window.utils.getRoundedValues(mest.get('value'), unit ? unit.get('significantDecimals') : '2', true);
                            if (mest.get('value') && currentValue === '') {
                                valData.push('-');
                            } else {
                                if (mest.get('value')) {
                                    valData.push(currentValue);
                                } else if (compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')) {
                                    valData.push(mest.get('symbol'));
                                } else {
                                    valData.push("-");
                                }
                            }
                        } else {
                            valData.push("-");
                        }
                        fillValueData(valData,obs);
                }
            }	
	}
	addMeasurandData(val, altId, whenObserved, mcId, valObj, unitName, measurandData){
		var self = this;
		var valueData = {};
		valueData.valueName = val.get('name');
		valueData.name = val.get('name');
		valueData.valueId = val.get('id');
		valueData.altId = altId;
		valueData.whenObserved = whenObserved;
		valueData.displayName = valObj.namePath? valueData.valueName + " [" + valObj.namePath +"]" : valueData.valueName ;	
		var labelName = valObj.namePath? valueData.valueName + " [" + valObj.namePath +"]" : valueData.valueName ;
		valueData.label = unitName == "Grade only" ? labelName : labelName + " (" + unitName +")" ;		
		valueData.valId = mcId;
        if(measurandData[unitName]){
            valueData.data = measurandData[unitName].data;
		    measurandData[unitName] = valueData;
        }else{
            valueData.data = [];
            measurandData[unitName] = valueData;
        }
		return measurandData;
	}
	
	getAltIdOfObservation(observation){
		var plan = DataManager.getDataManager().get('currentPlan');
		if(observation.id.indexOf('@BEEPPlans@')!== -1){
    		return observation.get('phaseObjectiveSet').get('phaseObjectiveSetOwner').id;
    	}else{
    		var context = observation.getContext();
            while (context.get('parentContext')) {
                context = context.get('parentContext');
            }
            var scenarioProxy = plan.getScenarioProxy(context.id);
            if(!scenarioProxy){
            	obsUpdated();
                return;
            }
            return scenarioProxy.get('designScenarioOwner').id;
    	}
	}
	addMeasureUnit(val, measurandData, unit, valUnit) {//used only for objective
		var self = this;
		measurandData[unit] = {};
		measurandData[unit].altId = window.utils.getPrefix(val.id);
		measurandData[unit].data = [];
		var valid = window.utils.getSuffix(val.id);
		var valFound = $.grep(self.preference.selectedValueObjs, function (e) { return window.utils.getSuffix(e.suffixId) === valid; });
		if (valFound[0] && valFound[0].label && valFound[0].label !== "") {
			var label = unit == "Grade only" ? valFound[0].label : valFound[0].label.concat(" (" + unit + ")");
			measurandData[unit].label = label;
		} else {
			measurandData[unit].label = val.get('name').concat(" (" + unit + ")");
		}
		if (valFound[0] && valFound[0].groupLabel && valFound[0].groupLabel !== "") {
			measurandData[unit].groupLabel = valFound[0].groupLabel;
		} else {
			measurandData[unit].groupLabel = null;
		}
		measurandData[unit].displayName = val.get('name');
		measurandData[unit].name = val.get('name')
		measurandData[unit].significantDecimals = valUnit ? valUnit.get('significantDecimals') : "2";
		measurandData[unit].unit = unit;
		measurandData[unit].valId = val.get('valueMeasurement').get('id')
		measurandData[unit].valueId = val.get('id');
		measurandData[unit].valueName = val.get('name');
		measurandData[unit].type = val.get('type');
	}
	getValueMeasurements(callback){
		var self = this;
		self.sortedDataSetList = [];
		// var plan = DataManager.getDataManager().get('currentPlan');
		// var scenarioId = plan.get("defaultScenario");
		// var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
		var scExecHeaders = [{'id':self.defaultScenario.get('id'),'name':self.defaultScenario.get('name')}];
		_.each(scExecHeaders, function(obj){
			var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: obj.id });
			var colPeriodSets =self.getColumnPeriodDataSetsTwo(scenario);
			self.sortedDataSetList.push({'id':scenario.get('id'),'name':scenario.get('name'),'colPeriodSets':colPeriodSets});
	    });
		var dataSet = {};
		self.datasets;
		self.margin = 0.05;
		self.altIndex = {};
		self.alts = self.getAlternatives();
		//self.scIndex = {};
		var selectedValues = self.preference.selectedValueObjs;
		var selectedObjs = self.preference.selectedObjectiveObjs;
		if (!selectedObjs) {
			selectedObjs = [];
		}
		var selectedChartType = self.selectedChartType();
		self.showBars = selectedChartType === "Bars" || selectedChartType === "Stacked bars" || selectedChartType === "Columns" || selectedChartType === "Stacked columns"? true : false;
		var tableType = false;
		if(selectedChartType === "Table"){
			tableType = true;
		}
		var periodKind = DataManager.getDataManager().get('currentPlan').get('periodKind');
		async.eachSeries(self.sortedDataSetList, function(obs, obsHandleCallback){
			var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: obs.id });
			if (!scenario) {
				obsHandleCallback();
				return;
			}
			var colPeriodSets = obs.colPeriodSets;
			async.eachSeries(colPeriodSets, function(pd, altHandleCallback){
				var year = pd.year;
				//var periodKind = pd.period;//Dec
				var period = pd.periodNumber;
				var altId = pd.altId;
				//var alt = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: altId });
				scenario.getDatasetAsync(year,period,periodKind,null,function(inputDataset,resultDataset){
					_.each(selectedValues,function(valId){
						var dataQtyJson = {};
						var unitName;
						var valName;
						var isPlanValue = valId.includes(window.plansKey);
						var suffixId = window.utils.getSuffix(valId);
						var valueId = isPlanValue ? valId : altId + window.utils.getSuffix(valId);
						var comp = window.utils.getElementModel(valueId,['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']);
						if(comp) {
							var qty
							if(inputDataset && comp.get("valueType") == ValueType.Atomic){
								qty = inputDataset.get(comp.get('id'));
							} else if(resultDataset && comp.get("valueType") == ValueType.Aggregated) {
								qty = resultDataset.get(comp.get('id'));
							}
							var sigQty = comp.getSignificantQty(qty);
							dataQtyJson.altId = altId;
							dataQtyJson.qty = sigQty;
							dataQtyJson.valId = comp.id;
							dataQtyJson.year = year;
							dataQtyJson.periodNumber = period;
							dataQtyJson.scId = obs.id;
							// fill,svg
							unitName = comp.get('unit').get('name');
							valName = comp.get('name');
						}
						if(!self.datasets[suffixId]){
							//add groupLabel and label
							self.datasets[suffixId] = {valId:comp.id,name:valName,label:valName,unit:unitName,data:[]};
						}
						self.datasets[suffixId].data.push(dataQtyJson);
					});
					altHandleCallback();
				});
			}, function(){
				obsHandleCallback();
			});
		},function(){
			//self.datasets = dataSet;
			callback();
		});
	};
	
	getAlternatives(){
		var alts = [];
		var currentPlan = DataManager.getDataManager().get('currentPlan');
		var phase = currentPlan.get('phase').findWhere({nextPhase:null});
		while(phase){
			var phaseAltsArray = [];
			var phaseAlts = phase.get('phaseAlternative');
			var masterAlt = phase.get('master');
			phaseAlts.each(function(phaseAlt){
				if(phaseAlt !== masterAlt){
					phaseAltsArray.push(phaseAlt);
				}
			});
			phaseAltsArray.unshift(masterAlt);
			alts = phaseAltsArray.concat(alts);
			phase = phase.get('previousPhase');
		}		
		return alts;
	};

	getXAxisData(refresh) {
	    var self = this;
	    var xAxisData = [];
	    var currentPlan = DataManager.getDataManager().get('currentPlan');
	    var phase = currentPlan.get('phase').findWhere({ nextPhase: null });
	    while (phase) {
	        var phaseData = {};
	        xAxisData.unshift(phaseData);
	        phaseData.id = phase.get('id');
	        phaseData.parent = '#';
	        phaseData.text = phase.get('name');
	        if (self.preference && (_.indexOf(self.preference.xAxisSelected, phaseData.id) >= 0)) {
	            phaseData.selected = true;
	            self.xAxisSelected.push(phaseData.id);
	        }

	        var phasePrimary = phase.get('primary');
	        if (phasePrimary && !self.preference && !refresh) {
	            self.xAxisSelected.push(phasePrimary.get('id'));
	        }


	        var phaseAlts = phase.get('phaseAlternative');
	        phaseAlts.each(function (phaseAlt) {
	            var altData = {};
	            altData.id = phaseAlt.get('id');
	            altData.parent = phase.get('id');
	            altData.text = phaseAlt.get('name');
	            xAxisData.push(altData);
	            if (!self.preference && (phasePrimary === phaseAlt)) {
	                altData.state = { opened: true, selected: true };
	            }
	            if (self.preference && (_.indexOf(self.preference.xAxisSelected, phaseAlt.get('id')) >= 0)) {
	                altData.state = { opened: true, selected: true };
	                self.xAxisSelected.push(phaseAlt.get('id'));
	            }
	            var phObjSet = phaseAlt.get('phaseObjectiveSet');
	            if(phObjSet) {
		            var objObservations = phObjSet.get('objectiveObservation');
		            objObservations.each(function (objObs) {
		                if (objObs.get('whenObserved') != null) {
		                    var altData = {};
		                    altData.id = phaseAlt.get('id');
		                    altData.parent = phase.get('id');
		                    altData.text = phaseAlt.get('name') + "(" + objObs.get('whenObserved') + ")";
		                    altData.timeStamp = objObs.get('whenObserved');
		                    altData.id = altData.id + altData.timeStamp;
		                    xAxisData.push(altData);
		                    if (self.preference && (_.indexOf(self.preference.xAxisSelected, phaseAlt.get('id') + altData.timeStamp) >= 0)) {
		                        altData.state = { opened: true, selected: true };
		                        self.xAxisSelected.push(phaseAlt.get('id') + altData.timeStamp);
		                    }
		                }
		            });
		            self.xAxisSelected = _.uniq(self.xAxisSelected);
	            }
	        });
	        phase = phase.get('previousPhase');
	    }
	    return xAxisData;
	};
	
	showAggregationView(view) {
		var self = this;
        var valueId = view.valueId;
		var altModel = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: view.altId });
		altModel.showAggregationView(valueId, function(){
			window.utils.startSpinner('refreshValues','Refreshing ...');
			DataManager.getDataManager().invokeValueUpdates(function(){
				if(self.parentView) {
					window.vdmModelView.setResizeHeight(true);//TODO refresh only charts and strategymap
					setTimeout(function(){
						window.utils.stopSpinner('refreshValues');	
					}, 300);
				}else {
					window.utils.stopSpinner('refreshValues');	
				}
			});
		});       
    };


	getColumnPeriodDataSets(steps){
		const self = this
		for (var step of steps.models){
			var periodsLen = step.get('noPeriods');
			var period = step.get("startPeriod");
			for(var l = 0; l < periodsLen; l++){
				if(l != 0){
					period++;
				}
				let yearPeriod = utils.calculateYearPeriod(self.defaultScenario.get("startTime") , period,self.currentPlan.get("periodKind"));
				//var phaseAltName = phases.at(j).get('name') + "/" + alternatives.at(i).get('name');
				var periodKind =  self.currentPlan.getPeriodKinds(yearPeriod.year,yearPeriod.period,self.currentPlan.get("periodKind"));
				var periodKindText = periodKind?.text || yearPeriod.period;
				self.periodSets().push({year:yearPeriod.year,period:periodKindText,periodNumber:periodKind.value,altId:step.get('alternativeId')})
			}
		}
	}

	getSelectPeriodOptions(){
		var self =  this;
		const fromObj = self.periodSets().find(d=>d.year===self.selectedFromYear() && d.period === self.selectedFromPeriod());
		const toObj =  self.periodSets().find(d=>d.year===self.selectedToYear() && d.period === self.selectedToPeriod());
		if(fromObj && toObj){
			const filteredPeriods = self.periodSets().filter(period => {
				const fromYear = fromObj.year;
				const fromPeriod = fromObj.periodNumber;
				const toYear = toObj.year;
				const toPeriod = toObj.periodNumber;
				
				const isAfterFrom = period.year > fromYear || (period.year === fromYear && period.periodNumber >= fromPeriod);
				const isBeforeTo = period.year < toYear || (period.year === toYear && period.periodNumber <= toPeriod);
				
				return isAfterFrom && isBeforeTo;
			});
			filteredPeriods.map((d)=>{
				var text = `${d.year}-${d.period}`;
				self.selectedPeriodOptions().push({text:text,value:text});
			})
		}

	}

	getPeriodsByFromYear(year){
		var self = this;
		let selectedYear = year
		const periods = self.periodSets().filter(d=>d.year===+selectedYear)
		self.fromPeriods(periods.map(d=>d.period));
		self.selectedFromPeriod(self.fromPeriods()[0])
	}

	getToPeriodsByFromPeriods(period){
		var self = this;
		if(self.selectedFromYear() && self.selectedToYear() && self.selectedFromYear() === self.selectedToYear()){
			var periodObj =  self.periodSets().find((d)=>d.year===self.selectedFromYear()&&d.period===period)
			const periods = self.periodSets().filter(d=>d.year===+self.selectedToYear())
			self.toPeriods(periods.filter(d=>d.periodNumber>=periodObj.periodNumber).map(d=>d.period));
			self.selectedToPeriod(self.toPeriods()[self.toPeriods().length-1])
		}
		
	}

	getPeriodsByToYear(year){
		var self = this;
		let selectedYear = year
		const periods = self.periodSets().filter(d=>d.year===+selectedYear)
		self.toPeriods(periods.map(d=>d.period));
		self.selectedToPeriod(self.toPeriods()[self.toPeriods().length-1])
	}

	reRenderSelectPeriod(){
		var self = this;
		var selectElement = $('#selectPeriod' + self.encodeId);
		selectElement.empty();

		self.selectedPeriodOptions().forEach(function(option) {
			selectElement.append($('<option>', {
				value: option.value,
				text: option.text
			}));
		});
		selectElement.multiselect('destroy');
		selectElement.multiselect({
			enableClickableOptGroups: false,
			numberDisplayed: 0,
			nonSelectedText: '0 Selected',
		});
	}

	generateMetricsCSV() {
		var self = this;
		var fromPeriodDataset = self.periodSets().find(d=>d.year===self.selectedFromYear() && d.period===self.selectedFromPeriod());
		var toPeriodDataset = self.periodSets().find(d=>d.year===self.selectedToYear() && d.period===self.selectedToPeriod());
		var params = {fromYear:self.selectedFromYear(),toYear:self.selectedToYear(),fromPeriod:fromPeriodDataset.periodNumber,toPeriod:toPeriodDataset.periodNumber,scenarioId:self.defaultScenario.get('id'),scenarioExecutionId:self.defaultScenario.get("defaultExecutionScenario")}
		DataManager.getDataManager().get('currentPlan').scenarioExportDialog(params);
	};

init(model,options){
    var self = this;
    this.datasets = {};
    this.values = [];
    this.DashboardValuesChart2ViewModel = this;
    this.model = model;
    this.chartInitialized = false;
    if (options && options.parentView) {
        this.parentView = options.parentView;
    }
    this.preference = JSON.parse(this.model.get('chartConfig'));
    this.name = kb.observable(model, 'name');
    this.id = kb.observable(model, 'id');
    this.importedTimestampObservationList = new Backbone.Collection;
    this.codeContainerList = {};
    this.chartTypes = ko.observableArray(['Curves', 'Stacked bars', 'Stacked columns', 'Bars', 'Columns', /*'Radar',*/ 'Table', 'Polar', 'Spiderweb']);
    this.chartTypes.sort();
    function htmlEscape(str) {
        return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
    }
	function saveChartData(name,value){
		var json = JSON.parse(self.model.get("chartConfig"));
			if(value != null){
				json[name] = value
			}
			
		self.model.set("chartConfig", JSON.stringify(json));
	}
	this.configJson = JSON.parse(self.model.get("chartConfig"));
    this.encodeId = this.model ? htmlEscape(this.model.id) : null;
    this.description = kb.observable(model, 'description');
    self.xAxisSelected = [];
    this.implementationDate = ko.observable();
    this.selectedChartType = ko.observable();
    this.selectedGroupType = ko.observable();
    this.selectedIntervalValue = ko.observable();
    this.groupTypes = ko.observableArray(['Phase/Alternative', 'Scenario', 'Time']);
    this.intervalList = ko.observableArray(['Day', 'Month', 'Quarter', 'Week', 'Year']);
    this.minimumDate = ko.observable();
    this.maximumDate = ko.observable();
    this.alignScales = ko.observable(false);
    this.showValues = ko.observable(false);
	this.showLegend = ko.observable(true);
    this.timeBars = ko.observable(false);
    //this.annotationNonEmpty = ko.observable(false);
    this.enableZoom = ko.observable(false);
    this.xaxisMin = {};
    this.xaxisMax = {};
    this.yaxes1Min = {};
    this.yaxes1Max = {};
    this.yaxes2Min = {};
    this.yaxes2Max = {};
    this.chartColors = ["#EDC951", "#F08080", "#FFA07A", "#4169E1", "#98FB98", "#EDC951", "#87CEFA", "#ceaeff", "#003366", "#0066ff"]
    
	//
	const dataManager = DataManager.getDataManager();
	this.currentPlan = dataManager.get('currentPlan');
	const scId = self.currentPlan.get("defaultScenario");
	this.defaultScenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scId });
	const executionScenarioId = self.defaultScenario.get("defaultExecutionScenario");
	this.executionScenario = Backbone.Relational.store.getObjectByName('transformation.ScenarioExecution').find({ id: executionScenarioId });

	this.periodSets = ko.observableArray([])
	this.selectedPeriodOptions = ko.observableArray([]);
	// this.selectedScenarioTables =  ko.observableArray([]);
	// this.scenarioOptions = ko.observableArray([]);
	this.selectedPeriodValues = ko.observableArray([]);
	this.fromYears = ko.observableArray([]);
	this.fromPeriods = ko.observableArray([]);
	this.toPeriods = ko.observableArray([]);
	this.toYears = ko.observableArray([]);
	this.selectedFromYear = ko.observable("");
	this.selectedFromPeriod = ko.observable("");
	this.selectedToYear = ko.observable("");
	this.selectedToPeriod = ko.observable("");
	// this.header1 = ko.observable('');
	// this.header2 = ko.observable('')
	// this.showMultipleScenarioTable = ko.observable(false);
	this.periodKind = self.currentPlan.get("periodKind")

	self.getColumnPeriodDataSets(self.defaultScenario.get('step'))

	const filteredYears = self.periodSets().filter((item, index, self) =>
		index === self.findIndex((t) => t.year === item.year)
	);

	self.fromYears(filteredYears.map(d=>d.year));
	var defaultPeriods = this.defaultScenario.fillDefaultPeriods(self.periodSets(),self.periodKind);
	self.periodSets().map(d=>{
		self.selectedPeriodOptions().push({text:`${d.year}-${d.period}`,value:`${d.year}-${d.period}`})
	})

	self.selectedFromYear.subscribe(function(value){
		if(value!==undefined){
			self.getPeriodsByFromYear(value)
			var toYears = filteredYears.filter(d=>d.year>=value).map(d=>d.year)
			self.toYears(toYears);
			self.selectedToYear(self.toYears()[self.toYears().length-1]);
			if(self.viewloaded){
				self.selectedPeriodOptions.removeAll();
				self.selectedPeriodValues.removeAll();
			}
			self.getSelectPeriodOptions();
		}
		self.reRenderSelectPeriod()
	});

	self.selectedFromPeriod.subscribe(function(value){
		if(value!==undefined){
			self.selectedFromPeriod(value)
			self.getToPeriodsByFromPeriods(value)
			if(self.viewloaded){
				self.selectedPeriodOptions.removeAll();
				self.selectedPeriodValues.removeAll();
			}
			self.getSelectPeriodOptions();
		}
		self.reRenderSelectPeriod()
		
	});

	
	self.selectedToYear.subscribe(function(value){
		if(value!==undefined){
			self.getPeriodsByToYear(value);
			self.selectedToYear(value);
			if(self.viewloaded){
				self.selectedPeriodOptions.removeAll();
				self.selectedPeriodValues.removeAll();
			}
			self.getSelectPeriodOptions();
		}
		self.reRenderSelectPeriod();
	});
	self.selectedToPeriod.subscribe(function(value){
		if(value!==undefined){
			self.selectedToPeriod(value);
			if(self.viewloaded){
				self.selectedPeriodOptions.removeAll();
				self.selectedPeriodValues.removeAll();
			}
			self.getSelectPeriodOptions();
		}
		self.reRenderSelectPeriod();
	});

	// $('#selectPeriod' + self.encodeId).multiselect({
	// 	enableClickableOptGroups: false,
	// 	numberDisplayed : 0,
	// 	nonSelectedText: '0 Selected',
		
	// });


	const interval = setInterval(() => {
        if ($('#selectPeriod' + self.encodeId).length > 0) {
			$('#selectPeriod' + self.encodeId).multiselect({
				enableClickableOptGroups: false,
				numberDisplayed : 0,
				nonSelectedText: '0 Selected',
				
			});
			clearInterval(interval);
        }
    }, 500);
	
	// self.getSelectPeriodOptions();
	// 
	if (options.savedMinimum) {
        this.minimumDate(DateFormat.getDateFromTimestamp(options.savedMinimum));
    }
    if (options.savedMaximum) {
        this.maximumDate(DateFormat.getDateFromTimestamp(options.savedMaximum));
    }
    if (options.selectedChartType) {
        this.selectedChartType(options.selectedChartType);
    } else {
        this.selectedChartType("Table");
    }
    if (options.selectedGroupType) {
        this.selectedGroupType(options.selectedGroupType)
    }
    if (options.savedInterval) {
        this.selectedIntervalValue(options.savedInterval)
    }
    if (options.showValues != null) {
        this.showValues(options.showValues);
    }
	if (options.showLegend != null) {
        this.showLegend(options.showLegend);
    }
    this.minimumDate.subscribe(function (val) {
		saveChartData("savedMinimum",val)
        self.refreshChart();
    });
    this.maximumDate.subscribe(function (val) {
		saveChartData("savedMaximum",val)
        self.refreshChart();
    });
	this.alignScales.subscribe(function (val) {
        self.refreshChart();
    });
    this.showValues.subscribe(function (val) {
        if (val) {
            self.showValues(true);
			saveChartData("showValues",true)
            self.refreshChart();
        } else {
            self.showValues(false);
			saveChartData("showValues",false)
            self.refreshChart();
        }
    });
	this.showLegend.subscribe(function (val) {
		if(self.preference){
			self.preference.showLegend = val;
		}
        if (val) {
            self.showLegend(true);
			saveChartData("showLegend",true)
            //self.refreshChart();
        } else {
            self.showLegend(false);
			saveChartData("showLegend",false)
            //self.refreshChart();
        }
		self.plotAccordingToChoices(true);
		self.initializePresentation(true);
    });

    this.selectedChartType.subscribe(function (selectedType) {
        if (!self.model || !self.chartInitialized) return;
        self.preference = JSON.parse(self.model.get('chartConfig'));
        if (self.plot) {
            //self.plot.shutdown();
        }
        if (self.dataTable) {
            self.dataTable.destroy(true);
        }
        //d3.select("#placeholder" + self.encodeId + ' svg').remove();
        self.maxUnitsPermitted = selectedType === "Stacked bars" || selectedType === "Stacked columns" ? 1 : 2;
        if (self.maxUnitsPermitted === 1) {
            var defaultUnitsArray = [];
            var units = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:checked");
            if (units.length > 0) {
                defaultUnitsArray.push(units[0].value);
                var label1 = window.utils.restoreEscapeCharacters(units[0].value);
                $('#yaxis' + self.encodeId).multiselect('select', label1);
                if (units.length > 1) {
                    var label2 = window.utils.restoreEscapeCharacters(units[1].value);
                    $('#yaxis' + self.encodeId).multiselect('deselect', label2);
                }
                self.preference.yAxisSelected = defaultUnitsArray;
                self.model.set('chartConfig', JSON.stringify(self.preference));
                var checkedAtr = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:checked");
                var uncheckedAtr = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:not(:checked)");
                if (checkedAtr.length >= self.maxUnitsPermitted) {
                    uncheckedAtr.attr("disabled", true);
                    uncheckedAtr.parent().attr("disabled", true);
                    uncheckedAtr.parent().css('cursor', "no-drop");
                } else {
                    uncheckedAtr.attr("disabled", false);
                    uncheckedAtr.parent().attr("disabled", false);
                    uncheckedAtr.parent().css('cursor', "pointer");
                }
            }
        } else {
            var checkedAtr = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:checked");
            var uncheckedAtr = $('#yaxis' + self.encodeId).siblings('.btn-group').children('.multiselect-container').find("input:not(:checked)");
            if (checkedAtr.length >= self.maxUnitsPermitted) {
                uncheckedAtr.attr("disabled", true);
                uncheckedAtr.parent().attr("disabled", true);
                uncheckedAtr.parent().css('cursor', "no-drop");
            } else {
                uncheckedAtr.attr("disabled", false);
                uncheckedAtr.parent().attr("disabled", false);
                uncheckedAtr.parent().css('cursor', "pointer");
            }
        }
        if (selectedType === "Table") {
            $('#groupByRow' + self.encodeId).hide();
            $('#alignScales' + self.encodeId).hide();
            $('#units' + self.encodeId).hide();
            $('#showValues' + self.encodeId).hide();
			$('#showLegend' + self.encodeId).hide();
            //            	$('#printChart'+self.encodeId).hide();
            self.resizeChartWithGridResize(true);
        } else if (selectedType === "Radar" || selectedType === "Polar" || selectedType === "Spiderweb") {
            $('#groupByRow' + self.encodeId).show();
            $('#alignScales' + self.encodeId).hide();
            $('#units' + self.encodeId).hide();
            $('#showValues' + self.encodeId).show();
			$('#showLegend' + self.encodeId).show();
            self.resizeChartWithGridResize(true);
        } else {
            $('#groupByRow' + self.encodeId).show();
            $('#alignScales' + self.encodeId).show();
            $('#units' + self.encodeId).show();
            $('#showValues' + self.encodeId).show();
			$('#showLegend' + self.encodeId).show();
            //  				$('#printChart'+self.encodeId).show();
        }
        if (self.model) {
            var gradeOnlyCount = 0;
            _.each(self.preference.selectedValueObjs, function (option) {
                if (option.gradeOnly) {
                    gradeOnlyCount++;
                }
            });
            if (selectedType === 'Radar' && self.groupByPhase && ((self.preference.selectedValueObjs.length * self.preference.selectedObservationObjs.length) - gradeOnlyCount) < 3) {
                bootbox.alert(DataManager.getDataManager().get('localeManager').get('radarMessage'));
                self.selectedChartType('Curves');
            } else if (selectedType === 'Radar' && self.groupByScenario && ((self.preference.selectedValueObjs.length * self.preference.selectedAlternativeObjs.length) - gradeOnlyCount) < 3) {
                bootbox.alert(DataManager.getDataManager().get('localeManager').get('radarMessage'));
                self.selectedChartType('Curves');
            } else {
                if (selectedType === "Table" || selectedType === "Spiderweb" || selectedType === "Polar") {
                    $("#groupBy" + self.encodeId + " option[value='Time']").attr('disabled', 'disabled');
                } else {
                    $("#groupBy" + self.encodeId + " option[value='Time']").removeAttr('disabled');
                }
                self.selectedChartType(selectedType);
                self.refreshChart();
            }
        }
		saveChartData("selectedChartType",selectedType)
    });
    this.selectedGroupType.subscribe(function (val) {
        if (!self.model || !self.chartInitialized) return;
        if (val === "Phase/Alternative") {
            self.groupByPhase = true;
            self.timeBars(false)
        } else if (val === "Scenario") {
            self.groupByScenario = true;
            self.timeBars(false)
        } else if (val === "Time") {
            self.groupByTime = true;
            self.timeBars(true);
        }
        var gradeOnlyCount = 0;
        _.each(self.preference.selectedValueObjs, function (option) {
            if (option.gradeOnly) {
                gradeOnlyCount++;
            }
        });
        if (val === "Time") {
            $("#selectChartType" + self.encodeId + " option[value='Radar']").attr('disabled', 'disabled');
            $("#selectChartType" + self.encodeId + " option[value='Table']").attr('disabled', 'disabled');
            $("#selectChartType" + self.encodeId + " option[value='Spiderweb']").attr('disabled', 'disabled');
            $("#selectChartType" + self.encodeId + " option[value='Polar']").attr('disabled', 'disabled');
        } else {
            $("#selectChartType" + self.encodeId + " option[value='Radar']").removeAttr('disabled');
            $("#selectChartType" + self.encodeId + " option[value='Table']").removeAttr('disabled');
            $("#selectChartType" + self.encodeId + " option[value='Spiderweb']").removeAttr('disabled');
            $("#selectChartType" + self.encodeId + " option[value='Polar']").removeAttr('disabled');
        }
        if (self.selectedChartType() === 'Radar' && self.groupByPhase && ((self.preference.selectedValueObjs.length * self.preference.selectedObservationObjs.length) - gradeOnlyCount) < 3) {
            bootbox.alert(DataManager.getDataManager().get('localeManager').get('radarMessage'));
            self.selectedChartType('Curves');
        } else if (self.selectedChartType() === 'Radar' && self.groupByScenario && ((self.preference.selectedValueObjs.length * self.preference.selectedAlternativeObjs.length) - gradeOnlyCount) < 3) {
            bootbox.alert(DataManager.getDataManager().get('localeManager').get('radarMessage'));
            self.selectedChartType('Curves');
        }
		saveChartData("selectedGroupType",val)
        self.refreshChart();
		});
		this.selectedIntervalValue.subscribe(function (val) {
			if ($('#placeholder' + self.encodeId) && $('#placeholder' + self.encodeId).length > 0) {
				self.selectedIntervalValue(val);
				saveChartData("savedInterval",val)
				self.refreshChart();
			}
		});
	}
    //#endCustomMethods
	static getInstance = function(model,options){
			var view = new DashboardValuesChart2ViewModel(model, options);
			//view.init(model, options);
			return view;

	};
}
path.DashboardValuesChart2ViewModel = DashboardValuesChart2ViewModel;