import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { PackageReference as PlanPackageReference } from './PackageReference'
import { PhaseMixin } from './PhaseMixin'
import { ScenarioPathStep } from './ScenarioPathStep'
import { PeriodKind, ProjectionMinPeriods, ProjectionRecomendPeriods } from '../vdml/PeriodKind'
import { daysPer4Weekly, daysPerWeekly } from '../../../../utils'
import { PeriodDataset } from './PeriodDataset'
import { ValueType } from '../vdml/ValueType'
import { addQuarters , addMonths, add, addDays, format, lastDayOfQuarter, lastDayOfMonth, lastDayOfYear} from 'date-fns'


var path = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
export class Phase2Mixin {
    getMixinClass() {
        return Phase2Mixin;
    };

    static getDialogViewProperties(type) {
        if (type == "PhaseDetails") {
            return {
                templatePath: "views/transformation/views/properties/PhaseDetailsTemplate.html",
                templateName: "PhaseDetailsTemplate",
                viewTypeStr: "appviews/transformation/views/properties/PhaseDetails2ViewModel",
                tabId: "PhaseDetails2View",
                tabName: "Phase"
            }
        } else {
            return PhaseMixin.getDialogViewProperties(type);
        }
    }

   static calcPhaseStartDate(periodKind, planStartDate, totalPeriods) {
        // totalPeriods is the sum of periods of all phase which are before the required phase
        let startDate = null;
        if (periodKind === PeriodKind.symbols()[0].name) {
            startDate = add(planStartDate, {
                years: totalPeriods,
            });
        } else if (periodKind === PeriodKind.symbols()[1].name) {
            startDate = addQuarters(planStartDate, totalPeriods);
        } else if (periodKind === PeriodKind.symbols()[2].name) {
            startDate = addMonths(planStartDate, totalPeriods);
        } else if (periodKind === PeriodKind.symbols()[3].name) {
            startDate = addDays(planStartDate, ((totalPeriods) * daysPer4Weekly));
        } else if (periodKind === PeriodKind.symbols()[4].name) {
            startDate = addDays(planStartDate, ((totalPeriods) * daysPerWeekly));
        }
        return format(startDate, 'MM/dd/yyyy');
    }
    
    static calcPhaseEndDate(periodKind, startDate, noOfPeriods) {
        let endDate = null;
        if (periodKind === PeriodKind.symbols()[0].name) {
            endDate = lastDayOfYear(add(startDate, {
                years: noOfPeriods - 1,
            }));
        } else if (periodKind === PeriodKind.symbols()[1].name) {
            endDate = lastDayOfQuarter(addQuarters(startDate, noOfPeriods - 1));
        } else if (periodKind === PeriodKind.symbols()[2].name) {
            endDate = lastDayOfMonth(addMonths(startDate, noOfPeriods - 1));
        } else if (periodKind === PeriodKind.symbols()[3].name) {
            endDate = addDays(startDate, ((noOfPeriods) * daysPer4Weekly) - 1);
        } else if (periodKind === PeriodKind.symbols()[4].name) {
            endDate = addDays(startDate, ((noOfPeriods) * daysPerWeekly - 1));
        }
        return format(endDate, 'MM/dd/yyyy');
    }

    getUsePreviousIds(phase){
		var usePreviousId = [];
        return usePreviousId;
    }

    updateStepAlt(altId){
        var self = this;
        const phaseId = self.get('id');
        const plan = self.get('phaseOwner');
        const scenarioId = plan.get("defaultScenario");
        const planScenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
        const stepData = planScenario.get('step').models.find((obj) => obj.get('phaseId') === phaseId);
        stepData.set('alternativeId', altId);
    }

    handlePrimaryChange(currentAltenative,newPrimary,callback){
		var self = this;
        const altId = newPrimary.id;
		self.updatePrimaryReferences(currentAltenative,newPrimary,function(altId){
            self.updateStepAlt(altId);
            callback();
        });
	};

    copyPlanScenario(currentAltModel,measurementsCopied,callback){
        var clonedFromAltId = currentAltModel.get('clonedFrom');
        var cloneAlt = clonedFromAltId ? window.utils.getElementModel(clonedFromAltId,['transformation.Alternative']) : null;//self.get('phaseAlternative').findWhere({'id':clonedFromAltId});
		if(cloneAlt){
            var altValues = cloneAlt.get("alternativeValues");
            altValues.forEach(val =>{
                currentAltModel.get("alternativeValues").add(val);
            });
        }
		callback();
	};

    deletePhaseAndStep(callback) {
        var self = this;
        const phaseId = self.get('id');
        const plan = self.get('phaseOwner');
        const scenarioId = plan.get("defaultScenario");
        const planScenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
        const sortedDataset = planScenario.getSortedDataSets(plan);
        const deletingStepData = planScenario.get('step').models.find((obj) => obj.get('phaseId') === phaseId);
        self.updateStep(deletingStepData, planScenario);
        const executionScenarioId = planScenario.get("defaultExecutionScenario");
        const executionScenario = Backbone.Relational.store.getObjectByName('transformation.ScenarioExecution').find({ id: executionScenarioId });
        const phaseNoOfPeriods = parseInt(deletingStepData.get('noPeriods'));
        deletingStepData.destroy();
        for (let index = 0; index < phaseNoOfPeriods; index++) {
            let inputDataset = executionScenario.get("input").findWhere(sortedDataset[sortedDataset.length - index - 1]);
            inputDataset.destroy();
        }
        callback();
    };

    updateStep(deletingStepData, planScenario){
        const previousStep = deletingStepData.get('previous');
        let nextStep = deletingStepData.get('next');
        if(nextStep == null) {
            previousStep.set('next', null);
            return;
        }
        let nextStepStartPeriod = 1;
        if(previousStep != null){
            let previousStepNoOfPeriods = parseInt(previousStep.get('noPeriods'));
            let previousStepStartPeriod = parseInt(previousStep.get('startPeriod'));
            nextStepStartPeriod = previousStepStartPeriod + previousStepNoOfPeriods;
            previousStep.set('next', nextStep);
        } else {
            planScenario.set('firstStep', nextStep);
        }
        nextStep.set('previous', previousStep);
        while(nextStep != null){
            let nextStepNoOfPeriods = parseInt(nextStep.get('noPeriods'));
            if(nextStepNoOfPeriods == 0) nextStep.set('startPeriod', 0);
            else nextStep.set('startPeriod', nextStepStartPeriod);
            nextStepStartPeriod = nextStepStartPeriod + nextStepNoOfPeriods;
            nextStep = nextStep.get('next');
        }
    }

    createTicketForUsePrevious(callback) {
        callback();
    }
    fixUsePreviousRelationsInNextPhase(currentPrimaryAlt,nextPhase,isolation){

    }
    movePackagePlanRelationsToCopyAlternative(altScenario,ownedPackageList){

    }
    correctRelationsGoneWrongWithClone(copiedPackages,copyAlt,alternative){

    }

    copypValues(measurementsCopied, vdmlPackages, callback) {
        callback(null);
    }

    updateProjection(projCallback){
        var self = this;
        var currentAltModel = self.get('primary');
        //var clonedFromAltId = currentAltModel.get('clonedFrom');
        var baseLineVals = [];
        var timeSeriesVals = [];
        var currentPlan = currentAltModel.getNestedParent();
        var scenarioData = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: currentPlan.get("defaultScenario") });
        var step = scenarioData.get("step").findWhere({alternativeId: currentAltModel.id});
        debugger
        var previousStep = step ? step.get('previous') : null;
        var previousAltId = previousStep ? previousStep.get('alternativeId'):null;
        var cloneFromAlt = previousAltId ? window.utils.getElementModel(previousAltId,['transformation.Alternative']) : null;
        var defaultExecutionScenario = window.utils.getElementModel(scenarioData.get("defaultExecutionScenario"),['transformation.ScenarioExecution']);
        var dataSets = cloneFromAlt ? cloneFromAlt.getPhaseAlternativeSteps(scenarioData) : [];
        var lastYearPeriod = dataSets[dataSets.length - 1];
        if(lastYearPeriod && defaultExecutionScenario){
            var totalPlanPeriods = step.get('noPeriods');
            var periodKind = currentPlan.get("periodKind");
            var timeSeriesQualified = self.validateTimeSeries(previousStep,periodKind,scenarioData,previousAltId);
            var timeSeriesDatasets = timeSeriesQualified.dataSets.length > 0 ? timeSeriesQualified.dataSets:[];
            var altValues = currentAltModel.get("alternativeValues");
            function getQuantity(valId,period,year,periodKind,stepAltId){
                var pDataset = defaultExecutionScenario.get("input").findWhere({period: period,year: year,periodKind : periodKind});
                var isPlanValue = valId.includes(window.plansKey);
                var qty = pDataset ? pDataset.get(valId) : null;
                if(stepAltId && !isPlanValue && !qty){
                    valId = stepAltId + window.utils.getSuffix(valId);
                    qty = pDataset ? pDataset.get(valId) : null;
                }
                return qty;
            }
            altValues.forEach(val =>{
                if(val.get("valueType") == ValueType.Atomic){
                    var qtyMissing = false;
                    if(timeSeriesDatasets.length > 0){
                        var timeSeriesList = [];
                        for(var i=0;i<timeSeriesDatasets.length;i++){
                            var qty = getQuantity(val.id,timeSeriesDatasets[i].period,timeSeriesDatasets[i].year,periodKind,timeSeriesDatasets[i].altId);
                            if(qty){
                                timeSeriesList.push(parseFloat(qty));
                            } else {
                                qtyMissing = true;
                                break;
                            }
                            if(timeSeriesDatasets.length-1 == i){
                                var timeParam = {'season_length':timeSeriesDatasets.length,'periods':timeSeriesDatasets.length,'time_series':timeSeriesList,'forecast_periods':totalPlanPeriods}
                                timeSeriesVals.push({'id':val.id, 'param':timeParam});
                            }
                        }
                    } else {
                        var qty = getQuantity(val.id,lastYearPeriod.period,lastYearPeriod.year,periodKind);
                        if(qty){
                            var param = val.getProjectionData(parseFloat(qty),totalPlanPeriods);
                            baseLineVals.push({'id':val.id, 'param':param});
                        }
                    }
                    if(qtyMissing && timeSeriesDatasets.length > 0){
                        var qty = getQuantity(val.id,lastYearPeriod.period,lastYearPeriod.year,periodKind);
                        if(qty){
                            var param = val.getProjectionData(parseFloat(qty),totalPlanPeriods);
                            baseLineVals.push({'id':val.id, 'param':param});
                        }
                    }
                }
            });
        }      
        
        if(baseLineVals.length > 0 || timeSeriesVals.length > 0){
            scenarioData.extrapolateBaseline(baseLineVals,currentAltModel,function(){
                scenarioData.extrapolateTimeSeries(timeSeriesVals,currentAltModel,projCallback);
            });
            //projCallback();
        } else {
            projCallback();
        }
    }

    validateTimeSeries(previousStep,periodKind,scenarioData,previousAltId) {
        let stepObj = previousStep;
        let periodsUntillCurPhase = 0;
        while (stepObj) {
            const noOfPeriods = stepObj.get('noPeriods');
            periodsUntillCurPhase = periodsUntillCurPhase + parseInt(noOfPeriods, 10);
            stepObj = stepObj.get("previous");
        }
        var timeSeriesQualified = {};
        //var currentDataSets = currentAltModel.getPhaseAlternativeSteps(scenarioData);
        //var existingPeriods = periodsUntillCurPhase - currentDataSets.length;
        var minProjPeriods = 0;
        var recommendProjPeriods = 0;
        if(periodKind == PeriodKind.symbols()[0].name){
            minProjPeriods = ProjectionMinPeriods.Yearly;
            recommendProjPeriods = ProjectionRecomendPeriods.Yearly
        } else if(periodKind == PeriodKind.symbols()[1].name){
            minProjPeriods = ProjectionMinPeriods.Quarterly;
            recommendProjPeriods = ProjectionRecomendPeriods.Quarterly
        } else if(periodKind == PeriodKind.symbols()[2].name){
            minProjPeriods = ProjectionMinPeriods.Monthly;
            recommendProjPeriods = ProjectionRecomendPeriods.Monthly
        } else if(periodKind == PeriodKind.symbols()[3].name){
            minProjPeriods = ProjectionMinPeriods.fourWeekly;
            recommendProjPeriods = ProjectionRecomendPeriods.fourWeekly
        } else if(periodKind == PeriodKind.symbols()[4].name){
            minProjPeriods = ProjectionMinPeriods.Weekly;
            recommendProjPeriods = ProjectionRecomendPeriods.Weekly
        }
        if(periodsUntillCurPhase >= minProjPeriods){
            var timeSeriesDatasets = [];
            function addStepDatasets(dataSets,altId){
                for(var j=0; j < dataSets.length; j++){
                    if(minProjPeriods == timeSeriesDatasets.length){
                        break;
                    }
                    dataSets[j].altId = altId;
                    timeSeriesDatasets.push(dataSets[j])
                }
            }
            var stepChange = previousStep;//.get('previous');
            while(stepChange){
                var stepAltId = stepChange.get('alternativeId');
                var stepAlt = window.utils.getElementModel(stepAltId,['transformation.Alternative']);
                var stepDataSets = stepAlt.getPhaseAlternativeSteps(scenarioData);
                addStepDatasets(stepDataSets,stepAltId);
                if(minProjPeriods == timeSeriesDatasets.length){
                    break;
                }
                stepChange = stepChange.get('previous');
            }
            //timeSeriesQualified.recommendProjPeriods;//do later
            timeSeriesQualified = {'minProjPeriods':minProjPeriods,'dataSets':timeSeriesDatasets,'recommendProjPeriods':recommendProjPeriods};
        }

        return timeSeriesQualified;
    }

    createIntermediatePhaseStep(selectedPhaseModel, creatingPreviousPhase) {
        var self = this;
        const newPhaseId = self.get('id');
        const alt = self.get("primary") ? self.get("primary") : self.get("phaseAlternative").models[0];
        const plan = self.get('phaseOwner');
        const scenarioId = plan.get("defaultScenario");
        const planScenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
        const executionScenarioId = planScenario.get("defaultExecutionScenario");
        const executionScenario = Backbone.Relational.store.getObjectByName('transformation.ScenarioExecution').find({ id: executionScenarioId });
        const stepsData = planScenario.get('step').models;
        const selectedPhaseModelId = selectedPhaseModel.get('id');
        const selectedPhaseModelStep = stepsData.find((obj) => obj.get('phaseId') === selectedPhaseModelId);
        if (creatingPreviousPhase) {
            const oldPreviosStep = selectedPhaseModelStep.get('previous');
            const newPhaseStep = new ScenarioPathStep({ id: window.guidGenerator(), stepOwner: planScenario, phaseId: newPhaseId, alternativeId: alt.id, startPeriod: 0, noPeriods: 0, previous: oldPreviosStep, next: selectedPhaseModelStep });
            planScenario.get("step").add(newPhaseStep);
            selectedPhaseModelStep.set("previous", newPhaseStep);
            if (oldPreviosStep) {
                oldPreviosStep.set("next", newPhaseStep);
            } else {
                planScenario.set("firstStep", newPhaseStep);
            }
        } else {
            let noOfPeriods = 0;
            let startPeriod = 0;
            const oldNextStep = selectedPhaseModelStep.get('next');
            if(oldNextStep == null){
                noOfPeriods = 1;
                const totalNoOfPeriods = stepsData.reduce((accumulator, currentObject) => {
                    return accumulator + currentObject.get('noPeriods');
                }, 0);
                startPeriod = 1 + totalNoOfPeriods;
                plan.createDefaultDataSets(planScenario.get('startTime'), startPeriod, noOfPeriods, plan.get('periodKind'), executionScenario);
            }
            const newPhaseStep = new ScenarioPathStep({ id: window.guidGenerator(), stepOwner: planScenario, phaseId: newPhaseId, alternativeId: alt.id, startPeriod: startPeriod, noPeriods: noOfPeriods, previous: selectedPhaseModelStep, next: oldNextStep });
            planScenario.get("step").add(newPhaseStep);
            selectedPhaseModelStep.set("next", newPhaseStep);
            if (oldNextStep) {
                oldNextStep.set("previous", newPhaseStep);
            }
        }
    }
    getCalculationChangeType(operationType,change){
        if(operationType == "add" || operationType == "destroy"){
            return 0;
        }else{
            if(change.previousPhase || change.nextPhase){
                return 0;
            }
        }
	}
    getCalculationChangeArtifact(operationType){
        var dataManager = DataManager.getDataManager();
        if(!dataManager.get("defaultScenario")){
            return undefined;
        }
        return dataManager.get("artifactsDocuments")[dataManager.get("defaultScenario").id].artifactId;
	}

}

path.Phase2Mixin = Phase2Mixin;
utils.customExtendClass(Phase2Mixin, new PhaseMixin());

