import {ACTION_TRANSITION, SET_TRANSITION, RULE_TRANSITION, ATOM_TRANSITION, EPSIOLON_TRANSITION, PRECEDENCE_PREDICATE} from './antlr4Constants';

/**
 * A parser that provides suggestions based on ANTLR transitions and grammar rules.
 * This class analyzes the current parsing state and available transitions to generate
 * contextually appropriate suggestions for code completion.
 */
class SuggestionParser {
    /**
     * Initiates the suggestion generation process for the current parsing context.
     * 
     * @param {string[]} ruleNames - Array of grammar rule names
     * @param {string[]} symbolicNames - Array of symbolic token names
     * @param {string[]} literalNames - Array of literal token names
     * @param {Object} state - Current parsing state object
     * @param {Object} tokens - Token stream interface with cursor management
     * @param {Object} collector - Collector object for gathering suggestions
     * @param {Array} inputRuleStack - Stack of previously processed tokens
     * @returns {Array} Collection of valid suggestions for the current context
     */
    suggest(
        state,
        tokens,
        collector,
        inputRuleStack){
            return this.process(
                state,
                tokens,
                collector,
                new Set(),
                inputRuleStack
            );
        }

    /**
     * Recursively processes the parsing state to generate suggestions.
     * Handles different types of ANTLR transitions including epsilon, action, atom, set, and rule transitions.
     * 
     * @param {string[]} ruleNames - Array of grammar rule names
     * @param {string[]} symbolicNames - Array of symbolic token names
     * @param {string[]} literalNames - Array of literal token names
     * @param {Object} state - Current parsing state object
     * @param {Object} tokens - Token stream interface with cursor management
     * @param {Object} collector - Collector object for gathering suggestions
     * @param {Set} stateAlreadyVisited - Set of already processed state numbers to prevent cycles
     * @param {Array} inputRuleStack - Stack of previously processed tokens
     * @returns {Array} Collection of valid suggestions for the current context
     * 
     * @private
     */
    process(
        state,
        tokens,
        collector,
        stateAlreadyVisited,
        inputRuleStack
    ) {
        // Check if the current token position matches the caret (cursor) position
        // When true, it indicates we're at the position where suggestions should be generated
        // When false, we're still processing tokens before the cursor position
        const atCaret = tokens.atCaret();

        for (let i = 0; i < state.transitions.length; i++) {
            const transition = state.transitions[i];

            // Handle epsilon transitions, action transitions, and precedence predicates
            if (transition.serializationType === EPSIOLON_TRANSITION || 
                transition.serializationType === ACTION_TRANSITION || 
                transition.serializationType === PRECEDENCE_PREDICATE) {
                if (!stateAlreadyVisited.has(transition.target.stateNumber)) {
                    stateAlreadyVisited.add(transition.target.stateNumber);
                    const newPassed = new Set(stateAlreadyVisited);
                    this.process(
                        transition.target,
                        tokens,
                        collector,
                        newPassed,
                        inputRuleStack
                    );
                }
            } 
            // Handle atomic transitions (single token matches)
            else if (transition.serializationType === ATOM_TRANSITION) {
                this.handleAtomTransition(atCaret,transition,state,tokens,collector,stateAlreadyVisited,inputRuleStack)
            } 
            // Handle set transitions (token ranges)
            else if (transition.serializationType === SET_TRANSITION) {
                this.handleSetTransition(atCaret,transition,state,tokens,collector,stateAlreadyVisited,inputRuleStack)
            } 
            // Handle rule transitions
            else if (transition.serializationType ===  RULE_TRANSITION) {
                if (!stateAlreadyVisited.has(transition.target.stateNumber)) {
                    stateAlreadyVisited.add(transition.target.stateNumber);
                    const newPassed = new Set();
                    this.process(
                        transition.target,
                        tokens,
                        collector,
                        newPassed,
                        []
                    );
                }
            }
        }
        return collector.getCollected();
    }

    handleAtomTransition(atCaret,
        transition,
        state,
        tokens,
        collector,
        stateAlreadyVisited,
        inputRuleStack){
            const nextToken = tokens.next();
                // When at caret position, collect suggestions for this transition
                if (atCaret) {
                        collector.collect(transition.label_,inputRuleStack, state);
                } 
                // When not at caret, continue processing if token matches
                else if (nextToken?.type === transition.label_) {
                    inputRuleStack.push(nextToken.text);
                    const newPassed = new Set(stateAlreadyVisited);
                    this.process(
                        transition.target,
                        tokens.move(),
                        collector,
                        stateAlreadyVisited,
                        inputRuleStack
                    );
                    tokens.moveLeft();
                }
        }

        handleSetTransition(atCaret,
            transition,
            state,
            tokens,
            collector,
            stateAlreadyVisited,
            inputRuleStack){
                const nextToken = tokens.next();
                const intervalSet = transition.label.intervals

                for (const symbol of intervalSet) {
                    // When at caret position, collect suggestions for all tokens in the range
                    if (atCaret) {
                        for (let transitionTokenType = symbol.start; transitionTokenType <= symbol.stop; transitionTokenType++) {
                            collector.collect(transitionTokenType,inputRuleStack, state);
                        }
                    }
                    // When not at caret, continue processing if token is in range
                    else{
                        if(symbol.start <= nextToken.type && nextToken.type <= symbol.stop){
                                inputRuleStack.push(nextToken.text);
                                const newPassed = new Set(stateAlreadyVisited);
                                this.process(transition.target,tokens.move(),collector,newPassed, inputRuleStack);
                                tokens.moveLeft();
                        }
                    }
                }
            }
}

export {SuggestionParser};