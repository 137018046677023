import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import { ScenarioMeasurement } from "../transformation/ScenarioMeasurement";
import {DataManager} from '../../../com/vbee/data/DataManager'
import { ValueElementMixin } from './ValueElementMixin'
import { ValueElementContext } from "../vdml/ValueElementContext";
import { Expression } from "../vdml/Expression";
import {MeasurableElementMixin} from './MeasurableElementMixin'
import {VDMLCanvasElementMixin} from './VDMLCanvasElementMixin'
import {ScenarioValueConstraints} from '../transformation/ScenarioValueConstraints'
import { Unit } from './Unit'

import { ValueType } from './ValueType'
	
var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
export class ValueElement2Mixin{

    static getMixinRelations(){
		let relations = ValueElementMixin.getMixinRelations();
		relations.splice(relations.findIndex(item => item.key === "valueMeasurement"), 1)
        return _.union(relations,[
            {
                type :Backbone.HasMany,
                containingClass:"vdml_ValueElement",
                key:"context",
                relatedModel:"vdml.ValueElementContext",
                reverseRelation: {
                    key:"contextOwner",
                    type :Backbone.HasOne,
                    includeInJSON:"id"
                }
            },
			{
                type :Backbone.HasOne,
                containingClass:"vdml_ValueElement",
                key:"unit",
                relatedModel:"vdml.Unit",
				includeInJSON: Backbone.Model.prototype.idAttribute
            },
			{
				type :Backbone.HasMany,
				containingClass:"vdml_ValueElement",
				key:"aggregatedToContext",
				relatedModel:"vdml.ValueElementContext",
				includeInJSON: Backbone.Model.prototype.idAttribute,
			}
        ])
    }

	static getDialogViewProperties(type) {
        if (type && type === "AggregationView") {
            return {
                templatePath: "views/vdml/views/properties/ValueAggregationProperties2Template.html",
                templateName: "ValueAggregationProperties2Template",
                viewTypeStr: "appviews/vdml/views/properties/ValueAggregation2ViewModel",
                tabId: "ValueAggregation2View",
                tabName: "ValueAggregation"
            }
        }
    }

	getParent(){
		var container;
		if(!container){
            container = this.get("percentageWeightOwner") ? this.get("percentageWeightOwner") : this.previousAttributes().percentageWeightOwner;
            if(container){
                return container;
            }
        }
		if(!container){
            container = this.get("satisfactionLevelOwner") ? this.get("satisfactionLevelOwner") : this.previousAttributes().satisfactionLevelOwner;
            if(container ){
                return container;
            }
        }
        if(!container){
            container = this.get("recipientOpinionOwner") ? this.get("recipientOpinionOwner") : this.previousAttributes().recipientOpinionOwner;
            if(container){
                return container;
            }
        }
		return this;
	}
	static getProperties(){
		let properties = ValueElementMixin.getProperties();
		properties.push({ name: "valueType", type: "EString", defaultValue: "Atomic", containingClass: "vdml_VdmlElement" });
		properties.push({ name:"isNominal", type:"EBoolean", defaultValue:false, containingClass:"vdml_VdmlElement"});
		return properties;
	}
	static getSubModelTypes(){
		var ret = ValueElementMixin.getSubModelTypes();
        ret['vdml_ProjectionProfile'] = 'vdml.ProjectionProfile';
		ret['vdml_PolynomialCoefficient'] = 'vdml.PolynomialCoefficient';
		ret['vdml_SeasonalFactor'] = 'vdml.SeasonalFactor';
		ret['vdml_ValueElementContext'] = 'vdml.ValueElementContext';
		return ret;
	}
    static getCumulativeMixinRelations(){
        if (!ValueElement2Mixin.cummulativeRelations) {
            ValueElement2Mixin.cummulativeRelations = _.union(ValueElement2Mixin.getMixinRelations()
                ,MeasurableElementMixin.getCumulativeMixinRelations()
                ,VDMLCanvasElementMixin.getCumulativeMixinRelations()
            );
        }
        return ValueElement2Mixin.cummulativeRelations.slice();
    }

	onDestroyElement(model) {
		//TODO remove context
	}

	getProjectionData(qty,totalPlanPeriods){
        var self = this;
        var projectionProfile = self.get("projectionProfile");
        let pattern = projectionProfile && projectionProfile.get('trendModel') ? projectionProfile.get('trendModel') : "Linear"
        var params = {baseline_quantity : parseInt(qty), periods:totalPlanPeriods, pattern:String(pattern).toLocaleLowerCase()}

        if(projectionProfile && projectionProfile.get('seasonalFactors').length > 0){
            var seasonalFactors = projectionProfile.get('seasonalFactors').models.map((d)=>{
                return d.get('quantity') ?  parseInt(d.get('quantity')) : 0
            })
            params =  {...params,seasonal_factors:seasonalFactors}
        }
        if(projectionProfile && projectionProfile.get('trendModel')==="Linear"){
            params = {...params,growth_rate: projectionProfile.get('rateFactor') ? projectionProfile.get('rateFactor') : 0}
        }else if(projectionProfile && projectionProfile.get('trendModel') ==="Exponential"){
            params = {...params,growth_rate:projectionProfile.get('rateFactor')?projectionProfile.get('rateFactor'):0}

        }else if(projectionProfile && projectionProfile.get('trendModel') ==="Logarithmic"){
            params = {...params,scaling_factor:projectionProfile.get('rateFactor')?projectionProfile.get('rateFactor'):0}

        }else if(projectionProfile && projectionProfile.get('trendModel') ==="Power"){
            params = {...params,exponent:projectionProfile.get('rateFactor')?projectionProfile.get('rateFactor'):0}

        }else if(projectionProfile && projectionProfile.get('trendModel') ==="Polynomial"){
            params = {...params,degree:projectionProfile.get('rateFactor')?projectionProfile.get('rateFactor'):0}

            if(projectionProfile.get('coefficients').length > 0){
                var coefficients = projectionProfile.get('coefficients').models.map((d)=>{
                    return parseInt(d.get('coefficient'))
                })
                params = {...params,coefficients:coefficients}
            }
        }else {
            params = {...params,growth_rate: 1}
        }
        return params;
    }

	getValueSlipName(alt, customName, customPeriod, customYear){
		var self = this;
		//TODO alt scenario fix
		var dm = DataManager.getDataManager();
		const requiredPeriod = customPeriod ? customPeriod : dm.get('currentPeriod');
		const requiredYear = customYear ? customYear : dm.get('currentYear');
		var name = customName ? customName : self.get('name');
		var defaultExecutionScenaroId = dm.get("defaultScenario").get("defaultExecutionScenario");
		var defaultExecutionScenario = window.utils.getElementModel(defaultExecutionScenaroId,['transformation.ScenarioExecution']);
		var periodDataset;
		if(self.get("valueType") == ValueType.Atomic){
			periodDataset = defaultExecutionScenario.get("input").findWhere({period: requiredPeriod, periodKind : dm.get('currentPlan').get("periodKind"), year: requiredYear});
		} else {
			periodDataset = defaultExecutionScenario.get("result").findWhere({period: requiredPeriod, periodKind : dm.get('currentPlan').get("periodKind"), year: requiredYear});	
		}
        if(periodDataset && periodDataset.get(self.id)){
		    var qty = periodDataset.get(self.id);
		    var unit = self.get('unit');
		    var significantDecimals = unit ? unit.get('significantDecimals') : 2;
		    var value = window.utils.getRoundedValues(qty, significantDecimals);
		    var unitName = unit ? unit.get('name') : null;
		    if (unitName) {
			    name += " (" + value + " " + unitName + ")";
		    }
        }
		return name;
	}

	getUnitRounding(){
		let self = this;
		if(self.get("unit")){
			return self.get("unit").get("roundingDigits");
		}else{
			let definition = self.get("definition");
			if(definition){
				return definition.get("unit").get("roundingDigits");
			}
		}
	}
	getValueContext(altId){
		var contexts = this.get("context").models;
        var context = null;
        for(var i=0;i<contexts.length;i++){
            if(contexts[i].get("alternative") && contexts[i].get("alternative").id == altId){
                context = contexts[i];
                break;
            }
        }
		return context;
	}

	getFromAggregations(altId){
		var self = this;
		var components = [];
		var packAltId = window.utils.getPrefix(self.getNestedParent().id);
		var context = self.getValueContext(altId);
		if(!context && self.get("valueType") == ValueType.Atomic && (packAltId == window.plansKey || altId == packAltId)){
			components = self.get('aggregatedFrom');
		}
		else if(context){
			components = context.get('aggregatedFromValue');
			if(/*(packAltId == window.plansKey || altId == packAltId) &&*/ components && components.length == 0){
				components = self.get('aggregatedFrom');
			}
		}else if(packAltId == window.plansKey || altId != packAltId){
			var alt = window.utils.getElementModel(altId,['transformation.Alternative']);
			var preAlt = null;
			if(alt.get('clonedFrom')){
				preAlt = window.utils.getElementModel(alt.get('clonedFrom'),['transformation.Alternative']);
			} else {
				if(alt.getParent().get("primary") == alt){
					preAlt = alt.getPreviousPhasePrimary();
				} else if(alt.getParent().get("master") !== alt){
					preAlt = alt.getParent().get("master");
				}
			}
			if(preAlt){
				return self.getFromAggregations(preAlt.id);
			}
		}
		return components;
	}

	getToAggregations(altId){
		var self = this;
		var components = new Backbone.Collection();
		var alt = window.utils.getElementModel(altId,['transformation.Alternative']);
		var contextColl = self.get('aggregatedToContext').where({'alternative':alt});
		if(!contextColl || contextColl.length == 0){
			components = self.get('aggregatedTo');
		}
		else {
			for(var i=0; i< contextColl.length; i++){
				components.add(contextColl.at(i).getParent());
			}
			var packAltId = window.utils.getPrefix(self.getNestedParent().id);
			if(packAltId == window.plansKey || altId != packAltId){
				var aggto = self.get('aggregatedTo');
				for(var i=0; i< aggto.length; i++){
					if(!aggto.at(i).getValueContext(altId)){
						components.add(aggto.at(i));
					}
				}
			}
		}
		return components;
	}

	getValueFormula(altId){
		var self = this;
		var formula;
		if(self.get('valueType') == ValueType.Atomic){
			return formula;
		}
		var context = self.getValueContext(altId);
		var packAltId = window.utils.getPrefix(self.getNestedParent().id);
        if(context){
            formula = context.get("formula");
        } else if(packAltId == window.plansKey || altId != packAltId){
			var alt = window.utils.getElementModel(altId,['transformation.Alternative']);
			var preAlt = null;
			if(alt.get('clonedFrom')){
				preAlt = window.utils.getElementModel(alt.get('clonedFrom'),['transformation.Alternative']);
			} else {
				if(alt.getParent().get("primary") == alt){
					preAlt = alt.getPreviousPhasePrimary();
				} else if(alt.getParent().get("master") !== alt){
					preAlt = alt.getParent().get("master");
				}
			}
			if(preAlt){
				return self.getValueFormula(preAlt.id);
			}
		}
		return formula;
	}

	getRoundingQty(qty){
		var self = this;
		var roundFactor = self.get("unit") ? self.get("unit").get("roundingDigits") : '4';
		var compQty = window.utils.getRoundedValues(qty, roundFactor);
		return compQty;
	}
	getSignificantQty(qty){
		var self = this;
		var sigFactor = self.get("unit") ? self.get("unit").get("significantDecimals") : '2';
		var compQty = window.utils.getRoundedValues(qty, sigFactor);
		return compQty;
	}
	addSatisfactionBaseTuple(component){
		var self = this;
		var obj = {};
		var baseTuples=[]
        obj.new = { seqNo: 0, id: self.get('id'), component: component, existing: false};
		baseTuples.push(obj);
		for(var i=0;i<baseTuples.length;i++) {
			if(!baseTuples[i].old && baseTuples[i].new) {
				var component = baseTuples[i].new.component;
				self.get('aggregatedFrom').add(component);
				if(component && !component.get('aggregatedTo').findWhere({id:self.get('id')})) {
					component.get('aggregatedTo').add(self);
				}
			}else if(baseTuples[i].old && !baseTuples[i].new){
				var component = baseTuples[i].old.component;
				var compCount = 0;
				for(var j=0;j<baseTuples.length;j++) {
					if(baseTuples[j].old && baseTuples[j].old.component === component && baseTuples[j].new){
						compCount++;
					}
				}
				if(compCount === 0) {
					this.get('aggregatedFrom').remove(component);
					if(component && component.get('aggregatedTo').findWhere({id:self.get('id')})) {
						component.get('aggregatedTo').remove(self);
					}
				}
			}else if(baseTuples[i].old && baseTuples[i].new){
				if(baseTuples[i].old.usePreviousAlternative != baseTuples[i].new.usePreviousAlternative){
					var comp = baseTuples[i].new.component;
					if(comp){
						comp.onAggregatedFromAdded(comp);
					}
				}
			}
		}
		
	};

	createExpression(context, valFormula,config,offset){
		var self = this;
        var expId = DataManager.getDataManager().guidGeneratorByOwner(self);
		if(config){
			config = JSON.stringify(config);
		}
        var exp = new Expression({id:expId,formulaOwner:context,offset:offset,expressionStr: valFormula,expressionConfig:config});
        context.set("formula",exp);
        return exp;
    }

    createValueElementContext(viewAlternative){
		var self = this;
        var contextId = DataManager.getDataManager().guidGeneratorByOwner(self);
        var context = new ValueElementContext({id:contextId,alternative:viewAlternative,contextOwner:self});
        self.get("context").add(context);
        return context;
    }

	createValueConstraints(valueConstraint,alt){
		var self = this
		if ((valueConstraint.maxBound) || (valueConstraint.minBound) || (valueConstraint.equalValue) || (valueConstraint.bigM)) {
			var scenario = DataManager.getDataManager().get('defaultScenario');
			var step = scenario.get("step").findWhere({alternativeId: alt.id});
			if(step){
				var scenarioValueConstraint = ScenarioValueConstraints.getInstance(self.get('id'),valueConstraint.maxBound,valueConstraint.minBound,valueConstraint.equalValue,valueConstraint.bigM,step);
				step.get('constraints').add(scenarioValueConstraint);
			}
		}
	}
	changeValFormula(model,previousName){
		var self = this;
		var aggrToValues = model.get('aggregatedTo').models;
		if(!aggrToValues){ return };
		var newVariableId = DataManager.getDataManager().htmlEscape(model);
		var oldVariableId = utils.valFormulaHtmlEscape(previousName,model.id);
		for(var i=0;i<aggrToValues.length;i++){
			var context = aggrToValues[i].get('context');
			for(var j=0;j<context.length;j++){
				var formula = context.models[j].get('formula');
				if(formula){
					var expr = formula.get('expressionStr');
					if(expr){
						var replacedExp = expr.replace(oldVariableId,newVariableId);
						formula.set('expressionStr',replacedExp);
					}
				}
			}
		}
	}

	handleNameChange(name, viewAlternative, changeInValue) {
        var self = this;
        var nameChanged = changeInValue ? changeInValue : false;
        if (self.get('name') !== name) {
            self.set('name', name);
            self.set('description', name);
            nameChanged = true;
			if(self.get("satisfactionLevel")!==null){
				self.get("satisfactionLevel").set('name', name + ' Satisfaction');
			}
			if(self.get("percentageWeight")!==null){
				self.get("percentageWeight").set('name', name + ' Weight');
			}
			if(self.get("recipientOpinion")!==null){
				self.get("recipientOpinion").set('name', name + ' Recipient Opinion');
			}
        }
        return nameChanged;
    }

	updateValueElementChanges(period, year, name, value, valueUnit, valFormula, baseTuples, viewAlternative, projectionProfile, valueConstraint, callback){
		var self = this;
		var plan = DataManager.getDataManager().get("currentPlan");
		var defaultExecutionScenaroId = plan.get("defaultExecutionScenario");
		var defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
		var periodDataset = defaultExecutionScenaro.get("input").findWhere({period: period,year:year,periodKind : plan.get("periodKind")});
		var changeInValue = false;
		//var updateOtherScenarios = updateOtherScenarios?updateOtherScenarios:false;
		if(value === '####'){
			value = Number.MAX_VALUE;
		}
		
		//handleValueUnitChange();
		handleQuantityChangeAndUnit(periodDataset);
		self.addRefToProjection(projectionProfile);
        changeInValue = self.handleNameChange(name, viewAlternative, changeInValue);
		
        handleFormulaChange(valFormula, viewAlternative, baseTuples);
		if(valueConstraint){
			handleConstraintChange(valueConstraint,viewAlternative);
		}
		/*if (!skipOtherScenarios && updateOtherScenarios) {
			callback(changeInValue);		
		}else{			
			callback(changeInValue);
		}*/
		callback(changeInValue);
		function handleConstraintChange(constraintVal,viewAlternative){
			if(constraintVal?.maxBound != null || constraintVal?.minBound != null || constraintVal?.equalValue != null || constraintVal?.bigM != null){
				var scenario = DataManager.getDataManager().get('defaultScenario');
				var step = scenario.get("step").findWhere({alternativeId: viewAlternative.id});
				var existobj = step ? step.get('constraints').findWhere({'valueId':self.id}): null;
				if(existobj){
					existobj.set('maxBound',constraintVal.maxBound);
					existobj.set('minBound',constraintVal.minBound);
					existobj.set('equalValue',constraintVal.equalValue);
					existobj.set('bigM',constraintVal.bigM);
				} else {
					self.createValueConstraints(constraintVal,viewAlternative);
				}			
			}
		}

        function handleFormulaChange(valFormula, viewAlternative, baseTuples){
			var oldFormula = self.getValueFormula(viewAlternative.id);
			var expFormula = valFormula ? valFormula.valueExpression : '';
			if(expFormula && expFormula.length > 0 && oldFormula != expFormula){
				self.set('valueType',ValueType.Aggregated);
				var contextCreation = false;
				var context = self.getValueContext(viewAlternative.id);
				if(!context){
					context = self.createValueElementContext(viewAlternative);
					contextCreation = true;
				}
				var packAltId = window.utils.getPrefix(self.getNestedParent().id);
				if((packAltId == window.plansKey && !contextCreation) || viewAlternative.id == packAltId){
					self.addBaseTuple(baseTuples);
				} else {
					context.addBaseTuple(baseTuples, contextCreation);
				}
				var expressionConfig = valFormula.expressionConfig;
				var offset = !valFormula.offset ? 0 : valFormula.offset;
				var exp = context.get("formula");
				if(exp){
					exp.set("expressionStr",expFormula);
					if(expressionConfig){
						expressionConfig = JSON.stringify(expressionConfig);
						exp.set("expressionConfig",expressionConfig);
					}
					exp.set("offset",offset);
				} else {
					exp = self.createExpression(context, expFormula, expressionConfig, offset);
				}
			}
		}
		function handleQuantityChangeAndUnit(periodDataset){
			var defaultValLib = plan.get('defaultValueLibrary');
			var unit = defaultValLib.get('unit').findWhere({name:valueUnit.unitName});
			if(valueUnit && valueUnit.unitName != "" && !unit){
				unit = Unit.getInstance(valueUnit.unitName,valueUnit.unitName,valueUnit.roundingDigits,valueUnit.significantDecimals,defaultValLib);
				self.set("unit",unit);
			}else if(valueUnit && valueUnit.unitName != "" && unit){
				self.set("unit",unit);
			}
			if(valueUnit && valueUnit.unitName != "" && unit){
				var roundFactor = valueUnit.roundingDigits ? valueUnit.roundingDigits : '4';
				unit.set("roundingDigits",roundFactor);
				var sigFactor = valueUnit.significantDecimals ? valueUnit.significantDecimals : '2';
				unit.set("significantDecimals",sigFactor);
			}
			if(periodDataset && self.get("valueType") == ValueType.Atomic){
				var measurement = new ScenarioMeasurement(self,periodDataset);
				if(value && !isNaN(value)){
					value = self.getRoundingQty(value);
				}
				measurement.setValue(+value);
			}
		}
	};

	addRefToAlternative(viewAlternative){
		var self = this;
		//viewAlternative.get("alternativeValues").add(self);
		var packId = self.getNestedParent().id;
		var nextAlts = [];
		var currentPlan = viewAlternative.getNestedParent();
		if(packId == currentPlan.id){
			_.each(currentPlan.get('phase').models,function(ph){
				_.each(ph.get('phaseAlternative').models,function(alt){
					nextAlts.push(alt);
				});
			});
		} else {
			nextAlts = viewAlternative.getAltsHavingPackage(packId);
			nextAlts.push(viewAlternative);
		}
		_.each(nextAlts,function(alt){
			alt.get("alternativeValues").add(self);
		});
	}
	addRefToProjection(profileId){
		var self = this;
		var profile = Backbone.Relational.store.getObjectByName('vdml.ProjectionProfile').find({ id: profileId });
		if(profile){
			self.set('projectionProfile',profile);
		}else{
			self.set('projectionProfile',null);
		}
	}

    primeValueMeasurement(year, period, value,unitToMatch,valFormula,baseTuples,viewAlternative,projectionProfile,valueConstraint){
		var self = this;
		var expFormula = valFormula ? valFormula.valueExpression : '';
		if((baseTuples && baseTuples.length > 0) || (expFormula && expFormula !="")){
			self.set("valueType",ValueType.Aggregated);
		} else {
			self.set("valueType",ValueType.Atomic);
		}
		self.addRefToAlternative(viewAlternative);
		
		if(valueConstraint){
			self.createValueConstraints(valueConstraint,viewAlternative);
		}
		var scenario = viewAlternative.getDefaultScenario();
        var measurement = scenario.createMeasurement(self,viewAlternative,year, period, valFormula,unitToMatch,baseTuples);     // 
		//var measurement = scenario.createMeasurement(currentPackage,self,valueMeasurement,null,charToMatch,unitToMatch,valFormula,accToMatch,baseTuples,null,null,timestamp,null,isOVS,viewAlternative);
		var roundingFactor = unitToMatch ? unitToMatch.roundingDigits : null;
		if(value && !isNaN(value)){
			value = parseFloat(value).toFixed(roundingFactor);
		}
		if(measurement && year!==undefined){
			measurement.setValue(+value);	
		}
		self.addRefToProjection(projectionProfile);
		return measurement;
	};
	getCalculationChangeType(operationType,change){
		if(operationType == "add" || operationType == "destroy"){
			return 0;
		}else{
			if(change.name || change.aggregatedFrom || change.aggregatedTo || change.unit || change.ValueElementContext){
				return 0;
			}
		}
		return;
	}
	getCalculationChangeArtifact(operationType){
		let dataManager = DataManager.getDataManager();
		return dataManager.get("artifactsDocuments")[dataManager.get("currentPlan").id].artifactId;
	}
	getScenarioValueAlternativeContext(scenario, altId){
		let self = this;
		let ret = undefined;
		let contexts = self.get("context");
		contexts.models.forEach(context => {
			if(context.get("alternative").id == altId){
				ret = context;
				return;
			}
		});
		if(ret == undefined){
			let step = scenario.get("step").findWhere({alternativeId : altId});
			let previousStep = step.get("previous");
			if(previousStep){
				return self.getScenarioValueAlternativeContext(scenario,previousStep.get("alternativeId"));
			}
		}
		return ret;
	}
	getValueExpressionForScenarioAlt(scenarioId,altId){
		let self = this;
		let scenario = Backbone.Relational.store.getObjectByName("transformation.PlanScenario").find({ id: scenarioId });
		if(scenario){
			let context = self.getScenarioValueAlternativeContext(scenario,altId);
			if(context){
				return context.get("formula");
			}
		}
	}
	getSatisfactionIntervalData(quantity,altId){
		var self = this;
		var data = {
			smileyValue: null,
			smileyColor: null,
			correctiveActions: null
		};
		var contextModel = self.getValueContext(altId);
        if(contextModel){
            var expressionConfig = contextModel.get('formula') ? contextModel.get('formula').get('expressionConfig') : null;
            if(expressionConfig){
                var intervals = JSON.parse(expressionConfig);
                const resultInterval = intervals.filter(item => item.level === quantity)[0];
                if(resultInterval){
					data.levelName = resultInterval.levelName ? resultInterval.levelName : '';
					data.smileyValue = resultInterval.smiley ? resultInterval.smiley : '';
            		data.smileyColor = resultInterval.color ? resultInterval.color : '#000000';
            		data.correctiveActions = resultInterval.actions ? utils.encodeDecodeBase64(resultInterval.actions) : '';
                }
            }
        }
        return data;
	}
}	
utils.customExtendClass (ValueElement2Mixin,new ValueElementMixin());

path.ValueElement2Mixin = ValueElement2Mixin;
