import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { ValueType } from '../../../../bo/vdml/ValueType'
import bootbox from '../../../../../../libs/bootbox/bootbox'


var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties", global.version);
export class ProjectionsViewModel {
    constructor(model, options) {
        var self = this;
        this.init(model, options);
    }
    dispose(){
        var self = this;
        window.cleanViewModel(self);
    }

    projectionsConfirmHandler(res){
        var self = this;
        const dataManager = DataManager.getDataManager();
        const currentPlan = dataManager.get('currentPlan');
        const scenarioId = currentPlan.get("defaultScenario");
        const scenarioData = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
        const defaultExecutionScenaroId = scenarioData.get("defaultExecutionScenario");
        if(res && res.length>0){
            var index = self.options.periodsets.findIndex(d => d.year+"-"+d.period === self.options.phaseAltName);
            let startForecastIndex = false;
            let forecastCounter = 0;
            for(let i = 0;i<self.options.periodsets.length;i++){
                var periodDataset = self.defaultExecutionScenaro.get("input").findWhere({period: self.options.periodsets[i].periodNumber,year: self.options.periodsets[i].year,periodKind : currentPlan.get("periodKind")});
                if(i>=index){
                    if (!startForecastIndex) {
                        startForecastIndex = true;  
                    }
                    if(periodDataset && startForecastIndex && forecastCounter < res.length){
                        periodDataset.set(self.options.valId,res[forecastCounter]);
                        forecastCounter++;
                    }	
                }
                					
            }
            window.vdmModelView.destroyTable('#firstScenarioTable');
            window.vdmModelView.renderDynamicRows(defaultExecutionScenaroId);
            window.vdmModelView.firstScenarioTable("firstScenarioTable");
            $('#firstScenarioTable').DataTable().draw('page')
        }
    }

    getQuantity(comp,inputPeriodDataset,resultPeriodDataset){
        var self = this;
        var qty = undefined;
        if(inputPeriodDataset && comp.get("valueType") == ValueType.Atomic){
            qty = inputPeriodDataset.get(comp.get('id'));
        } else if(resultPeriodDataset && comp.get("valueType") == ValueType.Aggregated) {
            qty = resultPeriodDataset.get(comp.get('id'));
        }
        if(comp.get('unit') && comp.get('unit').get('significantDecimals')){
            var roundingDigit = comp.get('unit').get('significantDecimals')
            if(qty){
                qty = parseFloat(parseFloat(qty).toFixed(roundingDigit));
            }
        }
        return qty
    }

    afterRenderView(node, view) {
        var self = view;
        var plan = DataManager.getDataManager().get('currentPlan');
        var valId = self.options.valId;
        var valueModal = window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
        var row = { "DT_RowId": 1, "level": 0, "key": 1, "parent": 0, name:valueModal.get("name"), type:valueModal.get("type"), suffix:valId};
        plan.getPeriodDatasetsAsync(self.options.scenarioExeId,self.options.periodsets,function(periodset){
            self.options.periodsets.map((d,i)=>{
                var periodKind =  plan.getPeriodKinds(d.year,d.periodNumber,plan.get("periodKind"));
                var periodKindText = periodKind?.text || d.period;
                var phaseAltName = d.year + "-" + periodKindText;
               var qty = self.getQuantity(valueModal,periodset.input[i],periodset.result[i]);
               if(qty){
                row[phaseAltName] = qty
               }else {
                row[phaseAltName] = '-'
               }
            })
            
        })
       
        self.createDatatable("projectionTable",self.options.periodsets,row)

    }

    callToJulia(url,json,callback){
        window.utils.startSpinner('refreshValues', 'Refreshing ...');
        DataManager.getDataManager().get('vmpServerService').postAllPromise(url, JSON.stringify(json)).then(function(res) {
        //save res to period dataset
        callback(res);			
        }).catch(error => {
            bootbox.alert("failed "+ error);
            console.error('An error occurred:', error);
            window.utils.stopSpinner('refreshValues');
        });
    }
    
    extrapolateBaseline(valId,qty){
        var self = this;
        self.projectionData = []
        var url = "/vdmbee/intrapolation/baseline";
        var params = {};
        var valueModal = window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
        const dataManager = DataManager.getDataManager();
        const currentPlan = dataManager.get('currentPlan');
        const scenarioId = currentPlan.get("defaultScenario");
        const scenarioData = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
        const defaultExecutionScenaroId = scenarioData.get("defaultExecutionScenario");
        self.defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
        const indexOfCcc = self.options.periodsets.findIndex(d => d.year+"-"+d.period === self.options.phaseAltName);
        const totalPeriods  = self.options.periodsets.slice(indexOfCcc);
        var params = valueModal.getProjectionData(qty,totalPeriods.length);
        var periodDatasets = scenarioData.getPlanDatasets(currentPlan);
        self.callToJulia(url,params,function(res){
            if(res.forecast.length>0){
                var row = { "DT_RowId": 1, "level": 0, "key": 1, "parent": 0, name:valueModal.get("name"), type:valueModal.get("type"), suffix:valId};
                var index = self.options.periodsets.findIndex(d => d.year+"-"+d.period === self.options.phaseAltName);
                let startForecastIndex = false;
                let forecastCounter = 0;
                for(let i = 0;i<periodDatasets.length;i++){
                    var periodKind =  currentPlan.getPeriodKinds(periodDatasets[i].year,periodDatasets[i].period,currentPlan.get("periodKind"));
                    var periodKindText = periodKind?.text || periodDatasets[i].period;
                    var phaseAltName = periodDatasets[i].year + "-" + periodKindText;
                    if(i>=index){
                        if (!startForecastIndex) {
                            startForecastIndex = true;  
                        }
            
                        if (startForecastIndex && forecastCounter < res.forecast.length) {
                            self.projectionData.push(res.forecast[forecastCounter]);
                            row[phaseAltName] = res.forecast[forecastCounter];
                            forecastCounter++;
                        }
                    }else {
                        row[phaseAltName] = '-'
                    }	
                }
            }
            window.vdmModelView.destroyTable('#projectionTable');
            self.createDatatable("projectionTable",self.options.periodsets,row)
            $('#projectionTable').DataTable().draw('page');
            window.utils.stopSpinner('refreshValues');
        });
            
    }

    discardProjectionHandler(){
        var self = this;
        var plan = DataManager.getDataManager().get('currentPlan');
        var valueModal = window.utils.getElementModel(self.options.valId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
        var row = { "DT_RowId": 1, "level": 0, "key": 1, "parent": 0, name:valueModal.get("name"), type:valueModal.get("type"), suffix:self.options.valId};
        plan.getPeriodDatasetsAsync(self.options.scenarioExeId,self.options.periodsets,function(periodset){
            self.options.periodsets.map((d,i)=>{
                var periodKind =  plan.getPeriodKinds(d.year,d.periodNumber,plan.get("periodKind"));
                var periodKindText = periodKind?.text || d.period;
                var phaseAltName = d.year + "-" + periodKindText;
               var qty = self.getQuantity(valueModal,periodset.input[i],periodset.result[i]);
               if(qty){
                row[phaseAltName] = qty
               }else {
                row[phaseAltName] = '-'
               }
            })
            
        })
        window.vdmModelView.destroyTable('#projectionTable');
        self.createDatatable("projectionTable",self.options.periodsets,row)
        $('#projectionTable').DataTable().draw('page');
        window.utils.stopSpinner('refreshValues');
    }

    previewProjectionHandler(){
        var self = this
        if(this.options.modalHeader == "Extrapolate Baseline") {
            self.extrapolateBaseline(self.options.valId,self.options.qty);
        } else if(option == "Extrapolate trend") {
            window.vdmModelView.extrapolateTrend(valId,phaseAltName);
        } else if(option == "Interpolate") {
            window.vdmModelView.interpolate(valId,phaseAltName);
        } else if(option == "Interpolate-extrapolate") {
            window.vdmModelView.interpolateextrapolate(valId,phaseAltName);
        } else {
            console.log(`You clicked: ${option}`);
        }
    }

    confirmProjectionHandler(){
        var self = this
        self.projectionsConfirmHandler(self.projectionData);
    }

    createDatatable(tableName,periodSets,rows){

        var dynamicColumns = []

        var columnRendering = []


        var plan = DataManager.getDataManager().get('currentPlan');
        var keyIndex = 0
        periodSets.map((d)=>{
            var phaseAltName = `${d.year}-${d.period}`;
            var periodSetColumns;
           
            if(plan.get('periodKind')==="Yearly"){
                periodSetColumns = d.year
            }else{
                periodSetColumns = phaseAltName
            }		
            
            dynamicColumns.push({
                title: periodSetColumns,
                targets: keyIndex
            });

            columnRendering.push({
                "data": phaseAltName,
                "type": "custom",
                "className": "phase-alt-name text-right",
                "createdCell": function (td, cellData, rowData, row, col) {
                if (rowData.level > 0) {
                    if (rowData.type == "Value"){
                        td.parentElement.setAttribute("suffix",rowData.suffix);
                        td.parentElement.setAttribute("planValue",rowData.planValue);
                        td.parentElement.setAttribute('valueName',rowData.valueName);
                    }
                    
                    }
                },
                "render": function (data, type, full, meta) {
                    switch (type) {
                        case "display":
                            return data;
                    }
                    return data;
                }
                
            });
            keyIndex++
        })

        var dt = $('#'+tableName).DataTable({
            "data": [rows],
            "bLengthChange": false,
            "ordering": false,
            "pageResize": true,
            "columnDefs": dynamicColumns,
            "columns": columnRendering,
            "bStateSave": true,
            dom: 'lrt',
            "paging":false,
            "scrollCollapse": true,
        })

        return dt
    }


    

    init(model,options){
        var self = this;
        this.ProjectionsViewModel = this;
        this.options = options.addOptions;
        this.projection = ko.observable(this.options.modalHeader);
        this.cleanModal = _.bind(self.cleanModalHandler, self);
        this.encodeId = window.utils.htmlEscape(this.options.id);
        this.startPeriod = ko.observable(this.options.phaseAltName);
        this.preview = _.bind(self.previewProjectionHandler, self);
        this.discard = _.bind(self.discardProjectionHandler, self);
        this.confirm = _.bind(self.confirmProjectionHandler, self);
        var valueModal = window.utils.getElementModel(self.options.valId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
        this.valName = ko.observable(valueModal.get('name'));
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
                , 'Close'
                , 'Complete'
                , "Preview"
                , "Discard"
                , "Confirm"
                , "projectionStartPeriod"
            ]);
        
    }
    cleanModalHandler() {
        var self = this;
        window.vdmModelView.destroyTable('#projectionTable');
        window.cleanDialogModel(self.encodeId, this);
    }

    static getInstance(model,options){
        var view = new ProjectionsViewModel(model,options);
        return view;
    };
}

	
	path.ProjectionsViewModel = ProjectionsViewModel;
