import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {CanvasDetailsViewModel} from './CanvasDetailsViewModel'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import { PeriodKind } from '../../../../bo/vdml/PeriodKind'

//define(["require", "jquery", "underscore", "muuri", "backbone", "knockout", "bootbox", "appcommon/com/vbee/data/DataManager", "appbo/vdml/Annotation", "app/global", "appbo/vdml/BusinessModel", "appbo/vdml/Participant", "appbo/vdml/ValueElement", "appbo/canvas/BMCanvasItem", "knockback", "summernote", "appviews/canvas/views/designer/MappingWizardViewModel", "appviews/canvas/views/designer/CanvasDetailsViewModel", "appviews/help/views/properties/BMGuidanceViewModel", "appcommon/com/vbee/utils/DateFormat", "appbo/canvas/BMCanvasDiagramMixin", "appbo/vdml/CapabilityLibraryMixin", "appbo/vdml/ValueDeliveryModelMixin", "appbo/ecomap/EcoMapMixin", "domtoimage", "colorpicker"],
//    function (require, $, _, Muuri, Backbone, ko, bootbox, DataManager, Annotation, global, BusinessModel, Participant, ValueElement, BMCanvasItem, kb, summernote, MappingWizardViewModel, CanvasDetailsViewModel, BMGuidanceViewModel, DateFormat, BMCanvasDiagramMixin, CapabilityLibraryMixin, ValueDeliveryModelMixin, EcoMapMixin) {
var path = DataManager.getDataManager().buildAppNsPath("canvas.views.properties", global.version);
export class CanvasDetails2ViewModel extends CanvasDetailsViewModel {

    getPeriodsByYear(year, plan) {
        var self = this;
        let selectedYear = year
        const periods = self.dataSets.filter(d => d.year === +selectedYear)
        const periodsByPeriodKind = periods.map(d => {
            return plan.getPeriodKinds(d.year, d.period, self.periodKind)
        });
        self.periodMonths(periodsByPeriodKind);
    };

    init(model, options) {
        super.init(model,options);
        var self = this;
        this.CanvasDetails2ViewModel = this;
        this.model = model;
        var dataManager = DataManager.getDataManager();
        this.periodYears = ko.observableArray();
        this.periodMonths = ko.observableArray();
        this.selectedYear = ko.observable();
        this.selectedPeriod = ko.observable();
        this.showPeriods = ko.observable(true)
        this.periodKind = dataManager.get('currentPlan').get("periodKind");
        var scenarioId = dataManager.get("currentPlan").get("defaultScenario");
        var currentAlternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({id: dataManager.get('viewAlternative')});
        this.scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
        var currentPlan = dataManager.get('currentPlan');
        this.dataSets = currentAlternative.getPhaseAlternativeSteps(self.scenario);
        const filteredYears = this.dataSets.filter((item, index, self) =>
            index === self.findIndex((t) => t.year === item.year)
        );
        this.periodYears(filteredYears.map(d=>{return {text:d.year,value:d.year}}));
        self.selectedYear.subscribe(function(value) {
            if (value !== undefined) {
                self.getPeriodsByYear(value, currentPlan);
                self.defaultPeriod ? self.selectedPeriod(self.defaultPeriod) : self.selectedPeriod();
                self.defaultPeriod = undefined
            }
        });

        if (PeriodKind.symbols()[0].name === self.periodKind) {
            this.showPeriods(false)
        }
        var defaultPeriodList = self.scenario.fillDefaultPeriods(this.dataSets, self.periodKind);
        self.defaultPeriod = defaultPeriodList?.period
        self.defaultYear = defaultPeriodList?.year;
        dataManager.set('currentPeriod', defaultPeriodList.period);
        self.selectedYear(defaultPeriodList.year);
        dataManager.set("currentYear", defaultPeriodList.year);
    }

    saveCanvasDetails(){
		var self = this;
        if(self.name().trim() != self.model.get('name')){
            self.model.set('name',self.name().trim());
            window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'),DataManager.getDataManager().get('viewAlternative'),self.CanvasDetailsViewModel);
        }
        self.model.set('description',self.description().trim());
        if(self.model.get('colors') != $('#useColors').is(':checked')){
            self.model.set('colors',$('#useColors').is(':checked'));
            var configObj = self.getConfigObj();
            var canvasBlocks = $('#canvas-group'+self.encodeId).children();
            if(configObj) {
                var backgroundColors = configObj.backgroundColors;
                if (self.model.get('colors')) {
                    for(var i=0;i<backgroundColors.length;i++){
                        canvasBlocks.eq(i).css('background-color', backgroundColors[i].color);
                    }
                }else{
                    for(var i=0;i<backgroundColors.length;i++){
                        canvasBlocks.eq(i).css('background-color', "#ffffff" );
                    }
                }
            }
            //self.createPicture();
        }
        //self.enableComplete(false);
        var dataManager = DataManager.getDataManager();
        var triggerDiagChange = false;
        if(self.selectedPeriod() != dataManager.get("currentPeriod") || self.selectedYear() != dataManager.get("currentYear")){
            triggerDiagChange = true;
        }
        dataManager.set("currentYear", self.selectedYear());
        dataManager.set('currentPeriod', self.selectedPeriod());
        self.defaultPeriod = self.selectedPeriod();
        self.defaultYear = self.selectedYear();
        if(triggerDiagChange){
            self.refreshWidgetData();
        }
	};
    refreshWidgetData(){
        var self = this;
        window.utils.startSpinner('loadingValues', "loading Values...");
        window.setTimeout(function() {
            self.loadDatasets(self.selectedPeriod(), self.selectedYear(), function() {
                if(window.vdmModelView.refreshTempValueCollections !== undefined){
                    window.vdmModelView.refreshTempValueCollections();
                }
                window.utils.stopSpinner('loadingValues');
            });
        }, 100);
    }

    loadDatasets(period, year, callback) {
        var self = this;
        self.scenario.getDatasetAsync(year, period, self.periodKind, null, function(periodDataset, calcPeriodDataset) {
            self.periodDataset = periodDataset;
            self.calcPeriodDataset = calcPeriodDataset;
            callback(periodDataset, calcPeriodDataset);
        });
    }

    static getInstance(model, options) {
        var view = new CanvasDetails2ViewModel(model, options);
        view.init(model, options);
        return view;
    };
}
path.CanvasDetails2ViewModel = CanvasDetails2ViewModel;
