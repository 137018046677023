import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as async from 'async'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import { ValueType } from '../../../../bo/vdml/ValueType'
import {EcoMapMixin} from '../../../../bo/ecomap/EcoMapMixin'
import * as EcoNavigatedViewer from '../../../../../../libs/bower-vdml-js/dist/vdml-navigated-viewer.min'
import { Plan2Mixin } from '../../../../bo/transformation/Plan2Mixin'
// import * as colResize from '../../../../../../libs/DataTable/dataTables.colResize'

	var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties", global.version);
	export class Values2ViewModel {
        constructor(model, options) {
            var self = this;
            this.init(model, options);
        }
		dispose(){
			var self = this;
			window.cleanViewModel(self);
		}
		/*getValueModalFromPreviousPhases(valId,altModel){
			var preAlt = altModel.getPreviousPhasePrimary();
			if(preAlt){
				valId = preAlt.id + window.utils.getSuffix(valId);
				var valueModal = window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
				if(!valueModal){
					valueModal = self.getValueModalFromPreviousPhases(valId,preAlt);
				} else {
					return valueModal;
				}
			} else {
				return valueModal;
			}
		}*/

		callToJulia(url,json,callback){
			window.utils.startSpinner('refreshValues', 'Refreshing ...');
			DataManager.getDataManager().get('vmpServerService').postAllPromise(url, JSON.stringify(json)).then(function(res) {
			//save res to period dataset
			callback(res);			
			}).catch(error => {
				bootbox.alert("failed "+ error);
				console.error('An error occurred:', error);
				window.utils.stopSpinner('refreshValues');
			});
		}

		


		extrapolateTrend(valId,selectedHorizon){
			var self = this
			var url = "/vdmbee/intrapolation/sparsedata";
			var params = {};
			const hashMap = {1: 1.1, 2: 2.2, 3: 3.3};
			const jsonString = JSON.stringify(hashMap);
			params.known_quantities = jsonString;
			params.planning_horizon = 2;
			params.pattern = "abc";
			params.seasonal_factors = [1.25,2.25];
			self.callToJulia(url,params);
		}

		interpolate(valId,selectedHorizon){
			var self = this
			var url = "/vdmbee/intrapolation/timeSeries";
			var params = {};
			params.season_length = 2;
			params.periods = 2;
			params.growth_rate = 1.00;
			params.time_series = [1.25,2.25];
			self.callToJulia(url,params);
		}

		interpolateextrapolate(valId,selectedHorizon){
			var self = this
			var url = "/vdmbee/intrapolation/twopoint";
			var params = {};
			params.start_quantity = 1.00;
			params.end_quantity = 1.00;
			params.planning_horizon = 2;
			params.pattern = "abc";
			params.seasonal_factors = [1.25,2.25];
			self.callToJulia(url,params);
		}
					
			buildOrderObject(dt, key, column) {
				var self = this;
				//var rowData = dt.row("#" + key).data();
				var rowData = dt.row("#" + key).data();
				if (typeof rowData === 'undefined') {
					return {};
				} else {
					var object = self.buildOrderObject(dt, rowData['parent'], column);
					var a = object;
					while (typeof a.child !== 'undefined') {
						a = a.child;
					}
					a.child = {};
					a.child.key = rowData['key'];
					a.child.value = rowData[column];
					return object;
				}
			};

			getQuantity(comp,inputPeriodDataset,resultPeriodDataset){
				var self = this;
				var qty = undefined;
				if (!comp){
					return qty
				}
				if(self.enableDirectEditing){
					if(inputPeriodDataset && comp.get("valueType") == ValueType.Atomic){
						qty = inputPeriodDataset.get(comp.get('id'));
					} else if(resultPeriodDataset && comp.get("valueType") == ValueType.Aggregated) {
						qty = resultPeriodDataset.get(comp.get('id'));
					}
				}		
				if(comp.get('unit') && comp.get('unit').get('significantDecimals')){
					var roundingDigit = comp.get('unit').get('significantDecimals')
					if(qty){
						qty = parseFloat(parseFloat(qty).toFixed(roundingDigit));
					}
				}
				return qty
			}

			subComponentValues(subComp,keyIndex,valObj,altColumnList,inputPeriodDataset, resultPeriodDataset, dynamicRows,phaseAltName,name,comp,isplanValue){
				var self = this;
				var calc = false;
				var subCompVal
				var childValObj 

				var compSuffix = subComp ?  window.utils.getSuffix(subComp.get('id')) : name + window.utils.getSuffix(comp.get('id'));

				for (var z = 0; z < dynamicRows.length; z++) {
					if (dynamicRows[z]["suffix"] == compSuffix) {
						childValObj = dynamicRows[z];
					}
					if (childValObj != null) {
						break;
					}
				}

				subCompVal = self.getQuantity(subComp,inputPeriodDataset,resultPeriodDataset)

				var subCompName

				if(name ==='Recipient Opinion'){
					if(subComp && subComp.get('unit')){
						subCompName =  name + " " + "("+subComp.get('unit').get('name')+")"
					}else {
						if(comp.get('unit')){
							subCompName = name + " " + "("+comp.get('unit').get('name')+")"
						}
						
					}
				}

				if(name ==='Weight'){
					subCompName = name + " " + "(%)"
					if(subComp && subComp.get('unit')){
						subCompName = name + " " + "("+subComp.get('unit').get('name')+")"
					}
				}

				if(name === 'Satisfaction'){
					calc = true;
					subCompName = name + ""
					if(subComp && subComp.get('isNominal')===false && subComp.get('unit')){
						subCompName = name + " " + "("+subComp.get('unit').get('name')+")"
					}
				}

				/*if (subComp && subComp.get('valueType') != ValueType.Atomic) {
					calc = true;
				}*/

				if(childValObj==undefined){
					childValObj = { "DT_RowId": keyIndex, "level": 5, "key": keyIndex, "parent": valObj.key, "name":subCompName, "type": 'Value', "suffix": compSuffix, "calc": calc, "subComponentVal":true, valueName : name, planValue:isplanValue};
					for (var j = 0; j < altColumnList.length; j++) {
						var altName = altColumnList[j];
						if(!childValObj[altName]){
							childValObj[altName] = "-";
						}
					}
					if(subCompVal !== undefined && subCompVal){
						// if(name === 'Satisfaction'){
						// 	var expressionConfig = contextModel.get('formula') ? contextModel.get('formula').get('expressionConfig') : '';
						// 	var intervals = JSON.parse(expressionConfig);
						// 	const resultInterval = intervals.filter(item => item.level === subCompVal)[0];
						// 	if(resultInterval){
						// 		childValObj[phaseAltName] = resultInterval.levelName
						// 	}
						// }else {
						// 	childValObj[phaseAltName] = subCompVal
						// }	
						childValObj[phaseAltName] = subCompVal					
					}else {
						childValObj[phaseAltName] = "-"
					}	
					dynamicRows.push(childValObj);
				}else if (phaseAltName) {
					if(subCompVal!==undefined && subCompVal){
						// if(name === 'Satisfaction'){
						// 	var contextModel = subCompVal.getValueContext(yearPeriod.alternativeId);
						// 	if(contextModel){
						// 		var expressionConfig = contextModel.get('formula') ? contextModel.get('formula').get('expressionConfig') : '';
						// 		var intervals = JSON.parse(expressionConfig);
						// 		const resultInterval = intervals.filter(item => item.level === subCompVal)[0];
						// 		if(resultInterval){
						// 			childValObj[phaseAltName] = resultInterval.levelName
						// 		}
						// 	}
						// }else {
						// 	childValObj[phaseAltName] = subCompVal
						// }
						childValObj[phaseAltName] = subCompVal
					}else {
						childValObj[phaseAltName] = "-"
					}
				}		
				keyIndex = keyIndex + 1;
				return keyIndex
			}

			checkValues(planComponents, valueLevel, dynamicRows, keyIndex, phaseAltName, inputPeriodDataset,resultPeriodDataset, altColumnList, actValueList) {
				var self = this;
				_.each(planComponents,function (comp) {
					if(actValueList && !actValueList.includes(comp)){
						return;
					}
					var valObj;
					var compSuffix = comp.id.substr(comp.id.lastIndexOf('@') + 1);
					for (var z = 0; z < dynamicRows.length; z++) {
						if (dynamicRows[z]["suffix"] == compSuffix) {
							valObj = dynamicRows[z];
						}
						if (valObj != null) {
							break;
						}
					}
					var mstValueUnit = "-";
					var calc = false;
					var smileyValue = "";
					var smileyColor = "";
					var mstValueUnit;

					mstValueUnit = self.getQuantity(comp,inputPeriodDataset,resultPeriodDataset);
					
					// 
					/*var valueMsmt = null;//self.getComponentMeasurement(comp, 'valueMeasurement', scenario);
					if(valueList && valueMsmt){
						var compValues = valueList[valueMsmt.id];
						if(compValues){
							var significantDecimals = 2;//TODO
							mstValueUnit = window.utils.getRoundedValues(compValues[0]['Value'], significantDecimals) + ' ' + compValues[0]['Unit'];
							
						}
					} else {
						var selectedInterval = null;//comp.getSatisfactionInterval(scenario);
						if(selectedInterval){
							smileyValue = selectedInterval.get('smiley') ? selectedInterval.get('smiley') : '';
							smileyColor = selectedInterval.get('smileyColor') ? selectedInterval.get('smileyColor') : '#000000';
							//satisfactionSmiley = comp.fetchSmileySrc(smileyValue);
						}
						if(valueMsmt){
							var observedMeasure = valueMsmt.getParent();
							var measure = observedMeasure.get('measure');
							if (comp.get('aggregatedFrom').length > 0) {
								calc = true;
							}
							var unit = measure.get('unit');
							var significantDecimals = unit ? unit.get('significantDecimals') : 2;
							var mstVal = window.utils.getRoundedValues(valueMsmt.get('value'), significantDecimals);
							if(mstVal && mstVal != '-'){
								mstVal = window.utils.thousandSeparator(mstVal);
							}
							mstValueUnit = mstVal + ' ' + unit.get('name');
						} else if(selectedInterval){//Gradeonly
							mstValueUnit = selectedInterval.get('symbol');
						}
						
					}*/
					if (comp.get('valueType') != ValueType.Atomic) {
						calc = true;
					}
					var level = 4
					
					if (valObj == null) {
						var isplanValue = false;
						var isActivityValue = false;
						if (DataManager.getDataManager().getRepositoryId(comp.id) == window.plansKey) {
							isplanValue = true;
						}
						if(comp.get('type')==="vdml_ValueAdd"){
							isActivityValue = true;
						}
						
						var unitName = comp.get('unit') ? comp.get('unit').get('name'):null;
						var compName = comp.get('name');
						if(unitName){
							compName = compName + " " + "("+unitName+")";
						}
						valObj = { "DT_RowId": keyIndex, "level": level, "key": keyIndex, "parent": valueLevel, "name": compName, "type": self.titleMap[comp.get('type')], "suffix": compSuffix, "calc": calc, planValue:isplanValue, 'subComponentVal':true,isActivityValue:isActivityValue };
						for (var j = 0; j < altColumnList.length; j++) {
							var altName = altColumnList[j];
							if(!valObj[altName]){
								valObj[altName] = "-";
							}
						}
						if(mstValueUnit){
							valObj[phaseAltName] = mstValueUnit
						}else{
							valObj[phaseAltName] = "-"
						};
						//valObj[phaseAltName] = {'value':mstValueUnit,"altId":altId,"customId":comp.id,"whenObserved": null};
						if(smileyValue){
							valObj[phaseAltName + '-satisfactionSmiley'] = smileyValue;
							valObj[phaseAltName + '-smileyColor'] = smileyColor;
						}
						// valObj[phaseAltName + '-isIterative'] = isterative;
						dynamicRows.push(valObj);
						keyIndex = keyIndex + 1;
						
					} else if(phaseAltName){
						if(smileyValue){
							valObj[phaseAltName + '-satisfactionSmiley'] = smileyValue;
							valObj[phaseAltName + '-smileyColor'] = smileyColor;
						}
						// valObj[phaseAltName + '-isIterative'] = isterative;
						if(mstValueUnit){
							valObj[phaseAltName] = mstValueUnit
						}else {
							valObj[phaseAltName] = "-"
						};

					
					}
					if(comp.get('type')==="vdml_ValuePropositionComponent" && !comp.get('isNominal')){
							keyIndex = self.subComponentValues(comp.get("satisfactionLevel"),keyIndex,valObj,altColumnList,inputPeriodDataset,resultPeriodDataset,dynamicRows,phaseAltName,"Satisfaction",comp,isplanValue)
							keyIndex = self.subComponentValues(comp.get("percentageWeight"),keyIndex,valObj,altColumnList,inputPeriodDataset,resultPeriodDataset,dynamicRows,phaseAltName,"Weight",comp,isplanValue) ;
							keyIndex = self.subComponentValues(comp.get("recipientOpinion"),keyIndex,valObj,altColumnList,inputPeriodDataset,resultPeriodDataset,dynamicRows,phaseAltName,"Recipient Opinion",comp,isplanValue);
					}
					
				});
				return keyIndex;
			};

			

	generateMetricsCSV() {
		var self = this;
		var fromPeriodDataset = self.periodSets().find(d=>d.year===self.selectedFromYear() && d.period===self.selectedFromPeriod());
		var toPeriodDataset = self.periodSets().find(d=>d.year===self.selectedToYear() && d.period===self.selectedToPeriod());
		var params = {fromYear:self.selectedFromYear(),toYear:self.selectedToYear(),fromPeriod:fromPeriodDataset.periodNumber,toPeriod:toPeriodDataset.periodNumber,scenarioId:self.scenario.get('id'),scenarioExecutionId:self.scenario.get("defaultExecutionScenario")}
		DataManager.getDataManager().get('currentPlan').scenarioExportDialog(params);
	};

	expandCollapseRows(table,displayed,dt){
		var self = this;
		$('#'+table+' tbody').on('click', 'tr td:first-child', function () {
			var tr = $(this).closest('tr');
			var row = $('#'+ table).DataTable().row(tr);
			var key = row.data().key;
			
			if (displayed.has(key)) {
				var childKeyList = [];
				function getChildKeys(childKeyList,key){
					for (var k = 0; k < self.dynamicRows.length; k++) {
						if (self.dynamicRows[k]["parent"] == key) {
							var childKey = self.dynamicRows[k].key;
							childKeyList.push(childKey);
							getChildKeys(childKeyList,childKey);
						}
					}
					return childKeyList
				}
				getChildKeys(childKeyList,key);
				var childList = childKeyList.length;
				while(childList--){
					displayed.delete(childKeyList[childList]);
				}						
				displayed.delete(key);
				tr.removeClass('details');
			} else {
				displayed.add(key);
				tr.addClass('details');
			}
			
			var regex = "^(0";
			displayed.forEach(function (value) {
				regex = regex + "|" + value;
			});
			regex = regex + ")$";
			dt.columns([2]).search(regex, true, false).draw();
		});	
	}

	firstScenarioTable(tableName){
		var self = this;
		var dynamicColumns = [{ title: 'Path', targets: 0 },{ title: 'Key', targets: 1 },
			{ title: 'Parent', targets: 2 }];
				var columnRendering = [{
					"data": "level",
					"visible": false
				}, {
					"data": "key",
					"visible": false
				}, {
					"data": "parent",
					"visible": false
				}]
				var plan = DataManager.getDataManager().get('currentPlan');
				var targetIndex = 3;
				let filteredPeriods = self.getFilterPeriodDatasets()


				if(self.selectedPeriodValues().length>0){
					filteredPeriods = self.selectedPeriodValues().map(d=>{
						for(var i=0;i<self.periodSets().length;i++){
							if(`${self.periodSets()[i].year}-${self.periodSets()[i].period}`=== d){
								return self.periodSets()[i]
						}
					}
				})}

			  	filteredPeriods.map((d)=>{
					var phaseAltName = `${d.year}-${d.period}`;
					var periodSetColumns;
					if(plan.get('periodKind')===self.labels["Yearly"]()){
						periodSetColumns = d.year
					}else{
						periodSetColumns = phaseAltName
					}		
					self.altMap[phaseAltName] = d.altId;
					self.yearPeriodMap[phaseAltName] = d;
					
					dynamicColumns.push({
						title: periodSetColumns,
						targets: targetIndex
					});

					columnRendering.push({
						"data": phaseAltName,
						"type": "custom",
						"className": "phase-alt-name text-right",
						"createdCell": function (td, cellData, rowData, row, col) {
						if (rowData.level > 0) {
							if (rowData.type == "Value"){
								td.parentElement.setAttribute("suffix",rowData.suffix);
								td.parentElement.setAttribute("planValue",rowData.planValue);
								td.parentElement.setAttribute('valueName',rowData.valueName);
							}
							
							}
						},
						"render": function (data, type, full, meta) {
							switch (type) {
								case "display":
									return data;
							}
							return data;
						}
					});
					targetIndex++;
				})
				let displayed;
				var dt = $('#'+tableName).DataTable({
					"data": self.dynamicRows,
					"bLengthChange": false,
					"pageLength": 50,
					"ordering": false,
					"pageResize": true,
					"columnDefs": dynamicColumns,
					"columns": columnRendering,
					'dom': 'lrt',
                    "bStateSave": true,    
					'fixedHeader':true,  
					"scrollY":"70vh",
                    "paging":false,
					"searching":true,
                    "scrollCollapse": true,
					"initComplete": function(settings, json) {
						var rowsToHide = [];
						displayed = new Set(_.pluck(this.dynamicRows, 'key'));
						var table = $('#'+tableName).DataTable();
						table.rows().every(function () {
							var data = this.data(); 
							var key = data.key; 

							if (!data.subComponentVal) {
								rowsToHide.push(key); 
							}
						});
						if(rowsToHide.length>0){
							rowsToHide.forEach(function(key) {
								var rowIndex = table.rows().indexes().filter(function(i) {
									return table.row(i).data().key === key; 
								})[0];
						
								if (rowIndex !== undefined) {
									var tr = $(table.row(rowIndex).node());
									displayed.add(key);
									tr.addClass('details'); 
								}
							});
						
							var regex = "^(0";
							displayed.forEach(function (value) {
								regex = regex + "|" + value;
							});
							regex = regex + ")$";
							table.columns([2]).search(regex, true, false).draw();
						}
					},
					"fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
						if(aData["type"] === "Value"){
							$('td', nRow).each(function () {
								if (this.className == "phase-alt-name text-right" ) {
										var a = document.createElement('a');
										a.style.color = 'inherit';
										a.style.cursor= 'pointer';
										a.setAttribute('id',aData["suffix"]);
										var val = this.innerText;
										a.appendChild(document.createTextNode(val));
										this.innerText = '';
										this.append(a);
										$(a).on('contextmenu',function(event){
											if(!self.showMultipleScenarioTable()){
												event.preventDefault();
												var qty = event.currentTarget.innerText;
												var cellIndex = event.currentTarget.parentElement.cellIndex;
												var phaseAltName =  $('#firstScenarioTable tr th').eq(cellIndex).find('span.dt-column-title').text();
												var currentPlan = DataManager.getDataManager().get('currentPlan');
												if(currentPlan.get("periodKind")==="Yearly"){
													phaseAltName = phaseAltName+"-1"
												}
												var altId = self.altMap[phaseAltName];
												var suffix = event.currentTarget.getAttribute('id');;
												var valId = altId+suffix;
												var planValue = event.currentTarget.parentElement.parentElement.getAttribute('planValue');
												if(planValue == 'true'){
													valId = window.plansKey+suffix;
												}
												self.showProjectionDropdown(event,valId,qty,phaseAltName);
											}
										});
									/*var colu = theadChildrens[this.cellIndex].innerText;
									if(aData[colu + "-satisfactionSmiley"]){
										var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
										svg.setAttribute("width", 21);
										svg.setAttribute("height", 20);
										svg.setAttribute("style",'vertical-align:bottom;');
										svg.setAttribute("viewBox", "-3 -2 26 26");
										svg.setAttribute("fill", aData[colu + "-smileyColor"]);
										//svg.setAttribute("correctiveActions",found[8]);
										//svg.style.cursor = 'pointer';
										var link = document.createElementNS("http://www.w3.org/2000/svg", "path");
										var smileySrc = '';
										for(var j=0;j<svgSmiliesList.length;j++){
											if(svgSmiliesList[j].value === aData[colu + "-satisfactionSmiley"]) {
												smileySrc = svgSmiliesList[j].src;
												break;
											}
										}

										link.setAttribute("d", smileySrc);
										svg.appendChild(link);
										//$('td:eq(3)', nRow).append(svg);
										this.append(svg);
									}
									}*/
									if(aData["calc"] != true && self.enableDirectEditing && !self.showMultipleScenarioTable()){
										$(this).on("keydown", function (event) {
											$(this).prop('oldValue', $(this).val());
											window.utils.checkNumericValue(event, this);
										});
										$(this).on("keyup", function (event) {
											let currentValue = $(event.target).val();
											var decimalLimit = 4;
											var rgx = new RegExp(`^\\-?[0-9]*\\.?[0-9]{0,${decimalLimit}}$`);
											if (!rgx.test(currentValue)) {
												$(event.target).val($(this).prop('oldValue'));
											}
										});

										$(this).on('dblclick', function() {
											var cell = $(this);
											if (!cell.is('[contenteditable]')) {
												cell.attr('contenteditable', 'true').focus();
											}
										});
						
										$(this).blur(function(event){
												var qty = event.currentTarget.innerText;
												var phaseAltName = $('#firstScenarioTable tr th').eq(event.currentTarget.cellIndex).find('span.dt-column-title').text();
												var currentPlan = DataManager.getDataManager().get('currentPlan');
												if(currentPlan.get("periodKind")==="Yearly"){
													phaseAltName = phaseAltName+"-1"
												}
												var altId = self.altMap[phaseAltName];
												var suffix = event.currentTarget.parentElement.getAttribute('suffix');
												var valId = altId+suffix;
												var planValue = event.currentTarget.parentElement.getAttribute('planValue');
												if(planValue == 'true'){
													valId = window.plansKey+suffix;
												}
												self.valueChange(valId,qty,phaseAltName,event,altId);
												$(this).removeAttr('contenteditable');
											//var tr = event.currentTarget.parentElement;
											
										});
									}
									$(a).click(function(event){
										if(!self.showMultipleScenarioTable()){
											var currentTarget = event.currentTarget;
											// var index = currentTarget.parentElement.cellIndex;
											var phaseAltName = $('#firstScenarioTable tr th').eq(event.currentTarget.parentNode.cellIndex).find('span.dt-column-title').text();
											var currentPlan = DataManager.getDataManager().get('currentPlan');
											if(currentPlan.get("periodKind")==="Yearly"){
												phaseAltName = phaseAltName+"-1"
											}
											var altId = self.altMap[phaseAltName];
											var suffix = currentTarget.getAttribute('id');
											var valId = altId+suffix;
											// var obj1 = {id:valId,altId:altId,timeStamp:null};
											var altModel = window.utils.getElementModel(altId,['transformation.Alternative']);
											var planValue = event.currentTarget.parentElement.parentElement.getAttribute('planValue');
												if(planValue == 'true'){
													valId = window.plansKey+suffix;
												}
											var cell = $(this).parent();
											if (!$(cell).is('[contenteditable]')) {
												altModel.showAggregationView(valId);
											}
										}
									});
								}
							});
						}
					}
				});

				$('#'+tableName).on('init.dt', function () {
					dt.columns([2]).search('^(0)$', true, false).draw();
				});

				if(self.showMultipleScenarioTable()){
					self.expandCollapseRows('fixedColumnsMultiTable',displayed,dt);
				}else {
					self.expandCollapseRows('fixedColumns',displayed,dt);
				}				
					
				$('#firstScenarioTableMultiTable_wrapper .dt-scroll-body').on('scroll', function() {
					var scrollLeft = $(this).scrollLeft();
					$('#secondScenarioTable_wrapper .dt-scroll-body').scrollLeft(scrollLeft);
				});
				$('#firstScenarioTable_wrapper .dt-scroll-body').on('scroll', function() {
					var scrollTop = $(this).scrollTop();  
					$('#fixedColumns_wrapper .dt-scroll-body').scrollTop(scrollTop);
				});

				$('#firstScenarioTableMultiTable_wrapper .dt-scroll-body').on('scroll', function() {
					var scrollTop = $(this).scrollTop();  
					$('#secondScenarioTable_wrapper .dt-scroll-body').scrollTop(scrollTop);
					$('#fixedColumnsMultiTable_wrapper .dt-scroll-body').scrollTop(scrollTop);
				});
	}

	secondScenarioTable(tableName){
		var self = this;
		var dynamicColumns = [{ title: 'Path', targets: 0 },{ title: 'Key', targets: 1 },
			{ title: 'Parent', targets: 2 }];
				var columnRendering = [ {
					"data": "level",
					"visible": false
				}, {
					"data": "key",
					"visible": false
				}, {
					"data": "parent",
					"visible": false
				}]
				var plan = DataManager.getDataManager().get('currentPlan');
				var targetIndex = 3;
				let filteredPeriods = self.getFilterPeriodDatasets()


				if(self.selectedPeriodValues().length>0){
					filteredPeriods = self.selectedPeriodValues().map(d=>{
						for(var i=0;i<self.periodSets().length;i++){
							if(`${self.periodSets()[i].year}-${self.periodSets()[i].period}`=== d){
								return self.periodSets()[i]
						}
					}
				})}

			  	filteredPeriods.map((d)=>{
					var phaseAltName = `${d.year}-${d.period}`;
					var periodSetColumns;
					if(plan.get('periodKind')===self.labels["Yearly"]()){
						periodSetColumns = d.year
					}else{
						periodSetColumns = phaseAltName
					}		
					self.altMap[phaseAltName] = d.altId;
					self.yearPeriodMap[phaseAltName] = d;
					
					dynamicColumns.push({
						title: periodSetColumns,
						targets: targetIndex
					});

					columnRendering.push({
						"data": phaseAltName,
						"type": "custom",
						"className": "phase-alt-name text-right",
						"render": function (data, type, full, meta) {
							switch (type) {
								case "display":
									return data;
							}
							return data;
						}
					});
					targetIndex++;
				})
				
				let displayed;
				var dt = $('#'+tableName).DataTable({
					"data": self.dynamicTargetRows,
					"bLengthChange": false,
					"pageLength": 50,
					"ordering": false,
					"pageResize": true,
					"columnDefs": dynamicColumns,
					"columns": columnRendering,
					'dom': 'lrt',
					"scrollY":"70vh",
                    "bStateSave": true,                    
                    "paging":false,
					"searching":true,
                    "scrollCollapse": true,
					"initComplete": function(settings, json) {
						var rowsToHide = [];
						displayed = new Set(_.pluck(this.dynamicRows, 'key'));
						var table = $('#'+tableName).DataTable();
						table.rows().every(function () {
							var data = this.data(); 
							var key = data.key; 

							if (!data.subComponentVal) {
								rowsToHide.push(key); 
							}
						});
						if(rowsToHide.length>0){
							rowsToHide.forEach(function(key) {
								var rowIndex = table.rows().indexes().filter(function(i) {
									return table.row(i).data().key === key; 
								})[0];
						
								if (rowIndex !== undefined) {
									var tr = $(table.row(rowIndex).node());
									displayed.add(key);
									tr.addClass('details'); 
								}
							});
						
							var regex = "^(0";
							displayed.forEach(function (value) {
								regex = regex + "|" + value;
							});
							regex = regex + ")$";
							table.columns([2]).search(regex, true, false).draw();
						}
					},
					
				});

				$('#'+tableName).on('init.dt', function () {
					dt.columns([2]).search('^(0)$', true, false).draw();
				});
				
				$('#'+"fixedColumnsMultiTable"+' tbody').on('click', 'tr td:first-child', function () {
					var tr = $(this).closest('tr');
					var row = $('#fixedColumnsMultiTable').DataTable().row(tr);
					var key = row.data().key;
					
					if (displayed.has(key)) {
						var childKeyList = [];
						function getChildKeys(childKeyList,key){
							for (var k = 0; k < self.dynamicRows.length; k++) {
								if (self.dynamicRows[k]["parent"] == key) {
									var childKey = self.dynamicRows[k].key;
									childKeyList.push(childKey);
									getChildKeys(childKeyList,childKey);
								}
							}
							return childKeyList
						}
						getChildKeys(childKeyList,key);
						var childList = childKeyList.length;
						while(childList--){
							displayed.delete(childKeyList[childList]);
						}						
						displayed.delete(key);
						tr.removeClass('details');
					} else {
						displayed.add(key);
						tr.addClass('details');
					}
					
					var regex = "^(0";
					displayed.forEach(function (value) {
						regex = regex + "|" + value;
					});
					regex = regex + ")$";
					dt.columns([2]).search(regex, true, false).draw();
				});		
				$('#firstScenarioTableMultiContainer').on('scroll', function() {
					var scrollLeft = $(this).scrollLeft();
					$('#secondScenarioTableContainer').scrollLeft(scrollLeft);
				});

				$('#secondScenarioTable_wrapper .dt-scroll-body').on('scroll', function() {
					var scrollTop = $(this).scrollTop();  
					$('#firstScenarioTableMultiTable_wrapper .dt-scroll-body').scrollTop(scrollTop);
					$('#fixedColumnsMultiTable_wrapper .dt-scroll-body').scrollTop(scrollTop);
				});
	}


	createFixedColumnTable(tableName){
		var self = this;
		var dynamicColumns = [{ title: 'Select', targets: 0 },{ title: 'Path', targets: 1 },{ title: 'Key', targets: 2 },
					{ title: 'Parent', targets: 3 },{ title: 'Name', targets: 4},{ title: 'Type', targets: 5}];
				var columnRendering = [{
					"class": "details-control",
					"orderable": false,
					"data": null,
					"defaultContent": "",
					"createdCell": function (td, cellData, rowData, row, col) {
						if (rowData.level > 0) {
							td.className = td.className + ' level-' + rowData.level;
							if(rowData.isActivityValue){
								td.className = td.className + ' activity';
							}
							if (rowData.type == "Value"){
								td.parentElement.setAttribute("suffix",rowData.suffix);
								td.parentElement.setAttribute("planValue",rowData.planValue);
							} /*else if(self.showOnlyValues){
								td.parentElement.style.display = "none";
							}*/
						}
					}
				}, {
					"data": "level",
					"visible": false
				}, {
					"data": "key",
					"visible": false
				}, {
					"data": "parent",
					"visible": false
				}, {
					"data": "name",
					"render": function (data, type, full, meta) {
							var order = self.buildOrderObject($('#'+tableName).DataTable(), full['key'], 'name').child;
							switch (type) {
								case "display":
									return data;
								case "filter":
									return data;
								case "sort":
									return order;
							}
							return data;
						}
					},
					{
						"data": "type",
                         "visible" : true
					}
				]
				var plan = DataManager.getDataManager().get('currentPlan');
				var targetIndex = 6;
				let filteredPeriods = this.getFilterPeriodDatasets();

				if(self.selectedPeriodValues().length>0){
					filteredPeriods = self.selectedPeriodValues().map(d=>{
						for(var i=0;i<self.periodSets().length;i++){
							if(`${self.periodSets()[i].year}-${self.periodSets()[i].period}`=== d){
								return self.periodSets()[i]
						}
					}
				})}

			  	filteredPeriods.map((d)=>{
					var phaseAltName = `${d.year}-${d.period}`;
					var periodSetColumns;
					if(plan.get('periodKind')===self.labels["Yearly"]()){
						periodSetColumns = d.year
					}else{
						periodSetColumns = phaseAltName
					}		
					self.altMap[phaseAltName] = d.altId;
					self.yearPeriodMap[phaseAltName] = d;
					
					targetIndex++;
				})
				
				let displayedFixedRow
				var dt = $('#'+tableName).DataTable({
					"data": self.dynamicRows,
					"bLengthChange": false,
					"pageLength": 50,
					"ordering": false,
					"pageResize": true,
					"columnDefs": dynamicColumns,
					"columns": columnRendering,
                    "bStateSave": true,
					'fixedHeader':true,  
					"scrollY":"70vh",
                    dom: 'lrt',
                    "paging":false,
					"searching":true,
                    "scrollCollapse": true,
					
					"initComplete": function(settings, json) {
						var rowsToHide = [];
						displayedFixedRow = new Set(_.pluck(this.dynamicRows, 'key'));
						var table = $('#'+tableName).DataTable();
						table.rows().every(function () {
							var data = this.data(); 
							var key = data.key; 

							if (!data.subComponentVal) {
								rowsToHide.push(key); 
							}
						});
						if(rowsToHide.length>0){
							rowsToHide.forEach(function(key) {
								var rowIndex = table.rows().indexes().filter(function(i) {
									return table.row(i).data().key === key; 
								})[0];
						
								if (rowIndex !== undefined) {
									var tr = $(table.row(rowIndex).node());
									displayedFixedRow.add(key);
									tr.addClass('details'); 
								}
							});
						
							var regex = "^(0";
							displayedFixedRow.forEach(function (value) {
								regex = regex + "|" + value;
							});
							regex = regex + ")$";
							table.columns([3]).search(regex, true, false).draw();
						}
					},
					"fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
						if(aData["type"] === "Value"){
							if(aData["calc"] == true){
								var imageUrl = '/img/calculator.png';
								$('td:eq(1)', nRow).css('background', 'white url(' + imageUrl + ') no-repeat right');
							}							
						}
					}					
				});

				$('#'+tableName).on('init.dt', function () {
					dt.columns([3]).search('^(0)$', true, false).draw();
				});
				$('#'+tableName+' tbody').on('click', 'tr td:first-child', function () {
					var tr = $(this).closest('tr');
					var row = dt.row(tr);
					var key = row.data().key;
					
					
					if (displayedFixedRow.has(key)) {
						var childKeyList = [];
						function getChildKeys(childKeyList,key){
							for (var k = 0; k < self.dynamicRows.length; k++) {
								if (self.dynamicRows[k]["parent"] == key) {
									var childKey = self.dynamicRows[k].key;
									childKeyList.push(childKey);
									getChildKeys(childKeyList,childKey);
								}
							}
							return childKeyList
						}
						getChildKeys(childKeyList,key);
						var childList = childKeyList.length;
						while(childList--){
							displayedFixedRow.delete(childKeyList[childList]);
						}							
						displayedFixedRow.delete(key);
						tr.removeClass('details');
					} else {
						displayedFixedRow.add(key);
						tr.addClass('details');
					}
					
					var regex = "^(0";
					displayedFixedRow.forEach(function (value) {
						regex = regex + "|" + value;
					});
					regex = regex + ")$";
					dt.columns([3]).search(regex, true, false).draw();
				});		

				$('#fixedColumns_wrapper .dt-scroll-body').on('scroll', function() {
					var scrollTop = $(this).scrollTop();  
					$('#firstScenarioTable_wrapper .dt-scroll-body').scrollTop(scrollTop);
				});
				
				$('#fixedColumnsMultiTable_wrapper .dt-scroll-body').on('scroll', function() {
					var scrollTop = $(this).scrollTop();  
					$('#secondScenarioTable_wrapper .dt-scroll-body').scrollTop(scrollTop);
					$('#firstScenarioTableMultiTable_wrapper .dt-scroll-body').scrollTop(scrollTop);
				});
		}


	getFilterPeriodDatasets(){
		var self = this;
		const fromObj = self.periodSets().find(d=>d.year===self.selectedFromYear() && d.period === self.selectedFromPeriod());
			const toObj =  self.periodSets().find(d=>d.year===self.selectedToYear() && d.period === self.selectedToPeriod());
			let filteredPeriods = self.periodSets().filter(period => {
				const fromYear = fromObj.year;
				const fromPeriod = fromObj.periodNumber;
				const toYear = toObj.year;
				const toPeriod = toObj.periodNumber;
				
				const isAfterFrom = period.year > fromYear || (period.year === fromYear && period.periodNumber >= fromPeriod);
				const isBeforeTo = period.year < toYear || (period.year === toYear && period.periodNumber <= toPeriod);
				
				return isAfterFrom && isBeforeTo;
			});
		return filteredPeriods
	}
	
	
	// createOfflineTable(tableName){
	// 	var self = this;
	// 	/*self.showOnlyValues = false;
	// 	if(self.selectedChartType() == "Show Only Values"){
	// 		self.showOnlyValues = true;
	// 	}*/
	// 	var dynamicColumns = [{ title: 'Select', targets: 0 },{ title: 'Path', targets: 1 },{ title: 'Key', targets: 2 },
	// 				{ title: 'Parent', targets: 3 },{ title: 'Name', targets: 4},{ title: 'Type', targets: 5}];
	// 			var columnRendering = [{
	// 				"class": "details-control",
	// 				"orderable": false,
	// 				"data": null,
	// 				"defaultContent": "",
	// 				"createdCell": function (td, cellData, rowData, row, col) {
	// 					if (rowData.level > 0) {
	// 						td.className = td.className + ' level-' + rowData.level;
	// 						if(rowData.isActivityValue){
	// 							td.className = td.className + ' activity';
	// 						}
	// 						if (rowData.type == "Value"){
	// 							td.parentElement.setAttribute("suffix",rowData.suffix);
	// 							td.parentElement.setAttribute("planValue",rowData.planValue);
	// 						} /*else if(self.showOnlyValues){
	// 							td.parentElement.style.display = "none";
	// 						}*/
	// 					}/* else if(self.showOnlyValues){
	// 						td.parentElement.style.display = "none";
	// 					}*/
	// 				}
	// 			}, {
	// 				"data": "level",
	// 				"visible": false
	// 			}, {
	// 				"data": "key",
	// 				"visible": false
	// 			}, {
	// 				"data": "parent",
	// 				"visible": false
	// 			}, {
	// 				"data": "name",
	// 				"render": function (data, type, full, meta) {
	// 						var order = self.buildOrderObject($('#'+tableName).DataTable(), full['key'], 'name').child;
	// 						switch (type) {
	// 							case "display":
	// 								return data;
	// 							case "filter":
	// 								return data;
	// 							case "sort":
	// 								return order;
	// 						}
	// 						return data;
	// 					}
	// 				},
	// 				{
	// 					"data": "type",
    //                      "visible" : true
	// 				}
	// 			]
	// 			var plan = DataManager.getDataManager().get('currentPlan');
	// 			var targetIndex = 6;
	// 			let filteredPeriods = this.getFilterPeriodDatasets()

	// 			if(self.selectedPeriodValues().length>0){
	// 				filteredPeriods = self.selectedPeriodValues().map(d=>{
	// 					for(var i=0;i<self.periodSets().length;i++){
	// 						if(`${self.periodSets()[i].year}-${self.periodSets()[i].period}`=== d){
	// 							return self.periodSets()[i]
	// 					}
	// 				}
	// 			})}


	// 		  	filteredPeriods.map((d)=>{
	// 				var phaseAltName = `${d.year}-${d.period}`;
	// 				var periodSetColumns;
	// 				if(plan.get('periodKind')===self.labels["Yearly"]()){
	// 					periodSetColumns = d.year
	// 				}else{
	// 					periodSetColumns = phaseAltName
	// 				}		
	// 				self.altMap[phaseAltName] = d.altId;
	// 				self.yearPeriodMap[phaseAltName] = d;
					
	// 				dynamicColumns.push({
	// 					title: periodSetColumns,
	// 					targets: targetIndex
	// 				});

	// 				columnRendering.push({
	// 					"data": phaseAltName,
	// 					"type": "custom",
	// 					"className": "phase-alt-name text-right",
	// 					"render": function (data, type, full, meta) {
	// 						//var order = self.buildOrderObject($('#'+tableName).DataTable(), full['key'], phaseAltName).child
	// 						switch (type) {
	// 							case "display":
	// 								return data;
	// 							/*case "filter":
	// 								var val = data != '-'?data.value:data;
	// 								return val;
	// 							case "sort":
	// 								return order;*/
	// 						}
	// 						return data;
	// 					}
	// 				});
	// 				targetIndex++;
	// 			})
				
	// 			let displayed;
	// 			var dt = $('#'+tableName).DataTable({
	// 				"data": self.dynamicRows,
	// 				"bLengthChange": false,
	// 				"pageLength": 50,
	// 				"ordering": false,
	// 				"pageResize": true,
	// 				"columnDefs": dynamicColumns,
	// 				"columns": columnRendering,
    //                 "bStateSave": true,
	// 				"fixedColumns": {
    //                     start: 3,
    //                 },
	// 				'fixedHeader':true,
    //                 'dom': 'lrt',
    //                 "paging":false,
	// 				"searching":true,
    //                 "scrollCollapse": true,
	// 				"scrollY":"400px",
	// 				"initComplete": function(settings, json) {
	// 					var rowsToHide = [];
	// 					displayed = new Set(_.pluck(this.dynamicRows, 'key'));
	// 					var table = $('#'+tableName).DataTable();
	// 					table.rows().every(function () {
	// 						var data = this.data(); 
	// 						var key = data.key; 

	// 						if (!data.subComponentVal) {
	// 							rowsToHide.push(key); 
	// 						}
	// 					});
	// 					if(rowsToHide.length>0){
	// 						rowsToHide.forEach(function(key) {
	// 							var rowIndex = table.rows().indexes().filter(function(i) {
	// 								return table.row(i).data().key === key; 
	// 							})[0];
						
	// 							if (rowIndex !== undefined) {
	// 								var tr = $(table.row(rowIndex).node());
	// 								displayed.add(key);
	// 								tr.addClass('details'); 
	// 							}
	// 						});
						
	// 						var regex = "^(0";
	// 						displayed.forEach(function (value) {
	// 							regex = regex + "|" + value;
	// 						});
	// 						regex = regex + ")$";
	// 						table.columns([3]).search(regex, true, false).draw();
	// 					}
	// 				},
	// 				"fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
	// 					if(aData["type"] === "Value"){
	// 						if(aData["calc"] == true){
	// 							var imageUrl = '/img/calculator.png';
	// 							$('td:eq(1)', nRow).css('background', 'white url(' + imageUrl + ') no-repeat right');
	// 						}							
	// 						$('td', nRow).each(function () {
	// 							if (this.className == "phase-alt-name text-right" ) {
	// 									var a = document.createElement('a');
	// 									a.style.color = 'inherit';
	// 									a.style.cursor= 'pointer';
	// 									a.setAttribute('id',aData["suffix"]);
	// 									var val = this.innerText;
	// 									a.appendChild(document.createTextNode(val));
	// 									this.innerText = '';
	// 									this.append(a);
	// 									$(a).on('contextmenu',function(event){
	// 										event.preventDefault();
	// 										var qty = event.currentTarget.innerText;
	// 										var cellIndex = event.currentTarget.parentElement.cellIndex;
	// 										var phaseAltName = $('#firstScenarioTable thead tr th').eq(cellIndex+2).find('.dt-column-title').text();
	// 										var currentPlan = DataManager.getDataManager().get('currentPlan');
	// 										if(currentPlan.get("periodKind")==="Yearly"){
	// 											phaseAltName = phaseAltName+"-1"
	// 										}
	// 										var altId = self.altMap[phaseAltName];
	// 										var suffix = event.currentTarget.parentElement.parentElement.getAttribute('suffix');
	// 										var valId = altId+suffix;
	// 										var planValue = event.currentTarget.parentElement.parentElement.getAttribute('planValue');
	// 										if(planValue == 'true'){
	// 											valId = window.plansKey+suffix;
	// 										}
	// 										self.showProjectionDropdown(event,valId,qty);	
	// 									});
	// 								/*var colu = theadChildrens[this.cellIndex].innerText;
	// 								if(aData[colu + "-satisfactionSmiley"]){
	// 									var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
	// 									svg.setAttribute("width", 21);
	// 									svg.setAttribute("height", 20);
	// 									svg.setAttribute("style",'vertical-align:bottom;');
	// 									svg.setAttribute("viewBox", "-3 -2 26 26");
	// 									svg.setAttribute("fill", aData[colu + "-smileyColor"]);
	// 									//svg.setAttribute("correctiveActions",found[8]);
	// 									//svg.style.cursor = 'pointer';
	// 									var link = document.createElementNS("http://www.w3.org/2000/svg", "path");
	// 									var smileySrc = '';
	// 									for(var j=0;j<svgSmiliesList.length;j++){
	// 										if(svgSmiliesList[j].value === aData[colu + "-satisfactionSmiley"]) {
	// 											smileySrc = svgSmiliesList[j].src;
	// 											break;
	// 										}
	// 									}

	// 									link.setAttribute("d", smileySrc);
	// 									svg.appendChild(link);
	// 									//$('td:eq(3)', nRow).append(svg);
	// 									this.append(svg);
	// 								}
	// 								}*/
	// 								if(aData["calc"] != true && self.enableDirectEditing){
	// 									$(this).on("keydown", function (event) {
	// 										$(this).prop('oldValue', $(this).val());
	// 										window.utils.checkNumericValue(event, this);
	// 									});
	// 									$(this).on("keyup", function (event) {
	// 										let currentValue = $(event.target).val();
	// 										var decimalLimit = 4;
	// 										var rgx = new RegExp(`^\\-?[0-9]*\\.?[0-9]{0,${decimalLimit}}$`);
	// 										if (!rgx.test(currentValue)) {
	// 											$(event.target).val($(this).prop('oldValue'));
	// 										}
	// 									});

	// 									$(this).on('dblclick', function() {
	// 										var cell = $(this);
	// 										if (!cell.is('[contenteditable]')) {
	// 											cell.attr('contenteditable', 'true').focus();
	// 										}
	// 									});
						
	// 									$(this).blur(function(event){
	// 										//var tr = event.currentTarget.parentElement;
	// 										var qty = event.currentTarget.innerText;
	// 										var phaseAltName = $('tr th').eq(event.currentTarget.cellIndex).find('span.dt-column-title').text();
	// 										var currentPlan = DataManager.getDataManager().get('currentPlan');
	// 										if(currentPlan.get("periodKind")==="Yearly"){
	// 											phaseAltName = phaseAltName+"-1"
	// 										}
	// 										var altId = self.altMap[phaseAltName];
	// 										var suffix = event.currentTarget.parentElement.getAttribute('suffix');
	// 										var valId = altId+suffix;
	// 										var planValue = event.currentTarget.parentElement.getAttribute('planValue');
	// 										if(planValue == 'true'){
	// 											valId = window.plansKey+suffix;
	// 										}
	// 										self.valueChange(valId,qty,phaseAltName,event);
	// 										$(this).removeAttr('contenteditable');
	// 									});
	// 								}
	// 								$(a).click(function(event){
	// 									var currentTarget = event.currentTarget;
	// 									// var index = currentTarget.parentElement.cellIndex;
	// 									var phaseAltName = $('tr th').eq(event.currentTarget.parentNode.cellIndex).find('span.dt-column-title').text();
	// 									var currentPlan = DataManager.getDataManager().get('currentPlan');
	// 									if(currentPlan.get("periodKind")==="Yearly"){
	// 										phaseAltName = phaseAltName+"-1"
	// 									}
	// 									var altId = self.altMap[phaseAltName];
	// 									var suffix = currentTarget.getAttribute('id');
	// 									var valId = altId+suffix;
	// 									// var obj1 = {id:valId,altId:altId,timeStamp:null};
	// 									var altModel = window.utils.getElementModel(altId,['transformation.Alternative']);
	// 									var planValue = event.currentTarget.parentElement.parentElement.getAttribute('planValue');
	// 										if(planValue == 'true'){
	// 											valId = window.plansKey+suffix;
	// 										}
	// 									var cell = $(this).parent();
	// 									if (!$(cell).is('[contenteditable]')) {
	// 										altModel.showAggregationView(valId);
	// 									}

	// 								});
	// 							}
	// 						});
	// 					}
	// 				}
	// 			});

	// 			$('#'+tableName).on('init.dt', function () {
	// 				dt.columns([3]).search('^(0)$', true, false).draw();
	// 			});
			
	// 			$('#'+tableName+' tbody').on('click', 'tr td:first-child', function () {
	// 				var tr = $(this).closest('tr');
	// 				var row = dt.row(tr);
	// 				var key = row.data().key;
					
	// 				if (displayed.has(key)) {
	// 					var childKeyList = [];
	// 					function getChildKeys(childKeyList,key){
	// 						for (var k = 0; k < self.dynamicRows.length; k++) {
	// 							if (self.dynamicRows[k]["parent"] == key) {
	// 								var childKey = self.dynamicRows[k].key;
	// 								childKeyList.push(childKey);
	// 								getChildKeys(childKeyList,childKey);
	// 							}
	// 						}
	// 						return childKeyList
	// 					}
	// 					getChildKeys(childKeyList,key);
	// 					var childList = childKeyList.length;
	// 					while(childList--){
	// 						displayed.delete(childKeyList[childList]);
	// 					}							
	// 					displayed.delete(key);
	// 					tr.removeClass('details');
	// 				} else {
	// 					displayed.add(key);
	// 					tr.addClass('details');
	// 				}
					
	// 				var regex = "^(0";
	// 				displayed.forEach(function (value) {
	// 					regex = regex + "|" + value;
	// 				});
	// 				regex = regex + ")$";
	// 				dt.columns([3]).search(regex, true, false).draw();
	// 			});		
	// 		}

			showModal(modalId,valId,type,title,qty,phaseAltName){
				var self = this;
				let yearPeriod = utils.calculateYearPeriod(self.scenario.get("startTime") , 1, self.periodKind );
				var addOptions = {modalHeader:title,periodsets:self.periodSets(),scenarioExeId:self.defaultExecutionScenaroId,planValue:type,valId:valId,yearPeriod:yearPeriod,qty:qty,id:modalId,phaseAltName:phaseAltName};
				window.getAndCreateModalDialog(null, window.utils.htmlEscape(modalId), Plan2Mixin, self.defaultExecutionScenaro, "Projections",null,addOptions );
			}

			showProjectionDropdown(event,valId,qty,projectionStartPeriod){
				var self = this;
				const arr = []
				//var altId = self.altMap[phaseAltName];
				var values =  window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
				if(values && values.get('valueType') == ValueType.Atomic && qty!=="-"){
					const currentPlan = DataManager.getDataManager().get('currentPlan');
					const scenarioId = currentPlan.get("defaultScenario");
					const scenarioData = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
					var periodDatasets = scenarioData.getPlanDatasets(currentPlan);
					for(let i = 0;i<periodDatasets.length;i++){
						var periodDataset = self.defaultExecutionScenaro.get("input").findWhere({period: periodDatasets[i].period,year: periodDatasets[i].year,periodKind : currentPlan.get("periodKind")});
						if(periodDataset.get(valId)!==undefined){
							arr.push(periodDataset.get(valId));
							
						}
					}
					// var options = ["Extrapolate baseline", "Extrapolate trend", "Interpolate", "Interpolate-extrapolate"];
					// if(arr.length>1 || periodDatasets.length===1){
					// 	options = ["Extrapolate trend", "Interpolate", "Interpolate-extrapolate"]
					// }

					var options

					if(arr.filter(d=>d!=='').length===1 && periodDatasets.length>1){
						options = ["Extrapolate baseline"];
					}

					function populateDropdown(menuElement, options, event) {
						menuElement.innerHTML = ""; // Clear existing buttons
						options.forEach(option => {
							const button = document.createElement("button");
							button.textContent = option;
							button.style.display = "block";
							button.style.width = "100%";
							button.style.border = "none";
							button.style.background = "none";
							button.style.padding = "10px";
							button.style.cursor = "pointer";
							button.style.textAlign = "left";
							button.onmouseover = () => button.style.backgroundColor = "#f0f0f0";
							button.onmouseout = () => button.style.backgroundColor = "white";
							button.onclick = () => {
								var phaseAltName = $('#firstScenarioTable tr th').eq(event.currentTarget.parentElement.cellIndex)[0].innerHTML;
								menuElement.style.display = "none";
								var modalId = "projections"+event.target.id;
								var type = event.target.parentElement.parentElement.getAttribute("planValue");
								if(option == "Extrapolate baseline") {
									self.showModal(modalId,valId,type,"Extrapolate Baseline",event.currentTarget.innerHTML,projectionStartPeriod);
									// window.vdmModelView.extrapolateBaseline(valId,phaseAltName,event.currentTarget.innerHTML);
								} else if(option == "Extrapolate trend") {
									window.vdmModelView.extrapolateTrend(valId,phaseAltName);
								} else if(option == "Interpolate") {
									window.vdmModelView.interpolate(valId,phaseAltName);
								} else if(option == "Interpolate-extrapolate") {
									window.vdmModelView.interpolateextrapolate(valId,phaseAltName);
								} else {
									console.log(`You clicked: ${option}`);
								}
								
							};
							menuElement.appendChild(button);
						});
					}

					function showDropdownMenu(event) {
						const dropdownMenu = document.getElementById("dropdownMenu");
						const cellRect = event.target.getBoundingClientRect();
						populateDropdown(dropdownMenu, options, event);
						var left = cellRect.left - 50;
						var top = cellRect.bottom - 50;
						dropdownMenu.style.display = "block";
						dropdownMenu.style.left = `${left}px`;
						dropdownMenu.style.top = `${top}px`;

						function handleClickOutside(event) {
							var self = this;
							if (!dropdownMenu.contains(event.target) && !event.target.classList.contains('dropdown-cell')) {
								dropdownMenu.style.display = "none";
								document.removeEventListener('click', handleClickOutside);
							}
						}
						document.addEventListener('click', handleClickOutside);
					}
					options && showDropdownMenu(event);
				}else {
					bootbox.alert("values are incorrect");
				}
			}

			destroyTable(table){
				if ($.fn.dataTable.isDataTable(table)) {
					$(table).DataTable().destroy();
				}
				$(table).html('');
				/*window.utils.setDatatableEmpty('offlineTable');*/
				$(table).html('');
				$("<table id="+table+" class='display' width='100%' cellspacing='0'>    </table>").insertAfter($(table+"_wrapper"));
				$(table+"_wrapper").remove();
			}

			valueChange(valId,qty,phaseAltName,event,altId){
				var self = this;
				var valueName = event.currentTarget.parentElement.getAttribute('valueName')
				const currentPlan = DataManager.getDataManager().get('currentPlan');
				var yearPeriod = self.yearPeriodMap[phaseAltName];
				var	inputPeriodDataset = self.defaultExecutionScenaro.get("input").findWhere({period: yearPeriod.periodNumber,year: yearPeriod.year,periodKind : currentPlan.get("periodKind")});				
				var valModal = window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']);
				if(inputPeriodDataset && qty!=="-" && qty!==""){
					if(valModal){
						if(valueName === "Weight"){
							inputPeriodDataset.set(valId,parseFloat(qty))
						}else if(valueName === "Recipient Opinion"){
							inputPeriodDataset.set(valId,qty)
						}else {
							inputPeriodDataset.set(valId,qty);
						}
					}else {
						if(["Weight","Recipient Opinion"].includes(valueName)){
							valId = valId.replace(valueName, "");
							valModal = window.utils.getElementModel(valId,['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']);
							var viewAlternative  = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({ id: altId });
							if(valueName === "Weight"){								
								valModal.primeWeight(yearPeriod.year,yearPeriod.period,qty,self.scenario,viewAlternative);
							}
							if(valueName === "Recipient Opinion"){
								valModal.primeRecipientOpinion(yearPeriod.year,yearPeriod.period,qty,self.scenario,viewAlternative);
							}
						}
					}
				}
			}

			tableColumnWidthCalculation(table) {
				const container = document.getElementById(table);
				const containerWidth = container.offsetWidth;				
				const columnWidth = containerWidth / 3;
			
				const tableColumnsdata = document.querySelectorAll('#'+table+" "+ 'th', '#'+table+" "+ 'td');
			
				tableColumnsdata.forEach(column => {
					column.style.setProperty('min-width', `${columnWidth}px`, 'important');
				});
			}

		afterRenderView(node, view) {
			var self = view;
			self.viewloaded = true;
			self.getMultiSelectOptGroups(self.scenarioOptions(),'selectedScenario',self.encodeId);			
			$('#selectedScenario' + self.encodeId).multiselect('rebuild');
			$('#selectedScenario' + self.encodeId).multiselect('select', [self.scenarioOptions()[0].options[0].value]);
			var currentplan = DataManager.getDataManager().get('currentPlan')
			var targetSc = currentplan.getTargetScenarioExecution();
			if(targetSc){
				self.renderTargetDynamicRows(targetSc.id);
			}
			if(self.showMultipleScenarioTable()){
				self.createFixedColumnTable("fixedColumns");
				self.firstScenarioTable("firstScenarioTable");
				self.secondScenarioTable("secondScenarioTable");
				// self.tableColumnWidthCalculation('secondScenarioTableContainer');
			}else {
				self.createFixedColumnTable("fixedColumns");
				self.firstScenarioTable("firstScenarioTable");
				// self.tableColumnWidthCalculation('firstScenarioTableContainer');
			}
	
			$('#selectPeriod' + self.encodeId).multiselect({
				enableClickableOptGroups: false,
				numberDisplayed : 0,
				nonSelectedText: '0 Selected',
				
			});
			$('#selectedScenario' + self.encodeId).multiselect({
				enableClickableOptGroups: false,
				numberDisplayed : 0,
				
			});			
			

			$('#selectedScenario' + self.encodeId).change(function() {
				var selectedOptions = $('#selectedScenario' + self.encodeId + ' option:selected');
				self.selectedScenarioTables.removeAll();
				selectedOptions.each(function(index) {
					self.selectedScenarioTables().push($(this).val());

					if(index===0){
						self.header1($(this).text())
					}
					if(index===1){
						self.header2($(this).text())
					}
				})
				if(self.selectedScenarioTables().length>1){
					self.showMultipleScenarioTable(true);
					self.reRenderTables('fixedColumnsMultiTable',function(){self.createFixedColumnTable('fixedColumnsMultiTable')});
					self.reRenderTables('firstScenarioTableMultiTable',function(){self.firstScenarioTable('firstScenarioTableMultiTable')});
					self.reRenderTables('secondScenarioTable',function(){self.secondScenarioTable('secondScenarioTable')});
					self.showExportButton(false);
				}else{
					self.showMultipleScenarioTable(false);
					self.reRenderTables('fixedColumns',function(){self.createFixedColumnTable('fixedColumns')});
					self.reRenderTables('firstScenarioTable',function(){self.firstScenarioTable('firstScenarioTable')});
					self.showExportButton(true);
				}
            });
			
			$('#selectPeriod' + self.encodeId).change(function() {
				var selectedOptions = $('#selectPeriod' + self.encodeId + ' option:selected');
				self.selectedPeriodValues.removeAll()
				selectedOptions.each(function() {
					self.selectedPeriodValues.push($(this).text());
				})
				if(self.showMultipleScenarioTable()){
					self.reRenderTables('fixedColumnsMultiTable',function(){self.createFixedColumnTable('fixedColumnsMultiTable')});
					self.reRenderTables('firstScenarioTableMultiTable',function(){self.firstScenarioTable('firstScenarioTableMultiTable')});
					self.reRenderTables('secondScenarioTable',function(){self.secondScenarioTable('secondScenarioTable')});
				}else{
					self.reRenderTables('fixedColumns',function(){self.createFixedColumnTable('fixedColumns')});
					self.reRenderTables('firstScenarioTable',function(){self.firstScenarioTable('firstScenarioTable')});
				}
            });
			$(".switch-input").change(function() {
				// self.enableDirectEditing = !self.enableDirectEditing;
				self.destroyTable('#offlineTable');
				self.renderDynamicRows(self.defaultExecutionScenaroId);
				self.createOfflineTable("offlineTable");
			});
			
			var dropdownMenu = $("#dropdownMenu"+self.encodeId);
			$('#dropdown'+self.encodeId).on('click', function(event) {
    			event.stopPropagation();
    			dropdownMenu.toggle();
			});
			$('#ValuesView').on('click', function(event) {
			    if (!dropdownMenu.is(event.target) && dropdownMenu.has(event.target).length === 0) {
			        dropdownMenu.hide();
			    }
			});
			window.utils.stopSpinner('valueView');
		}
		optimize(){
			var defaultScenario = DataManager.getDataManager().get('defaultScenario');
			var id = window.utils.htmlEscape(window.guidGenerator()) + "optimize";
			window.getAndCreateModalDialog(null, id, defaultScenario.getMixinClass(), defaultScenario, "Optimize", null, null);
			
			/*var dataManager = DataManager.getDataManager();
			var opType = dataManager.get('defaultScenario').get('optimizationType');
			var opObj = dataManager.get('defaultScenario').get("optimizationObjective");
			var opPeriod = dataManager.get('defaultScenario').get("optimizationPeriod");
			var exp = opObj ? opObj.get('expressionStr') : null;
			if(exp != null){
				var valModel = window.utils.getElementModel(exp, ['vdml.ValuePropositionComponent','vdml.ValueAdd','vdml.ValueElement']);
				var valName = valModel ? valModel.get('name') : exp;
				var dialogContent = `
					<div>
						<ul>
							<li>optimization Type : `+opType+`</li>
							<li>optimization Period : `+opPeriod+`</li>
							<li>optimization Objective : `+valName+`</li>
						</ul>
						<button id="optimizeButton" class="btn btn-primary">Optimize</button>
					</div>
				`;
			}
			bootbox.dialog({
				title: "Optimization",
				message: dialogContent,
				size: 'large'
			});	
			$('#optimizeButton').click(function () {
				//const option1 = $('#select1').val();
				//const option2 = $('#select2').val();
				bootbox.alert(`Under development`);
			});*/
		}
		calculate(){
			var self = this;
			var dataManager = DataManager.getDataManager();
			if(self.defaultExecutionScenaro.get('lastCalculated') && self.skipBuiding){
				window.utils.startSpinner('calculate', "Calculating...");
				dataManager.calculateJuliaModel(function(){
					self.showLastCalulatedDate();
					self.destroyTable('#offlineTable');
					self.renderDynamicRows(self.defaultExecutionScenaroId);
					self.createOfflineTable("offlineTable");
					window.utils.stopSpinner('calculate');
				});
			} else {
				window.utils.startSpinner('calculate', "Building...");
				dataManager.buidlJuliaModel(function(err){
					if(err){
						window.utils.stopSpinner('calculate');
						return;
					}
					window.utils.showSpinnerText("Calculating...");
					window.setTimeout(function () {
						dataManager.calculateJuliaModel(function(){
							self.showLastCalulatedDate();
							self.destroyTable('#offlineTable');
							self.renderDynamicRows(self.defaultExecutionScenaroId);
							self.createOfflineTable("offlineTable");
							window.utils.stopSpinner('calculate');
						});
					}, 500);
				});
			}
		}
		showLastCalulatedDate(){
			var self = this;
			if(self.defaultExecutionScenaro.get('lastCalculated') != null){
				var lastCalc = new Date(self.defaultExecutionScenaro.get('lastCalculated'));
				self.lastCalculate('Last Calculated on '+ lastCalc.toLocaleDateString() + " "+ lastCalc.toLocaleTimeString());
			} else {
				self.lastCalculate('');
			}
		}

		getBMRowData(altColumnList,keyIndex,dynamicRow,periodDataset){
			var self = this
			var plan = DataManager.getDataManager().get('currentPlan');
			self.periodSets().map((d,i)=>{
					var altModel = window.utils.getElementModel(d.altId,['transformation.Alternative']);
					var phaseDesignPart = altModel.get("phaseDesignPart");
					var periodKind =  self.model.getPeriodKinds(d.year,d.periodNumber,plan.get("periodKind"));
					var periodKindText = periodKind?.text || d.period;
					var phaseAltName = d.year + "-" + periodKindText;
					for (var k = 0; k < phaseDesignPart.length; k++) {
						var packId = phaseDesignPart.at(k).get("beepReference");
						if (packId.indexOf("Common") == -1 && phaseDesignPart.at(k).get("beepType") === "vdml_ValueDeliveryModel") {
							var packageModel = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({ id: packId });
							if(!packageModel){
								continue;
							}
							for (var l = 0; l < packageModel.get("businessModel").models.length; l++) {
								var bm = packageModel.get("businessModel").models.at(l);
								var bm1Row = self.createDynamicRow(bm.get("name"), bm.get("id"), keyIndex, 1, 0, phaseAltName, self.titleMap[bm.get('type')],altColumnList);
								var bmLevel = keyIndex;
								if(!dynamicRow.includes(bm1Row)){
									dynamicRow.push(bm1Row);
									keyIndex++;
								}else {
									bmLevel = bm1Row.key;
								}
								//var scenario =  alternatives.at(i).getMainScenario(bm.getNestedParent());
								keyIndex = fillValueProposition(bm, keyIndex, bmLevel, periodDataset.input[i],periodDataset.result[i], phaseAltName, altColumnList);
							
								keyIndex = fillMyValueProposition(bm, keyIndex, bmLevel, periodDataset.input[i],periodDataset.result[i], phaseAltName, altColumnList)
							}
						}
					}
			})
			function fillValueProposition(bm, keyIndex, bmLevel, inputPeriodDataset, resultPeriodDataset, phaseAltName, altColumnList) {
				for (var m = 0; m < bm.get("bmValueProposition").length; m++) {
					var bmVp = bm.get("bmValueProposition").models.at(m);
					var vp = bmVp.get("valueProposition");
					var component = bmVp.get("valueProposition").get("component");
					if(component.length == 0){
						continue;
					}
					var bmChild = self.createDynamicRow(bmVp.get("name"), bmVp.get("id"), keyIndex, 2, bmLevel, phaseAltName, self.titleMap[bmVp.get('type')],altColumnList);
					var valueLevel = keyIndex;
					if(!dynamicRow.includes(bmChild)){
						dynamicRow.push(bmChild);
						keyIndex++;
					}else {
						valueLevel = bmChild.key;
					}
					if (inputPeriodDataset || resultPeriodDataset) {
						keyIndex = self.checkValues(component.models, valueLevel, dynamicRow, keyIndex, phaseAltName,inputPeriodDataset,resultPeriodDataset,altColumnList);
						var vpStream = bm.get("bmValueStream").findWhere({"persuedProposition":vp});
						if(vpStream){
							var vpActivity = vpStream.get("valueStreamActivity")
							for(var k=0;k<=vpActivity.length;k++){
								var bmAct = bm.get("bmActivity").findWhere({'activity':vpActivity.at(k)});
								if(bmAct){
									keyIndex = fillActivity(bmAct, keyIndex, valueLevel, inputPeriodDataset,resultPeriodDataset, phaseAltName, altColumnList,vpStream)
								}
							}
						}
						
						
					}
				}
				return keyIndex			
			}
			function fillActivity(bmAct, keyIndex, bmLevel, inputPeriodDataset, resultPeriodDataset, phaseAltName, altColumnList,stream) {
					var actValueList = stream.get("activityValue").models;
					var component = bmAct.get("activityValue");
					// if(component.length == 0){
					// 	continue;
					// }
					var bmChild = self.createDynamicRow(bmAct.get("name"), bmAct.get("id")+stream.get('name'), keyIndex, 3, bmLevel, phaseAltName,self.titleMap[bmAct.get('type')],altColumnList);
					var valueLevel = keyIndex;
					if(!dynamicRow.includes(bmChild)){
						dynamicRow.push(bmChild);
						keyIndex++;
					}else {
						valueLevel = bmChild.key;
					}
					if (inputPeriodDataset||resultPeriodDataset) {
						keyIndex = self.checkValues(component.models, valueLevel, dynamicRow, keyIndex, phaseAltName, inputPeriodDataset, resultPeriodDataset, altColumnList,actValueList);
					}
				return keyIndex;
			}

			function fillMyValueProposition(bm, keyIndex, bmLevel, inputPeriodDataset, resultPeriodDataset, phaseAltName, altColumnList) {
				for (var o = 0; o < bm.get("bmValueFormula").length; o++) {
					var bmMyVP = bm.get("bmValueFormula").models.at(o);
					var mp = bmMyVP.get("valueFormula");
					var component = bmMyVP.get("valueFormula").get("component");
					if(component.length == 0){
						continue;
					}
					var bmChild = self.createDynamicRow(bmMyVP.get("name"), bmMyVP.get("id"), keyIndex, 2, bmLevel, phaseAltName, self.titleMap[bmMyVP.get('type')],altColumnList);
					var valueLevel = keyIndex;
					if(!dynamicRow.includes(bmChild)){
						dynamicRow.push(bmChild);
						keyIndex++;
					}else {
						valueLevel = bmChild.key;
					}
					if (inputPeriodDataset || resultPeriodDataset) {
						keyIndex = self.checkValues(component.models, valueLevel,dynamicRow, keyIndex, phaseAltName, inputPeriodDataset, resultPeriodDataset, altColumnList);
						var mpStream = bm.get("bmValueStream").findWhere({"persuedProposition":mp});
						if(mpStream){
							var mpActivity = mpStream.get("valueStreamActivity")
							for(var k=0;k<=mpActivity.length;k++){
								var bmAct = bm.get("bmActivity").findWhere({'activity':mpActivity.at(k)});
								if(bmAct){
									keyIndex = fillActivity(bmAct, keyIndex, valueLevel, inputPeriodDataset,resultPeriodDataset, phaseAltName, altColumnList, mpStream)
								}
								
							}
						}
					}
				}
				return keyIndex;
			}
		}

		

		createDynamicRow(name,id,key,level,parent,phaseAltName,type,altColumnList){
			var self = this;
			var valObj;
			var compSuffix = id.substr(id.lastIndexOf('@') + 1);
			for (var k = 0; k <	self.dynamicRows.length; k++) {
				if (self.dynamicRows[k]["suffix"] == compSuffix) {
					valObj = self.dynamicRows[k];
				}
				if (valObj != null) {
					break;
				}
			}
			if (valObj == null) {
				valObj = {};
				valObj["DT_RowId"] = key;
				valObj["level"] = level;
				valObj["key"] = key;
				valObj["parent"] = parent;
				valObj["name"] = name;
				valObj["type"] = type;
				valObj["suffix"] = compSuffix;
				if(altColumnList && altColumnList.length) {
					for (var j = 0; j < altColumnList.length; j++) {
						var altName = altColumnList[j];
						if(!valObj[altName]){
							valObj[altName] = "-";
						}
					}
				}
			}
			if(phaseAltName){
				valObj[phaseAltName] = "-";
			}
			return valObj;
		}

		getperiodDataset(plan,scenarioId,callback){
			let periodObject = {};
			var self = this;
			self.periodSets().forEach(item => {
				if (!periodObject[item.year]) {
					periodObject[item.year] = [];
				}
				periodObject[item.year].push(item.periodNumber);
			});
			DataManager.getDataManager().getPeriodDatasetsByScenarioExecution(plan.get('id'), scenarioId, periodObject, (data) => {
				if (data) {
					callback(data);  
				} else {
					callback(new Error("Failed to retrieve period data"));
				}
			});
		}

		renderTargetDynamicRows(scenarioId){
			var self = this;
			var plan = DataManager.getDataManager().get('currentPlan');
			self.dynamicTargetRows = []; 
			var altColumnList = [];
			var keyIndex = 1;
			var planObj = self.createDynamicRow(plan.get('name'), plan.get('id'), keyIndex, 0, 0, null ,self.titleMap[plan.get("type")]);
			self.dynamicTargetRows.push(planObj);
			keyIndex++;
			var planCriterionSet = plan.getOrCreatePlanCriterionSet();
			var planComponents = planCriterionSet.get('component');
			plan.getPeriodDatasetsAsync(scenarioId,self.periodSets(),function(periodDataset){

				self.periodSets().map((d,i)=>{
					var phaseAltName = d.year + "-" + d.period;
					altColumnList.push(phaseAltName);
					planObj[phaseAltName] = "-";
					keyIndex = self.checkValues(planComponents.models, 1, self.dynamicTargetRows, keyIndex, phaseAltName, periodDataset.input[i], periodDataset.result[i], altColumnList);
				
				});
				
				self.getBMRowData(altColumnList,keyIndex,self.dynamicTargetRows,periodDataset)
			});
	
				
		}


		renderDynamicRows(scenarioId){
			var self = this;
			self.dynamicRows = []; 
			var altColumnList = [];
			var plan = DataManager.getDataManager().get('currentPlan');
			var keyIndex = 1;
			var planObj = self.createDynamicRow(plan.get('name'), plan.get('id'), keyIndex, 0, 0, null ,self.titleMap[plan.get("type")]);
			self.dynamicRows.push(planObj);
			keyIndex++;
			var planCriterionSet = plan.getOrCreatePlanCriterionSet();
			var planComponents = planCriterionSet.get('component');
			

			plan.getPeriodDatasetsAsync(scenarioId,self.periodSets(),function(periodDataset){

				self.periodSets().map((d,i)=>{
					var phaseAltName = d.year + "-" + d.period;
					altColumnList.push(phaseAltName);
					planObj[phaseAltName] = "-";
					keyIndex = self.checkValues(planComponents.models, 1, self.dynamicRows, keyIndex, phaseAltName, periodDataset.input[i], periodDataset.result[i], altColumnList);
				
				});
				
				self.getBMRowData(altColumnList,keyIndex,self.dynamicRows,periodDataset)
			});

			
			
			//---------------- plan section start---------------//			
				
		}


		getColumnPeriodDataSets(){
			var self = this
			var plan = DataManager.getDataManager().get('currentPlan');
			
			var steps = self.scenario.get("step")
			for (var step of steps.models){
				var periodsLen = step.get('noPeriods');
				var period = step.get("startPeriod");
				for(var l = 0; l < periodsLen; l++){
					if(l != 0){
						period++;
					}
					let yearPeriod = utils.calculateYearPeriod(self.scenario.get("startTime") , period,plan.get("periodKind"));
					//var phaseAltName = phases.at(j).get('name') + "/" + alternatives.at(i).get('name');
					var periodKind =  self.model.getPeriodKinds(yearPeriod.year,yearPeriod.period,plan.get("periodKind"));
					var periodKindText = periodKind?.text || yearPeriod.period;
					self.periodSets().push({year:yearPeriod.year,period:periodKindText,periodNumber:periodKind.value,altId:step.get('alternativeId')})
				}
			}	
		}

		getSelectPeriodOptions(){
			var self =  this;
			const fromObj = self.periodSets().find(d=>d.year===self.selectedFromYear() && d.period === self.selectedFromPeriod());
			const toObj =  self.periodSets().find(d=>d.year===self.selectedToYear() && d.period === self.selectedToPeriod());
			const filteredPeriods = self.periodSets().filter(period => {
				const fromYear = fromObj.year;
				const fromPeriod = fromObj.periodNumber;
				const toYear = toObj.year;
				const toPeriod = toObj.periodNumber;
				
				const isAfterFrom = period.year > fromYear || (period.year === fromYear && period.periodNumber >= fromPeriod);
				const isBeforeTo = period.year < toYear || (period.year === toYear && period.periodNumber <= toPeriod);
				
				return isAfterFrom && isBeforeTo;
			});
			filteredPeriods.map((d)=>{
				var text = `${d.year}-${d.period}`;
				self.selectedPeriodOptions().push({text:text,value:text});
			})

		}

		getPeriodsByFromYear(year){
			var self = this;
			let selectedYear = year
			const periods = self.periodSets().filter(d=>d.year===+selectedYear)
			self.fromPeriods(periods.map(d=>d.period));
			self.selectedFromPeriod(self.fromPeriods()[0])
		}

		getToPeriodsByFromPeriods(period){
			var self = this;
			if(self.selectedFromYear() && self.selectedToYear() && self.selectedFromYear() === self.selectedToYear()){
				var periodObj =  self.periodSets().find((d)=>d.year===self.selectedFromYear()&&d.period===period)
				const periods = self.periodSets().filter(d=>d.year===+self.selectedToYear())
				self.toPeriods(periods.filter(d=>d.periodNumber>=periodObj.periodNumber).map(d=>d.period));
				self.selectedToPeriod(self.toPeriods()[self.toPeriods().length-1])
			}
			
		}

		getPeriodsByToYear(year){
			var self = this;
			let selectedYear = year
			const periods = self.periodSets().filter(d=>d.year===+selectedYear)
			self.toPeriods(periods.map(d=>d.period));
			self.selectedToPeriod(self.toPeriods()[self.toPeriods().length-1])
		}

		reRenderSelectPeriod(){
			var self = this;
			var selectElement = $('#selectPeriod' + self.encodeId);
				selectElement.empty();
	
				self.selectedPeriodOptions().forEach(function(option) {
					selectElement.append($('<option>', {
						value: option.value,
						text: option.text
					}));
				});
				selectElement.multiselect('destroy');
				selectElement.multiselect({
					enableClickableOptGroups: false,
					numberDisplayed: 0,
					nonSelectedText: '0 Selected',
				});
		}

		reRenderTables(table,createTableCallBack){
			var self = this;
			self.destroyTable('#'+table);
			self.getSelectPeriodOptions();
			createTableCallBack()
			$('#'+table).DataTable().draw('page')
		}
		refreshWidgetData(){
			var self = this;
			self.renderDynamicRows(self.defaultExecutionScenaroId);
            if(self.showMultipleScenarioTable()){
                self.reRenderTables('fixedColumnsMultiTable',function(){self.createFixedColumnTable('fixedColumnsMultiTable')});
                self.reRenderTables('firstScenarioTableMultiTable',function(){self.firstScenarioTable('firstScenarioTableMultiTable')});
                self.reRenderTables('secondScenarioTable',function(){self.secondScenarioTable('secondScenarioTable')});
            }else{
                self.reRenderTables('fixedColumns',function(){self.createFixedColumnTable('fixedColumns')});
                self.reRenderTables('firstScenarioTable',function(){self.firstScenarioTable('firstScenarioTable')});
            }
		}

		

		init(model,options){
			var self = this;
			this.Values2ViewModel = this;
			self.model = model;
			this.alternativeModel = options.addOptions;
			this.selectedChartType = ko.observable();
			this.periodSets = ko.observableArray([])
			this.fromYears = ko.observableArray([]);
			this.selectedPeriodOptions = ko.observableArray([]);
			this.selectedScenarioTables =  ko.observableArray([]);
			this.scenarioOptions = ko.observableArray([]);
			this.selectedPeriodValues = ko.observableArray([]);
			this.toPeriods = ko.observableArray([]);
			this.toYears = ko.observableArray([]);
			this.fromPeriods = ko.observableArray([]);
			this.selectedFromYear = ko.observable("");
			this.selectedFromPeriod = ko.observable("");
			this.header1 = ko.observable('');
			this.header2 = ko.observable('')
			this.selectedToYear = ko.observable("");
			this.selectedToPeriod = ko.observable("");
			this.showMultipleScenarioTable = ko.observable(false);
			this.periodKind = DataManager.getDataManager().get('currentPlan').get("periodKind")
			this.encodeId = this.model ? window.utils.htmlEscape(this.model.id) : null;
			this.skipBuiding = true;
			this.enableDirectEditing = true;
			this.showInputValues = ko.observable(true);
			this.showExportButton = ko.observable(true);
			this.titleMap = {};
			this.altMap = {};
			this.yearPeriodMap = {};
			this.titleMap['vdml_Community'] = "Market Segment";
			this.titleMap['vdml_OrgUnit'] = "Enterprise";
			this.titleMap['vdml_Actor'] = "Individual";
			this.titleMap['transformation_Plan'] = "Plan";
			this.titleMap['vdml_Party'] = "Role";
			this.titleMap['vdml_BusinessModel'] = "Business Model";
			this.titleMap['vdml_BusinessModelActivity'] = "Activity";
			this.titleMap['vdml_BusinessNetwork'] = "Participant Network";
			this.titleMap['vdml_BusinessModelValueProposition'] = "Value Proposition";
			this.titleMap['vdml_BusinessModelValueFormula'] = "My Proposition";
			this.titleMap['vdml_Activity'] = "Activity";
			this.titleMap['vdml_ValuePropositionComponent'] = "Value";
			this.titleMap['vdml_ValueAdd'] = "Value";
			this.showViewMap = ko.observable(true);
            if(DataManager.getDataManager().get('debugMode')){
                this.showViewMap(true);
            }
			var dataManager = DataManager.getDataManager();
			var scenarioId = dataManager.get("currentPlan").get("defaultScenario");
			this.scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
			self.defaultExecutionScenaroId = this.scenario.get("defaultExecutionScenario");
			this.defaultExecutionScenaro = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: self.defaultExecutionScenaroId });
			this.lastCalculate = ko.observable();
			self.showLastCalulatedDate();			
			// this.selectedChartType.subscribe(function (selectedType) {
			// 	if(selectedType == "Show Only Values"){
			// 		self.destroyTable('#offlineTable');
			// 		self.renderDynamicRows();
			// 		self.createOfflineTable("offlineTable");
			// 	} else if(self.viewloaded){
			// 		self.destroyTable('#offlineTable');
			// 		self.renderDynamicRows();
			// 		self.createOfflineTable("offlineTable");
			// 	}
			// });	

			self.getColumnPeriodDataSets()

			const filteredYears = this.periodSets().filter((item, index, self) =>
				index === self.findIndex((t) => t.year === item.year)
			);

			
			// this.periodSets().map(d=>{
			// 	self.selectedPeriodOptions.push({text:d.year+"-"+d.period, value:d.year-d.period})
			// });

			self.fromYears(filteredYears.map(d=>d.year));

			var defaultPeriods = this.scenario.fillDefaultPeriods(this.periodSets(),self.periodKind);

			 self.periodSets().map(d=>{
				self.selectedPeriodOptions().push({text:`${d.year}-${d.period}`,value:`${d.year}-${d.period}`})
			 })

			self.selectedFromYear.subscribe(function(value){
				if(value!==undefined){
					self.getPeriodsByFromYear(value)
					var toYears = filteredYears.filter(d=>d.year>=value).map(d=>d.year)
					self.toYears(toYears);
					self.selectedToYear(self.toYears()[self.toYears().length-1]);
					// defaultPeriod=undefined
					if(self.viewloaded){
						self.selectedPeriodOptions.removeAll();
						self.selectedPeriodValues.removeAll();
						if(self.showMultipleScenarioTable()){
							self.reRenderTables('fixedColumnsMultiTable',function(){self.createFixedColumnTable('fixedColumnsMultiTable')});
							self.reRenderTables('firstScenarioTableMultiTable',function(){self.firstScenarioTable('firstScenarioTableMultiTable')});
							self.reRenderTables('secondScenarioTable',function(){self.secondScenarioTable('secondScenarioTable')});
						}else{
							self.reRenderTables('fixedColumns',function(){self.createFixedColumnTable('fixedColumns')});
							self.reRenderTables('firstScenarioTable',function(){self.firstScenarioTable('firstScenarioTable')});
						}
					}
				}
				self.reRenderSelectPeriod()
			});

			self.selectedFromPeriod.subscribe(function(value){
				if(value!==undefined){
					self.selectedFromPeriod(value)
					self.getToPeriodsByFromPeriods(value)

					if(self.viewloaded){
						self.selectedPeriodOptions.removeAll();
						self.selectedPeriodValues.removeAll();
						if(self.showMultipleScenarioTable()){
							self.reRenderTables('fixedColumnsMultiTable',function(){self.createFixedColumnTable('fixedColumnsMultiTable')});
							self.reRenderTables('firstScenarioTableMultiTable',function(){self.firstScenarioTable('firstScenarioTableMultiTable')});
							// self.tableColumnWidthCalculation('firstScenarioTableMultiTable')
							self.reRenderTables('secondScenarioTable',function(){self.secondScenarioTable('secondScenarioTable')});
							// self.tableColumnWidthCalculation('firstScenarioTableMultiTable')
						}else{
							self.reRenderTables('fixedColumns',function(){self.createFixedColumnTable('fixedColumns')});
							self.reRenderTables('firstScenarioTable',function(){self.firstScenarioTable('firstScenarioTable')});
							// self.tableColumnWidthCalculation('firstScenarioTableContainer');
						}
						
					}
				}
				self.reRenderSelectPeriod()
				
			});

			
			self.selectedToYear.subscribe(function(value){
				if(value!==undefined){
					self.getPeriodsByToYear(value);
					self.selectedToYear(value);
					if(self.viewloaded){
						self.selectedPeriodOptions.removeAll();
						self.selectedPeriodValues.removeAll();
						if(self.showMultipleScenarioTable()){
							self.reRenderTables('fixedColumnsMultiTable',function(){self.createFixedColumnTable('fixedColumnsMultiTable')});
							self.reRenderTables('firstScenarioTableMultiTable',function(){self.firstScenarioTable('firstScenarioTableMultiTable')});
							self.reRenderTables('secondScenarioTable',function(){self.secondScenarioTable('secondScenarioTable')});
						}else{
							self.reRenderTables('fixedColumns',function(){self.createFixedColumnTable('fixedColumns')});
							self.reRenderTables('firstScenarioTable',function(){self.firstScenarioTable('firstScenarioTable')});
						}
					}
				}
				self.reRenderSelectPeriod();
			});
			self.selectedToPeriod.subscribe(function(value){
				if(value!==undefined){
					self.selectedToPeriod(value);
					if(self.viewloaded){
						self.selectedPeriodOptions.removeAll();
						self.selectedPeriodValues.removeAll();
						if(self.showMultipleScenarioTable()){
							self.reRenderTables('fixedColumnsMultiTable',function(){self.createFixedColumnTable('fixedColumnsMultiTable')});
							self.reRenderTables('firstScenarioTableMultiTable',function(){self.firstScenarioTable('firstScenarioTableMultiTable')});
							self.reRenderTables('secondScenarioTable',function(){self.secondScenarioTable('secondScenarioTable')});
						}else{
							self.reRenderTables('fixedColumns',function(){self.createFixedColumnTable('fixedColumns')});
							self.reRenderTables('firstScenarioTable',function(){self.firstScenarioTable('firstScenarioTable')});
							// self.tableColumnWidthCalculation('firstScenarioTableContainer');
						}
					}
				}
				self.reRenderSelectPeriod();
			});

			self.renderDynamicRows(self.defaultExecutionScenaroId);

			var currentplan = dataManager.get('currentPlan')
			//TODO for custom Scenario
			/*dataManager.getAllScenariosByPlan(currentplan.get('id'),(data)=>{
				data.map(d=>{
					const options = d.scenarioExecution.map(d=>{return {text:d.name,value:d.id}})
					if(d && d.id){
						self.scenarioOptions.push({label:d.name,options:options})						
					}
				})
				this.getMultiSelectOptGroups(self.scenarioOptions(),'selectedScenario',self.encodeId);			
				$('#selectedScenario' + self.encodeId).multiselect('rebuild');
				$('#selectedScenario' + self.encodeId).multiselect('select', [self.scenarioOptions()[0].options[0].value]);
				var targetVal = self.scenarioOptions()[0].options[1] ? self.scenarioOptions()[0].options[1].value : null;
				if(targetVal){
					self.renderTargetDynamicRows(targetVal);
				}
			})*/
			var defaultId = self.defaultExecutionScenaro.id;
			var defaultName = self.defaultExecutionScenaro.get('name');
			var scOptions = [{'text':defaultName,'value':defaultId}];
			self.scenarioOptions.push({label:scOptions[0].text,options:scOptions});
			var targetSc = currentplan.getTargetScenarioExecution();
			if(targetSc){
				var tarOptions = {'text':targetSc.get('name'),'value':targetSc.id};
				self.scenarioOptions()[0].options.push(tarOptions);
				//self.renderTargetDynamicRows(targetSc.id);
			}
			
			this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['ExportCSV', 'PresentationMode', 'Overview', 'Scenarios', 'Yearly', 'SelectValues']);
		}

		getMultiSelectOptGroups(options,select,id){
			var self = this
			var multiselectNode = $('#'+select + id);
			options.map(d => {
				var optionLabel = d.label;
			
				var unitOptionGroupNode = multiselectNode.children('optgroup[label="' + optionLabel + '"]');
				var optionGroupNode;
			
				if (unitOptionGroupNode.length === 0) {
					multiselectNode.append('<optgroup label="' + optionLabel + '"></optgroup>');
					unitOptionGroupNode = multiselectNode.children('optgroup[label="' + optionLabel + '"]');
				}
			
				optionGroupNode = unitOptionGroupNode.first();
			
				d.options.forEach(val => {
					var label = val.text;				
					if (optionGroupNode.has('>option[value="' + val.value + '"]').length === 0) {
						optionGroupNode.append('<option value="' +   val.value + '"  label="' + label + '">' + label + '</option>');
					}
				});
			});
		}

		viewMap(){
			var self = this;
			var lastPhase = self.model.get('phase').findWhere({ nextPhase: null });
            var altModel = lastPhase.get('primary');
			var phaseDesignPart = altModel.get("phaseDesignPart");
			for (var k = 0; k < phaseDesignPart.length; k++) {
				var packId = phaseDesignPart.at(k).get("beepReference");
				if (packId.indexOf("Common") == -1 && phaseDesignPart.at(k).get("beepType") === "ecomap_EcoMap") {
					var packageModel = Backbone.Relational.store.getObjectByName("ecomap.EcoMap").find({ id: packId });
					EcoMapMixin.viewMap(EcoNavigatedViewer,packageModel,null);
					break;
				}
			}
		}

		static getInstance(model,options){
			var view = new Values2ViewModel(model,options);
			return view;
		};
	}
	path.Values2ViewModel = Values2ViewModel;
