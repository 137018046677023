import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { DashboardMixin } from '../../../../bo/dashboard/DashboardMixin'
import ReactDOM from 'react-dom'
import React from  'react'
import PresentationDataGrid from './PresentationDataGrid'


/*define(["require", "jquery", "enhsplitter", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "app/global", "typeahead", "appbo/dashboard/DashboardMixin"],
function (require, $, enhsplitter, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, global, typeahead, DashboardMixin)
{*/
    var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart", global.version);
	export class DashboardDesignViewModel {
		htmlEscape(str) {
    		return String(str)
            .replace(/@/g, '')
            .replace(/ /g, '')
            .replace(/#/g, '');
		}
		
		sortByName(left, right) {
            return left.name.toLowerCase() === right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
        }
       
		dispose(){
			var self = this;
			if(self.presentationsList){
				self.presentationsList.removeAll();
			}
			window.cleanViewModel(self);		
		};
		
		cleanUp() {
    	};
    	afterRenderView() {
    	    $('[data-toggle="tooltip"]').tooltip(); 
    	};
		
    	addChart(rowId,uId,treeData) {
			var self = this;
    		if(DataManager.getDataManager().isDiscoverPurchased() || DataManager.getDataManager().isAdoptPurchased()){
    			self.parentView.newId = self.htmlEscape(window.guidGenerator()) + "createchart";
				var addOptions = {width:'1000px', rowId:rowId, uId:uId, treeData:treeData };
				var mixinClass = self.model.getMixinClass();
				window.getAndCreateModalDialog(self,self.parentView.newId,mixinClass,self.model,"CreateChart",null,addOptions);
    		}else{
    			window.utils.limitExceeded();
            	return false;
    		}
		};
		editChart(data,treeData,event) {
			var self = window.vdmModelView.dashboardDesignViewModel;
            var newId = self.htmlEscape(data.id) + "editchart";
			var chart = self.charts.findWhere({'id':data.id});
			// if(DataManager.getDataManager().get("dashboardType")==="old"){
			// 	var presentationView = _.filter(window.vdmModelView.charts(), function(chartView){return chartView.id() === data.id;})[0];
			// 	var addOptions = {'chartModel':chart, viewInstance:presentationView.viewInstance, width:'1000px'};
			// }
			var mixinClass = self.model.getMixinClass();
			var addOptions = {'chartModel':chart, width:'1000px',uId:data.uId,treeData:treeData};
			window.getAndCreateModalDialog(self,newId,mixinClass,self.model,"CreateChart",null,addOptions);
		};
		
		createRevisionBasedOnContext(pack,callback){
			if(window.checkContextForRevision()) {
				window.utils.startSpinner('revisionSpinner', "Creating a copy...");
				function fetchBmModel(newVdmPackage){
						if(newVdmPackage) {
                            DataManager.getDataManager().releaseSaveLock();
		        			DataManager.getDataManager().set('isActive',false);
							window.utils.stopSpinner('revisionSpinner');
		        			callback(newVdmPackage);
						}
					}
				window.setTimeout(function(){
                    DataManager.getDataManager().acquireSaveLock(function () {
                        pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
                    });
	        	},100);	
        	}
        	else {
        		callback(pack);
        	}
		};
		
		deleteChart(data, event){
			var self = this;
			var vdmPackage = self.model.getNestedParent();
			window.utils.startSpinner("saveData", "Deleting ... ");
			self.createRevisionBasedOnContext(vdmPackage,function(newVdmPackage){
				if(newVdmPackage !== vdmPackage){
					var dashboardSuffix = window.utils.getSuffix(self.model.id);
					var viewAltId = DataManager.getDataManager().getRepositoryId(newVdmPackage.id);			                	
					var analyticChart = newVdmPackage.get('dashboard').findWhere({'id':viewAltId+dashboardSuffix});
					self.model = analyticChart;
					var chart = self.charts.findWhere({'id':data.id});
					chart.destroy();
					DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + newVdmPackage.get('id') +"/" + newVdmPackage.get('type') + "/" +  newVdmPackage.get('version') + "/"+ analyticChart.id + "/"+ analyticChart.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), {trigger: true});
				}else {
					var chart = self.charts.findWhere({'id':data.id});
					self.parentView.chartCollection.remove(data.id);
					self.charts.remove(chart);
					if(chart){
						chart.destroy();
					}
					self.presentationsList.removeAll();
					self.charts.forEach(function(chart){
						self.presentationsList.push({ id: chart.id, name: chart.get('name')});	
					})
				}
			});
			window.utils.stopSpinner("saveData");
	};
			
	init(model, options){
		var self = this;
		this.DashboardDesignViewModel = this;
		this.parentView = model;
		this.model = model.model;
		// this.dashboardType = ko.observable(DataManager.getDataManager().get("dashboardType"));
		this.id = ko.observable(this.model.id);
		this.name = ko.observable(this.model.get('name'));
		this.description = ko.observable(this.model.get('description'));
		this.presentationsList = ko.observableArray([]);
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
			'name'
			, 'description'
			, 'Delete'
		]);
		this.name.subscribe(function (val) {
			self.description(val);
		});
		this.charts = this.model.get('presentation');
		this.charts.forEach(function (chart) {
			self.presentationsList.push({ id: chart.id, name: chart.get('name') });
		});
		self.presentationsList.sort(self.sortByName);

		self.treeView = PresentationDataGrid
		self.dashboardId =  self.model.get("id");
		
		ko.bindingHandlers.treeViewReact = {
			init() {
				return {controlsDescendantBindings: true};
			},
			update: function (element, valueAccessor) {
				var component = ko.unwrap(valueAccessor().component);

				var renderComponent = (props) => {
				ReactDOM.render(React.createElement(component,props),element);
				};

				const eventListener = (props) => {
					renderComponent(props);
				};
				window.vdmModelView.eventBus.on('updateProps', eventListener);
				renderComponent({ dashboardId: self.dashboardId });

				ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
				  ReactDOM.unmountComponentAtNode(element);
				});

			  }
		};
	}
	
	static getInstance(model, options){
		var view = new DashboardDesignViewModel(model, options);
		view.init(model, options);
		return view;
	};

}
path.DashboardDesignViewModel = DashboardDesignViewModel;