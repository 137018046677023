import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import {MeasurableElement} from './MeasurableElement'
import {MeasurableElementMixin} from './MeasurableElementMixin'
import {AnalysisContext} from './AnalysisContext'
import {VDMLCanvasElementMixin} from './VDMLCanvasElementMixin'
import {Accumulator} from '../smm/Accumulator'
import {Value} from './Value'
import {ValueDefinition} from './ValueDefinition'
import {MeasuredCharacteristic} from './MeasuredCharacteristic'
import {DataManager} from '../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/vdml/MeasurableElement","appbo/vdml/MeasurableElementMixin","appbo/vdml/AnalysisContext","appbo/vdml/VDMLCanvasElementMixin","appbo/smm/Accumulator"],
function(require,jQuery,_,async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,MeasurableElement,MeasurableElementMixin,AnalysisContext,VDMLCanvasElementMixin,Accumulator
, MeasuredCharacteristic

	, ValueDefinition, Value){*/
	
	var path = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
	if(!MeasuredCharacteristic){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.MeasuredCharacteristic){
			MeasuredCharacteristic = importPath.MeasuredCharacteristic;
		}
		else{
			import('./MeasuredCharacteristic').then(loadedModule => {
				MeasuredCharacteristic = loadedModule;
			});
			/*require(["appbo/vdml/MeasuredCharacteristic"],function(loadedModule){
				MeasuredCharacteristic = loadedModule;
			});*/
		}
	}
	
	if(!ValueDefinition){
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml",global.version);
		if(importPath.ValueDefinition){
			ValueDefinition = importPath.ValueDefinition;
		}
		else{
			import('./ValueDefinition').then(loadedModule => {
				ValueDefinition = loadedModule;
			});
			/*require(["appbo/vdml/ValueDefinition"],function(loadedModule){
				ValueDefinition = loadedModule;
			});*/
		}
	}
	if (!Value) {
		var importPath = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
		if (importPath.Value) {
			Value = importPath.Value;
		}
		else {
			import('./Value').then(loadedModule => {
				Value = loadedModule;
			});
			/*require(["appbo/vdml/Value"], function (loadedModule) {
				Value = loadedModule;
			});*/
		}
	}
	export class ValueElementMixin {
		/*constructor() {
            var path = DataManager.getDataManager().buildAppNsPath("vdml", global.version);
            path.ValueElementMixin = ValueElementMixin;
        }*/
	defaults(){
		var ret = {
			type: "vdml_ValueElement",
			isIterative:false
		}
		return jQuery.extend(MeasurableElement.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
			type :Backbone.HasMany,
			containingClass:"vdml_ValueElement",
			key:"aggregatedFrom",
			relatedModel:"vdml.ValueElement",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		{
			type :Backbone.HasMany,
			containingClass:"vdml_ValueElement",
			key:"aggregatedTo",
			relatedModel:"vdml.ValueElement",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		},
		/*{
			type :Backbone.HasMany,
			containingClass:"vdml_ValueElement",
			key:"benchmark",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"benchmarkOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},*/
		{
			type :Backbone.HasOne,
			containingClass:"vdml_ValueElement",
			key:"valueMeasurement",
			relatedModel:"vdml.MeasuredCharacteristic",
			reverseRelation: {
				key:"valueMeasurementOwner",
				type :Backbone.HasOne,
				includeInJSON:"id"
			}
		},
		{
			type :Backbone.HasOne,
			containingClass:"vdml_ValueElement",
			key:"valueDefinition",
			relatedModel:"vdml.ValueDefinition",
			includeInJSON: Backbone.Model.prototype.idAttribute,
		}
		])
	}
	static getCumulativeMixinRelations(){
		if (!ValueElementMixin.cummulativeRelations) {
            ValueElementMixin.cummulativeRelations = _.union(ValueElementMixin.getMixinRelations()
                ,MeasurableElementMixin.getCumulativeMixinRelations()
		        ,VDMLCanvasElementMixin.getCumulativeMixinRelations()
            );
        }
		return ValueElementMixin.cummulativeRelations.slice();
	};
	static getSuperTypes(){
		return [
			"vdml_MeasurableElement","vdml_VDMLCanvasElement"
		];
	}
	static getSubModelTypes(){
		return {
			vdml_ValueAdd : 'vdml.ValueAdd',
			vdml_ValuePropositionComponent : 'vdml.ValuePropositionComponent',
			vdml_Unit: 'vdml.Unit',
			vdml_Suggestion: 'vdml.Suggestion'
		}
	}
	static getProperties(){
		return [
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
			{name : "represents",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "documentID",type : "EString",defaultValue : "null",containingClass : "vdml_VdmlElement" },
			{name : "isAtomic",type : "EBoolean",defaultValue : "null",containingClass : "vdml_ValueElement" },
			{name : "isIterative",type : "EBoolean",defaultValue : false,containingClass : "vdml_ValueElement" },
			{name : "maxChange",type : "EString",defaultValue : "0.0001",containingClass : "vdml_VdmlElement" }
		]
	}
	getParent(){
		var container;
		return MeasurableElement.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return MeasurableElement.prototype.getPackagePath.apply(this, arguments);
	}
	getViewProperties(type){
		return {
			templatePath : "views/vdml/views/properties/ValueElementPropertiesTemplate.html",
			templateName : "ValueElementPropertiesTemplate",
			viewTypeStr : "appviews/vdml/views/properties/ValueElementViewModel",
			tabId : "ValueElementView",
			tabName: "ValueElement"
		}
	}
//#startCustomMethods

	onDestroyElement(model) {
		var aggregatedTo = model.previousAttributes().aggregatedTo;
		if(!aggregatedTo){
			return;
		}
		async.eachSeries(aggregatedTo.models,function(element,handleElementCallback){
			if(!Backbone.skipPersistance && !element.get('isIterative') && element != model){
				var altId = DataManager.getDataManager().getRepositoryId(element.getNestedParent().get('id'));
				var alt;
				if(altId === window.plansKey){
					alt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().getRepositoryId(model.getNestedParent().get('id')));
				}else {	
					alt = DataManager.getDataManager().getAlternativeSync(altId);
				}
				var prevAlt = alt?alt.getPreviousPhasePrimary():null;	
				var valueMeasurements = element.get('valueMeasurement')?element.get('valueMeasurement').get('measurement'):null;
				if(prevAlt && valueMeasurements.length > 0){
					var eleScenario = alt.getMainScenario(element.getNestedParent());
					var observedMeasure = eleScenario?eleScenario.getObservedMeasureWithMeasurements(element.get('valueMeasurement'), null):null;
					var foundUsePrevious = false;
					if(observedMeasure){
						var baseMsmtRelationships = observedMeasure.get('measurements').at(0).get('measurementRelationships');
						for(var i=0;i<baseMsmtRelationships.length;i++){
							var relation = baseMsmtRelationships.at(i);
							var toCompMsmt = relation.get('to');
							if(toCompMsmt && toCompMsmt.get('measuredCharacteristic')){
								var toComp = toCompMsmt.get('measuredCharacteristic').getParent();
							}
							if(toComp && relation.get('definition') && relation.get('definition').get('usePreviousAlternative') && model != toComp && window.utils.getSuffix(model.id) === window.utils.getSuffix(toComp.id)){
								foundUsePrevious = true;
								break;
							}
						}
						if(foundUsePrevious){
							element.updateValueMeasure(alt, function(){
								handleElementCallback();
							},false,null);
						}else {
							handleElementCallback();
						}
					}else {
						handleElementCallback();
					}
				}else {
					handleElementCallback();
				}
				
			}else {
				element.onAggregatedFromRemoved(model);
				handleElementCallback();
			}
		},function(){
		});
	}

	getValueSlipName(alt){
		var self = this;
		var name = self.get('name');
		var beepPack = self.getNestedParent();
		var scenario = alt.getMainScenario(beepPack);
		var observedMeasure = scenario ? scenario.getObservedMeasureWithMeasurements(self.get('valueMeasurement'), null):null;
		var valueMsmt = observedMeasure ? observedMeasure.get('measurements').at(0): null;
		if(valueMsmt) {
			var measure = valueMsmt.getParent().get('measure');
			var unit = measure ? measure.get('unit'): null;
			var significantDecimals = unit ? unit.get('significantDecimals') : 2;
			var value = window.utils.getRoundedValues(valueMsmt.get('value'), significantDecimals);
			var unitName = unit ? unit.get('name') : null;
			if (unitName) {
				name += " (" + value + " " + unitName + ")";
			}                 
		}
		return name;
	}
	getSatisfactionIntervalData(scenario){
		var self = this;
		var data = {
			smileyValue: null,
			smileyColor: null,
			correctiveActions: null
		};
		var selectedInterval = self.getSatisfactionInterval(scenario);
		if(selectedInterval){
			data.smileyValue = selectedInterval.get('smiley') ? selectedInterval.get('smiley') : '';
            data.smileyColor = selectedInterval.get('smileyColor') ? selectedInterval.get('smileyColor') : '#000000';
            data.correctiveActions = selectedInterval.get('correctiveActions') ? selectedInterval.get('correctiveActions') : '';
		}
		return data;
	}
	getSatisfactionInterval(context, timeStamp, importTimeStampValue){
		var self = this;
    	var selectedInterval;
    	var satisfactionMeasurement;
        /*if (self.getNestedParent() === DataManager.getDataManager().get('currentPlan')) {
            satisfactionMeasurement = self.getMeasurement('satisfactionLevel', context, timeStamp);
        } else {
            satisfactionMeasurement = self.getMeasurement('satisfactionLevel', context, timeStamp);
        }*/
        if(!isNaN(timeStamp) && importTimeStampValue && !isNaN(importTimeStampValue)) {
    		importTimeStampValue = parseFloat(importTimeStampValue);
    		timeStamp = null;
        }
        satisfactionMeasurement = self.getMeasurement('satisfactionLevel', context, timeStamp);
        if(satisfactionMeasurement /*&& self.getParent().get('overAllSatisfaction') !== self*/) {
			var satType = 'symbol';
			var satMeasure = satisfactionMeasurement.get('observedMeasure').get('measure');
			if(satisfactionMeasurement.get('value') && satMeasure && satMeasure.get('unit')){//Ranking
				satType = 'value';
			}
			if(satMeasure) {
				var intervals = satMeasure.get('interval');
				for(var i=0;i<intervals.length;i++){
					if(importTimeStampValue){
						var maxep = parseFloat(intervals.at(i).get('maximumEndPoint'));
						var maxOpen = intervals.at(i).get('maximumOpen');
						var minep = parseFloat(intervals.at(i).get('minimumEndPoint'));
						var minOpen = intervals.at(i).get('minimumOpen');
						var inInterval = false;
						if(maxOpen && minOpen){
							if(importTimeStampValue >= minep && importTimeStampValue <= maxep ){
								inInterval = true;
							}
						}else if(maxOpen){
							if(importTimeStampValue > minep && importTimeStampValue <= maxep ){
								inInterval = true;
							}
						}
						else{
							if(importTimeStampValue > minep && importTimeStampValue < maxep ){
								inInterval = true;
							}
						
						}
						if(inInterval){
							selectedInterval = intervals.at(i);
							break;
						}
					}else {
						if(intervals.at(i).get(''+satType) === satisfactionMeasurement.get(''+satType)){
							selectedInterval = intervals.at(i);
							break;
						}
					}
				}
			}
        }
    	return selectedInterval;  	
	};
	
	fetchSmileySrc(smileyValue){
		var smileySrc = '';
		var svgSmiliesList = window.utils.getSmiliesList();
		for(var j=0;j<svgSmiliesList.length;j++){
			if(svgSmiliesList[j].value === smileyValue) {
				smileySrc = svgSmiliesList[j].src;
				break;
			}
		}
		return smileySrc;
    };
	
	/*onChangeIsIterative(model,newValue){
		if(Backbone.skipPersistance){
			return;
		}
		if(newValue === true){
			model.set('maxChange',0.0001);
		}else{
			model.set('maxChange',undefined);
		}				
	};*/
	
	constructTupleSetsBasedOnObservedMeasure(baseComponent,observedMeasure,tuples,handledMeasurementRels,isNewTuple,currentObservedMeasure){
		var self = this;
		var foundBase = false;
		if(!observedMeasure){
			return foundBase;
		}
		var measureParent = observedMeasure.get('measure');
		var mesurementParent = observedMeasure.get('measurements').at(0);
		var baseMeasurementRels = mesurementParent.get('baseMeasurementTo');
		var baseMC = baseComponent.get('valueMeasurement');
		if(self.checkOVS()){
			baseMC = baseComponent.get('satisfactionLevel');
		}
		if(!baseMC){
			return;
		}
		var baseMCSuffix = baseMC.id.substr(baseMC.id.lastIndexOf('@') + 1);
		if (baseMeasurementRels) {
				baseMeasurementRels.each(function (baseMeasurementRel) {
					var relMC = baseMeasurementRel.get('to') ? baseMeasurementRel.get('to').get('measuredCharacteristic') : null;
					var relMCSuffix = relMC ? relMC.id.substr(relMC.id.lastIndexOf('@') + 1) : null;
					if (relMCSuffix !== baseMCSuffix) {
						return;
					}
					var baseMeasureRel = mesurementParent.getMeasureRelationForMeasurementRelation(baseMeasurementRel);
					if (baseMeasureRel && isNewTuple && currentObservedMeasure && currentObservedMeasure.get('measure') && currentObservedMeasure.get('measure').get('type') === "smm_CollectiveMeasure") {
						var newBaseMeasureRel = currentObservedMeasure.get('measure').createBaseMeasureRelationship(baseMeasureRel.get('to'));
						newBaseMeasureRel.set('usePreviousAlternative', baseMeasureRel.get('usePreviousAlternative'));
						var rescaledMeasure = baseMeasureRel.get('rescaledMeasure');
						if (rescaledMeasure) {
							newBaseMeasureRel.createRescaledMeasure(rescaledMeasure.get('offset'), rescaledMeasure.get('multiplier'),rescaledMeasure.get('operation'));
						}
						baseMeasureRel = newBaseMeasureRel;
					}

                    if (baseMeasureRel && measureParent) {
						var usePrevious = baseMeasureRel.get('usePreviousAlternative');
						var bMeasure;
						if (!usePrevious) {
							bMeasure = baseMeasureRel.get('to');
						} else {
							var useScenario = currentObservedMeasure ? currentObservedMeasure : observedMeasure;
							var currentAlt = useScenario.get('observation').getContext().getAlternative();
							var prevAlt = currentAlt?currentAlt.getPreviousPhasePrimary():null;
							if (!prevAlt) {
								foundBase = true;
								return;
							}
							var prevAltComp = prevAlt.getModelLinkedSync(baseComponent);
							var preAltScenario = prevAlt.getMainScenario(prevAltComp.getNestedParent());
							var obsMeasure = preAltScenario.getObservedMeasureWithMeasurements(prevAltComp.get('valueMeasurement'), null);
							bMeasure = obsMeasure ? obsMeasure.get('measure') : null;
						}
						handledMeasurementRels.push(baseMeasurementRel);
						var rescaledMeasure = baseMeasureRel.get('rescaledMeasure');
						var tuple = measureParent.constructBaseMeasureTuple(baseMC, bMeasure, rescaledMeasure ? rescaledMeasure.get('offset') : 0, rescaledMeasure ? rescaledMeasure.get('multiplier') : 1, rescaledMeasure ? rescaledMeasure.get('operation') : null, baseMeasureRel.get('usePreviousAlternative'), null, isNewTuple, baseMeasureRel);
						if (tuple) {
							foundBase = true;
							if (tuple.old) {
								tuple.old.id = baseMeasurementRel.id;
							}
							tuples.push(tuple);
							return;
						}
					}

				});
			if(!foundBase && isNewTuple && currentObservedMeasure) {
				var scenario = currentObservedMeasure.get('observation').getContext();
				var obsMes = scenario.getObservedMeasureWithMeasurements(baseComponent.get('valueMeasurement'));
                if (obsMes && measureParent) {
					var tuple = measureParent.constructSimpleBaseMeasureTuple(baseMC, obsMes.get('measure'), false);
					if(tuple) {
						foundBase = true;
						tuples.push(tuple);
					}
			   }
			}
		}
		return foundBase;
	};
	
	constructBaseMeasureTupleSet(mc,scenario,backupObsMes,nonPrimedScenarioUpdate){
		var tuples = [];
		var self = this;
		var handledMeasurementRels = [];
		var valueMeasurement = self.get('valueMeasurement');
		var obsMesParent = scenario.getObservedMeasureWithMeasurements(valueMeasurement,false);
		if(!obsMesParent){
			return tuples;
		}
		var measurementParent = obsMesParent.get('measurements').at(0);
		var measureParent = obsMesParent.get('measure');
		var mestRels = measurementParent.get('baseMeasurementTo');
		if(!obsMesParent){
			return tuples;
		}
		var baseComponents = self.get('aggregatedFrom');
		var alt = scenario.getAlternative();
		var parentAltDesignParts = alt?alt.get('phaseDesignPart'):null;
		baseComponents.each(function(baseComponent){
			var compPackage = baseComponent.getNestedParent();
			var compPackAltId = DataManager.getDataManager().getRepositoryId(compPackage.get('id'));
			if(baseComponent === self && alt && !alt.getPreviousPhasePrimary()){//stopping self aggregation in 1st phase
				return;
			}
			if(compPackAltId !== window.plansKey && parentAltDesignParts && !parentAltDesignParts.findWhere({'beepReference':compPackage.get('id')})){
				return;
			}
			var foundBase = self.constructTupleSetsBasedOnObservedMeasure(baseComponent,obsMesParent,tuples,handledMeasurementRels);
			if(!foundBase){
				if((parentAltDesignParts && parentAltDesignParts.findWhere({'beepReference':compPackage.get('id')})) || compPackAltId === window.plansKey){
					foundBase = self.constructTupleSetsBasedOnObservedMeasure(baseComponent,backupObsMes,tuples,handledMeasurementRels,true,obsMesParent);//creating new tuples
				}
				if (!foundBase && nonPrimedScenarioUpdate) {
				    var baseMC = baseComponent.get('valueMeasurement');
				    if (self.checkOVS()) {
				        baseMC = baseComponent.get('satisfactionLevel');
				    }
				    if (!baseMC) {
				        return;
				    }
				    if (measureParent.constructBaseMeasureTuple) {
				        var tuple = measureParent.constructBaseMeasureTuple(baseMC, null, 0, 1, null, false, null, false, null);
				        if (tuple) {
				            foundBase = true;
				            tuples.push(tuple);
				            return;
				        }
				    }
				}
			}
		});
		if(mestRels){
			mestRels.each(function(rel){
				if(_.indexOf(handledMeasurementRels, rel) < 0 && rel.get('to') && rel.get('to').get('observedMeasure')){
					var mesRel = measurementParent.getMeasureRelationForMeasurementRelation(rel);
					var baseMeasure =  rel.get('to').get('observedMeasure').get('measure');
					var rescaledMeasure = mesRel? mesRel.get('rescaledMeasure') : null;
					//var baseMC = rel.get('to').get('measuredCharacteristic');
					var tuple = measureParent.constructBaseMeasureTuple(null,baseMeasure,rescaledMeasure ? rescaledMeasure.get('offset'):0,rescaledMeasure? rescaledMeasure.get('multiplier'):1,rescaledMeasure?rescaledMeasure.get('operation'):null,rel.get('usePreviousAlternative'),true,null,mesRel);
					if(tuple){
						tuple.old.id = rel.get('id');
						tuples.push(tuple);	
					}				
				}
			});				
		}
		return tuples;
	};
	
	checkMeasureReuse(measure){
		var measureReused = true;
		if(measure.get('observedMeasure').length <=1){
			measureReused = false;
		}else {
			var foundOtherMc = false;
			var observedMeasures = measure.get('observedMeasure');
			var count=0;
			for(var i=0;i<observedMeasures.length;i++){
				if(observedMeasures.at(i).get('observation').get('whenObserved')){
					continue;
				}
				count++;
				if(count > 1){
					foundOtherMc = true;
					break;
				}
			}
			measureReused = foundOtherMc;
		}
		return measureReused;
	};
	
	compareBaseMeasureRelationShipTuple(baseMeasureRel1,baseMeasureRel2){
		var sameTuple = false;
		if(baseMeasureRel1 || baseMeasureRel2){
			return sameTuple;
		}
		var toBaseMeasure1 = baseMeasureRel1.get('to');
		var toBaseMeasure2 = baseMeasureRel2.get('to');
		if(toBaseMeasure1 && toBaseMeasure2 && toBaseMeasure1.get('trait') === toBaseMeasure2.get('trait')){
			var toBaseMeasure1RescaledMeasure = baseMeasureRel1.get('rescaledMeasure');
			var toBaseMeasure2RescaledMeasure = baseMeasureRel2.get('rescaledMeasure');
			
			var toBaseMeasure1offset = toBaseMeasure1RescaledMeasure ? toBaseMeasure1RescaledMeasure.get('offset') : 0;
	        var toBaseMeasure1multiplier = toBaseMeasure1RescaledMeasure ? toBaseMeasure1RescaledMeasure.get('multiplier') : 1;
	        var toBaseMeasure1operation = toBaseMeasure1RescaledMeasure ? toBaseMeasure1RescaledMeasure.get('operation') : null;
	        
	        var toBaseMeasure2offset = toBaseMeasure2RescaledMeasure ? toBaseMeasure2RescaledMeasure.get('offset') : 0;
	        var toBaseMeasure2multiplier = toBaseMeasure2RescaledMeasure ? toBaseMeasure2RescaledMeasure.get('multiplier') : 1;
	        var toBaseMeasure2operation = toBaseMeasure2RescaledMeasure ? toBaseMeasure2RescaledMeasure.get('operation') : null;
				
	        if (toBaseMeasure1offset === toBaseMeasure2offset && toBaseMeasure1multiplier === toBaseMeasure2multiplier && toBaseMeasure1operation === toBaseMeasure2operation) {
				sameTuple = true;
			}
		}
		return sameTuple;
    }

    handleNameChange(name, viewAlternative, measureVM, changeInValue) {
        var self = this;
        var nameChanged = changeInValue ? changeInValue : false;
        function changeValueStructure(valueModal, measureVM) {
            function changeMeasureCharacteristic(char, newName) {
                if (char) {
                    var vmChar = char.get('characteristicDefinition');
                    if (vmChar && vmChar.get('measuredCharacteristic').length <= 1) {
                        vmChar.get('measuredCharacteristic').at(0).set('name', newName);
                        vmChar.get('measuredCharacteristic').at(0).set('description', newName);
                    }// else {
                    //    beepPackage.linkOrCreateAndLinkCharacteristic(name, char);
                    //}
                }
            }
            var valueMeasurement = valueModal.get('valueMeasurement')
            var satisfactionLevel = valueModal.get('satisfactionLevel');
            var percentageWeight = valueModal.get('percentageWeight');
            var recipientMC = valueModal.get('recipientOpinion');
            var newName = name + ' Value MeasurementMC';
            changeMeasureCharacteristic(valueMeasurement, newName);
            newName = name + ' Satisfaction Level';
            changeMeasureCharacteristic(satisfactionLevel, newName);
            newName = name + ' Percentage Weight';
            changeMeasureCharacteristic(percentageWeight, newName);
            newName = name + ' Recipient MeasurementMC';
            changeMeasureCharacteristic(recipientMC, newName);

            if (measureVM && !self.checkMeasureReuse(measureVM)) {
                measureVM.set('name', measureVM.get('libraries').getUniqueProperty('name', name + ' Measurement', 'measureElements'));
            }
        }
        if (self.get('name') !== name) {
            self.set('name', name);
            self.set('description', name);
            var modelType = self.get('type').replace('_', '.');
            var plan = viewAlternative.getNestedParent();
            var suffixId = window.utils.getSuffix(self.id);
            for (var i = 0; i < plan.get('phase').length; i++) {
                var phase = plan.get('phase').at(i);
                _.each(phase.get('phaseAlternative').models, function (alt) {
                    if (alt != viewAlternative) {
                        var altVal = Backbone.Relational.store.getObjectByName(modelType).find({ id: alt.id + suffixId });
                        if (altVal) {
                            var compColl = altVal.getParent().get('component');
                            if (!compColl) {
                                compColl = altVal.getParent().get('valueAdd');
                            }
                            if (compColl && !compColl.findWhere({ 'name': name })) {
                                altVal.set('name', name);
                                //obsMesCurrentScenario = currentScenario.getObservedMeasureWithMeasurements(valueMeasurement, timestamp ? true : false, timestamp);
                                changeValueStructure(altVal, null);
                            }
                        }
                    }
                });
            }
            changeValueStructure(self, measureVM);
            nameChanged = true;
        }
		return nameChanged;
    }
	
    updateValueElementChanges(currentScenario, mainScenario, name, value, valueUnit, valFormula, accumulator, baseTuples, timestamp, viewAlternative, maxChange,expression, callback, skipOtherScenarios, updateOtherScenarios){
		var self = this;
		var netBaseTuples = 0;
		var replaceMeasurement = false;
		var replaceBaseMeasurements = [];
		var replaceMeasure = false;
		var usePreviousChange = false;
		var rescale = false;
		var calculate = false;
		var changeInValue = false;
		var destroyOldMeasure = false;
		var updateOtherScenarios = updateOtherScenarios?updateOtherScenarios:false;
		
		var obsMesCurrentScenario;
		var obsMesScenario;
		var mestVM;
		var measureVM;
		var allObsMeasures;
						
		var scenario = viewAlternative.getMainScenario(self.getNestedParent());
		var commonScenario = DataManager.getDataManager().get('currentCommonScenario');
		var beepPackage = self.getNestedParent();
		var valueMeasurement = self.get('valueMeasurement');
		var calculateSat;
		var isOVS = false;
		var vp = self.get('componentOwner');
		if(vp){
			var ovs = vp.get('overAllSatisfaction');
			if(ovs && ovs.get('id') === self.get('id')){
				isOVS = true;
			}
		}
		if(value === '####'){
			value = Number.MAX_VALUE;
		}
		
		obsMesCurrentScenario = currentScenario.getObservedMeasureWithMeasurements(valueMeasurement, timestamp ? true:false,timestamp);	
		obsMesScenario = scenario.getObservedMeasureWithMeasurements(valueMeasurement, timestamp ? true:false,timestamp);
		allObsMeasures = currentScenario.getAllObservedMeasureWithMeasurements(valueMeasurement);
		var allCollMeasurements = [];
		_.each(allObsMeasures,function(obsMeas){
			allCollMeasurements.push(obsMeas.get('measurements').at(0));
		});		
	    if (obsMesCurrentScenario) {
            mestVM = obsMesCurrentScenario.get('measurements').at(0);
			if(DataManager.getDataManager().get('debugMode')){
				mestVM.set('expression', expression);
			}
	        measureVM = obsMesCurrentScenario.get('measure');
	    }
	    var measureMatch;
	    var checkedMeasureMatch = false;
	    function matchingMeasureExists() {
	        if (!checkedMeasureMatch && measureVM.get('trait')) {
	            var defaultMeasureLibrary = beepPackage.createDefaultMeasureLibrary();
	            measureMatch = defaultMeasureLibrary.findMeasure(measureVM.get('trait').get('name'), valueUnit, accumulator, baseTuples);
	            checkedMeasureMatch = true;
	        }
	        return measureMatch;
	    }
        changeInValue = handleBaseRelationChange();
        changeInValue = self.handleNameChange(name, viewAlternative, measureVM, changeInValue);
        handleFormulaChange(valueMeasurement, obsMesCurrentScenario);
		if (!skipOtherScenarios && updateOtherScenarios) {
			var measurements = valueMeasurement.get('measurement');
			var contexts = [];
			measurements.each(function(measurement){
				if(measurement.get('observedMeasure') && measurement.get('observedMeasure').get('observation').getContext()) {
					contexts.push(measurement.get('observedMeasure').get('observation').getContext());
				}
			});
			contexts = _.uniq(contexts);
			async.each(contexts,function(context,updatedScenario){
				if(context !== scenario){
					var alt = context.getAlternative();
					self.updateValueMeasure(alt, function(){
						updatedScenario();
					},true,obsMesScenario,timestamp);
				}else{
					updatedScenario();
				}
			},function(){
				if(changeInValue){
					obsMesScenario.set('lastChange',new Date().getTime());
				}
				callback(obsMesScenario,mestVM,measureVM,calculateSat,changeInValue);			
			});
		}else{
			if(changeInValue){
				if(obsMesScenario){
					_.each(allObsMeasures,function(obsMes){
						obsMes.set('lastChange',new Date().getTime());	
					});
				}else {
					var allObsMesSatScenario = scenario.getAllObservedMeasureWithMeasurements(self.get('satisfactionLevel'));
					_.each(allObsMesSatScenario,function(obsMesSatScenario){
						obsMesSatScenario & obsMesSatScenario.set('lastChange',new Date().getTime());	
					});
				}
			}				
			callback(obsMesScenario,mestVM,measureVM,calculateSat,changeInValue);
		}


		function handleBaseRelationChange() {
			handleMaxChangeChange();
			if(obsMesScenario){
				netBaseTuples = 0;
				var newTuples = 0;
				var updateTuples = 0;
				var oldTuples = 0;
				var measureScenario = obsMesScenario.get('measure');
				var collMeasurement = obsMesScenario.get('measurements').at(0);
				var obsMeasure = collMeasurement.get('observedMeasure');
				var measure = obsMeasure.get('measure');
				var mesRelations =  measure.get('baseMeasureTo');
				if(mesRelations){
					mesRelations.each(function(baseRel){	//to handle deletes outside UI
						var relTo = baseRel.get('to');
						if(!relTo){
							relTo = baseRel.previousAttributes().to;
						}
						if(!relTo && baseRel && baseRel.id){
							relTo = DataManager.getDataManager().deletedMesRelDetails[baseRel.id];
							if(relTo){
								relTo = relTo.measureRelation;
							}
						}
						
						var foundTuple = false;
						_.each(baseTuples,function(baseTuple){
							if(baseTuple.old){
								if(baseTuple.old.measure === relTo || (baseTuple.new && baseTuple.new.measure === relTo)){
									foundTuple = true;
								}
							}	
						});
						var relToAltId = null;
						if(relTo){
							relToAltId = DataManager.getDataManager().getRepositoryId(relTo.get('id'));
						} 
						if(!foundTuple && relTo && ((viewAlternative.get('id') === relToAltId) || (baseRel && baseRel.get('usePreviousAlternative') && viewAlternative.get('id') !== relToAltId) )) {
							var delTuple = {};
							var mc = relTo.get('trait');
							if(!mc){
								mc = relTo.previousAttributes().trait;
							}
							delTuple.old = {measure:relTo,measuredCharacteristic:mc};
							if(baseRel.get('usePreviousAlternative')){
								var useAlt = viewAlternative.getPreviousPhasePrimary();
								var delData = DataManager.getDataManager().deletedMesRelDetails[baseRel.id];
								var baseComp = delData ? delData.baseComponent :null;
								if(useAlt && baseComp){
									delTuple.old.component = baseComp;
									useAlt.getModelLinked(baseComp,function(linkedComp){
										if(linkedComp) {
											mc = linkedComp.get('valueMeasurement');
											var compScenario = useAlt.getMainScenario(linkedComp.getNestedParent());
											var componentObsMes = compScenario ? compScenario.getObservedMeasureWithMeasurements(mc) : null;
											if (!componentObsMes) {
											    return;
											}
											delTuple.new = {};
											var newNode = delTuple.new;
											newNode.component = componentObsMes.get('measurements').at(0).get('measuredCharacteristic').getMeasurand();
											newNode.measure = componentObsMes.get('measure');
											newNode.measuredCharacteristic = mc;
											newNode.usePreviousAlternative = true;
											var inLinedRescaledMes = baseRel.get('rescaledMeasure');
											if(inLinedRescaledMes){
												newNode.offset = inLinedRescaledMes.get('offset')?inLinedRescaledMes.get('offset'):0;
												newNode.multiplier = baseRel.get('multiplier')?inLinedRescaledMes.get('multiplier'):1;
												newNode.operation = baseRel.get('operation');
											}else{
												newNode.offset = 0;
												newNode.multiplier = 1;
												newNode.operation = null;								
											}
											replaceMeasure = true;
											replaceMeasurement = true;
											usePreviousChange = true;
										}									
									});
								}
							}
							baseTuples.push(delTuple);
						}
					});
				}
				
				_.each(baseTuples,function(baseTuple){
					var obsMesRelations;
					_.each(allCollMeasurements,function(collMest){
						obsMesRelations = collMest.get('baseMeasurementTo');
						if(baseTuple.old && baseTuple.new){
							if(baseTuple.new.usePreviousAlternative && !viewAlternative.getPreviousPhasePrimary()){
								return;
							}						
							netBaseTuples++;
							var baseRelation;
							if(obsMesRelations){
								var baseMstRelation = obsMesRelations.get(baseTuple.old.id);
								/*for(var i=0;i<mesRelations.length;i++){
									if((mesRelations.at(i).get('to') == baseTuple.old.measure) || (mesRelations.at(i).get('to') == baseTuple.new.measure)){
										baseRelation = mesRelations.at(i);
										break;
									}
								}*/
								baseRelation = baseTuple.old.baseMeasureRel;
							}
							var changed = false;
							for(var prop in baseTuple.old){
								if(baseTuple.old[prop] != baseTuple.new[prop]){
									changed = true;
									if(!self.checkMeasureReuse(measureScenario) && baseRelation && !matchingMeasureExists()){
										//var baseRelation = collMeasurement.getMeasureRelationForMeasurementRelation(baseMstRelation);
										if(prop === 'multiplier' || prop === 'offset' || prop === 'operation'){
											var rescaledMeasure = baseRelation.get('rescaledMeasure');
											if(!rescaledMeasure){
												rescaledMeasure = baseRelation.createRescaledMeasure(baseTuple.new['offset'],baseTuple.new['multiplier'],baseTuple.new['operation']);
											}
											if(prop === 'multiplier' || prop === 'offset'){
												rescaledMeasure.set(prop,baseTuple.new[prop]);		
											}
											if(prop === 'operation'){
												var operation = baseRelation.get('measure').get('libraries').get('measureElements').findWhere({name:baseTuple.new[prop]});
												if(baseTuple.old[prop] !== baseTuple.new[prop]){
													rescaledMeasure.set(prop,operation);
												}
											}
										}else{
											if(prop === 'usePreviousAlternative'){
												baseRelation.set('usePreviousAlternative',baseTuple.new[prop]);
												var useAlt = viewAlternative;
												if(baseTuple.new[prop]){
													useAlt = viewAlternative.getPreviousPhasePrimary();
												}
												var baseMest;
												if(useAlt){
													useAlt.getModelLinked(baseTuple.old.component,function(linkedComponent){
														if(linkedComponent) {
															var compScenario = useAlt.getMainScenario(linkedComponent.getNestedParent());
                                                            if (compScenario) {
                                                                var whenObs;
                                                                var obs = collMest.get('observedMeasure').get('observation');
                                                                whenObs = obs.get('whenObserved');
                                                                var obsBaseMest = compScenario.getObservedMeasureWithMeasurements(linkedComponent.get('valueMeasurement'), whenObs ? true : false, whenObs);
                                                                if (obsBaseMest) {
                                                                    baseMest = obsBaseMest.get('measurements').at(0);
                                                                }
															}
														}
													});
												}
												if(baseMstRelation){
													if(baseMest){
														baseMstRelation.set('to',baseMest);
														baseRelation.set('to',baseMest.get('observedMeasure').get('measure'));
													}else{
														var multipleAgg = _.filter(collMest.get('measurementRelationships').models,function(rel){if(rel != baseMstRelation && rel.get('to') === baseMstRelation.get('to')){return rel;}});
														if(multipleAgg.length == 0){
															collMest.get('baseMeasurements').remove(baseMstRelation.get('to'));
														}
														baseMstRelation.destroy();
													}															
												}else{
													if(baseMest){
														var relName = collMest.getUniqueProperty('name',baseMest.get('name') + "Base",'baseMeasurementTo');
														collMest.createBaseMesurementRelationship(relName,relName,baseMest,baseRelation);
													}
												}
												calculate = true;
												replaceMeasurement = true;
											}
										}
									}else{
										replaceMeasure = true;
										replaceMeasurement = true;
									}
								}
							}
							if(changed){
								updateTuples++;
								calculate = true;
							}
						}else if(baseTuple.new && !baseTuple.old){
							if(baseTuple.new.usePreviousAlternative && !viewAlternative.getPreviousPhasePrimary()){
								return;
							}						
							netBaseTuples++;
							newTuples++;
							if(!collMest.get('observedMeasure').get('observation').get('whenObserved') && !self.checkMeasureReuse(measureScenario) && measure.get('type') === 'smm_CollectiveMeasure' && baseTuple.new['measure'] && !matchingMeasureExists()){
								var baseRelation = measure.createBaseMeasureRelationship(baseTuple.new['measure']);
								baseRelation.set('usePreviousAlternative',baseTuple.new['usePreviousAlternative']);
								baseRelation.createRescaledMeasure(baseTuple.new['offset'],baseTuple.new['multiplier'],baseTuple.new['operation']);
								baseTuple.new.baseMeasureRel = baseRelation;
								calculate = true;
							}
						}else{
							if(baseTuple.old.usePreviousAlternative && !viewAlternative.getPreviousPhasePrimary()){
								return;
							}						
							oldTuples++;
							if(!self.checkMeasureReuse(measureScenario)){
								if(baseTuple.old.id){
									var baseMestRel = obsMesRelations.get(baseTuple.old.id);
									var collMestWhenObserved = collMest.get('observedMeasure').get('observation').get('whenObserved');
									if(collMestWhenObserved){
										var baseMeasurementTo = collMest.get('baseMeasurementTo');
										if(baseMeasurementTo) {
											for(var i=0;i<baseMeasurementTo.length;i++){
												var toBaseMst = baseMeasurementTo.at(i).get('to');
												var fromMestWhenObserved = baseMeasurementTo.at(i).get('from').get('observedMeasure').get('observation').get('whenObserved');
												if(collMestWhenObserved === fromMestWhenObserved && toBaseMst && toBaseMst.get('measuredCharacteristic') && toBaseMst.get('measuredCharacteristic').getParent() === baseTuple.old.component){
													var collMestRescaledMeasure = baseMeasurementTo.at(i).get('definition')?baseMeasurementTo.at(i).get('definition').get('rescaledMeasure'):null;
													
													var collMestoffset = collMestRescaledMeasure ? collMestRescaledMeasure.get('offset') : 0;
									                var collMestmultiplier = collMestRescaledMeasure ? collMestRescaledMeasure.get('multiplier') : 1;
									                var collMestoperation = collMestRescaledMeasure ? collMestRescaledMeasure.get('operation') : null;
														
									                if (collMestoffset === baseTuple.old.offset && collMestmultiplier === baseTuple.old.multiplier && collMestoperation === baseTuple.old.operation) {
														baseMestRel = baseMeasurementTo.at(i);
														break;
													}
												}
											}
										}
									}
									if(baseMestRel) {
										var mesRelation = collMest.getMeasureRelationForMeasurementRelation(baseMestRel);
										var baseMest = baseMestRel.get('to');
										var multipleAgg = _.filter(collMest.get('measurementRelationships').models,function(rel){if(rel != baseMestRel && rel.get('to') === baseMest){return rel;}});
										if(multipleAgg.length == 0){
											collMest.get('baseMeasurements').remove(baseMest);
										}										
										if(baseMestRel) {
											baseMestRel.destroy();
										}
										if(mesRelation) {
											mesRelation.destroy();	
										}									
									}
								}else{
									var mesRelation;
									mesRelations.each(function(rel){
										var relTo = rel.get('to');
										if(!relTo){
											relTo = rel.previousAttributes().to;
										}
										if(relTo === baseTuple.old.measure){
											mesRelation = rel;	
										}
									});
									if(mesRelation){
										mesRelation.destroy();
									}
								}
								calculate = true;
							}
						}
					});
				});
				if((netBaseTuples>0 && (measure.get('type') !== 'smm_CollectiveMeasure')) || (netBaseTuples===0 && (measure.get('type') === 'smm_CollectiveMeasure') )){
					replaceMeasure = true;
					if(!self.checkMeasureReuse(measureScenario)){
						destroyOldMeasure = true;	
					}
					replaceMeasurement = true;
/*					if(skipOtherScenarios && netBaseTuples === 0){	//on henks demand..retaining the value
						value = 0;
					}*/
				}
				if((self.checkMeasureReuse(measureScenario) && (oldTuples>0 || newTuples > 0 || updateTuples> 0)) 
                    || ((!self.checkMeasureReuse(measureScenario) && (oldTuples > 0 || newTuples > 0 || updateTuples > 0)) && (measureMatch != measureScenario))){
                    replaceMeasure = true;
                    if (!self.checkMeasureReuse(measureScenario)) {
                        destroyOldMeasure = true;
                    }
				}
				if(oldTuples>0 || newTuples > 0 || (measure.get('type') === 'smm_CollectiveMeasure' && baseTuples.length == 0)){
					updateOtherScenarios = true;
				}
				handleAccumulatorChange();
				handleValueUnitChange();
				if(replaceMeasure && (self.checkMeasureReuse(measureScenario) || destroyOldMeasure || matchingMeasureExists() || usePreviousChange)){
					if(!self.checkMeasureReuse(measureScenario) && matchingMeasureExists()){
						destroyOldMeasure = true;
					}
					var newMeasure = invokeReplaceVMMeasure();
					if(destroyOldMeasure && (newMeasure.id !== measureVM.id)){
						var measureLibrary = newMeasure.get('libraries');
						var expectedMeasureName = newMeasure.get('trait').get('name')+ ' Measure';
						var measureNameExists = measureLibrary.get('measureElements').findWhere({'name':expectedMeasureName});
						if(!measureNameExists) {
							newMeasure.set('name',expectedMeasureName);
						}else if(expectedMeasureName !== newMeasure.get('name')){
							newMeasure.set('name',measureVM.get('name'));
						}
						measureVM.destroy();
					}
					measureVM = newMeasure;
					handleMesurementReplaceAndNewRelations();
				}else{
					handleMesurementReplaceAndNewRelations();
				}
				function invokeReplaceVMMeasure(){
					var defaultMeasureLibrary = beepPackage.createDefaultMeasureLibrary();
					//var baseRelSetToMatch =  self.constructBaseMeasureTupleSet(valueMeasurement,scenario);
					var baseRelSetToMatch =  baseTuples;
					var accToMatch = accumulator;
					var replacedMeasure;
					_.each(allCollMeasurements,function(collMeasurement){
						replacedMeasure = collMeasurement.replaceMeasure(defaultMeasureLibrary,null,measureVM.get('trait').get('name'),valueUnit,valFormula,accToMatch,baseRelSetToMatch,true,null,null,null);
					});
					replaceMeasurement = true;	
					return replacedMeasure;
				}				
				function handleAccumulatorChange(){
					if(accumulator && (measureVM && measureVM.get('type') === 'smm_CollectiveMeasure' && measureVM.get('accumulator') !== accumulator)){
						if(self.checkMeasureReuse(measureVM) || matchingMeasureExists()){
							replaceMeasure = true;
						}else{
							if(measureVM.get('type') === 'smm_CollectiveMeasure'){
                                measureVM.set('accumulator', accumulator);	
							}
						}
						calculate = true;
					}
				}				
				function handleMesurementReplaceAndNewRelations(){
					handleValueMeasurementValueChange();
					if(rescale){
						measureVM.rescaleToFixUnitDifference(true,true);
					}		
					checkReplacement();
					function checkReplacement(){
						_.each(baseTuples,function(baseTuple){
							_.each(allCollMeasurements,function(collMeasurement){
								if(baseTuple.new && !baseTuple.old){
									//var name = collMeasurement.getUniqueProperty('name',collMeasurement.get('name') + "Base",'baseMeasurementTo');
									var component = baseTuple.new['component'];
									var mc = isOVS ? component.get('satisfactionLevel') : component.get('valueMeasurement');
									var useAlt = viewAlternative;
									if(baseTuple.new.usePreviousAlternative){
										useAlt = viewAlternative.getPreviousPhasePrimary();
									}
									if(useAlt){
										useAlt.getModelLinked(component,function(linkedComp){
											if(linkedComp) {
												mc = isOVS ? linkedComp.get('satisfactionLevel') : linkedComp.get('valueMeasurement');
												var compScenario = useAlt.getMainScenario(linkedComp.getNestedParent());
												var componentObsMesures = compScenario?compScenario.getAllObservedMeasureWithMeasurements(mc):null;
												
												if(componentObsMesures){
													for(var i=0;i<componentObsMesures.length;i++){
														if(collMeasurement.get('observedMeasure').get('observation').get('whenObserved') === componentObsMesures[i].get('observation').get('whenObserved')){
															var compMest = componentObsMesures[i].get('measurements').at(0);
															if(!replaceMeasurement){
																var relName = collMeasurement.getUniqueProperty('name',compMest.get('name') + "Base",'baseMeasurementTo');
																collMeasurement.createBaseMesurementRelationship(relName,relName,compMest,baseTuple.new.baseMeasureRel);		
															}
															replaceBaseMeasurements.push(compMest);
															break;
														}
													}	
													/*_.each(componentObsMesures,function(componentObsMes){
														if(collMeasurement.get('observedMeasure').get('observation').get('whenObserved') === componentObsMes.get('observation').get('whenObserved')){
															var compMest = componentObsMes.get('measurements').at(0);
															if(!replaceMeasurement){
																var relName = collMeasurement.getUniqueProperty('name',compMest.get('name') + "Base",'baseMeasurementTo');
																collMeasurement.createBaseMesurementRelationship(relName,relName,compMest,baseTuple.new.baseMeasureRel);		
															}
															replaceBaseMeasurements.push(compMest);
														}
													});*/
												}	
											}									
										});
									}
								}
								if(baseTuple.new && baseTuple.old){
									var component = baseTuple.new['component'];
									var mc = isOVS ? component.get('satisfactionLevel') : component.get('valueMeasurement');
									var useAlt = viewAlternative;
									if(baseTuple.new.usePreviousAlternative){
										useAlt = viewAlternative.getPreviousPhasePrimary();
									}
									if(useAlt){
										useAlt.getModelLinked(component,function(linkedComp){
											if(linkedComp) {
												mc = isOVS ? linkedComp.get('satisfactionLevel') : linkedComp.get('valueMeasurement');
												var compScenario = useAlt.getMainScenario(linkedComp.getNestedParent());
												var componentObsMesures = compScenario?compScenario.getAllObservedMeasureWithMeasurements(mc):null;
												if(componentObsMesures){
													/*_.each(componentObsMesures,function(componentObsMes){
														var compMest = componentObsMes.get('measurements').at(0);
														replaceBaseMeasurements.push(compMest);
													});*/
													for(var i=0;i<componentObsMesures.length;i++){
														var compMest = componentObsMesures[i].get('measurements').at(0);
														if(collMeasurement.get('observedMeasure').get('observation').get('whenObserved') === componentObsMesures[i].get('observation').get('whenObserved')){
															replaceBaseMeasurements.push(compMest);
															break;
														}
														
													}
												}
											}
										});
									}
								}								
							});
							
						});	
						//replaceBaseMeasurements = _.uniq(replaceBaseMeasurements);
						if(netBaseTuples > 0 && !replaceMeasurement){// add new Relationships
							if(calculate){
								//collMeasurement.calculate(baseRelCallback);
								_.each(allCollMeasurements,function(collMeasurement){
                                    var cmTimeStamp = collMeasurement.get('observedMeasure').get('observation').get('whenObserved');
                                    if (DataManager.getDataManager().get('debugMode') && collMeasurement && collMeasurement.getRelationDetails) {
                                        collMeasurement.set('expression', collMeasurement.getRelationDetails()['expression']);
                                    }
									collMeasurement.get('measuredCharacteristic').getMeasurand().calculate(scenario,cmTimeStamp);	
								});
							}
						}else{
							checkMeasurementReplacement(baseTuples);
						}
						function checkMeasurementReplacement(baseTuples){
							_.each(allCollMeasurements,function(collMeasurement){
								var cmTimeStamp = collMeasurement.get('observedMeasure').get('observation').get('whenObserved');
								if(replaceMeasurement){
									var obsMes = collMeasurement.get('observedMeasure');
									var newMeasurement = obsMes.replaceMeasurement(measureVM.get('type'),replaceBaseMeasurements,null,baseTuples);
									collMeasurement = newMeasurement;
									//collMeasurement.calculate(baseRelCallback);
									if(collMeasurement.get('type') === "smm_DirectMeasurement"){//when changed from collective to direct
										collMeasurement.set('value',value);
                                    }
                                    if (DataManager.getDataManager().get('debugMode') && collMeasurement && collMeasurement.getRelationDetails) {
                                        collMeasurement.set('expression', collMeasurement.getRelationDetails()['expression']);
                                    }
									collMeasurement.get('measuredCharacteristic').getMeasurand().calculate(scenario,cmTimeStamp);
									//baseRelCallback(calculate);
								}else{
									if(calculate){
										//collMeasurement.calculate(baseRelCallback);
                                        if (DataManager.getDataManager().get('debugMode') && collMeasurement && collMeasurement.getRelationDetails) {
                                            collMeasurement.set('expression', collMeasurement.getRelationDetails()['expression']);
                                        }
										collMeasurement.get('measuredCharacteristic').getMeasurand().calculate(scenario,cmTimeStamp);
									}
								}
							});
						}
					}
				}
			}
			if(replaceMeasure){
				calculate = true;//here calculate = changeInValue
            }
			return calculate;
		}	
		
		function handleValueUnitChange(){
			if(measureVM && measureVM.get('unit')){
				var unit = beepPackage.findOrCreateUnit(valueUnit.unitName,valueUnit.unitObj);
				if(measureVM.get('unit').get('name') !== valueUnit.unitName){
					//copyObservedMeasureIfRequired(currentScenario,mainScenario,valueMeasurement);
					if(obsMesCurrentScenario){
						if(obsMesScenario && self.checkMeasureReuse(measureVM) || matchingMeasureExists()){
							replaceMeasure = true;
						}else{
							measureVM.set('unit',unit);
						}
					}
					rescale = true;
					changeInValue = true;
				}
				var roundingFactor = valueUnit.roundingDigits;
				var significantFactor = valueUnit.significantDecimals;
				if(roundingFactor < 0 || roundingFactor > 20){//can remove when validation in ui exists
					roundingFactor = 4;
				}
				if(significantFactor < 0 || significantFactor > 20){//can remove when validation in ui exists
					significantFactor = 2;
				}
				if(unit.get('roundingDigits') != roundingFactor){
					unit.set('roundingDigits',roundingFactor);
					calculate = true;
					calculateSat = true;
				}
				unit.set('significantDecimals',significantFactor);
			}
		}	

        function handleFormulaChange(valueMeasurement, observedMeasure){
            if (valueMeasurement && observedMeasure && observedMeasure.get('valueFormula') !== valFormula){
				//valueMeasurement.set('valueFormula', valFormula);
                observedMeasure.set('valueFormula', valFormula);
                //valueMeasurement.unset('valueFormula');
                changeInValue = true;
			}
		}
		function handleValueMeasurementValueChange(){
			if(mestVM){
				var unit = measureVM.get('unit');
				var sigDecimals = unit?unit.get('significantDecimals'):'2';
				var roundedVal = parseFloat(mestVM.get('value')).toFixed(sigDecimals);
				if(value !== roundedVal && mestVM.get('value') != value){
					calculate = true;
					calculateSat = true;
					changeInValue = true;
				}
				if((measureVM.get('type') !== 'smm_CollectiveMeasure' && calculate) || 
					(measureVM.get('type') === 'smm_CollectiveMeasure' && calculate && (self.get('aggregatedFrom').length === 1) && (self.id === self.get('aggregatedFrom').at(0).id))){
					if(value != null && value !== '' && !isNaN(value) && value != Number.MAX_VALUE){
						var roundDecimals = unit?unit.get('roundingDigits'):'4';
						value = parseFloat(value).toFixed(roundDecimals);
					}
					mestVM.set('value',value);	
				}
			}	
		}	
		function handleMaxChangeChange(){
			var isIterative = self.get('isIterative');
			if(isIterative && self.get('maxChange') !== maxChange){
				self.set('maxChange',maxChange);
				calculate = true;
				calculateSat = true;				
			}
		}		
	};

	primeValueMeasurement(valueMeasurement,measurand,value,unitToMatch,valFormula,accumulator,baseTuples,timestamp,viewAlternative,maxChange, isRecipient,expression){
		var self = this;
		var charToMatch;
		var accToMatch;
		var isOVS = false;
		var vp = self.get('componentOwner');
		if(vp){
			var ovs = vp.get('overAllSatisfaction');
			if(ovs && ovs.get('id') === self.get('id')){
				isOVS = true;
			}
		}
		var mcCharacteristic  = valueMeasurement.get('characteristicDefinition');
		if(mcCharacteristic){
			charToMatch = mcCharacteristic.get('name');
		}else{
			charToMatch = isRecipient ? measurand.get('name') + " recipient" : measurand.get('name');
		}
		if(baseTuples && baseTuples.length > 0){
			if(accumulator){
				accToMatch = accumulator;
			}else{
				accToMatch = Accumulator.symbols()[0];	
			}
		}
		var currentPackage = self.getNestedParent();
		var measureLibrary = currentPackage.createDefaultMeasureLibrary();
		var scenario = viewAlternative.getMainScenario(self.getNestedParent());
		var measurement = scenario.createMeasurement(currentPackage,self,valueMeasurement,measureLibrary,charToMatch,unitToMatch,valFormula,accToMatch,baseTuples,null,null,timestamp,null,isOVS,viewAlternative);
        measurement.getParent().set('valueFormula', valFormula);
		if(DataManager.getDataManager().get('debugMode')){
			measurement.set('expression', expression);
		}
        if (!self.get('isIterative')) {
			var roundingFactor = unitToMatch.roundingDigits;
			if(value && !isNaN(value)){
				value = parseFloat(value).toFixed(roundingFactor);
			}
			measurement.set('value',value);	
		}else{
			self.set('maxChange',maxChange);
			//measurement.get('measuredCharacteristic').getMeasurand().calculate(scenario,timestamp);
		}
		return measurement;
	};
	
	addBaseRelationshipsToParents(measureLibrary,measurementComponent,viewAlternative,callback){
		var self = this;
		var isCommon = false;
		var commonScenario = DataManager.getDataManager().get('currentCommonScenario');
		var scenario = viewAlternative.getMainScenario(self.getNestedParent());
		if(commonScenario === scenario){
			isCommon = true;
		}
		var measure = measurementComponent.get('observedMeasure').get('measure');
		var timestamp = measurementComponent.get('observedMeasure').get('observation').get('whenObserved');
		var parentComponents = self.get('aggregatedTo');
		async.eachSeries(parentComponents.models, function (parentValue, handledValue) {
		    var valProp = parentValue.get('componentOwner');
		    var isOVSParent = false;
		    var measureComponent = measurementComponent.get('observedMeasure').get('measure');
		    var obsMesChild;
		    var mesurementChild;
		    var childValueMeasurement;
		    if (valProp && valProp.get('overAllSatisfaction') === parentValue) {
		        isOVSParent = true;
		        childValueMeasurement = self.get('satisfactionLevel');
		        obsMesChild = scenario.getObservedMeasureWithMeasurements(childValueMeasurement, timestamp ? true : false, timestamp);
		        if(obsMesChild) {
		        	mesurementChild = obsMesChild.get('measurements').at(0);
		        	measureComponent = mesurementChild.get('observedMeasure').get('measure');
		        }
		    } else {
		        childValueMeasurement = self.get('valueMeasurement');
		        obsMesChild = scenario.getObservedMeasureWithMeasurements(childValueMeasurement, timestamp ? true : false, timestamp);
		        if(obsMesChild){
		        	mesurementChild = obsMesChild.get('measurements').at(0);
		        	measureComponent = mesurementChild.get('observedMeasure').get('measure');
		        }
		    }
			var parentValueMeasurement = parentValue.get('valueMeasurement');
			var parentScenario = viewAlternative.getMainScenario(parentValue.getNestedParent());
			parentValueMeasurement.determineEffectiveScenario(isCommon,parentValue.getNestedParent(),viewAlternative.get('id'),function(effectiveScenario){
				if(effectiveScenario !== parentScenario){
				    var obsmCurrent = effectiveScenario.getObservedMeasureWithMeasurements(parentValueMeasurement, timestamp ? true : false, timestamp);
					if(!obsmCurrent){
						handledValue();
						return;
					}
					var targetObservation = parentScenario.getObservation();
					obsmCurrent.copyObservedMeasureExclusive(targetObservation);
				}
				var parentObsMesWithMeasurements = parentScenario.getObservedMeasureWithMeasurements(parentValueMeasurement, timestamp ? true : false, timestamp);
				if(parentObsMesWithMeasurements && mesurementChild){
					var measureParent = parentObsMesWithMeasurements.get('measure');
					var mestParent = parentObsMesWithMeasurements.get('measurements').at(0);
					var parentContext = mestParent.get('observedMeasure').get('observation').getContext();
					var parentAlt;
					if(parentContext.get('type') === 'transformation_PhaseObjectiveSet'){
						parentAlt = parentContext.get('phaseObjectiveSetOwner').get('id');			
					}else{
						parentAlt = DataManager.getDataManager().getRepositoryId(parentContext.get('id'));
					}					
					var mestRels = mestParent.get('measurementRelationships');
					for(var i=0;i<mestRels.length;i++){
						var mestRel = mestRels.at(i);
						if(mestRel.get('to') && mestRel.get('to').get('measuredCharacteristic') === measurementComponent.get('measuredCharacteristic')){
							var context = mestRel.get('to').get('observedMeasure').get('observation').getContext();
							var mestAlt;
							if(context.get('type') === 'transformation_PhaseObjectiveSet'){
								mestAlt = context.get('phaseObjectiveSetOwner').get('id');			
							}else{
								mestAlt = DataManager.getDataManager().getRepositoryId(context.get('id'));
							}
							if(mestAlt !== parentAlt){
								handledValue();
								return;
							}
						}
					}					
					
					
					var oldMeasureParent;
					var reused = false;
					var newBaseMeasure = false;
					var typeChange = false;
					var deleteReplaceMeasure = false;

					if(measureComponent && measureParent.get('type') === 'smm_DirectMeasure'){
						typeChange = true;
					}
					if(measureParent.reused(parentValueMeasurement)){
						reused = true; 
					}
					if(measureParent.get('type') === 'smm_CollectiveMeasure' && !measureParent.hasBaseMeasure(measureComponent)){
						newBaseMeasure = true;
					}
					var baseMeasureTupleSet;
					var parentMeasurement = parentObsMesWithMeasurements.get('measurements').at(0);
					if(typeChange || (reused && newBaseMeasure)){
						var charToMatch = measureParent.get('trait');
						var unitToMatch = measureParent.get('unit');
						var unitObj = {unitName:unitToMatch.get('name'), roundingDigits:unitToMatch.get('roundingDigits'), significantDecimals:unitToMatch.get('significantDecimals')};
                        var valFormula = parentObsMesWithMeasurements.get('valueFormula') ? parentObsMesWithMeasurements.get('valueFormula'):parentValueMeasurement.get('valueFormula');
						baseMeasureTupleSet = parentValue.constructBaseMeasureTupleSet(parentValueMeasurement,parentScenario);
						var baseMeasureTuple = measureParent.constructBaseMeasureTuple(self.get('valueMeasurement'),measureComponent,0,1,null,null);
						self.updateBaseMeasureTupleSet(baseMeasureTupleSet,null,baseMeasureTuple);
						var measureReplaced = parentMeasurement.replaceMeasure(measureLibrary,null,charToMatch,unitObj,valFormula,typeChange ? Accumulator.symbols()[0] : measureParent.get('accumulator'),baseMeasureTupleSet,true,null,null,null);
						oldMeasureParent = measureParent;
						measureParent = measureReplaced;
						handleNext();
					}else{
						handleNext();
					}
					function handleNext(){
						var mesRel;
						if(!reused && newBaseMeasure){
                            mesRel = measureParent.createBaseMeasureRelationship(measureComponent);
						}
						if(newBaseMeasure && measureParent.get('unit') !== measureComponent.get('unit')){
							measureParent.rescaleToFixUnitDifference(false,true);
						}
						if(deleteReplaceMeasure && oldMeasureParent){
							oldMeasureParent.destroy();
						}
						if(typeChange){
							var newMeasurement = parentObsMesWithMeasurements.replaceMeasurement("smm_CollectiveMeasure",parentMeasurement.get("baseMeasurements"),null);
							parentMeasurement = newMeasurement;
							if (DataManager.getDataManager().get('debugMode') && parentMeasurement && parentMeasurement.getRelationDetails) {
                                parentMeasurement.set('expression', parentMeasurement.getRelationDetails()['expression']);
                            }
							parentMeasurement.calculate();
							newMeasurement.get('observedMeasure').set('lastChange',new Date().getTime());
							handledValue();
						}else{
							var name = parentMeasurement.getUniqueProperty('name',mesurementChild.get('name') + "Base",'baseMeasurementTo');
							if(!mesRel){
								mesRel = measureParent.createBaseMeasureRelationship(measureComponent);
							}
							parentMeasurement.createBaseMesurementRelationship(name, name, mesurementChild, mesRel);
							/*if (!reused && measureParent.get('baseMeasureTo').length > parentMeasurement.get('baseMeasurementTo').length) { //commenting as we are not checking in other scenarios whether the definition is used or not
							    var mesRels = measureParent.get('baseMeasureTo');
							    var mstRels = parentMeasurement.get('baseMeasurementTo');
							    var mesRelToDestroy = [];
							    for (var i = 0; i < mesRels.length; i++) {
							        var foundDefReference = false;
							        for (var j = 0; j < mstRels.length; j++) {
							            if (mstRels.at(j).get('definition') === mesRels.at(i)) {
							                foundDefReference = true;
							                break;
							            }
							        }
							        if (!foundDefReference) {
							            mesRelToDestroy.push(mesRels.at(i));
							        }
							    }
							    _.each(mesRelToDestroy, function (rel) {
							        rel.destroy();
							    });
							}*/
							if (DataManager.getDataManager().get('debugMode') && parentMeasurement && parentMeasurement.getRelationDetails) {
                                parentMeasurement.set('expression', parentMeasurement.getRelationDetails()['expression']);
                            }
							parentMeasurement.calculate();
							parentMeasurement.get('observedMeasure').set('lastChange',new Date().getTime());
							handledValue();
						}
					}
				}
				else {
					handledValue();
				}
			});
			
		},function(){
			if(callback){
				callback();
			}
			
		});	
	};	
	
	destroyAssosciatedMeasurement(mainScenario){
		var self = this;
		self.get('valueMeasurement').destroy();
		var benchmarks = self.get('benchmark');
		benchmarks.each(function(benchmark){
			benchmark.destroy();
		});
	};
	
	cacheUpdateValueMeasure(viewAlternative,callback){
		var self = this;
		var cacheId = self.get('id');// + viewAlternative.get('id');
		if(!DataManager.getDataManager().calculateValuesCache){
			DataManager.getDataManager().calculateValuesCache = {};
		}
		if(_.findKey(DataManager.getDataManager().calculateValuesCache,function(value,key){
			return key === cacheId;
		})){
			DataManager.getDataManager().calculateValuesCache[cacheId].alternatives.push(viewAlternative);
		}else{
			var alts = [viewAlternative];
			var baseData = {};
			DataManager.getDataManager().calculateValuesCache[cacheId] = {valueElement:self,alternatives:alts,deletedData:baseData};
		}
		callback();
	};
	
	updateValueMeasure(viewAlternative,callback,skipOtherScenarios,backupObsMes,timestamp,updateOtherScenarios,expression){
		var self = this;
        if (!viewAlternative) {
            callback();
			return;
		}
		var altWsData = DataManager.getDataManager().getWorkspaceDataWithIdSync(viewAlternative.id);
		if(_.indexOf(DataManager.getDataManager().deletingData,self.get('id')) >=0 || !altWsData){
			callback('skipped');
			return;	
		}
		var beepPackage = self.getNestedParent();
		var valueMeasurement = self.get('valueMeasurement');
		if(valueMeasurement){
			valueMeasurement.determineEffectiveScenario(false,beepPackage,viewAlternative,function(scenario){
				if(!scenario){
					callback('skipped');
					return;
				}
				var obsMes = scenario.getObservedMeasureWithMeasurements(valueMeasurement, timestamp ? true : false, timestamp);
				if(!obsMes){
					var commonScenario = DataManager.getDataManager().get('currentCommonScenario');
					if(commonScenario){
					    obsMes = commonScenario.getObservedMeasureWithMeasurements(valueMeasurement, timestamp ? true : false, timestamp);
					}
				}
				if(obsMes){
					var baseTuples = self.constructBaseMeasureTupleSet(self.get('valueMeasurement'),scenario,backupObsMes,true);
					var value = obsMes.get('measurements').at(0).get('value');
					var measure = obsMes.get('measure');
					var valueUnit = measure.get('unit');
					var unitName = valueUnit?valueUnit.get('name'):'';
					var roundingDigits = valueUnit?valueUnit.get('roundingDigits'):4;
					var significantDecimals = valueUnit?valueUnit.get('significantDecimals'):2;
					var valUnitObj = {unitName:unitName, roundingDigits:roundingDigits, significantDecimals:significantDecimals};
                    var valueFormula = obsMes.get('valueFormula') ? obsMes.get('valueFormula'):valueMeasurement.get('valueFormula');
					var accumulator;
					if(measure.get('type') === 'smm_CollectiveMeasure'){
						accumulator = measure.get('accumulator');
					}
                    self.updateValueElementChanges(scenario, scenario, self.get('name'), value, valUnitObj, valueFormula, accumulator, baseTuples, null, viewAlternative, self.get('maxChange'),expression,function(){
						callback();
					},skipOtherScenarios,updateOtherScenarios);	
				}else{
					callback('skipped');
				}
			});				
		}else{
			callback();
		}
	};

	
	canAggrigateFrom(elementId){
		var self = this;
		var aggregatedTos = self.get('aggregatedTo');
		for(var i=0;i<aggregatedTos.models.length;i++){
			var aggregatedTo = aggregatedTos.at(i);
			if(aggregatedTo.get('id') === elementId){
				return false;
			}else{
				var parentAggregated = aggregatedTo.canAggrigateFrom(elementId);
				if(!parentAggregated){
					return false;
				}
			}
		}
		return true;
	};
	
	getNextPathNodes(pathNodes,paths,origin){
		var self = this;
		var nextNodes = self.get('aggregatedTo').models.concat();
		for(var i=0;i<nextNodes.length;i++){
			if(nextNodes[i] === origin){
				paths.push(pathNodes.concat());
			}else{
				if(_.indexOf(pathNodes,nextNodes[i]) >= 0){
					continue;
				}
				var newPathNodes = pathNodes.concat(nextNodes[i]);
				nextNodes[i].getNextPathNodes(newPathNodes, paths, origin);
			}			
		}
		//pathNodes.length = 0;
	};
	
	getCyclicPath(){
		var paths = [];
		var self = this;
		self.getNextPathNodes([self],paths,self);
		if(paths.length>0){
			return paths;
		}else{
			return null;
		}
	};
	
	getCyclicPath2(element,paths,path){
		function addPathToPaths(){
			var found = false;
			for(var i=0;i<paths.length;i++){
				var currentPath = paths[i];
				var commonPath = _.intersection(currentPath,path);
				if(commonPath.length === path.length && commonPath.length === currentPath.length){
					found = true;
					break;
				}
			}
			if(!found && path.length>1){
				paths.push(path);
			}
		}
		var self = this;
		if(!element){
			element = this;
		}
		if(!path){
			paths = [];
			path = [];
			path.push(this);
		}
		var aggregatedTos = aggregatedTos = self.get('aggregatedTo').models.concat();
		var selfLocation = _.indexOf(aggregatedTos, self);
		if(selfLocation != -1) {
			aggregatedTos.splice(selfLocation,1);
		}
		for(var i=0;i<aggregatedTos.length;i++){
			var aggregatedTo = aggregatedTos[i];
			if(aggregatedTo === element){
				//paths.push(path);
				addPathToPaths();
				path = [];
				continue;
			}else{
				if(_.indexOf(path,aggregatedTo) >= 0){
					path = [];
					continue;
				}
				path.push(aggregatedTo);
				var parentCyclic = aggregatedTo.getCyclicPath(element,paths,path);
				if(parentCyclic && parentCyclic.length>0){
					addPathToPaths();
					path = [];
					continue;
				}else{
					path.pop();
				}
			}
		}
		if(paths.length>0){
			return paths;
		}else{
			return null;
		}
	};
	printStackSize(method){
		console.log('method:' + method)
		var i=0;
		function testStack(){
			i++
			testStack();
		}		
		try{
			testStack();
		}catch(e){
			console.log('stak size:' + i)
		}
	}
	
	getElementContextInPath(context){
		var self = this;
		var selfRepId = DataManager.getDataManager().getRepositoryId(self.id);
		if(Backbone.Relational.instanceofmodel(context,AnalysisContext)){
			if(selfRepId !== window.plansKey){
				return context;
			}else{
				return context.getAlternative().get('phaseObjectiveSet');
			}
		}else{
			if(selfRepId === window.plansKey){
				return context;
			}else{
				var elementPack = self.getNestedParent();
				var alt = context.get('phaseObjectiveSetOwner');
				return alt.getMainScenario(elementPack);
			}			
		}	
	};
	
	calculate(context,timestamp){
		var self = this;
		var isIterative = this.get('isIterative');
		if(isIterative && self._doingCalculation){
			return;
		}
		var obsMeasure = context.getObservedMeasureWithMeasurements(self.get('valueMeasurement'), timestamp ? true : false, timestamp);
		if(obsMeasure){
			var measurements = obsMeasure.get('measurements');
			var measurement = measurements.at(0);
			var mestCyclicPath = measurement.getCyclicPath();
			if(!mestCyclicPath || mestCyclicPath.length === 0){
				isIterative = false;
			}
		}
		//printStackSize('before iter');
		if(isIterative){
			var paths = this.getCyclicPath();
			if(paths && paths.length > 0){
				for(var j=0;j<paths.length;j++){
					var path = paths[j];
					self._doingCalculation = true;
					calculateIterativeValueWitoutParent();
					if(!DataManager.getDataManager().currentIteratedValues){
						DataManager.getDataManager().currentIteratedValues = {owner:self,elements:[]};
						for(var i = 0;i<path.length;i++){
							DataManager.getDataManager().currentIteratedValues.elements.push(path[i].get('id'));	
						}						
					}else{
                        if (_.indexOf(DataManager.getDataManager().currentIteratedValues.elements, self.get('id')) >= 0) {
                            delete self._doingCalculation;
							return;
						}else{
							for(var i = 0;i<path.length;i++){
								DataManager.getDataManager().currentIteratedValues.elements.push(path[i].get('id'));	
							}
						}
					}
					var firstElement = path.shift();
					path.push(firstElement);	//as first element in path is curren element
					var iterations = DataManager.getDataManager().get('currentPlan').get('maxIterations');
					if(iterations>1){
						DataManager.getDataManager().set('suppressLogging',true);
					}
					for(var iter=0;iter<iterations;iter++){
						var bigChanges = false;
						if(iter === iterations - 1){
							DataManager.getDataManager().set('suppressLogging',false);
						}
						for(var l=0;l<path.length;l++){
							var element = path[l];
							var elementContext = element.getElementContextInPath(context);
							var obsMeasure = elementContext ? elementContext.getObservedMeasureWithMeasurements(element.get('valueMeasurement'), timestamp? true:false,timestamp) : null;
							if(obsMeasure){
								var measurements = obsMeasure.get('measurements');
								var measurement = measurements.at(0);
								var oldValue = measurement.get('value');
								if(iter == 0 && measurement.get('type') == "smm_CollectiveMeasurement"){
									measurement.set('value','');
								}
								measurement.calculate((iter < iterations - 1) ? true: null);
								var newValue = measurement.get('value');
								if(!oldValue || Math.abs(newValue - oldValue) > element.get('maxChange')){
									bigChanges = true;
								}
								console.log('mest:' + measurement.get('name') + " val:" + measurement.get('value'));							
							}						
						}
						if(!bigChanges && iter < iterations - 1){
							DataManager.getDataManager().set('suppressLogging',false);
							for(var k=0;k<path.length;k++){
								var childElement = path[k];
								var childElementContext = childElement.getElementContextInPath(context);
								var obsMeasure = childElementContext ? childElementContext.getObservedMeasureWithMeasurements(childElement.get('valueMeasurement'), timestamp ? true : false, timestamp) : null;
								if(obsMeasure){
									var measurements = obsMeasure.get('measurements');
									var measurement = measurements.at(0);	
									if(measurement){
										measurement.calculateParents();
									}
								}
							}
							cleanIterationData();
							break;
						}else{
							if(!bigChanges){
								console.log("Iteration exceeded for "+self.get("name"));
								cleanIterationData();
								break;
							}
						}
					}
					cleanIterationData();
					delete self._doingCalculation;
				}
				var triggerMeasurements = {};
				for(var j=0;j<paths.length;j++){
					var path = paths[j];
					for(var k=0;k<path.length;k++){
						var childElement = path[k];
						var childElementContext = childElement.getElementContextInPath(context);
						var obsMeasure = childElementContext ? childElementContext.getObservedMeasureWithMeasurements(childElement.get('valueMeasurement'), timestamp ? true : false, timestamp) : null;
						if(obsMeasure){
							var measurements = obsMeasure.get('measurements');
							var measurement = measurements.at(0);	
							if(measurement){
								if(!triggerMeasurements[measurement.id]){
									triggerMeasurements[measurement.id] = true;
									measurement.changed = {value: measurement.get('value')};
									measurement.trigger('change', measurement, {});
								}
							}
						}
					}
				}
			}else{
				self._doingCalculation = true;
				calculateNonIterativeValue();
				delete self._doingCalculation;
			}
		}else{
			self._doingCalculation = true;
			self.set('isIterative',false);
			calculateNonIterativeValue();
			delete self._doingCalculation;
		}
		
		function cleanIterationData(){
			if(DataManager.getDataManager().currentIteratedValues && DataManager.getDataManager().currentIteratedValues.owner === self){
				DataManager.getDataManager().currentIteratedValues = null;
			}
			DataManager.getDataManager().set('suppressLogging',false);
		}
		function calculateIterativeValueWitoutParent(){
		    var obsMeasure = context.getObservedMeasureWithMeasurements(self.get('valueMeasurement'), timestamp ? true : false, timestamp);
			if(obsMeasure){
				var measurement = obsMeasure.get('measurements').at(0);	
				measurement.calculate(true);
			}
		}		
		function calculateNonIterativeValue(){
		    var obsMeasure = context.getObservedMeasureWithMeasurements(self.get('valueMeasurement'), timestamp ? true : false, timestamp);
			if(obsMeasure){
				var measurement = obsMeasure.get('measurements').at(0);	
				measurement.calculate();
			}			
		}
	};
	
	getMeasurement(measuredChar, scenario,timestamp){
		var measurement = null;
		var self = this;
		var mCharacteristic = self.get(measuredChar);
		var observedMeasure = mCharacteristic ? scenario.getObservedMeasureWithMeasurements(mCharacteristic, timestamp ? true : false, timestamp) :null;
		/*if(!observedMeasure){
			var commonScenario = DataManager.getDataManager().get('currentCommonScenario');
			observedMeasure = commonScenario ? commonScenario.getObservedMeasureWithMeasurements(mCharacteristic, timestamp ? true : false, timestamp) : null;
			measurement = observedMeasure?observedMeasure.get('measurements').at(0):null;
		}*/
		measurement = observedMeasure?observedMeasure.get('measurements').at(0):null;
		return measurement;
	};

	onAggregatedFromAdded(model){
		if(Backbone.skipPersistance){
			return;
		}
		if(this.get('isIterative') === false){
			var cyclicPaths = this.getCyclicPath();
			if(cyclicPaths && cyclicPaths.length > 0){
				_.each(cyclicPaths,function(cyclicPath){
					_.each(cyclicPath,function(cycleElement){
						cycleElement.set('isIterative',true);
						cycleElement.set('maxChange',DataManager.getDataManager().get('currentPlan').get('maxChange'));
					});
					
				})
			}
		}
	};
	onAggregatedToAdded(){
		if(Backbone.skipPersistance){
			return;
		}
		if(this.get('isIterative') === false){
			var cyclicPaths = this.getCyclicPath();
			if(cyclicPaths && cyclicPaths.length > 0){
				_.each(cyclicPaths,function(cyclicPath){
					_.each(cyclicPath,function(cycleElement){
						cycleElement.set('isIterative',true);
						cycleElement.set('maxChange',DataManager.getDataManager().get('currentPlan').get('maxChange'));
					});
				});
			}
		}
	};	
	onAggregatedFromRemoved(model){
		var self = this;
		if(Backbone.skipPersistance){
			return;
		}
		if(this.get('isIterative') && model.get('isIterative')){
			var cyclicPaths = this.getCyclicPath();
			if(cyclicPaths && cyclicPaths.length > 0){
				_.each(cyclicPaths,function(cyclicPath){
					var inPath = false;
					_.each(cyclicPath,function(cycleElement){
						if(cycleElement === model){
							inPath = true;
						}
					});
					if(inPath){
						_.each(cyclicPath,function(cycleElement){
							if(cycleElement === self && cyclicPaths.length > 1){
								return;
							}
							if(cycleElement.getCyclicPath().length == 0){
								cycleElement.set('isIterative',false);
								cycleElement.set('maxChange',null);
							}
						});
					}
				});				
			}
		}
	};
	onAggregatedToRemoved(model){
		var self  = this;
		if(Backbone.skipPersistance){
			return;
		}
		if(this.get('isIterative') && model.get('isIterative')){
			var cyclicPaths = model.getCyclicPath();
			if(cyclicPaths && cyclicPaths.length > 0){
				_.each(cyclicPaths,function(cyclicPath){
					var inPath = false;
					_.each(cyclicPath,function(cycleElement){
						if(cycleElement === model){
							inPath = true;
						}
					});
					if(inPath){
						_.each(cyclicPath,function(cycleElement){
							if(cycleElement === self && cyclicPaths.length > 1){
								return;
							}
							if(cycleElement.getCyclicPath().length == 0){
								cycleElement.set('isIterative',false);
								cycleElement.set('maxChange',null);
							}
						});
					}
				});
			}
		}
	};
	
	/*
	fetchValueSourceType(){
		var self = this;
		if(self.get('type')!=="vdml_ValueAdd") {
			var valueOwner = self.get('componentOwner');
			var valueType = valueOwner.get('type');
			valueType = valueType.substring(valueType.indexOf("_") + 1);
			var valueProvider = valueOwner.get('provider');
			if(valueProvider) {
				var bms = valueProvider.get('roleAssignment').at(0).get('participant').get('businessModel');
				var bmVfFound = false;
				for(var i=0; bms && i<bms.length;i++){
					var bmVf = bms.at(i).get('bmValueFormula').findWhere({'valueFormula':valueOwner});
					if(bmVf) {
						bmVfFound = true;
						valueType = 'valueFormula';
						break;
					}
				}
				if(!bmVfFound) {
					valueType = 'valueProposition';
				}
			}
			else {
				valueType = 'Plan';
			}
		}
		else {
			valueType = 'Activity';
		}
		return valueType;
	};
	*/
	
	getPreviousValueUnit(alt,valueMeasurement,timestamp){
		var self = this;
    	var previousUnitName;
		var packageOriginalOwnerId = DataManager.getDataManager().getRepositoryId(self.getNestedParent().get('id'));
		var phase = alt.get('phaseAlternativeOwner');
		function fetchPreviousPhaseUnit(phase){
			var previousPhase = phase.get('previousPhase');
    		if(previousPhase) {
    			var prePrimary = previousPhase.get('primary');
    			var preScenario = prePrimary.getMainScenario(self.getNestedParent());
    			var measurement = preScenario ? preScenario.getObservedMeasureWithMeasurements(valueMeasurement, timestamp ? true : false, timestamp) : null;
    			if(measurement && measurement.get('measure') && measurement.get('measure').get('unit')){
    				previousUnitName = measurement.get('measure').get('unit').get('name');
    				return previousUnitName;
    			}
    			else {
    				if(packageOriginalOwnerId !== prePrimary.get('id')){
    					var preMaster = previousPhase.get('master');
    					if(preMaster !== prePrimary){
    						var preMasterScenario = preMaster.getMainScenario(self.getNestedParent());
    						var preMasterMeasurement = preMasterScenario.getObservedMeasureWithMeasurements(valueMeasurement, timestamp ? true : false, timestamp);
			    			if(preMasterMeasurement && preMasterMeasurement.get('measure') && preMasterMeasurement.get('measure').get('unit')){
			    				previousUnitName = preMasterMeasurement.get('measure').get('unit').get('name');
			    				return previousUnitName;
			    			}
    					}
    					else {
    						fetchPreviousPhaseUnit(previousPhase);
    					}
    				}
    				else {
    					return previousUnitName;
    				}
    			}
    		}
		}
		var masterPhase = phase.get('master');
		var valueParent = self.getNestedParent();
		if(alt !== masterPhase && masterPhase.get('phaseDesignPart').findWhere({'beepReference':valueParent.get('id')})){		
			var masterScenario = masterPhase.getMainScenario(valueParent);
			var masterMeasurement = masterScenario ? masterScenario.getObservedMeasureWithMeasurements(valueMeasurement, timestamp ? true : false, timestamp) : null;
			if(masterMeasurement && masterMeasurement.get('measure') && masterMeasurement.get('measure').get('unit')){
				previousUnitName = masterMeasurement.get('measure').get('unit').get('name');
				return previousUnitName;
			}
			else {
				fetchPreviousPhaseUnit(phase);
			}
		}
		else {
			fetchPreviousPhaseUnit(phase);
		}
		return previousUnitName;
    };
    
	copy(targetContainer,measurementsCopied,valuesCopied){
		var sourceContainer = this.get('componentOwner');
		if(!sourceContainer){
			sourceContainer = this.get('valueAddOwner');
		}
		var nameStr;
		var copyValueElement;
		var ownerRole;
		if(targetContainer.get('type') === 'vdml_ValueProposition'){
			ownerRole = 'component';
		}else{
			ownerRole = 'valueAdd';
		}
		if(sourceContainer === targetContainer){
			nameStr = targetContainer.getUniqueProperty('name','Copy of ' + this.get('name'),ownerRole);
		}else{
			nameStr = targetContainer.getUniqueProperty('name',this.get('name'),ownerRole);
		}
		var valEleId = DataManager.getDataManager().guidGeneratorByOwner(targetContainer);
		var args = {id:valEleId,name:nameStr,description:this.get('description')};
		args[ownerRole + 'Owner'] = targetContainer;
		
		copyValueElement = new this.constructor(args);
		copyValueElement.set('valueDefinition',this.get('valueDefinition'));
		var mesChars = this.get('measuredCharacteristic');
		mesChars.each(function(mesChar){
			mesChar.copy(copyValueElement,measurementsCopied);			
		});	
		var valueMeasurement = this.get('valueMeasurement');
		if(valueMeasurement){
			valueMeasurement.copy(copyValueElement,measurementsCopied);
		}
		var benchmark = this.get('benchmark',measurementsCopied);
		if(benchmark){
			benchmark.copy(copyValueElement);
		}
		var satisfactionLevel = this.get('satisfactionLevel',measurementsCopied);
		if(satisfactionLevel){
			satisfactionLevel.copy(copyValueElement);
		}
		var percentageWeight = this.get('percentageWeight',measurementsCopied);
		if(percentageWeight){
			percentageWeight.copy(copyValueElement);
		}
		if(valuesCopied){
			var copyObj = {'value':this,'valueCopy':copyValueElement};
			valuesCopied.push(copyObj);
		}
		return copyValueElement;
	};

	createValueAggregations(valueCopy,copiedValues){
		this.get('aggregatedFrom').each(function(aggregatedFrom){
			for(var i=0;i<copiedValues.length;i++){
				if(copiedValues[i].value === aggregatedFrom){
					valueCopy.get('aggregatedFrom').add(copiedValues[i].valueCopy);
				}else{
					valueCopy.get('aggregatedFrom').add(aggregatedFrom);
				}
			}
		});		
	};
	
	checkOVS(){
		var self = this;
		var isOVS = false;
		var vp = self.get('componentOwner');
		if(vp){
			var ovs = vp.get('overAllSatisfaction');
			if(ovs){
				if(ovs.get('id') === self.get('id')){
					isOVS = true;
				}
			}
		}
		return isOVS;
	}
	
	incrementallyUpdate(obsMes,copiedObsMes,newMeasures,callback){
		var self = this;
		var mc = self.get('valueMeasurement');
		var oldScenario = obsMes.get('observation').getContext();
		var timestamp = obsMes.get('observation').get('whenObserved');
		var valueMasurementObsMes = oldScenario.getObservedMeasureWithMeasurements(mc, timestamp ? true : false, timestamp);
		var oldValueMeasure = valueMasurementObsMes ? valueMasurementObsMes.get('measure'):null;
		var newScenario = copiedObsMes.get('observation').getContext();
		var viewAlt = newScenario.getAlternative();
		var isDimensional = oldValueMeasure ? (oldValueMeasure.get('type') === 'smm_GradeMeasure' ? false : true) : false;
		
		var oldValue = valueMasurementObsMes ? valueMasurementObsMes.get('measurements').at(0).get('value') : null;
		var expression = valueMasurementObsMes? valueMasurementObsMes.get('measurements').at(0).get('expression'): null;
		
		var intervalTuples = [];
		function constructSatisfactionIntervalTuple(type,originalId,intervalName,intervalFrom,intervalTo, minOpen, maxOpen, smiley, smileyColor, correctiveActions){
       		if(type === "smm_GradeMeasure"){
                var obj = {};
                obj.new = {id:originalId,symbol:intervalName,minimumEndPoint:intervalFrom,maximumEndPoint:intervalTo,maximumOpen:maxOpen,minimumOpen:minOpen,smiley:smiley,smileyColor:smileyColor,correctiveActions:correctiveActions};
                if(originalId !== ""){
                	obj.old= {id:originalId,symbol:intervalName,minimumEndPoint:intervalFrom,maximumEndPoint:intervalTo,maximumOpen:maxOpen,minimumOpen:minOpen,smiley:smiley,smileyColor:smileyColor,correctiveActions:correctiveActions};
                }
                intervalTuples.push(obj);
            }
            else{ //Ranking Measure
                var obj = {};
                obj.new = {id:originalId,value:intervalName,minimumEndPoint:intervalFrom,maximumEndPoint:intervalTo,maximumOpen:maxOpen,minimumOpen:minOpen,smiley:smiley,smileyColor:smileyColor,correctiveActions:correctiveActions};
                if(originalId !== ""){
	                obj.old= {id:originalId,value:intervalName,minimumEndPoint:intervalFrom,maximumEndPoint:intervalTo,maximumOpen:maxOpen,minimumOpen:minOpen,smiley:smiley,smileyColor:smileyColor,correctiveActions:correctiveActions};
                }
                intervalTuples.push(obj);
            }
       }		
		var weightobservedMeasure = oldScenario ? oldScenario.getObservedMeasureWithMeasurements(self.get('percentageWeight'), timestamp ? true : false, timestamp) : null;
		var receipientobservedMeasure = oldScenario ? oldScenario.getObservedMeasureWithMeasurements(self.get('recipientOpinion'), timestamp ? true : false, timestamp) : null;
        var receipientMeasurement = receipientobservedMeasure?receipientobservedMeasure.get('measurements').at(0):null;
        var weightMeasurement = weightobservedMeasure?weightobservedMeasure.get('measurements').at(0):null;
        var satobservedMeasure = oldScenario ? oldScenario.getObservedMeasureWithMeasurements(self.get('satisfactionLevel'), timestamp ? true : false, timestamp) : null;
		
		var satisfactionType;
		var satValue;
		var satUnitObj = {unitName:'', roundingDigits:4, significantDecimals:2};
		if(satobservedMeasure){
			satisfactionType = satobservedMeasure.get('measure').get('type');
			satobservedMeasure.get('measure').get('interval').each(function(interval){
                var name = interval.get('symbol')?interval.get('symbol'):interval.get('value');
                constructSatisfactionIntervalTuple(satisfactionType, interval.get('id'), name, interval.get('minimumEndPoint'), interval.get('maximumEndPoint'),interval.get('minimumOpen'),interval.get('maximumOpen'),interval.get('smiley'),interval.get('smileyColor'),interval.get('correctiveActions'));
            }); 
			if(satisfactionType === "smm_RankingMeasure"){
				var satunit = satobservedMeasure.get('measure').get('unit');
				if(satunit){
					satUnitObj = {unitName:satunit.get('name'), roundingDigits:satunit.get('roundingDigits'), significantDecimals:satunit.get('significantDecimals')};
				}
				satValue = satobservedMeasure.get('measurements').at(0).get('value');
			}else {
				satValue = satobservedMeasure.get('measurements').at(0).get('symbol');
			}
		}
		var recipientValue = receipientMeasurement?receipientMeasurement.get('value'):null;
		var acc = valueMasurementObsMes ? valueMasurementObsMes.get('measure').get('accumulator') : null;
		var weight = weightMeasurement?weightMeasurement.get('value'):null;
		
			
		if(self.checkOVS()){
			mc = self.get('satisfactionLevel');
		}
		var oldTupleSet = self.constructBaseMeasureTupleSet(mc,oldScenario,null,true);
		var newTupleSet = self.constructBaseMeasureTupleSet(mc,copiedObsMes.get('observation').getContext(),null, true);
		
		var maxChange = self.get('maxChange') != null ?  self.get('maxChange'): DataManager.getDataManager().get('currentPlan').get('maxChange');
		function updateNewTupleSet(oldTupleSet,newTupleSet,viewAlt){
			var updatedTupleSet = [];
			var handledComponents = [];
			var handleBaseMeasureRel = [];
			var deleteTuples = [];
			for(var i=0;i<oldTupleSet.length;i++){
				var oldTupleComp = oldTupleSet[i].old.component;
				if(!oldTupleSet[i].new){
					continue;
				}
				var foundTuple = false;
				var newTupleSetLength = newTupleSet.length;
				while(newTupleSetLength--){
					var foundOtherTuple = false;
					var newTupleComp = newTupleSet[newTupleSetLength].old.component;
					if(oldTupleComp === newTupleComp){
						if(newTupleSet[newTupleSetLength].old.baseMeasureRel === oldTupleSet[i].old.baseMeasureRel || self.compareBaseMeasureRelationShipTuple(newTupleSet[newTupleSetLength].old.baseMeasureRel,oldTupleSet[i].old.baseMeasureRel)){
							foundTuple = true;
							delete oldTupleSet[i].old;
							if(oldTupleSet[i].new.measure && newMeasures[oldTupleSet[i].new.measure.id]){
								oldTupleSet[i].new.measure = newMeasures[oldTupleSet[i].new.measure.id];
							}
							oldTupleSet[i].old = newTupleSet[newTupleSetLength].old;
							if(oldTupleSet[i].new.usePreviousAlternative){
								var mc = oldTupleSet[i].new.measuredCharacteristic;
								if(viewAlt.get('phaseAlternativeOwner').get('master') !== viewAlt) {
									var prevAlt = viewAlt.getPreviousPhasePrimary();
									var prevAltComp = prevAlt.getModelLinkedSync(oldTupleSet[i].new.component);
			        				var preAltScenario = prevAlt.getMainScenario(prevAltComp.getNestedParent());
			        				var prevObsMes = preAltScenario.getObservedMeasureWithMeasurements(prevAltComp.get('valueMeasurement'), timestamp ? true : false, timestamp);
								}else {
								    var prevObsMes = oldScenario.getObservedMeasureWithMeasurements(mc, timestamp ? true : false, timestamp);
								}
								oldTupleSet[i].new.measure = prevObsMes.get('measure');
							}						
							updatedTupleSet.push(oldTupleSet[i]);
							handledComponents.push(newTupleComp);
							handleBaseMeasureRel.push(newTupleSet[newTupleSetLength].old.baseMeasureRel);
							newTupleSet.splice(newTupleSetLength,1);
							break;
						}else {
							for(var k=0;k<newTupleSet.length;k++){
								if(newTupleSet[k] != newTupleSet[newTupleSetLength] && newTupleSet[k].old.component === newTupleComp && (newTupleSet[k].old.baseMeasureRel === oldTupleSet[i].old.baseMeasureRel) || self.compareBaseMeasureRelationShipTuple(newTupleSet[k].old.baseMeasureRel,oldTupleSet[i].old.baseMeasureRel)){
									foundOtherTuple = true;
									break;
								}
							}
							if(foundOtherTuple){
								continue;
							}
							foundTuple = true;
							delete oldTupleSet[i].old;
							if(oldTupleSet[i].new.measure && newMeasures[oldTupleSet[i].new.measure.id]){
								oldTupleSet[i].new.measure = newMeasures[oldTupleSet[i].new.measure.id];
							}
							oldTupleSet[i].old = newTupleSet[newTupleSetLength].old;
							if(oldTupleSet[i].new.usePreviousAlternative){
								var mc = oldTupleSet[i].new.measuredCharacteristic;
								if(viewAlt.get('phaseAlternativeOwner').get('master') !== viewAlt) {
									var prevAlt = viewAlt.getPreviousPhasePrimary();
									var prevAltComp = prevAlt.getModelLinkedSync(oldTupleSet[i].new.component);
			        				var preAltScenario = prevAlt.getMainScenario(prevAltComp.getNestedParent());
			        				var prevObsMes = preAltScenario.getObservedMeasureWithMeasurements(prevAltComp.get('valueMeasurement'), timestamp ? true : false, timestamp);
								}else {
								    var prevObsMes = oldScenario.getObservedMeasureWithMeasurements(mc, timestamp ? true : false, timestamp);
								}
								oldTupleSet[i].new.measure = prevObsMes.get('measure');
							}						
							updatedTupleSet.push(oldTupleSet[i]);
							handledComponents.push(newTupleComp);
							handleBaseMeasureRel.push(newTupleSet[newTupleSetLength].old.baseMeasureRel);
							newTupleSet.splice(newTupleSetLength,1);
							break;
						}
					}
				}
				if(!foundTuple && !foundOtherTuple){
					delete oldTupleSet[i].old;
					if(oldTupleSet[i].new.usePreviousAlternative){
						var mc = oldTupleSet[i].new.measuredCharacteristic;
						var prevObsMes = oldScenario.getObservedMeasureWithMeasurements(mc, timestamp ? true : false, timestamp);
						oldTupleSet[i].new.measure = prevObsMes?prevObsMes.get('measure'):null;
					}
					updatedTupleSet.push(oldTupleSet[i]);
				}
			}
			for(var j=0;j<newTupleSet.length;j++){
				var newTupleComp = newTupleSet[j].old.component;
				//if(_.indexOf(handledComponents,newTupleComp) < 0 && _.indexOf(handleBaseMeasureRel,newTupleSet[j].old.baseMeasureRel) < 0){
					delete newTupleSet[j].new;
					updatedTupleSet.push(newTupleSet[j]);
				//}
			}
			return updatedTupleSet;
		}	
		var updatedTupleSet = updateNewTupleSet(oldTupleSet,newTupleSet,viewAlt);
		var unitObj;
		var valueFormula = '';
		if(oldValueMeasure && oldValueMeasure.get('unit')){
			var unit = oldValueMeasure.get('unit');
			unitObj = {unitName: unit.get('name'), roundingDigits:unit.get('roundingDigits'), significantDecimals:unit.get('significantDecimals')};
		}
        valueFormula = obsMes.get('valueFormula') ? obsMes.get('valueFormula'):mc.get('valueFormula');
		
		if(self.get('type') === 'vdml_ValuePropositionComponent'){
			self.update(self.get('name'),oldValue,unitObj,valueFormula,satisfactionType,isDimensional,satValue,satUnitObj, weight, recipientValue, acc,updatedTupleSet,intervalTuples,null,viewAlt,maxChange,null,expression,callback);
		}else{
			self.update(self.get('name'),oldValue,unitObj,valueFormula,acc,updatedTupleSet,null,viewAlt,maxChange,null,expression,callback);
		}
	};
	
	reCalculateValues(sourceAggArray,currentScenario,saveCallback){
		var self = this;
		var currentAlternativeId = DataManager.getDataManager().getRepositoryId(self.get('id'));
		DataManager.getDataManager().getAlternative(currentAlternativeId,function(currentAlternativeModal){
			var baseTuples = [];
			var intervalTuples = [];
			function constructBaseTuple(originalId,component,measure){
				var obj = {};
				var deleteTuple = false;
				for(var i=0;i<sourceAggArray.length;i++){
					if(sourceAggArray[i] === component){
						deleteTuple = true;
						break;
					}
				}
				if(!deleteTuple){
					obj.new = {id:originalId,component:component,measure:measure};
				}
	            obj.old = {id:originalId,component:component,measure:measure};
	            baseTuples.push(obj);
			}
			function constructSatisfactionIntervalTuple(type,originalId,intervalName,intervalFrom,intervalTo, minOpen, maxOpen){
	       		if(type === "smm_GradeMeasure"){
	                var obj = {};
	                obj.new = {id:originalId,symbol:intervalName,minimumEndPoint:intervalFrom,maximumEndPoint:intervalTo,maximumOpen:maxOpen,minimumOpen:minOpen};
	                if(originalId !== ""){
	                	obj.old= {id:originalId,symbol:intervalName,minimumEndPoint:intervalFrom,maximumEndPoint:intervalTo,maximumOpen:maxOpen,minimumOpen:minOpen};
	                }
	                intervalTuples.push(obj);
	            }
	            else{ //Ranking Measure
	                var obj = {};
	                obj.new = {id:originalId,value:intervalName,minimumEndPoint:intervalFrom,maximumEndPoint:intervalTo,maximumOpen:maxOpen,minimumOpen:minOpen};
	                if(originalId !== ""){
		                obj.old= {id:originalId,value:intervalName,minimumEndPoint:intervalFrom,maximumEndPoint:intervalTo,maximumOpen:maxOpen,minimumOpen:minOpen};
	                }
	                intervalTuples.push(obj);
	            }
	       }
			var scenario = currentScenario?currentScenario:currentAlternativeModal.getMainScenario(self.getNestedParent());
			var observedMeasure = scenario?scenario.getObservedMeasureWithMeasurements(self.get('valueMeasurement'), null):null;
	        var satobservedMeasure = scenario?scenario.getObservedMeasureWithMeasurements(self.get('satisfactionLevel'), null):null;
	        var weightobservedMeasure = scenario?scenario.getObservedMeasureWithMeasurements(self.get('percentageWeight'), null):null;
	        var receipientobservedMeasure = scenario?scenario.getObservedMeasureWithMeasurements(self.get('recipientOpinion'), null):null;
	        var receipientMeasurement = receipientobservedMeasure?receipientobservedMeasure.get('measurements').at(0):null;
	        var weightMeasurement = weightobservedMeasure?weightobservedMeasure.get('measurements').at(0):null;
			if(observedMeasure){
				var mests = observedMeasure.get('measurements');
				if(mests.length>0){
					var valMeasurement = mests.at(0);
					var sourcecomponents = self.get('aggregatedFrom');
					sourcecomponents.each(function(srccomponent){
						var componentAdded = false;
						if(valMeasurement && valMeasurement.get('baseMeasurementTo') && valMeasurement.get('baseMeasurementTo').length > 0) {
	            			valMeasurement.get('baseMeasurementTo').each(function(baseNMeasurement){
	                            if(baseNMeasurement.get('to').get('measuredCharacteristic') === srccomponent.get('valueMeasurement')){
	                                var baseNMeasureRelationShip = valMeasurement.getMeasureRelationForMeasurementRelation(baseNMeasurement);
	                                if(baseNMeasureRelationShip) {
	                                    constructBaseTuple(baseNMeasurement.id, srccomponent, baseNMeasureRelationShip.get('to'));
	                                    componentAdded = true;
	                                }
	                            }
	                        }); 
	                     }else {
	                     	var srcScenario = currentAlternativeModal.getMainScenario(srccomponent.getNestedParent());
	                     	var obsMeasure = srcScenario.getObservedMeasureWithMeasurements(srccomponent.get('valueMeasurement'), null);
	        				var measure = obsMeasure?obsMeasure.get('measure'):null;
	                     	constructBaseTuple(srccomponent.get('id'), srccomponent, measure);
	                     	componentAdded = true;
	                     }
	                    if(!componentAdded) {
	                    	var srcScenario = currentAlternativeModal.getMainScenario(srccomponent.getNestedParent());
	                     	var obsMeasure = srcScenario.getObservedMeasureWithMeasurements(srccomponent.get('valueMeasurement'), null);
	        				var measure = obsMeasure?obsMeasure.get('measure'):null;
	                     	constructBaseTuple(srccomponent.get('id'), srccomponent, measure);
	                    	componentAdded = true;
	                    }	
					});
					var satisfactionType;
					var satValue;
					var satUnitObj = {unitName:'', roundingDigits:4, significantDecimals:2};
					if(satobservedMeasure){
						satisfactionType = satobservedMeasure.get('measure').get('type');
						satobservedMeasure.get('measure').get('interval').each(function(interval){
	                        var name = interval.get('symbol')?interval.get('symbol'):interval.get('value');
	                        constructSatisfactionIntervalTuple(satisfactionType, interval.get('id'), name, interval.get('minimumEndPoint'), interval.get('maximumEndPoint'),interval.get('minimumOpen'),interval.get('maximumOpen'));
	                    }); 
						if(satisfactionType === "smm_RankingMeasure"){
							var satunit = satobservedMeasure.get('measure').get('unit');
							if(satunit){
								satUnitObj = {unitName:satunit.get('name'), roundingDigits:satunit.get('roundingDigits'), significantDecimals:satunit.get('significantDecimals')};
							}
							satValue = satobservedMeasure.get('measurements').at(0).get('value');
						}else {
							satValue = satobservedMeasure.get('measurements').at(0).get('symbol');
						}
					}
					var acc = observedMeasure.get('measure').get('accumulator');
                    var formula = observedMeasure.get('valueFormula')?observedMeasure.get('valueFormula'):self.get('valueMeasurement').get('valueFormula');
					var weight = weightMeasurement?weightMeasurement.get('value'):null;
					var recipientValue = receipientMeasurement?receipientMeasurement.get('value'):null;
					var valueUnit = observedMeasure.get('measure').get('unit');
					var unitJson = {unitName:'', roundingDigits:4, significantDecimals:2};
					var expression = valMeasurement.get('expression');
					if(valueUnit) {
						unitJson = {unitName:valueUnit.get('name'), roundingDigits:valueUnit.get('roundingDigits'), significantDecimals:valueUnit.get('significantDecimals')};
					}
					var maxChange = self.get('maxChange') != null ?  self.get('maxChange'): DataManager.getDataManager().get('currentPlan').get('maxChange');
					self.addBaseTuple(baseTuples);
					if(self.get('type')==="vdml_ValuePropositionComponent"){
						self.update(self.get('name'),valMeasurement.get('value'),unitJson,formula,satisfactionType,true,satValue,satUnitObj,weight, recipientValue, acc,baseTuples,intervalTuples,null,currentAlternativeModal,maxChange,self.model,expression,saveCallback);
					}else if(self.get('type')==="vdml_ValueAdd"){
	        			self.update(self.get('name'),valMeasurement.get('value'),unitJson,formula,acc,baseTuples,null,currentAlternativeModal,maxChange,self.model,expression,saveCallback);
	        		}
				}
			}else{
				for(var i=0;i<sourceAggArray.length;i++){
					sourceAggArray[i].get('aggregatedTo').remove(self);
					self.get('aggregatedFrom').remove(sourceAggArray[i]);
				}
				saveCallback();
			}
		});
	}
//#endCustomMethods

    static getDialogViewProperties(type) {
        if (type && type === "AggregationView") {
            return {
                templatePath: "views/vdml/views/properties/ValueAggregationPropertiesTemplate.html",
                templateName: "ValueAggregationPropertiesTemplate",
                viewTypeStr: "appviews/vdml/views/properties/ValueAggregationViewModel",
                tabId: "ValueAggregationView",
                tabName: "ValueAggregation"
            }
        }
    }

	getCurrentAltModel(){
		var altId = DataManager.getDataManager().get('viewAlternative');
		var altModel = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({id:altId});
		return altModel;
	}
	changeValFormula(model,previousName){
		var self = this;
	}
	
	
	
	}
	path.ValueElementMixin = ValueElementMixin;
	//return ValueElementMixin;
//});
