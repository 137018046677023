import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { SelectChartValuesViewModel } from './SelectChartValuesViewModel'

/*define(["require", "jquery", "enhsplitter", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "app/global", "typeahead", "appviews/dashboard/views/chart/PresentationViewModel", "appbo/vdml/BusinessModel","appbo/dashboard/Presentation", "appviews/vdml/views/chart/BMValuesChart", "appbo/dashboard/DashboardMixin"],
function (require, $, enhsplitter, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, global, typeahead, PresentationViewModel, BusinessModel, Presentation, BMValuesChart, DashboardMixin)
{*/
var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart", global.version);
export class SelectChartValues2ViewModel extends SelectChartValuesViewModel {
	
	
	fetchPlanDirectforAlt(plan,alt){
		var self = this;
		var values = [];
		var planCriterionSet = plan?plan.get('planCriterionSet'):null;
		var planComponents = planCriterionSet?planCriterionSet.get('component').models:null;
		for(var i=0;i<planComponents.length;i++){
			var value = planComponents[i];
				var valId = value.get('id');
				var suffixId = window.utils.getSuffix(valId);
				var obj, found = _.find(self.parentView.selectedValuesArray, function(e){ return e.suffixId === suffixId;});
				if(!found){
					found = _.find(self.allValues, function(e){ return (e.suffixId === suffixId && e.checked == true);});
				}
				var name = value.get('name');
				var fullPath = self.getFullPath(value);
				var altName = alt.get('name');
				var phaseName = alt.getParent().get('name');
				if(found){
					var groupLabel = found.groupLabel? found.groupLabel : "";
					var label = found.label? found.label : "";
					obj = {id:valId,name:name, escapeId:self.htmlEscape(valId), suffixId:suffixId, checked:true, displayName:name, path:fullPath, label:label, groupLabel:groupLabel, altName: altName, phaseName: phaseName, type:'Plan', planName: plan.get('name')};
				}else{
					obj = {id:valId,name:name, escapeId:self.htmlEscape(valId), suffixId:suffixId, checked:false, displayName:name, path:fullPath, altName:altName, phaseName:phaseName, type: 'Plan', planName: plan.get('name')};
				}
				values.push(obj);
				self.valueBBModels.push(value);
		}
		return values;
	};
	fillBMValues(bmArray, alt, valueType){
		var self = this;
		var bm, values = [];
		_.each(bmArray, function(bmId){
			bm = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({id:bmId});
			var bmValues = [];
			bm.getValues(bmValues,true);
			for(var i=0; i< bmValues.length; i++){
				if(valueType && window.utils.getSourceType(bmValues[i]) !== valueType){
					continue;
				}
				var valId = bmValues[i].get('id');
				var suffixId = window.utils.getSuffix(valId);
				var obj, found = _.find(self.parentView.selectedValuesArray, function(e){ return e.suffixId === suffixId;});
				if(!found){
					found = _.find(self.allValues, function(e){ return (e.suffixId === suffixId && e.checked == true);});
				}
				var name = bmValues[i].get('name');
				var fullPath = self.getFullPath(bmValues[i]);
				var altName = alt.get('name');
				var phaseName = alt.getParent().get('name');
				if(found){
					var groupLabel = found.groupLabel? found.groupLabel : "";
					var label = found.label? found.label : "";
					obj = {id:valId,name:name, escapeId:self.htmlEscape(valId), suffixId:suffixId, checked:true, displayName:name, path:fullPath, label:label, groupLabel:groupLabel, altName:altName, phaseName:phaseName, type:'BMValue'};
				}else{
					obj = {id:valId,name:name, escapeId:self.htmlEscape(valId), suffixId:suffixId, checked:false, displayName:name, path:fullPath, altName:altName, phaseName:phaseName, type:'BMValue'};
				}
				values.push(obj);
				self.valueBBModels.push(bmValues[i]);
			}
		});
		return values;
	};

	saveSelection(){
		var self = this;
		var selectedValues = _.filter(self.allValues, function(e){ return e.checked === true;});
		if(self.options.addOptions.settings){
			self.options.callback(this,self.encodeId,selectedValues);	
		}else {
			self.parentView.selectedValuesArray.length = 0;
			self.parentView.selectedValuesArray = self.parentView.selectedValuesArray.concat(selectedValues);
			self.parentView.refreshValueTable();
			self.cleanModal();
		}
	};
	
		
	init(model, options){
		var self = this;
		super.init(model,options);
	}
	
	static getInstance = function(model,options){
        var view = new SelectChartValues2ViewModel(model, options);
        view.init(model, options);
        return view;
	};
}
path.SelectChartValues2ViewModel = SelectChartValues2ViewModel;