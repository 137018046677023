import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import * as async from 'async'
import * as jszip from '../../../../../../libs/jszip/jszip.min'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { OptimizationType } from '../../../../bo/vdml/OptimizationType'
import { ValueType } from '../../../../bo/vdml/ValueType'
// import {PhaseObjectiveSet} from '../../../../../version1/bo/transformation/PhaseObjectiveSet'
// import {Alternative} from '../../../../../version1/bo/transformation/Alternative'
// import {CodeContainer} from '../../../../../version1/bo/beeppackage/CodeContainer'
// import {Observation} from '../../../../../version1/bo/smm/Observation'
// import {Scenario} from '../../../../../version1/bo/vdml/Scenario'
// import {MeasuredCharacteristic} from '../../../../../version1/bo/vdml/MeasuredCharacteristic'
// import {BeepVocabulary} from '../../../../../version1/bo/concept/BeepVocabulary'
/*
define(["require", "jquery", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "jszip", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/concept/BeepVocabulary", "appbo/transformation/PhaseObjectiveSet", "appbo/vdml/Scenario", "appbo/vdml/MeasuredCharacteristic", "appbo/smm/Observation", "appbo/transformation/Phase", "appbo/transformation/Alternative", "appbo/beeppackage/CodeContainer"],
    function (require, $, _, Backbone, ko, koMapping, kb, bootbox, async, jszip, DataManager, global, BeepVocabulary, PhaseObjectiveSet, Scenario, MeasuredCharacteristic, Observation, Phase, Alternative, CodeContainer)
{*/
	var path = DataManager.getDataManager().buildAppNsPath("transformation.views.properties",global.version);
export class  AdvancedPlanDetails2ViewModel {


    /*submitToIp() {
        var self = this;
        var dataManager = DataManager.getDataManager();
        var localmsg = dataManager.get('localeManager').get('submitToIp');
        if(self.publishedDate()){
            localmsg = dataManager.get('localeManager').get('reSubmitToIp');
        }
        bootbox.confirm(localmsg, function (val) {
            if (val) {
                window.utils.startSpinner('submitIP', "Submitting Plan... ");
                var update = false;
                if(self.publishedDate()){
                    update = true;
                }
                dataManager.submitToIp(self.model.id,self.model.get('type'),update,function(){
                    self.enableIPSubmit(false);
                    window.utils.stopSpinner('submitIP');
                });
            }
        });
    };*/

    /*createBackUp(){
        var self = this;
        var dataManager = DataManager.getDataManager();
        var planId = self.model.id;
        var now = new Date();
        var dateTime = now.getFullYear() + "-" +
               (now.getMonth() + 1) + "-" +
               now.getDate() + " " +
               now.getHours() + ":" +
               now.getMinutes();
        bootbox.prompt({
            title: "Version Label",
            //inputType: 'textarea',
            value: dateTime, 
            callback: function (versionLabel) {
                        var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
                        var packArtDoc = dataManager.get("artifactsDocuments")[planId];
                        var legalEntityId = dataManager.get("workspaceLegal")[currentWorkspaceId];
                        var existingPackArtId = packArtDoc ? packArtDoc.artifactId :planId ;
                        var url = '/vdmbee/workspace/backup/'+currentWorkspaceId+'/'+existingPackArtId+'?entityId=' + legalEntityId+'&versionlabel='+versionLabel;
                        dataManager.get('vmpServerService').postAllPromise(url).then(function(data) {
                            bootbox.alert("BackUp SucessFully done");
                        });
                    }
        });
    }*/
    /*getPlanVersions(previousDocumentVersion, callback){
        var self = this;
        var dataManager = DataManager.getDataManager();
        var planId = self.model.id;
        var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
        var packArtDoc = dataManager.get("artifactsDocuments")[planId];
        var legalEntityId = dataManager.get("workspaceLegal")[currentWorkspaceId];
        var existingPackArtId = packArtDoc ? packArtDoc.artifactId :planId ;
        var url = '/vdmbee/package/versions/'+currentWorkspaceId+'/'+existingPackArtId+'?entityId=' + legalEntityId;			
        dataManager.get('vmpServerService').getAllPromise(url).then(function(data) {
            self.packageVersionsList([]);
            if(data) {
                for(var i=0; i<data.length; i++){
                    self.packageVersionsList.push({name: data[i].versionLabel, id: data[i].id, documentId:data[i].documentId, version:data[i].version});
                    if(data[i].version == previousDocumentVersion){
                        self.selectedVersion(data[i].versionLabel);
                    }
                }
            }
            callback(data);
        });
    }*/
    /*showPlanVersions(){
        debugger
        var self = this;
        var dataManager = DataManager.getDataManager();
        var planId = self.model.id;
        var previousDocumentVersion = self.model.get("previousDocumentVersion");
        var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
        //var planDoc = dataManager.get("artifactsDocuments")[planId];
        //var artId = planDoc ? planDoc.artifactId : planId;
        self.getPlanVersions(previousDocumentVersion, function(){
            var htmlContent = "<div class=\"row\">";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "<div class=\"col-xs-3\">Current Version</div>";
            htmlContent = htmlContent + "<div class=\"col-xs-7\">";
            htmlContent = htmlContent + "<span>" + self.selectedVersion() + "</span>";
            htmlContent = htmlContent + "</div></div><br>";
            htmlContent = htmlContent + "<div class=\"row\">";
            htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
            htmlContent = htmlContent + "<div class=\"col-xs-3\">Select Version</div>";
            var htmlContent = htmlContent + "<div class=\"col-xs-7\"><select style = 'width:80px' id = 'planVersions'>";
            for(var i=0;i<self.packageVersionsList().length;i++){
                htmlContent += "<option name='version' value = \"" + self.packageVersionsList()[i].id + "\">"+ self.packageVersionsList()[i].name+ '</option>';
            }
            htmlContent = htmlContent + "</select></div>";
            htmlContent = htmlContent + "</div><br>";
            var box = bootbox.dialog({
                message: htmlContent,
                title: "<div><img class='smallIcon' src='img/icons/icons_16.png'>&nbsp;&nbsp;&nbsp;Select Plan version</div>",
                buttons: {
                    cancel: {
                        label: "Close",
                        className: "btn btn-default",
                        callback: function () {}
                    },
                    success: {
                        label: "Complete",
                        className: "switchPlan btn btn-complete",
                        callback: function () {
                            var docId;
                            var docVersion;
                            var selectedSwitchVersion = $('#planVersions').val();
                            for(var i=0; i<self.packageVersionsList().length; i++){
                                if(self.packageVersionsList()[i].id == selectedSwitchVersion){
                                    docId = self.packageVersionsList()[i].documentId;
                                    docVersion = self.packageVersionsList()[i].version;
                                    break;
                                }
                            }
                            if(docVersion == previousDocumentVersion){
                                bootbox.alert("You are already using that version");
                                //self.saveAdvancedDetails();
                                return;
                            }
                            window.utils.startSpinner('switchPlanVersionSpinner', "Switching Plan vesion...");
                            setTimeout(function () {
                                var url = '/vdmbee/artifact?docId='+docId+'&docVer='+docVersion+'&workspaceId='+currentWorkspaceId;
                                DataManager.getDataManager().get('vmpServerService').getAllPromise(url).then(function(res) {
                                    if(res && res.packageId){
                                        var packArtId = res.packageId;
                                        var currentWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
                                        var legalEntityId = dataManager.get("workspaceLegal")[currentWorkspaceId];
                                        var packArtDoc = dataManager.get("artifactsDocuments")[planId];
                                        var existingPackArtId = packArtDoc ? packArtDoc.artifactId :planId ;
                                        var url = '/vdmbee/workspace/switchplanversion/'+currentWorkspaceId+'/'+packArtId+'/'+existingPackArtId+'?entityId=' + legalEntityId;
                                        dataManager.get('vmpServerService').postAllPromise(url).then(function(data) {
                                            dataManager.applyWorkspaceById(currentWorkspaceId,function(){
                                                window.utils.stopSpinner("switchPlanVersionSpinner");
                                                self.saveAdvancedDetails();
                                                jQuery('.btn-editPlanClose').click();
                                                dataManager.applyWorkspaceById(currentWorkspaceId,function(){
                                                    window.vdmModelView.loadHomePagePlans(false,function(){
                                                            bootbox.alert('Package has been installed.');
                                                            window.utils.stopSpinner('switchPlanVersionSpinner');
                                                    });
                                                });
                                            });
                                        });
                                    } else {
                                        bootbox.alert("There are no such package");
                                    }	
                                });
                            }, 10);
                        }
                    }
                }
            });
            box.show();
        });
    }*/

    /*cleanModal() {
        var self = this;
        if (self.modalShown == 1 && self.model) {
            window.cleanDialogModel(self.encodeId, self);
        }
    };*/

    /*deleteScenario(view, event) {
        var self =this;
        if ($("#selectObs option:selected").length > 0) {
            var id = $("#selectObs option:selected").attr("value");
            if (id === self.appliedScenario()) {
                bootbox.alert('You cannot delete Applied Scenario');
            } else {
                bootbox.confirm('' + DataManager.getDataManager().get('localeManager').get('ScenarioDelete'), function (delScenario) {
                    if (delScenario) {
                        var id = $("#selectObs option:selected").attr("value");
                        for (var i = 0; i < self.timeStamps().length; i++) {
                            if (self.timeStamps()[i].id === id) {
                                self.timeStamps.destroy(self.timeStamps()[i]);
                                break;
                            }
                        }
                        if (window.utils.getObservableLength(self.timeStamps) == 1) {
                            self.scenarioSelected(false);
                        }
                    }
                });
            }
        }
    };
    */

    moveUp() {
        var self = this;
        var id = self.vocabularySelected();
        var index = self.linkedConceptPackageList().map(function (el) { return el.id; }).indexOf(id);
        if (index) {
            var spliced = self.linkedConceptPackageList.splice(index, 1)[0];
            self.linkedConceptPackageList.splice(index - 1, 0, spliced);
        }
    }
    moveDown() {
        var self = this;
        var id = self.vocabularySelected();
        var index = self.linkedConceptPackageList().map(function (el) { return el.id; }).indexOf(id);
        if (self.linkedConceptPackageList()[index + 1]) {
            var spliced = self.linkedConceptPackageList.splice(index, 1)[0];
            self.linkedConceptPackageList.splice(index + 1, 0, spliced);
        }
    }


    
    cleanUp() {
        this.conceptsPackageModel.length = 0;
        this.linkedConceptPackageList.removeAll();
    }
    
    showVocabDetails() {
        var self = this;
        var id = this.vocabularySelected();
        var index = self.linkedConceptPackageList().map(function (el) { return el.id; }).indexOf(id);
        var vocab = window.utils.getElementModel(self.linkedConceptPackageList()[index].id,['concept.Vocabulary']);
        var vocabName = vocab.get('name');
        var vocablocale = vocab.get('locale');
        var businessContext = vocab.get('businessContext');
        var vocabLib = vocab.get('vocabularyOwner').get('name');
        var vocabPackage = vocab.get('vocabularyOwner').get('vocabularyLibraryOwner').get('name');

        var msg = "<div>\
				<div class=\"row\">\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">Name</span></span></div>\
					<div class=\"col-xs-6\"><span> "+ vocabName + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

        var localeDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">Locale</span></span></div>\
					<div class=\"col-xs-6\"><span> "+ self.LANGUAGE_BY_LOCALE[vocablocale] + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

        var bcDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">Business Context</span></span></div>\
					<div class=\"col-xs-6\"><span>"+ businessContext + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

        var vocabLibDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">Vocabulary Library</span><span></div>\
					<div class=\"col-xs-6\"><span>"+ vocabLib + "</div><br>\
					<div class=\"col-xs-1\"></div>\
				</div>";

        var VocabPackageDiv = "<div class=\"row\">\
					<br>\
					<div class=\"col-xs-1\"></div>\
					<div class=\"col-xs-4\"><span class=\"control-label\"><span style=\"font-family: Roboto;\">Vocabulary Library Package</span><span></div>\
					<div class=\"col-xs-6\"><span>"+ vocabPackage + "</div><br>\
					<div class=\"col-xs-1\"></div><br>\
				</div>";

        if (vocablocale !== "") {
            msg = msg.concat(localeDiv);
        }
        if (businessContext !== "") {
            msg = msg.concat(bcDiv);
        }
        msg = msg.concat(vocabLibDiv).concat(VocabPackageDiv).concat("</div>");
        bootbox.dialog({
            message: msg,
            title: "Vocabulary Details",
            buttons: {
                main: {
                    label: "Close",
                    className: "btn btn-default",
                }
            }
        });
    }

    saveAdvancedView(planModel, callback) {
        var self = this;
        planModel.set('maxChange', self.maxchange());
        planModel.set('maxIterations', self.maxIterations());
        if(self.model && self.optimizationType() && self.agrigatedValue()) {
            self.defaultScenario.set('optimizationType', self.optimizationType());
            self.defaultScenario.set('optimizationPeriod', self.optimizationPeriod());
            var exp = self.defaultScenario.get("optimizationObjective");
            if(exp){
                exp.set("expressionStr",self.agrigatedValue());
            } else {
                exp = self.defaultScenario.createOptimizeExpression(self.agrigatedValue(), null);
            }
            self.defaultScenario.set("optimizationObjective",exp);
        } else if(self.model && !self.optimizationType() && !self.agrigatedValue()) {
            self.defaultScenario.set('optimizationType', null);
            self.defaultScenario.set('optimizationPeriod', null);
            self.defaultScenario.get("optimizationObjective")?.destroy();
        }
    };

    saveAdvancedDetails() {
        var self = this;
        return self.options.callback(this);
    };

    afterRenderView(node,view){
        var self = view;
        $("#maxChange").keyup(function (view) {
            var newValue = view.currentTarget.value;
            if (newValue == '') {
                self.enableComplete(false);
            } else if (newValue != '' && self.maxIterations() != '') {
                self.enableComplete(true);
            }
        });
        $("#maxIterations").keyup(function (view) {
            var newValue = view.currentTarget.value;
            if (newValue == '') {
                self.enableComplete(false);
            } else if (newValue != '' && self.maxChange() != '') {
                self.enableComplete(true);
            }
        });
        window.utils.applyFontSizeInDialog();
    };

    /* this.importMetricsCSV = function () {
         self.model.importMetricsCSV();
     };*/
    sortByName(left, right) {
        return left.name.toLowerCase() === right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
    }
    /*generateMetricsCSV() {
        var self = this;
        self.options.parentView.model.scenarioExportDialog([]);
    };*/
    /*updateView(view, encodeId) {
        $("#selectObs option:selected").text(view.obsName());
        window.cleanDialogModel(encodeId, view);
    };*/

    getAgregatedValues() {
        var self = this;
        const valueContexts = self.defaultScenario.getValuesContexts();
        let keys = Object.getOwnPropertyNames(valueContexts);
        let agriValueList = []
        for (let key in keys) {
            let altContext = valueContexts[key];
            altContext.valueElementContext.forEach(value => {
                let context = value.getValueContext(altContext.id);
                if(context){
                    const val = context.getParent();
                    agriValueList.push({id: val.id, name: val.get('name')})
                }
            });
        }
        return agriValueList;
    }
    init(model,options){
        var self = this;
        this.AdvancedPlanDetails2ViewModel = this;
        this.model = model;
        this.id = kb.observable(model, 'id');
        var dataManager = DataManager.getDataManager();
        this.defaultScenario = dataManager.get('defaultScenario');
        function htmlEscape(str) {
            return String(str)
                .replace(/@/g, '')
                .replace(/ /g, '')
                .replace(/#/g, '');
        }
        //this.encodeId = htmlEscape(this.id());
        this.encodeId = 'advanced';
        this.options = options;
        if (options && options.parentView) {
            //this.parentView = options.parentView;
            this.encodeId = "" + options.modalId;
            //this.parentModal = options.parentModal;
        }
        this.vocabularySelected = ko.observable(false);
        this.maxchange = ko.observable(self.model ? self.model.get('maxChange') : 0.0001);
        this.maxIterations = ko.observable(self.model ? self.model.get('maxIterations') : 100);
        this.LANGUAGE_BY_LOCALE = utils.languageList();
        this.enableComplete = ko.observable(true);
        this.showMarketSubmit = ko.observable(false);        
        this.linkedConceptPackageList = ko.observableArray([]);
        this.linkedModulesList = ko.observableArray([]);
        this.conceptsPackageModel = [];
        this.imageList = ko.observableArray();
        this.mapList = [];
        this.packageVersionsList = ko.observableArray([]);
		this.selectedVersion = ko.observable();
        this.optimizationPeriod = ko.observable();
        this.optimizationPeriodOptions = ko.observableArray([]);
        this.showOptimization = ko.observable(false);
        this.optimizationTypeOptions = OptimizationType.symbols();
        this.optimizationType = ko.observable((self.model && self.defaultScenario) ? self.defaultScenario.get('optimizationType') : null);
        this.agrigatedValueOptions = (self.model && self.defaultScenario) ? self.getAgregatedValues() : [];
        this.agrigatedValue = ko.observable((self.model && self.defaultScenario?.get('optimizationObjective')) ? self.defaultScenario?.get('optimizationObjective').get('expressionStr'): null);
        this.isOptimizationType = ko.observable(self.optimizationType() ? true : false);
        self.optimizationType.subscribe((val)=>{
            if(val === undefined || val === null){
                self.isOptimizationType(false);
                self.agrigatedValue(null);
            } else {
                self.isOptimizationType(true);
            }
        })
        if (self.model) {
            let stepObj = self.defaultScenario.get("firstStep");
            let periodsUntillCurPhase = 0;
            while (stepObj) {
                const noOfPeriods = stepObj.get('noPeriods');
                periodsUntillCurPhase = periodsUntillCurPhase + parseInt(noOfPeriods);
                stepObj = stepObj.get("next");
            }
            var periodArr = [];
            for(var i=1; i<=periodsUntillCurPhase; i++){
                periodArr.push({'name':i,'description':i});
            }
            self.optimizationPeriodOptions(periodArr);
            self.optimizationPeriod(self.defaultScenario.get('optimizationPeriod'));
            //var dataSets = self.defaultScenario.getPlanDatasets(self.model);
            function loadConceptPackages(conceptsPackages) {
                async.eachSeries(conceptsPackages.models, function (vocabulary, packageCallback) {
                    self.model.getVocabularyBeepPackageWithId(vocabulary.id, function (vocabularyModel) {
                        self.conceptsPackageModel.push(vocabularyModel);
                        packageCallback();
                    });
                }, function (err) {
                    for (var j = 0; j < self.conceptsPackageModel.length; j++) {
                        var vocabularyLibraries = self.conceptsPackageModel[j].get('vocabularyLibrary');
                        for (var k = 0; k < vocabularyLibraries.length; k++) {
                            var vocabularies = vocabularyLibraries.at(k).get('vocabulary');
                            for (var i = 0; i < vocabularies.length; i++) {
                                var locale = vocabularies.at(i).get('locale');
                                var localeString = "";
                                if (locale) {
                                    localeString = ' (' + self.LANGUAGE_BY_LOCALE[locale] + ')';
                                }
                                var conceptName = vocabularies.at(i).get('name') + localeString + ' (' + vocabularies.at(i).getParent().getParent().get('name') + ')';
                                self.linkedConceptPackageList.push({ id: vocabularies.at(i).get('id'), name: conceptName });
                            }
                        }
                    }
                });

            }
            function waitAndLoadRDFModel() {
                var planId = self.model.id.substr(self.model.id.lastIndexOf('@'));
                var commonAltId = planId + "-Common@";
                var commonRdfModel = DataManager.getDataManager().getRDFModel(commonAltId);
                if (commonRdfModel) {
                    commonRdfModel.getAllDocumentsCollectionOfType('concept_BeepVocabulary', loadConceptPackages, null, null, 'vbc:cmof_EObject-name', null, true);
                    commonRdfModel.getAllDocumentsCollectionOfType('beeppackage_BeepModule', function (refs) {
                        var modules = refs.models;
                        _.each(modules, function (module) {
                            self.linkedModulesList.push({ id: module.get('id'), name: module.get('name') });
                        });
                        self.linkedModulesList.sort(function (left, right) {
                            return left.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() == right.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ? 0 : (left.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() < right.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ? -1 : 1);
                        });
                        window.utils.stopSpinner('loadPackage');
                    }, false, null, 'vbc:cmof_EObject-name', null, true);
                } else {
                   //setTimeout(waitAndLoadRDFModel, 5);
                }
            }
            window.utils.startSpinner('loadPackage', 'Fetching Details...');
            window.setTimeout(function () {
                DataManager.getDataManager().set('currentPlan',self.model,{silent:true});
                var planId = self.model.id.substr(self.model.id.lastIndexOf('@'));
                var commonAltId = planId + "-Common@";
                DataManager.getDataManager().getModelIdInitializedWSData(commonAltId,function(){                    
                    model.fetchCustomPlanPackages(['concept_BeepVocabulary',], function(packagesList){
                        waitAndLoadRDFModel();
                    });
                });
                
            }, 100);
        }

        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), ['name'
            , 'description'
            , 'Close'
            , 'Maxchange'
            , 'Maxiterations'
            , 'Advanced'
            , 'Complete'
            , 'addAnother'
            , 'Delete'
            , 'ExportCSV'
            , 'Publish'
            , 'OptimizationType'
            , 'OptimizationPeriod'
            , 'value'
            , 'Maximize'
            , 'Minimize'
            , 'OptimizationObjective'
        ]);
        for (var i = 0; i < self.optimizationTypeOptions.length; i++) {
            self.optimizationTypeOptions[i].description =
                self.labels[self.optimizationTypeOptions[i].name]();
        }
    }


   static getInstance(model, options){
        var view = new AdvancedPlanDetails2ViewModel(model, options);
        view.init(model, options);
        return view;
   };
}
path.AdvancedPlanDetails2ViewModel = AdvancedPlanDetails2ViewModel;