/**
 * Wraps a token stream to provide navigation and position tracking capabilities.
 * This class is used to traverse tokens while maintaining cursor position information
 * for code completion and suggestion generation.
 */
class TokenStreamWrapper {
    /**
     * Creates a new TokenStreamWrapper instance.
     * 
     * @param {Object} tokens - The token stream object containing an array of tokens
     * @param {Array} tokens.tokens - Array of token objects, where each token has a type and text
     */
    constructor(tokens) {
        this.tokens = tokens;
        this.position = 0; // Current position in the token stream
    }

    /**
     * Checks if the current position in the token stream is at the caret (cursor) position.
     * A token type of -1 indicates the caret position in the stream.
     * 
     * @returns {boolean} True if the current token represents the caret position, false otherwise
     */
    atCaret() {
        return this.tokens.tokens[this.position]?.type === -1;
    }

    /**
     * Gets the next token in the stream without advancing the position.
     * Acts as a lookahead function to examine the upcoming token.
     * 
     * @returns {Object|null} The next token object if available, null if beyond stream length
     * @property {number} returns.type - The type of the token
     * @property {string} returns.text - The text content of the token
     */
    next() {
        if (this.position >= this.tokens.tokens.length) {
            return null;
        }
        return this.tokens.tokens[this.position];
    }

    /**
     * Advances the position in the token stream by one.
     * 
     * @returns {TokenStreamWrapper} The current instance for method chaining
     */
    move() {
        this.position++;
        return this;
    }

    /**
     * Moves the position in the token stream back by one.
     * 
     * @returns {TokenStreamWrapper} The current instance for method chaining
     */
    moveLeft() {
        this.position--;
        return this;
    }
}

export { TokenStreamWrapper };