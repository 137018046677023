import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import { StrategyMapDetailsViewModel } from './StrategyMapDetailsViewModel'
import { PeriodKind } from '../../../../bo/vdml/PeriodKind'
//import {PackageReference as PlanPackageReference} from '../../../../../version1/bo/transformation/PackageReference'

/*define(["require", "jquery", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "appbo/beeppackage/BeepPackageMixin", "appbo/transformation/PackageReference", "appcommon/com/vbee/filesystem/PackageReference","appbo/vdml/StrategyMapDiagram","appbo/ecomap/EcoMap","app/global"],
	function (require, $, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, BeepPackageMixin, PlanPackageReference, PackageReference, StrategyMapDiagram, EcoMap, global)
{*/
var path = DataManager.getDataManager().buildAppNsPath("strategyMap.views.designer", global.version);
export class StrategyMapDetails2ViewModel extends StrategyMapDetailsViewModel {
    getPeriodsByYear(year, plan) {
        var self = this;
        let selectedYear = year
        const periods = self.dataSets.filter(d => d.year === +selectedYear)
        const periodsByPeriodKind = periods.map(d => {
            return plan.getPeriodKinds(d.year, d.period, self.periodKind)
        });
        self.periodMonths(periodsByPeriodKind);
    };
    loadDatasets(period, year, callback) {
        var self = this;
        self.planScenario.getDatasetAsync(year, period, self.periodKind, null, function(periodDataset, calcPeriodDataset) {
            self.periodDataset = periodDataset;
            self.calcPeriodDataset = calcPeriodDataset;
            callback(periodDataset, calcPeriodDataset);
        });
    }

    refreshWidgetData(){
        var self = this;
        var isCompleteEnable = window.vdmModelView.enableComplete();
        window.utils.startSpinner('loadingValues', "loading Values...");
        window.setTimeout(function() {
            self.loadDatasets(self.selectedPeriod(), self.selectedYear(), function() {
                self.model.getNestedParent().refreshLabels(window.vdmModelView.modeler, self.fontsize(), function() {
                    if(!isCompleteEnable){
                        window.vdmModelView.enableComplete(false);
                    }
                    window.utils.stopSpinner('loadingValues');
                });
            });
        }, 100);
    }
    init(model, options) {
        super.init(model, options);
        var self = this;
        var dataManager = DataManager.getDataManager();
        this.periodYears = ko.observableArray();
        this.periodMonths = ko.observableArray();
        this.selectedYear = ko.observable();
        this.selectedPeriod = ko.observable();
        this.showPeriods = ko.observable(true)
        this.periodKind = dataManager.get('currentPlan').get("periodKind");
        var scenarioId = dataManager.get("currentPlan").get("defaultScenario");
        var currentAlternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({id: dataManager.get('viewAlternative')});
        this.planScenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
        var currentPlan = dataManager.get('currentPlan');
        this.dataSets = currentAlternative.getPhaseAlternativeSteps(self.planScenario);
        const filteredYears = this.dataSets.filter((item, index, self) =>
            index === self.findIndex((t) => t.year === item.year)
        );
        this.periodYears(filteredYears.map(d=>{return {text:d.year,value:d.year}}));
        self.selectedYear.subscribe(function(value) {
            if (value !== undefined) {
                self.getPeriodsByYear(value, currentPlan);
                self.defaultPeriod ? self.selectedPeriod(self.defaultPeriod) : self.selectedPeriod();
                self.defaultPeriod = undefined
            }
        });

        /*self.selectedPeriod.subscribe(function(value) {
            if (value !== undefined) {
                
            }
        });*/
        if (PeriodKind.symbols()[0].name === self.periodKind) {
            this.showPeriods(false)
        }
        var defaultPeriodList = self.planScenario.fillDefaultPeriods(this.dataSets, self.periodKind);
        self.defaultPeriod = defaultPeriodList?.period
        self.defaultYear = defaultPeriodList?.year;
        dataManager.set('currentPeriod', defaultPeriodList.period);
        self.selectedYear(defaultPeriodList.year);
        dataManager.set("currentYear", defaultPeriodList.year);
    }

    saveStrategyMapDetails(){
        var self = this;
        if(self.name() !== ""){
            if(self.name().trim() != self.model.get('name')){
                self.model.set('name',self.name().trim());
                window.setBreadCrumb(DataManager.getDataManager().get('viewPhase'),DataManager.getDataManager().get('viewAlternative'),self.StrategyMapDetailsViewModel);
            }
            self.model.set('description',self.description().trim());
            var defaultFontSize = self.model.get('fontsize') ? self.model.get('fontsize') : 12;
            if(self.fontsize() && defaultFontSize != self.fontsize()){
                self.model.set('fontsize',self.fontsize());
            }
            var triggerDiagChange = false;
            var dataManager = DataManager.getDataManager();
            if((self.fontsize() && defaultFontSize != self.fontsize()) || (self.selectedPeriod() != dataManager.get("currentPeriod") || self.selectedYear() != dataManager.get("currentYear"))){
                triggerDiagChange = true;
            }
            dataManager.set("currentYear", self.selectedYear());
            dataManager.set('currentPeriod', self.selectedPeriod());
            self.defaultPeriod = self.selectedPeriod();
            self.defaultYear = self.selectedYear();
            if(triggerDiagChange){
                self.refreshWidgetData();
            }
        }
    };

    static getInstance(model, options) {
        var view = new StrategyMapDetails2ViewModel();
        view.init(model, options);
        return view;
    };
}
path.StrategyMapDetails2ViewModel = StrategyMapDetails2ViewModel;