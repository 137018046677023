import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { BusinessModel } from '../../../../../version1/bo/vdml/BusinessModel'
import { DashboardMixin } from '../../../../../version1/bo/dashboard/DashboardMixin'
import { CodeContainer } from '../../../../../version1/bo/beeppackage/CodeContainer'
import { Presentation } from '../../../../../version1/bo/dashboard/Presentation'

/*define(["require", "jquery", "enhsplitter", "underscore", "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "async", "appcommon/com/vbee/data/DataManager", "app/global", "typeahead", "appviews/dashboard/views/chart/PresentationViewModel", "appbo/vdml/BusinessModel","appbo/dashboard/Presentation", "appviews/vdml/views/chart/BMValuesChart", "appbo/dashboard/DashboardMixin"],
function (require, $, enhsplitter, _, Backbone, ko, koMapping, kb, bootbox, async, DataManager, global, typeahead, PresentationViewModel, BusinessModel, Presentation, BMValuesChart, DashboardMixin)
{*/
var path = DataManager.getDataManager().buildAppNsPath("dashboard.views.chart", global.version);
export class SelectChartValuesViewModel {
	
	htmlEscape(str) {
		return String(str)
		.replace(/@/g, '')
		.replace(/ /g, '')
		.replace(/#/g, '');
	}
	sortByName(left, right) {
		return left.name.toLowerCase() === right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
	}
	cleanUp() {
		this.parentView = null;
		this.addOptions = null;
		this.options = null;
		$('.visual_search_addAlternative').detach();
	};
	saveFilterTags(query){
		var self = this;
		this.parentView.savedFilterTags = query;
	};
	fetchValuesFromAlts(){
		var self = this;
		self.packages = [];
		self.businessModels = [];
		self.altIds = [];
		var bmArray = [];
		self.valuesArray.removeAll();
		self.values.removeAll();
		var alts = [], phases = DataManager.getDataManager().get('currentPlan').get('phase');
		phases.each(function(phase){
			alts = alts.concat(phase.get('phaseAlternative').models);
		});
		_.each(alts, function(alt){
			self.altIds.push(alt.id);
			var packBBObj, phaseDesignPart = alt.get('phaseDesignPart');
			for(var i=0;i<phaseDesignPart.length;i++){
				if(phaseDesignPart.at(i).get('beepReference').indexOf("Common") == -1 && phaseDesignPart.at(i).get('beepType') !== 'report_BeepReport' && phaseDesignPart.at(i).get('beepType') !== 'ecomap_EcoMap' && phaseDesignPart.at(i).get('beepType') !== 'dashboard_Analytics'){
					self.packages.push({'id':phaseDesignPart.at(i).get('beepReference'), 'name':phaseDesignPart.at(i).get('name'), 'altName':alt.get('name'), 'altId':alt.id, 'phaseName': alt.get('phaseAlternativeOwner').get('name'), 'phaseId':  alt.get('phaseAlternativeOwner').id});
					packBBObj = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({id:phaseDesignPart.at(i).get('beepReference')});
					var bms = packBBObj.get('businessModel').models;
					_.each(bms, function(bm){
						bmArray.push(bm.id);
						self.businessModels.push({'id':bm.id, 'name':bm.get('name'), 'packName': packBBObj.get('name'), 'altName':alt.get('name'),'altId':alt.id, 'phaseName': alt.get('phaseAlternativeOwner').get('name'), 'phaseId':  alt.get('phaseAlternativeOwner').id});
					});
				}	
			}
		});
		//.fillValues(bmArray, self.altIds, null, null, true, null, true);// to fill all values initially
	};
	
	afterRenderView(){
		var self = this;
		var searchNode = $('#searchValues');
		var searchFieldNode = $("<div class=\"visual_search_addAlternative\"></div>");
		searchNode.append(searchFieldNode);
		setTimeout(function(){
			self.initializeSearch(null);
		}, 100);
		$('#editDetailsModalContent' + self.encodeId).resize(function () {
			$('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
		})
	};            
	cleanModal(){
		var self = this;
		self.parentView = null;
		self.addOptions = null;
		this.options = null;
		window.cleanDialogModel(self.encodeId,self);
	};
	fetchPlanDirectforAlt(plan,alt){
		var self = this;
		var values = [];
		var planCriterionSet = plan?plan.get('planCriterionSet'):null;
		var planComponents = planCriterionSet?planCriterionSet.get('component').models:null;
		for(var i=0;i<planComponents.length;i++){
			var value = planComponents[i];
			if(alt) {
				var altComp = alt.getModelLinkedSync(value);
				var preAltScenario = altComp?alt.getMainScenario(altComp.getNestedParent()):null;
				var previousMest = preAltScenario?preAltScenario.getTSLessMeasurementReferringToMeasuredCharteristic(altComp.get('valueMeasurement')):null;
				if(!previousMest){
					previousMest = preAltScenario?preAltScenario.getTSLessMeasurementReferringToMeasuredCharteristic(altComp.get('satisfactionLevel')):null;				
					var compSatisfactionMeasure = previousMest?previousMest.get('observedMeasure').get('measure'):null;
				}
			}
			if(self.options.addOptions.settings){
				var condition = preAltScenario && previousMest && ((previousMest.get('type') === 'smm_DirectMeasurement') || (previousMest.get('type') === 'smm_GradeMeasurement' && compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')));
			}else{
				condition = preAltScenario && previousMest;
			}
			if(condition) {
				var unit = previousMest.get('observedMeasure').get('measure').get('unit');
				var	previousUnit = unit?unit.get('name'):'Grade only';
				var valId = value.get('id');
				var suffixId = window.utils.getSuffix(valId);
				var obj, found = _.find(self.parentView.selectedValuesArray, function(e){ return e.suffixId === suffixId;});
				if(!found){
					found = _.find(self.allValues, function(e){ return (e.suffixId === suffixId && e.checked == true);});
				}
				var name = value.get('name');
				var fullPath = self.getFullPath(value);
				var altName = alt.get('name');
				var phaseName = alt.getParent().get('name');
				if(found){
					var groupLabel = found.groupLabel? found.groupLabel : "";
					var label = found.label? found.label : "";
					obj = {id:valId,name:name, escapeId:self.htmlEscape(valId), suffixId:suffixId, checked:true, previousUnit:previousUnit,displayName:name, path:fullPath, label:label, groupLabel:groupLabel, altName: altName, phaseName: phaseName, type:'Plan', planName: plan.get('name')};
				}else{
					obj = {id:valId,name:name, escapeId:self.htmlEscape(valId), suffixId:suffixId, checked:false, previousUnit:previousUnit,displayName:name, path:fullPath, altName:altName, phaseName:phaseName, type: 'Plan', planName: plan.get('name')};
				}
				values.push(obj);
				self.valueBBModels.push(value);
			}
		}
		return values;
	};
	fillBMValues(bmArray, alt, valueType){
		var self = this;
		var bm, values = [];
		_.each(bmArray, function(bmId){
			bm = Backbone.Relational.store.getObjectByName("vdml.BusinessModel").find({id:bmId});
			var bmValues = [];
			bm.getValues(bmValues,true);
			for(var i=0; i< bmValues.length; i++){
				if(valueType && window.utils.getSourceType(bmValues[i]) !== valueType){
					continue;
				}
				if(alt) {
					var altComp = alt.getModelLinkedSync(bmValues[i]);
					var preAltScenario = altComp?alt.getMainScenario(altComp.getNestedParent()):null;
					var previousMest = preAltScenario?preAltScenario.getTSLessMeasurementReferringToMeasuredCharteristic(altComp.get('valueMeasurement')):null;
					if(!previousMest){
						previousMest = preAltScenario?preAltScenario.getTSLessMeasurementReferringToMeasuredCharteristic(altComp.get('satisfactionLevel')):null;
						var compSatisfactionMeasure = previousMest?previousMest.get('observedMeasure').get('measure'):null;
					}
				}
				if(self.options.addOptions.settings){
					var condition = preAltScenario && previousMest && ((previousMest.get('type') === 'smm_DirectMeasurement') || (previousMest.get('type') === 'smm_GradeMeasurement' && compSatisfactionMeasure && compSatisfactionMeasure.get('type') === 'smm_GradeMeasure' && !compSatisfactionMeasure.get('gradeTo')));
				}else{
					condition = preAltScenario && previousMest;
				}
				if(condition) {
					var unit = previousMest.get('observedMeasure').get('measure').get('unit');
					var	previousUnit = unit?unit.get('name'):'Grade only';
					var valId = bmValues[i].get('id');
					var suffixId = window.utils.getSuffix(valId);
					var obj, found = _.find(self.parentView.selectedValuesArray, function(e){ return e.suffixId === suffixId;});
                    if(!found){
                        found = _.find(self.allValues, function(e){ return (e.suffixId === suffixId && e.checked == true);});
                    }
					var name = bmValues[i].get('name');
					var fullPath = self.getFullPath(bmValues[i]);
					var altName = alt.get('name');
					var phaseName = alt.getParent().get('name');
					if(found){
						var groupLabel = found.groupLabel? found.groupLabel : "";
						var label = found.label? found.label : "";
						obj = {id:valId,name:name, escapeId:self.htmlEscape(valId), suffixId:suffixId, checked:true, previousUnit:previousUnit,displayName:name, path:fullPath, label:label, groupLabel:groupLabel, altName:altName, phaseName:phaseName, type:'BMValue'};
					}else{
						obj = {id:valId,name:name, escapeId:self.htmlEscape(valId), suffixId:suffixId, checked:false, previousUnit:previousUnit,displayName:name, path:fullPath, altName:altName, phaseName:phaseName, type:'BMValue'};
					}
					values.push(obj);
					self.valueBBModels.push(bmValues[i]);
				}
			}
		});
		return values;
	};
	fillValues(bmArray, altArray, valueType, onlyBMValues, fillValues, valueName, noFilter){
		var self = this;
		var values = [];
		var alt, plan = DataManager.getDataManager().get('currentPlan');
		for(var i = 0;i < altArray.length; i++){
			alt = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({id:altArray[i]});
			if(valueType === "Plan"){
				values = values.concat(self.fetchPlanDirectforAlt(plan,alt));
			}else if(valueType === "Value Proposition" || valueType === "My Proposition" || valueType === "Activity"){
				values = values.concat(self.fillBMValues(bmArray, alt, valueType));
			}else if(onlyBMValues){
				values = values.concat(self.fillBMValues(bmArray, alt));
			}else{
				values = values.concat(self.fetchPlanDirectforAlt(plan,alt));
				values = values.concat(self.fillBMValues(bmArray, alt));
			}	
		}
		function addNamePath(grouped){
			var index;
			_.each(grouped, function(val){
				val.nameWithoutUnit=val.name;
				index = val.path?val.path.length:0;
			});
			if(grouped.length>1){
				var filteredArray = grouped;
				_.each(filteredArray, function(element){
						element.nameWithoutUnit = element.nameWithoutUnit + ' [';
						element.namePath = "";
					});
				var duplicates = true, y = 0;
				while(duplicates){
					_.each(filteredArray, function(element){
						if(y>0 && y<4){
							if(element.nameWithoutUnit === element.name + ' ['){
								element.nameWithoutUnit = element.nameWithoutUnit + element.path[y].name;
								element.namePath = element.namePath + element.path[y].name;
							}
							else if(element.path[y].name.indexOf('Package')<0){
								element.nameWithoutUnit = element.nameWithoutUnit +' /' +element.path[y].name;
								element.namePath = element.namePath + ' /' + element.path[y].name;
							}
						}
						else if(y===4){
							element.nameWithoutUnit = element.nameWithoutUnit + ' /' +element.altName;
							element.namePath = element.namePath + ' /' + element.altName;
						}
						else if(y===5){
							element.nameWithoutUnit = element.nameWithoutUnit + ' /' +element.phaseName;
							element.namePath = element.namePath + ' /' + element.phaseName;
						}
						else{
							if(element.path[y].name.indexOf('port')<0){
								if(element.type=='Plan'){
									element.nameWithoutUnit = element.nameWithoutUnit + element.planName;
									element.namePath = element.namePath + element.planName;
								}
								else{
									element.nameWithoutUnit = element.nameWithoutUnit + element.path[y].name;
									element.namePath = element.namePath + element.path[y].name;
								}
							}
						}
					});
					var z =_.groupBy(filteredArray, function(a){
						return a.nameWithoutUnit;
					});
					if(Object.keys(z).length>1){
						filteredArray = [];
						_.each(z, function(v){
							if(v.length>1){
								_.each(v,function(vals){
									filteredArray.push(vals);
								});
							}
							else{
								if(v[0].nameWithoutUnit === v[0].name +' ['){
									v[0].nameWithoutUnit = v[0].nameWithoutUnit + v[0].path[1].name + ']';
									v[0].displayName = v[0].nameWithoutUnit;
								}
								else{
									v[0].nameWithoutUnit = v[0].nameWithoutUnit+ ']';
									v[0].displayName = v[0].nameWithoutUnit;
								}
							}
						});
					}
					if(filteredArray.length === 0){
						duplicates = false;
					}
					y++;
				}
			}
		}
		if(fillValues){
			if(noFilter){
				self.allValues.length = 0;
				//to make them unique
				_.map(_.groupBy(values,function(val){
					return val.suffixId;
				}),function(grouped){
					_.each(grouped, function(val){
						if(val.checked === true){
							grouped[0].checked = true;
						}
					});
					self.allValues.push(grouped[0]);
				});
				self.allValues.sort(self.sortByName);
				//to add namepath
				_.map(_.groupBy(self.allValues,function(val){
						return val.name;
					}),function(grouped){
						addNamePath(grouped);
				});
			}
			self.values.removeAll();				
			self.values(values);
			if(valueName){
				var filteredValues = $.grep(self.values(), function(val){ return val.name === valueName; });
				self.values(filteredValues);
			}
			setTimeout(function(){
				window.utils.stopSpinner('loadPackageSpinner');
				self.getUniqueValues();
				for(var i=0;i<self.valuesArray().length;i++){
					var valId = self.valuesArray()[i].id;
					var currentValue;
					for(var j=0;j<self.valueBBModels.length;j++){
						if(self.valueBBModels[j].get('id')==valId){
							currentValue = self.valueBBModels[j];
						}
					}
				}
				_.map(_.groupBy(self.valuesArray(),function(val){
						return val.name;
					}),function(grouped){
						addNamePath(grouped);
				});
				var data = self.valuesArray().slice(0);
				self.valuesArray([]);
				self.valuesArray(data);
				if($(".valuesList input").length == $(".valuesList input:checked").length) {
					$("#selectAll").prop("checked", true);
				}else{
					$("#selectAll").prop("checked", false);
				}
				$('#selectAll').click(function(){
					var checked = $(this).prop('checked');
					$(".valuesList input").prop('checked', checked);
					_.each(self.valuesArray(), function(val){
						var valFound = _.filter(self.allValues, function(e){return e.escapeId === val.escapeId;});
						if(valFound && valFound[0]){
							valFound[0].checked = checked;
						}
					});
				});
				$('.valuesList').click(function(){
					if($(".valuesList input").length == $(".valuesList input:checked").length) {
						$("#selectAll").prop("checked", true);
					}else {
						$("#selectAll").prop("checked", false);            
					}
				});
				$('.valuesList input').click(function(){
					var id = event.currentTarget.id;
					var checked = _.filter(self.allValues, function(e){return e.escapeId+'value' === id;});
					if(checked && checked[0]){
						checked[0].checked = $(this).prop('checked');
					}
				});
				$('.valuesList').css('max-height', $(window).height()*0.4);
			},10);
		}else{
			self.valueNames = _.unique(values.map(function(a) {return a.name;}));
		}
		
	};
	getUniqueValues(){
		var self = this;
		self.valuesArray.removeAll();
		_.map(_.groupBy(self.values(),function(val){
			return val.suffixId;
		}),function(grouped){
			_.each(grouped, function(val){
				//grouped[0][val.altId] = val.currentValue;
				if(val.checked === true){
					grouped[0].checked = true;
				}
			});
			self.valuesArray.push(grouped[0]);
		});
		self.valuesArray.sort(self.sortByName);
	};
	
	getFullPath(element){
		var self = this;
		var fullPath = [];
		while(fullPath.length === 0 || fullPath[fullPath.length-1].id !== element.getParent().id){
			element = element.getParent();
			fullPath.push({id:element.id, name: element.get('name')});
		}
		return fullPath;
	}
	
	getRoundedValues(val, sigDecimal) {
		var self = this;
		if (val !== null && val !== '' && !isNaN(val) && val !== Number.MAX_VALUE && val !== Infinity) {
			var newVal = parseFloat(val).toFixed(sigDecimal);
			return newVal;
		} else if (val === Number.MAX_VALUE || val === Infinity) {
			return '####';
		}
		return val;
	}
	
	saveSelection(){
		var self = this;
		var selectedValues = _.filter(self.allValues, function(e){ return e.checked === true;});
		if(self.options.addOptions.settings){
			self.options.callback(this,self.encodeId,selectedValues);	
		}else {
			self.parentView.selectedValuesArray.length = 0;
			self.parentView.selectedValuesArray = self.parentView.selectedValuesArray.concat(selectedValues);
			self.parentView.refreshTable();
			self.cleanModal();
		}
	};
	
	getMatchingPhasesList(searchTerm,categories,callback,includeLastCategory,namePropertyNS){
		var self = this;
		callback = _.bind(callback,self);
		var phases = DataManager.getDataManager().get('currentPlan').get('phase');
		var phaseNames = [];
		phases.forEach(function(phase){
			phaseNames.push(phase.get('name'));
		});
		callback(phaseNames);
	}
	getMatchingAlternativesList(searchTerm,categories,callback,includeLastCategory,namePropertyNS){
		var self = this;
		callback = _.bind(callback,self);
		var docType, phaseName, altNames = [];
		var length = includeLastCategory ? categories.length : categories.length-1;
		for(var i=0;i<length;i++){
			var category = categories[i].get('category');
			var value = categories[i].get('value');
			if(category === "Phase"){
				if(value !== ""){
					phaseName = value;
				}
			}
		}
		var alts = [], phases = DataManager.getDataManager().get('currentPlan').get('phase');
		phases.each(function(phase){
			alts = alts.concat(phase.get('phaseAlternative').models);
		});
		if(phaseName){
			_.each(alts, function(alt){
				if(alt.get('phaseAlternativeOwner').get('name') === phaseName){
					altNames.push(alt.get('name'));
				}
			});
		}else{
			_.each(alts, function(alt){
				altNames.push(alt.get('name'));
			});
		}		
		callback(_.unique(altNames));
	}
	getMatchingPackagesList(searchTerm,categories,callback,includeLastCategory,namePropertyNS){
		var self = this;
		callback = _.bind(callback,self);
		var docType;
		var packageNames = [];
		var name, altName, phaseName, alt;
		var length = includeLastCategory ? categories.length : categories.length-1;
		for(var i=0;i<length;i++){
			var category = categories[i].get('category');
			var value = categories[i].get('value');
			if(category === "Phase"){
				if(value !== ""){
					phaseName = value;
				}
			}
			if(category === "Alternative"){
				if(value !== ""){
					altName = value;
				}
			}
		}
		var alts = [], phases = DataManager.getDataManager().get('currentPlan').get('phase');
		phases.each(function(phase){
			alts = alts.concat(phase.get('phaseAlternative').models);
		});
		function fillPackagesFromAlt(alt, packArray){
			var phaseDesignPart = alt.get('phaseDesignPart');
			for(var i=0;i<phaseDesignPart.length;i++){
				if(phaseDesignPart.at(i).get('beepReference').indexOf("Common") == -1 && phaseDesignPart.at(i).get('beepType') !== 'report_BeepReport' && phaseDesignPart.at(i).get('beepType') !== 'ecomap_EcoMap' && phaseDesignPart.at(i).get('beepType') !== 'dashboard_Analytics'){
					packArray.push(phaseDesignPart.at(i).get('name'));
				}
			}
		}
		if(phaseName){
			var filteredAlts = $.grep(alts, function(alt){
				if(alt.get('phaseAlternativeOwner').get('name') === phaseName){
					if(altName){
						if(alt.get('name') === altName){
							return true;
						}
						return false;
					}
					return true;
				}
			});
			_.each(filteredAlts, function(alt){
				fillPackagesFromAlt(alt, packageNames);
			});
		}else if(altName){
			filteredAlts = $.grep(alts, function(alt){ return alt.get('name') === altName;})[0];
			fillPackagesFromAlt(filteredAlts, packageNames);
		}else{
			_.each(alts, function(alt){
				fillPackagesFromAlt(alt, packageNames);
			});
		}
		callback(_.unique(packageNames));
	}
	getMatchingBMs(searchTerm,categories,callback,includeLastCategory,namePropertyNS){
		var self = this;
		callback = _.bind(callback,self);
		var docType;
		var name, alt, phaseName, altName,packageName, packObjs, bmNames = [],packBBObj, BMs = [];
		var length = includeLastCategory ? categories.length : categories.length-1;
		for(var i=0;i<length;i++){
			var category = categories[i].get('category');
			var value = categories[i].get('value');
			if(category === "Phase"){
				if(value !== ""){
					phaseName = value;
				}
			}
			if(category === "Alternative"){
				if(value !== ""){
					altName = value;
				}
			}
			if(category === "Package"){
				if(value !== ""){
					packageName = value;
				}
			}
		}
		var alts = [], phases = DataManager.getDataManager().get('currentPlan').get('phase');
		phases.each(function(phase){
			alts = alts.concat(phase.get('phaseAlternative').models);
		});
		function fillBMsFromPackage(packId, bmNames){
			packBBObj = Backbone.Relational.store.getObjectByName("vdml.ValueDeliveryModel").find({id:packId});
			if(packBBObj){
				BMs = packBBObj.get('businessModel').models;
				_.each(BMs, function(bm){
					bmNames.push(bm.get('name'));
				});
			}
		}
		if(phaseName){
			packObjs = $.grep(self.packages, function(pack){
				if(pack.phaseName === phaseName){
					if(altName){
						if(pack.altName === altName){
							if(packageName){
								if(pack.name === packageName){
									return true;
								}
								return false;
							}
							return true;
						}
					}else if(packageName){
						if(pack.name === packageName){
							return true;
						}
						return false;
					}
					return true;
				}					
			});
			_.each(packObjs, function(packObj){
				fillBMsFromPackage(packObj.id, bmNames);
			});
		}else if(altName){
			var packObj = $.grep(self.packages, function(pack){
				if(pack.altName === altName){
					if(packageName){
						if(pack.name === packageName){
							return true;
						}
						return false;
					}
					return true;
				}					
			})[0];
			if(packObj){
				fillBMsFromPackage(packObj.id, bmNames);
			}
		}else if(packageName){
			packObj = $.grep(self.packages, function(pack){
				if(pack.name === packageName){
					return true;
				}
				return false;
			})[0];
			fillBMsFromPackage(packObj.id, bmNames);
		}else{
			_.each(alts, function(alt){
				var phaseDesignPart = alt ? alt.get('phaseDesignPart') : [];
				for(var i=0;i<phaseDesignPart.length;i++){
					if(phaseDesignPart.at(i).get('beepReference').indexOf("Common") == -1 && phaseDesignPart.at(i).get('beepType') !== 'report_BeepReport' && phaseDesignPart.at(i).get('beepType') !== 'ecomap_EcoMap' && phaseDesignPart.at(i).get('beepType') !== 'dashboard_Analytics' && phaseDesignPart.at(i).get('beepType') !== 'strategymap_StrategyMap' && phaseDesignPart.at(i).get('beepType') !== 'valueStreammap_ValueStreamMap'){
						fillBMsFromPackage(phaseDesignPart.at(i).get('beepReference'), bmNames);
					}
				}
			});
		}
		callback(_.unique(bmNames));
	}
	getMatchingNamesListForValues(searchTerm,categories,callback,includeLastCategory,namePropertyNS){
		var self = this;
		callback = _.bind(callback,self);
		var docType;
		var name, valueType,alt, phaseName, packageName, altName, bmNames = [],packBBObj, BMs = [], bmName, bmArray = [], altArray = [], bmObj;
		var length = includeLastCategory ? categories.length : categories.length-1;
		for(var i=0;i<length;i++){
			var category = categories[i].get('category');
			var value = categories[i].get('value');
			if(category === "Tag"){
				if(value !== ""){
					valueType = value;
				}
			}
			if(category === "Phase"){
				if(value !== ""){
					phaseName = value;
				}
			}
			if(category === "Alternative"){
				if(value !== ""){
					altName = value;
				}
			}
			if(category === "Package"){
				if(value !== ""){
					packageName = value;
				}
			}
			if(category === "Business Model"){
				if(value !== ""){
					bmName = value;
				}
			}
			if(category === "Name"){
				if(value !== ""){
					name = value;
				}
			}
		}
		var phases = DataManager.getDataManager().get('currentPlan').get('phase');
		/*phases.each(function(phase){
			alts = alts.concat(phase.get('phaseAlternative').models);
		});*/
		if(phaseName){
			var bmObjs = $.grep(self.businessModels, function(bm){
				if(bm.phaseName === phaseName){
					if(altName){
						if(bm.altName === altName){
							if(packageName){
								if(bm.packName === packageName){
									if(bmName){
										if(bm.name === bmName){
											return true;
										}
										return false;
									}
									return true;
								}
							}else if(bmName){
								if(bm.name === bmName){
									return true;
								}
								return false;
							}
							return true;
						}
					}else if(packageName){
						if(bm.packName === packageName){
							if(bmName){
								if(bm.name === bmName){
									return true;
								}
								return false;
							}
							return true;
						}
					}else if(bmName){
						if(bm.name === bmName){
							return true;
						}
						return false;
					}
					return true;
				}
			});
			_.each(bmObjs, function(bm){
				bmArray.push(bm.id);
				//altArray.push(bm.altId);
			});
			phases.forEach(function(phase){
				if(phase.get('name') === phaseName){
					phase.get('phaseAlternative').forEach(function(alt){
						if(altName){
							if(alt.get('name') === altName){
								altArray.push(alt.id);
							}
						}else{
							altArray.push(alt.id);
						}							
					});
				}
			});
			if(packageName || bmName){
				callback(bmArray, _.unique(altArray), valueType, true, includeLastCategory, name);
			}else{
				callback(bmArray, _.unique(altArray), valueType, false, includeLastCategory, name);
			}
		}else if(altName){
			bmObjs = $.grep(self.businessModels, function(bm){
				if(bm.altName === altName){
					if(packageName){
						if(bm.packName === packageName){
							if(bmName){
								if(bm.name === bmName){
									return true;
								}
								return false;
							}
							return true;
						}
					}else if(bmName){
						if(bm.name === bmName){
							return true;
						}
						return false;
					}
					return true;
				}
			});
			_.each(bmObjs, function(bm){
				bmArray.push(bm.id);
				//altArray.push(bm.altId);
			});
			phases.forEach(function(phase){
				phase.get('phaseAlternative').forEach(function(alt){
					if(alt.get('name') === altName){
						altArray.push(alt.id);
					}							
				});
			});
			if(packageName || bmName){
				callback(bmArray, _.unique(altArray), valueType, true, includeLastCategory, name);
			}else{
				callback(bmArray, _.unique(altArray), valueType, false, includeLastCategory, name);
			}				
		}else if(packageName){
			bmObjs = $.grep(self.businessModels, function(bm){
				if(bm.packName === packageName){
					if(bmName){
						if(bm.name === bmName){
							return true;
						}
						return false;
					}
					return true;
				}
			});
			_.each(bmObjs, function(bm){
				bmArray.push(bm.id);
				altArray.push(bm.altId);
			});
			callback(bmArray, _.unique(altArray), valueType, true, includeLastCategory, name);
		}else if(bmName){
			bmObjs = $.grep(self.businessModels, function(bm){
				return bm.name === bmName;
			});
			_.each(bmObjs, function(bm){
				bmArray.push(bm.id);
				altArray.push(bm.altId);
			});
			callback(bmArray, _.unique(altArray), valueType, true, includeLastCategory, name);
		}else{
			_.each(self.businessModels, function(bm){
				bmArray.push(bm.id);
			});
			if(categories.length === 0){ //to fill all values in an array
				callback(bmArray, self.altIds, valueType, false, includeLastCategory, name, true);
			}else{
				callback(bmArray, self.altIds, valueType, false, includeLastCategory, name);	
			}	
		}
	}
	initializeSearch(searchValue){
		var self = this;
		searchValue = searchValue ? searchValue : (self.options ? self.options.searchValue : null);
		var currentWorkSpaceKey = DataManager.getDataManager().get('currentWorkSpaceKey');
		var nameNS;
		self.visualSearch = VS.init({
			container  : $('.visual_search_addAlternative'),
			query      : self.searchParameter,
			showFacets : true,
			remainder : 'Name',
			unquotable : [],
			callbacks  : {
			search : function(query, searchCollection) {
				if(query && query!==""){
				self.saveFilterTags(query);
				}
				self.getMatchingNamesListForValues("",searchCollection.toArray(),self.fillValues,true,nameNS);
			},
			facetMatches : function(callback) {
				var facets;
				facets = ['Tag', 'Phase', 'Alternative', 'Package', 'Business Model', 'Name'];
				var categories = self.visualSearch.searchBox.app.searchQuery.models;
				for(var i=0;i<categories.length;i++){
					var category = categories[i].get('category');
					var value = categories[i].get('value');
					if(category === "Tag"){
						if(value === "Plan"){
							facets.splice( facets.indexOf( "Package" ), 1 );
							facets.splice( facets.indexOf( "Business Model" ), 1 );
						}
						facets.splice( facets.indexOf( "Tag" ), 1 );
					}
					if(category === "Phase"){
						facets.splice( facets.indexOf( "Phase" ), 1 );
					}
					if(category === "Alternative"){
						facets.splice( facets.indexOf( "Alternative" ), 1 );
					}
					if(category === "Package"){
						facets.splice( facets.indexOf( "Package" ), 1 );
					}
					if(category === "Business Model"){
						facets.splice( facets.indexOf( "Business Model" ), 1 );
					}
					if(category === "Name"){
						facets.splice( facets.indexOf( "Name" ), 1 );
					}
				}
				callback(facets);
			},
			valueMatches : function(facet, searchTerm, callback) {
				switch (facet) {
				case 'Tag':
					callback(self.tags);
					break;
				case 'Phase':
					self.getMatchingPhasesList(searchTerm,self.visualSearch.searchBox.app.searchQuery.models,callback,false,nameNS);
					break;
				case 'Alternative':
					self.getMatchingAlternativesList(searchTerm,self.visualSearch.searchBox.app.searchQuery.models,callback,false,nameNS);
					break;
				case 'Package':
					self.getMatchingPackagesList(searchTerm,self.visualSearch.searchBox.app.searchQuery.models,callback,false,nameNS);
					break;
				case 'Business Model':
					self.getMatchingBMs(searchTerm,self.visualSearch.searchBox.app.searchQuery.models,callback,false,nameNS);
					break;
				case 'Name':
					self.getMatchingNamesListForValues(searchTerm,self.visualSearch.searchBox.app.searchQuery.models,self.fillValues,false,nameNS);
					callback(self.valueNames);
					break;
				}
			}
			}
		});
		//self.visualSearch.searchQuery.trigger("search")
		if(searchValue){
			self.visualSearch.searchBox.value(searchValue);
		}
		self.visualSearch.searchBox.searchEvent({});
	}
		
	init(model, options){
		var self = this;
		self.afterRenderView = _.bind(self.afterRenderView,self);
		this.options = options;
		if(options.addOptions && options.addOptions.viewType === "editView"){
			this.editView(true);
			this.alternativeId = options.addOptions.alternativeId;
		}
		this.parentView = options.parentView;
		this.currentAlt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
		this.SelectChartValuesViewModel = this;
		this.model = model;
		this.id = kb.observable(model, 'id');
		this.encodeId = options.modalId;
		this.type = ko.observable();
		this.name = ko.observable('');
		this.description = ko.observable('');
		this.phaseList = ko.observableArray([]);
		this.altList = ko.observableArray([]);
		this.selectedPhase = ko.observable();
		this.selectedAlt = ko.observable();
		this.values = ko.observableArray([]);
		this.valueBBModels = [];
		this.allValues = []; //values without filters
		this.valuesArray = ko.observableArray([]);
		//this.enableComplete = ko.observable(false);	
		this.valueGroups = [];
		this.showValues = ko.observable(false);
		this.label = ko.observable("");
		this.editView = ko.observable(false);
		this.tags = ['Plan', 'Value Proposition', 'My Proposition', 'Activity'];
		this.searchParameter = options.parentView.savedFilterTags != "" ? options.parentView.savedFilterTags : null;
		$('#modal' + self.encodeId).on('shown.bs.modal', function () {
			$('.search_facet_input').css('width', 'auto');
		});
		self.fetchValuesFromAlts();
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
			 'Close'
			, 'Complete'
			, 'Cancel'
			, 'type'
			, 'selectBusinessModel'
			, 'selectType'
			, 'Delete'
		]);
	}
	
	static getInstance = function(model,options){
        var view = new SelectChartValuesViewModel(model, options);
        view.init(model, options);
        return view;
	};
}
path.SelectChartValuesViewModel = SelectChartValuesViewModel;