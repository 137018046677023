const ATOM_TRANSITION = 5;
const SET_TRANSITION = 7;
const ACTION_TRANSITION = 6;
const RULE_TRANSITION = 3;
const EPSIOLON_TRANSITION = 1;
const PRECEDENCE_PREDICATE = 10

const DEFAULT_IDENTIFIER = "IDENTIFIER";
const IDENTIFIER_MAPPINGS = {
    FUNC_IDENTIFIER: [89, 97],        // States related to function identifiers
    BM_IDENTIFIER: [130, 156],        // States for bm identifiers
    VP_IDENTIFIER: [134, 160],        // States for vp identifiers
    ACTIVITY_IDENTIFIER: [138],       // States for activity identifiers
    VP_VALUE_IDENTIFIER : [164],      // States for Value identifiers from VP's
    ACTIVITY_VALUE_IDENTIFIER : [142] // States for Value identifiers from Activity of a VP.
};
exports.IDENTIFIER_MAPPINGS = IDENTIFIER_MAPPINGS;
exports.DEFAULT_IDENTIFIER = DEFAULT_IDENTIFIER;


exports.ATOM_TRANSITION = ATOM_TRANSITION;
exports.SET_TRANSITION = SET_TRANSITION;
exports.ACTION_TRANSITION = ACTION_TRANSITION;
exports.RULE_TRANSITION = RULE_TRANSITION;
exports.EPSIOLON_TRANSITION = EPSIOLON_TRANSITION;
exports.PRECEDENCE_PREDICATE = PRECEDENCE_PREDICATE;