import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'

/*define(["require","jquery","underscore","backbone","knockout","knockoutMapping","knockback","bootbox","appcommon/com/vbee/data/DataManager","app/global","appbo/vdml/ValueDeliveryModel","appbo/vdml/BusinessModel","appbo/vdml/ValueStream","typeahead","appbo/vdml/ValueDeliveryModelMixin", "summernote"],
function(require,$,_, Backbone,ko, koMapping,kb,bootbox,DataManager,global,ValueDeliveryModel,BusinessModel,ValueStream,typeahead,ValueDeliveryModelMixin
){*/
    
    var path = DataManager.getDataManager().buildAppNsPath("scenario.views.properties",global.version);
    
    export class CalculationSummaryViewModel{
        
		
    afterRenderView(node, view){
    
    }
          
    cleanModalHandler() {
        var self = this;
        window.cleanDialogModel(self.encodeId, this);
    }
        
    init(model, options){
		var self = this;
        this.CalculationSummaryViewModel = this;
        this.model = model;
        this.id = ko.observable(self.model.get('id'));
        this.encodeId = window.utils.htmlEscape(this.id());
        this.name = ko.observable(self.model.get('name'));
        var defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: self.model.get("defaultExecutionScenario") });
        this.scName = ko.observable(defaultExecutionScenario.get('name'));
        this.lastCalculated = ko.observable();
        this.calculationSummaries = ko.observableArray([]);
		this.cleanModal = _.bind(self.cleanModalHandler, self);
		
        
		this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'),['Close'
        ,'CalculationSummary'
        ,'Complete'
        ]);
	}

    showSummaryData(callback){
        var self = this;
        var dataManager = DataManager.getDataManager();
        var keysToExclude = [
            'dual_status',
            'has_duals',
            'dual_objective_value',
            'relative_gap',
            'dual_solution'
        ];
        //dataManager.fetchCalcData(true,true,function(resp){
            //var calcResults = resp;//dataManager.get('calcInfo');
            //var modelChange = calcResults ? calcResults.modelChange :{};
            // var scenarioExecutionChange = calcResults ? calcResults.scenarioExecutionChange : {};
            //var defaultScenario = dataManager.get('defaultScenario');
            //var defaultExecutionScenaroId = defaultScenario ? defaultScenario.get("defaultExecutionScenario"):null;
            //var defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
            //var calculationData = defaultExecutionScenario ? defaultExecutionScenario.get('calculationSummary') :null;
            //var calculationData = dataManager.get('calculationSummary');
            //debugger
            var plan = dataManager.get('currentPlan');
            //var calculationData = calcResults.calculationSummary;
            var calcList = dataManager.get("calcSummaryList")[plan.id] || [];
            if (calcList) {
                //calculationData = JSON.parse(calculationData);
                var calculationSummaryArray = [];
                
                /*if (calculationData.lastCalculated) {
                    const exists = calcList.some(item => item.summary.lastCalculated === calculationData.lastCalculated);
                    if (!exists) {
                        calcList.push({
                            'lastCalculated': calculationData.lastCalculated,
                            'summary': calculationData
                        });
                    }
                    calcList.sort((a, b) => b.summary.lastCalculated - a.summary.lastCalculated);
                    dataManager.get("calcSummaryList")[plan.id] = calcList;
                }*/                
                for (const calcData of calcList) {
                    var optimizedCalculationData = window.utils.filterData(calcData.summary, keysToExclude);
                    var lastCalculated = calcData.lastCalculated
                        ? window.utils.formatDateTime(calcData.lastCalculated)
                        : '';
                    var summaryDetails = [];
                
                    for (const [key, value] of Object.entries(optimizedCalculationData)) {
                        if (key !== "lastCalculated" && value !== null && value !== '') {
                            summaryDetails.push({
                                name: window.utils.formatKey(key),
                                value: value
                            });
                        }
                    }
                
                    calculationSummaryArray.push({
                        calculatedOn: lastCalculated,
                        summaryDetails: summaryDetails
                    });
                }
            }
        
            self.calculationSummaries(calculationSummaryArray);
            callback();
        //});
    }
    
    static getInstance(model,options){
        var view = new CalculationSummaryViewModel(model,options);
		view.init(model, options);
		return view;
    };
}
path.CalculationSummaryViewModel = CalculationSummaryViewModel;