import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
import {BMCanvasDiagram} from '../../../../../version1/bo/canvas/BMCanvasDiagram'
import {ValueStream} from '../../../../../version1/bo/vdml/ValueStream'
import {BusinessItem} from '../../../../../version1/bo/vdml/BusinessItem'
import {CapabilityOffer} from '../../../../../version1/bo/vdml/CapabilityOffer'
import {MeasuredCharacteristic} from '../../../../../version1/bo/vdml/MeasuredCharacteristic'
import {ValueDeliveryModelMixin} from '../../../../../version1/bo/vdml/ValueDeliveryModelMixin'

//define(["require", "jquery", "underscore", 'async', "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/canvas/BMCanvasDiagram", "appbo/vdml/ValueStream", "appbo/vdml/BusinessItem", "appbo/vdml/CapabilityOffer", "appbo/vdml/MeasuredCharacteristic", "appbo/vdml/ValueDeliveryModelMixin"],
//function (require, $, _, async, Backbone, ko, koMapping, kb, bootbox, DataManager, global, BMCanvasDiagram, ValueStream, BusinessItem, CapabilityOffer, MeasuredCharacteristic, ValueDeliveryModelMixin) {

 var path = DataManager.getDataManager().buildAppNsPath("canvas.views.properties", global.version);
 export class MappingWizardViewModel{
    constructor(modelElement, model, options, configObj, selectedDim, selectedSlip, callback) {
        var self = this;
        this.MappingWizardViewModel = this;
        this.model = model;
        this.modelElement = modelElement;
        this.canvasType = this.model.get('canvasType');
        this.enableMyProposition = ko.observable(true);
        this.enablePlanCheckBox = ko.observable(true);
        this.mappedSlipModal = null;
		this.usedCapability = null;
		this.usedCapabilityName = ko.observable();
		this.showCapabilityElement = ko.observable(false);
        this.setRules = _.bind(self.setRules, self);
		
        this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
		'mapItem',
		'prev',
		'PursuedVPMP',
		'next',
		'selectDimension',
		'selectDimensionTo',
		'selectDimensionTitle',
		'selectPartner',
		'selectCustomerSegment',
		'involvedParticipantNetwork',
		'inRole',
		'isType',
		'selectValueProposition',
		'provider',
		'recipient',
		'MyProposition',
		'inOrderToPursue',
		'isPerformedBy',
		'selectActivity',
		'Details',
		'selectResource',
		'type',
		'isUsedBy',
		'selectCostStructure',
		'selectRevenueStreams',
		'isProvidedBy'
		, 'selectEnterPartner'
		, 'selectEnterCustomer'
		, 'partner'
		, 'activity'
		, 'competence'
		, 'valueProposition'
		, 'value'
		, 'customer'
		, 'source'
		, 'SelectVPorMP'
		, 'SelectRole'
		, 'SelectActivity'
		, 'SelectParticipant'
		, 'selectValue'
		, 'PlanValue'
        , 'MyBusiness'
        ,'CapabilityElement'
        ]);
        if (configObj) {
            this.mappingsArray = configObj.bmMappings;
            this.dimensions = configObj.dimensions;
            this.rules = configObj.mappingRules ? configObj.mappingRules : {};
            if (!this.mappingsArray && this.dimensions) {
                this.mappingsArray = [];
                this.mappingsArray.push({ "mapping": "value", "label": "Values", "name": "Values" });
            }
        } else {	//just to handle null values
            this.dimensions = [
				{ "id": "dimension1", "name": "Key Partners", "mapping": ["partner"] },
				{ "id": "dimension2", "name": "Key Activities", "mapping": ["activity"] },
				{ "id": "dimension3", "name": "Key Resources", "mapping": ["competence"] },
				{ "id": "dimension4", "name": "Value Propositions", "mapping": ["valueProposition"] },
				{ "id": "dimension5", "name": "Customer Relationships", "mapping": ["value", "competence", "activity", "network"] },
				{ "id": "dimension6", "name": "Channels", "mapping": ["partner", "competence"] },
				{ "id": "dimension7", "name": "Customer Segments", "mapping": ["customer"] },
				{ "id": "dimension8", "name": "Cost Structure", "mapping": ["value"] },
				{ "id": "dimension9", "name": "Revenue Streams", "mapping": ["value"] }
            ];
            this.mappingsArray = [
				{ "mapping": "partner", "name": "Key Partners" },
				{ "mapping": "activity", "name": "Key Activities" },
				{ "mapping": "competence", "name": "Key Resources" },
				{ "mapping": "valueProposition", "name": "Value Propositions" },
				{ "mapping": "customer", "name": "Customer Segments" },
				{ "mapping": "value", "name": "Cost Revenue" },
				{ "mapping": "network", "name": "network" }
            ];
            this.rules = {};
        }
        for (var i = 0; i < this.mappingsArray.length; i++) {
            var label = this.mappingsArray[i].label;
            if (label) {
                label = label.replace(/ /g,'');
                this.mappingsArray[i].name = DataManager.getDataManager().get('localeManager').get(label);
            }
        }
        /*for (var j = 0; j < this.dimensions.length; j++) {
            var label = this.dimensions[j].label;
            if (this.dimensions[j].rowColumnHeader) {
                this.dimensions[j].labelValue = this.dimensions[j].rowColumnHeader;
            } else if (label) {
                this.dimensions[j].labelValue = DataManager.getDataManager().get('localeManager').get(label);
            }
        }*/
        this.mappingDimensionsFrom = ko.observableArray([]);
        this.mappingDimensionsFrom.push({ id: "empty", labelValue: "Select Dimension" });
        _.each(this.dimensions, function (dimension) {
            if (dimension.mapping)
                self.mappingDimensionsFrom.push(dimension);
        });
        this.selectedDimBackup = self.dimensions[_.findLastIndex(self.dimensions, { labelValue: selectedDim })];
        if (this.selectedDimBackup) {
            self.selectedDim = this.selectedDimBackup.name;
        }
        //this.selectedDimArray = [];
        this.showSelectedMapping = false;
        this.mappingDimensions = ko.observableArray([]);
        this.selectedMappingFrom = ko.observable();
        function sortUpdateToDimensionsList(left, right) {
            return left.name.toLowerCase() == right.name.toLowerCase() ? 0 : (left.name.toLowerCase() < right.name.toLowerCase() ? -1 : 1);
        }
        this.selectedMappingFrom.subscribe(function (dimension) {
            if (dimension) {
                self.selectedDim = dimension.id;
            } else {
                self.selectedDim = null;
            }
            self.mappingDimensions.removeAll();
            if (dimension && dimension.id !== "empty" && self.selectedDim) {
                self.selectedMappingArray = self.dimensions[_.findLastIndex(self.dimensions, { id: self.selectedDim })].mapping;
                if (self.selectedMappingArray && self.selectedMappingArray.length === 1) {
                    self.showSelectedMapping = true;
                }
                if (self.selectedMappingArray) {
                    for (var i = 0; i < self.selectedMappingArray.length; i++) {
                        var mapping = self.mappingsArray[_.findLastIndex(self.mappingsArray, { mapping: self.selectedMappingArray[i] })];
                        if (mapping) {	//TODO handle network mapping
                            self.mappingDimensions.push(mapping);
                        }
                    }
                } else {
                    if (dimension.id === "empty") {
                        for (var i = 0; i < self.mappingsArray.length; i++) {
                            self.mappingDimensions.push(self.mappingsArray[i]);
                        }
                    }
                }
            } else {
                for (var i = 0; i < self.mappingsArray.length; i++) {
                    self.mappingDimensions.push(self.mappingsArray[i]);
                }
            }
            self.mappingDimensions.sort(sortUpdateToDimensionsList);
            if (self.selectedMapping)
                self.selectedMapping(self.mappingDimensions()[0]);
        });


        this.selectedMappingFrom(self.mappingDimensionsFrom()[_.findLastIndex(self.mappingDimensionsFrom(), { name: self.selectedDim ? self.selectedDim : "Select Dimension" })]);

        this.selectedSlip = selectedSlip;
        this.callback = callback;
        model = null;
        this.id = ko.observable(self.model ? self.model.get('id') : null);
		this.encodeId = window.utils.htmlEscape(this.id());
		this.capabilityLibs = [];
        if (options && options.parentView) {
			this.parentView = options.parentView;
			if (options.parentView.capabilityLibs && options.parentView.capabilityLibs.length > 0) {
				self.showCapabilityElement(true);
				self.capabilityLibs = options.parentView.capabilityLibs.slice();
			} else if (options.parentView.getVsInstance && options.parentView.getVsInstance.capabilityLibs.length > 0) {
				self.showCapabilityElement(true);
				self.capabilityLibs = options.parentView.getVsInstance.capabilityLibs.slice();
			}
        }

        this.name = ko.observable(self.model ? self.model.get('name') : "");
        this.description = ko.observable(self.model ? self.model.get('description') : "");
        this.selectedMapping = ko.observable();

        //var CompetenceType (name, type, typeStr) {
        //    this.name = name;
        //    this.type = type;
        //    this.typeStr = typeStr;
        //};
        //var competenceTypeArray = [new CompetenceType("" + DataManager.getDataManager().get('localeManager').get('Resource'), BusinessItem, "BusinessItem"),
        //    new CompetenceType("" + DataManager.getDataManager().get('localeManager').get('Capability'), CapabilityOffer, "CapabilityOffer")];
        this.resourceTypes = ko.observableArray([]);
        //for (var i = 0; i < competenceTypeArray.length; i++) {
        //    self.resourceTypes.push({ name: competenceTypeArray[i].name, typeStr: competenceTypeArray[i].typeStr });
        //}
        
        this.selectedResourceType = ko.observable();
        
        this.oldMapping;
        this.mappingName = ko.observable();

        self.currentDimensionId = ko.observable(self.selectedDim);
        self.currentRules = ko.observable();
        self.currentRules(self.rules[self.selectedDim]);

        self.currentDimensionId.subscribe(function (dimension) {
            if (self.rules[dimension]) {
                self.currentRules(self.rules[dimension]);
            } else {
                self.setRules();
            }
        });
        

        self.currentRules.subscribe(self.setRules);
        this.selectedMapping.subscribe(function (mapping) {
            if (!mapping) {
                return;
            }
            if (mapping !== this.oldMapping) {
                while ($('.steps li').length > 1) {
                    $('.steps li').last().remove();
                }
                var stepsNode = $('.steps');
                var stepStr = "<li class=\"wizardLibLi\" data-step=\"stepNo\"><span class=\"badge\">2</span><span>header</span><span class=\"chevron\"></span></li>";
                var reStep = new RegExp('stepNo', 'g');
                var reHeader = new RegExp('header', 'g');
                stepStr = stepStr.replace(reHeader, "Details");
                if (mapping.mapping === 'partner') {
                    stepStr = stepStr.replace(reStep, 2);
                    stepsNode.append($(stepStr));
                } else
                    if (mapping.mapping === 'activity') {
                        stepStr = stepStr.replace(reStep, 3);
                        stepsNode.append($(stepStr));
                    } else
                        if (mapping.mapping === 'competence') {
                            stepStr = stepStr.replace(reStep, 4);
                            stepsNode.append($(stepStr));
                        } else
                            if (mapping.mapping === 'valueProposition') {
                                stepStr = stepStr.replace(reStep, 5);
                                stepsNode.append($(stepStr));
                            } else
                                if (mapping.mapping === 'customer') {
                                    stepStr = stepStr.replace(reStep, 6);
                                    stepsNode.append($(stepStr));
                                } else
                                    if (mapping.mapping === 'value') {
                                        stepStr = stepStr.replace(reStep, 7);
                                        stepsNode.append($(stepStr));
                                    } else
                                        if (mapping.mapping === 'network') {
                                            stepStr = stepStr.replace(reStep, 8);
                                            stepsNode.append($(stepStr));
                                        }
            }
            self.mappingName(mapping.name);
            this.oldMapping = mapping;

        });
        this.participantTypes = ko.observableArray([]);

        this.setParticipantTypes = function (types) {
            self.participantTypes.removeAll();
            _.each(types, function (type) {
                self.participantTypes.push(type);
            });
        };
        
        this.participantType = ko.observable();

        this.selectedNetwork = ko.observable();
        this.selectedNetworkRole = ko.observable();

        this.editNetworkRoleName = ko.observable(false);
        this.editNetworkName = ko.observable(false);

        this.editCustNetworkRoleName = ko.observable(false);
        this.editCustNetworkName = ko.observable(false);
        this.isBmValue = ko.observable(true);
        this.myProposition = ko.observable(false);
        this.myProposition.subscribe(function (newVal) {
            if (newVal) {
                $('#recipientLegend' + self.encodeId).hide();
                $('#recipients' + self.encodeId).hide();
                $('#recipientRoles' + self.encodeId).hide();
                self.vpReceivers.removeAll();
                var previouslySelectedRole = self.selectedProviderRole();
                //self.vpReceivers.push({});
                self.loadProviders(true);
                if(previouslySelectedRole && previouslySelectedRole.id){//logic to set previously set role by user
                	var bm = self.model.get('businessModel');
                	var businessRole = false;
                	if(bm && bm.get('business')) {
			            var bmRoles = bm.get('bmRole');
			            var role = bmRoles.findWhere({'id':previouslySelectedRole.id})
		                var roleAssignments = role.get('roleAssignment');
		                for (var i = 0; i < roleAssignments.length; i++) {
		                    var assignment = roleAssignments.at(i);
		                    if (assignment.get('participant').id === bm.get('business').get('id')) {
		                        businessRole = true;
	                    	}    
						}
					}
					if(businessRole) {
	                	for(var i=0;i<self.vpProviderRoles().length;i++){
		                	if(previouslySelectedRole.id === self.vpProviderRoles()[i].id){
		                		self.selectedProviderRole(self.vpProviderRoles()[i]);
		                		break;
		                	}
		                }
                	}
	                previouslySelectedRole = null;
                }                
            } else {
                $('#recipientLegend' + self.encodeId).show();
                $('#recipients' + self.encodeId).show();
                $('#recipientRoles' + self.encodeId).show();
                self.loadProviders();
                self.loadReceivers();
            }
        });
        this.isPlanValue = ko.observable(false);
        this.isPlanValue.subscribe(function (newVal) {
        	if (newVal) {
	   			self.isBmValue(false);	                
	            self.selectedUnmappedValue() && $('.btn-next').removeAttr('disabled');
            }else {
                if(self.selectedUnmappedValue() && self.valueProvider()){
                	$('.btn-next').removeAttr('disabled');
                }else {
                	 $('.btn-next').attr('disabled', 'disabled');
                }
                self.isBmValue(true);
	        }
        });
        this.selectedProviderRole = ko.observable();
        this.selectedReceiverRole = ko.observable();

        this.isActivityProvider = ko.observable(false);

        this.selectedProviderRole.subscribe(function (role) {
            if (self.selectedProviderRole()) {
                if (!self.selectedReceiverRole() && self.vpReceiver()) {
                    $('.btn-next').attr('disabled', 'disabled');
                } else if((self.selectedReceiverRole() && self.vpReceiver()) || (!self.selectedReceiverRole() && !self.vpReceiver())) {
                    $('.btn-next').removeAttr('disabled');
                }
                if (!self.selectedReceiverRole() || (self.selectedReceiverRole() && role && role.id === self.selectedReceiverRole().id)) {
                    self.loadReceiverRoles(self.vpReceiver(), role);
                }
            } else {
                //$('.warning').addClass('hide');
                $('.btn-next').attr('disabled', 'disabled');
            }
        });

        this.selectedReceiverRole.subscribe(function (role) {
            if (self.selectedProviderRole()) {
                if (!self.selectedReceiverRole() && self.vpReceiver()) {
                    $('.btn-next').attr('disabled', 'disabled');
                } else if((self.selectedReceiverRole() && self.vpReceiver()) || (!self.selectedReceiverRole() && !self.vpReceiver())) {
                    $('.btn-next').removeAttr('disabled');
                }
                if (!self.selectedProviderRole() || (self.selectedProviderRole() && role && role.id === self.selectedProviderRole().id)) {
                    self.loadProviderRoles(self.vpProvider(), role);
                }
            } else {
                //$('.warning').addClass('hide');
                $('.btn-next').attr('disabled', 'disabled');
            }
        });


        /*	    $("#editNetworkName"+self.encodeId).keyup(function (view) {
                    self.selectedNetwork(view.currentTarget.value);
                });
                
                $("#editCustNetworkRoleName"+self.encodeId).keyup(function (view) {
                    self.selectedNetworkRole(view.currentTarget.value);
                });
                $("#editCustNetworkName"+self.encodeId).keyup(function (view) {
                    self.selectedNetwork(view.currentTarget.value);
                });	 */

        attachMouseDown();
        function attachMouseDown() {
            var eleID = ('modal' + self.encodeId);
            $('#' + eleID).mouseup(function (event) {
            });
        }
        this.unmappedPartners = new Backbone.Collection();
        this.existingParticipants = new Backbone.Collection();
        this.existingParticipant = ko.observable();
        this.selectedUnmappedPartner = ko.observable();
        this.selectedUnmappedPartner.subscribe(function (selectedItem) {
            self.currentDimensionId(selectedItem ? selectedItem.dimId : null);
        });

        this.unmappedCustomers = new Backbone.Collection();
        this.selectedUnmappedCustomer = ko.observable();
        this.selectedUnmappedCustomer.subscribe(function (selectedItem) {
            self.currentDimensionId(selectedItem ? selectedItem.dimId : null)
        });

        this.unmappedVPs = new Backbone.Collection();
        this.selectedUnmappedVP = ko.observable();
        this.selectedUnmappedVP.subscribe(function (selectedItem) {
            self.currentDimensionId(selectedItem ? selectedItem.dimId : null)
        });

        this.unmappedResources = ko.observableArray([]);
        this.selectedUnmappedResource = ko.observable();
        this.selectedUnmappedResource.subscribe(function (selectedItem) {
        	if(self.currentDimensionId){
        		self.currentDimensionId(selectedItem ? selectedItem.dimId : null)
        	}
        });

        this.unmappedValues = ko.observableArray([]);
        this.selectedUnmappedValue = ko.observable();
        this.selectedUnmappedValue.subscribe(function (selectedItem) {
            self.currentDimensionId(selectedItem ? selectedItem.dimId : null)
        });

        this.mappedVPs = ko.observableArray([]);
        this.persuedVPs = ko.observable();
        
        this.mappedActivities = ko.observableArray([]);
        
        
        this.resourceActivities = ko.observable();

        this.activityPerformers = ko.observableArray([]);
        this.activityPerformer = ko.observable();

        this.activityPerformer.subscribe(function (performer) {
            self.loadPerformerRoles(performer);
        });
        this.activityPerformerRoles = ko.observableArray([]);
        this.activityPerformerRole = ko.observable();

        this.activityPerformerRole.subscribe(function () {
            if (self.persuedVPs() && $('#selectActivity' + self.encodeId).val().trim() !== "" && self.activityPerformerRole()) {
                $('.btn-next').removeAttr('disabled');
            }
            else {
                $('.btn-next').attr('disabled', 'disabled');
            }
        });

        this.persuedVPs.subscribe(function () {
            if (self.persuedVPs() && $('#selectActivity' + self.encodeId).val().trim() !== "" && self.activityPerformerRole()) {
                $('.btn-next').removeAttr('disabled');
            }
            else {
                $('.btn-next').attr('disabled', 'disabled');
            }
        });
        /*$('#selectActivity' + self.encodeId).on('selected',function (view) {
				var name = view.target.value;
                if(name !== '' && self.persuedVPs() && self.activityPerformerRole()) {
                    $('.btn-next').removeAttr('disabled');
                }
                else {
                	$('.btn-next').attr('disabled','disabled');
                }
		    });*/
        this.resourceActivities.subscribe(function () {
            if (self.selectedUnmappedResource() && self.resourceActivities()) {
                $('.btn-next').removeAttr('disabled');
            }
            else {
                $('.btn-next').attr('disabled', 'disabled');
            }
        });
        this.unmappedActivities = new Backbone.Collection();
        this.selectedUnmappedActivity = ko.observable();
        this.selectedUnmappedActivity.subscribe(function (selectedItem) {
            self.currentDimensionId(selectedItem ? selectedItem.dimId : null)
        });

        this.networkTypeaheadCollection = new Backbone.Collection();
        //this.roleTypeadheadCollection = new Backbone.Collection();

        this.vpProviders = ko.observableArray([]);
        this.vpProvider = ko.observable();

        this.valueProviders = ko.observableArray([]);
        this.valueProvider = ko.observable();
        this.activityVPs = ko.observableArray([]);
        this.activityVP = ko.observable();
        this.valueProvider.subscribe(function (provider) {
            if (self.selectedUnmappedValue() && self.valueProvider()) {
                $('.btn-next').removeAttr('disabled');
            }
            else {
                $('.btn-next').attr('disabled', 'disabled');
            }
            if (!provider) {
                self.isActivityProvider(false);
                return;
            }
            if (provider.type === 'vdml_Activity') {
                self.isActivityProvider(true);
                if(!self.activityVP()){
                	$('.btn-next').attr('disabled', 'disabled');
                }
                self.loadActivityVPs(provider);
            } else {
                self.isActivityProvider(false);
            }
        });
        this.activityVP.subscribe(function (provider) {
        	if (self.selectedUnmappedValue() && self.valueProvider() && self.activityVP()) {
                $('.btn-next').removeAttr('disabled');
            }else {
                $('.btn-next').attr('disabled', 'disabled');
            }
         });
        this.vpProviderRoles = ko.observableArray([]);
        this.vpReceiverRoles = ko.observableArray([]);
        this.vpProvider.subscribe(function (provider) {
            self.loadProviderRoles(provider, self.selectedReceiverRole());
        });
        this.vpReceivers = ko.observableArray([]);
        this.vpReceiver = ko.observable();
        this.vpReceiver.subscribe(function (receiver) {
            self.loadReceiverRoles(receiver, self.selectedProviderRole());
            if (!self.selectedReceiverRole() && self.vpReceiver()) {
                //$('.warning').addClass('hide');
                $('.btn-next').attr('disabled', 'disabled');
            } else if (!self.vpReceiver() && self.selectedProviderRole()) {
                //$('.warning').addClass('hide');
                $('.btn-next').removeAttr('disabled');
            }
        });

        this.selectedUnmappedNetwork = ko.observable();
        this.selectedUnmappedNetworkRole = ko.observable();
        this.unmappedNetworksTypeahead = new Backbone.Collection();
        this.unmappedNetworksRolesTypeahead = new Backbone.Collection();
        this.businessName = ko.observable(self.model.get('businessModel') ? self.model.get('businessModel').get('business') ? self.model.get('businessModel').get('business').get('name') : '' : '');

        
        self.setBusinessName();

        this.selectedResourceType.subscribe(function (val) {
			if ((val && val.type == "Capability") && self.showCapabilityElement()) {
                $('#selectCapabilityDiv' + self.encodeId).show();
            } else {
                $('#selectCapabilityDiv' + self.encodeId).hide();
            }
        });

        modelElement.on('shown.bs.modal', function () {
            var selector = "#" + modelElement.attr('id') + " .focus-ele";
            window.utils.focusElement(selector);
            $('#selectType').focus();
        });
        self.getViewAlternativeModel();
        this.afterRenderView = _.bind(this.afterRenderView,this);
        this.setRules = _.bind(this.setRules,this);
    }
    setBusinessName = function () {
        var self = this;
        if (self.businessName().trim() === "") {
            self.businessName("My Business");
        }
    }
    getDimensionName = function (mapping) {
        var self = this;
        var label = self.mappingDimensions()[_.findLastIndex(self.mappingDimensions(), { mapping: mapping })].label;
        if (label) {
            return DataManager.getDataManager().get('localeManager').get(label);
        }
    };
    startWizard (cond) {
        var self = this;
        var viewModel = this;
        var modalContentNode = $('#wizardModalContent');
        ko.unapplyBindings(modalContentNode, false);
        modalContentNode.children().remove();
        var templates = window.utils.getHtmlPage('CanvasMappingWizardTemplate');
        //$.get("js/app/version1/views/canvas/views/designer/MappingWizardTemplate.html", function (templates) {
            modalContentNode.append(templates);
            var templateNode = $('#wizardTemplateContent');
            ko.applyBindings(viewModel, templateNode[0]);
            viewModel.modelElement.modal('toggle');
            viewModel.$wizard = $('#NewWizard').wizard(cond);
            viewModel.handleWizardButtons();
            viewModel.afterRenderView();
        //});
    };
        
    handleParticipantSelected(selectedParticipant) {
        var self = this;
        var type = selectedParticipant.get('type').replace('_','.');
        for(var i=0;i<self.participantTypes().length;i++){
            if(self.participantTypes()[i].type === type){
                self.participantType(self.participantTypes()[i]);
                break;
            }
        }
        self.existingParticipant({id:selectedParticipant.get('id'),name:selectedParticipant.get('name'),type:type});
        if (self.matchField){
            self.matchField.show();
            self.matchField[0].innerHTML = 'Match';
        }
    };
    setCompetenceType(types) {
            var self = this;
            self.resourceTypes.removeAll();
            _.each(types, function (type) {
                self.resourceTypes.push(type);
            });
            if(self.mappedSlipModal && self.mappedSlipModal.get('canvasItem')) {
	            var vdmlCompType = self.mappedSlipModal.get('canvasItem').get('type');
	        	var compType;
	        	if(vdmlCompType === "vdml_BusinessItem"){
	        		compType = DataManager.getDataManager().get('localeManager').get('Resource');
	        	}else if(vdmlCompType === "vdml_CapabilityOffer"){
	        		compType = DataManager.getDataManager().get('localeManager').get('Capability');
	        	}
	        	if(compType) {
	        		var item = $.grep(self.resourceTypes(), function (e) { return e.type === compType; });
					self.selectedResourceType(item[0]);
					$("#selectCompetencyType" + self.encodeId).prop('disabled',true);
	        	}
			}
			var cap = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: self.selectedSlip });
            if (cap && cap.get('capabilityItem') && self.selectedMapping().name != "Activities") {
				var item = $.grep(self.resourceTypes(), function (e) { return e.type === "Capability"; });
				self.selectedResourceType(item[0]);
				$("#selectCompetencyType" + self.encodeId).prop('disabled',true);
				$("#selectCapabilityComp" + self.encodeId).prop('disabled',true);
				//$("#selectCapability" + self.encodeId).prop('disabled',true);
            }
			if (cap && cap.get('capabilityItem') && self.selectedMapping().name != "Activities") {
                self.usedCapabilityName(cap.get('capabilityItem').get('name'));
				self.usedCapability = cap.get('capabilityItem').get('id');
                $('#mapCapabilityCom'+self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
                $('#deleteCompCapability' + self.encodeId).show();
			}else if(cap && cap.get('canvasItem') && cap.get('canvasItem').get('capability') && self.selectedMapping().name != "Activities"){
				self.usedCapabilityName(cap.get('canvasItem').get('capability').get('name'));
				self.usedCapability = cap.get('canvasItem').get('capability').get('id');
                $('#mapCapabilityCom'+self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
                $('#deleteCompCapability' + self.encodeId).show();
			}
            
        };
    setRules(currentRules) {
        var self = this;
        if (currentRules) {
            var changedParticipantTypes = false;
            var changedResourceTypes = false;
            var changedMyProp = false;
            _.each(currentRules, function (currentRule) {
                if (currentRule.participantTypes) {
                    changedParticipantTypes = true;
                    self.setParticipantTypes(currentRule.participantTypes);
                }
                if (currentRule.resourceTypes) {
                    changedResourceTypes = true;
                    self.setCompetenceType(currentRule.resourceTypes);
                }
                if (currentRule.myProposition !== undefined) {
                    changedMyProp = true;
                    self.myProposition(currentRule.myProposition);
                    $('#myProposition' + self.encodeId).hide();
                }
            });
            if (!changedParticipantTypes) {
                self.setParticipantTypes([{ name: 'Market Segment', type: 'vdml.Community' }, { name: 'Enterprise', type: 'vdml.OrgUnit' }, { name: 'Individual', type: 'vdml.Actor' }]);
            }
            if (!changedResourceTypes) {
                self.setCompetenceType([{ type: 'Resource' }, { type: 'Capability' }]);
            }
            if (!changedMyProp) {
                $('#myProposition' + self.encodeId).show();
            }

        } else {
            self.setParticipantTypes([{ name: 'Market Segment', type: 'vdml.Community' }, { name: 'Enterprise', type: 'vdml.OrgUnit' }, { name: 'Individual', type: 'vdml.Actor' }]);
            self.setCompetenceType([{ type: 'Resource' }, { type: 'Capability' }]);
            $('#myProposition' + self.encodeId).show();
        }
    };
    afterRenderView () {
        var self = this;
        $('.modal-content').resizable();
        $('#deleteActCapability' + self.encodeId).hide();
        $('#deleteCompCapability' + self.encodeId).hide();
        var NetworkExtended = Backbone.Typeahead.extend({
            template: '<input type="text" id="network1' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        self.partnerNetworkTypeahead = new NetworkExtended({ collection: this.networkTypeaheadCollection });
        self.partnerNetworkTypeahead.setElement('#bmPartNetwork' + self.encodeId).render();
        $('#network1' + self.encodeId).change(function(view){
            if(self.matchField){
                self.matchField.hide();
            }
        });
        $('#network1' + self.encodeId).dblclick(function (view) {
            $('#network1' + self.encodeId).trigger('keyup');
            $('#network1' + self.encodeId).focus();
        });
        var handleNetworkSelected = function(selectedNetwork) {
            self.selectedNetwork(selectedNetwork);
            if ($("#selectPartner" + self.encodeId).val().trim() !== '' && $("#role1" + self.encodeId).val().trim() !== '') {
                $('.btn-next').removeAttr('disabled');
            }
            if ($("#selectCustomer" + self.encodeId).val().trim() !== '' && $("#role2" + self.encodeId).val().trim() !== '') {
                $('.btn-next').removeAttr('disabled');
            }
        };
        self.partnerNetworkTypeahead.on('selected', handleNetworkSelected);
        NetworkExtended = Backbone.Typeahead.extend({
            template: '<input type="text" id="network2' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        $('#network2' + self.encodeId).change(function(view){
            if(self.matchField){
                self.matchField.hide();
            }
        });
        self.customerNetworkTypeahead = new NetworkExtended({ collection: this.networkTypeaheadCollection });
        self.customerNetworkTypeahead.setElement('#bmCustNetwork' + self.encodeId).render();
        self.customerNetworkTypeahead.on('selected', handleNetworkSelected);

        /*var RoleExtended = Backbone.Typeahead.extend({
            template: '<input type="text" id="role1'+self.encodeId+'" placeholder="Name" class="form-control focus-ele"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
        });			
        self.partnerRoleTypeahead = new RoleExtended({collection:this.roleTypeadheadCollection});		
        self.partnerRoleTypeahead.setElement('#bmNetworkRole' + self.encodeId ).render();
        
        self.partnerRoleTypeahead.on('selected', function(selectedRole) {
            self.selectedNetworkRole(selectedRole);
        });*/

        var partnerExtended = Backbone.Typeahead.extend({
            template: '<input type="text" id="selectPartner' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        self.partnerTypeahead = new partnerExtended({ collection: this.unmappedPartners });
        self.partnerTypeahead.setElement('#bmPartner' + self.encodeId).render();
        
        self.partnerTypeahead.on('selected', function (selectedPartner) {
            self.selectedUnmappedPartner(selectedPartner);
        });
        
        var participantPartnerExtended = Backbone.Typeahead.extend({
            template: '<input type="text" id="selectParticipantPartner' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        self.participantPartnerTypeahead = new participantPartnerExtended({ collection: this.existingParticipants });
        self.participantPartnerTypeahead.setElement('#selectParticipantPartnerDiv' + self.encodeId).render();
        $('#selectParticipantPartner' + self.encodeId).change(function(view){
            if(self.matchField){
                self.matchField.hide();
            }
        });
        self.participantPartnerTypeahead.on('selected', function (selectedParticipant) {
            self.handleParticipantSelected(selectedParticipant);
            $("#selectPartnerType").attr('disabled', 'disabled');
        });	
        
        var participantCustomerExtended = Backbone.Typeahead.extend({
            template: '<input type="text" id="selectParticipantCustomer' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        self.participantCustomerTypeahead = new participantCustomerExtended({ collection: this.existingParticipants });
        self.participantCustomerTypeahead.setElement('#selectParticipantCustomerDiv' + self.encodeId).render();
        
        self.participantCustomerTypeahead.on('selected', function (selectedParticipant) {
            self.handleParticipantSelected(selectedParticipant);
            $("#selectCustomerType").attr('disabled', 'disabled');
        });	

        var activityExtended = Backbone.Typeahead.extend({
            template: '<input type="text" id="selectActivity' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        self.activityTypeahead = new activityExtended({ collection: this.unmappedActivities });
        self.activityTypeahead.setElement('#bmActivity' + self.encodeId).render();

        self.activityTypeahead.on('selected', function (selectedActivity) {
            self.selectedUnmappedActivity(selectedActivity);
        });

        var vpExtended = Backbone.Typeahead.extend({
            template: '<input type="text" id="selectVP' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        self.vpTypeahead = new vpExtended({ collection: this.unmappedVPs });
        self.vpTypeahead.setElement('#bmVP' + self.encodeId).render();

        self.vpTypeahead.on('selected', function (selectedVP) {
            self.selectedUnmappedVP(selectedVP);
        });

        var customerExtended = Backbone.Typeahead.extend({
            template: '<input type="text" id="selectCustomer' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        self.customerTypeahead = new customerExtended({ collection: this.unmappedCustomers });
        self.customerTypeahead.setElement('#bmCustomer' + self.encodeId).render();

        self.customerTypeahead.on('selected', function (selectedCustomer) {
            self.selectedUnmappedCustomer(selectedCustomer);
        });

        var networkExtended = Backbone.Typeahead.extend({
            template: '<input type="text" id="selectNetwork' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        self.networkTypeahead = new networkExtended({ collection: this.unmappedNetworksTypeahead });
        self.networkTypeahead.setElement('#bmNetwork' + self.encodeId).render();

        self.networkTypeahead.on('selected', function (selectedNetwork) {
            self.selectedUnmappedNetwork(selectedNetwork);
            self.loadNetworkRolesTypeahead(selectedNetwork);
            if (self.selectedUnmappedNetworkRole()) {
                self.selectedUnmappedNetworkRole(null);
                $('#selectNetworkRole' + self.encodeId).val('');
            }
            if ($('#selectNetworkRole' + self.encodeId).val().trim() != '') {
                $('.btn-next').removeAttr('disabled');
            }
        });

        var networkRoleExtended = Backbone.Typeahead.extend({
            template: '<input type="text" id="selectNetworkRole' + self.encodeId + '" placeholder="Name" width="90%" class="form-control focus-ele"><ul style="width:90%" class="dropdown-menu typeahead-scrollbar"></ul>',
        });
        self.networkRoleTypeahead = new networkRoleExtended({ collection: this.unmappedNetworksRolesTypeahead });
        self.networkRoleTypeahead.setElement('#networkRole' + self.encodeId).render();

        self.networkRoleTypeahead.on('selected', function (selectedRole) {
            self.selectedUnmappedNetworkRole(selectedRole);
            if ($('#selectNetwork' + self.encodeId).val().trim() != '') {
                $('.btn-next').removeAttr('disabled');
            }
        });


        /*var RoleExtended = Backbone.Typeahead.extend({
            template: '<input type="text" id="role2'+self.encodeId+'" placeholder="Name" class="form-control focus-ele"><ul class="dropdown-menu typeahead-scrollbar"></ul>',
        });	
        self.custRoleTypeahead = new RoleExtended({collection:this.roleTypeadheadCollection});		
        self.custRoleTypeahead.setElement('#bmCustNetworkRole' + self.encodeId).render();
        self.custRoleTypeahead.on('selected', function(selectedRole) {
            self.selectedNetworkRole(selectedRole);
        });	*/
        if (self.selectedDim && self.showSelectedMapping) {
            $('.btn-next').click();
        } else if (self.selectedDim && self.selectedMappingArray.length > 1 && _.contains(self.selectedMappingArray, 'value') && !self.model.get('businessModel')) {
            var valMappingObj = $.grep(self.mappingsArray, function (e) { return e.mapping === 'value'; });
            self.selectedMapping(valMappingObj[0]);
            $('.btn-next').click();
        } else if(self.model.get('businessModel') && self.selectedDim && self.selectedMappingArray.length > 1){
            var item = $.grep(self.dimensions, function (e) { return e.id === self.selectedDim; });
            if(item[0]) {
                var dimBlock = self.model.get('bmCanvasBlock').findWhere({'name':item[0].name});
                var selectedSlipModal = dimBlock.get('blockItem').findWhere({'id':self.selectedSlip});
                if(selectedSlipModal && selectedSlipModal.get('canvasItem')) {
                    var mappedSlipModalType = selectedSlipModal.get('canvasItem').get('type');
                    var selectedValueMapping;
                    if(mappedSlipModalType === "vdml_Community" || mappedSlipModalType === "vdml_OrgUnit" || mappedSlipModalType === "vdml_Actor"){
                        if(self.selectedMappingArray.indexOf('partner') != -1){
                            selectedValueMapping = $.grep(self.mappingsArray, function (e) { return e.mapping === 'partner'; });
                        }else {
                            selectedValueMapping = $.grep(self.mappingsArray, function (e) { return e.mapping === 'customer'; });
                        }
                    }else if(mappedSlipModalType === "vdml_BusinessItem" || mappedSlipModalType === "vdml_CapabilityOffer" && self.selectedMappingArray.indexOf('competence') != -1){
                        selectedValueMapping = $.grep(self.mappingsArray, function (e) { return e.mapping === 'competence'; });
                    }else if(mappedSlipModalType === "vdml_ValueAdd" || mappedSlipModalType === "vdml_ValuePropositionComponent" && self.selectedMappingArray.indexOf('value') != -1){
                        selectedValueMapping = $.grep(self.mappingsArray, function (e) { return e.mapping === 'value'; });            				
                    }else if(mappedSlipModalType === "vdml_ValueProposition" && self.selectedMappingArray.indexOf('valueProposition') != -1){
                        selectedValueMapping = $.grep(self.mappingsArray, function (e) { return e.mapping === 'valueProposition'; });            				
                    }else if(mappedSlipModalType === "vdml_Activity" && self.selectedMappingArray.indexOf('activity') != -1){
                        selectedValueMapping = $.grep(self.mappingsArray, function (e) { return e.mapping === 'activity'; });
                    }else if(mappedSlipModalType === "vdml_BusinessNetwork" && self.selectedMappingArray.indexOf('network') != -1){
                        selectedValueMapping = $.grep(self.mappingsArray, function (e) { return e.mapping === 'network'; });	
                    }
                    if(selectedValueMapping[0]) {
                        self.selectedMapping(selectedValueMapping[0]);
                        $('.btn-next').click();
                    }
                }            		
            }
        }
        if ((self.selectedUnmappedValue() && self.isPlanValue()) || self.mappedSlipModal) {
            $('.btn-prev').attr('disabled', 'disabled');
        }
        //self.currentRules(self.rules[self.selectedDim]);
        
        $('#selectParticipantPartner' + self.encodeId).keyup(function (view) {
            self.matchField = $(view.currentTarget).closest('.row').find('.match');
            if(view.currentTarget.value.trim() !== ""){
                self.matchField.show();
            }else{
                self.matchField.hide();
            }
            var matchFound = _.filter(self.existingParticipants.models,function(obj){return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase() && obj.get('type').replace('_','.') === self.participantType().type;});
            if(matchFound && matchFound.length){
                self.handleParticipantSelected(matchFound[0]);
                $("#selectPartnerType").attr('disabled', 'disabled');
                self.matchField[0].innerHTML = "Match";
            }else{
                self.existingParticipant('');
                $("#selectPartnerType").removeAttr('disabled');
                self.matchField[0].innerHTML = "New";
            }
        });
        
        $('#selectParticipantCustomer' + self.encodeId).keyup(function (view) {
            self.matchField = $(view.currentTarget).closest('.row').find('.match');
            if(view.currentTarget.value.trim() !== ""){
                self.matchField.show();
            }else{
                self.matchField.hide();
            }
            var matchFound = _.filter(self.existingParticipants.models,function(obj){return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase() && obj.get('type').replace('_','.') === self.participantType().type;});
            if(matchFound && matchFound.length){
                self.handleParticipantSelected(matchFound[0]);
                $("#selectCustomerType").attr('disabled', 'disabled');
                self.matchField[0].innerHTML = "Match";
            }else{
                self.existingParticipant('');
                $("#selectCustomerType").removeAttr('disabled');
                self.matchField[0].innerHTML = "New";
            }
        });
        
        $('#selectPartner' + self.encodeId).keyup(function (view) {
            var name = view.target.value;
            if (name !== '' && $("#network1" + self.encodeId).val().trim() !== '' && $("#role1" + self.encodeId).val().trim() !== '') {
                $('.btn-next').removeAttr('disabled');
            }
            else {
                $('.btn-next').attr('disabled', 'disabled');
            }
        });
        $('#selectActivity' + self.encodeId).keyup(function (view) {
            var name = view.target.value;
            if (name !== '' && self.persuedVPs() && self.activityPerformerRole()) {
                $('.btn-next').removeAttr('disabled');
            }
            else {
                $('.btn-next').attr('disabled', 'disabled');
            }
        });           
        $('#selectVP' + self.encodeId).keyup(function (view) {
            var name = view.target.value;
            if (name !== '' && self.vpProvider() && self.selectedProviderRole()) {
                $('.btn-next').removeAttr('disabled');
            }
            else {
                $('.btn-next').attr('disabled', 'disabled');
            }
        });


        $('#role1' + self.encodeId).keyup(function (view) {
            var name = view.target.value.trim();
            if (name !== '' && $("#network1" + self.encodeId).val().trim() !== '' && $("#selectPartner" + self.encodeId).val().trim() !== '') {
                $('.btn-next').removeAttr('disabled');
            }
            else {
                $('.btn-next').attr('disabled', 'disabled');
            }
            self.selectedNetworkRole(view.currentTarget.value);
        });
        $('#network1' + self.encodeId).keyup(function (view) {
            self.matchField = $(view.currentTarget).closest('.row').find('.match');
            if(view.currentTarget.value.trim() !== ""){
                self.matchField.show();
            }else{
                self.matchField.hide();
            }
            var matchFound = _.filter(self.networkTypeaheadCollection.models,function(obj){return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase();});
            if(matchFound && matchFound.length){
                self.selectedNetwork(matchFound[0]);
                self.matchField[0].innerHTML = "Match";
            }else{
                self.selectedNetwork(null);
                self.matchField[0].innerHTML = "New";
            }
            var name = view.target.value.trim();
            if (name !== '' && $("#role1" + self.encodeId).val().trim() !== '' && $("#selectPartner" + self.encodeId).val().trim() !== '') {
                $('.btn-next').removeAttr('disabled');
            }
            else {
                $('.btn-next').attr('disabled', 'disabled');
            }
            //self.selectedNetwork(new Backbone.Model({name:view.currentTarget.value}));
        });

        $('#role2' + self.encodeId).keyup(function (view) {
            var name = view.target.value.trim();
            if (name !== '' && $("#network2" + self.encodeId).val().trim() !== '' && $("#selectCustomer" + self.encodeId).val().trim() !== '') {
                $('.btn-next').removeAttr('disabled');
            }
            else {
                $('.btn-next').attr('disabled', 'disabled');
            }
            self.selectedNetworkRole(view.currentTarget.value);
        });
        $('#network2' + self.encodeId).keyup(function (view) {
            self.matchField = $(view.currentTarget).closest('.row').find('.match');
            if(view.currentTarget.value.trim() !== ""){
                self.matchField.show();
            }else{
                self.matchField.hide();
            }
            var matchFound = _.filter(self.networkTypeaheadCollection.models,function(obj){return obj.get('name').toLowerCase() === view.currentTarget.value.toLowerCase();});
            if(matchFound && matchFound.length){
                self.selectedNetwork(matchFound[0]);
                self.matchField[0].innerHTML = "Match";
            }else{
                self.selectedNetwork(null);
                self.matchField[0].innerHTML = "New";
            }
            var name = view.target.value.trim();
            if (name !== '' && $("#role2" + self.encodeId).val().trim() !== '' && $("#selectCustomer" + self.encodeId).val().trim() !== '') {
                $('.btn-next').removeAttr('disabled');
            }
            else {
                $('.btn-next').attr('disabled', 'disabled');
            }
            //self.selectedNetwork(new Backbone.Model({name:view.currentTarget.value}));
        });
        $('#selectNetwork' + self.encodeId).keyup(function (view) {
            var name = view.target.value.trim();
            self.selectedUnmappedNetwork(null);
            self.unmappedNetworksRolesTypeahead.reset();
            if (self.selectedUnmappedNetworkRole()) {
                self.selectedUnmappedNetworkRole(null);
                $('#selectNetworkRole' + self.encodeId).val('');
            }
            if (name !== '' && $('#selectNetworkRole' + self.encodeId).val().trim() !== '' && $('#businessName' + self.encodeId).val().trim() !== '') {
                $('.btn-next').removeAttr('disabled');
            }
            else {
                $('.btn-next').attr('disabled', 'disabled');
            }
        });
        $('#businessName' + self.encodeId).keyup(function (view) {
            var name = view.target.value.trim();
            if (name !== '' && $('#selectNetworkRole' + self.encodeId).val().trim() !== '' && $('#selectNetwork' + self.encodeId).val().trim() !== '') {
                $('.btn-next').removeAttr('disabled');
            }
            else {
                $('.btn-next').attr('disabled', 'disabled');
            }
        });
        $('#selectNetworkRole' + self.encodeId).keyup(function (view) {
            var name = view.target.value.trim();
            self.selectedUnmappedNetworkRole(null);
            if (name !== '' && $('#selectNetwork' + self.encodeId).val().trim() !== '' && $('#businessName' + self.encodeId).val().trim() !== '') {
                $('.btn-next').removeAttr('disabled');
            }
            else {
                $('.btn-next').attr('disabled', 'disabled');
            }
        });
        var autoCompAttr = 'new-password';
        if(window.browser == "chrome"){
            autoCompAttr = 'off';
        }
        $('input[type="text"]').attr('autocomplete',autoCompAttr);
        $('input[type="text"]').bind("paste", function(e){
            var validTxt = window.utils.pasteHandle(e);
            if(validTxt !== null){
                e.stopPropagation();
                e.preventDefault();
                $(e.target).val(window.utils.pasteContent(e,validTxt));
                return false;
            }
        });
        self.setRules(self.currentRules());
        /*			this.loadUnmappedPartners();
                    this.loadUnmappedCustomers();
                    this.loadNetworks();
                    this.loadUnmappedVPs();
                    this.loadProviders();*/
//            $("#selectParticipantCustomer"+ self.encodeId).keyup();
//            $("#selectParticipantPartner" + self.encodeId).keyup();
    }
        //creating a copy
    createRevisionBasedOnContext (revisionCheck, callback) {
        var self = this;
        if (window.checkContextForRevision() && revisionCheck) {
            window.utils.startSpinner('revisionSpinner', "Creating a copy...");
            /*function waitAndStopRevisionSpinner(model){
                function stopRevisionSpinner(){
                    window.utils.stopSpinner('revisionSpinner');
                    //trigger canvas
                    DataManager.getDataManager().get('router').navigate("views/"+ model.getNestedParent().get('id') + "/vdml_ValueDeliveryModel/" + model.getNestedParent().get('version') + "/" + model.get('id') + "/canvas_BMCanvasDiagram/" + DataManager.getDataManager().get('viewAlternative') +"", {trigger: true});
                }
                setTimeout(stopRevisionSpinner,10);
            }*/
            function fetchBmcModel(newVdmPackage) {
                if (newVdmPackage) {
                    var modelId = self.model.get('id');
                    var modelSuffix = modelId.substr(modelId.lastIndexOf('@') + 1);
                    var newId = DataManager.getDataManager().get('viewAlternative') + '' + modelSuffix;
                    self.model = newVdmPackage.get('canvas').findWhere({ 'id': newId });
                    var oldAltIdReg = new RegExp(DataManager.getDataManager().get('currentWorkspace').get('id'), 'g');
                    window.utils.updateObservablesForRevision(self.MappingWizardViewModel, oldAltIdReg, []);
                    DataManager.getDataManager().releaseSaveLock();
                    DataManager.getDataManager().set('isActive', false);
                    window.utils.stopSpinner('revisionSpinner');
                    callback(self.model);
                    /*DataManager.getDataManager().saveData({
                        success: function () {
                            window.utils.stopSpinner('revisionSpinner');
                            callback(self.model);
                        },
                        error: function () {
                            window.utils.stopSpinner('revisionSpinner');
                            callback(self.model);
                        },
                        persist: true
                    });*/
                }
            }
            window.setTimeout(function () {
                DataManager.getDataManager().acquireSaveLock(function () {
                    self.model.getNestedParent().createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmcModel);
                });
            }, 100);
        }
        else {
            callback(false);
        }
    }

    

    getRoleDispName(role, participant) {
        var self = this;
        var roleNS = "";
        var roles = self.model.get('businessModel').get('bmRole');
        var roleModel = roles.findWhere({ id: role.id });
        if (roleModel) {
            for (var i = 0; i < roles.length; i++) {
                var roleAssignment = roles.at(i).get('roleAssignment').at(0);
                var roleParticipant = roleAssignment ? roleAssignment.get('participant').get('id') : null;
                if ((roles.at(i).get('name') === roleModel.get('name')) && (roles.at(i).get('id') !== roleModel.get('id') && participant.id === roleParticipant)) {
                    roleNS = " [";
                    for (var k = 0; k < roleModel.getPackagePath().length - 1; k++) {
                        roleNS = roleNS.concat(roleModel.getPackagePath()[k].name);
                        if (roleModel.getPackagePath()[k + 2]) {
                            roleNS = roleNS.concat("/");
                        }
                    }
                    roleNS = roleNS.concat("]");
                }
            }
        }
        return roleNS;
    }

    loadProviderRoles (provider, receiverRole) {
        var self = this;
        var selProviderRole = self.selectedProviderRole();
        self.vpProviderRoles.removeAll();
        $('#providerRole' + self.encodeId).val('');
        if (!provider) {
            return;
        }
        var bm = self.model.get('businessModel');
        var bmRoles = bm.get('bmRole');
        bmRoles.each(function (role) {
            var roleAssignments = role.get('roleAssignment');
            for (var i = 0; i < roleAssignments.length; i++) {
                var assignment = roleAssignments.at(i);
                if (assignment.get('participant').id === provider.id) {
                    if (receiverRole && receiverRole.id === role.id) {
                        break;
                    }
                    var roleName = role.get('name').concat(self.getRoleDispName(role, provider));
                    self.vpProviderRoles.push({ id: role.id, name: roleName });
                }
            }
        });
        if(self.mappedSlipModal){
            var canvasItem = self.mappedSlipModal.get('canvasItem');
            var item = $.grep(self.vpProviderRoles(), function (e) { return e.id === canvasItem.get('provider').id; });
            self.selectedProviderRole(item[0]);
            $("#selectProviderRole" + self.encodeId).prop("disabled", true);
        }
        else if (selProviderRole) {
            var item = $.grep(self.vpProviderRoles(), function (e) { return e.name === selProviderRole.name; });
            self.selectedProviderRole(item[0]);
        }
    }
    loadPerformerRoles (provider) {
        var self = this;
        var selPerformerRoles = self.activityPerformerRole();
        self.activityPerformerRoles.removeAll();
        //$('#performer'+self.encodeId).val('');
        if (!provider) {
            return;
        }
        var bm = self.model.get('businessModel');
        var bmRoles = bm.get('bmRole');
        bmRoles.each(function (role) {
            var roleAssignments = role.get('roleAssignment');
            roleAssignments.each(function (assignment) {
                if (assignment.get('participant').id === provider.id) {
                    var roleName = role.get('name').concat(self.getRoleDispName(role, provider));
                    self.activityPerformerRoles.push({ id: role.id, name: roleName });
                }
            });
        });
        if(self.mappedSlipModal){
            var performingRole = self.mappedSlipModal.get('canvasItem').get('performingRole');
            var item = $.grep(self.activityPerformerRoles(), function (e) { return e.id === performingRole.id; });
            self.activityPerformerRole(item[0]);
            $("#selectPerformingRole" + self.encodeId).prop("disabled", true);
        }
        else if (selPerformerRoles) {
            var item = $.grep(self.activityPerformerRoles(), function (e) { return e.name === selPerformerRoles.name; });
            self.activityPerformerRole(item[0]);
        }
        var cap = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: self.selectedSlip });
        if (self.showCapabilityElement()) {
            $('#selectCapabilities' + self.encodeId).show();
        } else {
            $('#selectCapabilities' + self.encodeId).hide();
        }
        if (cap && cap.get('canvasItem') && cap.get('canvasItem').get('capabilityRequirement') || cap.get('capabilityItem')) {
            if(cap.get('canvasItem') && cap.get('canvasItem').get('capabilityRequirement')) {
            self.usedCapabilityName(cap.get('canvasItem').get('capabilityRequirement').get('name'));
            self.usedCapability = cap.get('canvasItem').get('capabilityRequirement').id;
            }else if (cap && !cap.get('canvasItem')){
            self.usedCapabilityName(cap.get('capabilityItem').get('name'));
            self.usedCapability = cap.get('capabilityItem').get('id');
            }
        }  
        if(self.usedCapability){
            $('#mapActCapability' +self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon glyphicon-pencil');
            $('#deleteActCapability' + self.encodeId).show();
        }
    }
    loadReceiverRoles (receiver, providerRole) {
        var self = this;
        var selReceiverRole = self.selectedReceiverRole();
        self.vpReceiverRoles.removeAll();
        $('#receiverRole' + self.encodeId).val('');
        if (!receiver) {
            return;
        }
        var bm = self.model.get('businessModel');
        var bmRoles = bm.get('bmRole');
        bmRoles.each(function (role) {
            var roleAssignments = role.get('roleAssignment');
            for (var i = 0; i < roleAssignments.length; i++) {
                var assignment = roleAssignments.at(i);
                if (assignment.get('participant').id === receiver.id) {
                    if (providerRole && providerRole.id === role.id) {
                        break;
                    }
                    var roleName = role.get('name').concat(self.getRoleDispName(role, receiver));
                    self.vpReceiverRoles.push({ id: role.id, name: roleName });
                }
            }
        });
        if(self.mappedSlipModal){
            var canvasItem = self.mappedSlipModal.get('canvasItem');
            if(canvasItem.get('recipient')) {
                var item = $.grep(self.vpReceiverRoles(), function (e) { return e.id === canvasItem.get('recipient').id; });
                self.selectedReceiverRole(item[0]);
                $("#selectReceiverRole" + self.encodeId).prop("disabled", true);
            }
        }
        else if (selReceiverRole) {
            var item = $.grep(self.vpReceiverRoles(), function (e) { return e.name === selReceiverRole.name; });
            self.selectedReceiverRole(item[0]);
        }
    }
    loadProviders (limitToBusiness) {
        var self = this;
        var selProvider = self.vpProvider();
        self.vpProviders.removeAll();
        if (!limitToBusiness) {
            var colls = this.model.get('businessModel').getNestedParent().get('collaboration');
            colls.each(function (coll) {
                if (coll.get('type') !== 'vdml_BusinessNetwork')
                    self.vpProviders.push({ name: coll.get('name'), id: coll.id });
            });
        } else {
            var business = this.model.get('businessModel').get('business');
            if (business) {
                self.vpProviders.push({ name: business.get('name'), id: business.id });
            }
        }
        var actors = this.model.get('businessModel').getNestedParent().get('actor');
        actors.each(function (actor) {
            self.vpProviders.push({ name: actor.get('name'), id: actor.id });
        });
        if(self.mappedSlipModal){
            var canvasItem = self.mappedSlipModal.get('canvasItem');
            var participant = canvasItem.get('provider').get('roleAssignment').at(0).get('participant');
            var item = $.grep(self.vpProviders(), function (e) { return e.id === participant.id; });
            self.vpProvider(item[0]);
            $("#selectProvider" + self.encodeId).prop("disabled", true);
        }
        else if (selProvider) {
            var item = $.grep(self.vpProviders(), function (e) { return e.name === selProvider.name; });
            self.vpProvider(item[0]);
        }
    }
    loadReceivers () {
        var self = this;
        var selReceiver = self.vpReceiver();
        self.vpReceivers.removeAll();
        //self.vpReceivers.push({});
        var colls = this.model.get('businessModel').getNestedParent().get('collaboration');
        colls.each(function (coll) {
            if (coll.get('type') !== 'vdml_BusinessNetwork')
                self.vpReceivers.push({ name: coll.get('name'), id: coll.id });
        });
        var actors = this.model.get('businessModel').getNestedParent().get('actor');
        actors.each(function (actor) {
            self.vpReceivers.push({ name: actor.get('name'), id: actor.id });
        });
        if(self.mappedSlipModal){
            var canvasItem = self.mappedSlipModal.get('canvasItem');
            if(canvasItem.get('recipient')){
                var participant = canvasItem.get('recipient').get('roleAssignment').at(0).get('participant');
                var item = $.grep(self.vpReceivers(), function (e) { return e.id === participant.id; });
                self.vpReceiver(item[0]);
                $("#selectReceiver" + self.encodeId).prop("disabled", true);
            }
        }
        else if (selReceiver) {
            var item = $.grep(self.vpReceivers(), function (e) { return e.name === selReceiver.name; });
            self.vpReceiver(item[0]);
        }
    }
    loadNetworksTypeAhead () {
        var self = this;
        self.partnerNetworkTypeahead.collection.reset();
        self.partnerNetworkTypeahead.rerender([]);
        self.customerNetworkTypeahead.collection.reset();
        self.customerNetworkTypeahead.rerender([]);
        var bm = this.model.get('businessModel');
        if (bm) {
            var bmNetworks = bm.get('participantNetwork');
            bmNetworks.each(function (network) {
                self.networkTypeaheadCollection.push({ id: network.id, name: network.get('name') });
            });
        }
        /*var dimObjs = [];
        _.each(self.dimensions,function(dimension){
            var boolean= false;
            _.each(dimension.mapping,function(mapping){
                if(mapping === "network"){
                    boolean = true;
                }
            });
            if(boolean){
                dimObjs.push(dimension);
            }
        });
        _.each(dimObjs,function(dimObj){
            var block = self.model.get('bmCanvasBlock').find({name:dimObj.name});
            var items = block.get('blockItem');
            items.each(function(item){
                if(!item.get('canvasItem')){
                    self.networkTypeaheadCollection.push({name:item.get('name'),blockItem:item});
                }
            });
        });	*/
    }
        
    loadExistingParticipants(){
        var self = this;
        self.existingParticipants.reset();
        var bm = self.model.get('businessModel');
        if(bm) {
            var dimensionName = self.selectedDimBackup.name;
            var dimBlock = self.model.get('bmCanvasBlock').findWhere({'name':dimensionName});
            var mappedSlipObject = [];
            if(dimBlock && dimBlock.get('blockItem')){
                    for(var i=0;i<dimBlock.get('blockItem').length;i++){
                    var canvasItem = dimBlock.get('blockItem').at(i).get('canvasItem');
                    if(canvasItem) {
                        mappedSlipObject.push(canvasItem);
                    }
                    }
            }
            var partList = bm.getParticipants();
            _.each(partList, function (participant) {
                if (mappedSlipObject.indexOf(participant) === -1 && participant != bm.get('business')) {
                    self.existingParticipants.push({ id: participant.id, name: participant.get('name'), type: participant.get('type') });
                }
            });
            //var partners = bm.get('bmNetworkPartner');
        //      partners.each(function (item) {
        //      	if(mappedSlipObject.indexOf(item.get('networkPartner')) === -1) {
        //      		self.existingParticipants.push({id: item.get('networkPartner').id, name: item.get('networkPartner').get('name'),type: item.get('networkPartner').get('type')});
        //      	}
        //      });
        //      var customers = bm.get('bmCustomer');
        //      customers.each(function (item) {
        //      	if(mappedSlipObject.indexOf(item.get('customer')) === -1) {
        //          	self.existingParticipants.push({id: item.get('customer').id, name: item.get('customer').get('name'),type: item.get('customer').get('type')});
        //      	}
        //      });
        }
    }

    loadUnmappedPartners () {
        var self = this;
        self.unmappedPartners.reset();
        var dimObjs = [];
        if (!self.selectedDim || self.selectedDim === "empty") {
            _.each(self.dimensions, function (dimension) {
                var boolean = false;
                _.each(dimension.mapping, function (mapping) {
                    if (mapping === "partner") {
                        boolean = true;
                    }
                });
                if (boolean) {
                    dimObjs.push(dimension);
                }
            });
        } else {
            dimObjs.push(self.dimensions[_.findLastIndex(self.dimensions, { id: self.selectedDim })]);
        }

        _.each(dimObjs, function (dimObj) {
            var block = self.model.get('bmCanvasBlock').find({ name: dimObj.name });
            var items = block.get('blockItem');
            items.each(function (item) {
                if(!self.mappedSlipModal && item.get('canvasItem') && item.get('id') === self.selectedSlip){
                    self.mappedSlipModal = item;
                }
                if (!item.get('canvasItem')) {
                    self.unmappedPartners.push({ id: item.id, name: item.get('name'), description:item.get('description'), blockItem: item, dimId: dimObj.id });
                }
            });
        });
        dimObjs.length = 0;
        if(self.mappedSlipModal){
            $("#selectParticipantPartner"+ self.encodeId).prop("disabled", true);
            var canvasItem = self.mappedSlipModal.get('canvasItem');
            self.handleParticipantSelected(canvasItem);
            $("#selectParticipantPartner" + self.encodeId).val(canvasItem.get('name'));
            $("#selectPartner" + self.encodeId).val(self.mappedSlipModal.get('name'));
            $("#selectPartnerType").prop("disabled", true);
            $("#selectPartner" + self.encodeId).prop("disabled", true);
        }
        else if (self.selectedSlip && self.unmappedPartners.length !== 0) {
            var selectedObj = self.unmappedPartners.findWhere({ 'id': self.selectedSlip });
            self.selectedUnmappedPartner(selectedObj);
            $("#selectPartner" + self.encodeId).val(selectedObj ? selectedObj.get('name') : '');
        }
    }

    loadNetworkRolesTypeahead (pn) {
        var self = this;
        self.unmappedNetworksRolesTypeahead.reset();
        var bm = this.model.get('businessModel');
        if (bm && pn) {
            var bmRoles = bm.get('bmRole');
            bmRoles.each(function (role) {
                if (role.get('collaborationRoleOwner').id === pn.get('collId')) {
                    self.unmappedNetworksRolesTypeahead.push({ id: role.id, name: role.get('name') });
                }
            });
        }
    }

    loadUnmappedNetwork () {
        var self = this;
        self.unmappedNetworksTypeahead.reset();
        var dimObjs = [];
        if (!self.selectedDim || self.selectedDim === "empty") {
            var bm = this.model.get('businessModel');
            if (bm) {
                var bmNetworks = bm.get('participantNetwork');
                bmNetworks.each(function (network) {
                    self.unmappedNetworksTypeahead.push({ id: network.id, name: network.get('name'), collId: network.get('collaboration').get('id') });
                });
            }
        } else {
            dimObjs.push(self.dimensions[_.findLastIndex(self.dimensions, { id: self.selectedDim })]);
        }
        _.each(dimObjs, function (dimObj) {
            var block = self.model.get('bmCanvasBlock').find({ name: dimObj.name });
            var items = block.get('blockItem');
            items.each(function (item) {
                if(!self.mappedSlipModal && item.get('canvasItem') && item.get('id') === self.selectedSlip){
                    self.mappedSlipModal = item;
                }
                if (!item.get('canvasItem')) {
                    self.unmappedNetworksTypeahead.push({ id: item.id, name: item.get('name'), description:item.get('description'),  blockItem: item, dimId: dimObj.id });
                }
            });
        });
        dimObjs.length = 0;
        if(self.mappedSlipModal){
            $("#selectNetwork" + self.encodeId).val(self.mappedSlipModal.get('name'));
            $("#selectNetwork" + self.encodeId).prop('disabled',true); 
            $("#businessName" + self.encodeId).prop('disabled',true); 
        }else if (self.selectedSlip && self.unmappedNetworksTypeahead.length !== 0) {
            var selectedObj = self.unmappedNetworksTypeahead.findWhere({ 'id': self.selectedSlip });
            self.selectedUnmappedNetwork(selectedObj);
            $("#selectNetwork" + self.encodeId).val(selectedObj ? selectedObj.get('name') : '');
        }
    }

    loadUnmappedCustomers () {
        var self = this;
        self.unmappedCustomers.reset();
        var dimObjs = [];
        if (!self.selectedDim || self.selectedDim === "empty") {
            _.each(self.dimensions, function (dimension) {
                var boolean = false;
                _.each(dimension.mapping, function (mapping) {
                    if (mapping === "customer") {
                        boolean = true;
                    }
                });
                if (boolean) {
                    dimObjs.push(dimension);
                }
            });
        } else {
            dimObjs.push(self.dimensions[_.findLastIndex(self.dimensions, { id: self.selectedDim })]);
        }
        _.each(dimObjs, function (dimObj) {
            var block = self.model.get('bmCanvasBlock').find({ name: dimObj.name });
            var items = block.get('blockItem');
            items.each(function (item) {
                if(!self.mappedSlipModal && item.get('canvasItem') && item.get('id') === self.selectedSlip){
                    self.mappedSlipModal = item;
                }
                if (!item.get('canvasItem')) {
                    self.unmappedCustomers.push({ id: item.id, name: item.get('name'), description:item.get('description'), blockItem: item, dimId: dimObj.id });
                }
            });
        });
        dimObjs.length = 0;
        if(self.mappedSlipModal){
            $("#selectParticipantCustomer"+ self.encodeId).prop("disabled", true);
            var canvasItem = self.mappedSlipModal.get('canvasItem');
            self.handleParticipantSelected(canvasItem);
            $("#selectParticipantCustomer" + self.encodeId).val(canvasItem.get('name'));
            $("#selectCustomer" + self.encodeId).val(self.mappedSlipModal.get('name'));
            $("#selectCustomerType").prop("disabled", true);
            $("#selectCustomer" + self.encodeId).prop("disabled", true);
        }
        else if (self.selectedSlip && self.unmappedCustomers.length !== 0) {
            var selectedObj = self.unmappedCustomers.findWhere({ 'id': self.selectedSlip });
            self.selectedUnmappedCustomer(selectedObj);
            $("#selectCustomer" + self.encodeId).val(selectedObj ? selectedObj.get('name') : '');
        }
    }
    loadUnmappedVPs () {
        var self = this;
        self.unmappedVPs.reset();
        var dimObjs = [];
        if (!self.selectedDim || self.selectedDim === "empty") {
            _.each(self.dimensions, function (dimension) {
                var boolean = false;
                _.each(dimension.mapping, function (mapping) {
                    if (mapping === "valueProposition") {
                        boolean = true;
                    }
                });
                if (boolean) {
                    dimObjs.push(dimension);
                }
            });
        } else {
            dimObjs.push(self.dimensions[_.findLastIndex(self.dimensions, { id: self.selectedDim })]);
        }
        _.each(dimObjs, function (dimObj) {
            var block = self.model.get('bmCanvasBlock').find({ name: dimObj.name });
            var items = block.get('blockItem');
            items.each(function (item) {
                if(!self.mappedSlipModal && item.get('canvasItem') && item.get('id') === self.selectedSlip){
                    self.mappedSlipModal = item;
                }
                if (!item.get('canvasItem')) {
                    self.unmappedVPs.push({ id: item.id, name: item.get('name'), description:item.get('description'),  blockItem: item, dimId: dimObj.id });
                }
            });
        });
        dimObjs.length = 0;
        if(self.mappedSlipModal){
            $("#selectVP" + self.encodeId).val(self.mappedSlipModal.get('name'));
            $("#selectVP" + self.encodeId).prop('disabled',true);
            var myVPCheck = self.mappedSlipModal.get('canvasItem').get('isValueFormula');
            self.myProposition(myVPCheck);
            self.enableMyProposition(false);
        }	
        else if (self.selectedSlip && self.unmappedVPs.length !== 0) {
            var selectedObj = self.unmappedVPs.findWhere({ 'id': self.selectedSlip });
            self.selectedUnmappedVP(selectedObj);
            $("#selectVP" + self.encodeId).val(selectedObj ? selectedObj.get('name') : '');
        }
    }
    loadUnmappedActivities () {
        var self = this;
        self.unmappedActivities.reset();
        var dimObjs = [];
        if (!self.selectedDim || self.selectedDim === "empty") {
            _.each(self.dimensions, function (dimension) {
                var boolean = false;
                _.each(dimension.mapping, function (mapping) {
                    if (mapping === "activity") {
                        boolean = true;
                    }
                });
                if (boolean) {
                    dimObjs.push(dimension);
                }
            });
        } else {
            dimObjs.push(self.dimensions[_.findLastIndex(self.dimensions, { id: self.selectedDim })]);
        }
        _.each(dimObjs, function (dimObj) {
            var block = self.model.get('bmCanvasBlock').find({ name: dimObj.name });
            var items = block.get('blockItem');
            items.each(function (item) {
                if(!self.mappedSlipModal && item.get('canvasItem') && item.get('id') === self.selectedSlip){
                    self.mappedSlipModal = item;
                }
                if (!item.get('canvasItem')) {
                    self.unmappedActivities.push({ id: item.id, name: item.get('name'), description:item.get('description'), blockItem: item, dimId: dimObj.id });
                }
            });
        });
        dimObjs.length = 0;
        if(self.mappedSlipModal){
            $("#selectActivity" + self.encodeId).val(self.mappedSlipModal.get('name'));
            $("#selectActivity" + self.encodeId).prop('disabled',true);
        }
        else if (self.selectedSlip && self.unmappedActivities.length !== 0) {
            var selectedObj = self.unmappedActivities.findWhere({ 'id': self.selectedSlip });
            self.selectedUnmappedActivity(selectedObj);
            $("#selectActivity" + self.encodeId).val(selectedObj ? selectedObj.get('name') : '');
        }
    }
    loadUnmappedResources () {
        var self = this;
        self.unmappedResources.removeAll();
        var dimObjs = [];
        if (!self.selectedDim || self.selectedDim === "empty") {
            _.each(self.dimensions, function (dimension) {
                var boolean = false;
                _.each(dimension.mapping, function (mapping) {
                    if (mapping === "competence") {
                        boolean = true;
                    }
                });
                if (boolean) {
                    dimObjs.push(dimension);
                }
            });
        } else {
            dimObjs.push(self.dimensions[_.findLastIndex(self.dimensions, { id: self.selectedDim })]);
        }
        _.each(dimObjs, function (dimObj) {
            var block = self.model.get('bmCanvasBlock').find({ name: dimObj.name });
            var items = block.get('blockItem');
            items.each(function (item) {
                if(!self.mappedSlipModal && item.get('canvasItem') && item.get('id') === self.selectedSlip){
                    self.mappedSlipModal = item;
                    self.unmappedResources.push({ id: self.mappedSlipModal.get('id'), name: self.mappedSlipModal.get('name'), blockItem: item, dimId: dimObj.id });
                }
                if (!item.get('canvasItem')) {
                    self.unmappedResources.push({ id: item.get('id'), name: item.get('name'), description:item.get('description'), blockItem: item, dimId: dimObj.id });
                }
            });
        });
        dimObjs.length = 0;
        if(self.mappedSlipModal){
            var item = $.grep(self.unmappedResources(), function (e) { return e.id === self.mappedSlipModal.id; });
            self.unmappedResources(item[0]);
            $("#selectCompetency" + self.encodeId).prop('disabled',true);
        }	
        else if (self.selectedSlip && self.unmappedResources().length !== 0) {
            var index = self.unmappedResources().map(function (el) { return el.id; }).indexOf(self.selectedSlip);
            var spliced = self.unmappedResources.splice(index, 1)[0];
            self.unmappedResources.removeAll();
            self.unmappedResources.unshift(spliced);
        }
    }
    loadUnmappedValues () {
        var self = this;
        self.unmappedValues.removeAll();
        var dimObjs = [];
        if (!self.selectedDim || self.selectedDim === "empty") {
            _.each(self.dimensions, function (dimension) {
                var boolean = false;
                _.each(dimension.mapping, function (mapping) {
                    if (mapping === "value") {
                        boolean = true;
                    }
                });
                if (boolean) {
                    dimObjs.push(dimension);
                }
            });
        } else {
            dimObjs.push(self.dimensions[_.findLastIndex(self.dimensions, { id: self.selectedDim })]);
        }
        _.each(dimObjs, function (dimObj) {
            var block = self.model.get('bmCanvasBlock').find({ name: dimObj.name });
            var items = block.get('blockItem');
            items.each(function (item) {
                if(!self.mappedSlipModal && item.get('canvasItem') && item.get('id') === self.selectedSlip){
                    self.mappedSlipModal = item;
                    self.unmappedValues.push({ id: self.mappedSlipModal.get('id'), name: self.mappedSlipModal.get('name'), blockItem: item, dimId: dimObj.id });
                }
                if (!item.get('canvasItem')) {
                    self.unmappedValues.push({ id: item.get('id'), name: item.get('name'), description:item.get('description'),  blockItem: item, dimId: dimObj.id });
                }
            });
        });
        dimObjs.length = 0;
        if(self.mappedSlipModal){
            var item = $.grep(self.unmappedValues(), function (e) { return e.id === self.mappedSlipModal.id; });
            self.selectedUnmappedValue(item[0]);
            $("#selectUnmappedValue" + self.encodeId).prop('disabled',true);
            var planValueCheck = false;
            if(self.mappedSlipModal.get('canvasItem').getNestedParent() === DataManager.getDataManager().get('currentPlan')){
                planValueCheck = true;
            }
            self.isPlanValue(planValueCheck);
            self.enablePlanCheckBox(false);
        }
        else if (self.selectedSlip && self.unmappedValues().length !== 0) {
            var index = self.unmappedValues().map(function (el) { return el.id; }).indexOf(self.selectedSlip);
            var spliced = self.unmappedValues.splice(index, 1)[0];
            self.unmappedValues.removeAll();
            self.unmappedValues.unshift(spliced);
        }
    }
    loadMappedVPs () {
        var self = this;
        self.mappedVPs.removeAll();
        var mappedVPsArray = [];
        var bmVPs = self.model.get('businessModel').get('bmValueProposition');
        bmVPs.each(function (bmVP) {
            var vp = bmVP.get('valueProposition');
            //self.mappedVPs.push({ name: vp.get('name'), id: vp.id });
            mappedVPsArray.push({ name: vp.get('name'), displayName:vp.get('name'), id: vp.id });
        });
        var bmVFs = self.model.get('businessModel').get('bmValueFormula');
        bmVFs.each(function (bmVF) {
            var vp = bmVF.get('valueFormula');
            //self.mappedVPs.push({ name: vp.get('name'), id: vp.id });
            mappedVPsArray.push({ name: vp.get('name'), displayName:vp.get('name'), id: vp.id });
        });
        for(var i=0;i<mappedVPsArray.length;i++){
            for(var j=0;j<mappedVPsArray.length;j++){
                if(mappedVPsArray[i] != mappedVPsArray[j] && mappedVPsArray[i].name === mappedVPsArray[j].name){
                    var vpObject = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: mappedVPsArray[i].id });
                    var vpPath = vpObject.getPackagePath();
                    var displayName = "   [";
                    if (vpPath[0] && vpPath[0].name && vpPath[1]) {
                        displayName = displayName.concat(vpPath[0].name + "  /  " + vpPath[1].name);
                    }
                    displayName = displayName.concat("]");
                    mappedVPsArray[i].displayName = mappedVPsArray[i].displayName.concat(displayName);
                    break;
                }
            }	
        }
        self.mappedVPs(mappedVPsArray);
    }
    loadMappedActivities () {
        var self = this;
        self.mappedActivities.removeAll();
        var bmActivities = self.model.get('businessModel').get('bmActivity');
        bmActivities.each(function (bmAct) {
            var act = bmAct.get('activity');
            var roleAssignment = act.get('performingRole').get('roleAssignment');
            if (roleAssignment && roleAssignment.models[0] && roleAssignment.models[0].get('participant') === self.model.get('businessModel').get('business')) {
                var actName = act.get('name');
                var nameCheck = self.model.get('businessModel').get('bmActivity').where({'name':''+actName})
                if(nameCheck && nameCheck.length > 1){
                    var actPath = act.getPackagePath();
                    var displayName = "   [";
                    if (actPath[0] && actPath[0].name && actPath[1]) {
                        displayName = displayName.concat(actPath[0].name + "  /  " + actPath[1].name);
                    }
                    displayName = displayName.concat("]");
                    actName = actName.concat(displayName);
                }
                self.mappedActivities.push({ name: actName, id: act.id });
            }
            
        });
        var cap = Backbone.Relational.store.getObjectByName('canvas.BMCanvasItem').find({ id: self.selectedSlip });
        if (cap && cap.get('capabilityItem') && (self.mappedSlipModal && self.mappedSlipModal.get('canvasItem') && self.mappedSlipModal.get('canvasItem').get('capability'))) {
            self.usedCapabilityName(cap.get('capabilityItem').get('name'));
            self.usedCapability = cap.get('capabilityItem').get('id');
        }
    }
    loadPerformers (limitToBusiness) {
        var self = this;
        self.activityPerformers.removeAll();
        var colls = this.model.get('businessModel').getNestedParent().get('collaboration');
        colls.each(function (coll) {
            if (coll.get('type') !== 'vdml_BusinessNetwork')
                self.activityPerformers.push({ name: coll.get('name'), id: coll.id });
        });
        if(self.mappedSlipModal){
            var performingRole = self.mappedSlipModal.get('canvasItem').get('performingRole');
            var participant = performingRole.get('roleAssignment').at(0).get('participant');
            var item = $.grep(self.activityPerformers(), function (e) { return e.id === participant.id; });
            self.activityPerformer(item[0]);
            $("#selectPerformer" + self.encodeId).prop("disabled", true);
        }	
    }
    loadValueProviders () {
        var self = this;
        self.valueProviders.removeAll();
        var valueProvidersArray = [];
        var bm = this.model.get('businessModel');
        var bmVPs = bm.get('bmValueProposition');
        bmVPs.each(function (bmVP) {
            var vp = bmVP.get('valueProposition');
            //self.valueProviders.push({ name: vp.get('name'), id: vp.id, type: vp.get('type') });
            valueProvidersArray.push({ name: vp.get('name'), displayName:vp.get('name'), id: vp.id, type: vp.get('type') });
        });
        var bmVFs = bm.get('bmValueFormula');
        bmVFs.each(function (bmVF) {
            var vf = bmVF.get('valueFormula');
            //self.valueProviders.push({ name: vf.get('name'), id: vf.id, type: vf.get('type') });
            valueProvidersArray.push({ name: vf.get('name'), displayName:vf.get('name'), id: vf.id, type: vf.get('type') });
        });
        var bmActivities = bm.get('bmActivity');
        bmActivities.each(function (bmActivity) {
            var activity = bmActivity.get('activity');
            //self.valueProviders.push({ name: activity.get('name'), id: activity.id, type: activity.get('type'), bmActivityId: bmActivity.id });
            valueProvidersArray.push({ name: activity.get('name'), displayName:activity.get('name'), id: activity.id, type: activity.get('type'), bmActivityId: bmActivity.id });
        });
        for(var i=0;i<valueProvidersArray.length;i++){
            for(var j=0;j<valueProvidersArray.length;j++){
                if(valueProvidersArray[i] != valueProvidersArray[j] && valueProvidersArray[i].name === valueProvidersArray[j].name){
                    var type = valueProvidersArray[i].type.replace('_','.');
                    var vpObject = Backbone.Relational.store.getObjectByName(''+type).find({ id: valueProvidersArray[i].id });
                    var vpPath = vpObject.getPackagePath();
                    var displayName = "   [";
                    if (vpPath[0] && vpPath[0].name && vpPath[1]) {
                        displayName = displayName.concat(vpPath[0].name + "  /  " + vpPath[1].name);
                    }
                    displayName = displayName.concat("]");
                    valueProvidersArray[i].displayName = valueProvidersArray[i].displayName.concat(displayName);
                    break;
                }
            }	
        }
        self.valueProviders(valueProvidersArray);
        if(self.mappedSlipModal){
            var valueOwner;
            if(self.mappedSlipModal.get('canvasItem').get('type') === "vdml_ValueAdd") {
                valueOwner = self.mappedSlipModal.get('canvasItem').getParent().getParent();
            }else {
                valueOwner = self.mappedSlipModal.get('canvasItem').getParent();//"vdml_ValueProposition"
            }
            var item = $.grep(self.valueProviders(), function (e) { return e.id === valueOwner.id; });
            self.valueProvider(item[0]);
            $("#selectValueSource" + self.encodeId).prop("disabled", true);
        }
    }
    hideAndShowSlipDetails () {
        var self = this;
        self.modelElement.modal("hide");
        $('.slipDetailsBox').removeClass('push-back');
    };
    hideAndCallback (revisionCanvas) {
        var self = this;
        self.modelElement.modal("hide");
        if (self.callback) {
            self.callback(self.model, revisionCanvas);
        }
    }
		
    handleWizardButtons () {
        var self = this;
        self.$wizard.on('finished.fu.wizard', function (e, data) {
            if (!self.selectedMapping()) {
                self.hideAndShowSlipDetails();
                return;
            }
            window.setTimeout(function () {
                if (self.selectedMapping().mapping === 'partner') {
                        self.createRevisionBasedOnContext(true, function (revisionCanvas) {
                            self.updatePartners(function(){
                                self.hideAndCallback(revisionCanvas);
                            });
                        });
                } else
                    if (self.selectedMapping().mapping === 'customer') {
                        self.createRevisionBasedOnContext(true, function (revisionCanvas) {
                            self.updateCustomers(function(){
                                self.hideAndCallback(revisionCanvas);
                            });
                        });
                    } else
                        if (self.selectedMapping().mapping === 'valueProposition') {
                                self.createRevisionBasedOnContext(true, function (revisionCanvas) {
                                    self.updateVP(function(){
                                        self.hideAndCallback(revisionCanvas);
                                    });
                                });
                        } else
                            if (self.selectedMapping().mapping === 'activity') {
                                    self.createRevisionBasedOnContext(true, function (revisionCanvas) {
                                        self.updateActivity(function(){
                                            self.hideAndCallback(revisionCanvas);
                                        });
                                    });
                            } else
                                if (self.selectedMapping().mapping === 'competence') {
                                        self.createRevisionBasedOnContext(true, function (revisionCanvas) {
                                            self.updateResource(function(){
                                                self.hideAndCallback(revisionCanvas);
                                            });
                                        });
                                } else
                                    if (self.selectedMapping().mapping === 'value') {
                                            self.createRevisionBasedOnContext(true, function (revisionCanvas) {
                                                self.updateValue(function(){
                                                    self.hideAndCallback(revisionCanvas);
                                                });
                                            });
                                    } else
                                        if (self.selectedMapping().mapping === 'network') {
                                            self.createRevisionBasedOnContext(true, function (revisionCanvas) {
                                                self.updateNetwork(function(){
                                                    self.hideAndCallback(revisionCanvas);
                                                });
                                            });
                                        } else {
                                            self.hideAndShowSlipDetails();
                                        }
                //self.$wizard.wizard('destroy');
                }, 100);
        });

        self.$wizard.on('clicked.fu.wizard.action', function (e, data) {
            if (!self.selectedMapping()) {
                self.hideAndShowSlipDetails();
                return;
            }

            if (data.step === 1) {
                $('.btn-next').attr('disabled', 'disabled');
            }
            else {
                $('.btn-next').removeAttr('disabled');
            }

            if (self.selectedMapping().mapping === "partner" && data.step === 1) {
                self.loadUnmappedPartners();
                self.loadExistingParticipants();
                self.loadNetworksTypeAhead();
                if($('#selectParticipantPartner' + self.encodeId).val().trim() === ''){
                    $('#selectParticipantPartner' + self.encodeId).val($('#selectPartner' + self.encodeId).val().trim());
                }
                if ($('#selectPartner' + self.encodeId).val().trim() !== "" && $('#role1' + self.encodeId).val().trim() !== "" && $('#network1' + self.encodeId).val().trim() !== "") {
                    $('.btn-next').removeAttr('disabled');
                }
                else {
                    $('.btn-next').attr('disabled', 'disabled');
                }
            } else
                if (self.selectedMapping().mapping === 'customer' && data.step === 1) {
                    self.loadUnmappedCustomers();
                    self.loadExistingParticipants();
                    self.loadNetworksTypeAhead();
                    if($('#selectParticipantCustomer' + self.encodeId).val().trim() === ''){
                        $('#selectParticipantCustomer' + self.encodeId).val($('#selectCustomer' + self.encodeId).val().trim());
                    }
                    if ($('#selectCustomer' + self.encodeId).val().trim() !== "" && $('#role2' + self.encodeId).val().trim() !== "" && $('#network2' + self.encodeId).val().trim() !== "") {
                        $('.btn-next').removeAttr('disabled');
                    }
                    else {
                        $('.btn-next').attr('disabled', 'disabled');
                    }
                } else
                    if (self.selectedMapping().mapping === 'valueProposition' && data.step === 1) {
                        self.loadUnmappedVPs();
                        self.loadProviders();
                        self.loadReceivers();
                        if (self.selectedUnmappedVP() && self.vpProvider() && self.selectedProviderRole()) {
                            $('.btn-next').removeAttr('disabled');
                        }
                        else {
                            $('.btn-next').attr('disabled', 'disabled');
                        }
                    } else
                        if (self.selectedMapping().mapping === 'activity' && data.step === 1) {
                            self.loadUnmappedActivities();
                            self.loadMappedVPs();
                            self.loadPerformers();
                            if (self.selectedUnmappedActivity() && self.activityPerformer() && self.activityPerformerRole() && self.persuedVPs()) {
                                $('.btn-next').removeAttr('disabled');
                            }
                            else {
                                $('.btn-next').attr('disabled', 'disabled');
                            }
                        } else
                            if (self.selectedMapping().mapping === 'competence' && data.step === 1) {
                                self.loadUnmappedResources();
                                self.loadMappedActivities();
                                if (self.selectedUnmappedResource() && self.selectedResourceType() && self.resourceActivities()) {
                                    $('.btn-next').removeAttr('disabled');
                                }
                                else {
                                    $('.btn-next').attr('disabled', 'disabled');
                                }
                            } else
                                if (self.selectedMapping().mapping === 'value' && data.step === 1) {
                                    self.loadUnmappedValues();
                                    if (!self.model.get('businessModel')) {
                                        self.isPlanValue(true);
                                    } else {
                                        self.loadValueProviders();
                                        if (self.selectedUnmappedValue() && self.valueProvider() && self.activityVP()) {
                                            $('.btn-next').removeAttr('disabled');
                                        } else {
                                            $('.btn-next').attr('disabled', 'disabled');
                                        }
                                    }
                                } else if (self.selectedMapping().mapping === 'network' && data.step === 1) {
                                    self.loadUnmappedNetwork();
                                    self.setBusinessName();
                                        if(!self.mappedSlipModal){
                                        $("#selectNetworkRole" + self.encodeId).val('My Business Role');
                                    }
                                    if (self.selectedUnmappedNetwork()) {
                                        $('.btn-next').removeAttr('disabled');
                                    }
                                    else {
                                        $('.btn-next').attr('disabled', 'disabled');
                                    }
                                }
        });

        self.$wizard.on('stepclick.fu.wizard', function (e, data) {
            if (data.step !== 1) {
                $('.btn-next').attr('disabled', 'disabled');
            }
            else {
                $('.btn-next').removeAttr('disabled');
            }
        });
    }
    updatePartners (callback) {
        var self = this;
        if (!self.selectedNetworkRole()) {
            return;
        }
        if (self.selectedMapping()) {
            var unmappedPartnerName = $('#selectParticipantPartner' + self.encodeId).val().trim();
            var unmappedPartnerDescription = self.selectedUnmappedPartner() ? self.selectedUnmappedPartner().get('description') : $("#selectPartner" + self.encodeId).val().trim();
            if(!unmappedPartnerName) {
                unmappedPartnerName = self.selectedUnmappedPartner() ? self.selectedUnmappedPartner().get('name') : $("#selectPartner" + self.encodeId).val().trim();
            }
            var networkName = self.selectedNetwork() ? self.selectedNetwork().get('name') : $("#network1" + self.encodeId).val().trim();
            var roleName = self.selectedNetworkRole();
            var bm = self.model.get('businessModel');
            function createCanvasPartner(networkModel,partnerCallback){
                var participantType = Backbone.Relational.store.getObjectByName(self.participantType().type);
                if(self.existingParticipant()){
                    var networkPartner = Backbone.Relational.store.getObjectByName(''+self.existingParticipant().type).find({ id: self.existingParticipant().id });
                    if(self.mappedSlipModal) {
                        var partner = self.mappedSlipModal.get('canvasItem');
                        var bmPartner = self.model.get('businessModel').get('bmNetworkPartner').findWhere({'networkPartner':partner});
                        partnerCallback(bmPartner);
                    }else {
                        bmPartner = bm.createBmNetworkPartner(self.existingParticipant().name, unmappedPartnerName, networkPartner);
                        partnerCallback(bmPartner);
                    }
                }else {
                        bm.createNewPartner(unmappedPartnerName, unmappedPartnerDescription, participantType, null, networkModel,self.currentAlternativeModel,function(bmPartner){
                        partnerCallback(bmPartner);
                    });
                    
                }
            }
            
            if (!self.selectedNetwork()) {
                    bm.createParticipantNetwork(networkName,null,self.currentAlternativeModel,function(networkModel){
                    networkModel.get('collaboration').createAndAssignDefaultRole(bm);
                    createCanvasPartner(networkModel,function(bmPartner){
                        if (bmPartner && networkModel) {
                            bm.addNewPartnerRole(bmPartner, roleName, roleName, networkModel, self.currentAlternativeModel, function(newAssignment){
                                if (self.selectedUnmappedPartner()) {
                                    self.selectedUnmappedPartner().get('blockItem').set('canvasItem', bmPartner.get('networkPartner'));
                                }
                                callback();
                            });
                        }
                    });
                });
            } else {
                var networkModel = Backbone.Relational.store.getObjectByName('vdml.ParticipantNetwork').find({ id: self.selectedNetwork().get('id') });
                createCanvasPartner(networkModel,function(bmPartner){
                    if (bmPartner && networkModel) {
                        bm.addNewPartnerRole(bmPartner, roleName, roleName, networkModel, self.currentAlternativeModel, function(newAssignment){
                            if (self.selectedUnmappedPartner()) {
                                self.selectedUnmappedPartner().get('blockItem').set('canvasItem', bmPartner.get('networkPartner'));
                            }
                            callback();
                        });
                    }
                });
            }
        }
    }
        
    updateCustomers (callback) {
        var self = this;
        if (!self.selectedNetworkRole()) {
            return;
        }
        if (self.selectedMapping()) {
            var unmappedCustomerName = $('#selectParticipantCustomer' + self.encodeId).val().trim();
            var unmappedCustomerDescription = self.selectedUnmappedCustomer() ? self.selectedUnmappedCustomer().get('description') : $("#selectCustomer" + self.encodeId).val().trim();
            if(!unmappedCustomerName) {
                unmappedCustomerName = self.selectedUnmappedCustomer() ? self.selectedUnmappedCustomer().get('name') : $("#selectCustomer" + self.encodeId).val().trim();
            }
            var networkName = self.selectedNetwork() ? self.selectedNetwork().get('name') : $("#network2" + self.encodeId).val().trim();
            var roleName = self.selectedNetworkRole();
            var bm = self.model.get('businessModel');
            function createCanvasCustomer(networkModel,customerCallback){
                var participantType = Backbone.Relational.store.getObjectByName(self.participantType().type);
                if(self.existingParticipant()){
                    var customerModel = Backbone.Relational.store.getObjectByName(''+self.existingParticipant().type).find({ id: self.existingParticipant().id });
                    if(self.mappedSlipModal) {
                        var customer = self.mappedSlipModal.get('canvasItem');
                        var bmCustomer = self.model.get('businessModel').get('bmCustomer').findWhere({'customer':customer});
                        customerCallback(bmCustomer);
                    }else {
                        var bmCustomer = bm.createBmCustomer(self.existingParticipant().name, unmappedCustomerName, customerModel);
                        customerCallback(bmCustomer);
                    }	
                }else {
                        bm.createNewCustomer(unmappedCustomerName, unmappedCustomerDescription, participantType, null, networkModel,self.currentAlternativeModel,function(bmCustomer){
                        customerCallback(bmCustomer);
                        
                    }); 
                }
            }
            if (!self.selectedNetwork()) {
                bm.createParticipantNetwork(networkName,null,self.currentAlternativeModel,function(networkModel){
                    networkModel.get('collaboration').createAndAssignDefaultRole(bm);
                    createCanvasCustomer(networkModel,function(bmCustomer){
                        if (bmCustomer && networkModel) {
                            bm.addNewCustomerRole(bmCustomer, roleName, roleName, networkModel, self.currentAlternativeModel, function(newAssignment){
                                    if (self.selectedUnmappedCustomer()) {
                                    self.selectedUnmappedCustomer().get('blockItem').set('canvasItem', bmCustomer.get('customer'));
                                }
                                callback();
                            });
                        }
                    });
                });
            } else {
                var networkModel = Backbone.Relational.store.getObjectByName('vdml.ParticipantNetwork').find({ id: self.selectedNetwork().get('id') });
                createCanvasCustomer(networkModel,function(bmCustomer){
                    if (bmCustomer && networkModel) {
                        bm.addNewCustomerRole(bmCustomer, roleName, roleName, networkModel, self.currentAlternativeModel, function(newAssignment){
                                if (self.selectedUnmappedCustomer()) {
                                self.selectedUnmappedCustomer().get('blockItem').set('canvasItem', bmCustomer.get('customer'));
                            }
                            callback();
                        });
                    }
                });
            }
        }
    };
        
    updateVP (callback) {
        var self = this;
        var bm = self.model.get('businessModel');
        if (!bm) {
            return;
        }
        var providerRole;
        if (self.selectedProviderRole()) {
            providerRole = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: self.selectedProviderRole().id });
        }
        var vp;
        var unmappedVPName = self.selectedUnmappedVP() ? self.selectedUnmappedVP().get('name') : $("#selectVP" + self.encodeId).val().trim();
        if (!self.myProposition()) {
            var receiverRole;
            if (self.selectedReceiverRole()) {
                receiverRole = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: self.selectedReceiverRole().id });
            }
            if(self.mappedSlipModal) {
                self.mappedSlipModal.get('canvasItem').set('recipient',receiverRole);
                callback();
            }else {
                    bm.addBMValueProposition(null, unmappedVPName, providerRole, receiverRole, null, self.currentAlternativeModel, function(vp){
                    if (self.selectedUnmappedVP()) {
                        vp.get('valueProposition') ? self.selectedUnmappedVP().get('blockItem').set('canvasItem', vp.get('valueProposition')) : self.selectedUnmappedVP().get('blockItem').set('canvasItem', vp.get('valueFormula'));
                    }
                    callback();
                });
                
            }                
        } else {
            bm.addBMValueFormula(null, unmappedVPName, providerRole, null, self.currentAlternativeModel, function(vp){
                if (self.selectedUnmappedVP()) {
                    vp.get('valueProposition') ? self.selectedUnmappedVP().get('blockItem').set('canvasItem', vp.get('valueProposition')) : self.selectedUnmappedVP().get('blockItem').set('canvasItem', vp.get('valueFormula'));
                }
                callback();
            });
        }
    }
    getViewAlternativeModel () {
        var self = this;
        var phase = DataManager.getDataManager().get('currentPlan').get('phase');
        var alternative = DataManager.getDataManager().get('currentWorkspace').get("id");
        var tempAlt = DataManager.getDataManager().get('viewAlternative') ? DataManager.getDataManager().get('viewAlternative') : alternative;
        for (var j = 0; j < phase.length; j++) {
            var alts = phase.at(j).get('phaseAlternative');
            var viewAlternativeModel = alts.findWhere({ 'id': tempAlt });
            if (viewAlternativeModel) {
                self.currentAlternativeModel = viewAlternativeModel;
                break;
            }
        }
    }
        
    updateResource (callback) {
        var self = this;
        var bm = self.model.get('businessModel');
        if (!bm || !self.resourceActivities() || !self.selectedUnmappedResource()) {
            return;
        }
        var scenario = self.currentAlternativeModel.getMainScenario(bm.getNestedParent());
        var resourceType;
        var competence;
        resourceType = self.selectedResourceType().type === "Resource" ? BusinessItem : CapabilityOffer;
        if(!self.mappedSlipModal) {
                bm.createNewCompetency(self.selectedUnmappedResource().name, self.selectedUnmappedResource().description, resourceType, null, bm.get('business'), scenario, self.currentAlternativeModel, function(competence){
                setMappedCompetency(competence,callback);
            });
            
        }else {
            competence = self.mappedSlipModal.get('canvasItem');
            setMappedCompetency(competence, callback);
            
        }
        
        function setMappedCompetency(competence,callback){
            for (var i = 0; i < self.resourceActivities().length; i++) {
                var activity = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: self.resourceActivities()[i].id });
                if (resourceType === BusinessItem) {
                    activity.createResourceInput(bm, competence, bm.get('business'));
                } else {
                    activity.createCapabilityUse(bm, [competence], bm.get('business'), scenario);
                }
            }
            if (self.usedCapability) {
                var capItem = self.getCapabilityElementModel(self.usedCapability);
                if (capItem) {
                    competence.set('capability', capItem);
                    $('#capabilityName').text(null)
                    $('.capMapped').removeClass('hide');
                    $('#capabilityName').html(capItem.get('name'));
                    $('#capMappedTo').hide();
                    $('#showCapMappedInfo').show();
                    $('#showCapMappedEdit').hide();
                }
            }
            if(!self.usedCapability){
                competence.set('capability',null);
                $('.capMapped').addClass('hide');
                $('#capMappedTo').hide();
                $('#showCapMappedInfo').hide();
                $('#showCapMappedEdit').hide();
            }
            self.selectedUnmappedResource().blockItem.set('canvasItem', competence);
            self.selectedUnmappedResource().blockItem.set('capabilityItem', null);
            callback();
        }
        
        
    }
    getCapabilityElementModel (id) {
        var self = this;
        var capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityCategory').find({ id: id });
        if (!capModel) {
            capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityDefinition').find({ id: id });
        }
        return capModel;
    }
    updateActivity (callback) {
        var self = this;
        var bm = self.model.get('businessModel');
        if (!bm || !self.persuedVPs() || !self.activityPerformerRole()) {
            return;
        }
        var unmappedActivityName = self.selectedUnmappedActivity() ? self.selectedUnmappedActivity().get('name') : $("#selectActivity" + self.encodeId).val().trim();
        var unmappedActivityDescription = self.selectedUnmappedActivity() ? self.selectedUnmappedActivity().get('description') : $("#selectActivity" + self.encodeId).val().trim();
        var performerRole = Backbone.Relational.store.getObjectByName('vdml.Role').find({ id: self.activityPerformerRole().id });
        var newActivity;
        function updateValueStream(newActivity,callback){
            for (var j = 0; j < self.persuedVPs().length; j++) {
                var persuedVP = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: self.persuedVPs()[j].id });
                var vdmlPackage = persuedVP.getNestedParent();
                var valueStreams = vdmlPackage.get('valueStream');
                var selectedValueStreamModel = null;
                for (var i = 0; i < valueStreams.length; i++) {
                    if (valueStreams.at(i).get('persuedProposition') && valueStreams.at(i).get('persuedProposition').get('id') === persuedVP.id) {
                        selectedValueStreamModel = valueStreams.at(i);
                        break;
                    }
                }
                if (selectedValueStreamModel == null) {
                    var vsId = DataManager.getDataManager().guidGeneratorByOwner(vdmlPackage);
                    selectedValueStreamModel = new ValueStream({ id: vsId, name: persuedVP.get('name') + " Value Stream", description: persuedVP.get('name') + " Value Stream", valueStreamOwner: vdmlPackage });
                }
                bm.get('bmValueStream').add(selectedValueStreamModel);
                selectedValueStreamModel.set('persuedProposition', persuedVP);
                selectedValueStreamModel.get('valueStreamActivity').add(newActivity);
            }

            if (self.usedCapability) {
                var capItem = self.getCapabilityElementModel(self.usedCapability);
                if (capItem) {
                    newActivity.set('capabilityRequirement', capItem);
                    $('#capabilityName').text(null)
                    $('.capMapped').removeClass('hide');
                    $('#capabilityName').html(capItem.get('name'));
                    $('#capMappedTo').hide();
                    $('#showCapMappedInfo').show();
                    $('#showCapMappedEdit').hide();
                }
            }

            if (self.selectedUnmappedActivity()) {
                self.selectedUnmappedActivity().get('blockItem').set('canvasItem', newActivity);
                self.selectedUnmappedActivity().get('blockItem').set('capabilityItem', null);
            }
            if(!self.usedCapability){
                newActivity.set('capabilityRequirement', null);
                $('.capMapped').addClass('hide');
                $('#capMappedTo').hide();
                $('#showCapMappedInfo').hide();
                $('#showCapMappedEdit').hide();
            }
            callback();
        }
        if(self.mappedSlipModal) {
            newActivity = self.mappedSlipModal.get('canvasItem');
            updateValueStream(newActivity,callback);
        }else {
                bm.addBMActivities(unmappedActivityName, unmappedActivityDescription, performerRole,self.currentAlternativeModel,function(newActivity){
                updateValueStream(newActivity,callback);
            });
        }
        
    }
        
    loadActivityVPs (valueProvider) {
        var self = this;
        var activity = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: valueProvider.id });
        var valueStreams = activity.getNestedParent().get('valueStream');
        var selActivityVP = self.activityVP();
        self.activityVPs.removeAll();
        var bm = self.model.get('businessModel');
        valueStreams.each(function (vs) {
            if (bm.get('bmValueStream').findWhere({ 'id': vs.id })) {
                var vsActivities = vs.get('valueStreamActivity');
                if (vsActivities.findWhere({ id: activity.id })) {
                    self.activityVPs.push({ name: vs.get('persuedProposition').get('name'), vpId: vs.get('persuedProposition').id, vsId: vs.id });
                }
            }
        });
        if (selActivityVP) {
            var item = $.grep(self.activityVPs(), function (e) { return e.name === selActivityVP.name; });
            self.activityVP(item[0]);
        }
    }
    updateValue (callback) {
        var self = this;
        var valueProvider = self.valueProvider();
        var value;
        if (self.isPlanValue()) {
            if(self.mappedSlipModal) {
                return;
            }
            var planCriterionSet = DataManager.getDataManager().get('currentPlan').getOrCreatePlanCriterionSet();
            value = self.parentView.createVPC(planCriterionSet, null, self.selectedUnmappedValue().name);
            self.selectedUnmappedValue().blockItem.set('canvasItem', value);
            callback();
        } else {
            if (!valueProvider || !self.selectedUnmappedValue()) {
                return;
            }
            if (valueProvider.type === 'vdml_Activity') {
                if (!self.activityVP()) {
                    return;
                }
                var activity = Backbone.Relational.store.getObjectByName('vdml.Activity').find({ id: valueProvider.id });
                var bmActivity = Backbone.Relational.store.getObjectByName('vdml.BusinessModelActivity').find({ id: valueProvider.bmActivityId });
                if(!self.mappedSlipModal) {
                    var outputPort = bmActivity?bmActivity.get('defaultOutput'):null;
                    var outputPortName = '';
                    if(!outputPort){
                        outputPortName = activity.getUniqueProperty('name', activity.get('name') ,'containedPort') +  " port";
                    }else {
                        outputPortName = outputPort.get('name');
                    }
                    var collaboration = activity.getParent();
                    var vdml = collaboration.getNestedParent();
                    var namePath = vdml.get('name') +'>'+collaboration.get('name') +'>'+activity.get('name') +'>'+outputPortName+'>'+self.selectedUnmappedValue().name;
                    vdml.getDocumentWithNamePath(namePath, "vdml_ValueAdd", self.currentAlternativeModel, true, vdml, function (results) {
                        if (results && results[0]) {
                            var doc = results[0].doc;
                            var parent = results[0].parent;
                            var altId = DataManager.getDataManager().getRepositoryId(doc);
                            var vdmStore = DataManager.getDataManager().getVDMStore(altId);
                            var version = DataManager.getDataManager().get('currentVDMVersion');
                            DataManager.getDataManager().fetchDocumentFromPackage(parent, "appbo/vdml/ValueDeliveryModel", version, doc, "appbo/vdml/ValueAdd", vdmStore, {
                                success: function (valModel) {
                                    value = self.parentView.createValueAdd(null, activity, self.selectedUnmappedValue().name, bmActivity,valModel);
                                    for (var j = 0; j < self.activityVP().length; j++) {
                                        var vs = Backbone.Relational.store.getObjectByName('vdml.ValueStream').find({ id: self.activityVP()[j].vsId });
                                        vs.get('activityValue').add(value);
                                    }
                                    self.selectedUnmappedValue().blockItem.set('canvasItem', value);
                                    callback();
                                },
                                error: function (error) {
                                    console.log('Unable to load Value document');
                                    value = self.parentView.createValueAdd(null, activity, self.selectedUnmappedValue().name, bmActivity);
                                    for (var j = 0; j < self.activityVP().length; j++) {
                                        var vs = Backbone.Relational.store.getObjectByName('vdml.ValueStream').find({ id: self.activityVP()[j].vsId });
                                        vs.get('activityValue').add(value);
                                    }
                                    self.selectedUnmappedValue().blockItem.set('canvasItem', value);
                                    callback();
                                }
                            });
                            
                            }else {
                            value = self.parentView.createValueAdd(null, activity, self.selectedUnmappedValue().name, bmActivity);
                            for (var j = 0; j < self.activityVP().length; j++) {
                                var vs = Backbone.Relational.store.getObjectByName('vdml.ValueStream').find({ id: self.activityVP()[j].vsId });
                                vs.get('activityValue').add(value);
                            }
                            self.selectedUnmappedValue().blockItem.set('canvasItem', value);
                            callback();
                            }
                    });
                    
                    
                }else {
                    value = self.mappedSlipModal.get('canvasItem');
                    for (var j = 0; j < self.activityVP().length; j++) {
                        var vs = Backbone.Relational.store.getObjectByName('vdml.ValueStream').find({ id: self.activityVP()[j].vsId });
                        vs.get('activityValue').add(value);
                    }
                    self.selectedUnmappedValue().blockItem.set('canvasItem', value);
                    callback();
                }
                
            } else {
                if(self.mappedSlipModal) {
                    return;
                }
                var vpc = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({ id: valueProvider.id });
                var valueName = self.selectedUnmappedValue().name.trim();
                var providerRole = vpc.get('provider');
                var collaboration = providerRole.getParent();
                var vdml = collaboration.getNestedParent();
                var namePath = vdml.get('name') +'>'+collaboration.get('name') +'>'+providerRole.get('name') +'>'+vpc.get('name')+'>'+valueName;
                vdml.getDocumentWithNamePath(namePath, "vdml_ValuePropositionComponent", self.currentAlternativeModel, true, vdml, function (results) {
                    if (results && results[0]) {
                        self.fetchNamePathValue(0,results,vpc,valueName,function(vpc,oldVpc,valueName){
                            value = self.parentView.createVPC(vpc,oldVpc,valueName);
                            self.selectedUnmappedValue().blockItem.set('canvasItem', value);
                            callback();
                        });
                    }else {
                        value = self.parentView.createVPC(vpc,null,valueName);
                        self.selectedUnmappedValue().blockItem.set('canvasItem', value);
                        callback();
                    }
                });
            }
        }
        
    }
        
    fetchNamePathValue(count,results,component,valueName,callback){
        var self = this;
        var doc = results[count].doc;
        var parent = results[count].parent;
        //var newVPCIdSuffix = window.utils.getSuffix(doc);
        var altId = DataManager.getDataManager().getRepositoryId(doc);
        var vdmStore = DataManager.getDataManager().getVDMStore(altId);
        var version = DataManager.getDataManager().get('currentVDMVersion');
        DataManager.getDataManager().fetchDocumentFromPackage(parent, "appbo/vdml/ValueDeliveryModel", version, doc, "appbo/vdml/ValuePropositionComponent", vdmStore, {
            success: function (valModel) {
                if(window.utils.getSuffix(valModel.getParent().id) === window.utils.getSuffix(component.id)){
                    callback(component,valModel,valueName);
                }else {
                    count++;
                    if(results[count]){
                        self.fetchNamePathValue(count,results,component,valueName,callback);	
                    }else {
                        callback(component,null,valueName);
                    }
                }                    
            },
            error: function (error) {
                console.log('Unable to load Value document');
                callback(component,null,valueName);
            }
        });
    }

    updateNetwork (callback) {
        var self = this;
        if (self.selectedMapping()) {
            var unmappedNetworkName = self.selectedUnmappedNetwork() ? self.selectedUnmappedNetwork().get('name') : $("#selectNetwork" + self.encodeId).val().trim();
            var bm = self.model.get('businessModel');
            var roleName = self.selectedUnmappedNetworkRole() ? self.selectedUnmappedNetworkRole().get('name') : $("#selectNetworkRole" + self.encodeId).val().trim();
            var participantNetwork;
            var business = bm.get('business');
            var newBusinessName = $('#businessName' + self.encodeId).val().trim();
            if(!business){
                bm.getNestedParent().createDefaultBusiness(bm,newBusinessName);
            }
            function setDiagramId(participantNetwork){
                if (business && business.get('name') !== newBusinessName) {
                    var businessName = self.model.getNestedParent().getUniqueProperty('name', newBusinessName, 'collaboration');
                    business.set('name', businessName);
                }

                if (self.selectedUnmappedNetwork() && self.selectedUnmappedNetwork().get('blockItem')) {
                    self.selectedUnmappedNetwork().get('blockItem').set('canvasItem', participantNetwork.get('collaboration'));
                }
            }
            if (!self.selectedUnmappedNetwork() && !self.mappedSlipModal) {
                bm.createParticipantNetwork(unmappedNetworkName,null,self.currentAlternativeModel,function(participantNetwork){
                    if (self.selectedUnmappedNetworkRole()) {
                        var role = bm.get('bmRole').findWhere({ 'id': self.selectedUnmappedNetworkRole().id });
                        bm.addExistingRoleToBusiness(bm.get('business'), role, participantNetwork);
                        setDiagramId(participantNetwork);
                        callback();
                    } else {
                        bm.addNewRoleToBusiness(bm.get('business'), roleName, roleName, participantNetwork,self.currentAlternativeModel,function(assignment){
                            setDiagramId(participantNetwork);
                            callback();	
                        });
                        //networkModel.get('collaboration').createAndAssignDefaultRole(bm);
                    }
                });
            } else {
                if(self.mappedSlipModal){
                    var coll = self.mappedSlipModal.get('canvasItem');//Backbone.Relational.store.getObjectByName('vdml.BusinessNetwork').find({ id: self.mappedSlipModal.get('canvasItem').get('id') });
                    participantNetwork = self.model.get('businessModel').get('participantNetwork').findWhere({'collaboration':coll});
                }else {
                    participantNetwork = Backbone.Relational.store.getObjectByName('vdml.ParticipantNetwork').find({ id: self.selectedUnmappedNetwork().get('id') });
                }
                if (!participantNetwork) {
                    bm.createParticipantNetwork(unmappedNetworkName,null,self.currentAlternativeModel,function(participantNetwork){
                        if (self.selectedUnmappedNetworkRole()) {
                            var role = bm.get('bmRole').findWhere({ 'id': self.selectedUnmappedNetworkRole().id });
                            bm.addExistingRoleToBusiness(bm.get('business'), role, participantNetwork);
                            setDiagramId(participantNetwork);
                            callback();
                        } else {
                            bm.addNewRoleToBusiness(bm.get('business'), roleName, roleName, participantNetwork,self.currentAlternativeModel,function(assignment){
                                setDiagramId(participantNetwork);
                                callback();
                            });	
                        }
                    });
                    //participantNetwork.get('collaboration').createAndAssignDefaultRole(bm);
                } else {
                    if (self.selectedUnmappedNetworkRole()) {
                        var role = bm.get('bmRole').findWhere({ 'id': self.selectedUnmappedNetworkRole().id });
                        bm.addExistingRoleToBusiness(bm.get('business'), role, participantNetwork);
                        setDiagramId(participantNetwork);
                        callback();
                    } else {
                        bm.addNewRoleToBusiness(bm.get('business'), roleName, roleName, participantNetwork,self.currentAlternativeModel,function(assignment){
                            setDiagramId(participantNetwork);
                            callback();
                        });	
                    }
                }
            }
        }
    }
        
    mapCapability(){
        var self = this;
        var id = window.utils.htmlEscape(window.guidGenerator());
        var currentPlan = DataManager.getDataManager().get('currentPlan');
        var options = {capabilityLibs: self.capabilityLibs, callback: function (capId, capName, capDesc, capParentId) {
                if (!capId) {
                    return;
                }
                self.usedCapability = capId;
                self.usedCapabilityName(capName);
                $('#mapActCapability' + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
                $('#deleteActCapability' + self.encodeId).show();
                $('#mapActCapability' + self.encodeId).css('margin-left','10px');
                $('#mapCapabilityCom' + self.encodeId).removeClass('glyphicon-plus-sign').addClass('glyphicon-pencil');
                $('#deleteCompCapability' + self.encodeId).show();
                $('#mapCompCapability' + self.encodeId).css('margin-left','10px');
            }
        };
        if (self.usedCapability){
            options.showCapabilityId = self.usedCapability;
            
        }
        window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "CapabilityExplorerJson", null, options);
    }
    deleteActCapability(){
        var self = this;
            var cap = self.usedCapability;
            if(cap){
                self.usedCapability = null;
                self.usedCapabilityName(null);
                $('#deleteActCapability'+self.encodeId).hide();
                $("#mapActCapability" + self.encodeId).removeClass('glyphicon-pencil').addClass('glyphicon-plus-sign');
                $("#mapActCapability" + self.encodeId).css('margin-left','-10px');
            }
    }
    deleteCompCapability(){
        var self = this;
        var cap = self.usedCapability;
        if(cap){
            self.usedCapability = null;
            self.usedCapabilityName(null);
            $('#deleteCompCapability'+self.encodeId).hide();
            $("#mapCapabilityCom" + self.encodeId).removeClass('glyphicon-pencil').addClass('glyphicon-plus-sign');
            $("#mapCapabilityCom" + self.encodeId).css('margin-left','-10px');
        }
    }
    static getInstance (model, options, configObj, selectedDim, selectedSlip, callback) {
        return new MappingWizardViewModel($('#wizardModal'), model, options, configObj, selectedDim, selectedSlip, callback);
    }
 }

path.MappingWizardViewModel = MappingWizardViewModel;
